import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const getContentList = (params, token) => {
  return fetch("get", `${API_HOST}api/v1/admin/contents/list`, params, token);
};

const getContentDetail = (params, token) => {
    return fetch("get", `${API_HOST}api/v1/admin/contents/detail/${params.id}`, {}, token);
};


const updateContent = (data, options) => {
  return fetch("put", `${API_HOST}api/v1/admin/contents/updateContent`, data, options);
};


export const ContentServices = {
    getContentList,
    updateContent,
    getContentDetail
};
