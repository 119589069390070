import { actionTypes } from "../actons/installment.action";

const initialState = {
  instalmentList: [],
  userInstalment: [],
  userInstalmentMeta: {},
  saveviewTerminatePlan: {},
  getFeeData: [],
  getWithdrawLimit: [],
};

const installment = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INSTALMENT_LIST:
      return {
        ...state,
        instalmentList: action.data.data,
      };
    case actionTypes.USER_INSTALMENT_LIST:
      return {
        ...state,
        userInstalment: action.data.data,
        userInstalmentMeta: action.data.meta,
      };
    // case actionTypes.USER_INSTALMENT_LIST:
    //   return {
    //     ...state,
    //     userInstalmentMeta: action.data,
    //   };
    case actionTypes.SAVE_VIEW_TERMINATE_PLAN:
      return {
        ...state,
        saveviewTerminatePlan: action.data.data,
      };
    case actionTypes.GetFee_List:
      return {
        ...state,
        getFeeData: action.data.data,
      };
    case actionTypes.GetWithdraw_Limit:
      return {
        ...state,
        getWithdrawLimit: action.data.data,
      };
    default:
      return state;
  }
};

export default installment;
