import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Container } from "reactstrap";
import { Tab } from "semantic-ui-react";
import Header from "components/Headers/Header";
import { withRouter } from "react-router";
import "../AccountSetting/AccountSetting.scss";
import { reduxForm } from "redux-form";
import TransactionFeeManagement from "views/TransactionFeeManagement/TransactionFeeManagement";
import TransactionFeeManagementWithdraw from "views/TransactionFeeManagement/TransactionFeeManagement";
const panes = [
  {
    menuItem: "Withdraw",
    render: () => (
      <Tab.Pane>
        {" "}
        <TransactionFeeManagementWithdraw statusType={"withdraw"} />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Deposit",
    render: () => (
      <Tab.Pane>
        <TransactionFeeManagement statusType={"deposit"} />
      </Tab.Pane>
    ),
  },
];

export class TabDevideHandle extends Component {
  render() {
    return (
      <div>
        <Header headerTitle="transaction management" />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Card className="shadow">
                <Tab panes={panes} />
              </Card>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "getDailyWithdrawLimit" })(TabDevideHandle))
);
