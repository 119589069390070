import React from "react";

import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./FormField.scss";

const TextArea = (props) => {
  const { meta = {} } = props;

  const inputProps = {
    type: props.type || "text",
    className: "form__input",
    name: props.input.name,
    id: props?.input?.name,
    readOnly: props.readOnly,
    autoFocus: props.autoFocus,
    autoComplete: props.autoComplete,
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    value: meta.uncontrolled ? undefined : props.input.value,
    defaultValue: meta.uncontrolled ? props.defaultValue : undefined,
    onChange: props.input.onChange,
    onKeyUp: props.onKeyUp,
  };

  let errorClassName = meta.error ? "error-input" : "";
  return (
    <div>
      <React.Fragment>
        <textarea
          style={{ width: "100%" }}
          rows="6"
          {...inputProps}
          className={errorClassName}
        />
      </React.Fragment>
      {meta.touched && meta.error ? (
        <div className="form__field-error">{meta.error}</div>
      ) : null}
    </div>
  );
};
const TextField = (props) => {
  const { meta = {} } = props;

  const inputProps = {
    type: props.type || "text",
    className: "form__input",
    name: props?.input?.name,
    id: props?.input?.name,
    readOnly: props?.readOnly,
    autoFocus: props?.autoFocus,
    autoComplete: props?.autoComplete,
    placeholder: props?.placeholder,
    maxLength: props?.maxLength,
    value: meta.uncontrolled ? undefined : props?.input?.value,
    defaultValue: meta.uncontrolled ? props.defaultValue : undefined,
    onChange: props?.input?.onChange,
    onKeyUp: props?.onKeyUp,
    disabled: props?.disabled,
  };

  let errorClassName = meta.error ? "error-input" : "";
  return (
    <div style={{ ...props.style }}>
      <React.Fragment>
        <Input {...inputProps} className={errorClassName} />
      </React.Fragment>
      {meta.touched && meta.error ? (
        <div className="form__field-error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const CheckBox = (props) => {
  const { meta = {} } = props;

  const checboxProps = {
    type: "checkbox",
    className: props.className,
    name: props.input.name,
    id: props?.input?.name,
    value: props.input?.value ? props.input.value : props.input?.name,
    defaultChecked: meta.uncontrolled ? props.defaultChecked : undefined,
    onChange: props.input.onChange,
    checked: props.checked,
  };

  return (
    <React.Fragment>
      <input {...checboxProps} />
      <label
        className="text-colorB"
        //  className="form__checkbox-label"
        htmlFor={props.input.name}
      >
        {props.label}
      </label>
      {meta.touched && meta.error ? (
        <div className="form__field-error">{meta.error}</div>
      ) : null}
    </React.Fragment>
  );
};

const SelectField = ({
  input,
  meta: { touched, error },
  children,
  values,
  placeholder,
}) => {
  input["value"] = values;
  return (
    <React.Fragment>
      <select
        {...input}
        style={{
          width: "100%",
          height: "47px",
          borderRadius: "8px",
          cursor: "pointer",
        }}
      >
        <option value="-1" selected={values == -1} disabled>
          {placeholder}
        </option>
        {children.map((x, y) => (
          <option key={y} value={x.value}>
            {x.name}
          </option>
        ))}
      </select>
      {touched && error && <div className="form__field-error">{error}</div>}
    </React.Fragment>
  );
};
const TextForTable = (props) => {
  const { meta = {} } = props;

  const inputProps = {
    type: props.type || "text",
    className: "form__input",
    name: props.input.name,
    id: props?.input?.name,
    readOnly: props.readOnly,
    autoFocus: props.autoFocus,
    autoComplete: props.autoComplete,
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    value: meta.uncontrolled ? undefined : props.input.value,
    defaultValue: props.defaultValue,
    onChange: props.input.onChange,
    onKeyUp: props.onKeyUp,
    disabled: props.disabled,
  };

  let errorClassName = meta.error ? "error-input" : "";
  return (
    <div style={{ ...props.style }}>
      <React.Fragment>
        <input
          {...inputProps}
          defaultValue={props.defaultValue}
          className={errorClassName}
        />
      </React.Fragment>
      {meta.touched && meta.error ? (
        <div className="form__field-error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const SimpleText = (props) => {
  const { meta = {} } = props;

  const inputProps = {
    type: props.type,
    className: "table-tr",
    name: props.input.name,
    id: props?.input?.name,
    readOnly: props.readOnly,
    autoFocus: props.autoFocus,
    autoComplete: props.autoComplete,
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    value: meta.uncontrolled ? undefined : props.input.value,
    defaultValue: meta.uncontrolled ? props.defaultValue : undefined,
    onChange: props.input.onChange,
    onKeyUp: props.onKeyUp,
    disabled: props.disabled,
  };

  let errorClassName = meta.error ? "error-input" : "";
  return (
    <div style={{ ...props.style }}>
      <React.Fragment>
        <Input {...inputProps} className={errorClassName} />
      </React.Fragment>
      {meta.touched && meta.error ? (
        <div className="form__field-error">{meta.error}</div>
      ) : null}
    </div>
  );
};
const FormField = (props) => {
  switch (props.type) {
    case "checkbox":
      return <CheckBox {...props} />;
    case "select":
      return <SelectField {...props} />;
    case "textArea":
      return <TextArea {...props} />;
    case "textTable":
      return <TextForTable {...props} />;
    case "simple_text":
      return <SimpleText {...props} />;
    case "input":

    default:
      return <TextField {...props} />;
  }
};

export { TextField, CheckBox, FormField };
