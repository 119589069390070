import { ContentServices } from "../../Services/ContentServices";
import { toast } from "../../components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";
import { reset } from "redux-form";

export const actionTypes = {
    SAVE_CONTENT_LIST: "SAVE_CONTENT_LIST",
    SAVE_CONTENT_DETAIL:"SAVE_CONTENT_DETAIL"
};
export function saveContentList(data) {
  return {
    type: actionTypes.SAVE_CONTENT_LIST,
    data,
  };
}
export function saveContentDetail(data) {
    return {
      type: actionTypes.SAVE_CONTENT_DETAIL,
      data,
    };
  }


export function getContentList(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();
    return ContentServices.getContentList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveContentList(data.data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getContentDetail(params) {
    return (dispatch, getState) => {
      if (params.searchKey == "") {
        dispatch(startLoading());
      }
  
      let state = getState();
      return ContentServices.getContentDetail(params, {
        jwt: state["persist"]["c_temp_user"]["token"],
      })
        .then(async (data) => {
            // console.log(">>>>>bar",data)
          dispatch(saveContentDetail(data.data.data));
          dispatch(stopLoading());
        })
        .catch((error) => {
          if (error) {
            toast.error(error["data"]["message"]);
          }
          dispatch(stopLoading());
        });
    };
  }
  
export function updateContent(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ContentServices.updateContent(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        toast.success(res.data.message);
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

