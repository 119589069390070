import React, { Component } from "react";
// import { Image, Grid, GridColumn, Header } from "semantic-ui-react";
import downlodePlay from "../../../images/download_play.png";
import downlodeios from "../../../images/Download_iso.png";
import { Card, Button, CardTitle,CardImg, Container, Row, Col } from 'reactstrap';


export class GoogleAuthStepOne extends Component {
  render() {
    return (

      <Card body>
     
        
      <Row columns={1} className="stepOne">
        <Col>
        <CardTitle>  Download and Install Google Authentication App</CardTitle>
        
         
        </Col>
        <Col>
        <CardImg centered src={downlodePlay} className ="image-btn card-img" alt="" />
        
        </Col>
        <Col>
        <CardImg centered src={downlodeios} className ="image-btn card-img"  alt="" />
        </Col>
        <Col>
          <CardTitle>This is used for withdraws and security modifications.</CardTitle>

        </Col>
      </Row>
      </Card>
    );
  }
}

export default GoogleAuthStepOne;
