import { ReportServices } from "../../Services/ReportServices";
import { startLoading, stopLoading } from "./loading.action";
import { UserService } from "../../Services/UserServices";
import { toast } from "../../components/Toast/Toast";
export const actionTypes = {
  GET_BALANCE_LIST: "GET_BALANCE_LIST",
  GET_TRADING_REPORT: "GET_TRADING_REPORT",
  GET_DEPOSIT_LIST: "GET_DEPOSIT_LIST",
  GET_WITHDRAW_LIST: "GET_WITHDRAW_LIST",
  GET_ACCOUNTS: "GET_ACCOUNTS"
};

export function saveAccounts(payload) {
  return {
    type: actionTypes.GET_ACCOUNTS,
    data: payload
  };
}
export function saveBalanceList(payload) {
  return {
    type: actionTypes.GET_BALANCE_LIST,
    data: payload
  };
}

export function saveDepositList(payload) {
  return {
    type: actionTypes.GET_DEPOSIT_LIST,
    data: payload
  };
}

export function saveWithdrawList(payload) {
  return {
    type: actionTypes.GET_WITHDRAW_LIST,
    data: payload
  };
}
export function saveTradingReport(payload) {
  return {
    type: actionTypes.GET_TRADING_REPORT,
    data: payload
  };
}
export function getUserBalanceList(data) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return ReportServices.getUserBalanceList(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        dispatch(saveBalanceList(res.data));
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getTradingReports(data) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return ReportServices.getTradingReports(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        dispatch(saveTradingReport(res.data));
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getTransactionWithdrawList(data) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return ReportServices.getTransactionList(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        dispatch(saveWithdrawList(res.data));
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getDepositCostList(data) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return ReportServices.getTokenDepositList(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        dispatch(saveDepositList(res.data));
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getAccountsList() {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(startLoading());
    return UserService.getAccountsList({
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        dispatch(saveAccounts(res.data));
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
