import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

//otc_user
const getOtcList = (params, options) => {
  return fetch("get", `${API_HOST}api/v1/admin/otc/list`, {}, options, params);
};

const getOtcLimit = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/otc/update/userOtcLimit`,
    data,
    options
  );
};
const getOtcLimitByCoin = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/otc/get/userOtcLimit`,
    data,
    options
  );
};
const getTraderList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/otc/all/otctraderlist`,
    {},
    options,
    params
  );
};

const getTraderHistory = (id, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/otc/trader/transactionhistory/${id.user_id.user_id}`,
    {},
    options
  );
};

const getOrderList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/otc/orderlist`,
    {},
    options,
    params
  );
};
const ordertradershistory = (id, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/otc/trader/ordertradershistory/${id}`,
    {},
    options
  );
};
const getCoinLimitSettings = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/getCoinLimitSettings`,
    {},
    options,
    params
  );
};
const setCoinLimitSettings = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/setCoinLimitSettings`,
    data,
    options
  );
};

const setBulkCoinLimitSettings = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/setBulkCoinLimitSettings`,
    data,
    options
  );
};

const cancelOrder = (id, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/otc/cancelOrder/${id}`,
    {},
    options
  );
};
export const OtcService = {
  getOtcList,
  getOtcLimit,
  getTraderList,
  getTraderHistory,
  getOrderList,
  ordertradershistory,
  getCoinLimitSettings,
  setCoinLimitSettings,
  getOtcLimitByCoin,
  cancelOrder,
  setBulkCoinLimitSettings
};
