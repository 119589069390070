import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";

import { Pagination, Dropdown, Form, Input, Label } from "semantic-ui-react";
import CustomHeader from "../../components/Headers/CustomHeader";
import { IMAGE_PATH } from "../../constants/index";
import Header from "components/Headers/Header";
import axios from "axios";
import { API_HOST } from "../../constants/index";
import { FormField } from "../../components/FormField";
import { Link, withRouter } from "react-router-dom";
import {
  getContentDetail,
  updateContent,
} from "../../Redux/actons/content.action";

import {
  getContactQueryList,
  getContactQueryList1,
  updateQuery,
} from "../../Redux/actons/subject.action";
import { Field, reduxForm, change } from "redux-form";
import { SelectField } from "../../components/SelectField";
import { getCoinList } from "../../Redux/actons/user.action";
import { startLoading, stopLoading } from "../../Redux/actons/loading.action";
import { required } from "redux-form-validators";
import CKEditor from "react-ckeditor-component-4";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
export class UpdateQuery extends Component {
  constructor(props) {
    super(props);
    // this.updateContent = this.updateContent.bind(this);
    this.state = {
      content: "",
      image: "",
      ViewMoreADMIN: false,
      ViewMoreUSER: false,
    };
  }

  componentDidMount = async () => {
    let searchKey = this.props.location.search.split("?")[1];
    if (searchKey) {
      localStorage.setItem("id", searchKey);
      await this.props.getContactQueryList1({
        ticket_id: searchKey,
      });
    } else {
      await this.props.getContactQueryList1({
        ticket_id: localStorage.getItem("id"),
      });
    }
    this.setState({ content: this.props.queryList?.message });
  };

  onChange = (evt) => {
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
  };

  updateQueryData = () => {
    this.props.updateQuery({
      id: localStorage.getItem("id"),
      admin_message: this.state.content,
      image: this.state.image,
      status: "more info required",
    });
    this.setState({
      content: "",
      image: "",
    });
  };

  onImageUpload = async (e) => {
    if (e.target.files.length == 1) {
      const formData = new FormData();
      await formData.append("image", e.target.files[0]);
      this.props.startLoading(true);
      var self = this;
      axios
        .post(`${API_HOST}api/v1/admin/wallets/uploadimage`, formData, {
          headers: {
            Authorization: this.props.JwtToken,
          },
        })
        .then(function (response) {
          self.setState({ image: response.data.data });
          self.props.stopLoading();
        })
        .catch(function (error) {
          this.props.stoptLoading();
        });
    } else {
      this.setState({
        image: "",
      });
    }
  };

  render() {
    // console.log('this.location >>>>',this.props.location);
    let searchKey = this.props.location.search.split("?")[1];
    let userDetail = this.props.queryView;
    let Messages = [];
    if (this.props.Messaages) {
      Messages = JSON?.parse(this.props.Messaages);
    }
    console.log(searchKey, ">>>>>>Testings------>>>>", Messages);
    return (
      <div>
        <div>
          <Header headerTitle={`Update Contact Query`} />
        </div>
        <div>
          {/* <Breadcrumb>
            <BreadcrumbItem>
              <Link to="content-management">Content Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {`Update ${this.props.detail.page_title} Content`}
            </BreadcrumbItem>
          </Breadcrumb> */}
        </div>

        <div style={{ padding: 30 }}>
          <div className="userNameList">
            <div className="itemStyle">
              <label style={{ color: "#000" }}>Name</label>
              <span style={{ color: "#000" }}>
                {userDetail?.name}
                {/* {userDetail?.document_number
                  ? userDetail?.document_number
                  : "None"} */}
              </span>
            </div>
            <div className="itemStyle">
              <label style={{ color: "#000" }}>Ticket Id</label>
              <span style={{ color: "#000", textDecoration: "none" }}>
                {userDetail?.id}
                {/* {userDetail?.pancard_number
                  ? userDetail?.pancard_number
                  : "None"} */}
              </span>
            </div>

            {userDetail?.image && (
              <div className="itemStyle">
                <label style={{ color: "#000" }}>Image Query</label>
                <span style={{ color: "#000", textDecoration: "none" }}>
                  <img
                    onClick={() =>
                      window.open(`${IMAGE_PATH}${userDetail?.image}`, "_blank")
                    }
                    style={{
                      cursor: "pointer",
                      width: 150,
                      height: 150,
                    }}
                    src={`${IMAGE_PATH}${userDetail?.image}`}
                  ></img>
                </span>
              </div>
            )}
            {Messages &&
              Messages.map((data) => {
                return (
                  <>
                    {/* {console.log(
                      ">>>>>>Testings------>>>>>>",
                      data?.message.length
                    )} */}
                    {data.type == "user" && (
                      <div
                        className="itemStyle"
                        style={{
                          alignItems: "start",
                          borderBottom: "1px solid #000",
                        }}
                      >
                        <label style={{ color: "#000" }}>User Message</label>
                        <span
                          style={{
                            color: "#000",
                            width: "300px",
                            textDecoration: "none",
                          }}
                        >
                          {this.state.ViewMoreUSER
                            ? data?.message
                            : `${data?.message.slice(0, 150)}${
                                data?.message.length > 150 ? "..." : ""
                              }`}

                          {/* <br> </br> */}
                          <br />
                          <span
                            style={{
                              color: "#2dce89",
                              width: "300px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (this.state.ViewMoreUSER) {
                                this.setState({
                                  ViewMoreUSER: false,
                                });
                              } else {
                                this.setState({
                                  ViewMoreUSER: true,
                                });
                              }
                            }}
                          >
                            {data?.message.length > 150 &&
                              !this.state.ViewMoreUSER &&
                              "View More"}
                            {data?.message.length > 150 &&
                              this.state.ViewMoreUSER &&
                              "Less View"}
                          </span>
                          {data?.image && (
                            <div className="itemStyle">
                              <label style={{ color: "#000" }}>
                                Image Query
                              </label>
                              <span
                                style={{
                                  color: "#000",
                                  textDecoration: "none",
                                }}
                              >
                                <img
                                  onClick={() =>
                                    window.open(
                                      `${IMAGE_PATH}${data?.image}`,
                                      "_blank"
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    width: 150,
                                    height: 150,
                                  }}
                                  src={`${IMAGE_PATH}${data?.image}`}
                                ></img>
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    )}
                    {data.type !== "user" && (
                      <div
                        className="itemStyle"
                        style={{
                          alignItems: "start",
                          borderBottom: "1px solid #000",
                        }}
                      >
                        <label style={{ color: "#000" }}>Admin Reply</label>
                        <span
                          style={{
                            color: "#000",
                            width: "300px",
                            textDecoration: "none",
                          }}
                        >
                          {/*
                          
                          {this.state.ViewMoreUSER
                            ? data?.message
                            : `${data?.message.slice(0, 150)}${
                                data?.message.length > 150 && "..."
                              }`}
                              
                              */}

                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.ViewMoreADMIN
                                ? data?.message
                                : `${data?.message.slice(0, 150)}
                             
                                  `,
                            }}
                          ></div>
                          {/* <br> </br> */}
                          <br />
                          <span
                            style={{
                              color: "#2dce89",
                              width: "300px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (this.state.ViewMoreADMIN) {
                                this.setState({
                                  ViewMoreADMIN: false,
                                });
                              } else {
                                this.setState({
                                  ViewMoreADMIN: true,
                                });
                              }
                            }}
                          >
                            {
                              data?.message.length > 150 &&
                                !this.state.ViewMoreADMIN &&
                                "View More"
                              // : !this.state.ViewMoreADMIN &&
                              //   data?.message.length < 150
                              // ? ""
                              // : "Less View"
                            }
                            {
                              data?.message.length > 150 &&
                                this.state.ViewMoreADMIN &&
                                "Less View"
                              // : !this.state.ViewMoreADMIN &&
                              //   data?.message.length < 150
                              // ? ""
                              // : "Less View"
                            }
                          </span>
                          {data?.image && (
                            <div className="itemStyle">
                              <label style={{ color: "#000" }}>
                                Image Query
                              </label>
                              <span
                                style={{
                                  color: "#000",
                                  textDecoration: "none",
                                }}
                              >
                                <img
                                  onClick={() =>
                                    window.open(
                                      `${IMAGE_PATH}${data?.image}`,
                                      "_blank"
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    width: 150,
                                    height: 150,
                                  }}
                                  src={`${IMAGE_PATH}${data?.image}`}
                                ></img>
                              </span>
                            </div>
                          )}
                        </span>{" "}
                      </div>
                    )}
                  </>
                );
              })}
            {/*    <div className="itemStyle">
              <label>Rejection Comment</label>
              <span style={{ textDecoration: "none" }}>
                {userDetail?.rejection_comment
                  ? userDetail?.rejection_comment
                  : "None"} 
              </span>
            </div>
           <div style={{ width: "100%", height: "50%", padding: 10 }}>
              <span>
                <img
                  onClick={() =>
                    window.open(`${userDetail?.doc_image1}`, "_blank")
                  }
                  style={{
                    cursor: "pointer",
                    width: "50%",
                    height: 200,
                  }}
                  src={
                    userDetail?.doc_image1 ? userDetail?.doc_image1 : "No image"
                  }
                ></img>
              </span>
              <span style={{ cursor: "pointer" }}>
                <img
                  onClick={() =>
                    window.open(`${userDetail.doc_image2}`, "_blank")
                  }
                  style={{ width: "50%", height: 200 }}
                  src={
                    userDetail?.doc_image2 ? userDetail?.doc_image2 : "No image"
                  }
                ></img>
              </span>
              <span style={{ cursor: "pointer" }}>
                <img
                  onClick={() =>
                    window.open(`${userDetail?.doc_image3}`, "_blank")
                  }
                  style={{ width: "50%", height: 200 }}
                  src={
                    userDetail?.doc_image3 ? userDetail?.doc_image3 : "No image"
                  }
                ></img>
              </span>
              <span style={{ cursor: "pointer" }}>
                <img
                  onClick={() =>
                    window.open(`${userDetail?.pancard_image}`, "_blank")
                  }
                  style={{ width: "50%", height: 200 }}
                  src={
                    userDetail?.pancard_image
                      ? userDetail?.pancard_image
                      : "No image"
                  }
                ></img>
              </span> 
            </div>*/}
          </div>
          {/* <Form onSubmit={handleSubmit(this.addBanner)}> */}
          <Form>
            <Form.Group>
              <Form.TextArea
                rows={20}
                cols={500}
                label="Description"
                placeholder="Description"
                value={this.state.content}
                onChange={(e, data) =>
                  this.setState({ content: e.target.value })
                }
              />
            </Form.Group>
            <Form.Field>
              {/* <CKEditor
                activeClass="p10"
                content={this.state.content}
                events={{
                  blur: this.onBlur,
                  afterPaste: this.afterPaste,
                  change: this.onChange,
                }}
              /> */}
              {/* <Input
                placeholder="Message"
                onChange={
                  this.onChange
                  // this.setState({ searchKey: e.target.value, page: 1 }, () => {
                  //   if (this.state.searchKey == "") {
                  //     this.onSearchList();
                  //   }
                  // });
                }
              ></Input> */}
            </Form.Field>
            {/* {this.state.isCoin && ( */}

            <Form.Group>
              <Form.Field>
                <Label for="image">Upload Image (328*180)</Label>
                <input
                  accept="image/*"
                  type="file"
                  onChange={this.onImageUpload}
                  // required
                />
              </Form.Field>
            </Form.Group>
            <Form.Field className="addBtn">
              <Button
                color="primary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
              <Button
                type="submit"
                color="success"
                onClick={() => this.updateQueryData()}
              >
                Update
              </Button>
            </Form.Field>
            {/* // )} */}
          </Form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    detail: state.content.detail,
    queryList: state?.subjectList?.queryList?.history,
    queryView: state?.subjectList?.queryView,
    Messaages: state?.subjectList?.queryView?.message,
    JwtToken: state.persist.c_user.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContactQueryList1: (data) => dispatch(getContactQueryList1(data)),
    getContentDetail: (data) => dispatch(getContentDetail(data)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),

    updateContent: (data) => dispatch(updateContent(data)),
    updateQuery: (data) => dispatch(updateQuery(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "UpdateQueryForm" })(UpdateQuery));
