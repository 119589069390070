import React, { Component } from "react";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import CustomHeader from "components/Headers/CustomHeader";

import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import { API_HOST } from "../../../constants/index";
import { FormField } from "../../../components/FormField";
import { required } from "redux-form-validators";
import {
  addAnnouncement,
  updateAnnouncement,
} from "../../../Redux/actons/announcement.action";
import { Link } from "react-router-dom";
import {
  startLoading,
  stopLoading,
} from "../../../Redux/actons/loading.action";

export class AddAnnouncement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: "",
    };
  }
  componentDidMount = () => {
    this.initialiseUpdateForm();
    this.checkRouteValid();
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      var isfound = false;
      // check user Permissions if route accessible
      await this.props.permissions.forEach((element) => {
        if (element.title == "Announcement Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };
  initialiseUpdateForm = () => {
    if (Object.keys(this.props.editDetails).length !== 0) {
      this.props.dispatch(
        change("AddAnnouncementForm", "message", this.props.editDetails.message)
      );
      this.props.dispatch(
        change("AddAnnouncementForm", "subject", this.props.editDetails.subject)
      );
    }
  };

  onImageUpload = async (e) => {
    if (e.target.files.length == 1) {
      const formData = new FormData();
      await formData.append("image", e.target.files[0]);
      this.props.startLoading(true);
      var self = this;
      axios
        .post(`${API_HOST}api/v1/admin/wallets/uploadimage`, formData, {
          headers: {
            Authorization: this.props.JwtToken,
          },
        })
        .then(function (response) {
          self.setState({ image: response.data.data });
          self.props.stopLoading();
        })
        .catch(function (error) {
          this.props.stoptLoading();
        });
    } else {
      this.setState({
        image: "",
      });
    }
  };

  addAnnouncement = (values) => {
    const param = {
      subject: values.subject,
      message: values.message,
      image: this.state.image,
    };

    if (Object.keys(this.props.editDetails).length == 0) {
      //  add
      this.props.addAnnouncement(param);
    } else {
      // update
      this.props.updateAnnouncement(
        param,
        this.props.editDetails.id,
        this.props.history
      );
    }
  };

  render() {
    console.log("ALLPROPS", this.props);
    let { handleSubmit } = this.props;
    console.log(this.state);
    return (
      <div>
        <CustomHeader />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="announcement-list">Announcement Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{Object.keys(this.props.editDetails).length == 0 ? "Add Announcement" : "Update Announcement"}</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div style={{ padding: 100 }}>
          <Form onSubmit={handleSubmit(this.addAnnouncement)}>
            <FormGroup>
              <Label for="subject">Subject</Label>

              <Field
                name="subject"
                component={FormField}
                type="text"
                validate={[required()]}
                placeholder="Subject"
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Message</Label>

              <Field
                name="message"
                component={FormField}
                type="textarea"
                placeholder="Enter message"
                validate={[required()]}
              />
            </FormGroup>
            <FormGroup>
              <Label for="image">Upload Image</Label>
              <input type="file" onChange={this.onImageUpload} />
            </FormGroup>

            <div className="text-center">
              <Button className="my-4" color="primary" type="submit">
                {Object.keys(this.props.editDetails).length !== 0
                  ? "Update"
                  : "Add"}
              </Button>
              <Link to="announcement-list">
                <Button color="success">Back</Button>
              </Link>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    editDetails: state.announcement.editDetails,
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
    JwtToken: state.persist.c_user.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addAnnouncement: (data) => dispatch(addAnnouncement(data)),
    updateAnnouncement: (data, id, history) =>
      dispatch(updateAnnouncement(data, id, history)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
  };
};

export default reduxForm({ form: "AddAnnouncementForm" })(
  connect(mapStateToProps, mapDispatchToProps)(AddAnnouncement)
);
