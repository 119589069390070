import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import "./index.scss";
import { FormField } from "../../components/FormField";
import { date, required } from "redux-form-validators";
import {
  getPairList,
  enableDisabelPair,
  addMinMaxAmount,
  getAllActiveTradeCurrencies,
} from "../../Redux/actons/pairCoin.action";
import { openCloseModal } from "../../Redux/actons/SubAdmin.action";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import {
  Pagination,
  Dropdown,
  Form,
  Select,
  Checkbox,
} from "semantic-ui-react";
import swapIcon from "../../images/swap.png";
import SetPairAmount from "./SetPairAmount";
import { IMAGE_PATH } from "../../constants/index";
import { setType } from "utils/utils";
import { addCoinPair, getCoinList } from "../../Redux/actons/token.action";
import { toast } from "components/Toast/Toast";
import Alert from "reactstrap/lib/Alert";
var Filteroptions = [{ key: "e", text: "None", value: "" }];
export class PairCoinManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      openCoinPair: false,
      setPairAmountModal: false,
      order: "",
      sortBy: "",
      coin_family: "",
      isSendColdWallet: false,
      order: "asc",
      selectPairId: null,
      coinId: "",
      totalAmount: "",
      max_sell_value: 0,
      min_sell_value: 0,
      coin_1_symbol: "",
      currency_id: "",
      type: "",
      coinDropdown: [],
      pairCoinDetail: [],
      selectedCoin1: "",
      selectedCoin2: "",
      buySellOption: "",
      buyOption: "",
      sellOption: "",
      // minAmount: null,
      // maxAmount: null
    };
  }

  // openModal = (data) => {
  //   console.log("userData", data);
  //   this.setState({ userData: data }, () => {
  //     this.toggleModal();
  //   });
  // };

  closeSetPairAmountModal = () => {
    this.setState({ setPairAmountModal: false });
    this.getPairList();
  };

  toggleModal = () => {
    this.setState({ setPairAmountModal: !this.state.setPairAmountModal });
  };

  componentDidMount = () => {
    this.getPairList();
    this.getCoinList();
    this.props
      .getAllActiveTradeCurrencies()
      .then((res) => {
        let i = 1;
        this.props.activeTradeCurrencies.forEach((element) => {
          let text = element.symbol.toUpperCase();
          if (element.is_token == 1) {
            if (element.coin_family == 1) {
              text = element.symbol.toUpperCase() + "(ERC-20)";
            } else if (element.coin_family == 3) {
              text = element.symbol.toUpperCase() + "(BEP-20)";
            } else if (element.coin_family == 4) {
              text = element.symbol.toUpperCase() + "(TRC-20)";
            } else if (element.coin_family == 5) {
              element.text = element.symbol.toUpperCase() + "(MATIC)";
            } else {
              text = element.symbol.toUpperCase();
            }
          }
          Filteroptions.push({
            key: i,
            text: text,
            value: element.currency_id,
          });
          i++;
        });
      })
      .catch((error) => {
        // error
      });
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.coin_list != this.props.coin_list) {
      console.log("nextProps >>", nextProps.coin_list);
      this.coinDropdown(nextProps.coin_list);
    }
  };
  componentWillUnmount = () => {
    this.props.openCloseModal();
    Filteroptions = [{ key: "e", text: "None", value: "" }];
  };

  coinDropdown = (cn_data) => {
    let list = [];
    cn_data.map(async (data) => {
      // is_token===1 ,coin_family
      let family = "";
      if (data?.is_token === 1) {
        switch (data?.coin_family) {
          case "1":
            family = "eth";
            break;
          case "2":
            family = "btc";
            break;
          case "3":
            family = "bnb";
            break;
          case "4":
            family = "trx";
            break;
          case "5":
            family = "matic";
            break;
        }
      }
      let text = data?.symbol.concat(family);
      let cn = {
        key: data?.currency_id,
        text: text,
        value: data?.currency_id,
        xyx: text,
      };
      list.push(cn);
    });
    this.setState({ coinDropdown: list });
  };

  getCoinList = () => {
    this.props.getCoinList({});
  };
  getPairList = () => {
    const data = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      currency_id: this.state.currency_id,
    };
    this.props.getPairList(data).then((res) => {
      this.setState({ pairCoinDetail: res });
    });
  };

  listByCurrency = (e, data) => {
    console.log("data >>", data);
    this.setState({ currency_id: data.value }, () => {
      this.getPairList();
    });
  };

  enableDisablePair = (data, id) => {
    let sts = data?.status == "enabled" ? "disabled" : "enabled";
    const buttonState = { status: sts, id: id };
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
    };
    this.props.enableDisabelPair(buttonState, id, params);
  };

  onEditPair = (id, data) => {
    // this.props.history.push(`edit-pair-coin/${id}`)
    this.props.history.push({
      pathname: `edit-pair-coin/${id}`,
      state: data,
    });
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getPairList();
    });
  };

  onSearchList = (search) => {
    // this.setState({ searchKey: search }, () => {
    this.getPairList();
    // });
  };

  checkTokenOne = (coin_family, is_token) => {
    let token_type = "";
    if (coin_family == 1) {
      token_type = is_token == 1 ? "(ERC20)" : "";
    }
    if (coin_family == 3) {
      token_type = is_token == 1 ? "(BEP20)" : "";
    }
    if (coin_family == 4) {
      token_type = is_token == 1 ? "(TRC20)" : "";
    }
    if (coin_family == 5) {
      token_type = is_token == 1 ? "(MATIC)" : "";
    }
    return token_type;
  };
  checkTokenTwo = (coin_family, is_token) => {
    let token_type = "";
    if (coin_family == 1) {
      token_type = is_token == 1 ? "(ERC20)" : "";
    }
    if (coin_family == 3) {
      token_type = is_token == 1 ? "(BEP20)" : "";
    }
    if (coin_family == 4) {
      token_type = is_token == 1 ? "(TRC20)" : "";
    }
    if (coin_family == 5) {
      token_type = is_token == 1 ? "(MATIC)" : "";
    }
    return token_type;
  };

  onSubmit = () => {
    if (!this.state.selectedCoin1) {
      toast.error("Buy coin is required");
      return;
    }
    if (!this.state.selectedCoin2) {
      toast.error("Sell coin is required");
      return;
    }
    if (!this.state.buyOption && !this.state.sellOption) {
      toast.error("One of Buy or Sell pair is required");
      return;
    }
    let cn1 = this.props.coin_list.filter((dt) => {
      return dt.currency_id === this.state.selectedCoin1;
    });
    let cn2 = this.props.coin_list.filter(
      (dt) => dt.currency_id === this.state.selectedCoin2
    );

    let datas = {
      coin_1_id: cn1[0]?.currency_id,
      coin_1_type: cn1[0]?.currency_type,
      coin_2_id: cn2[0]?.currency_id,
      coin_2_type: cn2[0]?.currency_type,
      buy_pair: this.state.buyOption ? 1 : 0,
      sell_pair: this.state.sellOption ? 1 : 0,
    };

    this.props
      .addCoinPair(datas)
      .then((res) => {
        this.setState({
          openCoinPair: false,
          selectedCoin1: "",
          selectedCoin2: "",
          buyOption: "",
          sellOption: "",
        });
        this.getPairList();
      })
      .catch((error) => {});
  };

  closeModal = () => {
    this.setState({ openCoinPair: false });
  };
  render() {
    console.log("Filteroptions", Filteroptions);
    let { handleSubmit, coin_list } = this.props;
    let { openCoinPair } = this.state;
    return (
      <div>
        <Header headerTitle="Pair Coin Management" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="trade-management">Trade Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Pair Coin Management</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        className="selectUserStyle"
                        options={Filteroptions}
                        placeholder="Filter by coin"
                        selection
                        onChange={(e, data) => this.listByCurrency(e, data)}
                      />

                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.setState({ openCoinPair: true })}
                      >
                        Add coin pair
                      </Button>

                      {/* <Link to="swap-history">
                        <Button color="success btnMamagePermission">
                          Swap History
                        </Button>
                      </Link> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Coin 1</th>
                        <th scope="col">Coin 1 Image</th>
                        <th scope="col"></th>
                        <th scope="col">Coin 2</th>
                        <th scope="col">Coin 2 Image</th>
                        <th scope="col">Pair name</th>
                        <th scope="col">Limit Type</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(this.props.pairCoinList?.history) &&
                      this.props.pairCoinList?.history?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        Array.isArray(this.props.pairCoinList?.history) &&
                        this.props.pairCoinList?.history?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            {/* <td>{usersList.pair_name.toUpperCase()}</td> */}
                            <td>
                              {data.coin_1_symbol.toUpperCase()}{" "}
                              {this.checkTokenOne(
                                data.coin_1_coin_family,
                                data.coin_1_is_token
                              )}
                            </td>
                            <td>
                              {data.coin_1_image !== null && (
                                <img
                                  src={`${IMAGE_PATH}${data.coin_1_image}`}
                                  alt="logo"
                                  style={{ height: 40, width: 40 }}
                                />
                              )}
                              {data.coin_1_image == null && "N/A"}
                            </td>
                            <td>
                              <img
                                src={`${swapIcon}`}
                                alt=""
                                style={{ height: 30, width: 30 }}
                              />
                            </td>
                            <td>
                              {data.coin_2_symbol.toUpperCase()}{" "}
                              {this.checkTokenTwo(
                                data.coin_2_coin_family,
                                data.coin_2_is_token
                              )}
                            </td>
                            <td>
                              {data.coin_2_image !== null && (
                                <img
                                  src={`${IMAGE_PATH}${data.coin_2_image}`}
                                  alt="logo"
                                  style={{ height: 40, width: 40 }}
                                />
                              )}
                              {data.coin_2_image == null && "N/A"}
                            </td>
                            <td>{data.pair_name.toUpperCase()}</td>
                            <td>
                              {data.limit_type != null
                                ? setType(data?.limit_type)
                                : "-"}
                            </td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color={
                                    data?.status === "enabled"
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={(e) => {
                                    setTimeout(() => {
                                      this.enableDisablePair(data, data.id);
                                    }, 200);
                                  }}
                                >
                                  {data?.status == "enabled"
                                    ? "Disable"
                                    : "Enable"}
                                </Button>
                              </span>
                              <span className="mb-0 ml-3 text-sm">
                                <Button
                                  color={"warning"}
                                  onClick={() => {
                                    this.toggleModal();
                                    this.setState({
                                      selectPairId: data.id,
                                      max_sell_value: data.max_sell_value,
                                      min_sell_value: data.min_sell_value,
                                      coin_1_symbol: data.coin_1_symbol,
                                      type:
                                        data?.limit_type != null
                                          ? data?.limit_type
                                          : "",
                                    });
                                  }}
                                >
                                  Configuration
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.pairCoinList?.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.pairCoinList?.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <SetPairAmount
          closeSetPairAmountModal={this.closeSetPairAmountModal}
          selectPairId={this.state.selectPairId}
          setPairAmountModal={this.state.setPairAmountModal}
          page={this.state.page}
          perPage={this.state.perPage}
          searchKey={this.state.searchKey}
          min_sell_value={this.state.min_sell_value}
          max_sell_value={this.state.max_sell_value}
          coin_1_symbol={this.state.coin_1_symbol}
          type={this.state.type}
        />
        <Modal
          isOpen={openCoinPair}
          toggle={this.closeModal}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.closeModal}>
            <span style={{ color: "white" }}>Add Coin Pair</span>
          </ModalHeader>
          <ModalBody>
            <Form>
              <Form.Group>
                <Dropdown
                  style={{ textTransform: "uppercase" }}
                  className="selectUserStyle myupper"
                  placeholder="Select Coin"
                  value={this.state.selectedCoin1}
                  search={true}
                  selection
                  onChange={(e, d) => {
                    console.log("dlskfjdlskfjdslkfjdslf : ", e, d);
                    if (this.state.selectedCoin2 !== d.value) {
                      this.setState({ selectedCoin1: d.value });
                    }
                  }}
                  options={this.state.coinDropdown}
                />
                <Dropdown
                  style={{ textTransform: "uppercase" }}
                  className="selectUserStyle myupper"
                  placeholder="Select Coin"
                  value={this.state.selectedCoin2}
                  search={true}
                  selection
                  onChange={(e, d) => {
                    if (this.state.selectedCoin1 !== d.value) {
                      this.setState({ selectedCoin2: d.value });
                    }
                  }}
                  options={this.state.coinDropdown}
                />
              </Form.Group>
              <Form.Group>
                <Checkbox
                  style={{ marginRight: "5px" }}
                  className="buysellOption"
                  checked={this.state.buyOption ? true : false}
                  onChange={(e, d) => {
                    this.setState({ buyOption: d.checked });
                  }}
                />
                <label style={{ marginRight: "20px", color: "white" }}>
                  Buy
                </label>
                <Checkbox
                  style={{ marginRight: "5px" }}
                  className="buysellOption"
                  checked={this.state.sellOption ? true : false}
                  onChange={(e, d) => {
                    this.setState({ sellOption: d.checked });
                  }}
                />
                <label style={{ color: "white" }}>Sell</label>
              </Form.Group>

              <Form.Field className="addBtn">
                <Button color="success" onClick={() => this.onSubmit()}>
                  Add
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log("state----------pairCoin", state.pairCoinList);
  return {
    pairCoinList: state.pairCoinList.pairCoinList,
    total: state.pairCoinList.pairCoinList.total,
    activeTradeCurrencies: state.pairCoinList.activeTradeCurrencies,
    coin_list: state.token.coin_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (data) => dispatch(reset(data)),
    openCloseModal: () => dispatch(openCloseModal(true)),
    getPairList: (data) => dispatch(getPairList(data)),
    getCoinList: (data) => dispatch(getCoinList(data)),
    addMinMaxAmount: () => dispatch(addMinMaxAmount()),
    enableDisabelPair: (data, id, params) =>
      dispatch(enableDisabelPair(data, id, params)),
    getAllActiveTradeCurrencies: () => dispatch(getAllActiveTradeCurrencies()),
    addCoinPair: (data) => dispatch(addCoinPair(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PairCoinManagement)
);
