import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Form, Grid, Header, List } from "semantic-ui-react";
import "./GoogleAuth.scss";
import GoogleAuthActive from "../AccountSetting/GoogleAuthActive/GoogleAuthActive";
import { setGoogleAuthStep } from "../../Redux/actons/account.action";
// import AdminSetting from "../AccountSetting/AdminSetting/AdminSetting";
import { connect } from "react-redux";
import { CustomHeader } from "components/Headers/CustomHeader";
// import SetAdminSetting from "./SetAdminSetting/SetAdminSetting";

class GoogleAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  goToNextStep = step => {
    if (step === 1 || step === 2) {
      this.props.setGoogleAuthStep(step + 1);
    } else {
      alert("API CALL");
    }
  };
  goToPrevStep = step => {
    if (step === 2 || step === 3) {
      this.props.setGoogleAuthStep(step - 1);
    }
  };
  render() {
    return (
      <div className="mainBlock">
        <CustomHeader />

        <Container divided="horizontal">
          <Row columns={2}>
            <Col>
              <List divided verticalAlign="middle">
                <GoogleAuthActive
                  goToStep={this.goToNextStep}
                  goToPrevStep={this.goToPrevStep}
                ></GoogleAuthActive>
                {/* <SetAdminSetting></SetAdminSetting> */}
                {/* <AdminSetting></AdminSetting> */}
              </List>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setGoogleAuthStep: step => dispatch(setGoogleAuthStep(step))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleAuth);
