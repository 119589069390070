import React from "react";
import { Form, Navbar, Nav, Container, Media,Modal,ModalHeader,Button,ModalFooter,ModalBody } from "reactstrap";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { logoutUser } from "../../Redux/actons/user.action";
import { getAdminNotification } from "../../Redux/actons/notification.action";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'
import { socket } from "../../index";
import "./navbar.scss";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNoticeNav: false,
      isOpen:false,
      notificationLimit: 10
    };
  }
  allNotification = () => {
    let data = {
      limit: ""
    };
    this.props.getAdminNotification(data);
  };

  allNotificationWithLimit = () => {
    let data = {
      limit: this.state.notificationLimit
    };
    this.props.getAdminNotification(data);
  };
  componentDidMount = () => {
    socket.on("notification", res => {});

    this.props.history.listen((location, action) => {
      this.setState({ isNoticeNav: false });
    });
  };
  logoutMe = async () => {
    confirmAlert({
      title: "Logout",
      message:
        "Are you sure to logout?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>this.props.onLogoutUser()
        },
        {
          label: "Cancel",
          onClick: () =>""
        }
      ],

      closeOnEscape: true,
  closeOnClickOutside: true,
  willUnmount: () => {},
  afterClose: () => {},
  onClickOutside: () => {},
  onKeypressEscape: () => {},
  overlayClassName: "overlay-custom-class-name"

    });
  };

  toggleBox = () => {
    this.allNotificationWithLimit();
    this.setState(prevState => ({ isNoticeNav: !prevState.isNoticeNav }));
  };

  navigateTo = type => {
    switch (type) {
      case "trnx_withdraw": {
        this.props.history.push("withdraw");

        break;
      }

      case "user": {
        this.props.history.push("user-management");

        break;
      }
      case "kyc_level_zero" || "kyc_level_one" || "kyc_level_two": {
        this.props.history.push("kyc-management");
        break;
      }
      case "announcements": {
        this.props.history.push("announcement-list");
        break;
      }
    }
  };
  render() {
    const { isNoticeNav } = this.state;
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid style={{ marginLeft: "12px", marginRight: "12px" }}>
            <span
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </span>

            <Nav className="align-items-center d-none d-md-flex" navbar>
              <Media className="align-items-center">
                <Media className="ml-2 d-none d-lg-block">
                  <ul className="rigthNav">
                    <li>
                      {/* <span className="badge">0</span>
                      <a className={`btn bg-yellow`} onClick={this.toggleBox}>
                        <span style={{ color: "#fff" }}>
                          <i className="fas fa-bell"></i>
                        </span> */}
                      {/* </a> */}
                      <div
                        className={`noticeDrop ${
                          isNoticeNav ? "isShow" : "isHide"
                        }`}
                      >
                        <ul className="alertList">
                          {this.props.notification
                            .filter(
                              data =>
                                data.type != "trnx_deposit" ||
                                data.type != "wallets" ||
                                data.type != "coin" ||
                                data.type != "contact_us"
                            )
                            .map((data, index) => (
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => this.navigateTo(data.type)}
                              >
                                <span>{data.message} </span>
                                {/* <a className="btnView">View</a> */}
                              </li>
                            ))}
                        </ul>
                        <a
                          className="viewAll"
                          onClick={() => this.allNotification()}
                        >
                          View All
                        </a>
                      </div>
                    </li>
                    <li>
                      <a
                        className="btn btn-primary"
                        onClick={() => this.logoutMe()}
                      >
                        <span style={{ color: "#fff" }}>
                          <i className="fas fa-sign-out-alt"></i> Logout
                        </span>
                      </a>
                    </li>
                  </ul>
                </Media>
              </Media>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    notification: state.notification.allNotification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogoutUser: () => dispatch(logoutUser()),
    getAdminNotification: data => dispatch(getAdminNotification(data))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminNavbar)
);
