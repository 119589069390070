import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
} from "reactstrap";
import { getSwapHistoryList } from "../../../Redux/actons/pairCoin.action";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Pagination, Dropdown } from "semantic-ui-react";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

export class SwapHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      order: "asc",
    };
  }
  componentDidMount = () => {
    this.getPairFilteredList();
  };

  getPairFilteredList = () => {
    let data = {
      // searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      // filterType: this.state.filterType,
    };
    this.props.getPairFilteredList(data);
  };

  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getPairFilteredList();
    });
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getPairFilteredList();
    });
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Swap History" />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Pair Name</th>
                        <th scope="col">Order Type</th>
                        <th scope="col">Markup Price</th>
                        <th scope="col">Fee</th>
                        <th scope="col">Conversion Rate</th>
                        <th scope="col">Converted Value</th>
                        <th scope="col">Order Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.pairCoinList.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.pairCoinList.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.pair_name.toUpperCase()}</td>
                            <td>{data.order_type.toUpperCase()}</td>
                            <td>{data.markup_price}</td>
                            <td>{data.fee}</td>
                            <td>{data.conversionRate}</td>
                            <td>{data.convertValue}</td>
                            <td>{data.order_status}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    pairCoinList: state.pairCoinList.swapHistory,
    total: state.pairCoinList.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPairFilteredList: (data) => dispatch(getSwapHistoryList(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SwapHistory)
);
