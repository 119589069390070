import { UserService } from "../../Services/UserServices";
import { startLoading, stopLoading } from "./loading.action";
import { getCoinList } from "./user.action";
import { reset } from "redux-form";

import { toast } from "../../components/Toast/Toast";
import {
  enableDisableGoogle,
  enableDisableColdWalletGoogle,
} from "./user.action";
export const actionTypes = {
  GOOGLE_AUTH_FORM_ACTIVE_DEACTIVE: "GOOGLE_AUTH_FORM_ACTIVE_DEACTIVE",
  SET_GOOGLE_AUTH_STEP: "SET_GOOGLE_AUTH_STEP",
  SAVE_GOOGLE_AUTH_KEY: "SAVE_GOOGLE_AUTH_KEY",
  OPEN_CLOSE_MODAL: "OPEN_CLOSE_MODAL",
  OPEN_GOOGLE_MODAL_DISABLE: "OPEN_GOOGLE_MODAL_DISABLE",
  OPEN_GOOGLE_MODAL_ENABLE: "OPEN_GOOGLE_MODAL_ENABLE",
  CHANGE_PASSWORD_MODAL: "CHANGE_PASSWORD_MODAL",
  OPEN_CHANGE_PASSWORD_AUTH: "OPEN_CHANGE_PASSWORD_AUTH",
  OPEN_CHNAGE_PASSWORD_2FA_MODAL: "OPEN_CHNAGE_PASSWORD_2FA_MODAL",
  SHOW_CHANGE_PASSWORD_AUTh_TOKEN_FORM: "SHOW_CHANGE_PASSWORD_AUTh_TOKEN_FORM",
  GET_ADMIN_SETTING_DETAILS: "GET_ADMIN_SETTING_DETAILS",
  OPEN_COLD_WALLET_GOOGLE_MODAL_DISABLE:
    "OPEN_COLD_WALLET_GOOGLE_MODAL_DISABLE",
  OPEN_COLD_WALLET_GOOGLE_MODAL_ENABLE: "OPEN_COLD_WALLET_GOOGLE_MODAL_ENABLE",
  SET_COLD_WALLET_GOOGLE_AUTH_STEP: "SET_COLD_WALLET_GOOGLE_AUTH_STEP",
  SAVE_COLD_WALLET_GOOGLE_AUTH_KEY: "SAVE_COLD_WALLET_GOOGLE_AUTH_KEY",
};

/*
 * Select/change Security option
 */
export function activeDeactiveGoogleAuthForm(payload) {
  return {
    type: actionTypes.EMAIL_AUTH_FORM_ACTIVE,
    data: payload,
  };
}
export function setGoogleAuthStep(step) {
  return {
    type: actionTypes.SET_GOOGLE_AUTH_STEP,
    data: step,
  };
}
export function saveGoogleAuthKey(data) {
  return {
    type: actionTypes.SAVE_GOOGLE_AUTH_KEY,
    data: data,
  };
}
export function openGoogleDisableModal(payload) {
  return {
    type: actionTypes.OPEN_GOOGLE_MODAL_DISABLE,
    data: payload,
  };
}
export function openGoogleEnableModal(payload) {
  return {
    type: actionTypes.OPEN_GOOGLE_MODAL_ENABLE,
    data: payload,
  };
}

export function openChangePasswordModal(payload) {
  return {
    type: actionTypes.CHANGE_PASSWORD_MODAL,
    data: payload,
  };
}
export function openChangePasswordAuthModal(payload) {
  return {
    type: actionTypes.OPEN_CHANGE_PASSWORD_AUTH,
    data: payload,
  };
}
export function openChangePassword2faModal(payload) {
  return {
    type: actionTypes.OPEN_CHNAGE_PASSWORD_2FA_MODAL,
    data: payload,
  };
}

export function open2FaTokenForm(payload) {
  return {
    type: actionTypes.SHOW_CHANGE_PASSWORD_AUTh_TOKEN_FORM,
    data: payload,
  };
}

export function saveFeeSettingDetails(payload) {
  return {
    type: actionTypes.GET_ADMIN_SETTING_DETAILS,
    data: payload,
  };
}

export function openColdWalletGoogleDisableModal(payload) {
  return {
    type: actionTypes.OPEN_COLD_WALLET_GOOGLE_MODAL_DISABLE,
    data: payload,
  };
}
export function openColdWalletGoogleEnableModal(payload) {
  return {
    type: actionTypes.OPEN_COLD_WALLET_GOOGLE_MODAL_ENABLE,
    data: payload,
  };
}

export function setColdWalletGoogleAuthStep(step) {
  return {
    type: actionTypes.SET_COLD_WALLET_GOOGLE_AUTH_STEP,
    data: step,
  };
}
export function saveColdWalletGoogleAuthKey(data) {
  return {
    type: actionTypes.SAVE_COLD_WALLET_GOOGLE_AUTH_KEY,
    data: data,
  };
}
export function resetPassword(data, history) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.changePassword(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        toast.success(data["data"]["message"]);
        dispatch(reset("ResetPasswordForm"));
        dispatch(openChangePasswordModal(false));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
//here i am

export function getGoogleImageSecretKey(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getGoogleImageSecretKey(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveGoogleAuthKey(data.data.data));
        dispatch(openGoogleEnableModal(true));
        // toast.success(data["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
//hello
export function enableDisableGoogleAuth(datas, type) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.enableDisableGoogleAuth(datas, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(enableDisableGoogle(type, datas.google_secret_key));
        dispatch(openGoogleDisableModal(false));
        dispatch(openGoogleEnableModal(false));
        dispatch(setGoogleAuthStep(1));
        toast.success(data["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function adminVerify(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();

    return UserService.adminVerify(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(openChangePasswordAuthModal(false));
        toast.success(data["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getAdminSettings(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getAdminSettings(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveFeeSettingDetails(data.data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function updateAdminSetting(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.updateAdminSettings(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function updateWithdrawLimit(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.updateWithdrawLimitBulk(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getCoinList());
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function updateWithdrawFee(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.updateWithdrawFee(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getCoinList());
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//set transaction fee limit
export function updateTransactionFeeLimit(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.updateTransactionLimit(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getCoinList());
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function uploadImage(data, coin) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.uploadImage(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        let uploadData = {
          coin_id: coin.coin_id,
          image: data.data.data,
        };
        dispatch(uploadFinalImage(uploadData));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function uploadFinalImage(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.uploadFinalImage(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getCoinList());

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
/** get cold wallet google image secret */
export function getColdWalletGoogleImageSecretKey(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getWalletColdGoogleImageSecretKey(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveColdWalletGoogleAuthKey(data.data.data));
        dispatch(openColdWalletGoogleDisableModal(true));
        // toast.success(data["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function enableDisableColdWalletGoogleAuth(datas, type) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.enableDisableColdWalletGoogleAuth(datas, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(enableDisableColdWalletGoogle(type, datas.google_secret_key));
        dispatch(openColdWalletGoogleDisableModal(false));
        dispatch(openColdWalletGoogleEnableModal(false));
        dispatch(setColdWalletGoogleAuthStep(1));
        toast.success(data["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
