import React, { Component } from "react";
import CustomHeader from "components/Headers/CustomHeader";
import { Form, label, Button } from "semantic-ui-react";

import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../components/FormField";
import { SelectField } from "../../components/SelectField";
import { email, required } from "redux-form-validators";
import {
  getCoinList,
  updateCoinPriceToUSD,
} from "../../Redux/actons/user.action";
import { amountLimit } from "../../validators/customValidator";
import { Link } from "react-router-dom";
import SwapTransactionFee from "../SwapManagement/SwapTransactionFee/SwapTransactionFee";
const actions = [
  { key: "m", text: "Activate", value: 1 },
  { key: "f", text: "Inactivate", value: 2 },
];
export class PriceManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCoin: false,
      defaultType: "",
      defaultAction: 1,
    };
  }

  componentDidMount = () => {
    this.props.getCoinList();
  };

  getDetails = (e, data) => {
    this.props.coinsList.forEach((element) => {
      if (element.value == data) {
        this.props.dispatch(
          change("TransactionFeeForm", "usd_price", element.usd_price)
        );
      }
    });
    this.setState({ isCoin: true, defaultType: data });
  };

  updateForm = (values) => {
    console.log("PRICE", values);
    this.props.updateCoinPriceToUSD(values);
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <CustomHeader />

        <SwapTransactionFee></SwapTransactionFee>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    feeSettingDetails: state.account.feeSettingDetails,
    coinsList: state.user.coinsListFiltered,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCoinList: () => dispatch(getCoinList()),
    updateCoinPriceToUSD: (data) => dispatch(updateCoinPriceToUSD(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "TransactionFeeForm" })(PriceManagement));
