import Index from "./views/Dashboard/Index";
import Profile from "views/examples/Profile.js";

import UserManagement from "./views/UserManagement/User/UserManagement";
import TransactionFeeSetting from "./views/TransactionFeeManagement/TransactionFeeSetting/TransactionFeeSetting";
import KycManagement from "./views/KycManagement/KycManagement";
import AuthForm from "./views/TwoFactorauth/AuthForm";
import TwoFactorauth from "./views/TwoFactorauth/TwoFactorauth";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import EmailConfirmation from "./views/ForgotPassword/EmailConfirmation";
import SubmittedKyc from "./views/UserManagement/SubmittedKyc/SubmittedKyc";
import ApprovedKyc from "./views/UserManagement/ApprovedKyc/ApprovedKyc";
import LoginFormPage from "views/Login/LoginFormPage";
import AccountSetting from "./views/AccountSetting/AccountSetting";
import GoogleAuth from "./views/GoogleAuth/GoogleAuth";
import WithdrawContainer from "./views/Withdraw/WithdrawContainer/WithdrawContainer";
import WithDrawDepositSetting from "./views/WithDrawDepositSetting/WithDrawDepositSetting";
import OtcLevelSetting from "./views/OtcManagement/OtcLevelSetting/OtcLevelSetting";
// import TransactionFeeManagement from "./views/TransactionFeeManagement/TransactionFeeManagement";
import TransactionFeeManagement from "./views/TransactionFeeManagement/TabDevideHandle";
import OtcListManagement from "./views/OtcManagement/OtcListManagement";
import OtcOrderListManagement from "./views/OtcManagement/OtcOrderListManagement";
import OtcTraderManagement from "./views/OtcManagement/OtcTraderManagement";
import SwapManagement from "./views/SwapManagement/SwapManagement";
import SubAdminManagement from "./views/SubAdminManagement/SubAdminManagement";
import AddNewSubAdmin from "./views/SubAdminManagement/AddNewSubAdmin/AddNewSubAdmin";
import ReportsManagement from "./views/ReportsManagement/ReportsManagement";
import TradingReports from "./views/ReportsManagement/TradingReports/TradingReports";
import AnnouncementList from "./views/Announcement/AnnouncementList";
import UserGuideListManagement from "./views/UserGuide/UserGuideList";
import FundManagement from "./views/FundsManagement/FundManagement";
import ViewFundHistory from "./views/FundsManagement/ViewUserFunds/ViewUserFunds";
import AddAnnouncement from "./views/Announcement/AddAnnouncement/AddAnnouncement";
import TransactionCoin from "./views/TransactionFeeManagement/TransactionCoinSetting/TransactionCoin";
import KycLevelSetting from "./views/KycManagement/KycLevelSettings/KycLevelSettings";
import PriceManagement from "./views/PriceManagement/PriceManagement";
import OtcTransactionFee from "./views/OtcManagement/OtcTransactionFee/OtcTransactionFee";
import SwapTransactionFee from "./views/SwapManagement/SwapTransactionFee/SwapTransactionFee";
import WithdrawRevenue from "./views/AccountingManagement/WithdrawRevenue/WithdrawRevenue";
import TotalRevenue from "./views/AccountingManagement/TotalRevenue/TotalRevenue";
import TransactionWithdrawReports from "./views/ReportsManagement/WithdrawReports/TransactionWithdrawReports";
import TokenDepositReports from "./views/ReportsManagement/DepositReports/TokenDepositReports";
import LogoManagement from "./views/LogoManagement/LogoManagement";
import HotwalletFunds from "./views/Withdraw/HotWalletFunds/HotWalletFunds";
import HotwalletFundsHistory from "./views/Withdraw/HotWalletFunds/HotWalletFundsHistory";
import PendingWithdrawList from "./views/Withdraw/WithdrawList/PendingWithdrawList";

import DeleteUser from "./views/UserManagement/User/DeleteUser";
import DepositManagement from "./views/DepositManagement/DepositManagement";
import FundHistory from "./views/FundsManagement/FundHistory";
import AppSetting from "./views/AppSetting/AppSetting";
import TokenList from "./views/TokenManagement/TokenList";
import AddToken from "./views/TokenManagement/AddToken/AddToken";
import EditToken from "./views/TokenManagement/Edit Token/EditToken";
import IDPChargeListing from "views/IDPChargeSetting/IDPChargeListing";
import ExchangeCoin from "views/PairCoinManagement/ExchangeCoin/ExchangeCoin";
import PairCoinManagement from "views/PairCoinManagement/PairCoinManagement";
import SwapHistory from "views/PairCoinManagement/SwapHistory/SwapHistory";
import ReferralManagement from "./views/ReferralManagement/ReferralManagement";
import MLMReferralManagement from "./views/ReferralManagement/MLMReferralManagement";
import InfluencerManagement from "./views/ReferralManagement/InfluencerManagement/InfluencerManagement";
import AddReferral from "./views/ReferralManagement/AddReferral/AddReferral";

import ViewListWithdrawLimit from "./views/ViewWithdrawLimit/ViewOrderList";

// import OrderManagement from "./views/OrderManagement/OrderManagement";
import ViewOrderList from "./views/OrderManagement/ViewOrderList";
import OrderSetting from "views/OrderManagement/OrderSetting/OrderSetting";

import OrderManagement from "./views/OrderBookManagement/OrderBookHistory";
import OrderBookManagement from "./views/OrderBookManagement/OrderManagement";
import ViewOrderBookList from "./views/OrderBookManagement/ViewOrderList";
import OrderBookSetting from "./views/OrderBookManagement/OrderSetting/OrderSetting";

import TradeManagement from "./views/TradeManagement/TradeManagement";
import ReferralHistory from "./views/ReferralManagement/ReferralHistory/ReferralHistory";
import CoinWiseHistory from "views/ReferralManagement/CoinWiseHistory/CoinWiseHistory";
import TradeSetting from "views/TradeManagement/TradeSetting/TradeSetting";
import INRManagement from "./views/INRManagement/TabDevideHandle";
import BankAccountManagement from "views/BankAccountManagement/BankAccountManagement";
import TradeHistory from "views/TradeManagement/TradeHistory/TradeHistory";
import FiatManagement from "views/FiatManagement/FiatManagement";
import AddInterest from "./views/InterestManagement/AddInterest/AddInterest";
import InterestManagement from "./views/InterestManagement/InterestManagement";
// import InterestUserList from "./views/InterestManagement/InterestUserList";
import InterestUserList1 from "./views/InterestManagement/InterestUserList/InterestUserList";
import ViewUserInterestHistory from "./views/InterestManagement/ViewUserInterestHistory/ViewUserInterestHistory";
import CoinWiseBalance from "views/Withdraw/HotWalletFunds/CoinWiseBalance";
import UserWiseBalance from "./views/Withdraw/HotWalletFunds/UserWiseBalance";
import UserInrList from "./views/INRManagement/UserInrList";
// import RewardManagement from "./views/RewardManagement/RewardManagement";
import RewardManagement from "./views/RewardManagement/TabDevideHandle";
import RewardManagementTree from "./views/RewardManagement/ParentTree/ParentTree";
import ViewIncentive from "./views/RewardManagement/ViewIncentive";
import LockFundMangement from "views/LockFundManagement/LockFundMangement";
import BannerManagement from "views/BannerManagement/BannerManagement";
import AddBanner from "views/BannerManagement/AddBanner";
import LockFundHistory from "./views/LockFundManagement/LockFundHistory";
import InstallmentsManagement from "views/InstallmentsManagement/InstallmentsManagement";
import TaralGoldManagement from "./views/TaralGoldManagement/TaralGoldManagement";
import TaralGoldRequests from "./views/TaralGoldManagement/TerminationRequest";
import UserTaralGoldHistory from "views/TaralGoldManagement/UserInstalmentHistory";
import RevenueManagement from "views/RevenueManagement/RevenueManagement";
import FiatRevenueList from "views/RevenueManagement/FiatRevenueList";
import UserInstalmentHistory from "views/InstallmentsManagement/UserInstalmentHistory";
import TerminationRequests from "views/InstallmentsManagement/TerminationRequest";
import RewardHistory from "./views/RewardManagement/RewardHistory";
import ScratchedHistory from "./views/RewardManagement/ScratchedHistory";
import ContentManagement from "views/ContentManagement/ContentManagement";
import ContactQueryManagement from "./views/ContactQueryManagement/ContactQueryManagement";

import UpdateContent from "views/ContentManagement/UpdateContent";
import LogReports from "views/SubAdminManagement/LogReports/LogReports";
import AddFundsFile from "./views/FundsManagement/AddFundsFile/AddFundsFile";
import ReferralIncentive from "views/ReferralManagement/ReferralIncentive";
import InfluencerIncentive from "./views/ReferralManagement/InfluencerManagement/InfluencerIncentive";
import InfluencerHistory from "./views/ReferralManagement/InfluencerManagement/InfluencerHistory";
import SubjectManagement from "views/ContactQueryManagement/SubjectManagement/SubjectManagement";
import UpdateQuery from "./views/ContactQueryManagement/UpdateQuery";
import ReplyOnTaralGoldQuery from "./views/ContactQueryManagement/ReplyOnTaralGoldQuery";
import AddUserGuide from "views/UserGuide/AddUserGuide/AddUserGuide";

var routes = [
  {
    path: "/index",
    name: "Dashboard Statistics",
    icon: "ni ni-tv-2",
    component: Index,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02",
    component: Profile,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25",
    component: LoginFormPage,
    layout: "/vnjdLbguybcAzLmvAlsdnfjDbiumaas",
  },
  // {
  //   path: "/reports",
  //   name: "Report Management",
  //   icon: "ni ni-badge",
  //   component: ReportsManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/user-management",
    name: "User Management",
    icon: "ni ni-circle-08",
    component: UserManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/delete-users",
    name: "Delete User",
    icon: "ni ni-circle-08",
    component: DeleteUser,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/submitted-kyc",
    name: "Users Submitted Kyc",
    icon: "ni ni-money-coins",
    component: SubmittedKyc,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/approved-kyc",
    name: "Users Approved Kyc",
    icon: "ni ni-single-02",
    component: ApprovedKyc,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/kyc-management",
    name: "Kyc Management",
    icon: "ni ni-badge",
    component: KycManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/transaction-fee-management",
    name: "Transaction Management",
    icon: "ni ni-badge",
    component: TransactionFeeManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/trade-management",
    name: "Trade Management",
    icon: "ni ni-collection",
    component: TradeManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // Order Book OLD
  // {
  //   path: "/order-management",
  //   name: "Order Book Management",
  //   icon: "ni ni-collection",
  //   component: OrderManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/view-order-list",
    name: "View Order List",
    icon: "ni ni-collection",
    component: ViewOrderList,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/order-setting",
    name: "Order Setting",
    icon: "ni ni-badge",
    component: OrderSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // Order Book OLD ENDS
  // Order-Book-NEW  engine
  {
    path: "/order-book-engine",
    name: "Order Book Management",
    icon: "ni ni-collection",
    component: OrderBookManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/order-management",
    name: "Order Book history",
    icon: "ni ni-collection",
    component: OrderManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/view-order-pair",
    name: "View Order List",
    icon: "ni ni-collection",
    component: ViewOrderBookList,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-pair",
    name: "Order Setting",
    icon: "ni ni-badge",
    component: OrderBookSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // Order-Book-NEW ENDS  engine

  {
    path: "/view-withdraw-limit",
    name: "Coin Withdraw Limit",
    icon: "ni ni-collection",
    component: ViewListWithdrawLimit,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/view-withdraw-setting",
  //   name: "View Order List",
  //   icon: "ni ni-collection",
  //   component: WithdrawLimitSetting,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  // {
  //   path: "/otc-management",
  //   name: "OTC Management",
  //   icon: "ni ni-badge",
  //   component: OtcListManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },

  // {
  //   path: "/swap-management",
  //   name: "Swap Management",
  //   icon: "ni ni-badge",
  //   component: SwapManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },

  {
    path: "/fund-management",
    name: "Fund Management",
    icon: "ni ni-badge",
    component: FundManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/view-fund-management",
  //   name: "View Fund Management",
  //   icon: "ni ni-badge",
  //   component: ViewFundHistory,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/fund-history",
    name: "Fund History",
    icon: "ni ni-badge",
    component: FundHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/deposit",
  //   name: "Deposit Management",
  //   icon: "ni ni-badge",
  //   component: DepositManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },

  {
    path: "/coin-wise-balance",
    name: "Coin Balance",
    icon: "ni ni-badge",
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
    component: CoinWiseBalance,
  },

  {
    path: "/user-wise-balance",
    name: "User Wise Balance",
    icon: "ni ni-badge",
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
    component: UserWiseBalance,
  },

  {
    path: "/withdrawal",
    name: "Withdrawal Management",
    icon: "ni ni-badge",
    component: WithdrawContainer,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/inrmanagement",
    name: "INR Management",
    icon: "ni ni-badge",
    component: INRManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/bank-account-management",
    name: "Bank Account Management",
    icon: "ni ni-badge",
    component: BankAccountManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/user-inr",
    name: "User INR Management",
    icon: "ni ni-badge",
    component: UserInrList,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/pending-withdraw",
    name: "Pending Withdrawal List",
    icon: "ni ni-badge",
    component: PendingWithdrawList,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/hot-wallet-funds",
    name: "Hot Wallet Management",
    icon: "ni ni-badge",
    component: HotwalletFunds,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/revenue-management",
    name: "Revenue Management",
    icon: "ni ni-badge",
    component: RevenueManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/fiat-revenue-list",
    name: "Fiat Revenue List",
    icon: "ni ni-badge",
    component: FiatRevenueList,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/history",
    name: "Cold wallet Transaction History",
    icon: "ni ni-badge",
    component: HotwalletFundsHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/trading-reports",
    name: "Trading Report",
    icon: "ni ni-badge",
    component: TradingReports,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/rewardManagement",
    name: "Reward Management",
    icon: "ni ni-badge",
    component: RewardManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/reward-tree",
  //   name: "Reward Management",
  //   icon: "ni ni-badge",
  //   component: RewardManagementTree,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/referral-management",
    name: "Referral Management",
    icon: "ni ni-diamond",
    component: ReferralManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/referral-management-mlm",
    name: "Influencer History",
    icon: "ni ni-diamond",
    component: MLMReferralManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/influencer-management",
    name: "Influencer Management",
    icon: "ni ni-diamond",
    component: InfluencerManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/influencer-history",
    name: "Influencer History",
    icon: "ni ni-diamond",
    component: InfluencerHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/referral-incentive",
    name: "Referral Incentive",
    icon: "ni ni-diamond",
    component: ReferralIncentive,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/reward-history",
    name: "Reward History",
    icon: "ni ni-badge",
    component: RewardHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/scratch-history",
    name: "Reward History",
    icon: "ni ni-badge",
    component: ScratchedHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/viewIncentive",
    name: "View Incentive",
    icon: "ni ni-badge",
    component: ViewIncentive,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/transaction",
    name: "Transaction Withdraw Report",
    icon: "ni ni-badge",
    component: TransactionWithdrawReports,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/deposit-token",
    name: "Token Deposit Report",
    icon: "ni ni-badge",
    component: TokenDepositReports,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/announcement-list",
    name: "Announcement Management",
    icon: "ni ni-badge",
    component: AnnouncementList,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  // {
  //   path: "/User-guide",
  //   name: "User Guide Management",
  //   icon: "ni ni-badge",
  //   component: UserGuideListManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },

  {
    path: "/banner-management",
    name: "Banner Management",
    icon: "ni ni-image",
    component: BannerManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/add-banner",
    name: "Add Banner",
    icon: "ni ni-image",
    component: AddBanner,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/content-management",
    name: "Content Management",
    icon: "ni ni-single-copy-04",
    component: ContentManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/contact-queries",
    name: "Contact Queries",
    icon: "ni ni-single-copy-04",
    component: ContactQueryManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/taralgold-reply-broadcasting",
  //   name: "Taralgold Broadcasting",
  //   icon: "ni ni-single-copy-04",
  //   component: ReplyOnTaralGoldQuery,
  //   // component: ContactQueryManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/taralgold-reply-broadcasting",
    // name: "Taralgold Broadcasting",
    name: "Lock fund Management",
    icon: "ni ni-lock-circle-open",
    component: ReplyOnTaralGoldQuery,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/coin-setting",
    name: "Coin Setting",
    icon: "ni ni-badge",
    component: WithDrawDepositSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/add-announcement",
    name: "Add / Update Announcement",
    icon: "ni ni-badge",
    component: AddAnnouncement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-userGuide",
    name: "add-userGuide",
    icon: "ni ni-badge",
    component: AddUserGuide,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    name: "Add Coin Pair",
    path: "/exchange-coin",
    icon: "ni ni-send",
    component: ExchangeCoin,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    name: "Pair Coin Management",
    path: "/pair-coin",
    icon: "ni ni-send",
    component: PairCoinManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   name: "Bank Account Management",
  //   path: "/bank-account-management",
  //   icon: "ni ni-badge",
  //   component: BankAccountManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },

  {
    name: "Fiat Management",
    path: "/fiat-management",
    icon: "ni ni-badge",
    component: FiatManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-referral",
    name: "Add Referral",
    component: AddReferral,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-subject",
    name: "Add Subject",
    component: SubjectManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/influencer-incentive",
    name: "Influencer Incentive",
    component: InfluencerIncentive,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/lock-fund-management",
    name: "Lock fund Management",
    icon: "ni ni-lock-circle-open",
    component: LockFundMangement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/view-fund-management",
    // name: "View Fund Management",
    name: "Lock fund Management",
    icon: "ni ni-lock-circle-open",
    component: ViewFundHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/installment-management",
  //   name: "Installment Management",
  //   icon: "ni ni-credit-card",
  //   component: InstallmentsManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/taral-gold-management",
    name: "Taral Gold Management",
    icon: "ni ni-credit-card",
    component: TaralGoldManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/user-installment-history",
    name: "User Installment History",
    icon: "",
    component: UserInstalmentHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/user-taral-gold-history",
    name: "User Taral Gold History",
    icon: "",
    component: UserTaralGoldHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/user-termination-requests",
    name: "User Termination Requests",
    icon: "",
    component: TerminationRequests,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/user-taral-gold-requests",
    name: "User Termination Requests",
    icon: "",
    component: TaralGoldRequests,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/lock-fund-history",
    name: "Lock fund History",
    icon: "ni ni-lock-circle-open",
    component: LockFundHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/trade-history",
    name: "Trade History",
    icon: "ni ni-collection",
    component: TradeHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/Otc-Level-setting",
    name: "OTC Level Setting",
    icon: "ni ni-badge",
    component: OtcLevelSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/Kyc-Level-setting",
    name: "Kyc Level Transaction Setting",
    icon: "ni ni-badge",
    component: KycLevelSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/referral-history",
    name: "Referral History",
    icon: "ni ni-badge",
    component: ReferralHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/coin-wise-history",
    name: "Coin Wise History",
    icon: "ni ni-badge",
    component: CoinWiseHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/trade-setting",
    name: "Trade Setting",
    icon: "ni ni-badge",
    component: TradeSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/price-management",
  //   name: "Price Management (IDA/IDP)",
  //   icon: "ni ni-badge",
  //   component: PriceManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  // {
  //   path: "/idp-charge-management",
  //   name: "IDP Charge Management",
  //   icon: "ni ni-badge",
  //   component: IDPChargeListing,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  // {
  //   path: "/logo-management",
  //   name: "Logo Management",
  //   icon: "ni ni-badge",
  //   component: LogoManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/token-management",
    name: "Token Management",
    icon: "ni ni-badge",
    component: TokenList,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-token",
    name: "Add Token",
    icon: "ni ni-badge",
    component: AddToken,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    // path: "/edit-token/:id",
    path: "/edit-token",
    name: "Edit Token",
    icon: "ni ni-badge",
    component: EditToken,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/swap-history",
    name: "Swap History",
    icon: "ni ni-badge",
    component: SwapHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/edit-pair-coin/:id",
    name: "Edit Pair Coin",
    icon: "ni ni-badge",
    component: ExchangeCoin,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/edit-interest/:id",
    name: "Edit Interest",
    icon: "ni ni-badge",
    component: AddInterest,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/add-interest",
    name: "Add Interest",
    icon: "ni ni-badge",
    component: AddInterest,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/interest-management",
    name: "Interest Management",
    icon: "ni ni-badge",
    component: InterestManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/interest-user-list",
    name: "Interest User List",
    icon: "ni ni-badge",
    component: InterestUserList1,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/view-user-interest-history",
    name: "view user interest history",
    icon: "ni ni-badge",
    component: ViewUserInterestHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/sub-admin-management",
    name: "Subadmin Management",
    icon: "ni ni-badge",
    component: SubAdminManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-sub-admin",
    name: "Add New Sub Admin",
    icon: "ni ni-badge",
    component: AddNewSubAdmin,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/account-setting",
    name: "Account Settings",
    icon: "ni ni-badge",
    component: AccountSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  // {
  //   path: "/app-setting",
  //   name: "App Settings",
  //   icon: "ni ni-badge",
  //   component: AppSetting,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/Transaction-fee",
    name: "Transaction Fee",
    icon: "ni ni-chart-bar-32 text-green",
    component: TransactionFeeSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/Transaction-coin",
    name: "Transaction Coin",
    icon: "ni ni-chart-bar-32 text-green",
    component: TransactionCoin,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/otc-order",
    name: "OTC order",
    component: OtcOrderListManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/otc-transaction-fee",
    name: "OTC transaction fee",
    component: OtcTransactionFee,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  // {
  //   path: "/accounting",
  //   name: "Accounting",
  //   icon: "ni ni-chart-bar-32 text-green",
  //   component: Accounting,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf"
  // },

  {
    path: "/total-revenue",
    name: "Total Revenue",
    component: TotalRevenue,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  //  {
  //   path: "/otc-revenue",
  //   name: "Otc Revenue",
  //   component: OtcRevenue,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf"
  // },
  {
    path: "/swap-transaction-fee",
    name: "Swap tansaction fee",
    path: "/swap-ida-to-idp-rate",
    name: "swap ida to idp rate",
    component: SwapTransactionFee,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/otc-trader",
    name: "OTC Trader",
    component: OtcTraderManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/google-auth-form",
    name: "Google Auth",
    icon: "ni ni-badge",
    component: AuthForm,
    layout: "/vnjdLbguybcAzLmvAlsdnfjDbiumaas",
  },
  {
    path: "/reset-password",
    name: "Forgot Password",
    icon: "ni ni-circle-08",
    component: ForgotPassword,
    layout: "/vnjdLbguybcAzLmvAlsdnfjDbiumaas",
  },
  {
    path: "/forgot-password",
    name: "Email Confirmation",
    icon: "ni ni-circle-08",
    component: EmailConfirmation,
    layout: "/vnjdLbguybcAzLmvAlsdnfjDbiumaas",
  },
  {
    path: "/two-factor-auth",
    component: TwoFactorauth,
    layout: "/vnjdLbguybcAzLmvAlsdnfjDbiumaas",
  },
  {
    name: "Google Auth",
    path: "/google-setting",
    component: GoogleAuth,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/update-content",
    name: "Update Content",
    icon: "ni ni-image",
    component: UpdateContent,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/update-query",
    name: "Update Query",
    icon: "ni ni-image",
    component: UpdateQuery,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/log-reports",
    name: "Log Reports",
    icon: "",
    component: LogReports,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-funds-file",
    name: "Add Funds File",
    icon: "ni ni-badge",
    component: AddFundsFile,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-user-guide",
    name: "Add User Guide",
    icon: "",
    component: AddUserGuide,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
];
export default routes;
