import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import { Form } from "semantic-ui-react";
import { FormField } from "../../../components/FormField";
import { addCoupon } from "../../../Redux/actons/referral.action";
import { Field, reduxForm, change, reset } from "redux-form";
import { SelectField } from "../../../components/SelectField";
import { getCoinList } from "../../../Redux/actons/user.action";
import { required } from "redux-form-validators";
import { invalidMinusValue } from "validators/customValidator";

const reward_types = [
  { key: "m", text: "Worth of fiat", value: "worth_of_fiat" },
  { key: "k", text: "Actual Crypto", value: "actual_crypto" },
];

const enable_types = [
  { key: "m", text: "Active", value: "1" },
  { key: "k", text: "Inactive", value: "0" },
];
const redeem_coupon1 = [
  { key: "m", text: "Sign Up", value: "sign_up" },
  { key: "k", text: "Redeem Coupon", value: "redeem_coupon" },
];

export class AddCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
      sortBy: "",
      image: "",
      link: "",
      rewardType: "0",
      name: "",
      coupon: "",
      amount: "",
      status: "1",
      redeem_coupon: "1",
    };
  }

  componentDidMount = () => {
    // this.getBannerList();
    // this.props.getCoinList();
  };

  addReward = (values) => {
    let data = {
      // type: values.reward_type,
      limit: values.limit,
      name: values.name,
      coupon: values.coupon.toUpperCase(),
      status: values.status,
      type: values.redeem_coupon,
    };
    this.props.addCoupon(data, this.props.reloadParams);
    this.props.closeRewardModal();
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props?.addRewardModal}
          toggle={this.props.closeRewardModal}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.closeRewardModal}>
            Add Coupon
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.addReward)}>
              <Form.Group>
                <Form.Field>
                  <label>Name</label>
                  <Field
                    placeholder="Name"
                    name="name"
                    // value={this.state.fee}
                    component={FormField}
                    validate={[required()]}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Coupon</label>
                  <Field
                    placeholder="Coupon"
                    name="coupon"
                    // value={this.state.fee}
                    component={FormField}
                    validate={[required()]}
                  />
                </Form.Field>
              </Form.Group>

              {/* <Form.Group>
                <Form.Field>
                  <label>Reward Type</label>
                  <Field
                    name="reward_type"
                    search={false}
                    selectOnBlur={false}
                    component={SelectField}
                    children={reward_types}
                    placeholder="Select"
                    onChange={(type) => this.setState({ rewardType: type })}
                    validate={[required()]}
                  ></Field>
                </Form.Field>
              </Form.Group> */}

              <Form.Group>
                <Form.Field>
                  <label>User Limit</label>
                  <Field
                    placeholder="User Limit"
                    name="limit"
                    // value={this.state.fee}
                    component={FormField}
                    type="number"
                    validate={[required(), invalidMinusValue()]}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Type</label>
                  <Field
                    name="redeem_coupon"
                    search={false}
                    selectOnBlur={false}
                    component={SelectField}
                    children={redeem_coupon1}
                    placeholder="Select"
                    onChange={(type) => this.setState({ redeem_coupon: type })}
                    value={this.state.redeem_coupon}
                    validate={[required()]}
                  ></Field>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Status</label>
                  <Field
                    name="status"
                    search={false}
                    selectOnBlur={false}
                    component={SelectField}
                    children={enable_types}
                    placeholder="Select"
                    onChange={(type) => this.setState({ status: type })}
                    value={this.state.status}
                    validate={[required()]}
                  ></Field>
                </Form.Field>
              </Form.Group>

              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Add Reward
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCoupon: (data, params) => dispatch(addCoupon(data, params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "AddCouponForm" })(AddCoupon));
