import { BannerServices } from "../../Services/BannerServices";
import { toast } from "../../components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";
import { reset } from "redux-form";

export const actionTypes = {
  SAVE_BANNER_LIST: "SAVE_BANNER_LIST",
  SAVE_BANNER_TYPE: "SAVE_BANNER_TYPE",
};
export function saveBannerList(data) {
  return {
    type: actionTypes.SAVE_BANNER_LIST,
    data,
  };
}

export function saveBannerType(data) {
  return {
    type: actionTypes.SAVE_BANNER_TYPE,
    data,
  };
}

export function getBannerList(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();
    return BannerServices.getBannerList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveBannerList(data.data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getBannerType(data) {
  return (dispatch, getState) => {
    let state = getState();
    return BannerServices.getBannerType(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveBannerType(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
      });
  };
}

export function addBanner(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return BannerServices.addBanner(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        toast.success(res.data.message);
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function deleteAnnouncement(data, params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return BannerServices.deleteBanner(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        toast.success(res.data.message);
        dispatch(getBannerList(params));
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
        //   toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

