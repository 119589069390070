import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import { connect } from "react-redux";
import { FormField } from "../../components/FormField";
import { required } from "redux-form-validators";
import { Field, reduxForm, change, reset } from "redux-form";

export class EditDescription extends Component {
    componentDidUpdate = async () => {
            console.log('description >>>>>>',this.props.updateData.description);
        await this.props.dispatch(
            change(
            "EditDescriptionForm",
            "description",
            this.props?.updateData?.description || null
            )
        );
    };
  render() {
    
    console.log("this.props >>>>", this.props.updateData);
    let { handleSubmit, t } = this.props;
    return (
      <div>
        <div>
          <Modal
            isOpen={this.props.updateDescriptionModal}
            toggle={this.props.openCloseDescriptionModal}
            className={[this.props.className, "modalCustom"]}
          >
            <Form onSubmit={handleSubmit((values) =>
                    this.props.updateDescription({
                      ...values,
                    }))}> 
              <ModalHeader
                toggle={this.props.openCloseDescriptionModal}
                style={{ backgroundColor: "	#343434", color: "white" }}
              >
                <span style={{ color: "white" }}>
                    Update Description
                </span>
              </ModalHeader>
              <ModalBody
                style={{ backgroundColor: "#343434", textAlign: "center" }}
              >
                <div>
                  <div>
                    <Field
                      placeholder="Add description"
                      name="description"
                      component={FormField}
                      type="textArea"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter style={{ backgroundColor: "#343434" }}>
                <Button
                  color="success"
                  onClick={handleSubmit((values) =>
                    this.props.updateDescription({
                      ...values,
                    })
                  )}
                >
                  Update
                </Button>
                <Button
                  color="danger"
                  onClick={this.props.openCloseDescriptionModal}
                >
                  Cancel
                </Button>
                {/* <Button
                  color="sucess"
                  onClick={handleSubmit((values) =>
                    this.props.acceptRejectKyc({
                      ...values,
                    })
                  )}
                >
                  {t("commonHeadings.add")}
                </Button> */}
              </ModalFooter>
            </Form>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {};
export default reduxForm({ form: "EditDescriptionForm" })(
    connect(mapStateToProps, mapDispatchToProps)(EditDescription)
  );
// export default connect()(reduxForm({ form: "editDescriptionForm" })(EditDescription));
// export default connect(
//   // mapStateToProps,
//   // mapDispatchToProps
// )(reduxForm({ form: "addCommentForm" })(withTranslation()(AddComment)));
