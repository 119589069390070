import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CustomHeader from "../../components/Headers/CustomHeader";
import Header from "components/Headers/Header";
import {
  Card,
  CardHeader,
  CardFooter,
  Input,
  Button,
  Table,
  Container,
  Row,
} from "reactstrap";
import {
  Pagination,
  Dropdown,
  Menu,
  Label,
  GridColumn,
  Grid,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import { API_HOST } from "../../constants/index";
import Level1 from "./KycLevels/Level1";
import Level2 from "./KycLevels/Level2";
import Leve3 from "./KycLevels/Leve3";
import {
  kycList,
  kycListCSV,
  saveKycSelectedFilter1,
  saveKycSelectedFilter2,
  saveKycSelectedFilter3,
  clearKycFilters,
  userDetail,
  DashboardList,
} from "../../Redux/actons/userList.action";
import { getCoinList } from "Redux/actons/user.action";
import UserDetail from "../UserManagement/User/UserDetail";
import Download from "../../views/CommonFiles/Download";
import { DashboardResult } from "views/ContactQueryManagement/SubjectManagement/DashboardResult";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

/* 0=>docNotUploaded,1=>pending,2=>approved,3=>rejected 	 */

const kycFilter = [
  { key: "p", text: "None", value: "none" },
  { key: "t", text: "Pending", value: "1" },
  { key: "k", text: "Verified", value: "2" },
  { key: "n", text: "Unverified", value: "3" },
];
const kycNationFilter = [
  { key: "p", text: "None", value: "none" },
  { key: "t", text: "Domestic", value: "domestic" },
  { key: "k", text: "International", value: "international" },
  // { key: "n", text: "Unverified", value: "3" },
];
export class KycManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1: false,
      modal2: false,
      modal3: false,
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "desc",
      sortBy: "",
      userInfo: {},
      type: window.location.hash.split("?")[1],
      filter1: "",
      filter2: "",
      filter3: "",
      downloadExcel: false,
      filterData: {},
      kycSelected: "",
      detailModal: false,
      kyc_type: "",
    };
  }

  componentDidMount = () => {
    this.getKycList();
    this.props.getCoinList();
    this.props.DashboardList();
  };
  componentWillUnmount = () => {
    this.props.saveKycSelectedFilter1("");
  };

  getKycList = () => {
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      order: this.state.order,
      type: this.state.type
        ? this.state.type
        : window.location.hash.split("?")[1]
        ? window.location.hash.split("?")[1]
        : "",
      kyc_type: this.state.kyc_type,
      // kycLevelType: this.state.type
      //   ? this.state.type == 1
      //     ? 1
      //     : this.state.type == 3
      //     ? 1
      //     : 2
      //   : "",
      kycLevelType: this.state.type
        ? this.state.kycSelected
        : window.location.hash.split("?")[1]
        ? 2
        : "",
    };
    this.props.kycList(data);
  };

  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getKycList();
    });
  };
  filterByKycLevel1 = async (e, data) => {
    this.props.saveKycSelectedFilter1(data.value);
    this.setState({ filter1: data.value, kycSelected: 1, filter2: "" });
    if (data.value !== "none") {
      this.setState({ type: data.value }, () => {
        this.getKycList();
      });
    } else {
      await this.props.clearKycFilters();

      this.setState({ type: "", filter1: "", kycSelected: "" }, () => {
        this.getKycList();
      });
    }
  };
  filterByKycLevel2 = async (e, data, kyc_type) => {
    this.props.saveKycSelectedFilter2(data.value);

    this.setState({
      filter2: data.value,
      kycSelected: 2,
      filter3: kyc_type,
    });
    if (data.value !== "none") {
      this.setState({ type: data.value, kyc_type: kyc_type, page: 1 }, () => {
        this.getKycList();
      });
    } else {
      await this.props.clearKycFilters();
      this.setState(
        { type: "", kyc_type: "", filter2: "", filter3: "", kycSelected: "" },
        () => {
          this.getKycList();
        }
      );
    }
  };
  filterByKycLevel3 = async (e, data, type) => {
    this.props.saveKycSelectedFilter3(data.value);
    this.setState({ filter3: data.value, filter2: type, page: 1 });
    if (data.value !== "none") {
      this.setState({ kyc_type: data.value, type: type }, () => {
        this.getKycList();
      });
    } else {
      await this.props.clearKycFilters();
      this.setState(
        { kyc_type: "", type: "", filter2: "", filter3: "", kycSelected: "" },
        () => {
          this.getKycList();
        }
      );
    }
  };

  //page chANGE
  changeKycPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getKycList();
    });
  };

  //search
  onSearchKyc = () => {
    // this.setState({ searchKey: xyz, page: 1 }, () => {
    this.getKycList();
    // });
  };

  showModal = (data) => {
    this.setState({ userInfo: data, modal1: true });
  };

  closeModal = () => {
    this.setState({ modal1: false });
  };

  showModal2 = (data) => {
    this.setState({ userInfo: data, modal2: true });
  };

  closeModal2 = () => {
    this.setState({ modal2: false });
  };

  showModal3 = (data) => {
    this.setState({ userInfo: data, modal3: true });
  };

  closeModal3 = () => {
    this.setState({ modal3: false });
  };

  search = (e) => {
    this.props.kycList(3, e.target.value);
  };

  downLoadUserList = () => {
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.props.totalKycRecords,
      page: 1,
      order: this.state.order,
      type: this.props.type,
      kycLevelType: this.props.kycSelected,
    };
    // this.setState({
    //   downloadExcel: !this.state.downloadExcel,
    //   download_url: `${API_HOST}api/v1/admin/kyc/download/kyclist`,
    //   filterData: filterData,
    // });

    this.props.kycListCSV(data);
    // window.location.href = `${API_HOST}api/v1/admin/kyc/download/kyclist`;
  };
  detailModalToggle = (userData) => {
    this.props.userDetail({ user_id: userData });
    this.setState({ detailModal: !this.state.detailModal });
  };
  clostDetailModal = () => {
    this.setState({ detailModal: !this.state.detailModal });
  };
  closeDownloadExcel = () => {
    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: "",
      filterData: {},
    });
  };
  render() {
    let { handleSubmit, dashboard, dashboardLists } = this.props;

    let fdata = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      order: this.state.order,
      type: this.props.type,
      kycLevelType: this.props.kycSelected,
    };
    return (
      <div>
        {/* <CustomHeader /> */}
        <Header headerTitle="kyc management" />
        {/* Page content */}
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Card className="shadow">
                <Grid.Row
                  columns={2}
                  // style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Total KYC"}
                      labResult={
                        dashboard?.totalActive
                          ? dashboard?.totalVerify + dashboard?.totalNotVerify
                          : "0"
                      }
                    />
                  </GridColumn>
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Pending Kyc(Level 2)"}
                      labResult={
                        dashboardLists?.kycStats?.pending_kyc_level_2
                          ? dashboardLists?.kycStats?.pending_kyc_level_2
                          : "0"
                      }
                    />
                  </GridColumn>
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Kyc Level 1(Verified)"}
                      labResult={
                        dashboardLists.kycStats?.verified_kyc_level_1
                          ? dashboardLists?.kycStats?.verified_kyc_level_1
                          : "0"
                      }
                    />
                  </GridColumn>
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Kyc Level 2(Verified)"}
                      labResult={
                        dashboardLists?.kycStats?.verified_kyc_level_2
                          ? dashboardLists?.kycStats?.verified_kyc_level_2
                          : "0"
                      }
                    />
                  </GridColumn>
                  {/* <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Total Active"}
                      labResult={
                        dashboard?.totalActive ? dashboard?.totalActive : "0"
                      }
                    />
                  </GridColumn>

                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Total Inactive "}
                      labResult={
                        dashboard?.totalInactive
                          ? dashboard?.totalInactive
                          : "0"
                      }
                    />
                  </GridColumn>
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Total Verified "}
                      labResult={
                        dashboard?.totalVerify ? dashboard?.totalVerify : "0"
                      }
                    />
                  </GridColumn>
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Total Unverified"}
                      labResult={
                        dashboard?.totalNotVerify
                          ? dashboard?.totalNotVerify
                          : "0"
                      }
                    />
                  </GridColumn> */}
                </Grid.Row>
              </Card>
            </div>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Kyc List</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search List"
                        style={{
                          color: "black",
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchKyc();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchKyc()}
                      >
                        Search
                      </Button>
                      {/* <Link to="Kyc-Level-setting">
                        <Button color="success btnMamagePermission">
                          Kyc Level Transaction Settings
                        </Button>
                      </Link>   */}
                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />{" "}
                      {/* <Dropdown
                        selectOnBlur={false}
                        className="selectUserStyle mx-2"
                        options={kycFilter}
                        placeholder="Kyc Level 1 Filter"
                        selection
                        value={this.props.filter1}
                        onChange={(e, data) => this.filterByKycLevel1(e, data)}
                      /> */}
                      <Dropdown
                        selectOnBlur={false}
                        className="selectUserStyle mx-3 "
                        options={kycFilter}
                        placeholder="KYC Status"
                        selection
                        value={this.state.type}
                        onChange={(e, data) =>
                          this.filterByKycLevel2(e, data, this.state.kyc_type)
                        }
                      />
                      <Dropdown
                        selectOnBlur={false}
                        className="selectUserStyle mx-3 "
                        options={kycNationFilter}
                        placeholder="KYC Type"
                        selection
                        value={this.state.kyc_type}
                        onChange={(e, data) =>
                          this.filterByKycLevel3(e, data, this.state.type)
                        }
                      />
                    </div>
                    <div
                      className="selectSearchOption"
                      style={{ marginTop: 20 }}
                    >
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.downLoadUserList()}
                      >
                        Download Kyc list
                      </Button>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">ID (Email)</th>
                        <th scope="col">Name</th>
                        <th scope="col">Kyc Type</th>
                        <th scope="col">KYC Level 1</th>
                        <th scope="col">KYC Level 2</th>
                        <th scope="col"> KYC Level 3</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props?.usersList?.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          <td>
                            <Link
                              onClick={() => this.detailModalToggle(usersList)}
                            >
                              {usersList.email ? usersList.email : "None"}
                            </Link>
                          </td>
                          {/* <Link to="user-management">
                              {usersList.email ? usersList.email : "None"}
                            </Link> */}
                          <td>{usersList.name ? usersList.name : "None"}</td>
                          <td style={{ textTransform: "" }}>
                            {usersList?.kyc_type ? (
                              <span style={{ textTransform: "uppercase" }}>
                                {" "}
                                {usersList?.kyc_type}
                              </span>
                            ) : (
                              "None"
                            )}
                          </td>

                          <td>
                            <span className="mb-0 text-sm">
                              <Button
                                color="success"
                                onClick={() => this.showModal(usersList)}
                              >
                                Verified
                              </Button>
                            </span>
                          </td>

                          <td>
                            <span className="mb-0 text-sm">
                              {" "}
                              <Button
                                color={
                                  usersList?.email_status === "1" &&
                                  usersList.mobile_status <= "1"
                                    ? "success"
                                    : usersList?.email_status === "0" &&
                                      usersList?.mobile_status === "0"
                                    ? "primary"
                                    : "danger"
                                }
                                // color={
                                //   Number(usersList.kyc_status) >= 1
                                //     ? "success"
                                //     : usersList?.email_status === "0" &&
                                //       usersList?.mobile_status === "0"
                                //     ? "primary"
                                //     : "danger"
                                // }
                                onClick={() => this.showModal2(usersList)}
                              >
                                {usersList?.email_status === "1" &&
                                usersList?.mobile_status === "1"
                                  ? "Verified"
                                  : usersList?.email_status == "0" &&
                                    usersList?.mobile_status == "0"
                                  ? "Pending"
                                  : "Unverified"}
                                {/* {Number(usersList.kyc_status) >= 1
                                  ? "Verified"
                                  : usersList?.kyc_status === "0"
                                  ? "Pending"
                                  : usersList?.kyc_status === "3"
                                  ? "Unverified"
                                  : "Pending"} */}
                              </Button>
                            </span>
                          </td>
                          <td className="text-left">
                            <span className="mb-0 text-sm">
                              {" "}
                              {/* 0=>docNotUploaded,1=>pending,2=>approved,3=>rejected 	 */}
                              <Button
                                color={
                                  usersList?.kyc_status === "2"
                                    ? "success"
                                    : usersList?.kyc_status === "1"
                                    ? "primary"
                                    : "danger"
                                }
                                onClick={() => this.showModal3(usersList)}
                              >
                                {usersList.kyc_status === "2"
                                  ? "Verified"
                                  : usersList?.kyc_status === "1"
                                  ? "Pending"
                                  : usersList?.kyc_status === "3"
                                  ? "Unverified"
                                  : "Doc not uploaded"}
                              </Button>
                            </span>
                          </td>
                        </tr>
                      ))}
                      {this.props?.usersList?.length == 0 && (
                        <td>No record found</td>
                      )}
                    </tbody>
                  </Table>
                  {this.props?.usersList?.length != 0 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) =>
                            this.changeKycPage(e, data)
                          }
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalKycRecords / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </Row>
            {this.state.modal1 && (
              <Level1
                modal={this.state.modal1}
                toggle={this.closeModal}
                userDetail={this.state?.userInfo}
              />
            )}
            {this.state.modal2 && (
              <Level2
                modal1={this.state.modal2}
                toggle1={this.closeModal2}
                userDetail={this.state?.userInfo}
              />
            )}
            {this.state.modal3 && (
              <Leve3
                modal2={this.state.modal3}
                toggle2={this.closeModal3}
                userDetail={this.state?.userInfo}
                formData={fdata}
              />
            )}
          </Container>
        </div>
        <UserDetail
          clostDetailModal={this.clostDetailModal}
          detailModal={this.state.detailModal}
        />
        <Download
          filterData={this.state.filterData}
          closeDownloadExcel={this.closeDownloadExcel}
          downloadExcel={this.state.downloadExcel}
          download_url={this.state.download_url}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    dashboardLists: state.userList.dashboardLists,
    usersList: state.userList?.kycLists,
    dashboard: state.userList?.totalKycRecords,
    totalKycRecords: state.userList?.totalKycRecords.total,
    filter1: state.userList?.filter1,
    filter2: state.userList?.filter2,
    filter3: state.userList?.filter3,
    kycSelected: state.userList?.kycSelected,
    type: state.userList?.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    DashboardList: (data) => dispatch(DashboardList(data)),
    kycList: (type, data) => dispatch(kycList(type, data)),
    kycListCSV: (type, data) => dispatch(kycListCSV(type, data)),
    userDetail: (id) => dispatch(userDetail(id)),
    getCoinList: () => dispatch(getCoinList()),
    saveKycSelectedFilter1: (data) => dispatch(saveKycSelectedFilter1(data)),
    saveKycSelectedFilter2: (data) => dispatch(saveKycSelectedFilter2(data)),
    saveKycSelectedFilter3: (data) => dispatch(saveKycSelectedFilter3(data)),
    clearKycFilters: () => dispatch(clearKycFilters()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KycManagement);
