import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Pagination, Form, Dropdown } from "semantic-ui-react";
import { toast } from "../../components/Toast/Toast";
import CustomHeader from "../../components/Headers/CustomHeader";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router";
import { listDateFormat } from "../../utils/utils";
import Header from "components/Headers/Header";
import {
  API_HOST,
  BSC_TXN_BSC,
  BTC_TXN_BTC,
  ETH_TXN_ETH,
  MATIC_TXN_MATIC,
} from "../../constants/index";
import {
  getTransactionList,
  sendfailureMessage,
  getTransactionDetails,
} from "../../Redux/actons/transaction.action";
import { Link } from "react-router-dom";
import { getCoinList } from "../../Redux/actons/user.action";
import {
  getCoinListForFilter,
  userDetail,
} from "../../Redux/actons/userList.action";
import UserDetail from "../UserManagement/User/UserDetail";
const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

const feeFilter = [
  { key: "e", text: "None", value: "none" },
  { key: "i", text: "Internal", value: "internal" },
  { key: "o", text: "Outgoing", value: "outgoing" },

  { key: "l", text: "Rejected", value: "rejected" },
];

const statusFilter = [
  { key: "k", text: "None", value: "none" },
  { key: "p", text: "Rejected", value: "rejected" },
  { key: "c", text: "Complete", value: "complete" },
  { key: "n", text: "Pending", value: "pending" },
  { key: "s", text: "Signed", value: "signed" },
  { key: "f", text: "Failed", value: "failed" },
  { key: "i", text: "In-progress", value: "in-progress" },
  { key: "a", text: "Approved", value: "approved" },
  { key: "d", text: "Cancelled", value: "cancelled" },
];
const Filteroptions = [{ key: "e", text: "None", value: "none" }];

export class DepositManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentUserId: "",
      open: false,
      openModel: false,
      trnx_type: "deposit",
      status: [],
      coin_type: "",
      searchKey: "",
      perPage: "10",
      page: "1",
      from_date: "",
      to_date: "",
      message: "",

      order: "desc",
      sortBy: "",
      detailModal: false,
      filterType: "",
    };
  }
  componentDidMount = () => {
    this.getTransList();
    this.props.getCoinList();
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.coinListCoinFilter !== this.props.coinListCoinFilter) {
      nextProps.coinListCoinFilter.forEach((element) => {
        Filteroptions.push(element);
      });
    }
  };
  toggle = () => this.setState({ open: !this.state.open });
  toggleDetails = () => this.setState({ openDetails: !this.state.openDetails });

  viewDetails = (transaction) => {
    let data = {
      withdraw_id: transaction.id,
      trnx_type: transaction.type,
    };

    this.props.getTransactionDetails(data);
    this.setState({ openDetails: true });
  };
  openModel = (data) => {
    this.setState({ commentUserId: data, open: true });
  };
  setMessage = (e) => {
    this.setState({ message: e.target.value });
  };
  sendMesage = async () => {
    if (this.state.message != "") {
      let data = {
        user_id: this.state.commentUserId.user_id,
        message: this.state.message,
        transactionId: this.state.commentUserId.id,
        type: this.state.commentUserId.type,
      };
      await this.props.sendfailureMessage(data);

      this.setState({ open: false, message: "" });
    } else {
      toast.error("Please enter message");
    }
  };

  getTransList = () => {
    let data = {
      trnx_type: this.state.trnx_type,
      status: this.state.status,
      coin_type: this.state.coin_type,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      searchKey: this.state.searchKey,

      perPage: this.state.perPage,
      page: this.state.page,

      order: this.state.order,
      filterType: this.state.filterType,
    };

    this.props.getTransactionList(data);
  };

  //page change
  changeTransPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getTransList();
    });
  };

  sortBy = (e, data) => {
    this.setState(
      {
        order: data.value,
        searchKey: "",
        status: [],
        coin_type: [],
      },
      () => {
        this.getTransList();
      }
    );
  };
  filterBy = (e, data) => {
    if (data.value !== "none") {
      this.setState({ filterType: data.value, trnx_type: data.value }, () => {
        this.getTransList();
      });
    } else {
      this.setState({ filterType: "", trnx_type: "" }, () => {
        this.getTransList();
      });
    }
  };

  filterByStatus = (e, data) => {
    this.setState({ status: [], searchKey: "" });
    if (data.value !== "none") {
      let status = [];

      status.push(data.value);
      this.setState({ status: status }, () => {
        this.getTransList();
      });
    } else {
      this.setState({ status: [] }, () => {
        this.getTransList();
      });
    }
  };
  filterByCoin = (e, data) => {
    if (data.value !== "none") {
      let coin_type = [];
      coin_type.push(data.value);

      this.setState(
        { coin_type: coin_type, status: [], searchKey: "", trnx_type: "" },
        () => {
          this.getTransList();
        }
      );
    } else {
      this.setState({ coin_type: [] }, () => {
        this.getTransList();
      });
    }
  };

  downLoadUserList = () => {
    window.location.href = `${API_HOST}api/v1/admin/transaction/download/transactionlist?trnx_type=deposit`;
  };

  redirectToHashSite = (coin, hash) => {
    if (coin == "btc") {
      window.open(`${BTC_TXN_BTC}/${hash}`, "_blank");
    }
    if (coin == "eth" || coin == "ida" || coin == "orbs") {
      window.open(`${ETH_TXN_ETH}/${hash}`, "_blank");
    }
    if (coin == "bnb" || coin == "busd" || coin == "rena") {
      window.open(`${BSC_TXN_BSC}/${hash}`, "_blank");
    }
    if (coin == "matic") {
      window.open(`${MATIC_TXN_MATIC}/${hash}`, "_blank");
    }
  };
  //search
  onSearchFee = (abc) => {
    // this.setState({ searchKey: abc, page: 1 }, () => {
    this.getTransList();
    // });
  };

  detailModalToggle = (userData) => {
    this.props.userDetail({ user_id: userData });
    this.setState({ detailModal: !this.state.detailModal });
  };
  clostDetailModal = () => {
    this.setState({ detailModal: !this.state.detailModal });
  };

  render() {
    let { handleSubmit, txnDetails } = this.props;
    return (
      <div>
        <Header headerTitle="deposit management" />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Transaction List</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchFee();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchFee()}
                      >
                        Search
                      </Button>

                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />

                      <Dropdown
                        className="selectUserStyle"
                        options={statusFilter}
                        placeholder="Filter by Status"
                        selection
                        onChange={(e, data) => this.filterByStatus(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={Filteroptions}
                        placeholder="Filter by Coin"
                        selection
                        onChange={(e, data) => this.filterByCoin(e, data)}
                      />
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.downLoadUserList()}
                        disabled
                      >
                        Download Deposit list
                      </Button>
                    </div>
                  </CardHeader>
                  <div responsive>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">ID (Email)</th>
                          {/* <th scope="col">Blockchain Transaction on</th> */}

                          <th scope="col">type</th>
                          <th scope="col">amount</th>
                          <th scope="col">Asset</th>
                          <th scope="col">status</th>

                          <th scope="col">blockchain_status</th>
                          <th scope="col">TX Time</th>
                          <th scope="col">Actions</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.getTransFeeList.map((usersList, index) => (
                          <tr className="table-tr" key={index}>
                            {/* <td>{usersList.email}</td> */}
                            <td>
                              <Link
                                onClick={() =>
                                  this.detailModalToggle(usersList)
                                }
                              >
                                {usersList.email ? usersList.email : "None"}
                              </Link>
                            </td>
                            {/* <td>
                            {usersList.req_type == "APP"
                              ? "Internal"
                              : "Completed"}
                          </td> */}

                            <td>{usersList.type.toUpperCase()}</td>
                            <td>{usersList.amount}</td>
                            <td>{usersList.coin_symbol.toUpperCase()}</td>
                            <td>{usersList.status.toUpperCase()}</td>

                            <td>{usersList.blockchain_status}</td>
                            <td>{listDateFormat(usersList.created_at)}</td>
                            {usersList.status !== "failed" && (
                              <td>
                                <span className="mb-0 text-sm">
                                  <Button
                                    color="success"
                                    onClick={() => this.openModel(usersList)}
                                  >
                                    Add Comment
                                  </Button>
                                </span>
                              </td>
                            )}
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color="success"
                                  onClick={() => this.viewDetails(usersList)}
                                >
                                  View Details
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))}
                        {this.props.getTransFeeList.length == 0 && (
                          <td>No record found</td>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  {this.props.getTransFeeList.length != 0 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) =>
                            this.changeTransPage(e, data)
                          }
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalTransRecords / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        {/* //comment modal */}

        <Modal isOpen={this.state.open} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Send Message</ModalHeader>
          <ModalBody>
            <Form>
              <Form.TextArea
                label="Message"
                placeholder="Message..."
                onChange={(e) => this.setMessage(e)}
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ open: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.sendMesage()}>
              Send
            </Button>
          </ModalFooter>
        </Modal>

        {/* detail modal */}

        {txnDetails && (
          <Modal isOpen={this.state.openDetails} toggle={this.toggleDetails}>
            <ModalHeader toggle={this.toggleDetails}>
              Transaction Details
            </ModalHeader>
            <ModalBody>
              <div className="userNameList">
                <div className="itemStyle">
                  <label style={{ color: "black" }}> From Address:</label>
                  <span style={{ color: "black" }}>
                    {txnDetails.from_adrs ? txnDetails.from_adrs : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "black" }}>To Address:</label>
                  <span style={{ color: "black" }}>
                    {txnDetails.to_adrs ? txnDetails.to_adrs : "None"}{" "}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "black" }}>Amount:</label>
                  <span style={{ color: "black" }}>
                    {txnDetails.amount ? txnDetails.amount : "None"}{" "}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "black" }}>Gas Limit:</label>
                  <span style={{ color: "black" }}>
                    {txnDetails.gas_limit}{" "}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "black" }}>Gas Price:</label>
                  <span style={{ color: "black" }}>
                    {txnDetails.gas_price}{" "}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "black" }}>Tx Hash:</label>
                  <span
                    style={{ color: "black" }}
                    onClick={() =>
                      this.redirectToHashSite(
                        txnDetails.coin_symbol,
                        txnDetails.tx_id
                      )
                    }
                  >
                    {txnDetails.tx_id ? txnDetails.tx_id : "None"}{" "}
                  </span>
                </div>
                {txnDetails.admin_comment !== "" && (
                  <div className="itemStyle">
                    <label style={{ color: "black" }}>Admin Comment:</label>
                    <span style={{ color: "black" }}>
                      {txnDetails.admin_comment
                        ? txnDetails.admin_comment
                        : "None"}{" "}
                    </span>
                  </div>
                )}
              </div>
            </ModalBody>
          </Modal>
        )}
        <UserDetail
          clostDetailModal={this.clostDetailModal}
          detailModal={this.state.detailModal}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    totalTransRecords: state.transaction.totalTransRecords,
    getTransFeeList: state.transaction.getTransFeeList,
    txnDetails: state.transaction.txnDetails,
    coinListCoinFilter: state.userList.coinListCoinFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionList: (filterType, data) =>
      dispatch(getTransactionList(filterType, data)),
    sendfailureMessage: (data) => dispatch(sendfailureMessage(data)),
    getTransactionDetails: (data) => dispatch(getTransactionDetails(data)),
    getCoinList: () => dispatch(getCoinListForFilter()),
    userDetail: (data) => dispatch(userDetail(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "ApprovedKyc" })(DepositManagement))
);
