import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
} from "reactstrap";
import {
  getReferralList,
  enableDisableReferral,
} from "../../Redux/actons/referral.action";
import { openCloseModal } from "../../Redux/actons/SubAdmin.action";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { IMAGE_PATH } from "../../constants/index";
import { Pagination, Confirm, GridColumn, Grid } from "semantic-ui-react";
import AddReferral from "./AddReferral/AddReferral";
import { DashboardResult } from "views/ContactQueryManagement/SubjectManagement/DashboardResult";

export class ReferralManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "30",
      page: "1",
      order: "",
      sortBy: "",
      coin_family: "",
      order: "asc",
      coinId: "",
      totalAmount: "",
      deleteConfirm: false,
      deleteData: {},
    };
  }
  componentDidMount = () => {
    this.getPairList();
  };
  componentWillUnmount = () => {
    this.props.openCloseModal();
  };

  getPairList = () => {
    const param = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    this.props.getReferralList(param);
  };

  enableDisablePair = (data, id) => {
    const param = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    const buttonState = { plan_id: id, status: data };
    this.props.enableDisableReferral(buttonState, param);
  };

  onEditPair = (data) => {
    this.props.history.push({
      pathname: `add-referral`,
      state: data,
    });
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getPairList();
    });
  };

  onIncentiveClick = (data) => {
    this.props.history.push({
      pathname: `referral-incentive`,
      state: data,
    });
  };

  deleteReward = (data, id, status) => {
    if (status == "active") {
      alert("Please inactive the plan before deleting.");
      return false;
    }
    let deleteData = { status: data, plan_id: id };
    this.setState({ deleteData: deleteData, deleteConfirm: true });
  };

  closeDeleteConfirm = () => {
    this.setState({ deleteData: {}, deleteConfirm: false });
  };

  checkForEnable = (list, data) => {
    if (data?.status == "active") {
      return true;
    } else if (
      list?.find(
        (el) => el.plan_type == data?.plan_type && el.status == "active"
      ) &&
      data?.status == "inactive"
    ) {
      return false;
    } else {
      return true;
    }
  };

  onConfirmation = () => {
    const buttonState = {
      status: this.state.deleteData.status,
      plan_id: this.state.deleteData.plan_id,
    };
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    this.props.enableDisableReferral(buttonState, params);
    this.setState({ deteteData: {}, deleteConfirm: false });
  };

  render() {
    let { handleSubmit, dashboard } = this.props;
    console.log(dashboard, "props", this.props.referralList);
    return (
      <div>
        <Header headerTitle="Referral Management" />
        <div>
          <div>
            <Confirm
              content={`Are you sure to delete this plan?`}
              style={{
                height: "auto",
                top: "5%",
                left: "auto",
                bottom: "auto",
                right: "auto",
              }}
              size="small"
              className="confirm-model"
              open={this.state.deleteConfirm}
              onCancel={this.closeDeleteConfirm}
              onConfirm={this.onConfirmation}
            />
          </div>

          <Container className="contentBlock" fluid>
            {/* Table */}
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Card className="shadow">
                <Grid.Row
                  columns={2}
                  // style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Total Referral"}
                      labResult={
                        dashboard?.activeList
                          ? dashboard?.activeList +
                            dashboard?.inActiveList +
                            dashboard?.deleteList
                          : "0"
                      }
                    />
                  </GridColumn>
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Active Referral"}
                      labResult={
                        dashboard?.activeList ? dashboard?.activeList : "0"
                      }
                    />
                  </GridColumn>

                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Inactive Referral"}
                      labResult={
                        dashboard?.inActiveList ? dashboard?.inActiveList : "0"
                      }
                    />
                  </GridColumn>
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Deleted Referral"}
                      labResult={
                        dashboard?.deleteList ? dashboard?.deleteList : "0"
                      }
                    />
                  </GridColumn>
                </Grid.Row>
              </Card>
            </div>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Link to="add-referral">
                        <Button color="success btnMamagePermission">
                          Add Referral
                        </Button>
                      </Link>
                      <Link to="referral-history">
                        <Button color="success btnMamagePermission">
                          View Referral History
                        </Button>
                      </Link>
                      <Link to="influencer-management">
                        <Button color="success btnMamagePermission">
                          Influencer Referral
                        </Button>
                      </Link>
                      <Link to="referral-management-mlm">
                        <Button color="success btnMamagePermission">
                          MLM Level
                        </Button>
                      </Link>
                      {/* <Link to="coin-wise-history">
                        <Button color="success btnMamagePermission">
                          Coin Wise History
                        </Button>
                      </Link> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Plan Type</th>
                        <th scope="col">Required Minimum amount</th>
                        <th scope="col">Minimum Amount</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props?.referralList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props?.referralList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data?.plan_type?.toUpperCase()}</td>
                            <td>{data?.require_min_amount?.toUpperCase()}</td>
                            <td>{data?.min_amount}</td>
                            <td>{data.status?.toUpperCase()}</td>
                            <td>
                              {/* <Button
                                color="primary"
                                onClick={() => this.onEditPair(data)}
                              >
                                Edit Referral
                              </Button> */}
                              <Button
                                color={
                                  data.status === "active"
                                    ? "danger"
                                    : "success"
                                }
                                onClick={() => {
                                  if (
                                    this.checkForEnable(
                                      this.props?.referralList,
                                      data
                                    ) == true
                                  ) {
                                    this.enableDisablePair(
                                      data.status === "active"
                                        ? "inactive"
                                        : "active",
                                      data.id
                                    );
                                  } else {
                                    alert(
                                      `${data?.plan_type?.toUpperCase()} is alreay active`
                                    );
                                  }
                                }}
                              >
                                {data.status === "active"
                                  ? "Disable"
                                  : "Enable"}
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => this.onIncentiveClick(data)}
                              >
                                View incentive
                              </Button>
                              <Button
                                color="danger"
                                onClick={() =>
                                  this.deleteReward(
                                    "deleted",
                                    data.id,
                                    data.status
                                  )
                                }
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {/* <CardFooter className="py-4"></CardFooter> */}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    referralList: state.referralList?.referralList?.rewardPlans?.rows,
    dashboard: state.referralList?.referralList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (data) => dispatch(reset(data)),
    openCloseModal: () => dispatch(openCloseModal(true)),
    getReferralList: (param) => dispatch(getReferralList(param)),
    enableDisableReferral: (data, params) =>
      dispatch(enableDisableReferral(data, params)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReferralManagement)
);
