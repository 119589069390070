import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const getUserBalanceList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/report/users/balancelist`,
    {},
    options,
    params
  );
};
const getTradingReports = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/report/tradingreport`,
    {},
    options,
    params
  );
};

const getTransactionList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/report/getTransactionWithdrawList`,
    {},
    options,
    params
  );
};

const getTokenDepositList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/report/getTokenDepositCost`,
    {},
    options,
    params
  );
};
export const ReportServices = {
  getUserBalanceList,
  getTradingReports,
  getTransactionList,
  getTokenDepositList
};
