import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, Form, Button } from "reactstrap";
import { required } from "redux-form-validators";
import { FormField } from "../../../components/FormField";
import { Field, reduxForm } from "redux-form";
import {
  verifyGoogleAuthRequestSecurity,
  openCloseModal
} from "../../../Redux/actons/fund.action";

export class Security extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    // if (this.props.user.cold_wallet_google2fa_status === 1) {
    //   this.props.openCloseModal(true);
    // } else {
    //   this.props.openCloseModal(false);
    // }
  };

  handleSubmit = values => {
    let { verifygoogleAuth,toggleColdModal } = this.props;

    verifygoogleAuth(values);
    //this.openCloseModal(true);
  };
  back = () => {
    this.props.openCloseModal(false)
  };
  render() {
    let { type, handleSubmit } = this.props;
    let { cold_wallet_google2fa_status } = this.props.user;

    type = "googleAuth";
    let heading = type === "Google";
    return (
      <div>
        <Modal
          isOpen={this.props?.openSecurityModal}
          //   toggle={this.back()}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalBody>
            <div className="userNameList">
              <h1 className="heading-type" style={{ color: "#fff" }}>
                Google Authentication Verification
              </h1>
              <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="input-field">
                  <Field
                    name="token"
                    component={FormField}
                    type="text"
                    label="Token:"
                    placeholder="Verify Token"
                    validate={[required()]}
                    autoFocus
                  />
                  <br></br>
                  <Button type="submit" color="info">
                    Verify
                  </Button>
                  <Button type="button" color="" onClick={() => this.back()}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.persist["c_user"],
    user: state.user["user"],
    openSecurityModal: state.funds.openSecurityModal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    verifygoogleAuth: values =>
      dispatch(verifyGoogleAuthRequestSecurity(values)),
    openCloseModal: status => dispatch(openCloseModal(status))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "securityColdForm" })(Security));
