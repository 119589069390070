import { actionTypes } from "../actons/interest.action";

const initialState = {
  currencyList: [],
  stakeInterestList: [],
  dailyInterestList: [],
  singleInterest: "",
  dailyInterestMeta: {},
};

const currencyInterest = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INTEREST_LIST:
      return {
        ...state,
        currencyList: action.data.data,
      };
    case actionTypes.SINGLE_INTEREST:
      return {
        ...state,
        singleInterest: action.data.data,
      };
    case actionTypes.STAKE_INTEREST_LIST:
      return {
        ...state,
        stakeInterestList1: action.data.data,
        //   };
        // case actionTypes.STAKE_INTEREST_LIST:
        //   return {
        // ...state,
        stakeInterestList: action.data.data.list,
      };
    case actionTypes.Daily_INTEREST_LIST:
      return {
        ...state,
        dailyInterestMeta: action.data.meta,
        //   };
        // case actionTypes.STAKE_INTEREST_LIST:
        //   return {
        // ...state,
        dailyInterestList: action.data.data,
      };

    default:
      return state;
  }
};

export default currencyInterest;
