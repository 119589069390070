import { actionTypes } from "../actons/account.action";

const initialState = {
  feeSettingDetails: [],
  isGoogleAuthForm: false,
  googleStep: 1,
  googleColdWalletStep: 1,
  googleAuthKeys: {},
  open: false,
  openGoogleModalDisable: false,
  openColdWalletGoogleModalDisable: false,
  openGoogleModalEnable: false,
  openColdWalletGoogleModalEnable: false,
  changePasswordModalOpen: false,
  openChangePassword: true,
  open2faModal: false,
  tokenForm: false,
  coldWalletGoogleAuthKeys: {},
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GOOGLE_AUTH_FORM_ACTIVE_DEACTIVE:
      return {
        ...state,
        isGoogleAuthForm: action.data
      };
    case actionTypes.SET_GOOGLE_AUTH_STEP:
      return {
        ...state,
        googleStep: action.data
      };
    case actionTypes.SAVE_GOOGLE_AUTH_KEY:
      return {
        ...state,
        googleAuthKeys: action.data
      };
    case actionTypes.OPEN_CLOSE_MODAL:
      return {
        ...state,
        open: action.data
      };
    case actionTypes.OPEN_GOOGLE_MODAL_DISABLE:
      return {
        ...state,
        openGoogleModalDisable: action.data
      };
    case actionTypes.OPEN_GOOGLE_MODAL_ENABLE:
      return {
        ...state,
        openGoogleModalEnable: action.data
      };
    case actionTypes.OPEN_COLD_WALLET_GOOGLE_MODAL_ENABLE:
      return {
        ...state,
        openColdWalletGoogleModalDisable: action.data
      };
    case actionTypes.OPEN_COLD_WALLET_GOOGLE_MODAL_DISABLE:
      return {
        ...state,
        openColdWalletGoogleModalEnable: action.data
      };
    case actionTypes.CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        changePasswordModalOpen: action.data
      };

    case actionTypes.OPEN_CHANGE_PASSWORD_AUTH:
      return {
        ...state,
        openChangePassword: action.data
      };

    case actionTypes.OPEN_CHNAGE_PASSWORD_2FA_MODAL:
      return {
        ...state,
        open2faModal: action.data
      };

    case actionTypes.GET_ADMIN_SETTING_DETAILS:
      let data = action.data;
      return {
        ...state,
        feeSettingDetails: data
      };

    case actionTypes.SET_COLD_WALLET_GOOGLE_AUTH_STEP:
      return {
        ...state,
        googleColdWalletStep: action.data
      };
    case actionTypes.SAVE_COLD_WALLET_GOOGLE_AUTH_KEY:
      return {
        ...state,
        coldWalletGoogleAuthKeys: action.data
      };

    default:
      return state;
  }
};

export default account;
