import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { Button } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../../components/FormField";
import { SelectField } from "../../../components/SelectField";
import { required } from "redux-form-validators";
import { amountLimit } from "../../../validators/customValidator";
import {
  pairCoinRequest,
  getPair,
  updatePair,
} from "../../../Redux/actons/pairCoin.action";
import { getCoinList } from "../../../Redux/actons/user.action";
import Header from "components/Headers/Header";
import { reset } from "redux-form";

export class ExchangeCoin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //   isCoin: false,
      id: "",
      defaultType: "",
      defaultAction: 1,
      selectedCoin: "",
      selectedCoinforSwap: "",
      feeCoin: "",
      markupRate: "",
      markdownRate: "",
      fee: "",
      isEdit: false,
    };
  }
  componentDidMount = async () => {
    this.props.getCoinList();
    console.log("pushed", this.props.location.pathname);
    if (this.props.location.pathname.includes("edit-pair-coin")) {
      const { id } = this.props.location.state;
      if (id != undefined) {
        this.setState({
          id: id,
          isEdit: true,
        });
      }
      await this.props.getPair(id);

      const {
        coin_1,
        coin_2,
        markup_rate,
        markdown_rate,
        fee_amount,
        fee_coin,
      } = this.props.pairCoin;
      this.setState({
        selectedCoin: coin_1,
        selectedCoinforSwap: coin_2,
        markupRate: markup_rate,
        markdownRate: markdown_rate,
        fee: fee_amount,
        feeCoin: fee_coin,
      });
    }
  };

  getDetails = (e, data) => {
    this.setState({ defaultType: data });
  };

  updateForm = (values) => {
    if (this.state.isEdit) {
      const formData = {
        id: this.state.id,
        markup_rate: this.state.markupRate,
        markdown_rate: this.state.markdownRate,
        fee_amount: this.state.fee,
        fee_coin: this.state.feeCoin,
      };
      this.props.updatePair(formData);
    } else {
      const formData = {
        coin_1: this.state.selectedCoin,
        coin_2: this.state.selectedCoinforSwap,
        markup_rate: this.state.markupRate,
        markdown_rate: this.state.markdownRate,
        fee: this.state.fee,
        fee_coin: this.state.feeCoin,
      };
      this.props.pairCoinRequest(formData);
    }
    this.props.reset("exchangeCoinForm");
  };
  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <div>
          <Header
            headerTitle={this.state.isEdit ? "Edit coin pair" : "Add coin pair"}
          />
        </div>
        <div style={{ padding: 30 }}>
          <Form onSubmit={handleSubmit(this.updateForm)}>
            <Form.Group>
              <Form.Field>
                <label>Choose Reward Coin</label>
                <Field
                  props={{ disabled: this.state.isEdit ? true : false }}
                  name="coin_1"
                  search={false}
                  selectOnBlur={false}
                  validate={[required()]}
                  component={SelectField}
                  defaultValue={this.state.selectedCoin}
                  children={this.props.coinsList}
                  placeholder="Select"
                  onChange={(e, data) => this.setState({ selectedCoin: data })}
                ></Field>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Choose Coin to pair</label>
                <Field
                  name="coin_2"
                  props={{ disabled: this.state.isEdit ? true : false }}
                  search={false}
                  selectOnBlur={false}
                  validate={this.state.isEdit ? [] : [required()]}
                  component={SelectField}
                  defaultValue={this.state.selectedCoinforSwap}
                  children={this.props.coinsList}
                  placeholder="Select"
                  onChange={(e, data) =>
                    this.setState({ selectedCoinforSwap: data })
                  }
                ></Field>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Mark up rate</label>
                <input
                  type="number"
                  name="markup_rate"
                  min="0"
                  value={this.state.markupRate}
                  validate={[required(), amountLimit()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ markupRate: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Mark down rate</label>
                <input
                  type="number"
                  name="markdown_rate"
                  min="0"
                  value={this.state.markdownRate}
                  validate={[required(), amountLimit()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ markdownRate: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Fee</label>
                <input
                  type="number"
                  name="fee"
                  min="0"
                  value={this.state.fee}
                  validate={[required(), amountLimit()]}
                  component={FormField}
                  onChange={(e, data) => this.setState({ fee: e.target.value })}
                ></input>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Fee Coin</label>
                <Field
                  // props={{ disabled: this.state.isEdit ? true : false }}
                  name="fee_coin"
                  search={false}
                  selectOnBlur={false}
                  // validate={this.state.isEdit ? [] : []}
                  component={SelectField}
                  defaultValue={this.state.feeCoin}
                  children={this.props.coinsList}
                  placeholder="Select"
                  onChange={(e, data) => this.setState({ feeCoin: data })}
                ></Field>
              </Form.Field>
            </Form.Group>
            {/* {this.state.isCoin && ( */}
            <Form.Field className="addBtn">
              <Button type="submit" color="success">
                Submit
              </Button>
            </Form.Field>
            {/* // )} */}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coinsList: state.user.coinsList,
    pairCoin: state.pairCoinList.pairCoin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCoinList: () => dispatch(getCoinList()),
    pairCoinRequest: (data) => dispatch(pairCoinRequest(data)),
    getPair: (data) => dispatch(getPair(data)),
    updatePair: (data) => dispatch(updatePair(data)),
    reset: (data) => dispatch(reset(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "exchangeCoinForm" })(ExchangeCoin));
