import React, { Component } from "react";
import { connect } from "react-redux";
// import { getFundHistory } from "../../../Redux/actons/fund.action";
import {
  getUserFundListView,
  getUserFund,
} from "../../../Redux/actons/fund.action";
import { withRouter } from "react-router";
import {
  Card,
  CardHeader,
  //   Table,
  Container,
  Row,
  Input,
  CardFooter,
  Breadcrumb,
  Button,
  BreadcrumbItem,
} from "reactstrap";
import { Table, TableRow } from "semantic-ui-react";
import { listDateFormat } from "../../../utils/utils";
import Header from "components/Headers/Header";
import { Pagination, Form, Dropdown } from "semantic-ui-react";
// import { toast } from "../../components/Toast/Toast";
// import CustomHeader from "../../components/Headers/CustomHeader";
import { Link } from "react-router-dom";
import AddFunds from "../AddFunds";
class ViewUserFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
      coin_name: "",
      coin_id: "",
      isAddFunds: false,
    };
  }
  componentDidMount = () => {
    //   this.checkRouteValid();

    this.getUserList();
  };
  //   checkRouteValid = async () => {
  //     if (this.props.user.role == 2) {
  //       var isfound = false;
  //       await this.props.permissions.forEach((element) => {
  //         if (element.title == "Fund Management") {
  //           isfound = true;
  //         }
  //       });
  //       if (!isfound) {
  //         this.props.history.push("/");
  //       }
  //     }
  //   };
  //   getFundHistory = () => {
  //     let data = {
  //       searchKey: this.state.searchKey,
  //       order: this.state.order,
  //       perPage: this.state.perPage,
  //       page: this.state.page,
  //     };
  //     this.props.getFundHistory(data);
  //   };
  //   onSearch = (text) => {
  //     this.setState({ searchKey: text, page: 1 }, () => {
  //       this.getFundHistory();
  //     });
  //   };
  //   //page change
  //   changeTransPage = (e, data) => {
  //     this.setState({ page: data.activePage }, () => {
  //       this.getFundHistory();
  //     });
  //   };
  getUserList = () => {
    let data = {
      //   searchKey: this.state.searchKey,
      //   order: this.state.order,
      user_id: window.location.hash.split("?")[1],
      //   page: this.state.page,
      //   coin_type: this.state.coin_type,
      //   list_type: this.state.listType,
    };
    this.props.getUserFundListView(data);
  };
  openAddFunds = async (user, islock, data) => {
    this.setState({
      userId: user,
      isLockFunds: islock,
      coin_id: data.coin_id,
      coin_name: data.coin_name,
    });
    // console.log("this.props.coin_id", data);
    this.setState({ coin_id: data });

    // let queryParams = {
    //   user_id: user,
    //   coin_id: data.coin_id,
    // };
    // console.log(queryParams);
    // await this.props.getUserFund(queryParams);
    // setTimeout(() => {
    this.setState({ isAddFunds: !this.state.isAddFunds });
    // }, 100);
  };
  //******************* */   console.log(e, "this.props.coin_id", data);
  // this.setState({ coin_id: data });

  // let queryParams = {
  //   user_id: this.props.userId,
  //   coin_id: data,
  // };
  // console.log(queryParams);
  // this.props.getUserFund(queryParams);

  //******************* */

  openCloseAddFunds = () => {
    this.setState({ coin_name: "", coin_id: "" });
    this.setState({ isAddFunds: !this.state.isAddFunds });
  };

  redirectLockFund = (id) => {
    // console.log("window ------>>>> UserId===== search", id);
    this.props.history.push({
      pathname: `lock-fund-history`,
      state: id.toString(),
    });
  };
  render() {
    // console.log(
    //   "window ------>>>> UserId===== search",
    //   window.location.hash.split("?")[1]
    // );
    let userId = window.location.hash.split("?")[1];
    let lists = this.props.getFundsList;
    let usersList = [];
    let userEmail;
    if (lists?.length > 0) {
      usersList = lists?.find(
        (data) => data.user_id == userId
      )?.coinAllBalances;
      userEmail = lists?.find((data) => data.user_id == userId)?.email;
    }
    // console.log(
    //   usersList,
    //   "coinAllBalances window ------>>>> UserId===== lists",
    //   this.props.getViewFundsList
    // );
    var fdata = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    let user_id = window.location.hash.split("?")[1];
    return (
      <div>
        <Header headerTitle="Fund history" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link
                to="fund-management"
                onClick={() => this.props.history.goBack()}
              >
                Fund Management
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>View User Fund</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Fund List</h3>
                      <h5 className="mb-2">
                        Email : <span>{userEmail}</span>
                      </h5>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Link to={`lock-fund-history?${user_id.toString()}`}>
                        <Button
                          color="success btnMamagePermission"
                          // onClick={() =>
                          //   this.redirectLockFund(usersList.user_id)
                          // }
                        >
                          Lock fund history
                        </Button>
                      </Link>
                    </div>
                    <div className="selectSearchOption">
                      {/* <Input
                        placeholder="Search"
                        onChange={(e) => this.onSearch(`${e.target.value}`)}
                        value={this.state.searchKey}
                      ></Input> */}
                      {/* <Link to="lock-fund-history">
                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.redirectLockFund(userId)}
                        >
                          Lock fund history
                        </Button>
                      </Link> */}
                    </div>
                  </CardHeader>
                  {/* ******************************** */}
                  <Table celled>
                    <Table.Header>
                      <Table.Row active>
                        <Table.HeaderCell>Sr No</Table.HeaderCell>
                        <Table.HeaderCell>Crypto Name</Table.HeaderCell>

                        <Table.HeaderCell>Balance</Table.HeaderCell>
                        <Table.HeaderCell>Add Funds</Table.HeaderCell>
                        <Table.HeaderCell>Manage Funds</Table.HeaderCell>

                        {/* <Table.HeaderCell>
                          WITHDRAW FEE(Income)
                        </Table.HeaderCell>
                        <Table.HeaderCell>WITHDRAW FEE(Outgo)</Table.HeaderCell>
                        <Table.HeaderCell>DEPOSIT FEE(Outgo)</Table.HeaderCell>
                        <Table.HeaderCell>TOTAL</Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {usersList?.length != 0 &&
                        usersList?.map((data, indexRow) => (
                          <Table.Row key={indexRow}>
                            <Table.Cell>{indexRow + 1}</Table.Cell>
                            <Table.Cell>
                              {`${
                                data.coin_name
                              } (${data.coin_symbol.toUpperCase()})`}
                              {/* {data.coin_symbol.toUpperCase()} */}
                            </Table.Cell>

                            <Table.Cell>{data.balance}</Table.Cell>
                            <Table.Cell>
                              <Button
                                color="success btnMamagePermission"
                                onClick={() =>
                                  this.openAddFunds(userId, false, data)
                                }
                              >
                                Add Funds
                              </Button>
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                color="success btnMamagePermission"
                                onClick={() =>
                                  this.openAddFunds(userId, true, data)
                                }
                              >
                                Manage Funds
                              </Button>
                            </Table.Cell>
                            {/* <Table.Cell>{data.withdrawFeeIncome}</Table.Cell>
                            <Table.Cell>{data.withdrawRevenueOutgo}</Table.Cell>
                            <Table.Cell>{data.depositRevenueOutgo}</Table.Cell>
                            <Table.Cell>
                              {data.otcIncome +
                                data.withdrawFeeIncome +
                                data.withdrawRevenueOutgo +
                                data.depositRevenueOutgo}
                            </Table.Cell> */}
                          </Table.Row>
                        ))}
                      {/* {Object.keys(this.props.userAccounts).length != 0 &&
                  this.props.userAccounts.revenueAccounting.map(
                    (data, indexRow) => (
                      <Table.Row key={indexRow}>
                        <Table.Cell>
                          {data.coin_symbol.toUpperCase()}
                        </Table.Cell>

                        <Table.Cell>{data.otcIncome}</Table.Cell>
                        <Table.Cell>{data.withdrawFeeIncome}</Table.Cell>
                        <Table.Cell>{data.withdrawRevenueOutgo}</Table.Cell>
                        <Table.Cell>{data.depositRevenueOutgo}</Table.Cell>
                        <Table.Cell>
                          {data.otcIncome +
                            data.withdrawFeeIncome +
                            data.withdrawRevenueOutgo +
                            data.depositRevenueOutgo}
                        </Table.Cell>
                      </Table.Row>
                    )
                  )} */}
                    </Table.Body>
                  </Table>
                  {/* ******************************** */}
                  {/* add funds modal  */}
                  {this.state.isAddFunds && (
                    <AddFunds
                      isAddFunds={this.state.isAddFunds}
                      toggle={this.openCloseAddFunds}
                      getUserList={this.getUserList}
                      userId={this.state.userId}
                      fdata={fdata}
                      coin_id={this.state.coin_id}
                      islockFunds={this.state.isLockFunds}
                    />
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log("FUNDS", state);
  return {
    getViewFundsList: state.funds.getViewFundsList,
    getFundsList: state.funds.getViewFundsList,
    fundHistory: state.funds.fundHistory,
    fundHistoryTotal: state.funds.fundHistoryTotal,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserFundListView: (data) => dispatch(getUserFundListView(data)),
    getUserFund: (data) => dispatch(getUserFund(data)),
    // getFundHistory: (data) => dispatch(getFundHistory(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewUserFunds)
);
