import React, { Component } from "react";
import { Form, Dropdown, Radio } from "semantic-ui-react";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../../components/FormField";
import { SelectField } from "../../../components/SelectField";
import { required } from "redux-form-validators";
import {
  getOrderSettings,
  viewPair,
  addPairs,
  EditPair,
  getCoinList,
  getcoinmarketcap,
  getcoingecko,
} from "../../../Redux/actons/user.action";
import Header from "components/Headers/Header";
import { Link } from "react-router-dom";
import { history } from "../../../Redux/store/history";
// import default from '../../../../build/static/js/2.2f4db25b.chunk';

const trade_fee_type = [
  {
    key: "fixed",
    text: "Fixed",
    value: "fixed",
  },
  {
    key: "percentage",
    text: "Percentage",
    value: "percentage",
  },
];
const UseBaseCoin = [
  { key: "k", text: "INR", value: "INR" },
  // { key: "m", text: "ETH", value: "ETH" },
  // { key: "k", text: "USDT", value: "USDT" },
  // { key: "k", text: "TRON", value: "TRON" },
];
export class OrderSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      markupRate: "",
      selectedCoin: "",
      fee: "",
      trade_buy_fee: "",
      trade_sell_fee: "",
      tradeType: "flat",
      markUp: true,
      markDown: false,
      fee_type: "",
      set_price: "",
      source: "                     ",
      price: "",
      buy_max: "",
      buy_min: "",
      buy_fee: "",
      sell_max: "",
      sell_fee: "",
      sell_min: "",
      plan_tenure_type: "",
      SecondaryCoin: "",
      BaseCoin: "INR",
      pair_name: "",
      mark_type: "",
      // SecondaryCoin: "",
      mark_up: "",
      mark_down: "",
      trading_fee: "",
      buttonName: "Add",
      source: "",
      coinGecko: false,
      noneData: false,
      coinMarketCap: false,
    };
    // this.updateTradeSetting = this.updateTradeSetting.bind(this);
  }
  componentDidMount = async () => {
    console.log("  --- idddd", window.location);
    let id = window.location.hash.split("?")[1];
    if (id) {
      await this.props.viewPair(window.location.hash.split("?")[1]);
    }
    await this.props.getCoinList();
    this.setupTradeList();
    this.handleError();
  };

  handleRadioGecko = () =>
    this.setState({
      source: "coin_gicko",
      coinGecko: true,
      noneData: false,
      coinMarketCap: false,
    });
  handleRadioNoneData = () =>
    this.setState({
      source: "none",
      coinGecko: false,
      noneData: true,
      coinMarketCap: false,
    });
  handleRadioMarketCap = () =>
    this.setState({
      source: "cmc",
      coinGecko: false,
      coinMarketCap: true,
      noneData: false,
    });

  handleRadioMarkUp = () =>
    this.setState({
      markUp: true,
      markDown: false,
    });
  handleRadioMarkDown = () =>
    this.setState({
      markUp: false,
      markDown: true,
    });

  AddPairClick = (currency_type) => {
    if (
      // this.state.mark_type === "" ||
      // this.state.plan_tenure_type === "" ||
      this.state.buy_min === "" ||
      this.state.buy_max === "" ||
      this.state.buy_fee === "" ||
      this.state.sell_min === "" ||
      this.state.sell_max === "" ||
      this.state.sell_fee === "" ||
      this.state.mark_up === "" ||
      this.state.fee_type === "" ||
      // this.state.markUp === "" ||
      // this.state.trading_fee === "" ||
      // this.state.BaseCoin === "" ||
      this.state.SecondaryCoin === ""
    ) {
      alert("all fields are required");
      return;
    }
    if (
      (this.state.source === "none" && this.state.set_price == "") ||
      (this.state.source === "" && this.state.set_price == "")
    ) {
      alert("price fields is required");
      return;
    }
    let markSUP = 0;
    let markSDown = 0;
    if (this.state.markDown) {
      markSUP = 0;
      markSDown = this.state.mark_up;
    } else {
      markSUP = this.state.mark_up;
      markSDown = 0;
    }

    let set_price = 0;
    if (this.state.source == "none") {
      set_price = this.state.set_price;
    } else {
      set_price = 0;
    }
    const data = {
      // pair_name: `${this.state.BaseCoin}_${this.state.SecondaryCoin}`,
      pair_name: `${this.state.SecondaryCoin}`,
      mark_up: markSUP,
      mark_down: markSDown,
      sell_min: Number(this.state.sell_min),
      buy_min: Number(this.state.buy_min),
      sell_max: Number(this.state.sell_max),
      buy_max: Number(this.state.buy_max),
      buy_fee: this.state.buy_fee,
      sell_fee: this.state.sell_fee,
      is_active: "1",
      fee_type: this.state.fee_type,
      source: this.state.source,
      price: set_price,
    };
    this.props.addPairs(data);
    this.props.history.goBack();
    // this.toggleFee();
    // }
  };
  EditPairClick = (currency_type) => {
    if (
      // this.state.mark_type === "" ||
      // this.state.plan_tenure_type === "" ||
      this.state.buy_min === "" ||
      this.state.buy_max === "" ||
      this.state.buy_fee === "" ||
      this.state.sell_min === "" ||
      this.state.sell_max === "" ||
      this.state.sell_fee === "" ||
      this.state.mark_up === "" ||
      this.state.fee_type === "" ||
      // this.state.markUp === "" ||
      // this.state.trading_fee === "" ||
      // this.state.BaseCoin === "" ||
      this.state.SecondaryCoin === ""
    ) {
      alert("all fields are required!");
      return;
    }

    if (this.state.source === "none" && this.state.set_price == "") {
      alert("price fields is required");
      return;
    }
    let markSUP = 0;
    let markSDown = 0;
    if (this.state.markDown) {
      markSUP = 0;
      markSDown = this.state.mark_up;
    } else {
      markSUP = this.state.mark_up;
      markSDown = 0;
    }
    let set_price = 0;
    if (this.state.source == "none") {
      set_price = this.state.set_price;
    } else {
      set_price = 0;
    }

    const data = {
      // pair_name: `${this.state.BaseCoin}_${this.state.SecondaryCoin}`,
      pair_name: `${this.state.SecondaryCoin}`,
      mark_up: markSUP,
      mark_down: markSDown,
      sell_min: Number(this.state.sell_min),
      buy_min: Number(this.state.buy_min),
      sell_max: Number(this.state.sell_max),
      buy_max: Number(this.state.buy_max),
      buy_fee: this.state.buy_fee,
      sell_fee: this.state.sell_fee,
      is_active: "1",
      fee_type: this.state.fee_type,
      source: this.state.source,
      price: set_price,
    };
    this.props.EditPair(data, window.location.hash.split("?")[1]);
    this.props.history.goBack();
    // this.toggleFee();
    // }
  };
  listByTradeType = (e, data) => {
    if (data.value !== "none") {
      this.setState({ plan_tenure_type: data.value }, () => {
        // this.getCoinValue(); //
      });
    } else {
      this.setState({ plan_tenure_type: "" }, () => {
        // this.getCoinValue(); //
      });
    }
  };

  listByBaseCoin = (e, data) => {
    if (data.value !== "none") {
      this.setState({ BaseCoin: data.value }, () => {
        // this.getCoinValue(); //
      });
    } else {
      this.setState({ BaseCoin: "" }, () => {
        // this.getCoinValue(); //
      });
    }
  };
  listBySecondaryCoin = (e, data) => {
    if (data.value !== "none") {
      this.setState({ SecondaryCoin: data.value }, () => {
        this.props.getcoinmarketcap({ symbol: data.value.toLowerCase() }); //
        this.props.getcoingecko({ symbol: data.value.toLowerCase() }); //
      });
    } else {
      this.setState({ SecondaryCoin: "" }, () => {
        // this.getCoinValue(); //
      });
    }
  };
  listByFeeType = (e, data) => {
    if (data.value !== "none") {
      this.setState({ fee_type: data.value }, () => {
        // this.props.getcoinmarketcap({ symbol: data.value.toLowerCase() }); //
        // this.props.getcoingecko({ symbol: data.value.toLowerCase() }); //
      });
    } else {
      this.setState({ fee_type: "" }, () => {
        // this.getCoinValue(); //
      });
    }
  };
  setupTradeList() {
    const tradeList = this.props.tradeSettingList;
    console.log("-----------------trade", tradeList);
    this.setState({
      trade_buy_fee: tradeList?.find((el) => el.type == "trade_buy_fee")?.value,
      trade_sell_fee: tradeList?.find((el) => el.type == "trade_sell_fee")
        ?.value,
      tradeType: tradeList?.find((el) => el.type == "trade_fee_type")?.value,
    });
  }
  handleError = () => {
    this.setState({
      SecondaryCoin: this.props.saveviewPair?.pair_name
        ? this.props.saveviewPair?.pair_name?.toUpperCase()
        : "",
      markUp: this.props.saveviewPair?.mark_down != "0" ? false : true,
      markDown: this.props.saveviewPair?.mark_down != "0" ? true : false,
      buy_max: this.props.saveviewPair?.buy_max
        ? this.props.saveviewPair?.buy_max
        : "",
      buy_min: this.props.saveviewPair?.buy_min
        ? this.props.saveviewPair?.buy_min
        : "",
      buy_fee: this.props.saveviewPair?.buy_fee
        ? this.props.saveviewPair?.buy_fee
        : "",
      source: this.props.saveviewPair?.source
        ? this.props.saveviewPair.source
        : "",
      fee_type: this.props.saveviewPair?.fee_type
        ? this.props.saveviewPair.fee_type
        : "",
      set_price: this.props.saveviewPair?.fee_type
        ? this.props.saveviewPair?.current_price
        : "",
      sell_max: this.props.saveviewPair.sell_max
        ? this.props.saveviewPair.sell_max
        : "",
      sell_fee: this.props.saveviewPair?.sell_fee
        ? this.props.saveviewPair?.sell_fee
        : "",
      sell_min: this.props.saveviewPair.sell_min
        ? this.props.saveviewPair?.sell_min
        : "",
      plan_tenure_type: "",
      mark_up:
        Number(this.props.saveviewPair?.mark_down) > 0
          ? this.props.saveviewPair?.mark_down
          : this.props.saveviewPair?.mark_up,
      buttonName: window.location.hash.split("?")[1] ? "Edit" : "Add",
    });
  };
  render() {
    // let { handleSubmit } = this.props;
    let {
      handleSubmit,
      savecoinmarketcap,
      savecoingecko,
      secondaryCoinList,
      saveviewPair,
    } = this.props;
    let defaultData = this.props.saveviewPair?.pair_name;
    console.log(
      secondaryCoinList,
      "saveviewPair----",
      this.state.SecondaryCoin,
      " ---- --- defaultData   ",
      defaultData?.toUpperCase()
    );

    return (
      <div>
        <div>
          <Header headerTitle="Order Settings" />
        </div>
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="order-book-engine">Order Book Engine</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Add Pair</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div style={{ padding: 30 }}>
          <Form>
            <Form.Group>
              <Form.Field>
                <label>Base Coin</label>

                <Dropdown
                  className="selectUserStyle btnMamagePermission"
                  options={UseBaseCoin}
                  placeholder="select base coin"
                  value={this.state.BaseCoin}
                  selection
                  onChange={(e, data) => this.listByBaseCoin(e, data)}
                />
              </Form.Field>
              <Form.Field>
                <label>Execution Coin</label>

                <Dropdown
                  className="selectUserStyle btnMamagePermission"
                  options={secondaryCoinList}
                  placeholder="select base coin"
                  value={this.state.SecondaryCoin}
                  defaultValue={defaultData?.toUpperCase()}
                  selection
                  onChange={(e, data) => this.listBySecondaryCoin(e, data)}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Fee Type</label>

                <Dropdown
                  className="selectUserStyle btnMamagePermission"
                  options={trade_fee_type}
                  placeholder="select fee type"
                  value={this.state.fee_type}
                  selection
                  onChange={(e, data) => this.listByFeeType(e, data)}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ color: "#000" }}>
                Select Price Fetched Method
              </label>
              <div style={{ alignItems: "end" }}>
                <Form.Field>
                  <Radio
                    label="Coin Gecko"
                    name="coinGecko"
                    value={1}
                    disabled={!savecoingecko}
                    // checked={this.state.coinGecko}
                    checked={this.state.source == "coin_gicko"}
                    onChange={this.handleRadioGecko}
                  />{" "}
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  {/* </Form.Field>
                <Form.Field> */}
                  <Radio
                    label="Coin Market Cap"
                    name="coinMarketCap"
                    value={1}
                    disabled={!savecoinmarketcap}
                    // checked={this.state.coinMarketCap}
                    checked={this.state.source == "cmc"}
                    onChange={this.handleRadioMarketCap}
                  />{" "}
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  {/* <Form.Field style={{ display: "flex", flexDirection: "row" }}> */}
                  <Radio
                    label="None"
                    name="noneData"
                    value={1}
                    // checked={this.state.noneData}
                    checked={this.state.source == "none"}
                    onChange={this.handleRadioNoneData}
                  />{" "}
                  &nbsp;
                  {/* </Form.Field> */}
                </Form.Field>
              </div>
            </Form.Group>
            <Form.Group>
              {" "}
              {this.state.source == "none" && (
                <Form.Field>
                  <label style={{ color: "#000" }}>Price</label>
                  <input
                    className="no-arrows"
                    name="set_price"
                    placeholder="Price"
                    value={this.state.set_price}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ set_price: e.target.value })
                    }
                  ></input>
                </Form.Field>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Mark Up / Mark Down in %</label>
                <input
                  className="no-arrows"
                  name="sell_fee"
                  placeholder="Mark Up"
                  value={this.state.mark_up}
                  type="number"
                  onChange={(e, data) =>
                    this.setState({ mark_up: e.target.value })
                  }
                ></input>
              </Form.Field>
              <Form.Field>
                <label>Select Condition</label>
                <Radio
                  label="Mark Up"
                  name="markUp"
                  value={1}
                  checked={this.state.markUp}
                  onChange={this.handleRadioMarkUp}
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <Radio
                  label="Mark Down"
                  name="markDown"
                  value={1}
                  checked={this.state.markDown}
                  onChange={this.handleRadioMarkDown}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Min Limit on Buy</label>
                <input
                  className="no-arrows"
                  name="buy_fee"
                  placeholder="Min Buy"
                  value={this.state.buy_min}
                  type="number"
                  onChange={(e, data) =>
                    this.setState({ buy_min: e.target.value })
                  }
                ></input>
              </Form.Field>
              <Form.Field>
                <label>Max Limit on Buy</label>
                <input
                  className="no-arrows"
                  name="buy_fee"
                  placeholder="Max Buy"
                  value={this.state.buy_max}
                  type="number"
                  onChange={(e, data) =>
                    this.setState({ buy_max: e.target.value })
                  }
                ></input>
              </Form.Field>
              <Form.Field>
                <label>Buy Fee</label>
                <input
                  className="no-arrows"
                  name="buy_fee"
                  placeholder="Buy Fee"
                  value={this.state.buy_fee}
                  type="number"
                  onChange={(e, data) =>
                    this.setState({ buy_fee: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Min Limit on Sell</label>
                <input
                  className="no-arrows"
                  name="sell_fee"
                  placeholder="Min Sell"
                  value={this.state.sell_min}
                  type="number"
                  onChange={(e, data) =>
                    this.setState({ sell_min: e.target.value })
                  }
                ></input>
              </Form.Field>
              {/* </Form.Group>
              <Form.Group> */}
              <Form.Field>
                <label>Max Limit on Sell</label>
                <input
                  className="no-arrows"
                  name="sell_fee"
                  placeholder="Max Sell"
                  value={this.state.sell_max}
                  type="number"
                  onChange={(e, data) =>
                    this.setState({ sell_max: e.target.value })
                  }
                ></input>
              </Form.Field>
              <Form.Field>
                <label>Sell Fee</label>
                <input
                  className="no-arrows"
                  name="sell_fee"
                  placeholder="Sell Fee"
                  value={this.state.sell_fee}
                  type="number"
                  onChange={(e, data) =>
                    this.setState({ sell_fee: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>
          </Form>
          <Button color="error" onClick={() => history.goBack()}>
            Back
          </Button>
          <Button
            color="success"
            // onClick={() => this.updateCoinValueClick()}
            onClick={() => {
              if (this.state.buttonName == "Add") {
                this.AddPairClick();
              } else {
                this.EditPairClick();
              }
            }}
          >
            {this.state.buttonName}
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    coinList: state.user.coinsList,
    secondaryCoinList: state.user.secondaryCoinList,
    tradeSettingList: state.user.tradeSettingList,
    saveOrderDetails: state.user.saveOrderDetails,
    saveviewPair: state.user.saveviewPair,
    savecoinmarketcap: state.user.savecoinmarketcap,
    savecoingecko: state.user.savecoingecko,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    viewPair: (data) => dispatch(viewPair(data)),
    getCoinList: () => dispatch(getCoinList()),
    addPairs: (data) => dispatch(addPairs(data)),
    EditPair: (data, id) => dispatch(EditPair(data, id)),
    getcoinmarketcap: (data) => dispatch(getcoinmarketcap(data)),
    getcoingecko: (data) => dispatch(getcoingecko(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "tradeSettings" })(OrderSetting));
