import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { Pagination, Dropdown, Form } from "semantic-ui-react";
import { Toast } from "../../../components/Toast/Toast";
import CustomHeader from "../../../components/Headers/CustomHeader";
import { withRouter } from "react-router";
import {
  getHotWalletBalance,
  getColdWalletHistory,
} from "../../../Redux/actons/fund.action";
import { Link } from "react-router-dom";
import {
  listDateFormat,
  convertExponentialToDecimal,
} from "../../../utils/utils";
import {
  API_HOST,
  BTC_TXN_BTC,
  ETH_TXN_ETH,
  BTC_ADDRESS_BTC,
  ETH_ADDRESS_ETH,
  BSC_TXN_BSC,
  MATIC_TXN_MATIC,
  MATIC_ADDRESS_MATIC,
  BSC_ADDRESS_BSC,
} from "../../../constants/index";

import { reset } from "redux-form";
import { toast } from "../../../components/Toast/Toast";
import Header from "components/Headers/Header";
const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
export class HotwalletFundsHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "desc",
      sortBy: "",
    };
  }
  componentDidMount = () => {
    this.getColdWalletHistorys();
    this.checkRouteValid();
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      var isfound = false;
      // check user Permissions if route accessible
      await this.props.permissions.forEach((element) => {
        if (element.title == "Hot Wallet Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };
  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getColdWalletHistorys();
    });
  };
  searchUser = (value) => {
    this.getColdWalletHistorys();
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getColdWalletHistorys();
    });
  };
  getColdWalletHistorys = () => {
    let data = {
      page: this.state.page,
      perPage: this.state.perPage,
      order: this.state.order,
      searchKey: this.state.searchKey,
    };
    this.props.getColdWalletHistory(data);
  };
  toggleModal = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };
  openModal = (data) => {
    this.setState({
      isSendColdWallet: !this.state.isSendColdWallet,
      coinId: data.coin_symbol,
      totalAmount: data.coin_symbol != "btc" ? data.amount : data.amount.result,
    });
  };
  toggleColdModal = () => {
    this.setState({
      isSendColdWallet: !this.state.isSendColdWallet,
      coinId: "",
    });
    this.props.reset("sendToColdWalletForm");
  };

  getFundList = () => {
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    // this.props.getAllWithdrawList(data);
  };

  //page change
  changeApprovedPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getWithdrawList();
    });
  };
  //search
  onSearchWithdraw = (abc) => {
    console.log(abc);
    this.setState({ searchKey: abc, page: 1 }, () => {
      //   this.getWithdrawList();
    });
  };

  close = () => {
    this.setState({ openConfirm: false });
  };

  redirectToHashSite = (coin, hash) => {
    if (coin == "btc") {
      window.open(`${BTC_TXN_BTC}/${hash}`, "_blank");
    }
    if (coin == "eth" || coin == "ida" || coin == "orbs") {
      window.open(`${ETH_TXN_ETH}/${hash}`, "_blank");
    }
    if (coin == "bnb" || coin == "busd" || coin == "rena") {
      // window.open(
      //   `https://live.blockcypher.com/btc-testnet/tx/${hash}`,
      //   "_blank"
      // );
      window.open(`${BSC_TXN_BSC}/${hash}`, "_blank");
    }
    if (coin == "matic") {
      window.open(`${MATIC_TXN_MATIC}/${hash}`, "_blank");
    }
  };

  redirectUrl = (coin, address) => {
    if (coin == "btc") {
      window.open(`${BTC_ADDRESS_BTC}/${address}`, "_blank");
    }
    if (coin == "eth" || coin == "ida" || coin == "orbs") {
      window.open(`${ETH_ADDRESS_ETH}/${address}`, "_blank");
    }
    if (coin == "bnb" || coin == "busd" || coin == "rena") {
      window.open(`${BSC_ADDRESS_BSC}/${address}`, "_blank");
    }
    if (coin == "matic") {
      window.open(`${MATIC_ADDRESS_MATIC}/${address}`, "_blank");
    }
  };

  redirectUrlTo = (coin, address) => {
    if (coin == "btc") {
      window.open(`${BTC_ADDRESS_BTC}/${address}`, "_blank");
    }
    if (coin == "eth" || coin == "ida" || coin == "orbs") {
      window.open(`${ETH_ADDRESS_ETH}/${address}`, "_blank");
    }
    if (coin == "bnb" || coin == "busd" || coin == "rena") {
      window.open(`${BSC_ADDRESS_BSC}/${address}`, "_blank");
    }
    if (coin == "matic") {
      window.open(`${MATIC_ADDRESS_MATIC}/${address}`, "_blank");
    }
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Cold Wallet Transaction History" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="hot-wallet-funds">Hot Wallet Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              Cold Wallet Transaction History
            </BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        style={{
                          color: "black",
                        }}
                        // onChange={e => this.searchUser(`${e.target.value}`)}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.searchUser();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.searchUser()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />
                      <Link to="hot-wallet-funds">
                        <Button color="success btnMamagePermission">
                          Back
                        </Button>
                      </Link>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">TXID</th>

                        <th scope="col">From Address</th>

                        <th scope="col">To Address</th>
                        <th scope="col">Asset</th>
                        <th scope="col">Amount</th>

                        <th scope="col">gas Limit</th>
                        <th scope="col">gas Price</th>
                        <th scope="col">gas reverted</th>
                        <th scope="col">Added On</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.coldHistory.map((data, index) => (
                        <tr className="table-tr" key={index}>
                          <a>
                            <td
                              title={data.tx_id}
                              onClick={() =>
                                this.redirectToHashSite(
                                  data.cold_coin_data.coin_symbol,
                                  data.tx_id
                                )
                              }
                            >
                              {data.tx_id
                                ? data.tx_id.slice(0, 12) + "..."
                                : "NONE"}
                            </td>
                          </a>
                          <td
                            title={data.from_adrs}
                            onClick={() =>
                              this.redirectUrl(
                                data.cold_coin_data.coin_symbol,
                                data.from_adrs
                              )
                            }
                          >
                            {data.from_adrs
                              ? data.from_adrs.slice(0, 12) + "..."
                              : "NONE"}
                          </td>

                          <td
                            title={data.to_adrs}
                            onClick={() =>
                              this.redirectUrlTo(
                                data.cold_coin_data.coin_symbol,
                                data.to_adrs
                              )
                            }
                          >
                            {data.to_adrs
                              ? data.to_adrs.slice(0, 12) + "..."
                              : "NONE"}
                          </td>
                          <td>
                            {data.cold_coin_data.coin_symbol.toUpperCase()}
                          </td>
                          <td>{data.amount}</td>

                          <td>{data.gas_limit ? data.gas_limit : "None"}</td>
                          <td>{data.gas_price ? data.gas_price : "None"}</td>
                          <td>
                            {data.gas_reverted !== null
                              ? data.gas_reverted
                              : "None"}
                          </td>

                          <td>{listDateFormat(data.created_at)}</td>
                          <td>{data.status.toUpperCase()}</td>
                        </tr>
                      ))}
                      {this.props.coldHistory.length === 0 && (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {this.props.coldHistory.length != 0 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    hotWalletBalance: state.funds.hotWalletBalance,
    coldHistory: state.funds.coldHistory,
    total: state.funds.total,
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotWalletBalance: () => dispatch(getHotWalletBalance()),
    getColdWalletHistory: (data) => dispatch(getColdWalletHistory(data)),
    reset: (data) => dispatch(reset(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HotwalletFundsHistory)
);
