import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
} from "reactstrap";
import { Pagination, Dropdown, Menu } from "semantic-ui-react";

import "./GoldSmith.scss";
import CustomHeader from "../../../components/Headers/CustomHeader";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router";
import { kycSubmittedList } from "../../../Redux/actons/userList.action";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

export class SubmittedKyc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
      sortBy: "",
    };
  }

  componentDidMount = () => {
    this.getSubmittedKyc();
  };

  getSubmittedKyc = () => {
    let data = {
      order: this.state.order,
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
    };

    this.props.kycSubmittedList(data);
  };

  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ order: data.value }, () => {
      this.getSubmittedKyc();
    });
  };

  changeSubmittedPage = (e, data) => {
    console.log("data", data);
    this.setState({ page: data.activePage }, () => {
      this.getSubmittedKyc();
    });
  };

  onSearchKyc = (valuee) => {
    console.log(valuee);
    this.setState({ searchKey: valuee, page: 1 }, () => {
      this.getSubmittedKyc();
    });
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <CustomHeader />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Submitted Kyc</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search by name/email"
                        onChange={(e) => this.onSearchKyc(`${e.target.value}`)}
                      ></Input>
                      <Dropdown
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.filterBy(e, data)}
                      />
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Document no</th>
                        <th scope="col">Kyc Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.usersList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          <td>{usersList.name ? usersList.name : "None"}</td>
                          <td>{usersList.email ? usersList.email : "None"}</td>
                          <td>
                            {usersList.mobile ? usersList.mobile : "None"}
                          </td>
                          <td>
                            {usersList.document_number
                              ? usersList.document_number
                              : "None"}
                          </td>
                          <td>
                            {/* {usersList.kyc_status} */}
                            {usersList.kyc_status == 1
                              ? "Unverified"
                              : usersList.kyc_status == 2
                              ? "Verified"
                              : usersList.kyc_status == 3
                              ? "Rejected"
                              : "Pending"}
                          </td>
                        </tr>
                      ))}
                      {this.props.usersList.length === 0 && (
                        <tr>
                          <h2>No result found</h2>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        onPageChange={(e, data) =>
                          this.changeSubmittedPage(e, data)
                        }
                        boundaryRange={1}
                        defaultActivePage={1}
                        ellipsisItem={true}
                        firstItem={true}
                        lastItem={true}
                        siblingRange={1}
                        totalPages={Math.ceil(
                          this.props.totalSubmittedRecords / this.state.perPage
                        )}
                      />
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    usersList: state.userList.submittedKyc,
    totalSubmittedRecords: state.userList.totalSubmittedRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    kycSubmittedList: (data) => dispatch(kycSubmittedList(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "SubmittedKyc" })(SubmittedKyc))
);
