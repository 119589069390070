import React, { Component } from "react";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
} from "reactstrap";
import { Pagination, Dropdown, Item, Confirm } from "semantic-ui-react";
import CustomHeader from "components/Headers/CustomHeader";

import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import { API_HOST, IMAGE_PATH } from "../../../constants/index";
import { FormField, TextField } from "../../../components/FormField";
import { required } from "redux-form-validators";
import {
  addFundsFile,
  getFundsFileList,
  getSampleFileList,
  processFile,
  deleteFile,
  downloadSampleFileApi,
} from "../../../Redux/actons/fund.action";
import { Link } from "react-router-dom";
import {
  startLoading,
  stopLoading,
} from "../../../Redux/actons/loading.action";
import { listDateFormat } from "../../../utils/utils";
import { toast } from "../../../components/Toast/Toast";
import { capitalizeFirst } from "../../../utils/utils";

const time_periods = process.env.REACT_APP_TENURE_PRIOD;

export class AddFundsFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      csvFile: "",
      perPage: "10",
      page: "1",
      fileType: "",
      fileName: "",
      processButtonDisable: false,
      selectedId: 0,
      isAddTenure: false,
      rows: [{ percentage: 0, time_period: 0, time_period_unit: time_periods }],
      totalPercentage: 0,
      comment: "",
      plan: "",
      openFileProcessModal: false,
    };
  }

  componentDidMount = () => {
    // this.checkRouteValid();
    this.getFundsHistoryList();
    this.props.getSampleFileList();
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      var isfound = false;
      // check user Permissions if route accessible
      await this.props.permissions.forEach((element) => {
        if (element.title == "Announcement Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };

  sortBy = (e, data) => {
    this.setState({ fileType: data.value, fileName: data.value });
  };

  onCsvFileUpload = async (e) => {
    if (e.target.files.length == 1) {
      const formData = new FormData();
      await formData.append("file", e.target.files[0]);
      this.props.startLoading(true);
      var self = this;
      axios
        .post(`${API_HOST}api/v1/admin/utility/upload_file`, formData, {
          headers: {
            Authorization: this.props.JwtToken,
          },
        })
        .then(function (response) {
          self.setState({ csvFile: response.data.data.name });
          self.props.stopLoading();
        })
        .catch(function (error) {
          self.props.stopLoading();
        });
    } else {
      this.setState({ csvFile: "" });
    }
  };

  openTenureModal = (plan) => {
    this.setState({
      isAddTenure: true,
      plan: plan,
      rows: [{ percentage: 0, time_period: 0, time_period_unit: time_periods }],
      totalPercentage: 0,
    });
  };

  //API Call
  addFundsFile = (values) => {
    const data = {
      comment: values.message,
      file_name: this.state.csvFile,
    };
    let params = {
      perpage: this.state.perPage,
      page: this.state.page,
    };
    this.props.addFundsFile(data, params);
  };

  getFundsHistoryList = async () => {
    let data = {
      perpage: this.state.perPage,
      page: this.state.page,
    };
    this.props.getFundsFileList(data);
  };

  downLoadSampleFile = () => {
    if (this.state.fileName === "") {
      toast.error("Please choose file type");
    } else {
      // window.location.href = `${API_HOST}api/v1/admin/airdrop/download_sample_files/${this.state.fileId}`;
      // this.props.downloadSampleFileApi(data); // Needs to delete this method
      window.location.href = `${IMAGE_PATH}${this.state.fileName}`;
    }
  };

  downLoadCSVFile = (selectedData) => {
    if (selectedData.file_name === "") {
      toast.error("File not found");
    } else {
      window.location.href = `${IMAGE_PATH}${selectedData.file_name}`;
    }
  };

  downLoadLogReport = (selectedData) => {
    if (selectedData.file_name === "") {
      toast.error("File not found");
    } else {
      window.location.href = `${API_HOST}api/v1/admin/airdrop/download_log_file/${selectedData.id}`;
      // this.props.downloadSampleFileApi(selectedData.id)
    }
  };

  deleteCsvFile = (selectedData) => {
    let data = {
      file_id: selectedData.id,
    };
    let params = {
      perpage: this.state.perPage,
      page: this.state.page,
    };
    this.props.deleteFile(data, params);
  };

  processCsvFile = (selectedData) => {
    let newData = this.state.rows.filter((el) => el.percentage > 0);
    this.setState({
      processButtonDisable: true,
      selectedId: selectedData.id,
      isAddTenure: false,
    });
    setTimeout(
      () => this.setState({ processButtonDisable: false, selectedId: 0 }),
      5000
    );
    let data = {
      file_id: selectedData.id,
      time_periods: newData,
    };

    console.log("------------params", data);
    let params = {
      perpage: this.state.perPage,
      page: this.state.page,
    };
    this.props.processFile(data, params);
    this.setState({ openFileProcessModal: false });
  };

  toggle = () => {
    this.setState({ isAddTenure: false });
  };

  addMore = (index) => {
    let data = [...this.state.rows];
    let newRow = {
      percentage: 0,
      time_period: 0,
      time_period_unit: time_periods,
    };
    data.push(newRow);

    const amount = data.reduce(
      (a, v) => parseFloat(a) + parseFloat(v.percentage),
      0
    );
    console.log("data----------", amount);
    this.setState({
      rows: data,
      totalPercentage: isNaN(parseFloat(amount)) ? 0 : amount,
    });
  };

  deleteRow = (index) => {
    let data = [...this.state.rows];
    let iPersent = this.state.rows[index].percentage;
    let remaining = this.state.totalPercentage - iPersent;
    this.setState({ totalPercentage: remaining });
    data.splice(index, 1);
    const amount = data.reduce(
      (a, v) => parseFloat(a) + parseFloat(v.percentage),
      0
    );
    console.log("data----------", amount);
    this.setState({
      rows: data,
      totalPercentage: isNaN(parseFloat(amount)) ? 0 : amount,
    });
  };

  render() {
    console.log("FUND MANAGEMENT", this.props);
    let { handleSubmit } = this.props;
    console.log(this.state);
    return (
      <>
        <div>
          <CustomHeader />
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="fund-management">Fund Management</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Add Funds</BreadcrumbItem>
            </Breadcrumb>
          </div>

          <div style={{ padding: 20, paddingBottom: 10 }}>
            <div className="selectSearchOption" style={{ paddingBottom: 20 }}>
              <Label
                for="csvFile"
                style={{ paddingRight: "6px", paddingTop: 10 }}
              >
                {" "}
                Download Sample csv file{" "}
              </Label>

              <Dropdown
                options={this.props.sampleFilesList}
                selection
                value={this.state.fileType}
                placeholder="Filter"
                onChange={(e, data) => this.sortBy(e, data)}
              />

              <Button
                color="success btnMamagePermission"
                onClick={() => this.downLoadSampleFile()}
              >
                Download Sample File
              </Button>
            </div>

            <Form onSubmit={handleSubmit(this.addFundsFile)}>
              <FormGroup>
                <Label for="email">Comment</Label>

                <Field
                  name="message"
                  component={FormField}
                  type="textarea"
                  placeholder="Enter comment"
                  validate={[required()]}
                />
              </FormGroup>
              <FormGroup>
                <Label for="csvFile" style={{ paddingRight: "6px" }}>
                  {" "}
                  Upload csv file{" "}
                </Label>
                <input
                  type="file"
                  accept={".csv"}
                  onChange={this.onCsvFileUpload}
                />
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  style={{ width: "150px" }}
                >
                  {" "}
                  Add{" "}
                </Button>
                {/* <Link to="fund-management">
                <Button color="success">Back</Button>
              </Link> */}
              </div>
            </Form>
          </div>

          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div style={{ width: "100%" }}>
                  <h3 className="mb-2">Add Fund History</h3>
                </div>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Comment</th>
                    <th scope="col">Record Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Uploaded Time</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.fundsHistoryList.map((fundList, index) => (
                    <tr className="table-tr" key={index}>
                      <td>
                        {fundList.comment
                          ? capitalizeFirst(fundList.comment)
                          : "None"}
                      </td>
                      <td>
                        {fundList.record_type
                          ? fundList.record_type.toUpperCase()
                          : "None"}
                      </td>
                      <td>
                        {fundList.status
                          ? fundList.status.toUpperCase()
                          : "None"}
                      </td>
                      <td>{listDateFormat(fundList.created_at)}</td>

                      {fundList.status.toUpperCase() === "DELETED" ? (
                        <td></td>
                      ) : (
                        <td>
                          <Button
                            color={"success btnMamagePermission"}
                            onClick={() => this.downLoadCSVFile(fundList)}
                          >
                            Download File
                          </Button>
                          {fundList.status.toUpperCase() === "FAILED" ? null : (
                            <Button
                              color="success btnMamagePermission"
                              onClick={() => this.openTenureModal(fundList)}
                              style={{ width: "130px" }}
                              disabled={
                                this.state.selectedId === fundList.id
                                  ? this.state.processButtonDisable
                                  : false
                              }
                            >
                              {fundList.status.toUpperCase() === "READY"
                                ? "Process"
                                : "Processe Again"}
                            </Button>
                          )}
                          <Button
                            color={"danger"}
                            onClick={() => this.deleteCsvFile(fundList)}
                          >
                            Delete
                          </Button>

                          {fundList.status.toUpperCase() === "PROCESSED" ? (
                            <Button
                              color={"success btnMamagePermission"}
                              onClick={() => this.downLoadLogReport(fundList)}
                            >
                              Download Log Report
                            </Button>
                          ) : null}
                        </td>
                      )}
                    </tr>
                  ))}
                  {this.props.fundsHistoryList.length == 0 && (
                    <div>
                      <td>No record</td>
                    </div>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  {this.props.fundsHistoryList.length > 0 && (
                    <Pagination
                      onPageChange={(e, data) => this.changePage(e, data)}
                      boundaryRange={1}
                      defaultActivePage={1}
                      ellipsisItem={true}
                      firstItem={true}
                      lastItem={true}
                      siblingRange={1}
                      totalPages={Math.ceil(
                        this.props.fundRecords / this.state.perPage
                      )}
                    />
                  )}
                </nav>
              </CardFooter>
            </Card>
          </div>
          <div className="modal2" style={{ zIndex: "1060", display: "block" }}>
            <Confirm
              className="modal_confirmation"
              content={`Are you sure you want to process file with above filled data?`}
              style={{
                height: "auto",
                top: "5%",
                left: "auto",
                bottom: "auto",
                right: "auto",
              }}
              size="small"
              className="confirm-model"
              open={this.state.openFileProcessModal}
              onCancel={() => this.setState({ openFileProcessModal: false })}
              onConfirm={() => this.processCsvFile(this.state.plan)}
            />
          </div>
          <Modal
            isOpen={this.state.isAddTenure}
            toggle={this.toggle}
            style={{ zIndex: "100", display: "block" }}

            // className={[this.props.className, "modalCustom"]}
          >
            <ModalHeader toggle={this.toggle}></ModalHeader>
            <ModalBody>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <span>Amount(%)</span>
                      </th>
                      <th>
                        <span>Tenure({time_periods})</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.state.rows.map((r, i) => (
                      <tr key={i}>
                        <td style={{ padding: "10px" }}>
                          <input
                            name="amount"
                            value={r?.percentage}
                            // onBlur={() => {
                            //   let data = this.state.rows;
                            //   let newData = data.filter(
                            //     (el) => el.percentage > 0
                            //   );
                            //   if (newData.length == 0) {
                            //     let newRow = {
                            //       percentage: 0,
                            //       time_period: 0,
                            //       time_period_unit: time_periods,
                            //     };
                            //     newData.push(newRow);
                            //   }
                            //   this.setState({
                            //     rows: newData,
                            //   });
                            // }}
                            onChange={async (event) => {
                              event.preventDefault();
                              let val = +event.target.value;
                              let data = this.state.rows;
                              data[i].percentage = val;

                              let amount = 0;

                              for await (const element of data) {
                                amount = amount + parseInt(element.percentage);
                              }

                              if (amount <= 100) {
                                this.setState({
                                  rows: data,
                                  totalPercentage: amount,
                                });
                              } else {
                                data[i].percentage = isNaN(
                                  parseFloat(
                                    data[i].percentage.toString().slice(0, -1)
                                  )
                                )
                                  ? null
                                  : parseFloat(
                                      data[i].percentage.toString().slice(0, -1)
                                    );

                                this.setState({
                                  rows: data,
                                });
                              }
                            }}
                          ></input>
                        </td>
                        <td style={{ padding: "10px" }}>
                          <input
                            name="tenure"
                            value={r?.time_period}
                            onChange={(event) => {
                              let data = [...this.state.rows];
                              data[i].time_period = event.target.value;
                              this.setState({ rows: data });
                            }}
                          ></input>
                        </td>
                        <td style={{ padding: "10px" }}>
                          {i === this.state.rows.length - 1 ? (
                            <Button
                              disabled={
                                this.state.totalPercentage >= 100 ? true : false
                              }
                              color={"success btnMamagePermission"}
                              onClick={() => {
                                this.state.rows[i].percentage == 0
                                  ? alert("Please add amount")
                                  : this.addMore(i - 1);
                              }}
                            >
                              Add
                            </Button>
                          ) : (
                            <Button
                              color={"danger"}
                              onClick={() => this.deleteRow(i)}
                            >
                              Delete
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <td>
                          <p>Total amount: {+this.state.totalPercentage}%</p>
                        </td>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Button
                          disabled={
                            this.state.totalPercentage > 100 ? true : false
                          }
                          color={"primary"}
                          onClick={() => {
                            if (
                              this.state.totalPercentage > 100 ||
                              this.state.totalPercentage < 100
                            ) {
                              alert("Amount should be equal to 100");
                              return;
                            }
                            let newData = this.state.rows.filter(
                              (el) => el.percentage > 0
                            );
                            let isEmptyTenure = false;
                            for (
                              let index = 0;
                              index < newData.length;
                              index++
                            ) {
                              const element = newData[index];
                              if (element.time_period == 0) {
                                isEmptyTenure = true;
                                break;
                              }
                            }
                            if (!isEmptyTenure) {
                              this.setState({ openFileProcessModal: true });
                            } else {
                              alert("Tenure should not be zero");
                            }
                          }}
                        >
                          Submit
                        </Button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    editDetails: state.announcement.editDetails,
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
    JwtToken: state.persist.c_user.token,
    fundsHistoryList: state.funds.fundsCsvFileList,
    sampleFilesList: state.funds.sampleFilesList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addFundsFile: (data, params) => dispatch(addFundsFile(data, params)),
    getFundsFileList: (data) => dispatch(getFundsFileList(data)),
    getSampleFileList: () => dispatch(getSampleFileList()),
    processFile: (data, page) => dispatch(processFile(data, page)),
    deleteFile: (data, page) => dispatch(deleteFile(data, page)),
    downloadSampleFileApi: (data) => dispatch(downloadSampleFileApi(data)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
  };
};

export default reduxForm({ form: "FundManagement" })(
  connect(mapStateToProps, mapDispatchToProps)(AddFundsFile)
);
