import React, { Component } from "react";
import { connect } from "react-redux";
import Switch from "react-switch";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
export class Level1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { userDetail } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.toggle}>
            <h1>PERSONAL INFO</h1>
          </ModalHeader>
          <ModalBody>
            <div className="userNameList">
              <div className="itemStyle">
                <label> Name:</label>
                <span>{userDetail.name ? userDetail.name : "None"}</span>
              </div>
              <div className="itemStyle">
                <label>Email-Id:</label>
                <span>{userDetail.email ? userDetail.email : "None"} </span>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Level1);
