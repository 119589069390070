import axios from "axios";
import { stopLoading } from "Redux/actons/loading.action";
import { toast } from "../components/Toast/Toast";
export { _fetch as fetch };
function handleError(error, reject) {
  if (!error) {
    toast.error("Something went wrong, Please try again");
  }
  if (error) {
    console.log("ERR", error.status);

    if (error.status === 401) {
      alert("Session expired");
      // toast.error(["Session expired !"]);
      setTimeout(() => {
        localStorage.clear();
        // toast.error(["Session expired !"]);
        window.location.reload();
      }, 2000);
    } else if (
      error.status === 409 &&
      error.data.message === "Data already exists."
    ) {
      // alert(error.data.message);
      toast.error(error["data"]["message"]);

      window.location.reload();
      // setTimeout(() => {
      //   // localStorage.clear();
      //   // window.location.reload();
      // }, 2000);
    }
  }
  reject(error);
  return;
}

function handleResponse(successs, resolve) {
  resolve(successs);
  return;
}

function setMehod(method, path, body, options, params) {
  // console.log("---- deleteCoinValue", body);
  let config = {};
  if (options) {
    if (options.jwt) {
      config.headers = {
        Authorization: options.jwt,
      };
    }
  }
  params = params ? "?" + new URLSearchParams(params).toString() : "";
  if (method === "get" || method === "delete") {
    return axios[method](`${path}${params}`, config);
  }
  if (method === "post" && params !== "") {
    return axios[method](`${path}${params}`, body, config);
  }
  if ((method === "post" && params == "") || method === "put") {
    return axios[method](`${path}`, body, config);
  }
}

function _fetch(method, path, body, options, params) {
  return new Promise((resolve, reject) => {
    return setMehod(method, path, body, options, params)
      .then(function (response) {
        handleResponse(response, resolve);
        // console.log("---- deleteCoinValue", response);
        return;
      })
      .catch(function (error) {
        // return handleError(error);
        handleError(error.response, reject);
        // console.log("---- deleteCoinValue --- error", error);
        return;
      });
  });
}
