import { fetch } from "./Fetch";
import { API_HOST, API_LOCAL_HOST } from "../constants/index";

const getTokenList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/wallets/coinList`,
    {},
    options,
    params
  );
};
const searchToken = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/wallets/searchErc20token`,
    data,
    options
  );
};
const addTokens = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/wallets/addErc20token`,
    data,
    options
  );
};
const deleteTokens = (id, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/wallets/deleteToken/${id}`,
    {},
    options
  );
};
const enableDisableToken = (action, options, id) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/wallets/changeTokenStatus/${id}`,
    action,
    options
  );
};
const getPrice = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/wallets/getCoinFiatPrice`,
    data,
    options
  );
};
const getEditTokenDetails = (id, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/wallets/getCoinFiatPriceById/${id}`,
    {},
    options
  );
};
const updateEditTokenDetails = (id, data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/wallets/editCoinPrice/${id}`,
    data,
    options
  );
};
const getTokenPriceByAlias = (alias, id, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/wallets/getCoinFiatPriceByAlias/${alias}/${id}`,
    {},
    options
  );
};
const getPriceByUsd = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/wallets/getCoinFiatPrice`,
    data,
    options
  );
};

const makeCoinPublic = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/wallets/makeTokenPublic/${data.id}`,
    data,
    options
  );
};
const swapOnOff = (id, data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/swap/update/${id}`,
    data,
    options
  );
};
const autoTransactionOnOff = (id, data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/coin/update_settings/`,
    data,
    options
  );
};
const setSwapFee = (id, data, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/swap/update_fee/${id}`,
    data,
    options
  );
};
const setWithdawMaxMinLimit = (id, data, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/wallets/update_withdraw_limit/${id}`,
    data,
    options
  );
};
const updatePriceSource = (params, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/coin/update_price_source`,
    params,
    options
  );
};

const getCoinList = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/trade/all_currency_list`,
    data,
    options
  );
};

const addCoinPair = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/trade/create_trade_pair`,
    data,
    options
  );
};

export const TokenServices = {
  getTokenList,
  searchToken,
  deleteTokens,
  addTokens,
  enableDisableToken,
  getPrice,
  getEditTokenDetails,
  updateEditTokenDetails,
  getTokenPriceByAlias,
  getPriceByUsd,
  makeCoinPublic,
  swapOnOff,
  autoTransactionOnOff,
  setSwapFee,
  setWithdawMaxMinLimit,
  updatePriceSource,
  getCoinList,
  addCoinPair,
};
