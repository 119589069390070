import { fetch } from "./Fetch";
import axios from "axios";
import { API_HOST, API_LOCAL_HOST, MOBILE_API_HOST } from "../constants/index";
// import { downLoaded } from "../Redux/actons/userList.action";
// import { getcoingecko } from "../Redux/actons/user.action";

const login = (data) => {
  return fetch("post", `${API_HOST}api/v1/admin/login`, data);
};
const getGoogleImageSecretKey = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/getGoogleAuthSecretKey`,
    data,
    options
  );
};

const enableDisableGoogleAuth = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/googleAuthEnableDisable`,
    data,
    options
  );
};

const verifyGoogleAuth = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/google2faVerify`,
    data,
    options
  );
};

//set admin_settings
const setAdminSetting = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/setAdminSettings`,
    data,
    options
  );
};

//admin_setings
const getAdminSetting = (options) => {
  return fetch("get", `${API_HOST}api/v1/admin/getAdminSettings`, {}, options);
};

//dashboard_list
const getDashboardList = (options) => {
  return fetch("get", `${API_HOST}api/v1/admin/users/dashboard`, {}, options);
};

//dashboard_graph
const getDashboarGraph = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/userGraphs`,
    {},
    options,
    params
  );
};

//user_list
const getUserList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/list`,
    {},
    options,
    params
  );
};
const getUserListCSV = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/download/userlist`,
    {},
    options,
    params
  );
};

//user_detail
const getUserDetails = (id, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/detail/${id.user_id.user_id}`,
    {},
    options
  );
};

//Bank Details
const getBankDetails = (params, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/users/bank_details`,
    params,
    options
  );
};

//user_coinbalance
const getUserBalance = (id, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/coinbalance/${id.user_id.user_id}`,
    {},
    options
  );
};

//kyc_list
const getKycList = (params, options) => {
  return fetch("get", `${API_HOST}api/v1/admin/kyc/list`, {}, options, params);
};

//change_status_kyc
const getKycStatus = (id, data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/kyc/changeStatus1/${id}`,
    data,
    options
  );
};

//submited_kyc
const getSubmittedKyc = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/submittedKyc`,
    {},
    options,
    params
  );
};

//approved_kyc
const getApprovedKyc = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/approvedKyc`,
    {},
    options,
    params
  );
};
const getAdminSettings = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/getAdminSettings`,
    {},
    options,
    params
  );
};
const updateAdminSettings = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/setAdminSettings`,
    data,
    options
  );
};
const updateWithdrawLimit = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/users/updateCoinlimit`,
    data,
    options
  );
};

const updateWithdrawLimitBulk = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/users/updateBulkWithdrawalLimit`,
    data,
    options
  );
};

const updateWithdrawFee = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/coin/update_bulk`,
    data,
    options
  );
};
const updateTransactionLimit = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/transaction/setTransactionFee`,
    data,
    options
  );
};
// TESTING
// const getCoinList = (options, params) => {
//   return fetch(
//     "get",
//     `${API_HOST}api/v1/admin/wallets/coinList?operateBy=1&page=1&perPage=${
//       params?.perPage ? params?.perPage : 30
//     } `,
//     {},
//     options,
//     params
//   );
// };
const getCoinListCoinSetting = async (options, params) => {
  //   try {
  // const res = await axios({
  //   method: "GET",
  //   // url: `https://testapi-main.mobiloitte.org/api/v1/admin/active_block_coin`,
  //   url: `${API_HOST}api/v1/admin/wallets/coinList?operateBy=${params.list_type}&page=${params.page}&perPage=${params.perPage}`,
  //   headers: {
  //     Authorization: options.jwt,
  //   },
  // });
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/wallets/coinList?operateBy=${params.list_type}&page=${params.page}&perPage=${params.perPage}`,
    {},
    options
    // params
  );
};
const getCoinList = async (options, params) => {
  //   try {
  const res = await axios({
    method: "GET",
    // url: `https://testapi-main.mobiloitte.org/api/v1/admin/active_block_coin`,
    url: `${API_HOST}api/v1/admin/wallets/coinList?operateBy=1&page=1&perPage=3`,
    headers: {
      Authorization: options.jwt,
    },
  });
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/wallets/coinList?operateBy=1&page=1&perPage=${res.data.meta.total}`,
    {},
    options
    // params
  );
};
const getActiveCoinList = async (options, params) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/wallets/coinList1`,
    {},
    options,
    params
  );
};

const getcoinmarketcap = async (options, params) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/coinmarketcap`,
    {},
    options,
    params
  );
};
const getcoingecko = async (options, params) => {
  return fetch("get", `${API_HOST}api/v1/admin/coingecko`, {}, options, params);
};
// const getCoinList = async (options, params) => {
//   try {
//     const res = await axios({
//       method: "GET",
//       // url: `https://testapi-main.mobiloitte.org/api/v1/admin/active_block_coin`,
//       url: `${API_HOST}api/v1/admin/wallets/coinList?operateBy=1&page=1&perPage=${
//         params?.perPage ? params?.perPage : 30
//       } `,
//       headers: {
//         Authorization: options.jwt,
//       },
//     });
//     // getCoinValue(data1, options);
//     return res.data;
//     // }
//   } catch (error) {
//     return error.response.data;
//   }
// };
const getPlan = (options, params) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/instalment/get_plan`,
    {},
    options,
    params
  );
};

const getCurrencyList = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/trade/currencyList`,
    {},
    options,
    data
  );
};
const getCoinValue = async (param, options) => {
  try {
    const res = await axios({
      method: "GET",
      // url: `https://testapi-main.mobiloitte.org/api/v1/admin/listCoins`,
      url: `${API_HOST}api/v1/admin/listCoins`,
      headers: {
        Authorization: options.jwt,
      },
      params: param,
    });
    return res;
    // }
  } catch (error) {
    console.log(error);
    return error.response.data;
  }

  // return fetch(
  //   "get",
  //   `${API_HOST}api/v1/admin/listCoins`,
  //   {},
  //   // options,
  //   param
  // );
};
const listCoinsLimits = async (param, options) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API_HOST}api/v1/admin/reward/listCoinsLimits`,
      headers: {
        Authorization: options.jwt,
      },
      params: param,
    });
    return res;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }

  // return fetch(
  //   "get",
  //   `${API_HOST}api/v1/admin/listCoins`,
  //   {},
  //   // options,
  //   param
  // );
};
const orderbookListCoins = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/pairs/order_history`,
    {},
    options,
    data
  );
};
const OrderPairList = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/pairs/pair_list`,
    {},
    options,
    data
  );
};
const orderbookFee = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v2/order_book/get_fee`,
    {},
    options,
    data
  );
};
const viewOrderbookCoins = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/pairs/view_order?order_id=${data.order_id}`,
    {},
    options
    // data
  );
};

const getTradeHistory = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/trade/history`,
    {},
    options,
    params
  );
};

const downloadTradeHistory = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/tradeHistoryDownload?user_id=${params.userId}`,
    {},
    options
  );
};
// https://api.tarality.online/api/admin/users/tradeHistoryDownload?user_id=2553

const getTradeSettings = (options) => {
  return fetch("get", `${API_HOST}api/v1/admin/trade/settings`, {}, options);
};
const getOrderSettings = (options) => {
  return fetch("get", `${API_HOST}api/v1/admin/viewMarkup`, {}, options);
};
const viewPair = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/pairs/view_pair?id=${data}`,
    {},
    options
  );
};

const updateTradeSettings = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/trade/updateTradeBuySellFee`,
    data,
    options
  );
};
const addOrUpdateMarkup = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/addOrUpdateMarkup`,
    data,
    options
  );
};
const addPairs = (data, options) => {
  return fetch("post", `${API_HOST}api/v1/admin/pairs/add_pair`, data, options);
};
const EditPair = (data, id, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/pairs/edit_pair?id=${id}`,
    data,
    options
  );
};

const updateCurrencyList = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/trade/updateCurrency`,
    data,
    options
  );
};
const updateCoinLimit = async (data, options, data1) => {
  try {
    console.log("UserServices ----", data);
    const res = await axios({
      method: "POST",
      url: `${API_HOST}api/v1/admin/reward/addUpdateCoinLimit`,
      // url: `${API_HOST}api/v1/admin/reward/deleteCoinLimit/${data.id}`,
      headers: {
        Authorization: options.jwt,
      },
      data: data,
      // data: {
      //   coin_id: 0,
      //   limit: 0,
      // },
    });
    // listCoinsLimits(data1, options);
    console.log("UserServices ----", res);
    return res.data;
    // }
  } catch (error) {
    console.log(error);
    console.log("UserServices ----", error.response.data);
    return error.response.data;
  }
};
const deleteCoinLimit = async (data, options, data1) => {
  try {
    console.log("id ----", data);
    const res = await axios({
      method: "DELETE",
      url: `${API_HOST}api/v1/admin/reward/deleteCoinLimit/${data.id}`,
      headers: {
        Authorization: options.jwt,
      },
      // params: data,
    });
    // listCoinsLimits(data1, options);
    return res.data;
    // }
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
const deleteCoinValue = async (data, options, data1) => {
  try {
    const res = await axios({
      method: "put",
      // url: `https://testapi-main.mobiloitte.org/api/v1/admin/active_block_coin`,
      url: `${API_HOST}api/v1/admin/active_block_coin`,
      headers: {
        Authorization: options.jwt,
      },
      params: data,
    });
    getCoinValue(data1, options);
    return res.data;
    // }
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const updateRate = (data, id, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/trade/update-cmcRate/${id}`,
    data,
    options
  );
};
const addCoinValue = (data, id, options) => {
  return fetch("post", `${API_HOST}api/v1/admin/addCoinValue`, data, options);
};
const editFee = (data, id, options) => {
  return fetch("put", `${API_HOST}api/v2/order_book/edit_fee`, data, options);
};
const EnableDisablePair = async (params, data, options) => {
  try {
    const res = await axios({
      method: "PUT",
      url: `${API_HOST}api/v1/admin/pairs/active_inActive_pair?id=${params.id}`,
      headers: {
        Authorization: options.jwt,
      },
      data: {
        is_active: data.status,
      },
    });
    return res;
    // }
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
const CancleOredr = async (data, id, options) => {
  try {
    const res = await axios({
      method: "delete",
      url: `${API_HOST}api/v1/admin/cancelOrder/${data.orderId}/${data.pairInUSDT}`,
      headers: {
        Authorization: options.jwt,
      },
    });
    return res;
    // }
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
const updateCoinValue = (data, id, options) => {
  return fetch("put", `${API_HOST}api/v1/admin/updateCoinValue`, data, options);
};

const updateCoinPriceToUSD = (data, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/price/updateCoinPriceToUSD/${data.coin_id}`,
    data,
    options
  );
};
const uploadImage = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/users/uploadimage`,
    data,
    options
  );
};
const uploadFinalImage = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/users/update/coinlogo`,
    data,
    options
  );
};
const getAdminNotification = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/getNotifications`,
    {},
    options,
    params
  );
};

const addComment = (data, id, options) => {
  var model = {
    comment: data.comment,
  };
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/users/addComment/${data.user_id}`,
    model,
    options
  );
};

const changePassword = (data, options) => {
  return fetch("post", `${API_HOST}api/v1/admin/changepassword`, data, options);
};
const getAccountsList = (options) => {
  return fetch("get", `${API_HOST}api/v1/admin/users/accounting`, {}, options);
};
const enableDisableUser = (id, data, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/users/enableOrDisable/${id}`,
    data,
    options
  );
};
const enableDisableUserWithdraw = (id, data, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/users/enableDisableForWithdraw/${id}`,
    data,
    options
  );
};

const downLoaded = async (data, params, options) => {
  try {
    const res = await axios({
      method: "get",
      url: data,
      // url: `${API_HOST}api/v1/admin/active_block_coin`,
      // headers: {
      //   Authorization: options.jwt,
      // },
      // params: params,
    });
    let filename = "downLoadLists.xlsx";
    var blob = res.data;
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var downloadLink = window.document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
    // getCoinValue(data1, options);
    // return res.data;
    // }
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
const removeUser = (id, data, options) => {
  return fetch(
    "delete",
    `${API_HOST}api/v1/admin/users/delete/${id}`,
    data,
    options
  );
};
const forgotPasswordForUser = (data) => {
  return fetch("post", `${MOBILE_API_HOST}/user/updatepassword`, data);
};
const forgotLoginPin = (data) => {
  return fetch("post", `${MOBILE_API_HOST}/wallet/updatepin`, data);
};
const forgotTxnPin = (data) => {
  return fetch("post", `${MOBILE_API_HOST}/user/transaction/updatepin`, data);
};
const deletedUserList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/deletedUser/list`,
    {},
    options,
    params
  );
};

const changeUserEmail = (id, data, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/users/changeIdpEmail/${id}`,
    data,
    options
  );
};
const triggerOnOff = (data, options) => {
  return fetch("post", `${API_HOST}api/v1/admin/triggerOnOff`, data, options);
};
const getAppSettings = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/getAdminSettings`,
    {},
    options,
    params
  );
};

const getGoogleStatus = (options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/getGoogleAuthStatus`,
    {},
    options
  );
};
const submitIdpChargeSetting = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/wallets/addBankDetailAndAnnouncement`,
    data,
    options
  );
};
const getIDPChargeList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/wallets/idpchargelist`,
    {},
    options,
    params
  );
};
const approveRejectIDP = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/wallets/acceptrejectidpcharge`,
    data,
    options
  );
};
const getColdWalletGoogleStatus = (options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/getColdWalletGoogleAuthStatus`,
    {},
    options
  );
};
const getWalletColdGoogleImageSecretKey = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/getColdWalletGoogleAuthSecretKey`,
    data,
    options
  );
};
const enableDisableColdWalletGoogleAuth = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/coldWalletGoogleAuthEnableDisable`,
    data,
    options
  );
};

const getUserTerminationRequests = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/instalment/termination-list`,
    data,
    options
  );
};

const terminateRequest = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/instalment/terminateV2/${data}`,
    {},
    options
  );
};

export const UserService = {
  getAppSettings,
  triggerOnOff,
  changeUserEmail,
  deletedUserList,
  changePassword,
  login,
  getGoogleStatus,
  enableDisableGoogleAuth,
  getGoogleImageSecretKey,
  verifyGoogleAuth,
  setAdminSetting,
  getAdminSetting,
  getUserList,
  getUserListCSV,
  getUserDetails,
  getBankDetails,
  getKycList,
  updateTransactionLimit,
  getDashboardList,
  getSubmittedKyc,
  getApprovedKyc,
  getUserBalance,
  getKycStatus,
  getDashboarGraph,
  getAdminSettings,
  updateAdminSettings,
  getcoinmarketcap,
  getcoingecko,
  getCoinList,
  getCoinListCoinSetting,
  // getCoinList1,
  getActiveCoinList,
  getPlan,
  updateWithdrawLimit,
  updateWithdrawFee,
  updateCoinPriceToUSD,
  uploadImage,
  uploadFinalImage,
  getAdminNotification,
  addComment,
  getAccountsList,
  enableDisableUser,
  enableDisableUserWithdraw,
  removeUser,
  forgotPasswordForUser,
  forgotTxnPin,
  forgotLoginPin,
  submitIdpChargeSetting,
  getIDPChargeList,
  approveRejectIDP,
  getCurrencyList,
  getCoinValue,
  listCoinsLimits,
  orderbookListCoins,
  OrderPairList,
  orderbookFee,
  viewOrderbookCoins,
  updateCurrencyList,
  deleteCoinValue,
  deleteCoinLimit,
  updateCoinLimit,
  getTradeSettings,
  getOrderSettings,
  viewPair,
  updateTradeSettings,
  addOrUpdateMarkup,
  addPairs,
  EditPair,
  getTradeHistory,
  downloadTradeHistory,
  updateWithdrawLimitBulk,
  updateRate,
  addCoinValue,
  downLoaded,
  editFee,
  CancleOredr,
  EnableDisablePair,
  updateCoinValue,
  getColdWalletGoogleStatus,
  getWalletColdGoogleImageSecretKey,
  enableDisableColdWalletGoogleAuth,
  getUserTerminationRequests,
  terminateRequest,
};
