import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import {
  getCurrencyList,
  updateCurrency,
  deleteCoinValue,
  updateRate,
  addCoinValue,
  editFee,
  CancleOredr,
  EnableDisablePair,
  updateCoinValue,
  getCoinValue,
  orderbookListCoins,
  orderbookFee,
  OrderPairList,
  orderbookViewCoins,
} from "../../Redux/actons/user.action";
import { Pagination, Form, Dropdown } from "semantic-ui-react";
import Header from "components/Headers/Header";
import { withRouter, Link } from "react-router-dom";
import { listDateFormat } from "utils/utils";
import SemanticDatepicker from "react-semantic-ui-datepickers";

const options = [
  { key: "m", text: "Upgrade", value: "upgrade" },
  { key: "k", text: "Downgrade", value: "downgrade" },
];

const UserFilter = [
  { key: "c", text: "Ascending", value: "asc" },
  { key: "u", text: "Descending", value: "desc" },
];
const statusFilter = [
  { key: "o", text: "All", value: "none" },
  { key: "c", text: "Pending", value: "PENDING" },
  { key: "u", text: "Completed", value: "COMPLETED" },
];

export class OrderManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      buyOrder: "",
      sellOrder: "",
      coinName: "",
      max: "",
      min: "",
      coinName1: "",
      max1: "",
      min1: "",
      buyRate: "",
      sellRate: "",
      sortBy: "",
      openModel: false,
      openModel1: false,
      openModelCancleOrder: false,
      openModelFee: false,
      isUpdate: false,
      modalData: "",
      listType: "1",
      tokenId: "",
      order_type: "asc",
      order_status: "",
      start_date: "",
      end_date: "",
      orderId: "",
      pairUSDT: "",
      pairView: "",
      sell_fee: this.props.saveorderbookFee?.data?.sell_fee,
      buy_fee: this.props.saveorderbookFee?.data?.buy_fee,
      datepicker_start_date: null,
      datepicker_end_date: null,
      status: "",
      id: "",
    };
  }
  componentDidMount = () => {
    this.orderbookListCoins();
    this.OrderPairList();
    // this.props.orderbookFee();
  };

  getCurrencyList = () => {
    const param = {
      search: this.state.searchKey,
      page: this.state.page,
      perPage: this.state.perPage,
      list_type: this.state.listType,
      status: "1",
    };
    this.props.getCurrencyList(param);
  };
  OrderPairList = () => {
    const param = {
      searchKey: this.state.searchKey,
      page: this.state.page,
      perPage: this.state.perPage,
      // status: "1",
      order: this.state.order_type,
    };
    this.props.OrderPairList(param);
  };
  orderbookListCoins = () => {
    const data = {
      // search: this.state.searchKey,
      page: this.state.page,
      perPage: this.state.perPage,
      order_type: this.state.order_type,
      order_status: this.state.order_status,

      status: "1",
    };
    this.props.orderbookListCoins(data);
  };
  orderbookViewCoins = (id) => {
    this.setState({ pairView: id }, () => {
      this.openModel1();
    });
  };

  onSearchList = () => {
    this.OrderPairList();
  };

  listByUser = (e, data) => {
    if (data.value !== "none") {
      this.setState({ order_type: data.value }, () => {
        this.OrderPairList();
      });
    } else {
      this.setState(
        {
          order_type: "",
          start_date: "",
          end_date: "",
          datepicker_start_date: null,
          datepicker_end_date: null,
        },
        () => {
          this.OrderPairList();
        }
      );
    }
  };
  listByStatus = (e, data) => {
    if (data.value !== "none") {
      this.setState({ order_status: data.value }, () => {
        this.orderbookListCoins();
      });
    } else {
      this.setState(
        {
          order_status: "",
          start_date: "",
          end_date: "",
          datepicker_start_date: null,
          datepicker_end_date: null,
        },
        () => {
          this.orderbookListCoins();
        }
      );
    }
  };
  SetStartDate(data) {
    if (data.value !== null) {
      let date = new Date(data.value);
      let start_date = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
      this.setState({ start_date: start_date });
      this.setState({ datepicker_start_date: data.value });
    } else {
      this.setState({ start_date: "" });
      this.setState({ datepicker_start_date: data.value });
    }
  }
  SetEndDate(data) {
    if (data.value !== null) {
      let date = new Date(data.value);
      let end_date = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
      this.setState({ end_date: end_date });
      this.setState({ datepicker_end_date: data.value });
    } else {
      this.setState({ end_date: "" });
      this.setState({ datepicker_end_date: data.value });
    }
  }

  toggleCancleOrder = () =>
    this.setState({
      openModelCancleOrder: !this.state.openModelCancleOrder,
    });
  toggleFee = () =>
    this.setState({
      openModelFee: !this.state.openModelFee,
    });
  openModelFee = (data) => {
    this.setState({
      openModelFee: true,
    });
  };
  toggle = () =>
    this.setState({
      openModel: !this.state.openModel,
    });
  toggle1 = () =>
    this.setState({
      openModel1: !this.openModel1,
    });

  CancleOrderbookCoins = (data) => {
    this.setState({
      id: data.id,
      status: data.is_active == "0" ? "1" : "0",
      // pairUSDT: data.coin_name,
      openModelCancleOrder: true,
    });
  };
  openModel = (data) => {
    this.setState({
      openModel: true,
    });
  };
  openModel1 = () => {
    this.setState({
      openModel1: true,
    });
  };

  deleteCoinValue = (data1, id, currencyType) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    const buttonState = { status: data1 };

    const params = {
      id: data1,
    };
    this.props.deleteCoinValue(params, data2);

    this.orderbookListCoins();
  };
  enableDisableCurrency = (data, id, currencyType) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    const buttonState = { status: data };

    const params = {
      currency_type: currencyType,
      currency_id: id,
      status: data,
    };
    this.props.updateCurrency(params, data2);
  };

  onChangePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      // this.orderbookListCoins();
      this.OrderPairList();
    });
  };

  sortForBuy = (e, data) => {
    this.setState({ buyOrder: data.value }, () => {});
  };

  sortForSell = (e, data) => {
    this.setState({ sellOrder: data.value }, () => {});
  };

  updateCoinValueClick = (currency_type) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    if (
      this.state.coinName1 === "" ||
      this.state.min1 === "" ||
      this.state.max1 === ""
    ) {
      alert("all fields are required");
      return;
    }

    if (this.state.max1 < this.state.min1) {
      alert("Min value should be less than Max");
    } else {
      const data = {
        id: this.state.tokenId,
        coin_name: this.state.coinName1,
        min: this.state.min1,
        max: this.state.max1,
        // sell_rate_type: this.state.sellOrder,
        // currency_id: this.state.modalData.currency_id,
        // currency_type: this.state.currencyType,
      };
      this.props.updateCoinValue(data, this.state.modalData.id, data2);
      this.toggle1();
    }
  };
  EnableDisablePair = () => {
    const params = {
      id: this.state.id,
    };
    const data = {
      status: this.state.status,
    };
    this.props.EnableDisablePair(params, data);
    this.toggleCancleOrder();
    // }
  };
  editFeeClick = (currency_type) => {
    if (this.state.sell_fee === "" || this.state.buy_fee === "") {
      alert("all fields are required");
      return;
    }

    const data = {
      buy_fee: this.state.buy_fee,
      sell_fee: this.state.sell_fee,
    };
    this.props.editFee(data);
    this.toggleFee();
    // }
  };
  addCoinValueClick = (currency_type) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    if (
      this.state.coinName === "" ||
      this.state.min === "" ||
      this.state.max === ""
    ) {
      alert("all fields are required");
      return;
    }

    if (this.state.max < this.state.min) {
      alert("Min value should be less than Max");
    } else {
      const data = {
        coin_name: this.state.coinName,
        min: this.state.min,
        max: this.state.max,
        // sell_rate_type: this.state.sellOrder,
        // currency_id: this.state.modalData.currency_id,
        // currency_type: this.state.currencyType,
      };
      this.props.addCoinValue(data, this.state.modalData.id, data2);
      this.toggle();
    }
  };

  render() {
    let { handleSubmit, saveorderbookpairlist } = this.props;
    return (
      <div>
        <Header headerTitle="Order Book Management" />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      {/* <Form
                        className="selectSearchOption"
                        style={{
                          height: "40px",
                          paddingRight: "10px",
                        }}
                      >
                        <Form.Group>
                          <Form.Field>
                            <SemanticDatepicker
                              locale="en-US"
                              onChange={(event, data) =>
                                this.SetStartDate(data)
                              }
                              type="basic"
                              format="YYYY-MM-DD"
                              value={this.state.datepicker_start_date}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field>
                            <SemanticDatepicker
                              locale="en-US"
                              className="btnMamagePermission"
                              onChange={(event, data) => this.SetEndDate(data)}
                              type="basic"
                              format="YYYY-MM-DD"
                              value={this.state.datepicker_end_date}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form> */}
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        className="selectUserStyle btnMamagePermission"
                        options={UserFilter}
                        placeholder="Filter by order"
                        selection
                        onChange={(e, data) => this.listByUser(e, data)}
                      />
                      {/* <Dropdown
                        className="selectUserStyle btnMamagePermission"
                        options={statusFilter}
                        placeholder="Filter by status"
                        selection
                        onChange={(e, data) => this.listByStatus(e, data)}
                      />  */}
                      <Link to="add-pair">
                        <Button color="success btnMamagePermission">
                          + Add pair
                        </Button>
                      </Link>
                      {/* <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openModelFee()}
                      >
                        Edit Fee
                      </Button> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Pair Id</th>
                        <th scope="col">Coin Id</th>
                        <th scope="col">Pair Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">buy fee</th>
                        <th scope="col">buy max</th>
                        <th scope="col">buy min</th>
                        <th scope="col">Sell fee</th>
                        <th scope="col">Sell max</th>
                        <th scope="col">Sell min</th>
                        <th scope="col">Mark Up</th>
                        <th scope="col">mark Down</th>

                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.saveorderbookpairlist?.length === 0 ||
                        (this.props.saveorderbookpairlist === undefined && (
                          <tr>
                            <td>No results found</td>
                          </tr>
                        ))}{" "}
                      {this.props.saveorderbookpairlist?.length !== 0 &&
                        this.props.saveorderbookpairlist?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data?.id}</td>
                            <td>{data?.coin_id}</td>
                            <td>{data?.pair_name?.toUpperCase()}</td>
                            <td>
                              {data.is_active === "1" ? "Enabled" : "Disabled"}
                            </td>
                            <td>{data?.buy_fee}</td>
                            <td>{data?.buy_max}</td>
                            <td>{data?.buy_min}</td>
                            <td>{data?.sell_fee}</td>
                            <td>{data?.sell_max}</td>
                            <td>{data?.sell_min}</td>
                            <td>{data?.mark_up}</td>
                            <td>{data?.mark_down}</td>

                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color="success"
                                  onClick={() => {
                                    this.orderbookViewCoins(data);
                                  }}
                                >
                                  View
                                </Button>
                                {/* <Link
                                  to={`add-pair?${data?.id?.toString()}`}
                                  style={{ paddingRight: "5px" }}
                                > */}
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.props.history.push({
                                      pathname: "add-pair",
                                      search: data?.id?.toString(),
                                    })
                                  }
                                >
                                  Edit
                                </Button>
                                {/* </Link> */}
                                <Button
                                  color={
                                    data.is_active !== "0"
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={() => {
                                    // this.openModel1(data);
                                    this.CancleOrderbookCoins(data);
                                  }}
                                >
                                  {data.is_active !== "0"
                                    ? "Disable"
                                    : "Enable"}
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {this.props.saveorderbookpairmeta?.pages != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.onChangePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.saveorderbookpairmeta?.pages
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        <Modal
          isOpen={this.state.openModel1}
          toggle={this.toggle1}
          className={[this.props.className, "modalCustom"]}
        >
          {/* {this.props.saveVieworderBook?.coin_name} */}

          {/* <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={[this.props.className, "modalCustom"]}
        > */}
          <ModalHeader toggle={this.props.toggle}>
            <h1>View Pair INFO</h1>
          </ModalHeader>
          <ModalBody>
            <div className="userNameList">
              <div className="itemStyle">
                <label>Pair Name:</label>
                <label>
                  {this.state.pairView?.pair_name
                    ? this.state.pairView?.pair_name
                    : "None"}
                </label>
              </div>
              <div className="itemStyle">
                <label>Buy MAX:</label>
                <span
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.pairView?.buy_max
                    ? `${this.state.pairView?.buy_max} `
                    : "None"}{" "}
                </span>
              </div>
              <div className="itemStyle">
                <label>Buy MIN :</label>
                <label>
                  {this.state.pairView?.buy_min
                    ? this.state.pairView?.buy_min
                    : "None"}{" "}
                </label>
              </div>
              <div className="itemStyle">
                <label>Buy Fee:</label>
                <label>
                  {this.state.pairView?.buy_fee
                    ? this.state.pairView?.buy_fee
                    : "None"}{" "}
                </label>
              </div>
              <div className="itemStyle">
                <label>Sell MAX:</label>
                <span
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.pairView?.sell_max
                    ? `${this.state.pairView?.sell_max} `
                    : "None"}{" "}
                </span>
              </div>
              <div className="itemStyle">
                <label>Sell MIN :</label>
                <label>
                  {this.state.pairView?.sell_min
                    ? this.state.pairView?.sell_min
                    : "None"}{" "}
                </label>
              </div>
              <div className="itemStyle">
                <label>Sell Fee:</label>
                <label>
                  {this.state.pairView?.sell_fee
                    ? this.state.pairView?.sell_fee
                    : "None"}{" "}
                </label>
              </div>
              <div className="itemStyle">
                <label>Mark Up:</label>
                <label>
                  {this.state.pairView?.mark_up
                    ? this.state.pairView?.mark_up
                    : "None"}{" "}
                </label>
              </div>
              <div className="itemStyle">
                <label>Mark Down:</label>
                <label>
                  {this.state.pairView?.mark_down
                    ? this.state.pairView?.mark_down
                    : "None"}{" "}
                </label>
              </div>
              {/* <div className="itemStyle">
                <label>Time:</label>
                <label>
                  {this.props.saveVieworderBook?.update_at
                    ? listDateFormat(this.props.saveVieworderBook?.update_at)
                    : "None"}{" "}
                </label>
              </div> */}
              {/* <div className="itemStyle">
                <label> Status:</label>
                <span>
                  {this.props.saveVieworderBook?.status
                    ? this.props.saveVieworderBook?.status.toUpperCase()
                    : "None"}{" "}
                </span>
              </div> */}
              {/* <div className="itemStyle">
                <label> Order Status:</label>
                <span>
                  {this.props.saveVieworderBook?.order_status
                    ? this.props.saveVieworderBook?.order_status.toUpperCase()
                    : "None"}{" "}
                </span>
              </div> */}
            </div>
          </ModalBody>
          {/* </Modal> */}
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel1: false })}
            >
              Cancel
            </Button>
            {/* <Button color="success" onClick={() => this.updateCoinValueClick()}>
              Update
            </Button> */}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.openModelCancleOrder}
          toggle={this.toggleCancleOrder}
        >
          <ModalBody>
            <h4>{this.state.status != 0 ? "Enable" : "Disable"} Pair</h4>
            <span>
              Are you sure to {this.state.status != 0 ? "Enable" : "Disable"}{" "}
              this pair!
            </span>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModelCancleOrder: false })}
            >
              Back
            </Button>
            <Button color="success" onClick={() => this.EnableDisablePair()}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.openModelFee} toggle={this.toggleFee}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Fee Type</label>

                  <input
                    name="coin_mname"
                    placeholder="Coin Name"
                    value={this.props.saveorderbookFee?.data?.fee_type}
                    type="text"
                    disabled
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ coinName: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Sell Fee</label>
                  <input
                    name="sell_fee"
                    placeholder="Sell Fee"
                    value={this.state.sell_fee}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ sell_fee: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Buy Fee</label>
                  <input
                    name="buy_fee"
                    placeholder="Buy Fee"
                    value={this.state.buy_fee}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ buy_fee: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              {/* <Form.Group>
                <Form.Field>
                  <label>Buy Fee</label>

                  <input
                    name="buy_fee"
                    placeholder="Buy Fee"
                    value={this.state.buy_fee}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ but_fee: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group> */}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModelFee: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.editFeeClick()}>
              Edit Fee
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.openModel} toggle={this.toggle}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Coin Name</label>

                  <input
                    name="coin_mname"
                    placeholder="Coin Name"
                    value={this.state.coinName}
                    type="text"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ coinName: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Min</label>
                  <input
                    name="Min"
                    placeholder="Min"
                    value={this.state.min}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ min: e.target.value })
                    }
                  ></input>
                  {/* <Dropdown
                    className="selectUserStyle"
                    placeholder="Min"
                    options={options}
                    selection
                    value={this.state.buyOrder}
                    onChange={(e, data) => this.sortForBuy(e, data)}
                  />{" "} */}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Max</label>

                  <input
                    name="max"
                    placeholder="Max"
                    value={this.state.max}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ max: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.addCoinValueClick()}>
              Add Pair
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    getCoinList: state.user.CurrencyList,
    saveorderbookList: state.user.saveorderbookList?.data?.data,
    saveorderbookpairlist: state.user.saveorderbookpairlist,
    saveorderbookpairmeta: state.user.saveorderbookpairmeta,
    // saveorderbookListNo: state.user.saveorderbookListNo,
    orderbookMeta: state.user.saveorderbookList?.data?.meta,
    saveCoinValue: state.user.saveCoinValues?.rows,
    saveVieworderBook: state.user.savevieworderbook,
    total: state.user.totalRecords,
    saveorderbookFee: state.user.saveorderbookFee,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    OrderPairList: (data) => dispatch(OrderPairList(data)),
    getCurrencyList: (data) => dispatch(getCurrencyList(data)),
    orderbookListCoins: (data) => dispatch(orderbookListCoins(data)),
    orderbookFee: (data) => dispatch(orderbookFee(data)),
    orderbookViewCoins: (data) => dispatch(orderbookViewCoins(data)),
    updateCurrency: (data, data2) => dispatch(updateCurrency(data, data2)),
    deleteCoinValue: (data, data2) => dispatch(deleteCoinValue(data, data2)),
    addCoinValue: (data, id, data2) => dispatch(addCoinValue(data, id, data2)),
    editFee: (data, id, data2) => dispatch(editFee(data)),
    CancleOredr: (params, id, data2) => dispatch(CancleOredr(params)),
    EnableDisablePair: (params, data) =>
      dispatch(EnableDisablePair(params, data)),
    updateCoinValue: (data, id, data2) =>
      dispatch(updateCoinValue(data, id, data2)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderManagement)
);
