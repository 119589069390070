import React from "react";

import { Container } from "reactstrap";
import { connect } from "react-redux";
import { Table, TableRow } from "semantic-ui-react";
import { getTotalCoinList } from "../../Redux/actons/revenue.action";
import { DashboardList } from "../../Redux/actons/userList.action";
import Header from "../../components/Headers/Header";
import { Link } from "react-router-dom";
import { convertExponentialToDecimal } from "../../utils/utils";
import { getAccountsList } from "../../Redux/actons/report.action";
import { getHotWalletBalance } from "../../Redux/actons/fund.action";
import {
  saveKycSelectedFilter1,
  saveKycSelectedFilter2,
} from "../../Redux/actons/userList.action";
class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columnSide: [
        "Deposit",
        "Withdraw",
        "Deposit fee",
        "Withdraw fee",
        "Total",
      ],
    };
  }
  componentDidMount = () => {
    this.props.DashboardList().then((res) => {
      this.props.getTotalCoinList();
      this.props.getAccountsList();
    });
  };
  navigateToKyc2 = async (value) => {
    //     { key: "p", text: "None", value: "none" },
    //     { key: "t", text: "Pending", value: "1" },
    //     { key: "k", text: "Verified", value: "2" },
    //     { key: "n", text: "Unverified", value: "3" },
    //   ];
    await this.props.saveKycSelectedFilter2(value);
    this.props.history.push({ pathname: "kyc-management", search: value });
  };
  navigateToKyc1 = async (value) => {
    await this.props.saveKycSelectedFilter1(value);
    this.props.history.push("kyc-management");
  };
  render() {
    let { usersList } = this.props;
    return (
      <>
        <Header headerTitle="Dashboard Statistics" />
        {/* Page content */}
        {Object.keys(usersList).length != 0 && (
          <Container className="contentBlock">
            <h3>User Stats</h3>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>New users (24 Hrs)</Table.HeaderCell>
                  <Table.HeaderCell>New Users (1 Week)</Table.HeaderCell>
                  <Table.HeaderCell>New Users (1 Month)</Table.HeaderCell>
                  <Table.HeaderCell>Total Users</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {usersList.userStats.twenty_four_new_users}
                  </Table.Cell>
                  <Table.Cell>{usersList.userStats.week_new_users}</Table.Cell>
                  <Table.Cell>{usersList.userStats.month_new_users}</Table.Cell>
                  <Table.Cell>
                    {" "}
                    <Link to="user-management">
                      <b style={{ fontSize: 12 }}>
                        <u>{usersList.userStats.total_users}</u>
                      </b>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {/* //kyc  */}
            <h3>Kyc Stats</h3>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Pending Kyc(Level 2)</Table.HeaderCell>
                  <Table.HeaderCell>Kyc Level 1(Verified)</Table.HeaderCell>
                  <Table.HeaderCell>Kyc Level 2(Verified)</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell onClick={() => this.navigateToKyc2("1")}>
                    <Link>
                      <b style={{ fontSize: 12 }}>
                        <u>{usersList.kycStats.pending_kyc_level_2}</u>
                      </b>
                    </Link>
                  </Table.Cell>
                  <Table.Cell onClick={() => this.navigateToKyc1("2")}>
                    <Link>
                      <b style={{ fontSize: 12 }}>
                        <u>{usersList.kycStats.verified_kyc_level_1}</u>
                      </b>
                    </Link>
                  </Table.Cell>
                  <Table.Cell onClick={() => this.navigateToKyc2("2")}>
                    <Link>
                      {" "}
                      <b style={{ fontSize: 12 }}>
                        <u>{usersList.kycStats.verified_kyc_level_2}</u>
                      </b>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {/* //transaction Stats */}
            <h3>Transaction Stats</h3>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Pending TX</Table.HeaderCell>
                  <Table.HeaderCell>
                    No. of Internal Tx (24 hrs)
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    No. of Outgoing tX (24 hrs)
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    No. of Deposit tX (24 hrs)
                  </Table.HeaderCell>
                  <Table.HeaderCell>Fee Revenue(24 hrs)</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Link to="pending-withdraw">
                      <b style={{ fontSize: 12 }}>
                        <u>{usersList.transStats.tranx_pending}</u>
                      </b>
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{usersList.transStats.internal_tranx}</Table.Cell>
                  <Table.Cell>{usersList.transStats.outgoing_tranx}</Table.Cell>
                  <Table.Cell>{usersList.transStats.total_deposits}</Table.Cell>
                  <Table.Cell>
                    {usersList.transStats.btc_fee
                      ? usersList.transStats.btc_fee + "BTC"
                      : "NONE"}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <h3>INR Stats</h3>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Earning Amount</Table.HeaderCell>
                  <Table.HeaderCell>Deposit Amount</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{usersList.inrStats?.inr_earning}</Table.Cell>
                  <Table.Cell>{usersList.inrStats?.inr_deposit}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {/* otc stats  */}
            {/* <h3>OTC Stats</h3>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Trade Type</Table.HeaderCell>
                  <Table.HeaderCell>Trades</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Buyer fee's</Table.HeaderCell>
                  <Table.HeaderCell>Seller Fee's</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {usersList.otcStats.map((data, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {data.status == 1 ? "Active" : "Completed"}
                    </Table.Cell>
                    <Table.Cell>{data.trade}</Table.Cell>
                    <Table.Cell>
                      {data.total_amount} {data.buy_symbol.toUpperCase()}
                    </Table.Cell>
                    <Table.Cell>
                      {convertExponentialToDecimal(parseFloat(data.buyer_fee))}{" "}
                      {data.buy_symbol.toUpperCase()}
                    </Table.Cell>
                    <Table.Cell>
                      {convertExponentialToDecimal(parseFloat(data.seller_fee))}{" "}
                      {data.sell_symbol.toUpperCase()}
                    </Table.Cell>
                  </Table.Row>
                ))}
                {usersList.otcStats.length == 0 && (
                  <TableRow>
                    <Table.Cell>No records found</Table.Cell>
                  </TableRow>
                )}
              </Table.Body>
            </Table> */}
            {/* swap stats  */}

            {/* <h3>Swap Stats</h3>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell># of Swap</Table.HeaderCell>
                  <Table.HeaderCell>Change in IDA(24 hrs)</Table.HeaderCell>
                  <Table.HeaderCell>Change in IDP(24 hrs)</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {this.props.usersList.swapStats.map((data, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{data.total_swaps}</Table.Cell>
                    <Table.Cell>{data.change_in_ida}</Table.Cell>
                    <Table.Cell>{data.change_in_idp}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table> */}
            {/* fund stats  */}
            <h3>Fund Stats</h3>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Hot Wallet Balance</Table.HeaderCell>
                  <Table.HeaderCell>Cold Wallet Balance</Table.HeaderCell>
                  {/* <Table.HeaderCell>
                    Internal TX Amount(24 hrs)
                  </Table.HeaderCell> */}
                  <Table.HeaderCell>
                    External(Outgoing) TX Amount(24hrs)
                  </Table.HeaderCell>

                  <Table.HeaderCell>Deposit Tx Amount(24 hrs)</Table.HeaderCell>
                  <Table.HeaderCell>
                    Cost to Transferring to HOT Wallet
                  </Table.HeaderCell>
                  <Table.HeaderCell>Today's Revenue(Tx+OTC)</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {usersList.fundStats.map((data, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {data.hotWalletBalance} {data.coin_symbol.toUpperCase()}
                    </Table.Cell>
                    <Table.Cell>
                      {" "}
                      {data.coldWalletBalance} {data.coin_symbol.toUpperCase()}
                    </Table.Cell>

                    {/* <Table.Cell>
                      {data.incomingTransactionsAmount}{" "}
                      {data.coin_symbol.toUpperCase()}
                    </Table.Cell> */}
                    <Table.Cell>
                      {data.outgoingTransactionsAmount}{" "}
                      {data.coin_symbol.toUpperCase()}
                    </Table.Cell>
                    <Table.Cell>
                      {data.depositTransactionsAmount}{" "}
                      {data.coin_symbol.toUpperCase()}
                    </Table.Cell>
                    <Table.Cell>
                      {data.costToTransToHotwallet}{" "}
                      {data.coin_symbol.toUpperCase()}
                    </Table.Cell>
                    <Table.Cell>
                      {data.todayRevenue} {data.coin_symbol.toUpperCase()}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {/* balance status  */}
            <h3>24H Balance Status</h3>
            <Table celled className="table-responsive">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  {Object.keys(this.props.userAccounts).length != 0 &&
                    this.props.userAccounts.balanceAccounting.map(
                      (data, index) => (
                        <Table.HeaderCell>
                          {data.coin_symbol.toUpperCase()}
                        </Table.HeaderCell>
                      )
                    )}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {Object.keys(this.props.userAccounts).length != 0 &&
                  this.state.columnSide.map((data, indexRow) => (
                    <Table.Row key={indexRow}>
                      <Table.Cell>{data.toUpperCase()}</Table.Cell>
                      {this.props.userAccounts.balanceAccounting.map(
                        (data, index) =>
                          (indexRow == 0 && (
                            <Table.Cell>{data.deposits}</Table.Cell>
                          )) ||
                          (indexRow == 1 && (
                            <Table.Cell>
                              {data.withdraws > 0 ? "-" : ""}
                              {data.withdraws}
                            </Table.Cell>
                          )) ||
                          (indexRow == 2 && (
                            <Table.Cell>
                              {data.depositFee > 0 ? "-" : ""}
                              {data.depositFee}
                            </Table.Cell>
                          )) ||
                          (indexRow == 3 && (
                            <Table.Cell>{data.withdrawFee}</Table.Cell>
                          )) ||
                          (indexRow == 4 && (
                            <Table.Cell>
                              {data.deposits -
                                data.withdraws -
                                data.depositFee +
                                data.withdrawFee}
                            </Table.Cell>
                          ))
                      )}
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>

            {/* revenue 24 H  */}
            <h3>24H Revenue</h3>
            <Table celled>
              <Table.Header>
                <Table.Row active>
                  <Table.HeaderCell></Table.HeaderCell>

                  <Table.HeaderCell>OTC FEE(Income)</Table.HeaderCell>

                  <Table.HeaderCell>WITHDRAW FEE(Income)</Table.HeaderCell>
                  <Table.HeaderCell>WITHDRAW FEE(Outgo)</Table.HeaderCell>
                  <Table.HeaderCell>DEPOSIT FEE(Outgo)</Table.HeaderCell>
                  <Table.HeaderCell>TOTAL</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {Object.keys(this.props.userAccounts).length != 0 &&
                  this.props.userAccounts.revenueAccounting.map(
                    (data, indexRow) => (
                      <Table.Row key={indexRow}>
                        <Table.Cell>
                          {data.coin_symbol.toUpperCase()}
                        </Table.Cell>

                        <Table.Cell>{data.otcIncome}</Table.Cell>
                        <Table.Cell>{data.withdrawFeeIncome}</Table.Cell>
                        <Table.Cell>{data.withdrawRevenueOutgo}</Table.Cell>
                        <Table.Cell>{data.depositRevenueOutgo}</Table.Cell>
                        <Table.Cell>
                          {data.otcIncome +
                            data.withdrawFeeIncome +
                            data.withdrawRevenueOutgo +
                            data.depositRevenueOutgo}
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
              </Table.Body>
            </Table>
            {/* Accountin */}
            {/* <h3>24H Swap Status</h3>
            <Table celled>
              {Object.keys(this.props.userAccounts).length != 0 &&
                this.props.userAccounts.swapAccounting.length > 0 && (
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>Swap Count</Table.HeaderCell>
                      <Table.HeaderCell>From </Table.HeaderCell>
                      <Table.HeaderCell>To</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                )}

              <Table.Body>
                {Object.keys(this.props.userAccounts).length != 0 &&
                  this.props.userAccounts.swapAccounting.map((data, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        {data.swap_from.toUpperCase() +
                          " " +
                          "to" +
                          " " +
                          data.swap_to.toUpperCase()}
                      </Table.Cell>
                      <Table.Cell>{data.swap_count} </Table.Cell>
                      <Table.Cell>
                        {data.from_amount} {data.swap_from.toUpperCase()}
                      </Table.Cell>
                      <Table.Cell>
                        {data.to_amount} {data.swap_to.toUpperCase()}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                {Object.keys(this.props.userAccounts).length != 0 &&
                  this.props.userAccounts.swapAccounting.length > 0 && (
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>

                      {Object.keys(this.props.userAccounts).length != 0 &&
                        this.props.userAccounts.swapAccounting.length > 0 && (
                          <Table.HeaderCell>
                            {" "}
                            {this.props.userAccounts.swapAccounting[0].swap_from.toUpperCase()}{" "}
                            movement
                          </Table.HeaderCell>
                        )}
                      {Object.keys(this.props.userAccounts).length != 0 &&
                        this.props.userAccounts.swapAccounting.length == 2 && (
                          <Table.Cell>
                            {this.props.userAccounts.swapAccounting[0]
                              .from_amount -
                              this.props.userAccounts.swapAccounting[1]
                                .to_amount}
                          </Table.Cell>
                        )}
                    </Table.Row>
                  )}
                {Object.keys(this.props.userAccounts).length != 0 &&
                  this.props.userAccounts.swapAccounting.length > 0 && (
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      {Object.keys(this.props.userAccounts).length != 0 &&
                        this.props.userAccounts.swapAccounting.length > 1 && (
                          <Table.HeaderCell>
                            {" "}
                            {this.props.userAccounts.swapAccounting[1].swap_from.toUpperCase()}{" "}
                            movement
                          </Table.HeaderCell>
                        )}
                      {Object.keys(this.props.userAccounts).length != 0 &&
                        this.props.userAccounts.swapAccounting.length == 2 && (
                          <Table.Cell>
                            {" "}
                            {this.props.userAccounts.swapAccounting[1]
                              .from_amount -
                              this.props.userAccounts.swapAccounting[0]
                                .to_amount}
                          </Table.Cell>
                        )}
                    </Table.Row>
                  )}
                {Object.keys(this.props.userAccounts).length != 0 &&
                  this.props.userAccounts.swapAccounting.length == 0 && (
                    <Table.Row>
                      <Table.Cell>No records found</Table.Cell>
                    </Table.Row>
                  )}
              </Table.Body>
            </Table> */}
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    usersList: state.userList.dashboardLists,
    userAccounts: state.reports.userAccounts,
    hotWalletBalance: state.funds.hotWalletBalance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    DashboardList: (data) => dispatch(DashboardList(data)),
    getTotalCoinList: () => dispatch(getTotalCoinList()),
    getAccountsList: () => dispatch(getAccountsList()),
    getHotWalletBalance: () => dispatch(getHotWalletBalance()),
    saveKycSelectedFilter1: (data) => dispatch(saveKycSelectedFilter1(data)),
    saveKycSelectedFilter2: (data) => dispatch(saveKycSelectedFilter2(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
