import { TokenServices } from "../../Services/TokenServices";
import { startLoading, stopLoading } from "./loading.action";

import { toast } from "../../components/Toast/Toast";
import { date } from "redux-form-validators";
export const actionTypes = {
  GET_TOKEN_LIST: "GET_TOKEN_LIST",
  SAVE_TOKEN_LIST: "SAVE_TOKEN_LIST",
  SAVE_TOKEN: "SAVE_TOKEN",
  SAVE_PRICE_LIST: "SAVE_PRICE_LIST",
  SAVE_EDIT_TOKEN_DETAILS: "SAVE_EDIT_TOKEN_DETAILS",
  SAVE_EDIT_TOKEN_DETAILS2: "SAVE_EDIT_TOKEN_DETAILS2",
  SAVE_PRICE_DATA: "SAVE_PRICE_DATA",
  UPDATE_BUTTON: "UPDATE_BUTTON",
  SAVE_COIN_LIST: "SAVE_COIN_LIST",
};

/*
 * Select/change Security option
 */
export function saveTokenList(payload) {
  return {
    type: actionTypes.SAVE_TOKEN_LIST,
    data: payload,
  };
}

export function saveCoinList(payload) {
  return {
    type: actionTypes.SAVE_COIN_LIST,
    data: payload,
  };
}

export function saveToken(payload) {
  return {
    type: actionTypes.SAVE_TOKEN,
    data: payload,
  };
}
export function savePriceList(payload) {
  return {
    type: actionTypes.SAVE_PRICE_LIST,
    data: payload,
  };
}
export function saveEditTokenDetails(payload) {
  console.log("savetoken", payload);
  return {
    type: actionTypes.SAVE_EDIT_TOKEN_DETAILS,
    data: payload,
  };
}
export function saveEditTokenDetails2(payload) {
  return {
    type: actionTypes.SAVE_EDIT_TOKEN_DETAILS2,
    data: payload,
  };
}
export function savePriceData(payload) {
  return {
    type: actionTypes.SAVE_PRICE_DATA,
    data: payload,
  };
}
export function updateButton(payload) {
  return {
    type: actionTypes.UPDATE_BUTTON,
    data: payload,
  };
}

export function getTokenList(data) {
  return (dispatch, getState) => {
    if (data.search == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return TokenServices.getTokenList(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveTokenList(res.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getCoinList(data) {
  return (dispatch, getState) => {
    let state = getState();
    return TokenServices.getCoinList(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveCoinList(res.data?.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function addCoinPair(data) {
  return (dispatch, getState) => {
    let state = getState();
    return TokenServices.addCoinPair(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        // dispatch(saveCoinList(res.data?.data));
        console.log("ttttttttttttttttttt : ", res);
        toast.success(res["data"]["message"]);
        dispatch(stopLoading());
        return res?.ata?.data;
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function makeCoinPublic(data, data2) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return TokenServices.makeCoinPublic(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(getTokenList(data2));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function searchToken(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    // if (data.searchKey == "") {
    //   dispatch(startLoading());
    // }
    let state = getState();
    return TokenServices.searchToken(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveToken(res.data.data));

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function addTokens(data, history) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return TokenServices.addTokens(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        history.push("token-management");
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function deleteTokens(id, params) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return TokenServices.deleteTokens(id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(getTokenList(params));

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function enableDisableToken(action, id, params) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return TokenServices.enableDisableToken(
      action,
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      },
      id
    )
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(getTokenList(params));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getPrice(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TokenServices.getPrice(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(savePriceList(res.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getEditTokenDetails(id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TokenServices.getEditTokenDetails(id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveEditTokenDetails(res.data.data.coinPriceData.rows));
        dispatch(saveEditTokenDetails2(res.data.data.coinData));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function updateEditTokenDetails(id, data, history) {
  return (dispatch, getState) => {
    console.log("data data", data);
    dispatch(startLoading());
    let state = getState();
    return TokenServices.updateEditTokenDetails(id, data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        toast.success(res.data.message);
        window.history.back();
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getTokenPriceByAlias(alias, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TokenServices.getTokenPriceByAlias(alias, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(savePriceData(res.data.data));
        dispatch(updateButton(false));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
          dispatch(updateButton(true));
        }
        dispatch(stopLoading());
      });
  };
}

export function getPriceByUsd(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TokenServices.getPriceByUsd(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(savePriceData(res.data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function swapOnOff(data, data2) {
  return (dispatch, getState) => {
    let id = data.id;
    delete data.id;
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return TokenServices.swapOnOff(id, data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(getTokenList(data2));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function autoTransactionOnOff(data, data2) {

  console.log(data2,'==========>data2')
  return (dispatch, getState) => {
    let id = data.id;
    delete data.id;
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return TokenServices.autoTransactionOnOff(id, data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(getTokenList(data2));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function setSwapFee(data, data2) {
  return (dispatch, getState) => {
    let id = data.id;
    delete data.id;
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return TokenServices.setSwapFee(id, data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(getTokenList(data2));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function setWithdawMaxMinLimit(data, data2) {
  return (dispatch, getState) => {
    let id = data.id;
    delete data.id;
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return TokenServices.setWithdawMaxMinLimit(id, data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(getTokenList(data2));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function updatePriceSource(params, tokenListParams) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TokenServices.updatePriceSource(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(stopLoading());
        dispatch(getTokenList(tokenListParams));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
