import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { Pagination, Dropdown, Form } from "semantic-ui-react";

import "./UserManagement.scss";
import CustomHeader from "../../../components/Headers/CustomHeader";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router";
import { API_HOST } from "../../../constants/index";
import { deletedUserList } from "../../../Redux/actons/userList.action";
import { listDateFormat } from "../../../utils/utils";
import { Link } from "react-router-dom";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

export class DeleteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: 10,
      page: 1,
      order: "asc",
      sortBy: "",

      filterType: "",
    };
  }
  componentDidMount = () => {
    this.checkRouteValid();
    this.getDeletedUserList();
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      var isfound = false;
      // check user Permissions if route accessible
      await this.props.permissions.forEach((element) => {
        if (element.title == "User Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };
  getDeletedUserList = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      filterType: this.state.filterType,
    };
    this.props.deletedUserList(data);
  };

  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getDeletedUserList();
    });
  };
  filterBy = (e, data) => {
    if (data.value !== "none") {
      this.setState({ filterType: data.value }, () => {
        this.getDeletedUserList();
      });
    } else {
      this.setState({ filterType: "" }, () => {
        this.getDeletedUserList();
      });
    }
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getDeletedUserList();
    });
  };

  //search user
  searchUser = (value) => {
    this.setState({ searchKey: value, page: 1 }, () => {
      this.getDeletedUserList();
    });
  };

  render() {
    return (
      <div>
        <CustomHeader />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="user-management">User Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Delete User</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Deleted Users List</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search User"
                        style={{
                          color: "black",
                        }}
                        onChange={(e) => this.searchUser(`${e.target.value}`)}
                      ></Input>
                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Email-Id</th>
                        <th scope="col">Date</th>
                        <th scope="col">Deleted By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.deleteUserList.map((data, index) => (
                        <tr className="table-tr" key={index}>
                          <td>{data.email}</td>
                          <td>{listDateFormat(data.created_at)}</td>
                          <td>{data.deleted_by}</td>
                        </tr>
                      ))}
                      {this.props.totalRecords === 0 && (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {this.props.totalRecords > this.state.perPage && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalRecords / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <div></div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deleteUserList: state.userList.deleteUserList,
    totalRecords: state.userList.totalDelete,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deletedUserList: (data) => dispatch(deletedUserList(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeleteUser)
);
