import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
} from "reactstrap";
import { Pagination, Confirm, Dropdown, Menu } from "semantic-ui-react";
import CustomHeader from "../../../components/Headers/CustomHeader";
import { withRouter } from "react-router";

import { listDateFormat } from "../../../utils/utils";
import {
  API_HOST,
  BTC_TXN_BTC,
  ETH_TXN_ETH,
  BTC_ADDRESS_BTC,
  ETH_ADDRESS_ETH,
  BSC_TXN_BSC,
  MATIC_TXN_MATIC,
} from "../../../constants/index";

import { getDepositCostList } from "../../../Redux/actons/report.action";
import { Link } from "react-router-dom";
const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
export class TokenDepositReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      sort: "asc",
    };
  }

  componentDidMount = () => {
    this.getAllDepositList();
  };

  goToTRaderReport = () => {
    this.props.history.push("reports");
  };

  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ sort: data.value }, () => {
      this.getAllDepositList();
    });
  };
  redirectToHashSite = (coin, hash) => {
    if (coin == "btc") {
      window.open(`${BTC_TXN_BTC}/${hash}`, "_blank");
    }
    if (coin == "eth" || coin == "ida" || coin == "orbs") {
      window.open(`${ETH_TXN_ETH}/${hash}`, "_blank");
    }
    if (coin == "bnb" || coin == "busd" || coin == "rena") {
      window.open(`${BSC_TXN_BSC}/${hash}`, "_blank");
    }
    if (coin == "matic") {
      window.open(`${MATIC_TXN_MATIC}/${hash}`, "_blank");
    }
  };

  redirectToUrl = (coin, address) => {
    if (coin == "btc") {
      window.open(`${BTC_ADDRESS_BTC}/${address}`, "_blank");
    }
    if (coin == "eth" || coin == "ida" || coin == "orbs") {
      window.open(`${ETH_ADDRESS_ETH}/${address}`, "_blank");
    }
    if (coin == "bnb" || coin == "busd" || coin == "rena") {
      window.open(`${BSC_TXN_BSC}/${address}`, "_blank");
    }
    if (coin == "matic") {
      window.open(`${MATIC_TXN_MATIC}/${address}`, "_blank");
    }
  };
  redirectUrl = (coin, address) => {
    if (coin == "btc") {
      window.open(`${BTC_ADDRESS_BTC}/${address}`, "_blank");
    }
    if (coin == "eth" || coin == "ida" || coin == "orbs") {
      window.open(`${ETH_ADDRESS_ETH}/${address}`, "_blank");
    }
    if (coin == "bnb" || coin == "busd" || coin == "rena") {
      window.open(`${BSC_TXN_BSC}/${address}`, "_blank");
    }
    if (coin == "matic") {
      window.open(`${MATIC_TXN_MATIC}/${address}`, "_blank");
    }
  };

  getAllDepositList = () => {
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      sort: this.state.sort,
    };
    this.props.getDepositCostList(data);
  };

  //page change
  changeApprovedPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getAllDepositList();
    });
  };

  downLoadDepositList = () => {
    window.location.href = `${API_HOST}api/v1/admin/report/download/tokenDepositCost`;
  };

  render() {
    return (
      <div>
        <CustomHeader />
        <div>
          <Container className="contentBlock" fluid>
            <div
              className="header-body"
              style={{ paddingLeft: 25, paddingRight: 25 }}
            >
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div style={{ width: "100%" }}>
                        <h3 className="mb-0">Token Deposit Fee List</h3>
                      </div>
                      <div className="selectSearchOption">
                        <Dropdown
                          className="selectUserStyle"
                          options={options}
                          selection
                          value={this.state.sort}
                          onChange={(e, data) => this.filterBy(e, data)}
                        />

                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.downLoadDepositList()}
                        >
                          Download Token Deposit Cost list
                        </Button>

                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.goToTRaderReport()}
                        >
                          Back
                        </Button>
                      </div>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">TXID</th>
                          <th scope="col">From Address</th>
                          <th scope="col">To Address</th>
                          <th scope="col">Coin Symbol</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Gas Limit</th>
                          <th scope="col">Gas Price</th>
                          <th scope="col">Gas Revert</th>
                          <th scope="col">Tx Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.depositList.map((usersList, index) => (
                          <tr className="table-tr" key={index}>
                            {/* <td>{usersList.tx_id}</td> */}
                            <td
                              title={usersList.tx_id}
                              onClick={() =>
                                this.redirectToHashSite(
                                  usersList.coin_symbol,
                                  usersList.tx_id
                                )
                              }
                            >
                              {usersList.tx_id
                                ? usersList.tx_id.slice(0, 12) + "..."
                                : "NONE"}
                            </td>
                            {/* <td>{usersList.from_adrs}</td>
                            <td>{usersList.to_adrs}</td> */}
                            <td
                              title={usersList.from_adrs}
                              onClick={() =>
                                this.redirectToUrl(
                                  usersList.coin_symbol,
                                  usersList.from_adrs
                                )
                              }
                            >
                              {usersList.from_adrs
                                ? usersList.from_adrs.slice(0, 12) + "..."
                                : "NONE"}
                            </td>
                            <td
                              title={usersList.to_adrs}
                              onClick={() =>
                                this.redirectUrl(
                                  usersList.coin_symbol,
                                  usersList.to_adrs
                                )
                              }
                            >
                              {usersList.to_adrs
                                ? usersList.to_adrs.slice(0, 12) + "..."
                                : "NONE"}
                            </td>
                            <td>{usersList.coin_symbol}</td>
                            <td>{usersList.amount}</td>
                            <td>{usersList.gas_limit}</td>
                            <td>{usersList.gas_price}</td>
                            <td>{usersList.gas_revert}</td>
                            {/* <td>{usersList.created_at}</td> */}
                            <td>{listDateFormat(usersList.created_at)}</td>
                          </tr>
                        ))}
                        {this.props.depositList.length == 0 && (
                          <td>No record</td>
                        )}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        {this.props.depositTotalRecords > 0 && (
                          <Pagination
                            onPageChange={(e, data) =>
                              this.changeApprovedPage(e, data)
                            }
                            boundaryRange={1}
                            defaultActivePage={1}
                            ellipsisItem={true}
                            firstItem={true}
                            lastItem={true}
                            siblingRange={1}
                            totalPages={Math.ceil(
                              this.props.depositTotalRecords /
                                this.state.perPage
                            )}
                          />
                        )}
                      </nav>
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    depositList: state.reports.depositList,
    depositTotalRecords: state.reports.depositTotalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDepositCostList: (data) => dispatch(getDepositCostList(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TokenDepositReports)
);
