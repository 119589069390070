import { actionTypes } from "../actons/report.action";

const initialState = {
  BalanceList: [],
  totalRecords: "",
  tradingList: [],
  tradingTotalRecords: "",
  depositList: [],
  withdrawList: [],
  depositTotalRecords: "",
  withdrawTotalRecords: "",
  userAccounts: {},
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BALANCE_LIST:
      return {
        ...state,
        BalanceList: action.data.data,
        totalRecords: action.data.meta.total,
      };
    case actionTypes.GET_ACCOUNTS:
      return {
        ...state,
        userAccounts: action.data.data,
      };
    case actionTypes.GET_TRADING_REPORT:
      return {
        ...state,
        tradingList: action.data.data,
        tradingTotalRecords: action.data.meta.total,
      };
    case actionTypes.GET_DEPOSIT_LIST:
      return {
        ...state,
        depositList: action.data.data,
        depositTotalRecords: action.data.meta.total,
      };
    case actionTypes.GET_WITHDRAW_LIST:
      return {
        ...state,
        withdrawList: action.data.data,
        withdrawTotalRecords: action.data.meta.total,
      };
    default:
      return state;
  }
};

export default reports;
