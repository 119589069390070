import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { getInfluencerHistory } from "../../../Redux/actons/referral.action";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Dropdown, Pagination } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { setType } from "utils/utils";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

export class InfluencerHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      sortBy: "",
      order: "desc",
      id: "",
    };
  }
  componentDidMount = () => {
    if (this.props.location.state) {
      localStorage.setItem("id", this.props.location.state.id);
      if (this.state.id == "") {
        this.setState({ id: this.props.history.location.state.id }, () => {
          this.getHistoryList(String(this.props.history.location.state.id));
        });
      } else {
        this.props.history.goBack();
      }
    } else {
      this.setState({ id: localStorage.getItem("id") });
      this.getHistoryList(localStorage.getItem("id"));
    }
  };

  getHistoryList = (id) => {
    let data = {
      search: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      id: id,
    };
    this.props.getInfluencerHistory(data);
  };

  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getHistoryList();
    });
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getHistoryList();
    });
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Influencer Referral History" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="influencer-management">Influencer Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Influencer History</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search by email"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.getHistoryList();
                              }
                            }
                          );
                        }}
                      ></Input>

                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.getHistoryList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Sr. No.</th>
                        <th scope=" col">Plan Type</th>
                        <th scope="col">Referral amount</th>
                        <th scope="col">Reawrd Type</th>
                        <th scope="col">Influencer Name</th>
                        {/* <th scope="col">Coupon</th> */}
                        <th scope="col">Referred To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.getReferalHistoryList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.getReferalHistoryList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{index + 1}.</td>
                            <td>{data?.plan_type.toUpperCase()}</td>
                            <td>
                              {data?.reward_amount}{" "}
                              {data?.coin_symbol.toUpperCase()}
                            </td>
                            <td>{setType(data?.reward_type)}</td>
                            <td>{data?.name}</td>
                            {/* <td>{data?.coupon.toUpperCase()}</td> */}
                            <td>{data?.email}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    getReferalHistoryList: state?.referralList?.influencerHistory?.history,
    total: state?.referralList?.influencerHistory?.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInfluencerHistory: (data) => dispatch(getInfluencerHistory(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InfluencerHistory)
);
