import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  Pagination,
  Confirm,
  Form,
  Dropdown,
  Grid,
  GridColumn,
} from "semantic-ui-react";
import { withStyles, Typography } from "@material-ui/core";
import Switch from "react-switch";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import {
  getFiatHistoryList,
  getBalance,
  changeTransactionStatus,
  getFiatStatus,
  getBankDepositSettings,
  changeDepositSettings,
  getAllDepositListCSV,
} from "../../Redux/actons/inrMangement.action";
import { Link } from "react-router-dom";
import { toast } from "../../components/Toast/Toast";
import Header from "components/Headers/Header";
import CardHeader from "reactstrap/lib/CardHeader";
import Input from "reactstrap/lib/Input";
import { listDateFormat } from "utils/utils";
import DashboardResult from "views/ContactQueryManagement/SubjectManagement/DashboardResult";
import MetaMaskConnection from "components/WalletConnection/MetaMaskConnection";
import TronConnection from "components/WalletConnection/TronConnection";
import { IMAGE_PATH } from "constants/index";
import moment from "moment";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

const filter = [
  { key: "p", text: "None", value: "" },
  { key: "s", text: "Accepted", value: "accept" },
  { key: "k", text: "Pending", value: "pending" },
  { key: "n", text: "Declined", value: "declined" },
];

const typeFilter = [
  { key: "m", text: "None", value: "" },
  { key: "k", text: "Manual", value: "manual" },
  { key: "N", text: "Automatic", value: "automatic" },
];

export class INRManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "desc",
      sortBy: "",
      transId: "",
      action: "",
      transaction: "",
      showDetails: false,
      fiatStatus: "",
      modal1: false,
      status: "",
      Comment: "",
      type: this.props.statusType,
      despositSettings: "",
      payment_source: "",
      start_date: null,
      end_date: null,
    };
  }

  componentDidMount = () => {
    this.getFiatHistory();
    this.getDepositSetting();
  };
  getDepositSetting = () => {
    this.props.getBankDepositSettings();
  };
  getFiatHistory = () => {
    let data = {
      perPage: this.state.perPage,
      page: this.state.page,
      searchKey: this.state.searchKey,
      order: this.state.order,
      status: this.state.status,
      type: this.state.type,
      payment_source: this.state.payment_source,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };
    let data1 = {
      perPage: this.state.perPage,
      page: this.state.page,
      searchKey: this.state.searchKey,
      order: this.state.order,
      status: this.state.status,
      type: this.state.type,
    };

    this.props.getFiatHistoryList(data);
    this.props.getFiatStatus(data1);
    this.props.getBalance();
  };

  openConfirmApproveModal = async (id, transId, amount) => {
    if (this.props.statusType !== "deposit") {
      await this.props.getBalance();
      {
        parseFloat(amount) > parseFloat(this.props.balance)
          ? alert("Insufficient balance")
          : this.setState({
              openConfirm: true,
              action: id,
              transId: transId,
            });
      }
    } else {
      this.setState({
        openConfirm: true,
        action: id,
        transId: transId,
      });
    }
  };

  close = () => {
    this.setState({ openConfirm: false });
  };

  showModal = (data) => {
    this.setState({ fiatStatus: data }, () => {
      this.closeModal();
    });
  };

  closeModal = () => {
    this.setState({ modal1: !this.state.modal1 });
  };

  onConfirmation = () => {
    // if(Comment == ""){
    //   // alert("Comment is R")
    // }
    let data = {
      id: this.state.transId,
      message: this.state.Comment,
      status: this.state.action == 1 ? "accept" : "declined",
    };

    let data2 = {
      perPage: this.state.perPage,
      page: this.state.page,
      searchKey: this.state.searchKey,
      order: this.state.order,
      status: this.state.status,
      type: this.state.type,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };

    this.props.changeTransactionStatus(data, data2);
    this.setState({ openConfirm: false });
  };

  showDetails = (tx) => {
    this.setState({ transaction: tx }, () => {
      this.toggleModal();
    });
  };

  toggleModal = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getFiatHistory();
    });
  };

  onSearchFee = (search) => {
    // this.setState({ searchKey: search, page: 1 }, () => {
    this.getFiatHistory();
    // });
  };

  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getFiatHistory();
    });
  };

  filterBy = (e, data) => {
    if (data.value !== "") {
      this.setState({ status: data.value }, () => {
        this.getFiatHistory();
      });
    } else {
      this.setState({ status: "" }, () => {
        this.getFiatHistory();
      });
    }
  };

  filterByType = (e, data) => {
    if (data.value !== "") {
      this.setState({ type: data.value }, () => {
        this.getFiatHistory();
      });
    } else {
      this.setState({ type: "" }, () => {
        this.getFiatHistory();
      });
    }
  };
  filterByPaymentSource = (e, data) => {
    if (data.value !== "") {
      this.setState({ payment_source: data.value }, () => {
        this.getFiatHistory();
      });
    } else {
      this.setState({ payment_source: "" }, () => {
        this.getFiatHistory();
      });
    }
  };
  filterByStartDate = (e) => {
    if (e.target.value) {
      this.setState({ start_date: e.target.value }, () => {
        this.getFiatHistory();
      });
    } else {
      this.setState({ start_date: null }, () => {
        this.getFiatHistory();
      });
    }
  };
  filterByStartEnd = (e) => {
    if (e.target.value) {
      this.setState({ end_date: e.target.value }, () => {
        this.getFiatHistory();
      });
    } else {
      this.setState({ end_date: null }, () => {
        this.getFiatHistory();
      });
    }
  };
  redirectUser = (id) => {
    this.props.history.push({
      pathname: `user-inr`,
      state: id,
    });
  };
  changeStatus = async (type, status) => {
    let object;
    if (type === "automatic") {
      object = {
        is_manual_deposit: this.props.depositSettings.is_manual_deposit,
        is_automatic_deposit: status ? "1" : "0",
      };
    } else {
      object = {
        is_manual_deposit: status ? "1" : "0",
        is_automatic_deposit: this.props.depositSettings.is_automatic_deposit,
      };
    }

    await this.props.changeDepositSettings(object);
    this.getDepositSetting();
  };
  downloadDepositRequest = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      page: 1,
      perPage: this.props.total,
      status: this.state.status,
      type: this.state.type,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };
    this.props.getAllDepositListCSV(data);
  };
  render() {
    let { handleSubmit, statusType } = this.props;
    // this.setState({ type: statusType === "deposit" });
    return (
      <div>
        {/* <Header headerTitle="inr management" /> */}
        {/* approve disaprove confirm */}
        <div>
          {/* <Confirm
            content={`Are you sure to ${
              this.state.action == 1 ? "Approve" : "Reject"
            }`}
            style={{
              height: "auto",
              top: "5%",
              left: "auto",
              bottom: "auto",
              right: "auto",
            }}
            size="small"
            className="confirm-model"
            open={this.state.openConfirm}
            type={this.state.action}
            onCancel={this.close}
            onConfirm={this.onConfirmation}
          /> */}
          <Modal
            isOpen={this.state.openConfirm}
            toggle={this.close}
            className="modalCustom"
          >
            <ModalHeader toggle={this.close}>
              <h1>
                <span>
                  Transaction{" "}
                  {this.state.action == 1 ? "Approval" : "Rejection"} ?
                </span>
              </h1>
            </ModalHeader>
            <ModalBody>
              <div className="userNameList">
                {/* <div className="itemStyle">
                  <label>Account no. :</label>
                  <label>
                    {this.state.transaction.account_no
                      ? this.state.transaction.account_no
                      : "None"}
                  </label>
                </div>
                <div className="itemStyle">
                  <label>Reference id: </label>
                  <label>
                    {this.state.transaction.reference_id
                      ? this.state.transaction.reference_id
                      : "None"}
                  </label>
                </div>
                <div className="itemStyle">
                  <label>Transaction Date:</label>
                  <label>
                    {this.state.transaction.trnx_date
                      ? this.state.transaction.trnx_date
                      : "None"}
                  </label>
                </div> */}
                <div className="itemStyle">
                  <h1>
                    <label>
                      {`Are you sure to ${
                        this.state.action == 1 ? "Approve" : "Reject"
                      } ?`}
                    </label>
                  </h1>
                </div>
                <div className="userNameList">
                  <Input
                    placeholder="Comment"
                    onChange={(e) => {
                      this.setState({ Comment: e.target.value });
                    }}
                  ></Input>
                </div>
                <div className="itemStyle1">
                  <Button color="error" onClick={this.close}>
                    Back
                  </Button>
                  <Button color="success" onClick={this.onConfirmation}>
                    Confirm
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              {statusType === "deposit" && (
                <Card className="shadow" style={{ marginBottom: "12px" }}>
                  <GridColumn>
                    <div className="row">
                      <Card>
                        <CardHeader className="border-0">
                          <h3 className="mb-0">
                            Active/de-active deposits mode
                          </h3>
                        </CardHeader>

                        <div
                          className="registerstyle"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "12px",
                            padding: "0 1.25rem ",
                          }}
                        >
                          <label
                            className="label"
                            style={{ color: "#000", marginRight: "6px" }}
                          >
                            Automatic Deposit
                          </label>
                          <Switch
                            onChange={(e) => this.changeStatus("automatic", e)}
                            checked={
                              this.props.depositSettings
                                .is_automatic_deposit === "1"
                                ? true
                                : false
                            }
                            offColor="#FF0000"
                          />
                        </div>

                        <div
                          className="registerstyle"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "12px",
                            padding: "0 1.25rem",
                          }}
                        >
                          <label
                            className="label"
                            style={{ color: "#000", marginRight: "6px" }}
                          >
                            Manual Deposit
                          </label>
                          <Switch
                            onChange={(e) => this.changeStatus("manual", e)}
                            checked={
                              this.props.depositSettings.is_manual_deposit ===
                              "1"
                                ? true
                                : false
                            }
                            offColor="#FF0000"
                          />
                        </div>
                      </Card>
                    </div>
                  </GridColumn>
                </Card>
              )}
              <Card className="shadow">
                {/* <CardHeader className="border-0"> */}
                <Grid.Row
                  columns={2}
                  style={{
                    display: "flex",
                    width: "100%",
                    //   flexWrap: "wrap",
                    // justifyContent: "space-between",
                    marginRight: 0,
                    marginLeft: 0,
                  }}
                  // style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  {statusType === "deposit" ? (
                    <>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Pending Deposit"}
                          labResult={
                            this.props?.queryListRecords?.dp_failedList
                              ? this.props?.queryListRecords?.dp_failedList
                              : "0"
                          }
                        />
                      </GridColumn>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Accept Deposit"}
                          labResult={
                            this.props?.queryListRecords?.dp_acceptList
                              ? this.props?.queryListRecords?.dp_acceptList
                              : "0"
                          }
                        />
                      </GridColumn>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Failed Deposit"}
                          labResult={
                            this.props?.queryListRecords?.dp_failedList
                              ? this.props?.queryListRecords?.dp_failedList
                              : "0"
                          }
                        />
                      </GridColumn>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Refunded Deposit"}
                          labResult={
                            this.props?.queryListRecords?.dp_refundedList
                              ? this.props?.queryListRecords?.dp_refundedList
                              : "0"
                          }
                        />
                      </GridColumn>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Declined Deposit"}
                          labResult={
                            this.props?.queryListRecords?.dp_declinedList
                              ? this.props?.queryListRecords?.dp_declinedList
                              : "0"
                          }
                        />
                      </GridColumn>
                    </>
                  ) : (
                    <>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Pending Withdraw"}
                          labResult={
                            this.props?.queryListRecords?.wd_pendingList
                              ? this.props?.queryListRecords?.wd_pendingList
                              : "0"
                          }
                        />
                      </GridColumn>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Accepted Withdraw"}
                          labResult={
                            this.props?.queryListRecords?.wd_acceptList
                              ? this.props?.queryListRecords?.wd_acceptList
                              : "0"
                          }
                        />
                      </GridColumn>

                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Failed Withdraw"}
                          labResult={
                            this.props?.queryListRecords?.wd_failedList
                              ? this.props?.queryListRecords?.wd_failedList
                              : "0"
                          }
                        />
                      </GridColumn>

                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Refunded Withdraw"}
                          labResult={
                            this.props?.queryListRecords?.wd_refundedList
                              ? this.props?.queryListRecords?.wd_refundedList
                              : "0"
                          }
                        />
                      </GridColumn>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Declined Withdraw"}
                          labResult={
                            this.props?.queryListRecords?.wd_declinedList
                              ? this.props?.queryListRecords?.wd_declinedList
                              : "0"
                          }
                        />
                      </GridColumn>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Inprogress Withdraw"}
                          labResult={
                            this.props?.queryListRecords?.wd_declinedList
                              ? this.props?.queryListRecords?.wd_declinedList
                              : "0"
                          }
                        />
                      </GridColumn>
                    </>
                  )}
                </Grid.Row>
              </Card>
            </div>
            <CardHeader className="border-0">
              <div style={{ width: "100%" }}>
                <h3 style={{ fontWeight: "1000" }} className="mb-2">
                  Total Balance: ₹{this.props.balance}{" "}
                </h3>
              </div>
              <div className="selectSearchOption">
                <Input
                  placeholder="Search"
                  onChange={(e) => {
                    this.setState(
                      { searchKey: e.target.value, page: 1 },
                      () => {
                        if (this.state.searchKey == "") {
                          this.onSearchFee();
                        }
                      }
                    );
                  }}
                ></Input>
                <Button
                  color="success btnMamagePermission"
                  onClick={() => this.onSearchFee()}
                >
                  Search
                </Button>
                <Dropdown
                  className="selectUserStyle ml-2"
                  options={options}
                  selection
                  value={this.state.order}
                  onChange={(e, data) => this.sortBy(e, data)}
                />
                <Dropdown
                  className="selectUserStyle mx-2"
                  options={filter}
                  placeholder="Filter by Status"
                  selection
                  onChange={(e, data) => this.filterBy(e, data)}
                />
                <Dropdown
                  className="selectUserStyle mx-2"
                  options={typeFilter}
                  placeholder="Filter by payment source"
                  selection
                  onChange={(e, data) => this.filterByPaymentSource(e, data)}
                />

                {/* <Dropdown
                  className="selectUserStyle"
                  options={typeFilter}
                  placeholder="Filter by type"
                  selection
                  onChange={(e, data) => this.filterByType(e, data)}
                /> */}
              </div>

              <div
                className="selectSearchOption"
                style={{ marginTop: "20px", alignItems: "end" }}
              >
                {statusType == "deposit" && (
                  <>
                    <Form.Group>
                      <Form.Field style={{ marginRight: "10px" }}>
                        <label style={{ color: "#000" }}>Start Date</label>
                        <Input
                          type="date"
                          max={new Date().toISOString().split("T")[0]}
                          name="start_date"
                          value={this.state.start_date}
                          onChange={(e) => {
                            this.filterByStartDate(e);
                          }}
                          style={{ marginRight: "10px" }}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field>
                        <label style={{ color: "#000" }}>End Date</label>
                        <Input
                          type="date"
                          name="end_date"
                          min={
                            this.state.start_date &&
                            new Date(this.state.start_date)
                              .toISOString()
                              .split("T")[0]
                          }
                          value={this.state.end_date}
                          onChange={(e) => this.filterByStartEnd(e)}
                        />
                      </Form.Field>
                    </Form.Group>
                  </>
                )}

                <Button
                  color="success btnMamagePermission"
                  onClick={() => this.showModal(this.props.fiatStatus)}
                >
                  Total Transaction History
                </Button>
                <Link to="bank-account-management">
                  <Button color="success btnMamagePermission">
                    Fee/Limit Management
                  </Button>
                </Link>
                {statusType === "deposit" && (
                  <Button
                    color="success btnMamagePermission"
                    onClick={this.downloadDepositRequest}
                  >
                    Download CSV
                  </Button>
                )}
              </div>
              {/* <div className="selectSearchOption">
                <div>
                  <MetaMaskConnection />
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <TronConnection />
                </div>
              </div> */}
            </CardHeader>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Name</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Type</th>
                        <th scope="col">Status</th>
                        {/* <th scope="col">Details</th> */}
                        <th scope="col">Created_At</th>

                        <th scope="col">View</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.fiatHistoryList.map((data, index) => (
                        <tr className="table-tr" key={index}>
                          {/* <Link to="user-management"> */}
                          <td>{data.email ? data.email : "None"}</td>
                          {/* </Link> */}
                          <td>
                            {data.name ? data.name.toUpperCase() : "None"}
                          </td>
                          <td>{data.amount}</td>
                          <td>{data.type.toUpperCase()}</td>
                          <td>{data.status.toUpperCase()}</td>
                          <td>
                            {data?.created_at != ""
                              ? listDateFormat(data?.created_at)
                              : "None"}
                          </td>

                          <td>
                            <Button
                              color="success"
                              onClick={() => this.showDetails(data)}
                            >
                              Show Details
                            </Button>
                          </td>
                          <td>
                            <Button
                              color="success"
                              disabled={data.status != "pending"}
                              onClick={() =>
                                this.openConfirmApproveModal(
                                  1,
                                  data.id,
                                  data.amount
                                )
                              }
                              // style={{
                              //   visibility:
                              //     data.status == "pending"
                              //       ? "visible"
                              //       : "hidden",
                              // }}
                            >
                              Accept
                            </Button>
                            <Button
                              color="danger"
                              disabled={data.status != "pending"}
                              onClick={() =>
                                this.openConfirmApproveModal(
                                  2,
                                  data.id,
                                  data.amount
                                )
                              }
                              // style={{
                              //   visibility:
                              //     data.status == "pending"
                              //       ? "visible"
                              //       : "hidden",
                              // }}
                            >
                              Reject
                            </Button>
                            <Button
                              color="success"
                              onClick={() => this.redirectUser(data.user_id)}
                            >
                              View User
                            </Button>
                          </td>
                        </tr>
                      ))}
                      {this.props.fiatHistoryList.length == 0 && (
                        <td>No record found</td>
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.showDetails}
          toggle={this.toggleModal}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.toggleModal}>
            <h1>
              <span>Transaction Informations</span>
            </h1>
          </ModalHeader>
          <ModalBody>
            <div className="userNameList">
              <div className="itemStyle">
                <label>Account no. :</label>
                <label>
                  {this.state.transaction.account_no
                    ? this.state.transaction.account_no
                    : "None"}
                </label>
              </div>
              <div className="itemStyle">
                <label>Email :</label>
                <label>
                  {this.state.transaction.email
                    ? this.state.transaction.email
                    : "None"}
                </label>
              </div>
              <div className="itemStyle">
                <label>Amount :</label>
                <label>
                  {this.state.transaction.amount
                    ? `${this.state.transaction.amount} INR`
                    : "None"}
                </label>
              </div>
              {statusType === "deposit" && (
                <div className="itemStyle">
                  <label>Payment Mode :</label>
                  <label>
                    {this.state.transaction.payment_mode
                      ? `${this.state.transaction.payment_mode}`
                      : "None"}
                  </label>
                </div>
              )}

              <div className="itemStyle">
                <label>Reference id: </label>
                <label>
                  {this.state.transaction.reference_id
                    ? this.state.transaction.reference_id
                    : "None"}
                </label>
              </div>
              <div className="itemStyle">
                <label>Transaction Date:</label>
                <label>
                  {this.state.transaction.trnx_date
                    ? moment(this.state.transaction.trnx_date).format("lll")
                    : "None"}
                </label>
              </div>
              <div className="itemStyle">
                <label>Message :</label>
                <label>
                  {this.state.transaction.message
                    ? this.state.transaction.message
                    : "None"}
                </label>
              </div>
              {this.state.transaction.recipt === null ? (
                <div className="itemStyle">
                  <label>No receipt found</label>
                </div>
              ) : (
                <div className="itemStyle">
                  <span style={{ cursor: "pointer" }}>
                    <img
                      onClick={() =>
                        window.open(
                          `${IMAGE_PATH}${this.state.transaction.recipt}`,
                          "_blank"
                        )
                      }
                      style={{ width: "100%", height: 200 }}
                      src={
                        this.state.transaction.recipt
                          ? `${IMAGE_PATH}${this.state.transaction.recipt}`
                          : "No image"
                      }
                    ></img>
                  </span>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>

        {/* SHOW TOTAL TRASACTION STATUS  */}
        {this.state.modal1 && (
          <Modal
            isOpen={this.state.modal1}
            toggle={this.closeModal}
            className={[this.props.className, "modalCustom"]}
          >
            <ModalHeader toggle={this.closeModal}>
              <h1>
                <span>Transaction Status</span>
              </h1>
            </ModalHeader>
            <ModalBody>
              <div className="userNameList">
                {this.state.fiatStatus.deposit && (
                  <div>
                    <div className="itemStyle">
                      <h4 style={{ color: "white" }}>Deposit</h4>
                    </div>

                    {this?.state?.fiatStatus?.deposit.map((data) => (
                      <div className="itemStyle">
                        <label>
                          {data.status ? data.status.toUpperCase() : "None"}
                        </label>
                        <label>{data.amount ? data?.amount : "None"}</label>
                      </div>
                    ))}
                  </div>
                )}

                {this.state.fiatStatus.withdraw && (
                  <div>
                    <div className="itemStyle">
                      <h4 style={{ color: "white" }}>Withdraw</h4>
                    </div>

                    {this?.state?.fiatStatus?.withdraw.map((data) => (
                      <div className="itemStyle">
                        <label>
                          {data.status ? data.status.toUpperCase() : "None"}
                        </label>
                        <label>{data.amount ? data?.amount : "None"}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  // console.log("Update Balance", state.fiatHistory.balance);
  return {
    fiatHistoryList: state.fiatHistory.fiatHistoryList,
    queryListRecords: state.fiatHistory.queryListRecords,
    total: state.fiatHistory.total,
    fiatStatus: state.fiatHistory.fiatStatus,
    balance: state.fiatHistory.balance,
    depositSettings: state.fiatHistory.depositSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFiatHistoryList: (data) => dispatch(getFiatHistoryList(data)),
    getBalance: () => dispatch(getBalance()),
    getFiatStatus: (data) => dispatch(getFiatStatus(data)),
    changeTransactionStatus: (data, data2) =>
      dispatch(changeTransactionStatus(data, data2)),
    getBankDepositSettings: () => dispatch(getBankDepositSettings()),
    changeDepositSettings: (data) => dispatch(changeDepositSettings(data)),
    getAllDepositListCSV: (data) => dispatch(getAllDepositListCSV(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "ApprovedKyc" })(INRManagement))
);
