import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  IMAGE_PATH,
  BSC_ADDRESS_BSC,
  BTC_ADDRESS_BTC,
  ETH_ADDRESS_ETH,
  MATIC_ADDRESS_MATIC,
} from "../../constants/index";
import Header from "components/Headers/Header";
import { Dropdown, Pagination, Radio } from "semantic-ui-react";
import CustomHeader from "../../components/Headers/CustomHeader";
import {
  getTokenList,
  deleteTokens,
  enableDisableToken,
  makeCoinPublic,
  swapOnOff,
  autoTransactionOnOff,
  updatePriceSource,
} from "../../Redux/actons/token.action";

import SetSwapFee from "./SetSwapFee";
import UpdateSetting from "./UpdateSetting";

import SetWithdrawLimit from "./SetWithdrawLimit";
const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
const coinOptions = [
  { key: "1", text: "Coin Gecko", value: "coin_gicko" },
  { key: "2", text: "Pan Cake Swap", value: "pan_cake_swap" },
  { key: "3", text: "Coin Markt Cap", value: "cmc" },
  { key: "4", text: "Probit", value: "probit" },
];

const addedByOptions = [
  { key: "m", text: "All", value: "" },
  { key: "k", text: "Admin", value: "1" },
  { key: "l", text: "User", value: "0" },
];

export class TokenList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      search: "",
      perPage: "10",
      page: "1",
      order: "asc",
      selectedIndex: 0,
      sortBy: "",
      getDetailsId: {},
      modal: false,
      openConfirm: false,
      deleteId: "",
      deleteBalance: 0,
      openEnableDisableConfirm: false,
      action: 0,
      coin_id: "",
      status: "Disable",
      swapFeeModal: false,
      updateSettingModal: false,
      swap_coin_id: 0,
      coin_id: "",
      coins_setting: "",
      coin_name: "",
      swap_fee: 0,
      withdrawLimitModal: false,
      max_withdraw_limit: "",
      min_withdraw_limit: "",
      withdraw_coin_id: 0,
      coin_symbol: "",
      operateBy: "1",
    };
  }

  componentDidMount = () => {
    this.getTokenList();
  };

  getTokenList = () => {
    let data = {
      search: this.state.search,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      operateBy: this.state.operateBy,
    };
    this.props.getTokenList(data);
  };

  // //page change
  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getTokenList();
    });
  };

  filterBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getTokenList();
    });
  };

  filterAddedBy = (e, data) => {
    this.setState({ operateBy: data.value }, () => {
      this.getTokenList();
    });
  };

  filterCoinBy = (e, data) => {
    // this.props.tokenList[this.state.selectedIndex].price_source = data.value
    // this.forceUpdate();
    let tokenListParams = {
      search: this.state.search,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };

    let params = {
      coin_id: this.props.tokenList[this.state.selectedIndex].coin_id,
      price_source: data.value,
    };
    this.props.updatePriceSource(params, tokenListParams);
  };

  //search
  onSearchList = (abc) => {
    // this.setState({ search: abc, page: 1 }, () => {
    this.getTokenList();
    // });
  };
  openModel = (data) => {
    this.setState({ getDetailsId: data, open: true });
  };
  addToken = () => {
    this.props.history.push("add-token");
  };
  close = () => {
    this.setState({ openConfirm: false });
  };
  onDeleteToken = (id, balance, coin_symbol) => {
    this.setState({
      openConfirm: true,
      deleteId: id,
      deleteBalance: balance,
      coin_symbol: coin_symbol,
    });
  };
  onConfirmation = async () => {
    let data = {
      search: this.state.search,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    await this.props.deleteTokens(this.state.deleteId, data).then(async () => {
      await this.getTokenList();
    });
    this.close();
  };
  onConfirmEnableDisable = () => {
    let data = {
      action: this.state.action,
    };
    let params = {
      search: this.state.search,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      operateBy: this.state.operateBy,
    };
    this.closeEnableDisable();
    this.props.enableDisableToken(data, this.state.coin_id, params);
  };
  // enable diable //
  closeEnableDisable = () => {
    this.setState({
      openEnableDisableConfirm: false,
    });
  };
  openEnableDisable = (action, coin_id, balance, coin_symbol) => {
    if (action === 2) {
      this.setState({
        openEnableDisableConfirm: true,
        action,
        coin_id,
        status: "Disable",
        deleteBalance: balance,
        coin_symbol: coin_symbol,
      });
    } else {
      this.setState({
        openEnableDisableConfirm: true,
        action,
        coin_id,
        status: "Enable",
        deleteBalance: balance,
        coin_symbol: coin_symbol,
      });
    }
  };
  onEditToken = (id) => {
    // this.props.history.push(`edit-token/${id}`);
    this.props.history.push({
      pathname: `edit-token`,
      state: id,
    });
  };

  onMakePublic = (id) => {
    let data = {
      id: id,
    };
    let data2 = {
      search: this.state.search,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      operateBy: this.state.operateBy,
    };
    this.props.makeCoinPublic(data, data2);
  };

  onSwapOnOff = (id, type) => {
    // console.log('event >>>>>',type,id);
    let data = {
      id: id,
      status: type,
    };
    let data2 = {
      search: this.state.search,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      operateBy: "",
    };
    this.props.swapOnOff(data, data2);
  };

  onAutoTransactionOnOff = (id, type) => {
    // console.log('event >>>>>',type,id);
    let data = {
      coin_id: id,
      auto_withdraw_min_amt: "0",
      allow_withdraw: type,
    };
    let data2 = {
      search: this.state.search,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      operateBy: this.state.operateBy,
    };
    this.props.autoTransactionOnOff(data, data2);
  };

  redirectToUrl = (address, family) => {
    if (family == 2) {
      window.open(`${BTC_ADDRESS_BTC}/${address}`, "_blank");
    }
    if (family == 1) {
      window.open(`${ETH_ADDRESS_ETH}/${address}`, "_blank");
    }
    if (family == 3) {
      window.open(`${BSC_ADDRESS_BSC}/${address}`, "_blank");
    }
    if (family == 5) {
      window.open(`${MATIC_ADDRESS_MATIC}/${address}`, "_blank");
    }
  };

  openSwapFeeModal = (id, swap_fee, coin_name) => {
    this.setState({
      swapFeeModal: true,
      swap_coin_id: id,
      swap_fee: swap_fee,
      coin_name: coin_name,
    });
  };
  closeSwapFeeModal = () => {
    this.setState({ swapFeeModal: !this.state.swapFeeModal });
  };

  openSettingModal = (id, setting) => {
    this.setState({
      updateSettingModal: true,
      coin_id: id,
      coins_setting: setting,
    });
  };
  closeSettingModal = () => {
    this.setState({ updateSettingModal: !this.state.updateSettingModal });
  };

  openWithdrawLimitModal = (
    id,
    coin_name,
    min_withdraw_limit,
    max_withdraw_limit
  ) => {
    this.setState({
      withdrawLimitModal: true,
      withdraw_coin_id: id,
      min_withdraw_limit: min_withdraw_limit,
      max_withdraw_limit: max_withdraw_limit,
      coin_name: coin_name,
    });
  };
  closeWithdrawLimitModal = () => {
    this.setState({ withdrawLimitModal: !this.state.withdrawLimitModal });
  };

  render() {
    let { handleSubmit, tokenList } = this.props;
    console.log("tokenList >>", tokenList);
    return (
      <div>
        <Header headerTitle="token management" />
        <div>
          <Modal isOpen={this.state.openConfirm} toggle={this.close}>
            <ModalHeader toggle={this.close}>Delete Token</ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                This Token contain{" "}
                {this.state.deleteBalance === null
                  ? 0
                  : this.state.deleteBalance}{" "}
                {this.state.coin_symbol !== undefined
                  ? this.state.coin_symbol.toLocaleUpperCase()
                  : ""}{" "}
                amount.
              </h4>
              <h4> Are you sure want to delete?</h4>
            </ModalBody>
            <ModalFooter>
              <Button color="error" onClick={() => this.close()}>
                Cancel
              </Button>
              <Button color="success" onClick={() => this.onConfirmation()}>
                Confirm
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.openEnableDisableConfirm}
            toggle={this.closeEnableDisable}
          >
            <ModalHeader toggle={this.closeEnableDisable}>
              {this.state.status} Token
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                This Token contain {this.state.deleteBalance}{" "}
                {this.state.coin_symbol !== undefined
                  ? this.state.coin_symbol.toLocaleUpperCase()
                  : ""}{" "}
                amount.
              </h4>
              <h4> Are you sure want to {this.state.status} ?</h4>
            </ModalBody>
            <ModalFooter>
              <Button color="error" onClick={() => this.closeEnableDisable()}>
                Cancel
              </Button>
              <Button
                color="success"
                onClick={() => this.onConfirmEnableDisable()}
              >
                Confirm
              </Button>
            </ModalFooter>
          </Modal>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Token List</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { search: e.target.value, page: 1 },
                            () => {
                              if (this.state.search == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        className="mx-2"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.filterBy(e, data)}
                      />
                      <Dropdown
                        options={addedByOptions}
                        selection
                        value={this.state.operateBy}
                        onChange={(e, data) => this.filterAddedBy(e, data)}
                      />
                      <Button
                        color="default btnMamagePermission"
                        onClick={() => this.addToken()}
                      >
                        + Add Token
                      </Button>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        {/* <th scope="col">Coin Id</th> */}
                        <th scope="col">Coin Name</th>
                        <th scope="col">Coin Symbol</th>
                        <th scope="col">Coin Gicko Alias</th>
                        <th scope="col">Coin Image</th>
                        <th scope="col">Contract Address</th>
                        <th scope="col">Added By</th>
                        <th scope="col">Coin Status</th>
                        <th scope="col">Auto Withdraw</th>
                        <th scope="col">Update Setting</th>

                        <th scope="col">Actions</th>
                        {/* <th scope="col">Operated By</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {tokenList.map((data, index) => (
                        <tr className="table-tr" key={index}>
                          {/* <td>{data.coin_id}</td> */}
                          <td>{data.text}</td>
                          <td>{data.coin_symbol}</td>
                          <td>{data.coin_gicko_alias}</td>
                          <td>
                            <img
                              src={`${IMAGE_PATH}${data.coin_image}`}
                              alt="logo"
                              style={{ height: 40, width: 40 }}
                            />
                          </td>
                          <td
                            onClick={() =>
                              this.redirectToUrl(
                                data.token_address,
                                data.coin_family
                              )
                            }
                          >
                            {data.is_token ? data.token_address : "N/A"}
                          </td>
                          <td>{data?.operate_by == 1 ? "Admin" : "User"}</td>
                          <td>
                            {data.coin_status === 1 ? "Enabled" : "Disabled"}
                          </td>
                          <td>
                            {
                              // console.log(data?.coins_setting?.allow_withdraw,'data.coins_setting.allow_withdraw===')
                              // data?.coins_setting?.allow_withdraw ==
                              //       "manual" ?(
                              //         <Radio
                              // toggle
                              // label=""
                              // checked={
                              //   data.coins_setting
                              //     ? data.coins_setting.allow_withdraw ==
                              //       "automatic"
                              //       ? true
                              //       : false
                              //     : false
                              // }
                              // onChange={(e, toggleData) => {
                              //   // console.log(toggleData,"=================>data--")
                              //   if (toggleData.checked) {
                              //   // let toggleData=data.coins_setting
                              //   this.openSettingModal( data.coin_id,data.coins_setting
                              //     ? data.coins_setting.allow_withdraw ==
                              //       "automatic"
                              //       ? "manual"
                              //       : "automatic"
                              //     : "automatic")
                              // }else {
                              //   this.onAutoTransactionOnOff(
                              //       data.coin_id,
                              //       data.coins_setting
                              //         ? data.coins_setting.allow_withdraw ==
                              //           "automatic"
                              //           ? "manual"
                              //           : "automatic"
                              //         : "automatic"
                              //     );
                              // }
                              //   //
                              // }}
                              // />
                              //       ):(
                              //         <Radio
                              //         toggle
                              //         label=""
                              //         checked={
                              //           data.coins_setting
                              //             ? data.coins_setting.allow_withdraw ==
                              //               "automatic"
                              //               ? true
                              //               : false
                              //             : false
                              //         }
                              //         onChange={(e, toggleData) => {
                              //           // console.log(toggleData,"=================>data--")
                              //           this.onAutoTransactionOnOff(
                              //               data.coin_id,
                              //               data.coins_setting
                              //                 ? data.coins_setting.allow_withdraw ==
                              //                   "automatic"
                              //                   ? "manual"
                              //                   : "automatic"
                              //                 : "automatic"
                              //             );
                              //           //
                              //         }}
                              //         />
                              //       )
                            }
                            <span style={{ marginRight: "10px" }}>
                              <Radio
                                style={{ marginBottom: -15 }}
                                toggle
                                checked={
                                  data.coins_setting
                                    ? data.coins_setting.allow_withdraw ===
                                      "automatic"
                                      ? true
                                      : false
                                    : false
                                }
                                onChange={(e, toggleData) => {
                                  // console.log(toggleData,"=================>data--")
                                  if (toggleData.checked) {
                                    // let toggleData=data.coins_setting

                                    this.openSettingModal(
                                      data.coin_id,
                                      data.coins_setting
                                        ? data.coins_setting.allow_withdraw ==
                                          "automatic"
                                          ? "manual"
                                          : "automatic"
                                        : "automatic"
                                    );
                                  } else {
                                    this.onAutoTransactionOnOff(
                                      data.coin_id,
                                      data.coins_setting
                                        ? data.coins_setting.allow_withdraw ==
                                          "automatic"
                                          ? "manual"
                                          : "automatic"
                                        : "automatic"
                                    );
                                  }
                                  //
                                }}
                              />
                            </span>
                            <span style={{ marginRight: "10px" }}>
                              <Button
                                color="primary"
                                onClick={() => this.onEditToken(data.coin_id)}
                              >
                                Edit
                              </Button>
                            </span>
                            <span>
                              <Button
                                color="danger"
                                onClick={() =>
                                  this.onDeleteToken(
                                    data.coin_id,
                                    data.balance,
                                    data.coin_symbol
                                  )
                                }
                              >
                                Delete
                              </Button>
                            </span>
                            {data.coin_status === 1 ? (
                              <span style={{ marginLeft: "10px" }}>
                                <Button
                                  color="warning"
                                  onClick={() =>
                                    this.openEnableDisable(
                                      2,
                                      data.coin_id,
                                      data.balance,
                                      data.coin_symbol
                                    )
                                  }
                                >
                                  Disable
                                </Button>
                              </span>
                            ) : (
                              <span style={{ marginLeft: "10px" }}>
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.openEnableDisable(
                                      1,
                                      data.coin_id,
                                      data.balance,
                                      data.coin_symbol
                                    )
                                  }
                                >
                                  Enable
                                </Button>
                              </span>
                            )}
                            <span style={{ marginLeft: "10px" }}>
                              <Button
                                color="success"
                                onClick={() => this.onMakePublic(data.coin_id)}
                                style={{
                                  visibility:
                                    data.operate_by == null
                                      ? "visible"
                                      : "hidden",
                                }}
                              >
                                Make public
                              </Button>
                            </span>
                            {data.is_token == 1 && (
                              <span style={{ marginLeft: "10px" }}>
                                {data.swap == 1 ? (
                                  <Radio
                                    toggle
                                    label="Swap On/Off"
                                    onChange={(e) =>
                                      this.onSwapOnOff(data.coin_id, 0)
                                    }
                                    defaultChecked
                                  />
                                ) : (
                                  <Radio
                                    toggle
                                    label="Swap On/Off"
                                    onChange={(e) =>
                                      this.onSwapOnOff(data.coin_id, 1)
                                    }
                                  />
                                )}
                              </span>
                            )}
                            {data.is_token == 1 && (
                              <span style={{ marginLeft: "10px" }}>
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.openSwapFeeModal(
                                      data.coin_id,
                                      data.swap_fee,
                                      data.coin_name
                                    )
                                  }
                                  // style={{
                                  //   visibility:
                                  //     data.operate_by == 0
                                  //       ? "visible"
                                  //       : "hidden",
                                  // }}
                                >
                                  Set swap fee
                                </Button>
                              </span>
                            )}
                            <span style={{ marginLeft: "10px" }}>
                              <Dropdown
                                options={coinOptions}
                                selection
                                value={data.price_source}
                                onClick={() =>
                                  this.setState({ selectedIndex: index })
                                }
                                onChange={(e, data) =>
                                  this.filterCoinBy(e, data)
                                }
                              />

                              {/* <Button
                                color="success"
                                onClick={() => this.openWithdrawLimitModal(data.coin_id,data.coin_name,data.min_withdraw_limit,data.max_withdraw_limit)}
                                style={{
                                  visibility:
                                    data.operate_by == null
                                      ? "visible"
                                      : "hidden",
                                }}
                              >
                                Set withdraw min and max limit
                              </Button> */}
                            </span>
                          </td>
                        </tr>
                      ))}
                      {tokenList.length == 0 && <td>No record</td>}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      {this.props.totalRecords > 0 && (
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalRecords / this.state.perPage
                          )}
                        />
                      )}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        <div>
          <Modal isOpen={this.state.open} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>More..</ModalHeader>
            <ModalBody>
              <div className="userNameList">
                <div className="itemStyle">
                  <label
                    style={{ color: "black", fontWeight: "bold", fontSize: 14 }}
                  >
                    {" "}
                    Subject:<p>{this.state.getDetailsId.subject}</p>
                  </label>
                </div>
                <div className="itemStyle">
                  <label
                    style={{ color: "black", fontWeight: "bold", fontSize: 14 }}
                  >
                    Message:<p>{this.state.getDetailsId.message} </p>
                  </label>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <SetSwapFee
          search={this.state.search}
          order={this.state.order}
          perPage={this.state.perPage}
          page={this.state.page}
          swap_coin_id={this.state.swap_coin_id}
          swap_fee={this.state.swap_fee}
          coin_name={this.state.coin_name}
          swapFeeModal={this.state.swapFeeModal}
          closeSwapFeeModal={this.closeSwapFeeModal}
          setOperateBy={this.state.operateBy}
        />

        <UpdateSetting
          // search={this.state.search}
          // order={this.state.order}
          // perPage={this.state.perPage}
          // page={this.state.page}
          // swap_coin_id={this.state.swap_coin_id}
          // swap_fee={this.state.swap_fee}
          // coin_name={this.state.coin_name}
          search={this.state.search}
          order={this.state.order}
          perPage={this.state.perPage}
          page={this.state.page}
          coin_id={this.state.coin_id}
          coins_setting={this.state.coins_setting}
          setOperateBy={this.state.operateBy}
          updateSettingModal={this.state.updateSettingModal}
          closeSwapFeeModal={this.closeSettingModal}
        />

        <SetWithdrawLimit
          search={this.state.search}
          order={this.state.order}
          perPage={this.state.perPage}
          page={this.state.page}
          withdraw_coin_id={this.state.withdraw_coin_id}
          min_withdraw_limit={this.state.min_withdraw_limit}
          max_withdraw_limit={this.state.max_withdraw_limit}
          coin_name={this.state.coin_name}
          withdrawLimitModal={this.state.withdrawLimitModal}
          openWithdrawLimitModal={this.openWithdrawLimitModal}
          closeWithdrawLimitModal={this.closeWithdrawLimitModal}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    tokenList: state.token.tokenList,
    totalRecords: state.token.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTokenList: (data) => dispatch(getTokenList(data)),
    makeCoinPublic: (data, data2) => dispatch(makeCoinPublic(data, data2)),
    swapOnOff: (data, data2) => dispatch(swapOnOff(data, data2)),
    autoTransactionOnOff: (data, data2) =>
      dispatch(autoTransactionOnOff(data, data2)),
    deleteTokens: (id, param) => dispatch(deleteTokens(id, param)),
    enableDisableToken: (action, id, params) =>
      dispatch(enableDisableToken(action, id, params)),
    updatePriceSource: (params, tokenListParams) =>
      dispatch(updatePriceSource(params, tokenListParams)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenList);
