import { fetch } from "./Fetch";
import { API_HOST, API_LOCAL_HOST } from "../constants/index";

const changeTransactionStatus = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/fiat/changeStatus/${data.id}`,
    data,
    options
  );
};

//get fiat history list
const getFiatHistoryList = (data, options) => {
  const dataToSend = {
    order: data.order,
    page: data.page,
    perPage: data.perPage,
    searchKey: data.searchKey,
    status: data.status,
    type: data.type,
    start_date: data.start_date ? data.start_date : "",
    end_date: data.end_date ? data.end_date : "",
  };
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/fiat/${
      data.payment_source === "manual" ? "manual_history" : "history"
    }`,
    {},
    options,
    dataToSend
  );
};

const getBalance = (options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/fiat/vartulBalance`,
    {},
    options
  );
};

const getFiatStatus = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/fiat/statusWiseBalance`,
    {},
    options,
    params
  );
};

const getUserInr = (data, params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/fiat/userFiatHistory/${data.user_id}`,
    {},
    options,
    params
  );
};
const getDepositSettings = (options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/getBankDepositSetting`,
    {},
    options
  );
};
const changeDepositStatus = (data, options) => {
  return fetch(
    "put",
    `${API_HOST}/api/v1/admin/updateBankSetting`,
    data,
    options
  );
};
export const INRManagementServices = {
  getFiatHistoryList,
  getBalance,
  changeTransactionStatus,
  getFiatStatus,
  getUserInr,
  getDepositSettings,
  changeDepositStatus,
};
