import { OtcService } from "../../Services/OtcServices";
import { toast } from "../../components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";

export const actionTypes = {
  SAVE_OTC_DETAILS: "SAVE_OTC_DETAILS",
  TOTAL_OTC_RECORDS: "TOTAL_OTC_RECORDS",
  SAVE_ORDER_DETAILS: "SAVE_ORDER_DETAILS",
  TOTAL_ORDER_RECORDS: "TOTAL_ORDER_RECORDS",
  SAVE_TRADER_DETAILS: "SAVE_TRADER_DETAILS",
  TOTAL_TRADER_RECORDS: "TOTAL_TRADER_RECORDS",
  SAVE_TRADER_HISTORY: "SAVE_TRADER_HISTORY",
  // EDIT_USER_DAPPS: "EDIT_USER_DAPPS",
  SAVE_COIN_LIMIT_SETTING: "SAVE_COIN_LIMIT_SETTING",
  SAVE_OTC_LIMIT_COIN: "SAVE_OTC_LIMIT_COIN",
  SAVE_OTC_LIMIT_ALL_COIN: "SAVE_OTC_LIMIT_ALL_COIN",
};

export function saveOtcLimitByCoin(data) {
  return {
    type: actionTypes.SAVE_OTC_LIMIT_COIN,
    data,
  };
}

export function saveOtcLimitForAllCoin(data) {
  return {
    type: actionTypes.SAVE_OTC_LIMIT_ALL_COIN,
    data,
  };
}
export function saveCoinLimitSettings(data) {
  return {
    type: actionTypes.SAVE_COIN_LIMIT_SETTING,
    data,
  };
}

export function saveTraderHistory(data) {
  return {
    type: actionTypes.SAVE_TRADER_HISTORY,
    data,
  };
}

export function saveOtcDetails(data) {
  return {
    type: actionTypes.SAVE_OTC_DETAILS,
    data,
  };
}

export function totalOtcRecords(data) {
  return {
    type: actionTypes.TOTAL_OTC_RECORDS,
    data,
  };
}

export function saveOrderDetails(data) {
  return {
    type: actionTypes.SAVE_ORDER_DETAILS,
    data,
  };
}

export function totalOrderRecords(data) {
  return {
    type: actionTypes.TOTAL_ORDER_RECORDS,
    data,
  };
}

export function saveTraderDetails(data) {
  return {
    type: actionTypes.SAVE_TRADER_DETAILS,
    data,
  };
}

export function totalTraderRecords(data) {
  return {
    type: actionTypes.TOTAL_TRADER_RECORDS,
    data,
  };
}

// export function editDapp(status) {
//   return {
//     type: actionTypes.EDIT_USER_DAPPS,
//     data: status
//   };
// }

//get otc users
export function getOtcUserList(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();

    return OtcService.getOtcList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveOtcDetails(data.data.data));

        dispatch(totalOtcRecords(data.data.meta.total));

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//otc user limit
export function updateOtcLimit(data, fdata) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();

    return OtcService.getOtcLimit(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(getOtcUserList(fdata));
        toast.success(data["data"]["message"]);

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//otc order list

export function getOtcOrderList(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();

    return OtcService.getOrderList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveOrderDetails(data.data.data));

        dispatch(totalOrderRecords(data.data.meta.total));

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// cancel order
export function cancelOrder(id, fdata) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return OtcService.cancelOrder(id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(getOtcOrderList(fdata));
        toast.success(data["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//otc trader list

export function getOtcTraderList(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();

    return OtcService.getTraderList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveTraderDetails(data.data.data));

        dispatch(totalTraderRecords(data.data.meta.total));

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//trader history
export function getOtcTradeHistory(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();

    return OtcService.getTraderHistory(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function ordertradershistory(id) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();

    return OtcService.ordertradershistory(id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveTraderHistory(data.data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getCoinLimitSetting(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return OtcService.getCoinLimitSettings(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveCoinLimitSettings(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function setCoinLimitSetting(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return OtcService.setBulkCoinLimitSettings(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getOtcLimitByCoin(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return OtcService.getOtcLimitByCoin(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveOtcLimitByCoin(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getOtcLimitForAllCoin(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return OtcService.getOtcLimitByCoin(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveOtcLimitForAllCoin(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
