import { actionTypes } from "../actons/SubAdmin.action";
import { capitalizeFirst } from "../../utils/utils";

const initialState = {
  subAdminList: [],
  totalRecords: "",
  permissions: [],
  sideBarSubAdminPermissions: [],
  openSecurityModal: true,
  logDetailList: [],
  logModules: [],
};
const subAdmin = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUBADMIN_LIST:
      return {
        ...state,
        subAdminList: action.data.data,
        totalRecords: action.data.meta,
      };
    case actionTypes.GET_LOGDETAIL_LIST:
      let arrayLogDetail = [];
      {
        action.data.data.modules.map((logDetail, index) =>
          arrayLogDetail.push({
            key: index,
            value: logDetail.module,
            text: capitalizeFirst(logDetail.module),
          })
        );
      }

      return {
        ...state,
        logDetailList: action.data.data.list.rows,
        totalRecords: action.data.data.list.count,
        logModules: arrayLogDetail,
      };
    case actionTypes.SAVE_PERMISSIONS:
      return {
        ...state,
        permissions: action.data,
      };

    case actionTypes.SAVE_SUB_ADMIN_ASSINED_PERMISSIONS:
      return {
        ...state,
        sideBarSubAdminPermissions: action.data,
      };

    case actionTypes.OPEN_CLOSE_SECURITY:
      return {
        ...state,
        openSecurityModal: action.data,
      };
    default:
      return state;
  }
};

export default subAdmin;
