import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import { getCoinList } from "../../Redux/actons/revenue.action";
import { Pagination, Form, Dropdown } from "semantic-ui-react";
import Header from "components/Headers/Header";
import { withRouter, Link } from "react-router-dom";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
const UserFilter = [
  { key: "o", text: "All", value: "all" },
  { key: "c", text: "By Admin", value: "by_admin" },
  { key: "u", text: "By Users", value: "by_users" },
];

export class RevenueManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      sortBy: "",
      order: "asc",
      openModel: false,
      modalData: "",
      listType: "all",
    };
  }
  componentDidMount = () => {
    // alert('test');
    this.getCoinList();
  };

  getCoinList = () => {
    const param = {
      page: this.state.page,
      perPage: this.state.perPage,
      search: this.state.searchKey,
      listType: this.state.listType,
      order: this.state.order,
    };
    this.props.getCoinList(param);
  };

  onChangePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getCoinList();
    });
  };
  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ order: data.value }, () => {
      this.getCoinList();
    });
  };
  listByUser = (e, data) => {
    if (data.value !== "none") {
      this.setState({ listType: data.value }, () => {
        this.getCoinList();
      });
    } else {
      this.setState({ listType: "all" }, () => {
        this.getCoinList();
      });
    }
  };

  sortForBuy = (e, data) => {
    this.setState({ buyOrder: data.value }, () => {});
  };

  sortForSell = (e, data) => {
    this.setState({ sellOrder: data.value }, () => {});
  };
  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Revenue Management" />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search by coin name"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.getCoinList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.getCoinList()}
                      >
                        Search
                      </Button>
                      {/* <Link to="trade-setting">
                        <Button color="success btnMamagePermission">
                          Trade Settings
                        </Button>
                      </Link> */}

                      <Dropdown
                        className="ml-2"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.filterBy(e, data)}
                      />
                      {/* <Dropdown
                          className="selectUserStyle"
                          options={UserFilter}
                          placeholder="Filter"
                          selection
                          value={this.state.listType}
                          onChange={(e, data) => this.listByUser(e, data)}
                        />  */}

                      <Link to="fiat-revenue-list">
                        <Button color="success btnMamagePermission">
                          Fiat Revenue List
                        </Button>
                      </Link>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Coin Id</th>
                        <th scope="col">Coin Name</th>
                        <th scope="col">Coin Symbol</th>
                        <th scope="col">Deposits</th>
                        <th scope="col">Withdraws</th>
                        <th scope="col">Fee On Withdraws</th>
                        <th scope="col">Fee On Deposits</th>
                        <th scope="col">Earning on withdraws</th>
                        {/* <th scope="col">Buy rate(%)</th>
                        <th scope="col">Sell rate(%)</th> */}
                        {/* <th scope=" col">Currency Type</th> */}
                        {/* <th scope="col">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.coinData?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.coinData?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.coin_id}</td>
                            <td>{data.coin_name?.toUpperCase()}</td>
                            <td>{data.coin_symbol.toUpperCase()}</td>
                            <td>
                              {data.total_deposits == null
                                ? "N/A"
                                : data.total_deposits}
                            </td>
                            <td>
                              {data.total_withdraws == null
                                ? "N/A"
                                : data.total_withdraws}
                            </td>
                            <td>
                              {data.fee_on_withdraws == null
                                ? "N/A"
                                : data.fee_on_withdraws}
                            </td>
                            <td>
                              {data.fee_on_deposits == null
                                ? "N/A"
                                : data.fee_on_deposits}
                            </td>
                            <td>
                              {data.total_earning == null
                                ? "N/A"
                                : data.total_earning}
                            </td>
                            {/* <td>
                              {data.buy_rate_type !== null
                                ? data.buy_rate_type == "downgrade"
                                  ? "-" + data.buy_rate
                                  : "+" + data.buy_rate
                                : data.buy_rate}
                            </td>
                            <td>
                              {data.sell_rate_type !== null
                                ? data.sell_rate_type == "downgrade"
                                  ? "-" + data.sell_rate
                                  : "+" + data.sell_rate
                                : data.sell_rate}
                            </td> */}
                            {/* <td>{data.currency_type.toUpperCase()}</td> */}
                            {/* <td>
                              <span className="mb-0 text-sm">
                                {/* <Button
                                  color={
                                    data.status === "active"
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={() =>
                                    this.enableDisableCurrency(
                                      data.status === "active"
                                        ? "inactive"
                                        : "active",
                                      data.currency_id,
                                      data.currency_type
                                    )
                                  }
                                >
                                  {data.status === "active"
                                    ? "Inactive"
                                    : "Active"}
                                </Button> */}

                            {/* <Button
                                  color="success"
                                  onClick={() => this.openModel(data)}
                                >
                                  Edit Buy/Sell Rate
                                </Button> 
                              </span>
                            </td> */}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.totalRecords != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.onChangePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalRecords / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        <Modal isOpen={this.state.openModel} toggle={this.toggle}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Buy rate</label>

                  <input
                    name="buy_rate"
                    placeholder="Buy Rate"
                    value={this.state.buyRate}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ buyRate: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Buy rate type</label>
                  <Dropdown
                    className="selectUserStyle"
                    placeholder="Buy rate type"
                    options={options}
                    selection
                    value={this.state.buyOrder}
                    onChange={(e, data) => this.sortForBuy(e, data)}
                  />{" "}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Sell rate</label>

                  <input
                    name="buy_rate"
                    placeholder="Sell Rate"
                    value={this.state.sellRate}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ sellRate: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Sell rate type</label>
                  <Dropdown
                    className="selectUserStyle"
                    placeholder="Sell rate type"
                    options={options}
                    selection
                    value={this.state.sellOrder}
                    onChange={(e, data) => this.sortForSell(e, data)}
                  />{" "}
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.updateRateClick()}>
              Update
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    coinData: state.revenue.coinData,
    totalRecords: state.revenue.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCoinList: (data) => dispatch(getCoinList(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RevenueManagement)
);
