import {
  clusterApiUrl,
  Connection,
  PublicKey,
  RpcResponseAndContext,
  SignatureResult,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import PhantomWallet from "components/WalletConnection/PhantomWallet";
import { NETWORK_TYPE_SOLANA } from "../../../../constants/index";
import React, { useMemo, useEffect, useRef, useState } from "react";
// import { PhantomProvider } from "./ConnectWallet";

// interface ITransferSolProps {
//     provider: PhantomProvider;
// }

const network = "devnet";

const defaultDest = "B9u6ujLc4onUfkjjUt9VZPt3zbJaC7H7GmeiJA2a9WAt"; // to address

const SolTransfer = (props) => {
  // Create a connection to blockchain and
  // make it persistent across renders
  const connection = useRef(
    new Connection(clusterApiUrl(NETWORK_TYPE_SOLANA.toLocaleLowerCase()))
  );
  // const connection = useMemo(
  //   () => clusterApiUrl(NETWORK_TYPE_SOLANA),
  //   [NETWORK_TYPE_SOLANA]
  // );

  // console.log(
  //   connection,
  //   "NETWORK_TYPE_SOLANA",
  //   NETWORK_TYPE_SOLANA.toLocaleLowerCase()
  // );
  const [destAddr, setDestAddr] = useState(defaultDest);
  const [lamports, setLamports] = useState(10000);
  const [txid, setTxid] = useState(null > null);
  const [slot, setSlot] = useState(null > null);
  const [myBalance, setMyBalance] = useState(0);
  const [rxBalance, setRxBalance] = useState(0);
  const [pubkey, setpubkey] = useState();
  /* ******************************************************************** */

  // Get the balance the first time the component is mounted  /* ********** IMPORTANT ********** */
  useEffect(() => {
    // connection.current.getBalance(pubkey).then(setMyBalance);
    connection.current.getBalance(pubkey).then((bal) => {
      console.log(bal, "bal --- pubkey --- ");
      setMyBalance(bal);
    });
  }, [pubkey]);
  // useEffect(() => {
  //   console.log(connection, "pubkey --- useEffect", pubkey);
  //   connection.current.getBalance(pubkey).then((bal) => {
  //     console.log(bal, "bal --- pubkey --- ");
  //     // setBalance(bal);
  //   });
  // }, [pubkey]);

  useEffect(() => {
    connection.current.getBalance(new PublicKey(destAddr)).then(setRxBalance);
  }, [destAddr]);

  const handleChangeAddr = (event) => {
    setDestAddr(event.target.value);
  };

  const handleChangeLamp = (event) => {
    setLamports(parseInt(event.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("  testings ---- 65", event);
    // Create a TX object /* ********** IMPORTANT ********** */
    let transaction = new Transaction({
      feePayer: pubkey,
      recentBlockhash: (await connection.current.getRecentBlockhash())
        .blockhash,
    });

    console.log("  testings ---- 73", event);
    // Add instructions to the tx /* ********** IMPORTANT ********** */
    transaction.add(
      SystemProgram.transfer({
        fromPubkey: pubkey,
        toPubkey: new PublicKey(destAddr),
        lamports: lamports, // 9 zeroes
      })
    );

    console.log(connection.current, "  testings ---- 83", transaction);
    // Get the TX signed by the wallet (signature stored in-situ)  /* ********** IMPORTANT ********** */
    await window.solana.signTransaction(transaction);
    // Send the TX to the network  /* ********** IMPORTANT ********** */
    connection.current
      .sendRawTransaction(transaction.serialize())
      .then((id) => {
        console.log(`92 Transaction ID: ${id}`);
        setTxid(id);
        connection.current.confirmTransaction(id).then((confirmation) => {
          console.log(
            ` testings ---- 94 Confirmation slot: ${confirmation.context.slot}`
          );
          setSlot(confirmation.context.slot);
          connection.current.getBalance(pubkey).then(setMyBalance);
          connection.current
            .getBalance(new PublicKey(destAddr))
            .then(setRxBalance);
          console.log("  testings ---- 102");
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  /* ******************************************************************** */

  return (
    <div>
      <PhantomWallet setpubkey={setpubkey} />
      {/* <form onSubmit={handleSubmit}>
        <label>Enter address of destination</label>
        <br />
        <input type="text" value={destAddr} onChange={handleChangeAddr} />
        <br />
        <label>Amount of lamports</label>
        <br />
        <input type="number" value={lamports} onChange={handleChangeLamp} />
        <br />
        <input type="submit" value="Send lamports" />
        <hr />
        <p>My Balance: {myBalance} lamports</p>
        <p>Recipient Balance: {rxBalance} lamports</p>
        <hr />
        {txid ? (
          <p>
            Transaction id: <span style={{ fontSize: "0.7em" }}>{txid}</span>
          </p>
        ) : null}
        {slot ? <p>Confirmation slot: {slot}</p> : null}
      </form> */}
    </div>
  );
};

export default SolTransfer;
