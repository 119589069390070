import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const getSwapList = (params, options) => {
  console.log("PARAMS", params);
  console.log("************>>>>>", options);
  return fetch("get", `${API_HOST}api/v1/admin/swap/list`, {}, options, params);
};

//swap_graph
const getSwapGraph = (params, options) => {
  console.log("************>>>>>", options);
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/swap/swapGraphs`,
    {},
    options,
    params
  );
};
export const SwapServices = {
  getSwapList,
  getSwapGraph
};
