import { PairCoinServices } from "../../Services/PairCoinServices";
import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../components/Toast/Toast";

export const actionTypes = {
  PAIR_COIN_LIST: "PAIR_COIN_LIST",
  PAIR_COIN: "PAIR_COIN",
  SWAP_HISTORY: "SWAP_HISTORY",
  SAVE_ACTIVE_TRADE_CURRENCIES: "SAVE_ACTIVE_TRADE_CURRENCIES",
};

export function savePairCoinList(data) {
  return {
    type: actionTypes.PAIR_COIN_LIST,
    data: data,
  };
}

export function savePairCoin(data) {
  return {
    type: actionTypes.PAIR_COIN,
    data: data,
  };
}

export function saveSwapHistory(data) {
  return {
    type: actionTypes.SWAP_HISTORY,
    data: data,
  };
}
export function saveActiveTradeCurrencies(data) {
  return {
    type: actionTypes.SAVE_ACTIVE_TRADE_CURRENCIES,
    data: data,
  };
}
/*
Api call for adding the coin pair
@method : post
@params =>
{
	 "coin_1" : "4",
	 "coin_2": "10",
	 "markup_rate": "0",
	 "markdown_rate": "0",
	 "fee": "0",
	 "fee_coin": 0
}
*/
export function pairCoinRequest(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return PairCoinServices.pairCoinRequest(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

/*
Api call for getting the coin pair list
@method : get
*/
export function getPairList(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return new Promise((resolve, reject) => {
      return PairCoinServices.getPairList(params, {
        jwt: state["persist"]["c_temp_user"]["token"],
      })
        .then(async (res) => {
          console.log('--------------getPairList', res)
          dispatch(savePairCoinList(res.data));
          dispatch(stopLoading());
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error) {
            toast.error(error["data"]["message"]);
          }
          dispatch(stopLoading());
          reject();
        });
    });
  };
}

/*
Api call for getting the coin pair
@method : get
*/
export function getPair(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return PairCoinServices.getPairCoinFromId(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(savePairCoin(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getSwapHistoryList(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return PairCoinServices.getSwapHistoryList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveSwapHistory(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

/*
Api call for updating the coin pair
@method : post
*/
export function updatePair(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return PairCoinServices.updatePairCoin(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        console.log("UPDATE_PAIR ---->", data);
        toast.success(data.data.data);
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

/*
Api call for enable/disable coin pair
@method : post
@params : 
{
    "status":"active / inactive"
}
*/
export function enableDisabelPair(data, id, param) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return PairCoinServices.enableDisablePair(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getPairList(param));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function addMinMaxAmount(data, data2) {
  console.log(data);
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return PairCoinServices.minMax(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        console.log("data2data2data2>>", data2);
        dispatch(getPairList(data2));
        toast.success(data["data"]["message"]);
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getAllActiveTradeCurrencies() {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return PairCoinServices.getAllActiveTradeCurrencies({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveActiveTradeCurrencies(res.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
