import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { openCloseModal } from "../../Redux/actons/SubAdmin.action";
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";
import { Pagination, Dropdown, Form } from "semantic-ui-react";
import { listDateFormat } from "../../utils/utils";
import AddComment from "./AddComment";
import { reset, Field, reduxForm, getFormValues } from "redux-form";
import { getCoinList } from "../../Redux/actons/user.action";
import {
  userInstalmentHistory,
  terminatePlan,
} from "../../Redux/actons/installment.action";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

const Filteroptions = [{ key: "e", text: "None", value: "none" }];
const status = [
  { key: "n", text: "None", value: "" },
  { key: "m", text: "Paid", value: "paid" },
  { key: "k", text: "Pending", value: "pending" },
  // { key: "k", text: "Not Acquired", value: "not acquired" },
  { key: "k", text: "Terminated", value: "terminated" },
  { key: "l", text: "Processing", value: "processing" },
];

export class UserInstalmentHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      sortBy: "",
      coin_family: "",
      order: "asc",
      selectedCoin: "",
      status: "",
      openAmountModal: false,
      userData: "",
      isTerminate: false,
      isClaim: false,
      penalty: "",
      comment: "",
      plan_id: "",
      isCommentOpen: false,
      id: 0,
      action: 1,
    };
  }
  componentDidMount = async () => {
    if (this.props.location.state) {
      await this.getPairList(this.props.location.state);
      this.setState({ plan_id: this.props.location.state });
    } else {
      this.props.history.goBack();
    }
    // this.getPairList();
    this.props.getCoinList().then(() => {
      this.props.coinsList.forEach((element) => {
        Filteroptions.push(element);
      });
    });
  };
  componentWillUnmount = () => {
    this.props.openCloseModal();
  };

  acceptRejectClaim = async (values = {}) => {
    console.log(
      "comment >> >>",
      values,
      this.state.action,
      this.state.id,
      this.props.formData
    );
    let data = {
      action: this.state.action,
      comment: values.message,
    };
    // this.props.changeKycStatus(this.state.id, data, this.props.formData);
    // this.props.toggle2();
    this.openCloseCommentModal();
  };
  getPairList = (planId) => {
    const data = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      order: this.state.order,
      coin_name: this.state.selectedCoin,
      status: this.state.status,
      plan_id: planId,
    };
    this.props.userInstalmentHistory(data);
  };
  verifyKyc = (action) => {
    let id = this.props.userDetail?.user_id;
    if (action == 2) {
      this.addComment(id, action);
    } else {
      let data = {
        action: action,
      };

      this.props.changeKycStatus(id, data, this.props.formData);
      // this.props.toggle2();
    }
  };

  addComment = (id, action) => {
    this.setState({
      action: action,
      id: id,
      isCommentOpen: true,
    });
  };

  openCloseCommentModal = () => {
    this.setState({
      isCommentOpen: !this.state.isCommentOpen,
    });
  };
  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getPairList(this.state.plan_id);
    });
  };

  openAmountModal = (data, isTerminate, isClaim) => {
    setTimeout(() => {
      this.setState({
        openAmountModal: !this.state.openAmountModal,
        userData: data,
        isTerminate: isTerminate,
        isClaim: isClaim,
      });
    }, 100);
  };

  toggle = () =>
    this.setState({ openAmountModal: !this.state.openAmountModal });

  onSearchList = (search) => {
    // this.setState({ searchKey: search }, () => {
    this.getPairList(this.state.plan_id);
    // });
  };

  orderBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getPairList(this.state.plan_id);
    });
  };

  filterByCoin = (e, data) => {
    this.setState({ selectedCoin: data.value }, () => {
      this.getPairList(this.state.plan_id);
    });
  };

  filterByStatus = (e, data) => {
    this.setState({ status: data.value }, () => {
      this.getPairList(this.state.plan_id);
    });
  };

  terminatePlan = (id) => {
    if (this.state.penalty == "") {
      alert("Penalty amount is required");
    } else if (this.state.comment == "") {
      alert("Comment is required");
    } else if (
      this.state.userData.paid_amount +
        (this.state.userData.processing_fee / 100) *
          this.state.userData.paid_amount -
        this.state.penalty <
      0
    ) {
      alert(
        "Amount cannot exceed" +
          " " +
          (this.state.userData.paid_amount +
            (this.state.userData.processing_fee / 100) *
              this.state.userData.paid_amount -
            this.state.penalty)
      );
    } else {
      const params = {
        message: this.state.comment,
        penalty: this.state.penalty,
      };

      const data = {
        page: this.state.page,
        perPage: this.state.perPage,
        searchKey: this.state.searchKey,
        order: this.state.order,
        coin_id: this.state.selectedCoin,
        status: this.state.status,
      };

      this.props.terminatePlan(params, id, data);
      this.toggle();
    }
  };

  render() {
    let { handleSubmit } = this.props;
    console.log("instalmentList--------------->", this.props);
    return (
      <div>
        <Header headerTitle="User Taral Gold History" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="taral-gold-management">Taral Gold Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>User Taral Gold History</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.orderBy(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={Filteroptions}
                        placeholder="Filter by Coin"
                        selection
                        onChange={(e, data) => this.filterByCoin(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={status}
                        placeholder="Filter by status"
                        selection
                        onChange={(e, data) => this.filterByStatus(e, data)}
                      />
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        {/* <th scope="col">S.No.</th> */}
                        <th scope="col">User email</th>
                        <th scope="col">Coin</th>
                        <th scope="col">Amount paid</th>
                        <th scope="col">Amount Remaining</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.instalmentList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.instalmentList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data?.email}</td>
                            <td>{data?.coin_name}</td>
                            <td>{data?.paid_amount}</td>
                            <td>{data?.due_amount}</td>
                            <td> {data?.total_amount}</td>
                            <td>{data?.status.toUpperCase()}</td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.openAmountModal(data, false, false)
                                  }
                                >
                                  View
                                </Button>
                                {(data.status === "processing" ||
                                  data.status === "not acquired") && (
                                  <Button
                                    color="danger"
                                    onClick={() =>
                                      this.openAmountModal(data, true, false)
                                    }
                                  >
                                    Terminate
                                  </Button>
                                )}
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total > 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(this.props.total)}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.openAmountModal}
          toggle={this.toggle}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.toggle}>
            <h1>Installment Details</h1>
          </ModalHeader>
          <ModalBody>
            <div className="userNameList">
              <div className="itemStyle">
                <label>User name:</label>
                <label>{this.state.userData.email}</label>
              </div>
              <div className="itemStyle">
                <label>User email:</label>
                <label>{this.state.userData.email}</label>
              </div>
              <div className="itemStyle">
                <label>User currency:</label>
                <label>{this.state.userData.coin_name}</label>
              </div>
              <div className="itemStyle">
                <label>Quantity:</label>
                <label>{this.state.userData.qty}</label>
              </div>
              <div className="itemStyle">
                <label>Estimated amount:</label>
                <label>{this.state.userData.monthly_emi}</label>
              </div>
              <div className="itemStyle">
                <label>Enroll Date:</label>
                <label>{listDateFormat(this.state.userData.created_at)}</label>
              </div>
              <div className="itemStyle">
                <label>Plan Tenure:</label>
                <label>
                  {" "}
                  {this.state.userData.tenure +
                    " " +
                    this.state.userData.tenure_type}{" "}
                </label>
              </div>
              <div className="itemStyle">
                <label>Maturity Date:</label>
                <label>{listDateFormat(this.state.userData.due_date)}</label>
              </div>
              <div className="itemStyle">
                <label>Total amount paid:</label>
                <label>{this.state.userData.paid_amount}</label>
              </div>
              <div className="itemStyle">
                <label>Total payment due:</label>
                <label>{this.state.userData.due_amount}</label>
              </div>
              {this.state.isTerminate && (
                <div>
                  <div className="itemStyle">
                    <label>Penalty to be charged(₹):</label>
                    <input
                      type="number"
                      name="downpayment"
                      min="0"
                      required
                      onChange={(e, data) =>
                        this.setState({ penalty: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className="itemStyle">
                    <label>Add comment:</label>
                    <textarea
                      style={{ height: 200 }}
                      onChange={(e, data) =>
                        this.setState({ comment: e.target.value })
                      }
                    ></textarea>
                  </div>
                  <div className="itemStyle">
                    <label></label>
                    <Button
                      color={"danger"}
                      onClick={() =>
                        this.terminatePlan(this.state.userData.installment_id)
                      }
                    >
                      Terminate
                    </Button>
                  </div>
                </div>
              )}
              {this.state.isClaim && (
                <div>
                  <div className="itemStyle">
                    <label></label>
                    <Button
                      color={"danger"}
                      onClick={() => {
                        // this.terminatePlan(this.state.userData.installment_id)
                        this.verifyKyc(2);
                      }}
                    >
                      Reject
                    </Button>
                    <Button
                      color="success"
                      onClick={() => {
                        // this.terminatePlan(this.state.userData.installment_id)
                      }}
                    >
                      Approve
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>

        <AddComment
          isCommentOpen={this.state.isCommentOpen}
          openCloseCommentModal={this.openCloseCommentModal}
          acceptRejectClaim={this.acceptRejectClaim}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    instalmentList: state.installment.userInstalment,
    instalmentList1: state.installment.userInstalmentMeta,
    total: state.installment.userInstalmentMeta.pages,
    coinsList: state.user.coinsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (data) => dispatch(reset(data)),
    openCloseModal: () => dispatch(openCloseModal(true)),
    userInstalmentHistory: (data) => dispatch(userInstalmentHistory(data)),
    terminatePlan: (data, id, params) =>
      dispatch(terminatePlan(data, id, params)),
    getCoinList: () => dispatch(getCoinList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "InstallmentsManagementForm" })(UserInstalmentHistory));
