import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import SidebarSubAdmin from "../components/Sidebar/SidebarSubAdmin.js";
import LoaderComponent from "components/LoaderComponent/LoaderComponent.js";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import routes from "../routes";
import Error404 from "../Error404";
import routesSubAdmin from "../routesSubAdmin";
import UserManagement from "../views/UserManagement/User/UserManagement";
import KycManagement from "../views/KycManagement/KycManagement";
import OtcListManagement from "../views/OtcManagement/OtcListManagement";
import FundManagement from "../views/FundsManagement/FundManagement";
import ReportsManagement from "../views/ReportsManagement/ReportsManagement";
import AnnouncementList from "../views/Announcement/AnnouncementList";
import WithdrawContainer from "../views/Withdraw/WithdrawContainer/WithdrawContainer";
import { getSubAdminPermissions } from "../Redux/actons/SubAdmin.action";
// import TransactionFeeManagement from "../views/TransactionFeeManagement/TransactionFeeManagement";
import TransactionFeeManagement from "../views/TransactionFeeManagement/TabDevideHandle";
import SwapManagement from "../views/SwapManagement/SwapManagement";
import SubAdminManagement from "../views/SubAdminManagement/SubAdminManagement";
import LogoManagement from "../views/LogoManagement/LogoManagement";
import PriceManagement from "../views/PriceManagement/PriceManagement";
import HotwalletFunds from "../views/Withdraw/HotWalletFunds/HotWalletFunds";
import DepositManagement from "../views/DepositManagement/DepositManagement";
import RevenueManagement from "../views/RevenueManagement/RevenueManagement";
// import INRManagement from "views/INRManagement/INRManagement.js";
import INRManagement from "../views/INRManagement/TabDevideHandle";
// import RewardManagement from "views/RewardManagement/RewardManagement";
import RewardManagement from "../views/RewardManagement/TabDevideHandle";
import BannerManagement from "views/BannerManagement/BannerManagement";
import ContentManagement from "views/ContentManagement/ContentManagement";
import PairCoinManagement from "views/PairCoinManagement/PairCoinManagement";
import BankAccountManagement from "views/BankAccountManagement/BankAccountManagement";
import FiatManagement from "views/FiatManagement/FiatManagement";
import ReferralManagement from "views/ReferralManagement/ReferralManagement";
import TradeManagement from "views/TradeManagement/TradeManagement";
import InstallmentsManagement from "views/InstallmentsManagement/InstallmentsManagement";
import TaralGoldManagement from "views/TaralGoldManagement/TaralGoldManagement";
import TaralGoldRequests from "views/TaralGoldManagement/TerminationRequest";
import UserTaralGoldHistory from "views/TaralGoldManagement/UserInstalmentHistory";
import TokenList from "views/TokenManagement/TokenList";
import InterestManagement from "views/InterestManagement/InterestManagement";
import AccountSetting from "views/AccountSetting/AccountSetting.js";
import AddFundsFile from "views/FundsManagement/AddFundsFile/AddFundsFile.js";
import SubjectManagement from "views/ContactQueryManagement/SubjectManagement/SubjectManagement.js";
import ContactQueryManagement from "views/ContactQueryManagement/ContactQueryManagement.js";
import UserGuideListManagement from "views/UserGuide/UserGuideList.js";
import UpdateQuery from "views/ContactQueryManagement/UpdateQuery";
import InfluencerManagement from "views/ReferralManagement/InfluencerManagement/InfluencerManagement";
import AddUserGuide from "views/UserGuide/AddUserGuide/AddUserGuide";
import InterestUserList1 from "views/InterestManagement/InterestUserList/InterestUserList";
import ViewFundHistory from "views/FundsManagement/ViewUserFunds/ViewUserFunds";
import ReplyOnTaralGoldQuery from "../views/ContactQueryManagement/ReplyOnTaralGoldQuery";
import ViewUserInterestHistory from "../views/InterestManagement/ViewUserInterestHistory/ViewUserInterestHistory";
import MLMReferralManagement from "../views/ReferralManagement/MLMReferralManagement";
import ScratchedHistory from "../views/RewardManagement/ScratchedHistory";
class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subCount: 0,
      subAdminRoute: [
        {
          path: "/user-management",
          name: "User Management",
          icon: "ni ni-circle-08",
          component: UserManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },

        {
          path: "/kyc-management",
          name: "Kyc Management",
          icon: "ni ni-badge",
          component: KycManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/inrmanagement",
          name: "INR Management",
          icon: "ni ni-badge",
          component: INRManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/contact-queries",
          name: "Contact Queries",
          icon: "ni ni-single-copy-04",
          component: ContactQueryManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/update-query",
          name: "Contact Queries",
          icon: "ni ni-single-copy-04",
          component: UpdateQuery,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },

        {
          path: "/add-subject",
          name: "Add Subject",
          component: SubjectManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },

        {
          path: "/user-guide",
          name: "User-guide Management",
          icon: "ni ni-badge",
          component: UserGuideListManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        // {
        //   path: "/withdrawal",
        //   name: "Withdrawal Management",
        //   icon: "ni ni-badge",
        //   component: WithdrawContainer,
        //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        // },
        {
          path: "/otc-management",
          name: "OTC Management",
          icon: "ni ni-badge",
          component: OtcListManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/fund-management",
          name: "Lock fund Management",
          icon: "ni ni-badge",
          component: FundManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/add-funds-file",
          name: "Add Funds File",
          icon: "ni ni-badge",
          component: AddFundsFile,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/add-user-guide",
          name: "Add User Guide",
          icon: "",
          component: AddUserGuide,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/hot-wallet-funds",
          name: "Hot Wallet Management",
          icon: "ni ni-badge",
          component: HotwalletFunds,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/deposit",
          name: "Deposit Management",
          icon: "ni ni-badge",
          component: DepositManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/reports",
          name: "Report Management",
          icon: "ni ni-badge",
          component: ReportsManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/announcement-list",
          name: "Announcement Management",
          icon: "ni ni-badge",
          component: AnnouncementList,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },

        {
          path: "/transaction-fee-management",
          name: "Transaction Management",
          icon: "ni ni-badge",
          component: TransactionFeeManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/trade-management",
          name: "Trade Management",
          icon: "ni ni-collection",
          component: TradeManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/swap-management",
          name: "Swap Management",
          icon: "ni ni-badge",
          component: SwapManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/sub-admin-management",
          name: "Subadmin Management",
          icon: "ni ni-badge",
          component: SubAdminManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/logo-management",
          name: "Logo Management",
          icon: "ni ni-badge",
          component: LogoManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/price-management",
          name: "Price Management (IDA/IDP)",
          icon: "ni ni-badge",
          component: PriceManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/revenue-management",
          name: "Revenue Management",
          icon: "ni ni-badge",
          component: RevenueManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/rewardManagement",
          name: "Reward Management",
          icon: "ni ni-badge",
          component: RewardManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/scratch-history",
          name: "Reward Management",
          icon: "ni ni-badge",
          component: ScratchedHistory,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/banner-management",
          name: "Banner Management",
          icon: "ni ni-image",
          component: BannerManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/content-management",
          name: "Content Management",
          icon: "ni ni-single-copy-04",
          component: ContentManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          name: "Pair Coin Management",
          path: "/pair-coin",
          icon: "ni ni-send",
          component: PairCoinManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          name: "INR Management",
          path: "/bank-account-management",
          icon: "ni ni-badge",
          component: ViewUserInterestHistory,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/view-user-interest-history",
          name: "Interest Management",
          icon: "ni ni-badge",
          component: ViewUserInterestHistory,
          // component: BankAccountManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/taralgold-reply-broadcasting",
          name: "Contact Queries",
          // name: "Lock fund Management",
          icon: "ni ni-lock-circle-open",
          component: ReplyOnTaralGoldQuery,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          name: "Lock fund Management",
          path: "/fiat-management",
          icon: "ni ni-badge",
          component: FiatManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/view-fund-management",
          name: "View Fund Management",
          icon: "ni ni-badge",
          component: ViewFundHistory,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/referral-management",
          name: "Referral Management",
          icon: "ni ni-diamond",
          component: ReferralManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/referral-management-mlm",
          name: "Referral Management",
          icon: "ni ni-diamond",
          component: MLMReferralManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/installment-management",
          name: "Installment Management",
          icon: "ni ni-credit-card",
          component: InstallmentsManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/taral-gold-management",
          name: "Taral Gold Management",
          icon: "ni ni-credit-card",
          component: TaralGoldManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/user-taral-gold-history",
          name: "Taral Gold Management",
          icon: "ni ni-credit-card",
          component: UserTaralGoldHistory,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/user-taral-gold-requests",
          name: "Taral Gold Management",
          icon: "",
          component: TaralGoldRequests,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/token-management",
          name: "Token Management",
          icon: "ni ni-badge",
          component: TokenList,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/interest-management",
          name: "Interest Management",
          icon: "ni ni-badge",
          component: InterestManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/interest-user-list",
          name: "Interest Management",
          icon: "ni ni-badge",
          component: InterestUserList1,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/account-setting",
          name: "Account Settings",
          icon: "ni ni-badge",
          component: AccountSetting,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
        {
          path: "/influencer-management",
          name: "Referral Management",
          icon: "ni ni-diamond",
          component: InfluencerManagement,
          layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
        },
      ],
    };
  }
  componentDidMount = () => {
    this.setState({ subCount: this.state.subCount + 1 });

    if (this.props.user.role == 2) {
      this.props.getSubAdminPermissions();
    }
  };
  componentWillUnmount = () => {
    // routesSubAdmin.push([]);
  };

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes, routesSubAdmin) => {
    // await this.setState({ subCount: this.state.subCount + 1 });
    if (this.props.user.role == 1) {
      return routes.map((prop, key) => {
        // for main admin
        if (prop.layout === "/vainkjranltifshdfhgdsfhdjjhdgf") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return null;
        }
      });
    }
    //sub admin
    if (this.props.user.role == 2) {
      //  Important Note : Please always increment or decrement length <= acc to routesSubAdmin length
      // let roleData = JSON.parse(sessionStorage.getItem('roleData'));
      // console.log('roleData >>',roleData)

      if (this.state.subCount <= 1 && routesSubAdmin.length <= 49) {
        for (var i = 0; i < this.state.subAdminRoute.length; i++) {
          for (var k = 0; k < this.props.permissions.length; k++) {
            if (
              this.state.subAdminRoute[i].name ==
              this.props.permissions[k].title
            ) {
              // alert(this.props.permissions[k].title);
              // console.log('this.props.permissions[k].title >>>',this.props.permissions[k].title );
              routesSubAdmin.push(this.state.subAdminRoute[i]);
            }
          }
        }
      }
      // console.log("routesSubAdmin22 >>>", routesSubAdmin.length);

      return routesSubAdmin.map((prop, key) => {
        // for main admin
        if (prop.layout === "/vainkjranltifshdfhgdsfhdjjhdgf") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return null; // <Route component={Error404} />;
        }
      });
    }
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "";
  };
  render() {
    // console.log("ffffffffff ", this.props.user.role);
    return (
      <>
        {this.props.user.role == 1 && (
          <Sidebar {...this.props} routes={routes} />
        )}
        {this.props.user.role == 2 && (
          <SidebarSubAdmin {...this.props} routes={routesSubAdmin} />
        )}
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>{this.getRoutes(routes, routesSubAdmin)}</Switch>
          <LoaderComponent></LoaderComponent>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  // console.log("------------------------------------", state);
  return {
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubAdminPermissions: () => dispatch(getSubAdminPermissions()),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
