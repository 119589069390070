import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
} from "reactstrap";

import { openCloseModal } from "../../Redux/actons/SubAdmin.action";
import Header from "components/Headers/Header";
import { Pagination, Dropdown } from "semantic-ui-react";
import { reset, Field, reduxForm } from "redux-form";
import { getCoinList } from "../../Redux/actons/user.action";
import {
  getInstalmentList,
  enableDisabelLock,
  update_description,
} from "../../Redux/actons/installment.action";
import AddPlan from "./AddPlan";
import EditDescription from "./EditDescription";
import Input from "reactstrap/lib/Input";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

const Filteroptions = [{ key: "e", text: "None", value: "none" }];
const status = [
  { key: "n", text: "All", value: "" },
  { key: "m", text: "Active", value: "active" },
  { key: "k", text: "Inactive", value: "inactive" },
];

export class InstallmentsManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      sortBy: "",
      coin_family: "",
      order: "asc",
      selectedCoin: "",
      status: "",
      openAmountModal: false,
      updateDescriptionModal: false,
      updateData: {},
    };
  }
  componentDidMount = () => {
    this.getPairList();
    this.props.getCoinList().then(() => {
      this.props.coinsList.forEach((element) => {
        Filteroptions.push(element);
      });
    });
  };
  componentWillUnmount = () => {
    this.props.openCloseModal();
  };

  getPairList = () => {
    const data = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      order: this.state.order,
      coin_id: this.state.selectedCoin,
      status: this.state.status,
    };
    this.props.getInstalmentList(data);
  };

  enableDisablePair = (data, id) => {
    const buttonState = { status: data };
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      order: this.state.order,
      coin_id: this.state.selectedCoin,
      status: this.state.status,
    };

    this.props.enableDisabelLock(buttonState, id, params);
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getPairList();
    });
  };

  openAmountModal = (data) => {
    setTimeout(() => {
      this.setState({ openAmountModal: !this.state.openAmountModal });
    }, 100);
  };

  toggle = () => {
    this.setState({ openAmountModal: !this.state.openAmountModal });
  };

  onSearchList = (search) => {
    this.setState({ page: 1 }, () => {
      this.getPairList();
    });
  };

  orderBy = (e, data) => {
    this.setState({ order: data.value, page: 1 }, () => {
      this.getPairList();
    });
  };

  filterByCoin = (e, data) => {
    this.setState({ selectedCoin: data.value, page: 1 }, () => {
      this.getPairList();
    });
  };

  filterByStatus = (e, data) => {
    this.setState({ status: data.value, page: 1 }, () => {
      this.getPairList();
    });
  };

  onUserHistory = () => {
    this.props.history.push({
      pathname: `user-installment-history`,
    });
  };

  redirectPlanUserHistory = (id) => {
    this.props.history.push({
      pathname: `user-installment-history`,
      state: id,
    });
  };

  openCloseDescriptionModal = (id, description) => {
    let data = { id: id, description: description };
    this.setState({
      updateData: data,
      updateDescriptionModal: !this.state.updateDescriptionModal,
    });
  };

  updateDescription = (values) => {
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      order: this.state.order,
      status: this.state.status,
    };
    let description = values.description;
    //console.log('description >>>2222455355555',description);
    this.props.update_description(
      { description: description },
      this.state.updateData.id,
      params
    );
    this.setState({
      updateDescriptionModal: !this.state.updateDescriptionModal,
    });
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Installment Management" />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.orderBy(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle mx-2"
                        options={Filteroptions}
                        placeholder="Filter by Coin"
                        selection
                        onChange={(e, data) => this.filterByCoin(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={status}
                        placeholder="Filter by status"
                        selection
                        onChange={(e, data) => this.filterByStatus(e, data)}
                      />
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openAmountModal()}
                      >
                        Add plan
                      </Button>
                      {/* <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onUserHistory()}
                      >
                        User history
                      </Button> */}
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => {
                          this.props.history.push("user-termination-requests");
                        }}
                      >
                        Termination requests
                      </Button>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Plan Id</th>
                        <th scope="col">Currency type</th>
                        <th scope="col">Tenure</th>
                        <th scope="col">Down payment</th>
                        <th scope="col">Down payment type</th>
                        <th scope="col">Conveyance Fee</th>
                        <th scope="col">Processing Fee</th>
                        <th scope="col">Status</th>
                        <th scope="col"> Acquisition Time Period </th>
                        <th scope="col"> Termination Time Period </th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.instalmentList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.instalmentList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data?.plan_id}.</td>
                            <td>{data.coin_name}</td>
                            <td>
                              {data.plan_tenure_time +
                                " " +
                                data.plan_tenure_type}
                            </td>
                            <td>{data.down_payment}</td>
                            <td>{data.down_payment_type.toUpperCase()}</td>
                            <td> {data.conveyance_fee}</td>
                            <td> {data.processing_fee}</td>
                            <td>{data.status.toUpperCase()}</td>
                            <td>
                              {data.acquisition_tenure_time +
                                " " +
                                data.acquisition_tenure_type}
                            </td>
                            <td>
                              {/* {data.termination_tenure_time != null ?{data.termination_tenure_time + " " + data.termination_tenure_type } : "NULL"} */}
                              {data.termination_tenure_type != null
                                ? data.termination_tenure_time +
                                  " " +
                                  data.termination_tenure_type
                                : "None"}
                            </td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color={
                                    data.status === "active"
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={() =>
                                    this.enableDisablePair(
                                      data.status === "active"
                                        ? "inactive"
                                        : "active",
                                      data.plan_id
                                    )
                                  }
                                >
                                  {data.status === "active"
                                    ? "Disable"
                                    : "Enable"}
                                </Button>
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.redirectPlanUserHistory(data.plan_id)
                                  }
                                >
                                  View users
                                </Button>
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.openCloseDescriptionModal(
                                      data.plan_id,
                                      data.description
                                    )
                                  }
                                >
                                  Edit Description
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <AddPlan toggle={this.state.openAmountModal} close={this.toggle} />
        <EditDescription
          updateDescriptionModal={this.state.updateDescriptionModal}
          openCloseDescriptionModal={this.openCloseDescriptionModal}
          updateDescription={this.updateDescription}
          updateData={this.state.updateData}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    instalmentList: state.installment.instalmentList.history,
    total: state.installment.instalmentList.totalRecords,
    coinsList: state.user.coinsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (data) => dispatch(reset(data)),
    openCloseModal: () => dispatch(openCloseModal(true)),
    getInstalmentList: (data) => dispatch(getInstalmentList(data)),
    enableDisabelLock: (data, id, params) =>
      dispatch(enableDisabelLock(data, id, params)),
    getCoinList: () => dispatch(getCoinList()),
    update_description: (data, id, params) =>
      dispatch(update_description(data, id, params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "InstallmentsManagementForm" })(InstallmentsManagement));
