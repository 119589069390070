import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Breadcrumb, BreadcrumbItem, Container } from "reactstrap";

import { Pagination, Dropdown, Form, Input } from "semantic-ui-react";
import CustomHeader from "../../components/Headers/CustomHeader";
import { IMAGE_PATH } from "../../constants/index";
import Header from "components/Headers/Header";
import axios from "axios";
import { API_HOST } from "../../constants/index";
import { FormField } from "../../components/FormField";
import { Link, withRouter } from "react-router-dom";
import {
  getContentDetail,
  updateContent,
} from "../../Redux/actons/content.action";

import {
  getContactQueryList,
  getContactQueryList1,
  updateQueryTaralGoldReply,
  getSubjectList,
} from "../../Redux/actons/subject.action";
import { Field, reduxForm, change } from "redux-form";
import { SelectField } from "../../components/SelectField";
import { getCoinList } from "../../Redux/actons/user.action";
import { startLoading, stopLoading } from "../../Redux/actons/loading.action";
import { required } from "redux-form-validators";
import CKEditor from "react-ckeditor-component-4";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
export class ReplyOnTaralGoldQuery extends Component {
  constructor(props) {
    super(props);
    // this.updateContent = this.updateContent.bind(this);
    this.state = {
      content: "",
      subject: "",
      ViewMoreADMIN: false,
      ViewMoreUSER: false,
    };
  }
  // updateContent(newContent) {
  //   this.setState({
  //       content: newContent
  //   })
  // }
  // componentDidMount = async () => {
  //   let searchKey = this.props.location.search.split("?")[1];
  //   if (searchKey) {
  //     localStorage.setItem("id", searchKey);
  //     await this.props.getContactQueryList1({
  //       ticket_id: searchKey,
  //     });
  //   } else {
  //     await this.props.getContactQueryList1({
  //       ticket_id: localStorage.getItem("id"),
  //     });
  //   }
  //   // let val = this.props.match.params.id
  //   // console.log("Id ====",val)
  //   // await this.props.getContentDetail({id:this.props.match.params.id});
  //   this.setState({ content: this.props.queryList?.message });
  //   // if(this.props.detail.content !== ""){
  //   //   this.setState({content:this.props.detail.content});
  //   // }
  // };

  // componentWillReceiveProps=(nextProps) => {
  //   if(this.props.detail.content !== nextProps.detail.content){
  //     //alert('test')
  //     this.setState({content:nextProps.detail.content});
  //   }
  // }
  componentDidMount = () => {
    // this.getQueryList();
    this.getSubjectList();
  };
  filterBySubject = (e, data) => {
    console.log(data.value);
    this.setState({ subject: data.value }, () => {
      // this.getQueryList();
    });
  };
  getSubjectList = () => {
    let data = {
      // search: this.state.searchKey,
      status: 1,
      // subject: this.state.subject,
      // perPage: this.state.perPage,
      // page: this.state.page,
    };
    this.props.getSubjectList(data);
  };

  onChange = (evt) => {
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
  };

  // updateData = () => {
  //   this.props.updateContent({
  //     page_id: localStorage.getItem("id"),
  //     content: this.state.content,
  //   });
  // };

  updateQueryData = () => {
    let data = {
      // id: localStorage.getItem("id"),
      message: this.state.content,
      subject: this.state.subject,
      // status: "resolved", //
    };
    this.props.updateQueryTaralGoldReply(data);
    this.setState({
      content: "",
    });
    this.props.history.push("contact-queries");
  };

  // onBlur=(evt)=>{
  //   console.log("onBlur event called with event info: ", evt);
  // }

  // afterPaste=(evt)=>{
  //   console.log("afterPaste event called with event info: ", evt);
  // }

  render() {
    // console.log('this.location >>>>',this.props.location);
    let searchKey = this.props.location.search.split("?")[1];
    let userDetail = this.props.queryView;
    let Messages = [];
    if (this.props.Messaages) {
      Messages = JSON?.parse(this.props.Messaages);
    }

    let subjectedLists = [
      {
        key: "",
        text: "none",
        value: "",
      },
    ];
    let subjectedLists1 = this.props.subjectList ? this.props.subjectList : [];
    let subjectedLists12 = this.props.subjectList
      ? this.props.subjectList.filter((data) => data.status == "0")
      : [];
    let obj = {
      key: "",
      text: "",
      value: "",
    };
    if (subjectedLists1.length > 0) {
      for (let i = 0; i < subjectedLists1.length; i++) {
        obj = {
          key: i,
          text: subjectedLists1[i].title,
          value: subjectedLists1[i].title,
        };
        subjectedLists.push(obj);
      }
    }
    console.log(searchKey, ">>>>>>Testings------>>>>", Messages);
    console.log("data == updateQueryTaralGoldReply => 60", this.state.subject);
    return (
      <div>
        <div>
          <Header headerTitle={`Broadcasting`} />
        </div>
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="content-management">Content Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {`Update Broadcasting Content`}
            </BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div style={{ padding: 30 }}>
          <Container className="contentBlock" fluid>
            <Form>
              <Form.Field>
                <Dropdown
                  // selectOnBlur={false}
                  className="selectUserStyle"
                  options={subjectedLists}
                  placeholder="Filter by Subject"
                  selection
                  value={this.state.subject}
                  // onChange={(e, data) => this.filterBy(e, data)}
                  // onChange={(e, data) =>
                  //   this.setState({ subject: e.target.value })
                  // }
                  onChange={(e, data) => this.filterBySubject(e, data)}
                />
              </Form.Field>
              <Form.Group>
                <Form.TextArea
                  rows={20}
                  cols={500}
                  label="Description"
                  placeholder="Description"
                  value={this.state.content}
                  onChange={(e, data) =>
                    this.setState({ content: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Field className="addBtn">
                <Button
                  color="primary"
                  onClick={() => this.props.history.goBack()}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  color="success"
                  onClick={() => this.updateQueryData()}
                >
                  Message
                </Button>
              </Form.Field>
              {/* // )} */}
            </Form>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    detail: state.content.detail,
    queryList: state?.subjectList?.queryList?.history,
    queryView: state?.subjectList?.queryView,
    Messaages: state?.subjectList?.queryView?.message,
    subjectList: state.subjectList?.subjectList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContactQueryList1: (data) => dispatch(getContactQueryList1(data)),
    getContentDetail: (data) => dispatch(getContentDetail(data)),

    getSubjectList: (data) => dispatch(getSubjectList(data)),
    updateContent: (data) => dispatch(updateContent(data)),
    updateQueryTaralGoldReply: (data) =>
      dispatch(updateQueryTaralGoldReply(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "UpdateQueryForm" })(ReplyOnTaralGoldQuery));
