import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { withRouter } from "react-router";

import logoPublet from "../../images/Taral.png";
var ps;

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
  };
  componentWillMount = () => {
    // alert(this.props.history.location.pathname);
    // document.getElementById("/sub-admin-management").classList.add("active");
  };

  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 100);

    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          {prop.name !== "Icon" &&
            prop.name !== "Add User Guide" &&
            prop.name !== "Maps" &&
            prop.name !== "Tables" &&
            prop.name !== "Login" &&
            prop.name !== "Register" &&
            prop.name !== "User Profile" &&
            prop.name !== "Google Auth" &&
            prop.name !== "Forgot Password" &&
            prop.name !== "Email Confirmation" &&
            prop.name !== "OTC Level Setting" &&
            prop.name !== "Transaction Fee" &&
            prop.name !== "Add New Sub Admin" &&
            prop.name !== "OTC order" &&
            prop.name !== "OTC Trader" &&
            prop.name !== "Trading Report" &&
            prop.name !== "Transaction Withdraw Report" &&
            prop.name !== "Token Deposit Report" &&
            prop.name !== "OTC order" &&
            prop.name !== "OTC Trader" &&
            prop.name !== "Add / Update Announcement" &&
            // prop.name !== "Withdrawal Management" &&
            prop.name !== "Transaction Coin" &&
            prop.name !== "Users Approved Kyc" &&
            prop.name !== "Users Submitted Kyc" &&
            prop.name !== "Kyc Level Transaction Setting" &&
            prop.name !== "Reports Management" &&
            prop.name !== "Total Revenue" &&
            prop.name !== "swap ida to idp rate" &&
            prop.name !== "Pending Withdrawal List" &&
            prop.name !== "OTC transaction fee" &&
            prop.name !== "Pending Kyc" &&
            prop.name != "Cold wallet Transaction History" &&
            prop.name != "Delete User" &&
            prop.name != "Fund History" &&
            prop.name != "View Order List" &&
            prop.name !== "Coin Setting" &&
            prop.name !== "Add Token" &&
            prop.name !== "Add Coin Pair" &&
            prop.name !== "Edit Token" &&
            prop.name !== "Edit Pair Coin" &&
            prop.name !== "Swap History" &&
            prop.name !== "Add Referral" &&
            prop.name !== "Referral History" &&
            prop.name !== "Coin Wise History" &&
            prop.name !== "Trade Setting" &&
            prop.name !== "Trade History" &&
            prop.name !== "Edit Interest" &&
            prop.name !== "Add Interest" &&
            prop.name !== "Interest User List" &&
            prop.name !== "view user interest history" &&
            prop.name !== "Interest User List" &&
            prop.name !== "Coin Balance" &&
            prop.name !== "User Wise Balance" &&
            prop.name !== "User INR Management" &&
            prop.name !== "View Incentive" &&
            prop.name !== "Add Banner" &&
            prop.name !== "Lock fund History" &&
            prop.name !== "Lock fund Management" &&
            prop.name !== "Reward History" &&
            prop.name !== "Order Setting" &&
            prop.name !== "User Installment History" &&
            prop.name !== "User Taral Gold History" &&
            prop.name !== "Fiat Revenue List" &&
            prop.name !== "Withdrawal Management" &&
            prop.name !== "Pair Coin Management" &&
            prop.name !== "Banner Management" &&
            prop.name !== "Fiat Management" &&
            // prop.name !== "Bank Account Management" &&
            prop.name !== "Log Reports" &&
            prop.name !== "Add Funds File" &&
            prop.name !== "Update Content" &&
            prop.name !== "Referral Incentive" &&
            prop.name !== "Influencer Management" &&
            prop.name !== "Influencer Incentive" &&
            prop.name !== "Influencer History" &&
            prop.name !== "Update Query" &&
            prop.name !== "add-userGuide" &&
            prop.name !== "Add Subject" &&
            prop.name !== "User Termination Requests" && (
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                id={prop.path}
                activeClassName="active"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            )}
        </NavItem>
      );
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light customColr"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>

          {/* Collapse */}
          <Collapse
            navbar
            isOpen={this.state.collapseOpen}
            className="customNav"
          >
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>

            <div className="logoBlock">
              <img src={logoPublet} className="insideLogo" />
              <p>Taral Admin</p>
            </div>
            <hr className="my-3" style={{ background: "darkgray" }} />
            {/* Navigation */}
            <h6 className="navbar-heading text-muted">Menu</h6>

            <Nav navbar className="customNavFont">
              {this.createLinks(routes)}
            </Nav>
            {/* Divider */}
            <hr className="my-3" style={{ background: "darkgray" }} />
            {/* Heading */}
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
  };
};

export default withRouter(connect(mapStateToProps)(Sidebar));
