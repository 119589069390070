import { actionTypes } from "../actons/banner.action";

const initialState = {
  bannerList: [],
  bannerType: [],
};

const banner = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_BANNER_LIST:
      return {
        ...state,
        bannerList: action.data,
      };
    case actionTypes.SAVE_BANNER_TYPE:
      var banner = action.data;
      var Final = [];

      banner.forEach((element) => {
        element.value = element.id;
        element.text = element.name.toUpperCase();
        Final.push(element);
      });
      return {
        ...state,
        bannerType: Final,
      };
    default:
      return state;
  }
};

export default banner;
