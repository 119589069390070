import { UserService } from "../../Services/UserServices";
import { toast } from "../../components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";
import { reset } from "redux-form";
import { DownLoadCSVFunction } from "./installment.action";
import moment from "moment";

export const actionTypes = {
  SAVE_USER_LIST: "SAVE_USER_LIST",
  TOTAL_RECORDS: "TOTAL_RECORDS",
  SAVE_KYC_LIST: "SAVE_KYC_LIST",
  TOTAL_KYC_RECORDS: "TOTAL_KYC_RECORDS",
  SAVE_DASHBOARD_LIST: "SAVE_DASHBOARD_LIST",
  OPEN_CLOSE_DETAIL_MODAL: "OPEN_CLOSE_DETAIL_MODAL",
  OPEN_CLOSE_BANK_MODAL: "OPEN_CLOSE_BANK_MODAL",
  GET_USER_DETAIL: "GET_USER_DETAIL",
  GET_BANK_DETAIL: "GET_BANK_DETAIL",
  SAVE_USER_KYC_LIST: "SAVE_USER_KYC_LIST",
  TOTAL_SUBMITTED_RECORDS: "TOTAL_SUBMITTED_RECORDS",
  SAVE_USER_KYC_APPROVED_LIST: "SAVE_USER_KYC_APPROVED_LIST",
  TOTAL_APPROVED_RECORDS: "TOTAL_APPROVED_RECORDS",
  GET_USER_COIN: "GET_USER_COIN",
  OPEN_CLOSE_BALANCE_MODAL: "OPEN_CLOSE_BALANCE_MODAL",
  CHANGE_KYC_STATUS: "CHANGE_KYC_STATUS",
  OPEN_CLOSE_KYC_MODAL: "OPEN_CLOSE_KYC_MODAL",
  SAVE_ADMIN_SET: "SAVE_ADMIN_SET",
  OPEN_CLOSE_ADMIN_DETAIL_MODAL: "OPEN_CLOSE_ADMIN_DETAIL_MODAL",
  OPEN_CLOSE_SET_ADMIN_MODAL: "OPEN_CLOSE_SET_ADMIN_MODAL",
  // VIEW_KYC_DETAIL:"VIEW_KYC_DETAIL"
  SAVE_DASHBOARD_GRAPH: "SAVE_DASHBOARD_GRAPH",
  SAVE_KYC_FILETR1: "SAVE_KYC_FILETR1",
  SAVE_KYC_FILETR2: "SAVE_KYC_FILETR2",
  SAVE_KYC_FILETR3: "SAVE_KYC_FILETR3",
  CLEAR_KYC_FILTER: "CLEAR_KYC_FILTER",
  SAVE_DELETED_USER: "SAVE_DELETED_USER",
  SAVE_USER_COIN_LIST_FILTER: "SAVE_USER_COIN_LIST_FILTER",
  SAVE_APP_SETTING: "SAVE_APP_SETTING",
};

export function saveAppSettings(data) {
  return {
    type: actionTypes.SAVE_APP_SETTING,
    data: data,
  };
}
export function saveDeletedUsers(data, total) {
  return {
    type: actionTypes.SAVE_DELETED_USER,
    data: data,
    total: total,
  };
}
export function clearKycFilters(data) {
  return {
    type: actionTypes.CLEAR_KYC_FILTER,
  };
}

export function saveKycSelectedFilter1(data) {
  return {
    type: actionTypes.SAVE_KYC_FILETR1,
    data: data,
  };
}
export function saveKycSelectedFilter2(data) {
  return {
    type: actionTypes.SAVE_KYC_FILETR2,
    data: data,
  };
}
export function saveKycSelectedFilter3(data) {
  return {
    type: actionTypes.SAVE_KYC_FILETR3,
    data: data,
  };
}
export function saveAdminSet(data) {
  return {
    type: actionTypes.SAVE_ADMIN_SET,
    data: data,
  };
}

export function saveAdminSetDetail(data) {
  return {
    type: actionTypes.SAVE_ADMIN_SET_DETAIL,
    data: data,
  };
}

export function openCloseSetAdminPopup(data) {
  return {
    type: actionTypes.OPEN_CLOSE_SET_ADMIN_MODAL,
    data: data,
  };
}

export function openCloseAdminDetailPopup(data) {
  return {
    type: actionTypes.OPEN_CLOSE_ADMIN_DETAIL_MODAL,
    data: data,
  };
}

export function openCloseUserDetailPopup(data) {
  return {
    type: actionTypes.OPEN_CLOSE_DETAIL_MODAL,
    data: data,
  };
}

export function openCloseBankDetailPopup(data) {
  return {
    type: actionTypes.OPEN_CLOSE_BANK_MODAL,
    data: data,
  };
}

export function openCloseBalancePopup(data) {
  return {
    type: actionTypes.OPEN_CLOSE_BALANCE_MODAL,
    data: data,
  };
}

export function openCloseKycPopup(data) {
  return {
    type: actionTypes.OPEN_CLOSE_KYC_MODAL,
    data: data,
  };
}

export function userDetailId(data) {
  return {
    type: actionTypes.GET_USER_DETAIL,
    data: data,
  };
}

export function saveBankDetails(data) {
  return {
    type: actionTypes.GET_BANK_DETAIL,
    data: data,
  };
}

export function userCoinId(data) {
  return {
    type: actionTypes.GET_USER_COIN,
    data: data,
  };
}

export function userKycStatus(data) {
  return {
    type: actionTypes.CHANGE_KYC_STATUS,
    data: data,
  };
}

export function totalRecords(data) {
  return {
    type: actionTypes.TOTAL_RECORDS,
    data,
  };
}

export function totalKycRecords(data) {
  return {
    type: actionTypes.TOTAL_KYC_RECORDS,
    data,
  };
}
export function totalSubmittedRecords(data) {
  return {
    type: actionTypes.TOTAL_SUBMITTED_RECORDS,
    data,
  };
}

export function totalApprovedRecords(data) {
  return {
    type: actionTypes.TOTAL_APPROVED_RECORDS,
    data,
  };
}

export function saveUserList(data) {
  return {
    type: actionTypes.SAVE_USER_LIST,
    data,
  };
}

export function saveKycList(data) {
  return {
    type: actionTypes.SAVE_KYC_LIST,
    data,
  };
}

export function saveUserKycList(data) {
  return {
    type: actionTypes.SAVE_USER_KYC_LIST,
    data,
  };
}
export function userKycApprovedList(data) {
  return {
    type: actionTypes.SAVE_USER_KYC_APPROVED_LIST,
    data,
  };
}
export function saveDashboardList(status) {
  return {
    type: actionTypes.SAVE_DASHBOARD_LIST,
    dashboard_data: status,
  };
}

export function saveDashboardGraph(status) {
  return {
    type: actionTypes.SAVE_DASHBOARD_GRAPH,
    dashboard_data: status,
  };
}

export function saveCoinsListForFilter(data) {
  return {
    type: actionTypes.SAVE_USER_COIN_LIST_FILTER,
    user: data,
  };
}
export function getCoinListForFilter() {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getCoinList({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveCoinsListForFilter(res.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
//set admin setting
export function setAdminSettings(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();

    return UserService.setAdminSetting(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveAdminSetDetail(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//get admin setings

export function getAdminSettings(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();

    return UserService.getAdminSetting({
      data,
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveAdminSet(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//dashboard_list

export function DashboardList(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getDashboardList({
      data,
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveDashboardList(data.data.data));
      })
      .catch((error) => {
        if (error) {
          //   toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//dashboard_graph

export function DashboardGraph(type) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getDashboarGraph(type, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());

        dispatch(saveDashboardGraph(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//user_list

export function userList(params) {
  return (dispatch, getState) => {
    if (params.filterType.length > 0) {
      params.filterType = params.filterType.toString();
    } else {
      params.filterType = "";
    }
    dispatch(startLoading());
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();

    return UserService.getUserList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());

        dispatch(saveUserList(data.data.data));
        dispatch(totalRecords(data.data.meta.total));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getUserListCSV(params) {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(startLoading());
    return UserService.getUserList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        let arrayOfObjects = data?.data?.data;
        if (!arrayOfObjects?.length) {
          return;
        }
        DownLoadCSVFunction(arrayOfObjects, "CSV_download_user_list");
      })
      .catch((error) => {
        dispatch(stopLoading());
        console.log(error);
      });
  };
}

//user_detail

export function downLoaded(data, params) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return UserService.downLoaded(data, params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        // dispatch(userDetailId(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function userDetail(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return UserService.getUserDetails(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(userDetailId(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// Bank Details
export function bankDetails(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getBankDetails(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveBankDetails(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//user_coinbalance

export function userCoinBalance(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();

    return UserService.getUserBalance(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(userCoinId(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//Download_CSV kyc_list

export function kycListCSV(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    // if (params.searchKey == "") {
    //   dispatch(startLoading());
    // }

    let state = getState();
    return UserService.getKycList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());

        let arrayOfObjects = data.data.data;
        if (!arrayOfObjects.length) {
          return;
        }
        DownLoadCSVFunction(arrayOfObjects, "download_kyc_list");
        // dispatch(saveKycList(data.data.data));
        // dispatch(totalKycRecords(data.data.meta));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
//kyc_list

export function kycList(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();
    return UserService.getKycList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveKycList(data.data.data));
        dispatch(totalKycRecords(data.data.meta));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//submitted_kyc

export function kycSubmittedList(params) {
  return (dispatch, getState) => {
    // dispatch(startLoading());
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return UserService.getSubmittedKyc(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveUserKycList(data.data.data));
        // alert(data.data.meta.total)
        dispatch(totalSubmittedRecords(data.data.meta.total));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//approved_kyc

export function kycApprovedList(params) {
  return (dispatch, getState) => {
    // dispatch(startLoading());
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();

    return UserService.getApprovedKyc(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(userKycApprovedList(data.data.data));
        // alert(data.data.meta.total)
        dispatch(totalApprovedRecords(data.data.meta.total));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//change_kyc_status
export function changeKycStatus(id, data, fdata) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return UserService.getKycStatus(id, data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(kycList(fdata));
        dispatch(reset("addCommentForm"));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//add comment

export function userAddComment(data, id, history) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.addComment(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res.data.message);

        // history.push("user-management");
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// enable disable user
export function enableDisableUser(id, data, reqData) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.enableDisableUser(id, data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res.data.message);
        dispatch(userList(reqData));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function enableDisableUserWithdraw(id, data, reqData) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.enableDisableUserWithdraw(id, data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res.data.message);
        dispatch(userList(reqData));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function removeUser(id, data, reqData) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.removeUser(id, data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res.data.message);
        dispatch(userList(reqData));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function deletedUserList(params) {
  return (dispatch, getState) => {
    // dispatch(startLoading());
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();

    return UserService.deletedUserList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveDeletedUsers(data.data.data, data.data.meta.total));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// change idp email
export function changeUserEmail(id, params, fdata) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();

    return UserService.changeUserEmail(id, params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        toast.success(res.data.message);
        dispatch(userList(fdata));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// trigger sigin in on off
export function triggerOnOff(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.triggerOnOff(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        let data = {
          type: "server_maintenance_mode",
        };

        dispatch(getAppSettings(data));
        toast.success(res.data.message);
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
//get app setting
export function getAppSettings(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getAppSettings(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveAppSettings(res.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
