import React, { Component } from "react";
import "./AccountSetting.scss";
import GoogleAuthActive from "./GoogleAuthActive/GoogleAuthActive";
import ColdWalletGoogleAuthActive from "./ColdWalletGoogleAuthActive/GoogleAuthActive";
import ChangePassword from "./ChangePassword/ChangePassword";
import {
  setGoogleAuthStep,
  resetPassword,
  setColdWalletGoogleAuthStep,
} from "../../Redux/actons/account.action";
import {
  getGSTLimit,
  setGSTLimit,
} from "../../Redux/actons/transaction.action";
import { connect } from "react-redux";
import CustomHeader from "../../components/Headers/CustomHeader";
import Header from "components/Headers/Header";
import { Card, CardImg, CardTitle, Button } from "reactstrap";
import server from "../../images/server3.png";
import fiatImg from "../../images/withdrawFiat3.png";
import cryptoImg from "../../images/withdrawCrypto2.png";
import CryptoWithdrawSetting from "./CryptoWithdrawSetting/CryptoWithdrawSetting";
import FiatWithdrawSetting from "./FiatWithdrawSetting/FiatWithdrawSetting";

class AccountSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fiatStatus: false,
      cryptoStatus: false,
    };
    this.getServerMode();
    this.getFiatWithdrawMode();
    this.getCryptoWithdrawMode();
  }
  resethandleSubmit = (value) => {
    console.log("value", value);
    this.props.resetPassword(value);
  };
  goToNextStep = (step) => {
    if (step === 1 || step === 2) {
      this.props.setGoogleAuthStep(step + 1);
    }
  };
  goToPrevStep = (step) => {
    if (step === 2 || step === 3) {
      this.props.setGoogleAuthStep(step - 1);
    }
  };
  coldWalletGoToNextStep = (step) => {
    if (step === 1 || step === 2) {
      this.props.setColdWalletGoogleAuthStep(step + 1);
    }
  };
  coldWalletGoToPrevStep = (step) => {
    if (step === 2 || step === 3) {
      this.props.setColdWalletGoogleAuthStep(step - 1);
    }
  };

  getServerMode = () => {
    this.props
      .getServerMode({ type: "server_maintenance_mode" })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
  };

  setServerMode = (mode) => {
    let params = {
      type: "server_maintenance_mode",
      value: mode,
    };
    this.props
      .setServerMode(params)
      .then((response) => {
        console.log(response);
        this.getServerMode();
      })
      .catch((error) => console.log(error));
  };

  getFiatWithdrawMode = () => {
    this.props
      .getServerMode({ type: "allow_fiat_withdraw" })
      .then((response) => {
        console.log("fiate=======> ", response);
        this.setstate({ fiatStatus: response });
      })
      .catch((error) => console.log(error));
  };

  setFiatWithdrawMode = (mode) => {
    let params = {
      type: "allow_fiat_withdraw",
      value: mode,
    };
    this.props
      .setServerMode(params)
      .then((response) => {
        console.log(response);
        this.getFiatWithdrawMode();
      })
      .catch((error) => console.log(error));
  };

  getCryptoWithdrawMode = () => {
    this.props
      .getServerMode({ type: "allow_crypto_withdraw" })
      .then((response) => {
        console.log("withdraw============> ", response);
        this.setstate({ cryptoStatus: response });
      })
      .catch((error) => console.log(error));
  };

  setCryptoWithdrawMode = (mode) => {
    let params = {
      type: "allow_crypto_withdraw",
      value: mode,
    };
    this.props
      .setServerMode(params)
      .then((response) => {
        console.log(response);
        this.getCryptoWithdrawMode();
      })
      .catch((error) => console.log(error));
  };

  render() {
    return (
      <div>
        <Header headerTitle="account settings" />
        <div className="contentBlock container-fluidk authList">
          {/* <Header as="h1">Account Setting</Header> */}

          {/* <Header as="h3">Authentications</Header> */}

          <GoogleAuthActive
            goToStep={this.goToNextStep}
            goToPrevStep={this.goToPrevStep}
          ></GoogleAuthActive>
          <ColdWalletGoogleAuthActive
            coldWalletGoToStep={this.coldWalletGoToNextStep}
            coldWalletGoToPrevStep={this.coldWalletGoToPrevStep}
          ></ColdWalletGoogleAuthActive>
          <ChangePassword onSubmit={this.resethandleSubmit}></ChangePassword>

          <Card body>
            <CardImg
              src={server}
              // style={{ height: 40, width: 40, marginLeft: 20 }}
            />
            <CardTitle>Server Maintenance</CardTitle>
            <Button
              color="info"
              onClick={() =>
                this.setServerMode(this.props.serverValue == "0" ? "1" : "0")
              }
            >
              {this.props.serverValue != "0" ? "Disable" : "Enable"}
            </Button>
          </Card>

          {/* start */}
          {/* <Card body>
            <CardImg
              src={fiatImg} 
            />
            <CardTitle>Fiat withdraw</CardTitle>
            <Button
              color="info"
              onClick={() =>
                this.setFiatWithdrawMode(
                  this.props.serverValue == "0" ? "1" : "0"
                )
              }
            >
              {this.props.serverValue != "0" ? "Disable" : "Enable"}
            </Button>
          </Card>

          <Card body>
            <CardImg
              src={cryptoImg} 
            />
            <CardTitle>Crypto withdraw</CardTitle>
            <Button
              color="info"
              onClick={() =>
                this.setCryptoWithdrawMode(
                  this.props.serverValue == "no" ? "yes" : "no"
                )
              }
            >
              {this.props.serverValue != "no" ? "Disable" : "Enable"}
            </Button>
          </Card> */}
          {/* end */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
    serverValue: state.transaction.gstValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGoogleAuthStep: (step) => dispatch(setGoogleAuthStep(step)),
    setColdWalletGoogleAuthStep: (step) =>
      dispatch(setColdWalletGoogleAuthStep(step)),
    resetPassword: (data) => dispatch(resetPassword(data)),
    getServerMode: (data) => dispatch(getGSTLimit(data)),
    setServerMode: (data) => dispatch(setGSTLimit(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountSetting);
