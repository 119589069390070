export const API_HOST = process.env.REACT_APP_API_URL;
// export const API_HOST = "https://testapi-main.mobiloitte.org/";
export const MOBILE_API_HOST = process.env.REACT_APP_MOBILE;
export const CAPTCHA_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;
export const IMAGE_PATH = process.env.REACT_APP_FILE_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const BTC_ADDRESS_BTC = process.env.REACT_APP_BTC_ADDRESS;
export const BTC_TXN_BTC = process.env.REACT_APP_BTC_TXN;
export const ETH_ADDRESS_ETH = process.env.REACT_APP_ETH_ADDRESS;
export const ETH_TXN_ETH = process.env.REACT_APP_ETH_TXN;
export const BSC_TXN_BSC = process.env.REACT_APP_BNB_TXN;
export const MATIC_TXN_MATIC = process.env.REACT_APP_MATIC_TXN;
export const BSC_ADDRESS_BSC = process.env.REACT_APP_BNB_ADDRESS;
export const MATIC_ADDRESS_MATIC = process.env.REACT_APP_MATIC_ADDRESS;
export const BTCV_ADDRESS_BTCV = process.env.REACT_APP_BTCV_ADDRESS;
export const API_LOCAL_HOST = "http://10.1.4.245:3000/";
export const NETWORK_TYPE_SOLANA = process.env.REACT_APP_NETWORK_TYPE_SOLANA;
