import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  getRewardList,
  enableDisableReward,
  changeRewardAmount,
} from "../../Redux/actons/reward.action";
import { openCloseModal } from "../../Redux/actons/SubAdmin.action";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Pagination, Dropdown, Form, Confirm } from "semantic-ui-react";
import swapIcon from "../../images/swap.png";
import { required } from "redux-form-validators";
import { amountLimit } from "../../validators/customValidator";
import { FormField } from "../../components/FormField";
import AddReward from "./AddReward";
import RewardLimit from "./RewardLimit";

export class RewardManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      order: "asc",
      amountModalOpen: false,
      minAmount: "",
      planData: "",
      addRewardModal: false,
      openConfirm: false,
      deleteStatus: "",
      deleteId: "",
      withdrawReward: false,
    };
  }
  componentDidMount = () => {
    this.getList();
  };
  componentWillUnmount = () => {
    // this.props.openCloseModal();
  };

  getList = () => {
    // const data = {
    //   page: this.state.page,
    //   perPage: this.state.perPage,
    //   searchKey: this.state.searchKey,
    // };
    this.props.getRewardList();
  };

  enableDisableReward = (data, id) => {
    const buttonState = { status: data };
    this.props.enableDisableReward(buttonState, id);
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getRewardList();
    });
  };

  //   onSearchList = (search) => {
  //     this.setState({ searchKey: search }, () => {
  //       this.getPairList();
  //     });
  //   };

  openAmountModal = (data) => {
    this.setState({
      openAmountModal: true,
      minAmount: data.min_amount,
      planData: data,
    });
  };

  toggle = () =>
    this.setState({ openAmountModal: !this.state.openAmountModal });

  editPlanAmount = async (data) => {
    const params = {
      id: data.id,
      amount: this.state.minAmount,
    };

    this.props.changeRewardAmount(params);
    this.toggle();
  };

  onIncentiveClick = (data) => {
    this.props.history.push({
      pathname: `viewIncentive`,
      state: data,
    });
  };

  addNewReward = () => {
    this.setState({ addRewardModal: !this.state.addRewardModal });
  };

  withdrawReward = () => {
    this.setState({ withdrawReward: !this.state.withdrawReward });
  };

  openConfrimationModal = (status, id) => {
    this.setState({ openConfirm: true, deleteStatus: status, deleteId: id });
  };

  closeRewardModal = () => {
    this.setState({ addRewardModal: !this.state.addRewardModal });
  };

  close = () => {
    this.setState({ openConfirm: false });
  };

  onConfirmation = () => {
    const buttonState = { status: this.state.deleteStatus };
    this.props.enableDisableReward(buttonState, this.state.deleteId);
    this.setState({ openConfirm: false });
  };

  checkForEnable = (list, data) => {
    if (data?.status == "active") {
      return true;
    } else if (
      list?.find((el) => el.type == data?.type && el.status == "active") &&
      data?.status == "inactive"
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        {/* <Header headerTitle="Reward Management" /> */}
        <div>
          <div>
            <div>
              <Confirm
                content={`Are you sure to delete this plan ?`}
                style={{
                  height: "auto",
                  top: "5%",
                  left: "auto",
                  bottom: "auto",
                  right: "auto",
                }}
                size="small"
                className="confirm-model"
                open={this.state.openConfirm}
                onCancel={this.close}
                onConfirm={this.onConfirmation}
              />
            </div>
          </div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      {/* <Input
                        placeholder="Search"
                        onChange={(e) => this.onSearchList(`${e.target.value}`)}
                      ></Input> */}

                      <Link to="reward-history">
                        <Button color="success btnMamagePermission">
                          Reward History
                        </Button>
                      </Link>
                      <Button
                        color="default btnMamagePermission"
                        onClick={() => this.addNewReward()}
                      >
                        + Add Reward
                      </Button>

                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.withdrawReward()}
                      >
                        Set Limit
                      </Button>

                      {/* <Link to="swap-history">
                            <Button color="success btnMamagePermission">
                              Swap History
                            </Button>
                          </Link> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col"> Type</th>
                        <th scope="col">Min amount</th>
                        <th scope="col">Plan Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.rewardList?.rows?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.rewardList?.rows?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.type.toUpperCase()}</td>
                            <td>{data.min_amount}</td>
                            <td>{data.status.toUpperCase()}</td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color={
                                    data.status === "active"
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={() => {
                                    if (
                                      this.checkForEnable(
                                        this.props.rewardList.rows,
                                        data
                                      ) == true
                                    ) {
                                      this.enableDisableReward(
                                        data.status === "active"
                                          ? "inactive"
                                          : "active",
                                        data.id
                                      );
                                    } else {
                                      alert(
                                        `${data?.type.toUpperCase()} is alreay active`
                                      );
                                    }
                                  }}
                                >
                                  {data.status === "active"
                                    ? "Inactive"
                                    : "Active"}
                                </Button>
                                <Button
                                  color={"success"}
                                  onClick={() => this.openAmountModal(data)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  color={"success"}
                                  onClick={() => this.onIncentiveClick(data)}
                                >
                                  View incentive
                                </Button>
                                <Button
                                  color={"danger"}
                                  onClick={() =>
                                    this.openConfrimationModal(
                                      "deleted",
                                      data.id
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {/* {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "} */}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <Modal isOpen={this.state.openAmountModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Edit Amount</ModalHeader>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Minimum amount</label>
                  <input
                    type="number"
                    name="min_amount"
                    min="0"
                    maxLength={10}
                    value={this.state.minAmount}
                    validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ minAmount: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openAmountModal: false })}
            >
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() => this.editPlanAmount(this.state.planData)}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
        <AddReward
          closeRewardModal={this.closeRewardModal}
          addRewardModal={this.state.addRewardModal}
        />
        <RewardLimit
          closeRewardModal={this.withdrawReward}
          showRewardModal={this.state.withdrawReward}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    rewardList: state.rewardList.rewardList,
    // total: state.pairCoinList.pairCoinList.total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRewardList: () => dispatch(getRewardList()),
    enableDisableReward: (data, id) => dispatch(enableDisableReward(data, id)),
    changeRewardAmount: (data) => dispatch(changeRewardAmount(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RewardManagement)
);
