import { actionTypes } from "../actons/referral.action";

const initialState = {
  referralList: [],
  referralListMLM: [],
  referralHistory: [],
  coinWiseHistory: [],
  referralIncentive: [],
  influencerReferralList: [],
  influencerIncentiveList: [],
  influencerHistory: [],
};

const referralList = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REFERRAL_LIST:
      return {
        ...state,
        referralList: action.data.data,
      };
    case actionTypes.REFERRAL_LIST_MLM:
      return {
        ...state,
        referralListMLM: action.data.data,
      };
    case actionTypes.REFERRAL_HISTORY:
      return {
        ...state,
        referralHistory: action.data.data,
      };
    case actionTypes.COIN_WISE_HISTORY:
      return {
        ...state,
        coinWiseHistory: action.data.data,
      };
    case actionTypes.REFERRAL_INCENTIVE:
      return {
        ...state,
        referralIncentive: action.data.data,
      };
    case actionTypes.INFLUENCER_REFERAL_LIST:
      return {
        ...state,
        influencerReferralList: action.data,
      };
    case actionTypes.INFUENCER_INCENTIVE:
      return {
        ...state,
        influencerIncentiveList: action.data,
      };
    case actionTypes.INFUENCER_HISTORY:
      return {
        ...state,
        influencerHistory: action.data.data,
      };
    default:
      return state;
  }
};

export default referralList;
