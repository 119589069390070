import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const geTransactionList = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/transaction/getTransactions`,
    data,
    options
  );
};
const sendMessage = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/transaction/sendmessage`,
    data,
    options
  );
};
const getTransactionDetails = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/transaction/detail`,
    data,
    options
  );
};
const setDailyWithdrawLimit = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/settings/updateSettings`,
    data,
    options
  );
};
const getDailyWithdrawLimit = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/settings/detail/${data.type}`,
    {},
    options
  );
};

const getGSTLimit = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/settings/detail/${data.type}`,
    {},
    options
  );
};

const setGSTLimit = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/settings/updateSettings`,
    data,
    options
  );
};
const updateTxnWalletThresholdValues = (data, options) => {
  console.log("data-----", data);
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/edit_token_gas_fee`,
    data,
    options
  );
};
const gettxnWalletThresholdValue = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/get_token_gas_fee`,
    data,
    options
  );
};

export const TransactionService = {
  geTransactionList,
  sendMessage,
  getTransactionDetails,
  setDailyWithdrawLimit,
  getDailyWithdrawLimit,
  getGSTLimit,
  setGSTLimit,
  gettxnWalletThresholdValue,
  updateTxnWalletThresholdValues,
};
