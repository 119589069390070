import React from "react";
import {
  Button,
  FormGroup,
  Form,
  Container,
  Card,
  CardHeader,
  Label,
} from "reactstrap";
import { reduxForm, change, Field } from "redux-form";
import { FormField } from "../../components/FormField";
import { required } from "redux-form-validators";
import { useDispatch } from "react-redux";
import { submitIdpChargeSetting } from "Redux/actons/user.action";

function IDPChargeSetting(props) {
  const dispatch = useDispatch();
  const onFormSubmit = (values) => {
    dispatch(submitIdpChargeSetting(values));
  };
  return (
    <div>
      <div>
        <Container className="contentBlock" fluid>
          <div style={{ padding: 30 }}>
            <Card className="shadow">
              <CardHeader className="border-0" style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <h3 className="mb-2">Deposit Account Setting</h3>
                </div>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => props.setSettingPage(false)}
                >
                  Back
                </Button>
              </CardHeader>
              <Form onSubmit={props.handleSubmit(onFormSubmit)}>
                <FormGroup style={{ padding: "10px" }}>
                  <Label for="account_number">Bank Account</Label>
                  <div style={{ display: "flex" }}>
                    <Field
                      name="account_number"
                      component={FormField}
                      type="text"
                      style={{ width: "50%" }}
                    />
                  </div>
                </FormGroup>
                <FormGroup style={{ padding: "10px" }}>
                  <Label for="bank_name">Name of Bank</Label>
                  <div style={{ display: "flex" }}>
                    <Field
                      name="bank_name"
                      component={FormField}
                      type="text"
                      style={{ width: "50%" }}
                    />
                  </div>
                </FormGroup>
                <CardHeader className="border-0">
                  <div style={{ width: "100%" }}>
                    <h3 className="mb-2">Deposit Announcement Setting</h3>
                  </div>
                </CardHeader>
                <FormGroup style={{ padding: "10px" }}>
                  <Label for="announcement_text">Announcement</Label>
                  <div style={{ display: "flex" }}>
                    <Field
                      name="announcement_text"
                      component={FormField}
                      type="text"
                      style={{ width: "50%" }}
                    />
                  </div>
                </FormGroup>
                <FormGroup style={{ padding: "10px" }}>
                  <div>
                    <Button type="submit" id="updatePrice" color="primary">
                      Submit
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </Card>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default reduxForm({ form: "IDPChargeForm" })(IDPChargeSetting);
