import { actionTypes } from "../actons/userList.action";

const initialState = {
  adminSettings: [],
  setAdminSettings: [],
  dashboardLists: {},
  usersLists: [],
  kycLists: [],
  search: "",
  totalRecords: "",
  totalKycRecords: "",
  userDetailModal: false,
  getUserId: {},
  getUserCoinId: {},
  userBalanceModal: false,
  userKycModal: false,
  submittedKyc: [],
  totalSubmittedRecords: "",
  approvedKyc: [],
  totalApprovedRecords: "",
  getKycStatusId: {},
  adminDetailModal: false,
  setAdminModal: false,
  dashboardGraph: {
    labels: [],
    datasets: [
      {
        label: "Performance",
        data: [],
      },
    ],
  },
  type: "",
  filter1: "",
  filter2: "",
  kycSelected: "", // 1 or 2 values
  // getkycDetailId :{}
  deleteUserList: [],
  totalDelete: 0,
  coinListCoinFilter: [],
  appSetting: [],
};

const userList = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case actionTypes.SAVE_DELETED_USER:
      return {
        ...state,
        deleteUserList: action.data,
        totalDelete: action.total,
      };
    case actionTypes.SAVE_ADMIN_SET_DETAIL:
      return {
        ...state,
        setAdminSettings: action.data,
      };

    case actionTypes.SAVE_KYC_FILETR1:
      return {
        ...state,
        filter1: action.data,
        filter2: "",
        filter3: "",
        kycSelected: 1,
        type: action.data != "none" ? action.data : "",
      };
    case actionTypes.SAVE_KYC_FILETR2:
      return {
        ...state,
        filter3: "",
        filter2: action.data,
        filter1: "",
        kycSelected: 2,
        type: action.data != "none" ? action.data : "",
      };
    case actionTypes.SAVE_KYC_FILETR3:
      // console.log("SAVE_KYC_FILETR3", action);
      return {
        ...state,
        filter3: action.data,
        filter2: "",
        filter1: "",
        kycSelected: 2,
        type: action.data != "none" ? action.data : "",
      };

    case actionTypes.CLEAR_KYC_FILTER:
      return {
        ...state,
        filter2: "",
        filter1: "",
        kycSelected: "",
      };
    case actionTypes.OPEN_CLOSE_SET_ADMIN_MODAL:
      return {
        ...state,
        setAdminModal: action.data,
      };

    case actionTypes.OPEN_CLOSE_ADMIN_DETAIL_MODAL:
      return {
        ...state,
        adminDetailModal: action.data,
      };

    case actionTypes.SAVE_DASHBOARD_LIST:
      return {
        ...state,
        dashboardLists: action.dashboard_data,
      };

    case actionTypes.SAVE_DASHBOARD_GRAPH:
      return {
        ...state,
        dashboardGraph: {
          labels: action.dashboard_data.lable,
          datasets: [
            {
              label: "Performance",
              data: action.dashboard_data.data,
            },
          ],
        },
      };

    case actionTypes.SAVE_USER_LIST:
      return {
        ...state,
        usersLists: action.data,
      };

    case actionTypes.TOTAL_RECORDS:
      return {
        ...state,
        totalRecords: action.data,
      };
    case actionTypes.OPEN_CLOSE_DETAIL_MODAL:
      return {
        ...state,
        userDetailModal: action.data,
      };

    case actionTypes.GET_USER_DETAIL:
      return {
        ...state,
        getUserId: action.data,
      };

    case actionTypes.GET_USER_COIN:
      return {
        ...state,
        getUserCoinId: action.data,
      };

    case actionTypes.OPEN_CLOSE_BALANCE_MODAL:
      return {
        ...state,
        userBalanceModal: action.data,
      };

    case actionTypes.SAVE_KYC_LIST:
      return {
        ...state,
        kycLists: action.data,
      };

    case actionTypes.TOTAL_KYC_RECORDS:
      return {
        ...state,
        totalKycRecords: action.data,
      };

    // case actionTypes.VIEW_KYC_DETAIL:
    //   return {
    //     ...state,
    //     getkycDetailId: action.data
    //   };
    case actionTypes.SAVE_USER_KYC_LIST:
      return {
        ...state,
        submittedKyc: action.data,
      };

    case actionTypes.TOTAL_SUBMITTED_RECORDS:
      return {
        ...state,
        totalSubmittedRecords: action.data,
      };

    case actionTypes.CHANGE_KYC_STATUS:
      return {
        ...state,
        getKycStatusId: action.data,
      };

    case actionTypes.OPEN_CLOSE_KYC_MODAL:
      return {
        ...state,
        userKycModal: action.data,
      };

    case actionTypes.SAVE_USER_KYC_APPROVED_LIST:
      return {
        ...state,
        approvedKyc: action.data,
      };

    case actionTypes.TOTAL_APPROVED_RECORDS:
      return {
        ...state,
        totalApprovedRecords: action.data,
      };
    case actionTypes.SAVE_USER_COIN_LIST_FILTER:
      var coin = action.user;
      var Final = [];
      var filterd = [];
      var logoCoins = [];
      // console.log("coinLIst", coin);
      coin.forEach((element) => {
        element.value = element.coin_id;

        // 1=eth, 2=btc, 3=bnb, 4=trx

        if (element.is_token == 1) {
          if (element.coin_family == 1) {
            element.text = element.coin_symbol.toUpperCase() + "(ERC-20)";
          } else if (element.coin_family == 3) {
            element.text = element.coin_symbol.toUpperCase() + "(BEP-20)";
          } else if (element.coin_family == 4) {
            element.text = element.coin_symbol.toUpperCase() + "(TRC-20)";
          } else if (element.coin_family == 5) {
            element.text = element.coin_symbol.toUpperCase() + "(MATIC)";
          } else {
            element.text = element.coin_symbol.toUpperCase();
          }
        } else {
          element.text = element.coin_symbol.toUpperCase();
        }

        Final.push(element);
      });
      coin.forEach((element) => {
        if (element.coin_name) {
          if (element.coin_name !== "Orbs") {
            filterd.push(element);
            logoCoins.push(element);
          } else {
            logoCoins.push(element);
          }
        }
      });

      return {
        ...state,
        coinsList: Final,
        coinListCoinFilter: Final,
        LogoCoins: logoCoins,
      };
    // return {
    //   ...state,
    //   coinListCoinFilter: Final
    // };
    case actionTypes.SAVE_APP_SETTING:
      return {
        ...state,
        appSetting: action.data,
      };
    default:
      return state;
  }
};

export default userList;
