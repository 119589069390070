import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Form, label } from "semantic-ui-react";
import "./SendToColdWallet.scss";
import { Field, reduxForm, change, reset} from "redux-form";
import { FormField } from "../../../components/FormField";
import { SelectField } from "../../../components/SelectField";
import { email, required } from "redux-form-validators";
import { sendToColdWallets,openCloseSendClodModal } from "../../../Redux/actons/fund.action";
export class SendToColdWallet extends Component {
  sendToCold = (values) => {
    values.coin_family=this.props.coinFamily;
   // this.props.sendToColdWallets(this.props.coinId, values);
    this.props.openCloseSendClodModal(false);
    if (this.props.isToken == 0 ){
      this.props.sendToColdWallets(this.props.coinId, values);
    }else{
      this.props.sendToColdWallets(this.props.address, values);
    }
    this.props.toggleColdModal();
    this.props.reset("sendToColdWalletForm");
  };
  closeModal = () =>{
    // alert('test')
    this.props.openCloseSendClodModal(false);
  }
  render() {
    let { handleSubmit } = this.props;
  return (
      <div>
        <Modal
          isOpen={this.props?.openSendColdModal}
          toggle={this.props.toggleColdModal}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.toggleColdModal}>
            <h1>Send to cold wallet</h1>
          </ModalHeader>
          <ModalBody>
            <h4 style={{ color: "#fff" }}>
              Total amount: {this.props.totalAmount}{" "}
              {this.props.coinId.toUpperCase()}
            </h4>
            <Form onSubmit={handleSubmit(this.sendToCold)}>
              <Form.Group>
                <Form.Field>
                  <label style={{ color: "#fff" }}>Amount</label>
                  <Field
                    type="number"
                    name="amount"
                    validate={[required()]}
                    component={FormField}
                    placeholder="Amount"
                  ></Field>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label style={{ color: "#fff" }}>Address</label>
                  <Field
                    type="text"
                    name="to_address"
                    validate={[required()]}
                    component={FormField}
                    placeholder="Address"
                  ></Field>
                </Form.Field>
              </Form.Group>
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Send
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log('----------------------funds',state.funds);
  return {
    coinsList: state.user.coinsList,
    openSendColdModal: state.funds.openSendColdModal
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    reset: (data) => dispatch(reset(data)),
    sendToColdWallets: (coin, data) => dispatch(sendToColdWallets(coin, data)),
    openCloseSendClodModal: (status) => dispatch(openCloseSendClodModal(status)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "sendToColdWalletForm" })(SendToColdWallet));
