import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, label } from "semantic-ui-react";
import { Button } from "reactstrap";
import {
  getUserFund,
  setUserFund,
  getFundUsers,
  saveWalletId,
  lockUserFunds,
  unLockUserFunds,
} from "../../../Redux/actons/fund.action";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
// import { FormField } from "../../components/FormField";
// import { SelectField } from "../../components/SelectField";
// import { email, required } from "redux-form-validators";
// import { toast } from "../../components/Toast/Toast";
import { reset } from "redux-form";
// import { getWithdrawFeeList } from "../../Redux/actons/user.action";
import {
  // addUpdateWithdraw,
  getWithdrawFeeList,
  addUpdateWithdraw,
} from "../../../Redux/actons/installment.action";
import { Pagination, Dropdown } from "semantic-ui-react";

const options = [
  { key: "n", text: "Minutes", value: "minutes" },
  { key: "z", text: "Hours", value: "hours" },
  { key: "m", text: "Day", value: "day" },
  { key: "e", text: "Week", value: "week" },
  { key: "k", text: "Month", value: "month" },
  { key: "l", text: "Year", value: "year" },
];

const downpaymentType = [
  { key: "k", text: "Percentage", value: "percentage" },
  { key: "l", text: "Actual Value", value: "actual_value" },
];

export class SetLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minQty: "",
      view: true,
    };
  }
  //getWithdrawFeeList

  componentDidMount = () => {
    this.props.getWithdrawFeeList();
  };

  toggle = () => {
    this.props.reset("addFundsForm");
    this.props.close();
    this.setState({ view: true });
  };

  addUpdateWithdraw = () => {
    let dayObject = { day: 1, week: 7, month: 30, year: 365 };
    if (!this.state.minQty) {
      alert("All fields are required");
    } else {
      // console.log('dayObject[this.state.planTenure]',dayObject[this.state.planTenure]);
      //   let actualPlanTime =
      //     this.state.planTime * dayObject[this.state.planTenure];
      //   let actualAcquisitionTime =
      //     this.state.acquisitionTime * dayObject[this.state.acquisitionType];
      // console.log('dayObject[this.state.acquisitionType]',dayObject[this.state.acquisitionType]);

      //   if (actualPlanTime < actualAcquisitionTime) {
      //     alert("Acquisition Time should be less than Plan Time");
      //   } else {
      const data = {
        withdraw_limit: this.state.minQty,
      };

      const data1 = {
        page: this.state.page,
        perPage: this.state.perPage,
        searchKey: this.state.searchKey,
        order: this.state.order,
        //coin_id: this.state.selectedCoin,
        status: this.state.status,
      };

      this.props.addUpdateWithdraw(data, data1); // need to add api
      this.toggle();
      //   }
    }
  };
  render() {
    // console.log("add plan getWithdrawFeeList --- GetWithdrawLimit", this.props);
    // console.log(
    //   "getWithdrawFeeList --- GetWithdrawLimit-----12",
    //   this.props.getWithdrawLimit
    // );
    let { handleSubmit } = this.props;
    return (
      <Modal isOpen={this.props.toggle}>
        <ModalHeader toggle={this.toggle}>
          {this.state.view ? "View" : "Set"} User Withdraw Limit
        </ModalHeader>
        <ModalBody>
          <Form>
            {this.state.view ? (
              <Form.Group>
                <Form.Field>
                  <label style={{ width: "200px" }}>Withdraw Limit </label>
                  <input
                    type="number"
                    name="minimum_qunatity"
                    min="0"
                    max="100"
                    value={this.props.getWithdrawLimit?.withdraw_limit}
                    //   value={this.state.selectedCoin}
                    disabled={this.state.view}
                    required
                    onChange={(e, data) =>
                      this.setState({ minQty: e.target.value })
                    }
                  />
                </Form.Field>
              </Form.Group>
            ) : (
              <Form.Group>
                <Form.Field>
                  <label style={{ width: "200px" }}>Withdraw Limit </label>
                  <input
                    type="number"
                    name="minimum_qunatity"
                    min="0"
                    max="100"
                    placeholder={this.props.getWithdrawLimit?.withdraw_limit}
                    //   value={this.state.selectedCoin}
                    disabled={this.state.view}
                    required
                    onChange={(e, data) =>
                      this.setState({ minQty: e.target.value })
                    }
                  />
                </Form.Field>
              </Form.Group>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="error"
            onClick={() => {
              this.toggle();
              this.setState({ view: true });
            }}
          >
            Cancel
          </Button>
          {this.state.view ? (
            <Button
              color="success"
              onClick={(e, data) => this.setState({ view: false })}
            >
              Edit
            </Button>
          ) : (
            <Button color="success" onClick={() => this.addUpdateWithdraw()}>
              Save
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coinsList: state.user.coinsList,
    getWithdrawLimit: state.installment.getWithdrawLimit,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWithdrawFeeList: () => dispatch(getWithdrawFeeList()),
    reset: (data) => dispatch(reset(data)),
    addUpdateWithdraw: (data, params) =>
      dispatch(addUpdateWithdraw(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "addFundsForm" })(SetLimit));
