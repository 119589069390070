import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCoinList } from "../../Redux/actons/user.action";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  getCurrencyList,
  updateCurrency,
  deleteCoinLimit,
  updateCoinLimit,
  updateRate,
  addCoinValue,
  updateCoinValue,
  listCoinsLimits,
} from "../../Redux/actons/user.action";
import { Pagination, Form, Dropdown } from "semantic-ui-react";
import Header from "components/Headers/Header";
import { withRouter, Link } from "react-router-dom";
import { listDateFormat } from "utils/utils";

const options = [
  { key: "m", text: "Upgrade", value: "upgrade" },
  { key: "k", text: "Downgrade", value: "downgrade" },
];
const coinsListINR = [{ key: "m", text: "INR", value: 9 }];

const UserFilter = [
  { key: "o", text: "All", value: "" },
  { key: "c", text: "Inactive", value: "0" },
  { key: "u", text: "Active", value: "1" },
];

export class ViewOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      buyOrder: "",
      sellOrder: "",
      coinName: "",
      max: "",
      min: "",
      coinName1: "",
      max1: "",
      min1: "",
      buyRate: "",
      sellRate: "",
      sortBy: "",
      coinId: "",
      limit: "",
      isUpdateCoin: false,
      openModel: false,
      openModel1: false,
      openModel2: false,
      isUpdate: false,
      isAdd: false,
      modalData: "",
      listType: "1",
      tokenId: "",
      _id: "",
      status1: "",
      setTotalData: {},
    };
  }
  componentDidMount = () => {
    this.listCoinsLimits(); // /api/v1/admin/reward/listCoinsLimits
    this.getCoinList();
    // this.props.getCurrencyList();
    // this.props.getCoinList();
  };

  getCoinList = () => {
    const param = {
      searchKey: this.state.searchKey,
      page: 1,
      perPage: 100,
      // list_type: this.state.listType,
      status: "1",
    };
    this.props.getCoinList(param);
  };
  getCurrencyList = () => {
    const param = {
      searchKey: this.state.searchKey,
      page: 1,
      perPage: 100,
      // list_type: this.state.listType,
      status: "1",
    };
    this.props.getCurrencyList(param);
  };
  listCoinsLimits = () => {
    // status1
    const param = {
      searchKey: this.state.searchKey,
      page: this.state.page,
      perPage: this.state.perPage,
      status: this.state.status1.toString(),
    };
    // console.log("price_in_inr", param);
    this.props.listCoinsLimits(param);
  };
  onSearchList = () => {
    this.listCoinsLimits();
  };

  listByUser = (e, data) => {
    if (data.value !== "none") {
      this.setState({ status1: data.value }, () => {
        this.listCoinsLimits(); //
      });
    } else {
      this.setState({ status1: "" }, () => {
        this.listCoinsLimits(); //
      });
    }
  };
  SelectCoinData = (e, data) => {
    console.log(this.state.isUpdateCoin, "e ---- data", data, data.value);

    let selectedCoinName = data.options.find(
      (data1) => data1.value == data.value
    ).coin_id;
    console.log(data, "e ---- data", selectedCoinName);
    // if (data.value !== "none") {
    this.setState({ coinId: selectedCoinName }, () => {
      // this.listCoinsLimits(); //
    });
    // } else {
    //   this.setState({ status1: "" }, () => {
    //     // this.listCoinsLimits(); //
    //   });
    // }
  };

  toggle = () =>
    this.setState({
      openModel: !this.state.openModel,
    });
  toggle1 = () =>
    this.setState({
      openModel1: !this.openModel1,
    });
  toggle2 = () =>
    this.setState({
      openModel2: !this.openModel2,
    });

  openModel = (data) => {
    this.setState({
      openModel: true,
    });
  };
  openModel1 = (data) => {
    this.setState({
      openModel1: true,
      coinName1: data.symbol,
      _id: data.id,
      min1: data.min,
      max1: data.max,
      tokenId: data.id,
      isUpdate: true,
      isModal: false,
      isModal: false,
      setTotalData: data,
    });
  };
  openModel2 = (data) => {
    console.log(data, "coinsList ------->>> data1");
    this.setState({
      openModel2: true,
      isUpdateCoin: true,
      isAdd: true,
      coinId: data.coin_id,
      limit: data.limit,
      setTotalData: data,
    });
  };
  openModelSetting = () => {
    this.setState({
      coinId: "",
      limit: "",
      openModel2: true,
      isUpdateCoin: false,
      isAdd: false,
    });
  };

  // deleteCoinLimit = (data1, id, currencyType) => {
  //   this.setState({isModal:true,
  //     min1: data.min,
  //     max1: data.max,
  //    })
  // }
  updateCoinLimit = (data1, id, currencyType) => {
    console.log(this.state._id, id, "coinsList ------->>> data1", data1);

    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
      status: this.state.status1,
    };

    const buttonState = { status: data1 };

    let params;
    if (this.state.openModel1) {
      params = {
        id: data1.id,
        // min: this.state.min1,
        // max: this.state.max1,
      };
    } else {
      params = {
        id: data1.id,
        min: data1.min,
        max: data1.max,
      };
    }
    this.props.updateCoinLimit(params, data2);
    if (this.state.openModel1) {
      this.toggle1();
    }
  };
  deleteCoinLimit = (data1, id, currencyType) => {
    console.log(this.state._id, id, "coinsList ------->>> data1", data1);

    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
      status: this.state.status1,
    };

    const buttonState = { status: data1 };

    let params;
    if (this.state.openModel1) {
      params = {
        id: data1.id,
        // min: this.state.min1,
        // max: this.state.max1,
      };
    } else {
      params = {
        id: data1.id,
        min: data1.min,
        max: data1.max,
      };
    }
    this.props.deleteCoinLimit(params, data2);
    if (this.state.openModel1) {
      this.toggle1();
    }
  };
  editCoinPairs = (data1, id, currencyType) => {
    console.log(this.state.limit, this.state.coinId, " ----> data1 ", data1);
    if (
      this.state.openModel2 &&
      (this.state.limit == "" || this.state.coinId == "")
    ) {
      alert("All fields are required");
      return;
    }
    if (
      this.state.openModel2 &&
      (this.state.limit == null || this.state.coinId == null)
    ) {
      alert("All fields are required");
      return;
    }
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    const buttonState = { status: data1 };

    let params = {
      coin_id: this.state.coinId,
      limit: this.state.limit,
    };
    this.props.updateCoinLimit(params, data2);
    if (this.state.openModel2) {
      this.toggle2();
    }
  };
  enableDisableCurrency = (data, id, currencyType) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    const buttonState = { status: data };

    const params = {
      currency_type: currencyType,
      currency_id: id,
      status: data,
    };
    this.props.updateCurrency(params, data2);
  };

  onChangePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.listCoinsLimits();
    });
  };

  sortForBuy = (e, data) => {
    this.setState({ buyOrder: data.value }, () => {});
  };

  sortForSell = (e, data) => {
    this.setState({ sellOrder: data.value }, () => {});
  };

  updateCoinValueClick = (currency_type) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    if (
      this.state.coinName1 === "" ||
      this.state.min1 === "" ||
      this.state.max1 === ""
    ) {
      alert("all fields are required");
      return;
    }

    if (this.state.max1 < this.state.min1) {
      alert("Min value should be less than Max");
    } else {
      const data = {
        id: this.state.tokenId,
        coin_name: this.state.coinName1,
        min: this.state.min1,
        max: this.state.max1,
        // sell_rate_type: this.state.sellOrder,
        // currency_id: this.state.modalData.currency_id,
        // currency_type: this.state.currencyType,
      };
      this.props.updateCoinValue(data, this.state.modalData.id, data2);
      this.toggle1();
    }
  };
  addCoinValueClick = (currency_type) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    if (
      this.state.coinName === "" ||
      this.state.min === "" ||
      this.state.max === ""
    ) {
      alert("all fields are required");
      return;
    }

    if (this.state.max < this.state.min) {
      alert("Min value should be less than Max");
    } else {
      const data = {
        coin_name: this.state.coinName,
        min: this.state.min,
        max: this.state.max,
        // sell_rate_type: this.state.sellOrder,
        // currency_id: this.state.modalData.currency_id,
        // currency_type: this.state.currencyType,
      };
      this.props.addCoinValue(data, this.state.modalData.id, data2);
      this.toggle();
    }
  };

  render() {
    let { handleSubmit, saveCoinValue, coinsList, saveCoinLimit } = this.props;
    console.log(
      saveCoinValue,
      saveCoinLimit,
      "  saveOrderDetails123 saveCoinValue ----",
      this.props
    );

    let selectedCoinName = coinsList?.find(
      (data) => data?.coin_id == this.state.coinId
    )?.value;
    return (
      <div>
        <Header headerTitle="View Withdraw Limit" />
        {/* <Breadcrumb>
          <BreadcrumbItem>
            <Link to="order-management">Order Book Management</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>View Pair List</BreadcrumbItem>
        </Breadcrumb> */}
        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      {/* <Input
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>

                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>

                      <Dropdown
                        className="selectUserStyle btnMamagePermission"
                        options={UserFilter}
                        placeholder="Filter by order"
                        value={this.state.status1}
                        selection
                        onChange={(e, data) => this.listByUser(e, data)}
                      /> */}

                      <Button
                        color="success"
                        onClick={() => this.openModelSetting()}
                      >
                        Setting
                      </Button>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Coin Id</th>
                        <th scope="col">Coin Name</th>
                        <th scope="col">Limit</th>
                        {/* <th scope="col">Price In INR</th> */}
                        {/* <th scope="col">Min Price </th>
                        <th scope="col">Max Price </th> */}
                        {/* <th scope="col">Date</th> */}
                        <th scope=" col">Updated At</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.saveCoinValue?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.saveCoinValue?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data?.id}</td>
                            <td>
                              {data?.coin_id == 9
                                ? "INR"
                                : `${data?.coin?.coin_name}( ${
                                    data?.coin?.coin_symbol &&
                                    data?.coin?.coin_symbol?.toUpperCase()
                                  }
                              )`}
                            </td>
                            <td>{data.limit}</td>
                            {/* <td>{data.price_in_inr}</td> */}
                            {/* <td>{data.min ? data.min : "None"}</td>
                            <td>{data.max ? data.max : "None"}</td>
                            <td>
                              {data.status == "0" ? "Inactive" : "Active"}
                            </td> */}
                            <td>
                              {data.updated_at != ""
                                ? listDateFormat(data.updated_at)
                                : "None"}
                            </td>

                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color={"danger"}
                                  onClick={() => {
                                    // if (data.max > 0) {
                                    this.deleteCoinLimit(data);
                                    // } else {
                                    //   // this.EnableModal(data.id);
                                    //   this.openModel1(data);
                                    // }
                                  }}
                                  disabled={data?.coin_id == 9}
                                >
                                  Delete
                                  {/* {data.status == "1" ? "Disable" : "Enable"} */}
                                </Button>

                                <Button
                                  color="success"
                                  onClick={() => this.openModel2(data)}
                                >
                                  Update
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.onChangePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(this.props.total)}
                        />
                      </nav>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        <Modal isOpen={this.state.openModel1} toggle={this.toggle1}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Coin Name</label>
                  <Dropdown
                    className="selectUserStyle btnMamagePermission"
                    options={UserFilter}
                    placeholder="Select Coin"
                    value={this.state.status1}
                    selection
                    onChange={(e, data) => this.listByUser(e, data)}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Min</label>
                  <input
                    name="Min"
                    placeholder="Min"
                    value={this.state.min1}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ min1: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Max</label>

                  <input
                    name="max1"
                    placeholder="Max"
                    value={this.state.max1}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ max1: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel1: false })}
            >
              Cancel
            </Button>
            <Button
              color="success"
              // onClick={() => this.updateCoinValueClick()}
              onClick={() => this.deleteCoinLimit(this.state.setTotalData)}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.openModel2} toggle={this.toggle2}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Coin Name</label>
                  {this.state.coinId == 9 ? (
                    <Dropdown
                      className="selectUserStyle btnMamagePermission"
                      options={coinsListINR}
                      placeholder="Select Coin"
                      value={this.state.coinId}
                      selection
                      disabled={this.state.isUpdateCoin}
                      onChange={(e, data) => this.SelectCoinData(e, data)}
                    />
                  ) : (
                    <Dropdown
                      className="selectUserStyle btnMamagePermission"
                      options={coinsList}
                      placeholder="Select Coin"
                      value={selectedCoinName}
                      selection
                      disabled={this.state.isUpdateCoin}
                      onChange={(e, data) => this.SelectCoinData(e, data)}
                    />
                  )}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Limit</label>
                  <input
                    name="Limit"
                    placeholder="Limit"
                    value={this.state.limit}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ limit: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel2: false })}
            >
              Cancel
            </Button>
            <Button
              color="success"
              // onClick={() => this.updateCoinValueClick()}
              onClick={() => this.editCoinPairs()}
            >
              {this.state.isAdd ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.openModel} toggle={this.toggle}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Coin Name</label>

                  <input
                    name="coin_mname"
                    placeholder="Coin Name"
                    value={this.state.coinName}
                    type="text"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ coinName: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Min</label>
                  <input
                    name="Min"
                    placeholder="Min"
                    value={this.state.min}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ min: e.target.value })
                    }
                  ></input>
                  {/* <Dropdown
                    className="selectUserStyle"
                    placeholder="Min"
                    options={options}
                    selection
                    value={this.state.buyOrder}
                    onChange={(e, data) => this.sortForBuy(e, data)}
                  />{" "} */}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Max</label>

                  <input
                    name="max"
                    placeholder="Max"
                    value={this.state.max}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ max: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.addCoinValueClick()}>
              Add Pair
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log("saveCoinLimit --->>> state", state);
  return {
    getCoinList: state.user.getCoinList,
    coinsList: state.user.coinsList,
    totalCount: state.user.saveCoinLimitCount,
    saveCoinValue: state.user.saveCoinLimit, //list
    saveCoinLimit: state.user.saveCoinLimit, //list
    total: state.user.saveCoinLimitTotalPages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrencyList: (data) => dispatch(getCurrencyList(data)),
    listCoinsLimits: (data) => dispatch(listCoinsLimits(data)),
    getCoinList: (data) => dispatch(getCoinList()),
    updateCurrency: (data, data2) => dispatch(updateCurrency(data, data2)),
    deleteCoinLimit: (data, data2) => dispatch(deleteCoinLimit(data, data2)),
    updateCoinLimit: (data, data2) => dispatch(updateCoinLimit(data, data2)),
    addCoinValue: (data, id, data2) => dispatch(addCoinValue(data, id, data2)),
    updateCoinValue: (data, id, data2) =>
      dispatch(updateCoinValue(data, id, data2)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewOrderList)
);
