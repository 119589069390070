import { RevenueService } from "../../Services/RevenueServices";
import { toast } from "../../components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";

export const actionTypes = {
  SAVE_WITHDRAW_REVENUE: "SAVE_WITHDRAW_REVENUE",
  TOTAL_WITHDRAW_REVENUE: "TOTAL_WITHDRAW_REVENUE",
  SAVE_TOTAL_REVENUE: "SAVE_TOTAL_REVENUE",
  SAVE_TOTAL_WITHDRAW_REVENUE: "SAVE_TOTAL_WITHDRAW_REVENUE",
  SAVE_OTC_REVENUE: "SAVE_OTC_REVENUE",
  SAVE_REVENUE_GRAPH: "SAVE_REVENUE_GRAPH",
  SAVE_REVENUE_COINS: "SAVE_REVENUE_COINS",
  TOTAL_REVENUE_COINS:"TOTAL_REVENUE_COINS",
  SAVE_FIAT_REVENUE: "SAVE_FIAT_REVENUE",
  TOTAL_FIAT_REVENUE:"TOTAL_FIAT_REVENUE"
};

export function saveWithdrawRevenue(data) {
  return {
    type: actionTypes.SAVE_WITHDRAW_REVENUE,
    data,
  };
}
export function totalWithdrawRevenue(data) {
  return {
    type: actionTypes.TOTAL_WITHDRAW_REVENUE,
    data,
  };
}

export function saveTotalRevenue(data) {
  return {
    type: actionTypes.SAVE_TOTAL_REVENUE,
    data,
  };
}

export function saveTotalWithdrawRevenue(data) {
  return {
    type: actionTypes.SAVE_TOTAL_WITHDRAW_REVENUE,
    data,
  };
}

export function saveOtcRevenue(data) {
  return {
    type: actionTypes.SAVE_OTC_REVENUE,
    data,
  };
}

export function saveRevenueGraph(status) {
  return {
    type: actionTypes.SAVE_REVENUE_GRAPH,
    total_data: status,
  };
}
export function saveRevenueCoins(data) {
  return {
    type: actionTypes.SAVE_REVENUE_COINS,
    data
  };
}
export function totalRevenueRecords(data) {
  return {
    type: actionTypes.TOTAL_REVENUE_COINS,
    data,
  };
}

export function saveFiatRevenue(data) {
  return {
    type: actionTypes.SAVE_FIAT_REVENUE,
    data
  };
}
export function totalFiatRevenueRecords(data) {
  return {
    type: actionTypes.TOTAL_FIAT_REVENUE,
    data,
  };
}

//get withdraw revenue list
export function getWithdrawCoinList(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();

    return RevenueService.getRevenueList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveWithdrawRevenue(data.data.data));

        dispatch(totalWithdrawRevenue(data.data.meta.total));

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//get total coin revenue
export function getTotalCoinList(params) {
  return (dispatch, getState) => {

    let state = getState();

    return RevenueService.getTotalRevenue(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveTotalRevenue(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//swap revenue
export function getWithdrawRevenueFee(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return RevenueService.getWithdrawRevenue(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveTotalWithdrawRevenue(data.data.data));

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//otc revenue
export function getOtcRevenueFee(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return RevenueService.getOtcRevenue(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveOtcRevenue(data.data.data));

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//graph
export function totalRevenueGraph(type) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RevenueService.getRevenueGraph(type, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());

        dispatch(saveRevenueGraph(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
//get total coin revenue
export function getCoinList(params) {
  return (dispatch, getState) => {

    let state = getState();

    return RevenueService.getCoinList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveRevenueCoins(data.data.data));
        dispatch(totalRevenueRecords(data.data.meta.total));
        
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
//get fiat amount revenue
export function getFiatRevenueList(params) {
  return (dispatch, getState) => {

    let state = getState();

    return RevenueService.getFiatRevenueList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveFiatRevenue(data.data.data));
        dispatch(totalFiatRevenueRecords(data.data.meta.total));
        
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

