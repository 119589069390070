import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const getAllSubAdmin = (params, options) => {
  return fetch("get", `${API_HOST}api/v1/subadmin/list`, {}, options, params);
};
const removeSubAdmin = (id, options) => {
  return fetch(
    "delete",
    `${API_HOST}api/v1/subadmin/delete/${id}`,
    {},
    options
  );
};
const enableDisableSubAdmin = (data, id, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/subadmin/disabled/${id}`,
    data,
    options
  );
};
const getAdminPermissions = (options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/subadmin/admin_permissions`,
    {},
    options
  );
};
const assignAdminPermissions = (data, id, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/subadmin/assign_permissions/${id}`,
    data,
    options
  );
};
const getSubAdminPermissions = (options) => {
  return fetch("get", `${API_HOST}api/v1/admin/getSubadminModule`, {}, options);
};

const addSubAdmin = (data, options) => {
  return fetch("post", `${API_HOST}api/v1/subadmin/add`, data, options);
};

const getLogReport = (params, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/utility/action_logs`,
    params,
    options
  );
};

export const SubAdminService = {
  getAllSubAdmin,
  removeSubAdmin,
  enableDisableSubAdmin,
  getAdminPermissions,
  assignAdminPermissions,
  addSubAdmin,
  getSubAdminPermissions,
  getLogReport,
};
