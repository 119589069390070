import { actionTypes } from "../actons/user.action";

const initialState = {
  meta: {},
  user: {},
  secondaryCoinList: [],
  coinsList: [],
  coinsListSetting: [],
  coinsListMeta: {},
  coinsListFiltered: [],
  LogoCoins: [],
  CurrencyList: [],
  saveCoinValues: [],
  saveCoinValuesCount: {},
  saveCoinValuesTotalPages: 1,
  saveCoinLimit: [],
  saveCoinLimitCount: {},
  saveviewPair: {},
  saveCoinLimitTotalPages: 1,
  saveorderbookList: [],
  saveorderbookpairlist: [],
  saveorderbookpairmeta: {},
  saveorderbookFee: {},
  savevieworderbook: {},
  savePlanData: {},
  savecoinmarketcap: {},
  savecoingecko: {},
  tradeSettingList: [],
  tradeHistoryList: [],
  totalRecords: "",
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.ENABLE_DISABLE_GOOGLE_AUTH:
      state.user.google2fa_secret = action.google_secret_key;

      if (action.user === "enable") {
        state.user.google2fa_status = 1;
        return { ...state };
      } else {
        state.user.google2fa_status = 0;
        return { ...state };
      }
    case actionTypes.SAVE_USER_GOOGLE_2FA_STATUS: {
      let user = { ...state.user };
      user.google2fa_status = action.data.google2fa_status;
      user.google2fa_secret = action.data.google2fa_secret;

      return {
        ...state,
        user: user,
      };
    }
    case actionTypes.SAVE_USER_ACTIVE_COIN_LIST:
      var coin = action.user;
      var Final = [];
      var filterd = [];
      var logoCoins = [];
      // console.log("coinLIst", coin);
      coin.forEach((element) => {
        element.value = element.coin_id;

        // 1=eth, 2=btc, 3=bnb, 4=trx

        if (element.is_token == 1) {
          if (element.coin_family == 1) {
            element.text = element.coin_symbol.toUpperCase() + "(ERC-20)";
          } else if (element.coin_family == 3) {
            element.text = element.coin_symbol.toUpperCase() + "(BEP-20)";
          } else if (element.coin_family == 4) {
            element.text = element.coin_symbol.toUpperCase() + "(TRC-20)";
          } else if (element.coin_family == 5) {
            element.text = element.coin_symbol.toUpperCase() + "(MATIC)";
          } else {
            element.text = element.coin_symbol.toUpperCase();
          }
        } else {
          element.text = element.coin_symbol.toUpperCase();
        }

        Final.push(element);
      });
      coin.forEach((element) => {
        if (element.coin_name) {
          if (element.coin_name !== "Orbs") {
            filterd.push(element);
            logoCoins.push(element);
          } else {
            logoCoins.push(element);
          }
        }
      });

      return {
        ...state,
        activeCointList: Final,
      };

    case actionTypes.SAVE_USER_COIN_LIST:
      var coin = action.user;
      var Final = [];
      var filterd = [];
      var logoCoins = [];
      // console.log("coinLIst", coin);
      coin.forEach((element) => {
        element.value = element.coin_id;

        // 1=eth, 2=btc, 3=bnb, 4=trx

        if (element.is_token == 1) {
          if (element.coin_family == 1) {
            element.text = element.coin_symbol.toUpperCase() + "(ERC-20)";
          } else if (element.coin_family == 3) {
            element.text = element.coin_symbol.toUpperCase() + "(BEP-20)";
          } else if (element.coin_family == 4) {
            element.text = element.coin_symbol.toUpperCase() + "(TRC-20)";
          } else if (element.coin_family == 5) {
            element.text = element.coin_symbol.toUpperCase() + "(MATIC)";
          } else {
            element.text = element.coin_symbol.toUpperCase();
          }
        } else {
          element.text = element.coin_symbol.toUpperCase();
        }

        Final.push(element);
      });
      coin.forEach((element) => {
        if (element.coin_name) {
          if (element.coin_name !== "Orbs") {
            filterd.push(element);
            logoCoins.push(element);
          } else {
            logoCoins.push(element);
          }
        }
      });
      let secondaryCoinList = [];
      coin.forEach((element) => {
        element.value = element.coin_symbol.toUpperCase();
        secondaryCoinList.push(element);
      });
      return {
        ...state,
        coinsList: Final,
        secondaryCoinList: secondaryCoinList,
        coinsListFiltered: filterd,
        LogoCoins: logoCoins,
      };
    case actionTypes.SAVE_USER_COIN_LIST_SETTING:
      console.log("------ >>> coin", action);
      var coin = action.user;
      console.log(coin, "------ >>> coin", action);
      var Final = [];
      var filterd = [];
      var logoCoins = [];
      // console.log("coinLIst", coin);
      coin.data.forEach((element) => {
        element.value = element.coin_id;

        // 1=eth, 2=btc, 3=bnb, 4=trx

        if (element.is_token == 1) {
          if (element.coin_family == 1) {
            element.text = element.coin_symbol.toUpperCase() + "(ERC-20)";
          } else if (element.coin_family == 3) {
            element.text = element.coin_symbol.toUpperCase() + "(BEP-20)";
          } else if (element.coin_family == 4) {
            element.text = element.coin_symbol.toUpperCase() + "(TRC-20)";
          } else if (element.coin_family == 5) {
            element.text = element.coin_symbol.toUpperCase() + "(MATIC)";
          } else {
            element.text = element.coin_symbol.toUpperCase();
          }
        } else {
          element.text = element.coin_symbol.toUpperCase();
        }

        console.log(coin, "------ >>> coin", element);
        Final.push(element);
      });
      coin.data.forEach((element) => {
        if (element.coin_name) {
          if (element.coin_name !== "Orbs") {
            filterd.push(element);
            logoCoins.push(element);
          } else {
            logoCoins.push(element);
          }
        }
      });

      console.log(coin, "------ >>> coin", coin.meta);
      return {
        ...state,
        coinsListSetting: Final,
        coinsListMeta: coin.meta,
        coinsListFiltered: filterd,
        LogoCoins: logoCoins,
      };

    case actionTypes.SAVE_CURRENCY_LIST:
      // console.log("action", action);
      var coin = action.data.history;
      // var coin = action.data.currency_list.rows;
      var Final = [];
      var filterd = [];
      var logoCoins = [];
      coin.forEach((element) => {
        // console.log("element", element);
        element.value = element.coin_id;

        // 1=eth, 2=btc, 3=bnb, 4=trx

        if (element.is_token == 1) {
          if (element.coin_family == 1) {
            element.text = element.name.toUpperCase() + "(ERC-20)";
          } else if (element.coin_family == 3) {
            element.text = element.name.toUpperCase() + "(BEP-20)";
          } else if (element.coin_family == 4) {
            element.text = element.name.toUpperCase() + "(TRC-20)";
          } else if (element.coin_family == 5) {
            element.text = element.name.toUpperCase() + "(MATIC)";
          } else {
            element.text = element.name.toUpperCase();
          }
        } else {
          element.text = element.name.toUpperCase();
        }

        Final.push(element);
      });

      coin.forEach((element) => {
        if (element.coin_name) {
          if (element.coin_name !== "Orbs") {
            filterd.push(element);
            logoCoins.push(element);
          } else {
            logoCoins.push(element);
          }
        }
      });

      return {
        ...state,
        CurrencyList: Final,
        coinsListFiltered: filterd,
        LogoCoins: logoCoins,
        // totalRecords: action.data.currency_list.count
        totalRecords: action.data.totalRecords,
      };

    case actionTypes.SAVE_TRADE_SETTINGS:
      return {
        ...state,
        tradeSettingList: action.user,
      };
    case actionTypes.SAVE_COIN_MARKET_CAP:
      return {
        ...state,
        savecoinmarketcap: action.user,
      };
    case actionTypes.SAVE_COIN_GECKO:
      return {
        ...state,
        savecoingecko: action.user,
      };
    case actionTypes.SAVE_TRADE_HISTORY:
      return {
        ...state,
        tradeHistoryList: action.user,
      };
    case actionTypes.SAVE_ORDER_SETTING:
      return {
        ...state,
        saveOrderDetails: action.user,
      };
    case actionTypes.SAVE_VIEW_PAIR:
      return {
        ...state,
        saveviewPair: action.user,
      };
    case actionTypes.SAVE_COIN_VALUE:
      return {
        ...state,
        saveCoinValues: action.data.statusCode == 200 ? action.data.data : [],
        saveCoinValuesCount: action.data.meta,
        saveCoinValuesTotalPages:
          action.data.statusCode == 200 ? action.data.meta.pages : 1,
      };
    case actionTypes.SAVE_COIN_LIMIT:
      return {
        ...state,
        saveCoinLimit: action.data.data.rows,
        saveCoinLimitCount: action.data.data.count,
      };
    case actionTypes.SAVE_ORDER_BOOK_LIST:
      return {
        ...state,
        saveorderbookList: action,
      };
    case actionTypes.SAVE_ORDER_BOOK_PAIR_LIST:
      return {
        ...state,
        saveorderbookpairlist: action.data.data,
        saveorderbookpairmeta: action.data.meta,
      };
    case actionTypes.SAVE_ORDER_BOOK_FEE:
      return {
        ...state,
        saveorderbookFee: action,
      };
    case actionTypes.SAVE_VIEW_ORDER_BOOK:
      return {
        ...state,
        savevieworderbook: action.data.data,
      };
    case actionTypes.SAVE_PLAN_DATA:
      return {
        ...state,
        savePlanData: action,
      };
    case actionTypes.SAVE_USER_COLD_WALLET_GOOGLE_2FA_STATUS: {
      let user = { ...state.user };
      user.cold_wallet_google2fa_secret =
        action.data.cold_wallet_google2fa_secret;
      user.cold_wallet_google2fa_status =
        action.data.cold_wallet_google2fa_status;

      return {
        ...state,
        user: user,
      };
    }
    case actionTypes.ENABLE_DISABLE_COLD_WALLET_GOOGLE_AUTH:
      state.user.cold_wallet_google2fa_secret = action.google_secret_key;

      if (action.user === "enable") {
        state.user.cold_wallet_google2fa_status = 1;
        return { ...state };
      } else {
        state.user.cold_wallet_google2fa_status = 0;
        return { ...state };
      }
    default:
      return state;
  }
};

export default user;
