import React, { useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { WalletAdapterNetwork, WalletError } from "@solana/wallet-adapter-base";
import { NETWORK_TYPE_SOLANA } from "../../constants/index";
import { clusterApiUrl } from "@solana/web3.js";
import {
  CoinbaseWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";

const ConnectSolana = ({ children }) => {
  const network1 = WalletAdapterNetwork;
  const network = WalletAdapterNetwork[NETWORK_TYPE_SOLANA];
  //   const network = WalletAdapterNetwork.Devnet;
  console.log(
    clusterApiUrl(network),
    "clusterApiUrl(network) ---- network ---- ",
    network,
    network1
  );
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new CoinbaseWalletAdapter(),
      new SlopeWalletAdapter(),
      new TorusWalletAdapter(),
      new SolflareWalletAdapter({ network }),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect={false}>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default ConnectSolana;
