import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../../components/FormField";
import { SelectField } from "../../../components/SelectField";
import { required } from "redux-form-validators";
import { amountLimit } from "../../../validators/customValidator";
import { saveReferralPair } from "../../../Redux/actons/referral.action";
import { getCoinList } from "../../../Redux/actons/user.action";
import Header from "components/Headers/Header";
import { reset } from "redux-form";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";

const options = [
  // { key: "m", text: "Deposit Crypto", value: "deposit_crypto" },
  { key: "n", text: "Sign Up", value: "sign_up" },
  { key: "n", text: "Deposit Fiat", value: "deposit_fiat" },
  { key: "k", text: "Kyc", value: "kyc" },
  { key: "l", text: "First Trade", value: "first_trade" },
];

const requiredOpt = [
  { key: "m", text: "True", value: "true" },
  { key: "n", text: "False", value: "false" },
];

export class AddReferral extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //   isCoin: false,
      id: "",
      defaultType: "",
      defaultAction: 1,
      referralAmount: "",
      referralCoin: "",
      planType: "deposit_fiat",
      requiredMinAmount: "false",
      minDeposit: "0",
      isEdit: false,
    };
  }
  componentDidMount = async () => {
    this.props.getCoinList();
    console.log("pushed", this.props.location.state);
    if (this.props.location.state === undefined) {
    } else {
      this.setState({
        referralAmount: this.props.location.state.referral_amount,
        referralCoin: this.props.location.state.referral_coin,
        planType: this.props.location.state.plan_type,
        minDeposit: this.props.location.state.min_amount,
        isEdit: true,
      });
    }
  };

  getDetails = (e, data) => {
    this.setState({ defaultType: data });
  };

  updateForm = (values) => {
    const formData = {
      // referral_amount: this.state.referralAmount,
      // referral_coin: this.state.referralCoin,
      plan_type: this.state.planType,
      min_amount:
        this.state.planType != "deposit_fiat" ? "0" : this.state.minDeposit,
      require_min_amount:
        this.state.planType != "deposit_fiat" ? "false" : "true",
    };
    this.props.saveReferralPair(formData);
    // }
    this.props.reset("addReferralForm");
    this.props.history.goBack();
  };
  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <div>
          <Header
            headerTitle={this.state.isEdit ? "Edit Referral" : "Add Referral"}
          />
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="referral-management">Referral Management</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.state.isEdit ? "Edit Referral" : "Add Referral"}
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <div style={{ padding: 30 }}>
          <Form onSubmit={handleSubmit(this.updateForm)}>
            <Form.Group>
              <Form.Field>
                <label>Plan Type</label>
                <Dropdown
                  className="selectUserStyle"
                  options={options}
                  selection
                  value={this.state.planType}
                  onChange={(e, data) =>
                    this.setState({ planType: data.value })
                  }
                />
              </Form.Field>
            </Form.Group>

            {this.state.planType == "deposit_fiat" && (
              <Form.Group>
                <Form.Field>
                  <label>Minimum Amount</label>
                  <input
                    type="number"
                    name="min_deposit"
                    min="0"
                    value={
                      this.state.minDeposit !== null ? this.state.minDeposit : 0
                    }
                    validate={[required(), amountLimit()]}
                    component={FormField}
                    onChange={(e, data) =>
                      this.setState({ minDeposit: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            )}

            {/* {this.state.isCoin && ( */}
            <Form.Field className="addBtn">
              <Button type="submit" color="success">
                Submit
              </Button>
            </Form.Field>
            {/* // )} */}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coinsList: state.user.coinsList,
    pairCoin: state.pairCoinList.pairCoin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCoinList: () => dispatch(getCoinList()),
    saveReferralPair: (data) => dispatch(saveReferralPair(data)),
    reset: (data) => dispatch(reset(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "addReferralForm" })(AddReferral));
