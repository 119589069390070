import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { getReferralHistory } from "../../../Redux/actons/referral.action";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import {
  Dropdown,
  Pagination,
  Confirm,
  GridColumn,
  Grid,
} from "semantic-ui-react";
import { DashboardResult } from "views/ContactQueryManagement/SubjectManagement/DashboardResult";

import { Link } from "react-router-dom";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

export class ReferralHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      sortBy: "",
      order: "desc",
    };
  }
  componentDidMount = () => {
    this.getHistoryList();
  };

  getHistoryList = () => {
    let data = {
      search: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      // filterType: this.state.filterType,
    };
    this.props.getReferralHistory(data);
  };

  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getHistoryList();
    });
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getHistoryList();
    });
  };

  render() {
    let { handleSubmit, dashboard } = this.props;
    console.log("props", dashboard);
    return (
      <div>
        <Header headerTitle="Referral History" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="referral-management">Referral Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Referral History</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Card className="shadow">
                <Grid.Row
                  columns={2}
                  // style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Total Referral"}
                      labResult={
                        dashboard?.completeList
                          ? dashboard?.completeList + dashboard?.pendingList
                          : "0"
                      }
                    />
                  </GridColumn>
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Completed Referral"}
                      labResult={
                        dashboard?.completeList ? dashboard?.completeList : "0"
                      }
                    />
                  </GridColumn>

                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Pending Referral"}
                      labResult={
                        dashboard?.pendingList ? dashboard?.pendingList : "0"
                      }
                    />
                  </GridColumn>
                </Grid.Row>
              </Card>
            </div>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search by email"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.getHistoryList();
                              }
                            }
                          );
                        }}
                      ></Input>

                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.getHistoryList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Coin</th>
                        <th scope=" col">Plan Type</th>
                        <th scope="col">Referral amount</th>
                        <th scope="col">Referred By</th>
                        <th scope="col">Referred To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.getReferalHistoryList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.getReferalHistoryList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.coin_symbol.toUpperCase()}</td>
                            <td>{data.plan_type.toUpperCase()}</td>
                            <td>
                              {parseFloat(data.referral_amount).toFixed(4)}
                            </td>
                            <td>{data.referred_by_email}</td>
                            <td>{data.referred_to_email}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    dashboard: state?.referralList?.referralHistory,
    getReferalHistoryList:
      state?.referralList?.referralHistory?.referral_History?.history,
    total: state?.referralList?.referralHistory?.referral_History?.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReferralHistory: (data) => dispatch(getReferralHistory(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReferralHistory)
);
