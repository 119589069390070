import { TransactionService } from "../../Services/TransactionServices";
import { toast } from "../../components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";
import { DownLoadCSVFunction } from "./installment.action";

export const actionTypes = {
  SAVE_TRANSACTION_LIST: "SAVE_TRANSACTION_LIST",
  SAVE_TRANSACTION_DETAIL: "SAVE_TRANSACTION_DETAIL",

  TOTAL_TRANSACTION_RECORDS: "TOTAL_TRANSACTION_RECORDS",
  SAVE_DAILY_WITHDRAW_LIMIT: "SAVE_DAILY_WITHDRAW_LIMIT",
  SAVE_GST_LIMIT: "SAVE_GST_LIMIT",
  SAVE_TDS_LIMIT: "SAVE_TDS_LIMIT",
  SAVE_TXN_WALLET_THRESHOLD_VALUE: "SAVE_TXN_WALLET_THRESHOLD_VALUE",
};

export function saveTransactionList(data) {
  return {
    type: actionTypes.SAVE_TRANSACTION_LIST,
    data,
  };
}
export function totalTransactionRecords(data) {
  return {
    type: actionTypes.TOTAL_TRANSACTION_RECORDS,
    data,
  };
}

export function saveTxnDetails(data) {
  return {
    type: actionTypes.SAVE_TRANSACTION_DETAIL,
    data,
  };
}

export function saveDailyWithdrawLimit(data) {
  return {
    type: actionTypes.SAVE_DAILY_WITHDRAW_LIMIT,
    data,
  };
}
export function setThresholdValues(data) {
  return {
    type: actionTypes.SAVE_TXN_WALLET_THRESHOLD_VALUE,
    data,
  };
}

export function saveGSTLimit(data) {
  return {
    type: actionTypes.SAVE_GST_LIMIT,
    data,
  };
}

export function saveTDSLimit(data) {
  return {
    type: actionTypes.SAVE_TDS_LIMIT,
    data,
  };
}

//get transaction fee

export function getTransactionListCSV(data) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return TransactionService.geTransactionList(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        console.log("Download ----", data);
        let arrayOfObjects = data?.data.data;
        if (!arrayOfObjects?.length) {
          return;
        }
        DownLoadCSVFunction(arrayOfObjects, "CSV_download_transaction_list");

        // dispatch(saveTransactionList(data.data));
        // dispatch(totalTransactionRecords(data.data.meta.total));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getTransactionList(data) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return TransactionService.geTransactionList(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());

        dispatch(saveTransactionList(data.data));
        dispatch(totalTransactionRecords(data.data.meta.total));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function sendfailureMessage(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TransactionService.sendMessage(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        toast.success(data["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getTransactionDetails(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TransactionService.getTransactionDetails(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveTxnDetails(res.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getDailyWithdrawLimit(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TransactionService.getDailyWithdrawLimit(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveDailyWithdrawLimit(res.data.data));
      })
      .catch((error) => {
        // if (error) {
        //   toast.error(error["data"]["message"]);
        // }
        dispatch(stopLoading());
      });
  };
}
export function getTxnWalletThresholdValue(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TransactionService.gettxnWalletThresholdValue(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    }).then(async (res) => {
      dispatch(stopLoading());
      dispatch(setThresholdValues(res.data.data));
    });
  };
}

export function updateTxnWalletThresholdValues(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TransactionService.updateTxnWalletThresholdValues(
      { data: data },
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      }
    )
      .then(async (data) => {
        dispatch(stopLoading());
        toast.success(data["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function setDailyWithdrawLimit(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TransactionService.setDailyWithdrawLimit(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        toast.success(data["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getGSTLimit(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TransactionService.getGSTLimit(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveGSTLimit(res.data.data));
        return res.data.data;
      })
      .catch((error) => {
        // if (error) {
        //   toast.error(error["data"]["message"]);
        // }
        dispatch(stopLoading());
      });
  };
}
export function setGSTLimit(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TransactionService.setGSTLimit(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        toast.success(data["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//TDS

export function getTDSLimit(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TransactionService.getGSTLimit(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveTDSLimit(res.data.data));
      })
      .catch((error) => {
        // if (error) {
        //   toast.error(error["data"]["message"]);
        // }
        dispatch(stopLoading());
      });
  };
}
export function setTDSLimit(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return TransactionService.setGSTLimit(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        toast.success(data["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
