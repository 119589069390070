import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  userDailyInterestList,
  deleteInterestPair,
} from "../../../Redux/actons/interest.action";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Pagination, Confirm } from "semantic-ui-react";
import { listDateFormat } from "utils/utils";

export class ViewUserInterestHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      openConfirm: false,
      itemId: "",
    };
  }
  componentDidMount = () => {
    this.userDailyInterestList();
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.userDailyInterestList();
    });
  };
  userDailyInterestList = () => {
    const data = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      order: this.state.order,
      // coin_id: this.state.selectedCoin,
      status: "staked",
    };
    let id = window.location.hash.split("?")[1];
    this.props.userDailyInterestList(data, id);
  };

  onEditPair = (id, data) => {
    this.props.history.push({
      pathname: `edit-interest/${id}`,
      state: data,
    });
  };

  openDeleteModal = (id) => {
    this.setState({
      openConfirm: true,
      itemId: id,
    });
  };

  close = () => {
    this.setState({ openConfirm: false });
  };

  onConfirmation = () => {
    this.props.deleteInterestPair(this.state.itemId);
    this.close();
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="View Daily Interest History " />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="interest-user-list">User Interest Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Daily Interest History</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Confirm
            content={`Are you sure to delete this ?`}
            style={{
              height: "auto",
              top: "5%",
              left: "auto",
              bottom: "auto",
              right: "auto",
            }}
            size="small"
            className="confirm-model"
            open={this.state.openConfirm}
            onCancel={this.close}
            onConfirm={this.onConfirmation}
          />
        </div>
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      {/* <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      /> */}

                      {/* <Link to="add-interest">
                        <Button color="success btnMamagePermission">
                          Add Interest
                        </Button>
                      </Link> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Currency</th>
                        <th scope="col">Email</th>
                        {/* <th scope="col">Status</th> */}
                        {/* <th scope="col">Amount</th> */}
                        <th scope="col"> Interest</th>
                        <th scope="col">Date</th>
                        {/* <th scope="col">Actions</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.InterestList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.InterestList &&
                        this.props.InterestList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data?.coin?.coin_name}</td>
                            <td>{data?.user?.email}</td>
                            {/* <td>{data?.status}</td> */}
                            {/* <td>{data?.amount}</td> */}
                            <td>{data?.interest_amount}</td>
                            {/* <td>
                              {data.created_at
                                ? listDateFormat(data.created_at)
                                : "None"}
                            </td> */}
                            <td>
                              {data.updated_at
                                ? listDateFormat(data.updated_at)
                                : "None"}
                            </td>
                            {/* <td>Testings</td> */}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(this.props.total)}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    InterestList: state.currencyInterest?.dailyInterestList,
    total: state?.currencyInterest?.dailyInterestMeta?.pages,
    // totalRecords: state.currencyInterest.dailyInterestMeta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userDailyInterestList: (data, id) =>
      dispatch(userDailyInterestList(data, id)),
    deleteInterestPair: (data) => dispatch(deleteInterestPair(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewUserInterestHistory)
);
