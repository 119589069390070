import React, { Component } from "react";
// import { Modal, Grid, Button, List, GridColumn } from "semantic-ui-react";
import {
  Card,
  Button,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  CardImg,
  Container,
  Row,
  Col,
} from "reactstrap";

import iconGoogle from "../../../images/googleAuthentication_en.png";
import iconGoogleDisable from "../../../images/googleAuthentication_en.png";

import { connect } from "react-redux";
import GoogleAuthStepOne from "./GoogleAuthStepOne";
import GoogleAuthStepTwo from "./GoogleAuthStepTwo";
import GoogleAuthStepThree from "./GoogleAuthStepThree";
import {
  enableDisableGoogleAuth,
  getGoogleImageSecretKey,
  openGoogleDisableModal,
  openGoogleEnableModal,
} from "../../../Redux/actons/account.action";
import GoogleAuthDisable from "./GoogleAuthDisable";
import { MDBCloseIcon } from "mdbreact";
import { getGoogleStatus } from "../../../Redux/actons/user.action";

export class GoogleAuthActive extends Component {
  componentDidMount = () => {
    this.props.getGoogleStatus();
  };
  enableGoogleAuth = (values) => {
    values["action"] = 1;
    values["google_secret_key"] = this.props.googleAuthKeys.google_secret_key;
    this.props.enableDisableGoogleAuth(values, "enable");
  };

  disableGoogleAuth = (values) => {
    values["action"] = 0;
    values["google_secret_key"] = this.props.user.google2fa_secret;
    this.props.enableDisableGoogleAuth(values, "disable");
  };

  openModal = (isEnable) => {
    if (isEnable) {
      this.props.openGoogleDisableModal(true);
    } else {
      let data={
        issueQr:"tarality"
      }
      this.props.getGoogleImageSecretKey(data);
    }
  };
  close = () => {
    this.props.openGoogleDisableModal(false);
    this.props.openGoogleEnableModal(false);
    this.props.goToPrevStep(2);
  };

  render() {
    let {
      user,
      step,
      openGoogleModalEnable,
      openGoogleModalDisable,
    } = this.props;
    console.log("USER", user);

    return (
      <div>
        <Card body>
          <CardImg
            src={user.google2fa_status ? iconGoogle : iconGoogleDisable}
          />
          <CardTitle>Google Authentication</CardTitle>
          <Button
            color="info"
            onClick={() => this.openModal(user.google2fa_status)}
          >
            {user.google2fa_status ? "Disable" : "Enable"}
          </Button>

          <Modal
            isOpen={openGoogleModalEnable}
            closeOnDimmerClick={false}
            onClosed={this.close}
            className={[this.props.className, "modalCustom"]}
          >
            <ModalHeader>
              <h1>Enable Google Authentication</h1>
              <MDBCloseIcon
                onClick={this.close}
                floated="right"
                className="popUpClose"
              />
            </ModalHeader>
            <ModalBody className="authPopUpBody">
              {step === 1 && <GoogleAuthStepOne></GoogleAuthStepOne>}
              {step === 2 && (
                <GoogleAuthStepTwo
                  goToPrevStep={this.props.goToPrevStep}
                ></GoogleAuthStepTwo>
              )}
              {step === 3 && (
                <GoogleAuthStepThree
                  onSubmit={this.enableGoogleAuth}
                  goToPrevStep={this.props.goToPrevStep}
                ></GoogleAuthStepThree>
              )}

              {step === 2 && (
                <Button
                  color="secondary"
                  floated="left"
                  onClick={() => this.props.goToPrevStep(step)}
                >
                  Previous
                </Button>
              )}

              {(step === 1 || step === 2) && (
                <Button
                  color="info"
                  type="button"
                  floated="right"
                  positive
                  onClick={() => this.props.goToStep(step)}
                >
                  Continue
                </Button>
              )}
            </ModalBody>
          </Modal>
          {/* disable google modal */}
          <Modal
            size="tiny"
            closeIcon
            isOpen={openGoogleModalDisable}
            closeOnDimmerClick={false}
            onClosed={this.close}
          >
            <ModalHeader>Disable Google Authentication</ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col>
                    <GoogleAuthDisable
                      close={this.close}
                      onSubmit={this.disableGoogleAuth}
                    ></GoogleAuthDisable>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log("googleAuthKeys111111", state);
  return {
    user: state.user.user,
    isGoogleForm: state.account.isGoogleAuthForm,
    step: state.account.googleStep,
    googleAuthKeys: state.account.googleAuthKeys,
    openGoogleModalDisable: state.account.openGoogleModalDisable,
    openGoogleModalEnable: state.account.openGoogleModalEnable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    enableDisableGoogleAuth: (data, type) =>
      dispatch(enableDisableGoogleAuth(data, type)),
    getGoogleImageSecretKey: (data) => dispatch(getGoogleImageSecretKey(data)),
    openGoogleDisableModal: (status) =>
      dispatch(openGoogleDisableModal(status)),
    openGoogleEnableModal: (status) => dispatch(openGoogleEnableModal(status)),
    getGoogleStatus: () => dispatch(getGoogleStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAuthActive);
