import React, { Component } from "react";
import { MDBCloseIcon } from "mdbreact";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { FormField } from "../../../components/FormField";
import iconPassword from "../../../images/icon_password_enabled.png";
import iconGoogleDisable from "../../../images/googleAuthentication_en.png";
import {
  passwordValidator,
  matchPassword
} from "../../../validators/customValidator";
import { required, email } from "redux-form-validators";
import { openChangePasswordModal } from "../../../Redux/actons/account.action";
import {
  Card,
  Button,
  CardTitle,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardImg,
  Container,
  Row,
  Col,
  Form
} from "reactstrap";

export class ChangePassword extends Component {
  openModal = () => {
    this.props.openChangePasswordModal(true);
  };
  closeModal = () => {
    this.props.openChangePasswordModal(false);
  };
  render() {
    // let { handleSubmit, user,  } = this.props;
    let { handleSubmit, changePasswordModalOpen } = this.props;

    return (
      <div>
        <Card body>
          <CardImg src={iconPassword} />
          <CardTitle>Password</CardTitle>

          <Button color="info" className={"is_active"} onClick={this.openModal}>
            Change Password
          </Button>

          <Modal
            size="tiny"
            isOpen={changePasswordModalOpen}
            closeOnDimmerClick={false}
            onClosed={this.cc}
            className={[this.props.className, "modalCustom"]}
          >
            <ModalHeader>
              <h1>Change Password</h1>
              <MDBCloseIcon
                onClick={this.closeModal}
                floated="right"
                className="popUpClose"
              />
            </ModalHeader>

            <ModalBody className="authPopUpBody">
              <Row columns={1} className="stepThree">
                <Col>
                  <Form onSubmit={handleSubmit}>
                    <label>Change Password</label>

                    <Field
                      name="old_password"
                      // size="small"
                      component={FormField}
                      type="password"
                      placeholder="Old Password"
                      validate={[required()]}
                      autoFocus
                    />
                    <label>New Password</label>

                    <Field
                      name="new_password"
                      component={FormField}
                      type="password"
                      placeholder="New Password"
                      validate={[
                        required(),
                        passwordValidator(),
                        matchPassword()
                      ]}
                    />

                    <label>Confirm Password</label>

                    <Field
                      name="confirm_password"
                      component={FormField}
                      type="password"
                      placeholder="Confirm Password"
                      validate={[
                        required(),
                        passwordValidator(),
                        matchPassword()
                      ]}
                    />

                    <Row columns={2} style={{ marginTop: "20px" }}>
                      <Col className="mr0">
                        <Button color="secondary" type="submit" floated="left">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
    isEmailForm: state.account.isEmailAuthForm,
    changePasswordModalOpen: state.account.changePasswordModalOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openChangePasswordModal: open => dispatch(openChangePasswordModal(open))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "ResetPasswordForm" })(ChangePassword));
