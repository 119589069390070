import { UserGuideServices } from "../../Services/UserGuideServices";
import { toast } from "../../components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";
import { reset } from "redux-form";

export const actionTypes = {
  GET_USER_GUIDE_DETAILS: "GET_USER_GUIDE_DETAILS",
  // TOTAL_USER_GUIDE_RECORDS: "TOTAL_USER_GUIDE_RECORDS",
//   GET_ANNOUNCE_DETAIL: "GET_ANNOUNCE_DETAIL",
//   SAVE_EDIT_DETAIL: "SAVE_EDIT_DETAIL"
};
// export function saveAnnounceEditDetails(data) {
//   return {
//     type: actionTypes.SAVE_EDIT_DETAIL,
//     data
//   };
// }

export function saveUserGuideDetails(data) {
  return {
    type: actionTypes.GET_USER_GUIDE_DETAILS,
    data
  };
}
export function totalUserGuideRecords(data) {
  return {
    type: actionTypes.TOTAL_USER_GUIDE_RECORDS,
    data
  };
}

// export function getAnnounceDetailId(data) {
//   return {
//     type: actionTypes.GET_ANNOUNCE_DETAIL,
//     data: data
//   };
// }

//get Announcement list
export function getGuideScreenList(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();
    return UserGuideServices.getGuideScreenList(params, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async data => {
        dispatch(saveUserGuideDetails(data.data.data));
        // dispatch(totalUserGuideRecords(data.data.meta.total));

        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          // toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// add anouncement
export function addUserGuideImage(data,history) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserGuideServices.addUserGuideImage(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        toast.success(res.data.message);

        // dispatch(reset("addUserGuideImageForm")); // requires form name

        history.push("user-guide");       

        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}






//get details
// export function getAnnouncementDetails(data) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());

//     let state = getState();
//     return AnnouncementService.getAnnouncementDetail(data, {
//       jwt: state["persist"]["c_temp_user"]["token"]
//     })
//       .then(async data => {
//         dispatch(getAnnounceDetailId(data.data.data));

//         dispatch(stopLoading());
//       })
//       .catch(error => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }
// add anouncement
// export function addAnnouncement(data) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return AnnouncementService.addAnnouncement(data, {
//       jwt: state["persist"]["c_temp_user"]["token"]
//     })
//       .then(async res => {
//         toast.success(res.data.message);
//         dispatch(reset("AddAnnouncementForm")); // requires form name

//         dispatch(stopLoading());
//       })
//       .catch(error => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }
// //delete announcement
// export function deleteAnnouncement(data, id) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return AnnouncementService.deleteAnnouncement(id, {
//       jwt: state["persist"]["c_temp_user"]["token"]
//     })
//       .then(async res => {
//         toast.success(res.data.message);
//         dispatch(getAnnouncementList(data));
//         dispatch(stopLoading());
//       })
//       .catch(error => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

//update
export function changeGuideStatus(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserGuideServices.changeGuideStatus(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        toast.success(res.data.message);
        // history.push("User-guide");       
        dispatch(getGuideScreenList(data));
        // history.push("announcement-list");
        
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          // toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// //send notification

// export function sendAnnouncementNotification(data, id, history) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return AnnouncementService.sendAnnouncement(data, id, {
//       jwt: state["persist"]["c_temp_user"]["token"]
//     })
//       .then(async res => {
//         toast.success(res.data.message);
//         // history.push("announcement-list");
//         dispatch(stopLoading());
//       })
//       .catch(error => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }
