import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import {
  getCurrencyList,
  updateCurrency,
  updateRate,
} from "../../Redux/actons/user.action";
import { Pagination, Form, Dropdown } from "semantic-ui-react";
import Header from "components/Headers/Header";
import { withRouter, Link } from "react-router-dom";

const options = [
  { key: "m", text: "Upgrade", value: "upgrade" },
  { key: "k", text: "Downgrade", value: "downgrade" },
];

const UserFilter = [
  { key: "o", text: "All", value: "1" },
  { key: "c", text: "By Admin", value: "2" },
  { key: "u", text: "By Users", value: "3" },
];

export class TradeManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      buyOrder: "",
      sellOrder: "",
      buyRate: "",
      sellRate: "",
      sortBy: "",
      openModel: false,
      modalData: "",
      listType: "1",
    };
  }
  componentDidMount = () => {
    this.getCurrencyList();
  };

  getCurrencyList = () => {
    const param = {
      searchKey: this.state.searchKey,
      page: this.state.page,
      perPage: this.state.perPage,
      list_type: this.state.listType,
      status: "1",
    };
    this.props.getCurrencyList(param);
  };
  onSearchList = () => {
    this.getCurrencyList();
  };

  listByUser = (e, data) => {
    if (data.value !== "none") {
      this.setState({ listType: data.value }, () => {
        this.getCurrencyList();
      });
    } else {
      this.setState({ listType: "1" }, () => {
        this.getCurrencyList();
      });
    }
  };

  toggle = () => this.setState({ openModel: !this.state.openModel });

  openModel = (data) => {
    this.setState({
      modalData: data,
      openModel: true,
      buyRate: data.buy_rate,
      sellRate: data.sell_rate,
      buyOrder: data.buy_rate_type,
      sellOrder: data.sell_rate_type,
      currencyType: data?.currency_type,
    });
  };

  enableDisableCurrency = (data, id, currencyType) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    const buttonState = { status: data };

    const params = {
      currency_type: currencyType,
      currency_id: id,
      status: data,
    };
    this.props.updateCurrency(params, data2);
  };

  onChangePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getCurrencyList();
    });
  };

  sortForBuy = (e, data) => {
    this.setState({ buyOrder: data.value }, () => {});
  };

  sortForSell = (e, data) => {
    this.setState({ sellOrder: data.value }, () => {});
  };

  updateRateClick = (currency_type) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    const params = {
      buy_rate: this.state.buyRate,
      sell_rate: this.state.sellRate,
      buy_rate_type: this.state.buyOrder,
      sell_rate_type: this.state.sellOrder,
      currency_id: this.state.modalData.currency_id,
      currency_type: this.state.currencyType,
    };
    this.props.updateRate(params, this.state.modalData.id, data2);
    this.toggle();
  };

  render() {
    let { handleSubmit } = this.props;

    if (this.props.user.role == 2) {
      // console.log('permissions >>',this.props.permissions)
      var isfound = false;
      // check user Permissions if route accessible
      this.props.permissions.forEach((element) => {
        // if (element.title == "Withdrawal Management") {
        if (element.title == "Pair Coin Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        // this.props.history.push("/");
      }
    }

    return (
      <div>
        <Header headerTitle="Trade Management" />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      {/* <Dropdown
                        className="selectUserStyle"
                        options={UserFilter}
                        placeholder="Filter"
                        selection
                        onChange={(e, data) => this.listByUser(e, data)}
                      /> */}
                      <Link to="trade-setting">
                        <Button color="success btnMamagePermission">
                          Trade Settings
                        </Button>
                      </Link>
                      <Link to="trade-history">
                        <Button color="success btnMamagePermission">
                          Trade History
                        </Button>
                      </Link>

                      {this.props.user.role !== 2 && (
                        <Link to="pair-coin">
                          <Button color="success btnMamagePermission">
                            Pair Coin Management
                          </Button>
                        </Link>
                      )}

                      {this.props.user.role === 2 && isfound && (
                        <Link to="pair-coin">
                          <Button color="success btnMamagePermission">
                            Pair Coin Management
                          </Button>
                        </Link>
                      )}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Coin Id</th>
                        <th scope="col">Coin Name</th>
                        <th scope="col">Coin Symbol</th>
                        <th scope="col">Buy rate(%)</th>
                        <th scope="col">Sell rate(%)213</th>
                        <th scope=" col">Currency Type</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.getCoinList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.getCoinList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.currency_id}</td>
                            <td>{data.text?.toUpperCase()}</td>
                            <td>{data.symbol.toUpperCase()}</td>
                            <td>
                              {data.buy_rate_type !== null
                                ? data.buy_rate_type == "downgrade"
                                  ? "-" + data.buy_rate
                                  : "+" + data.buy_rate
                                : data.buy_rate}
                            </td>
                            <td>
                              {data.sell_rate_type !== null
                                ? data.sell_rate_type == "downgrade"
                                  ? "-" + data.sell_rate
                                  : "+" + data.sell_rate
                                : data.sell_rate}
                            </td>
                            <td>{data.currency_type.toUpperCase()}</td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color={
                                    data.status === "active"
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={() =>
                                    this.enableDisableCurrency(
                                      data.status === "active" ? "0" : "1",
                                      data.currency_id,
                                      data.currency_type
                                    )
                                  }
                                >
                                  {data.status === "active"
                                    ? "Inactive"
                                    : "Active"}
                                </Button>

                                <Button
                                  color="success"
                                  onClick={() => this.openModel(data)}
                                >
                                  Edit Buy/Sell Rate
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.onChangePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        <Modal isOpen={this.state.openModel} toggle={this.toggle}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Buy rate</label>

                  <input
                    name="buy_rate"
                    placeholder="Buy Rate"
                    value={this.state.buyRate}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ buyRate: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Buy rate type</label>
                  <Dropdown
                    className="selectUserStyle"
                    placeholder="Buy rate type"
                    options={options}
                    selection
                    value={this.state.buyOrder}
                    onChange={(e, data) => this.sortForBuy(e, data)}
                  />{" "}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Sell rate</label>

                  <input
                    name="buy_rate"
                    placeholder="Sell Rate"
                    value={this.state.sellRate}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ sellRate: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Sell rate type</label>
                  <Dropdown
                    className="selectUserStyle"
                    placeholder="Sell rate type"
                    options={options}
                    selection
                    value={this.state.sellOrder}
                    onChange={(e, data) => this.sortForSell(e, data)}
                  />{" "}
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.updateRateClick()}>
              Update
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    getCoinList: state.user.CurrencyList,
    total: state.user.totalRecords,
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrencyList: (data) => dispatch(getCurrencyList(data)),
    updateCurrency: (data, data2) => dispatch(updateCurrency(data, data2)),
    updateRate: (data, id, data2) => dispatch(updateRate(data, id, data2)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TradeManagement)
);
