// RewardScrach

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  addScratch,
  getScratchList,
  enableDisableScratch,
  editRewardScratch,
  sendScratchCard,
} from "../../Redux/actons/reward.action";
import { Checkbox } from "semantic-ui-react";
import moment from "moment";
import { openCloseModal } from "../../Redux/actons/SubAdmin.action";
import { Link } from "react-router-dom";
import { SelectField } from "../../components/SelectField";
// import { addReward } from "../../Redux/actons/reward.action";
import { Field, reset } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import {
  Pagination,
  Dropdown,
  Form,
  Confirm,
  TableHeader,
  TableHeaderCell,
  TableBody,
  Tab,
  TableCell,
  TableRow,
  ModalActions,
} from "semantic-ui-react";
import swapIcon from "../../images/swap.png";
import { required } from "redux-form-validators";
import { amountLimit } from "../../validators/customValidator";
import { FormField } from "../../components/FormField";
import AddReward from "./AddReward";
import RewardLimit from "./RewardLimit";
import excelToJson from "./excelToJson";

const reward_types = [
  { key: "n", text: "Deposit Crypto", value: "deposit_crypto" },
  { key: "m", text: "Deposit fiat", value: "deposit_fiat" },
  // { key: "k", text: "Sign up", value: "sign_up" },
  { key: "l", text: "First Trade", value: "first_trade" },
  { key: "a", text: "First Buy Plan ", value: "first_buy_plan" },
  { key: "b", text: "EMI Pay In Taral ", value: "emi_pay_in_taral" },
  { key: "o", text: "Partcipate In ICO ", value: "partcipate_in_ico" },
  { key: "p", text: "KYC Approval ", value: "kyc_approval" },
  { key: "q", text: "First EMI ", value: "first_emi" },
  { key: "r", text: "share", value: "share" },
  // { key: "s", text: "Other", value: "other" },
];
const amount_types = [
  { key: "n", text: "INR", value: "inr" },
  { key: "m", text: "TARAL", value: "taral" },
  { key: "l", text: "USDT", value: "usdt" },
  { key: "k", text: "ETH", value: "eth" },
  { key: "s", text: "BNB", value: "bnb" },
  { key: "o", text: "BTC", value: "btc" },
  // { key: "k", text: "Sign up", value: "sign_up" },
  // { key: "l", text: "First Trade", value: "first_trade" },
];
const duration_types = [
  { key: "n", text: "Day", value: "day" },
  { key: "l", text: "Week", value: "week" },
  { key: "m", text: "Month", value: "month" },
  // { key: "k", text: "Sign up", value: "sign_up" },
  // { key: "l", text: "First Trade", value: "first_trade" },
];
const duration = [
  { key: "n", text: "1", value: 1 },
  { key: "a", text: "2", value: 2 },
  { key: "b", text: "3", value: 3 },
  { key: "c", text: "4", value: 4 },
  // { key: "k", text: "Sign up", value: "sign_up" },
  // { key: "l", text: "First Trade", value: "first_trade" },
];
export class RewardScratch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      order: "asc",
      amountModalOpen: false,
      minAmount: "",
      planData: "",
      addRewardModal: false,
      addRewardModal1: false,
      openConfirm: false,
      deleteStatus: "",
      deleteId: "",
      amount_type: "inr",
      duration: "",
      duration_type: "",
      rewardType: "",
      withdrawReward: false,
      editOrAdd: false,
      id: "",
      start_date: null,
      end_date: null,
      openSendDirect: false,
      directSendScratchData: [],
      isCustomScratch: false,
      threshodValue: "",
    };
  }
  componentDidMount = () => {
    this.getList();
  };
  componentWillUnmount = () => {
    // this.props.openCloseModal();
  };

  getList = () => {
    // const data = {
    //   page: this.state.page,
    //   perPage: this.state.perPage,
    //   searchKey: this.state.searchKey,
    // };
    this.props.getScratchList();
  };

  enableDisableScratch = (data, id) => {
    const buttonState = { status: data };
    this.props.enableDisableScratch(buttonState, id);
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getScratchList();
    });
  };

  //   onSearchList = (search) => {
  //     this.setState({ searchKey: search }, () => {
  //       this.getPairList();
  //     });
  //   };

  openAmountModal = (data) => {
    this.setState({
      openAmountModal: true,
      minAmount: data.min_amount,
      planData: data,
    });
  };

  toggle = () =>
    this.setState({ openAmountModal: !this.state.openAmountModal });

  editScratch = async (data) => {
    const params = {
      id: this.state.id,
      // amount: this.state.minAmount,
      // type: this.state.rewardType,
      amount_type: this.state.amount_type,
      amount: this.state.minAmount,
      duration: this.state.duration,
      duration_type: this.state.duration_type,
    };

    this.props.editRewardScratch(params);
    // this.toggle();
    this.setState({
      minAmount: "",
      addRewardModal1: "",
    });
  };

  onIncentiveClick = (data) => {
    this.props.history.push({
      pathname: `viewIncentive`,
      state: data,
    });
  };

  addNewReward = (data, editOrAdd) => {
    this.setState({
      addRewardModal: !this.state.addRewardModal,
    });
  };
  addNewReward1 = (data, editOrAdd) => {
    console.log("-----", data);
    this.setState({
      id: data?.id,
      rewardType: data?.type,
      amount_type: data?.amount_type,
      minAmount: data?.min_amount,
      duration: data?.duration,
      duration_type: data?.duration_type,
      editOrAdd: editOrAdd,
      addRewardModal1: !this.state.addRewardModal1,
    });
  };

  withdrawReward = () => {
    this.setState({ withdrawReward: !this.state.withdrawReward });
  };

  openConfrimationModal = (status, id) => {
    this.setState({ openConfirm: true, deleteStatus: status, deleteId: id });
  };

  closeRewardModal1 = () => {
    this.setState({ addRewardModal1: !this.state.addRewardModal1 });
  };
  closeRewardModal = () => {
    this.setState({ addRewardModal: !this.state.addRewardModal });
  };

  close = () => {
    this.setState({ openConfirm: false });
  };

  onConfirmation = () => {
    const buttonState = { status: this.state.deleteStatus };
    this.props.enableDisableScratch(buttonState, this.state.deleteId);
    this.setState({ openConfirm: false });
  };

  checkForEnable = (list, data) => {
    if (data?.status == "active") {
      return true;
    } else if (
      list?.find((el) => el.type == data?.type && el.status == "active") &&
      data?.status == "inactive"
    ) {
      return false;
    } else {
      return true;
    }
  };
  addScratch = () => {
    let data = {
      type: this.state.rewardType,
      amount_type: this.state.amount_type,
      amount: this.state.minAmount,
      duration: this.state.duration,
      duration_type: this.state.duration_type,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      is_custom: this.state.isCustomScratch,
      max_amount: this.state.threshodValue,
    };
    if (this.state.rewardType == "") {
      alert("Reward type is required");
      return;
    }
    if (!this.state.isCustomScratch && this.state.duration_type == "") {
      alert("Duration type is required");
      return;
    }
    if (!this.state.isCustomScratch && this.state.duration == "") {
      alert("Duration is required");
      return;
    }
    if (this.state.minAmount == 0 || this.state.minAmount == "") {
      alert("Amount fields must be grater than 0");
      return;
    }

    if (this.state.isCustomScratch && this.state.start_date === null) {
      alert("Please select a start date.");
      return;
    }
    if (this.state.isCustomScratch && this.state.end_date === null) {
      alert("Please select a end date.");
      return;
    }
    if (this.state.threshodValue === "" && this.state.threshodValue === 0) {
      alert("Please enter threshold value.");
      return;
    }
    this.props.addScratch(data);
    this.setState({
      minAmount: "",
      rewardType: "",
      threshodValue: "",
      isCustomScratch: false,
      end_date: null,
      start_date: null,
      duration_type: "",
      duration: "",
      amount_type: "",
    });
    this.closeRewardModal();
  };
  closeDirectSendModal = () => {
    this.setState({ openSendDirect: false });
  };
  uploadedExcelDataHandler = (data) => {
    let resultJSON = [];
    for (let i = 1; i < data.length; i++) {
      resultJSON.push({
        email: data[i].C,
        name: data[i].B,
        amount: data[i].D,
        amount_type: data[i].E?.toLowerCase(),
      });
    }
    this.setState({ directSendScratchData: resultJSON });
  };
  convertExcelToObject = async (file) => {
    const $this = this;
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = new Uint8Array(event.target.result);
      let result = excelToJson({ source: data });
      $this.uploadedExcelDataHandler(result.Sheet1);
    };
    reader.readAsArrayBuffer(file);
  };
  uploadFileHandler = async (event) => {
    const file = event.target.files[0];
    this.convertExcelToObject(file);
  };
  sendDirectScratchCardHandler = async () => {
    this.props.sendScratchCard(this.state.directSendScratchData);
    this.setState({ openSendDirect: false, directSendScratchData: [] });
  };
  render() {
    let { handleSubmit, rewardList } = this.props;

    return (
      <div>
        <div>
          <div>
            <div>
              <Confirm
                content={`Are you sure to delete this plan ?`}
                style={{
                  height: "auto",
                  top: "5%",
                  left: "auto",
                  bottom: "auto",
                  right: "auto",
                }}
                size="small"
                className="confirm-model"
                open={this.state.openConfirm}
                onCancel={this.close}
                onConfirm={this.onConfirmation}
              />
            </div>
          </div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      {/* <Input
                        placeholder="Search"
                        onChange={(e) => this.onSearchList(`${e.target.value}`)}
                      ></Input> */}

                      <Link to="scratch-history">
                        <Button color="success btnMamagePermission">
                          Scratch History
                        </Button>
                      </Link>
                      <Button
                        color="default btnMamagePermission"
                        onClick={() => this.addNewReward("", false)}
                      >
                        + Add Scratch
                      </Button>
                      <Button
                        color="default btnMamagePermission"
                        onClick={() => this.setState({ openSendDirect: true })}
                      >
                        Send Direct
                      </Button>

                      {/* <Button
                        color="success btnMamagePermission"
                        onClick={() => this.withdrawReward()}
                      >
                        Set Limit
                      </Button> */}

                      {/* <Link to="swap-history">
                            <Button color="success btnMamagePermission">
                              Swap History
                            </Button>
                          </Link> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col"> Type</th>
                        <th scope="col">Amount Type</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Plan Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.rewardList?.rows?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.rewardList?.rows?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>
                              {data.type !== "other"
                                ? data.type.toUpperCase()
                                : data.name}
                            </td>
                            <td>{data?.amount_type?.toUpperCase()}</td>
                            <td>
                              {data?.duration
                                ? `${
                                    data?.duration
                                  } ${data?.duration_type?.toUpperCase()}`
                                : "None"}
                            </td>
                            <td>{data.min_amount}</td>
                            <td>{data.status.toUpperCase()}</td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color={
                                    data.status === "active"
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={() => {
                                    if (
                                      this.checkForEnable(
                                        this.props.rewardList.rows,
                                        data
                                      ) == true
                                    ) {
                                      this.enableDisableScratch(
                                        data.status === "active"
                                          ? "inactive"
                                          : "active",
                                        data.id
                                      );
                                    } else {
                                      alert(
                                        `${data?.type.toUpperCase()} is alreay active`
                                      );
                                    }
                                  }}
                                >
                                  {data.status === "active"
                                    ? "Inactive"
                                    : "Active"}
                                </Button>
                                <Button
                                  color={"success"}
                                  onClick={() => this.addNewReward1(data, true)}
                                >
                                  Edit Amount
                                </Button>
                                {/* <Button
                                  color={"success"}
                                  onClick={() => this.onIncentiveClick(data)}
                                >
                                  View incentive
                                </Button> */}
                                {/* <Button
                                  color={"danger"}
                                  onClick={() =>
                                    this.openConfrimationModal(
                                      "deleted",
                                      data.id
                                    )
                                  }
                                >
                                  Delete
                                </Button> */}
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {/* {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "} */}
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        {/*Send Direct Scratch Card Modal */}

        <Modal
          isOpen={this.state.openSendDirect}
          toggle={this.closeDirectSendModal}
        >
          <ModalHeader toggle={this.closeDirectSendModal}>
            Send Direct Scratch Card to Users
          </ModalHeader>
          <ModalBody>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                color="default btnMamagePermission"
                onClick={() =>
                  window.open(
                    "https://docs.google.com/spreadsheets/d/1QbHB0Qo3H_zvkuT680I6VmHzpOyxP2Tty8cI8a_SUdU/edit?usp=sharing"
                  )
                }
              >
                Download template
              </Button>
              <div>
                <input
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={this.uploadFileHandler}
                />
                <label htmlFor="contained-button-file">
                  <div
                    color="success btnMamagePermission"
                    style={{
                      padding: "12px",
                      color: "#000",
                      cursor: "pointer",
                      marginLeft: "12px",
                    }}
                  >
                    Upload xlsx
                  </div>
                </label>
              </div>
            </div>

            {this.state.directSendScratchData &&
              this.state.directSendScratchData.length !== 0 && (
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col"> Sr. No.</th>
                        <th scope="col">Coupon Name</th>
                        <th scope="col">Amount Type</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.directSendScratchData?.map((data, index) => (
                        <tr className="table-tr" key={`dataRow${index}`}>
                          <td>{index + 1}</td>
                          <td>{data?.name}</td>
                          <td>{data?.amount_type}</td>
                          <td>{data?.amount}</td>
                          <td>{data?.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
          </ModalBody>
          <ModalActions>
            {this.state.directSendScratchData &&
              this.state.directSendScratchData.length !== 0 && (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <Button
                    color="default btnMamagePermission"
                    onClick={this.sendDirectScratchCardHandler}
                  >
                    Submit
                  </Button>
                </div>
              )}
          </ModalActions>
        </Modal>

        <Modal isOpen={this.state.openAmountModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Edit Scratch Amount</ModalHeader>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Amount</label>
                  <input
                    type="number"
                    name="min_amount"
                    min="0"
                    maxLength={10}
                    value={this.state.minAmount}
                    validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ minAmount: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openAmountModal: false })}
            >
              Cancel
            </Button>
            <Button
              color="success"
              // onClick={() => this.editPlanAmount(this.state.planData)}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.addRewardModal1}
          toggle={this.closeRewardModal1}
        >
          <ModalHeader toggle={this.closeRewardModal1}>
            Edit Scratch Card Amount
          </ModalHeader>
          <ModalBody>
            <Form
            // onSubmit={this.addReward}
            >
              <Form.Group>
                <Form.Field>
                  <label>Reward Type</label>
                  <Field
                    name="reward_type"
                    search={false}
                    selectOnBlur={false}
                    component={SelectField}
                    children={reward_types}
                    disabled
                    value={this.state.rewardType}
                    defaultValue={this.state.rewardType}
                    placeholder="Select reward type"
                    onChange={(type) => this.setState({ rewardType: type })}
                    validate={[required()]}
                  ></Field>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Reward Amount Type</label>
                  <Field
                    name="reward_type"
                    search={false}
                    selectOnBlur={false}
                    component={SelectField}
                    children={amount_types}
                    value={this.state.amount_type}
                    defaultValue={this.state.amount_type}
                    disabled
                    // disabled
                    placeholder="Select reward amount type"
                    onChange={(type) => this.setState({ amount_type: type })}
                    validate={[required()]}
                  ></Field>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Duration</label>
                  <Field
                    name="reward_type"
                    search={false}
                    selectOnBlur={false}
                    component={SelectField}
                    children={duration}
                    value={this.state.duration}
                    defaultValue={this.state.duration}
                    disabled
                    // disabled
                    placeholder="Select duration"
                    onChange={(type) => this.setState({ duration: type })}
                    validate={[required()]}
                  ></Field>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Duration Type</label>
                  <Field
                    name="reward_type"
                    search={false}
                    selectOnBlur={false}
                    component={SelectField}
                    children={duration_types}
                    value={this.state.duration_type}
                    defaultValue={this.state.duration_type}
                    disabled
                    // disabled
                    placeholder="Select duration type"
                    onChange={(type) => this.setState({ duration_type: type })}
                    validate={[required()]}
                  ></Field>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Amount</label>
                  <input
                    type="number"
                    name="min_amount"
                    min="0"
                    maxLength={10}
                    value={this.state.minAmount}
                    validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ minAmount: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="error" onClick={this.closeRewardModal1}>
              Cancel
            </Button>
            <Button
              color="success"
              // type="submit"
              onClick={this.editScratch}
              // onClick={() => {
              //   // if (this.state.editOrAdd) {
              //     this.addScratch();
              //   // } else {
              //   //   this.editScratch();
              //   // }
              // }}
            >
              + EDIT
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.addRewardModal}
          toggle={this.closeRewardModal}
        >
          <ModalHeader toggle={this.closeRewardModal}>Add Scratch</ModalHeader>
          <ModalBody>
            <Form
            // onSubmit={this.addReward}
            >
              <Form.Group>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Form.Field>
                      <label>Reward Type</label>
                      <Field
                        name="reward_type"
                        search={false}
                        selectOnBlur={false}
                        component={SelectField}
                        children={reward_types}
                        // disabled={this.state.editOrAdd}
                        value={this.state.rewardType}
                        defaultValue={this.state.rewardType}
                        placeholder="Select reward type"
                        onChange={(type) => this.setState({ rewardType: type })}
                        validate={[required()]}
                      ></Field>{" "}
                    </Form.Field>
                  </div>
                  <div style={{ marginLeft: "12px" }}>
                    <Form.Field>
                      <Checkbox
                        label="Other"
                        id=" customCheckLogin1"
                        checked={this.state.isCustomScratch}
                        onChange={(e, data) =>
                          this.setState({
                            isCustomScratch: !this.state.isCustomScratch,
                          })
                        }
                      />
                    </Form.Field>
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Reward Amount Type</label>
                  <Field
                    name="reward_type"
                    search={false}
                    selectOnBlur={false}
                    component={SelectField}
                    children={amount_types}
                    value={this.state.amount_type}
                    defaultValue={this.state.amount_type}
                    // disabled
                    placeholder="Select reward amount type"
                    onChange={(type) => this.setState({ amount_type: type })}
                    validate={[required()]}
                  ></Field>
                </Form.Field>
              </Form.Group>
              {!this.state.isCustomScratch && (
                <>
                  <Form.Group>
                    <Form.Field>
                      <label>Duration</label>
                      <Field
                        name="reward_type"
                        search={false}
                        selectOnBlur={false}
                        component={SelectField}
                        children={duration}
                        value={this.state.duration}
                        defaultValue={this.state.duration}
                        // disabled
                        placeholder="Select duration"
                        onChange={(type) => this.setState({ duration: type })}
                        validate={[required()]}
                      ></Field>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <label>Duration Type</label>
                      <Field
                        name="reward_type"
                        search={false}
                        selectOnBlur={false}
                        component={SelectField}
                        children={duration_types}
                        value={this.state.duration_type}
                        defaultValue={this.state.duration_type}
                        // disabled
                        placeholder="Select duration type"
                        onChange={(type) =>
                          this.setState({ duration_type: type })
                        }
                        validate={[required()]}
                      ></Field>
                    </Form.Field>
                  </Form.Group>
                </>
              )}
              {this.state.isCustomScratch && (
                <>
                  <Form.Group>
                    <Form.Field>
                      <label>Start Date</label>
                      <input
                        type="datetime-local"
                        min={new Date()
                          .toISOString()
                          .slice(0, new Date().toISOString().lastIndexOf(":"))}
                        name="start_date"
                        value={this.state.start_date}
                        component={FormField}
                        onChange={(e, data) => {
                          // console.log("date----", e.target.value);
                          this.setState({ start_date: e.target.value });
                        }}
                      ></input>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <label>End Date</label>
                      <input
                        type="datetime-local"
                        name="end_date"
                        min={new Date(this.state.start_date)
                          .toISOString()
                          .slice(
                            0,
                            new Date(this.state.start_date)
                              .toISOString()
                              .lastIndexOf(":")
                          )}
                        value={this.state.end_date}
                        component={FormField}
                        onChange={(e, data) =>
                          this.setState({ end_date: e.target.value })
                        }
                      ></input>
                    </Form.Field>
                  </Form.Group>
                </>
              )}
              <Form.Group>
                <Form.Field>
                  <label>Threshold Amount</label>
                  <input
                    type="number"
                    name="min_amount"
                    min="0"
                    maxLength={10}
                    placeholder="Enter amount"
                    value={this.state.threshodValue}
                    validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ threshodValue: e.target.value })
                    }
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Reward Amount</label>
                  <input
                    type="number"
                    name="min_amount"
                    min="0"
                    maxLength={10}
                    placeholder="Enter amount"
                    value={this.state.minAmount}
                    validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ minAmount: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="error" onClick={this.closeRewardModal}>
              Cancel
            </Button>
            <Button
              color="success"
              // type="submit"
              onClick={this.addScratch}
              // onClick={() => {
              //   // if (this.state.editOrAdd) {
              //     this.addScratch();
              //   // } else {
              //   //   this.editScratch();
              //   // }
              // }}
            >
              + ADD
            </Button>
          </ModalFooter>
        </Modal>

        {/* <AddReward
          closeRewardModal={this.closeRewardModal}
          addRewardModal={this.state.addRewardModal}
        /> */}
        <RewardLimit
          closeRewardModal={this.withdrawReward}
          showRewardModal={this.state.withdrawReward}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    rewardList: state.rewardList.scratchList,
    // total: state.pairCoinList.pairCoinList.total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getScratchList: () => dispatch(getScratchList()),
    enableDisableScratch: (data, id) =>
      dispatch(enableDisableScratch(data, id)),
    editRewardScratch: (data) => dispatch(editRewardScratch(data)),
    addScratch: (data) => dispatch(addScratch(data)),
    sendScratchCard: (data) => dispatch(sendScratchCard(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RewardScratch)
);
