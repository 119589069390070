import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
} from "reactstrap";
import { Pagination, Confirm, Dropdown, Menu } from "semantic-ui-react";
import CustomHeader from "../../../components/Headers/CustomHeader";
import { withRouter } from "react-router";
import { API_HOST } from "../../../constants/index";
import { listDateFormat } from "../../../utils/utils";
import { convertExponentialToDecimal } from "../../../utils/utils";
import { getTradingReports } from "../../../Redux/actons/report.action";
import { Link } from "react-router-dom";
import { toast } from "../../../components/Toast/Toast";
import { UserService } from "Services/UserServices";
const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
export class TradingReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
    };
  }

  componentDidMount = () => {
    this.getTradingReportList();
    this.checkRouteValid();
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      var isfound = false;
      // check user Permissions if route accessible
      await this.props.permissions.forEach((element) => {
        if (element.title == "Report Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };
  gotoBalaceList = () => {
    this.props.history.push("reports");
  };
  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ order: data.value }, () => {
      this.getTradingReportList();
    });
  };
  getTradingReportList = () => {
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      order: this.state.order,
    };
    this.props.getTradingReports(data);
  };

  //page change
  changeApprovedPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getTradingReportList();
    });
  };
  //search
  onSearchWithdraw = (abc) => {
    this.setState({ searchKey: abc, page: 1 }, () => {
      this.getTradingReportList();
    });
  };
  downloadTradingReports = () => {
    // this.props.downloadBalanceList();
    if (this.props.tradingList.length != 0) {
      window.location.href = `${API_HOST}api/v1/admin/report/download/tradingreport`;
    } else {
      toast.error("No records found");
    }
  };
  render() {
    return (
      <div>
        {/* approve disaprove confirm */}

        <CustomHeader />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-0">OTC Trading Fee</h3>
                      <div className="selectSearchOption">
                        <Input
                          placeholder="Search"
                          onChange={(e) =>
                            this.onSearchWithdraw(`${e.target.value}`)
                          }
                        ></Input>
                        <Dropdown
                          className="selectUserStyle"
                          options={options}
                          selection
                          value={this.state.order}
                          onChange={(e, data) => this.filterBy(e, data)}
                        />
                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.downloadTradingReports()}
                        >
                          Download Otc Trading Reports
                        </Button>
                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.gotoBalaceList()}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Owner Name</th>
                        <th scope="col">Trade User</th>
                        <th scope="col">Trade User Email</th>
                        <th scope="col">Trade Owner</th>
                        <th scope="col">Trade Owner Email</th>
                        <th scope="col">offer_type</th>
                        <th scope="col">buy_symbol</th>
                        <th scope="col">sell_symbol</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Owner otc fee</th>{" "}
                        <th scope="col">User otc fee</th>
                        <th scope="col">TimeStamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.tradingList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          <td>{usersList.owner_name}</td>
                          {usersList.trade_user != null ? (
                            <td>{usersList.trade_user.name}</td>
                          ) : (
                            <td>None</td>
                          )}
                          {usersList.trade_user != null ? (
                            <td>{usersList.trade_user.email}</td>
                          ) : (
                            <td>None</td>
                          )}
                          <td>{usersList.trade_owner.name}</td>
                          <td>{usersList.trade_owner.email}</td>
                          <td>{usersList.offer_type}</td>
                          <td>{usersList.buy_symbol.toUpperCase()}</td>
                          <td>{usersList.sell_symbol.toUpperCase()}</td>
                          <td>{usersList.amount}</td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.owner_otc_fee)
                            )}
                          </td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.user_otc_fee)
                            )}
                          </td>
                          <td>{listDateFormat(usersList.created_at)}</td>
                        </tr>
                      ))}
                      {this.props.tradingList.length == 0 && <td>No record</td>}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      {this.props.totalRecords > 0 && (
                        <Pagination
                          onPageChange={(e, data) =>
                            this.changeApprovedPage(e, data)
                          }
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalRecords / this.state.perPage
                          )}
                        />
                      )}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    tradingList: state.reports.tradingList,
    totalRecords: state.reports.tradingTotalRecords,
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTradingReports: (data) => dispatch(getTradingReports(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TradingReports)
);
