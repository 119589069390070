import { SubAdminService } from "../../Services/SubAdminService";

import { UserService } from "../../Services/UserServices";
import { startLoading, stopLoading } from "./loading.action";

import { reset } from "redux-form";

import { toast } from "../../components/Toast/Toast";
export const actionTypes = {
  GET_SUBADMIN_LIST: "GET_SUBADMIN_LIST",
  SAVE_PERMISSIONS: "SAVE_PERMISSIONS",
  SAVE_SUB_ADMIN_ASSINED_PERMISSIONS: "SAVE_SUB_ADMIN_ASSINED_PERMISSIONS",
  OPEN_CLOSE_SECURITY: "OPEN_CLOSE_SECURITY",
  GET_LOGDETAIL_LIST: "GET_LOGDETAIL_LIST",
};

/*
 * Select/change Security option
 */
export function openCloseModal(payload) {
  return {
    type: actionTypes.OPEN_CLOSE_SECURITY,
    data: payload,
  };
}
export function saveSubAdminPermissions(payload) {
  return {
    type: actionTypes.SAVE_SUB_ADMIN_ASSINED_PERMISSIONS,
    data: payload,
  };
}
export function saveSubAdminList(payload) {
  return {
    type: actionTypes.GET_SUBADMIN_LIST,
    data: payload,
  };
}
export function savePermissions(payload) {
  return {
    type: actionTypes.SAVE_PERMISSIONS,
    data: payload,
  };
}
export function saveLogDetailList(payload) {
  return {
    type: actionTypes.GET_LOGDETAIL_LIST,
    data: payload,
  };
}

export function getAllSubAdmin(data) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return SubAdminService.getAllSubAdmin(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveSubAdminList(res.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function removeSubAdmin(data, id) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return SubAdminService.removeSubAdmin(id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        toast.success(res.data.message);
        dispatch(getAllSubAdmin(data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function enableDisableSubAdmin(apiData, data, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return SubAdminService.enableDisableSubAdmin(apiData, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(stopLoading());
        dispatch(getAllSubAdmin(data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getAdminPermissions() {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return SubAdminService.getAdminPermissions({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(savePermissions(res.data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function assignAdminPermissions(data, id, req) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return SubAdminService.assignAdminPermissions(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res.data.message);

        dispatch(stopLoading());
        dispatch(getAllSubAdmin(req));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function addSubAdmin(data, history) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return SubAdminService.addSubAdmin(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        toast.success(res.data.message);

        dispatch(reset("addSubAdminForm")); // requires form name
        history.push("sub-admin-management");
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getSubAdminPermissions() {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    /*USING SAME API FOR SUBADMIN PERMISSIONS*/
    return SubAdminService.getSubAdminPermissions({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveSubAdminPermissions(res.data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function verifyGoogleAuthRequestSecurity(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    UserService.verifyGoogleAuth(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then((success) => {
        dispatch(stopLoading());
        dispatch(openCloseModal(false));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// getLogReport
export function getLogReport(data) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return SubAdminService.getLogReport(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveLogDetailList(res.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          // toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
