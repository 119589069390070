import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { verifyGoogleAuthRequest } from "../../Redux/actons/security.action";
import Web3 from "web3";
import Blockies from "react-blockies";

import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react"; // Default styles that can be overridden by your app
require("@solana/wallet-adapter-react-ui/styles.css");

const PhantomWallet = (props) => {
  //   const { activate, account, deactivate, chainId } = useWeb3React();
  const web3 = (window.web3 = new Web3(window.ethereum));
  const [networkId, setNetworkId] = React.useState();
  const [loader, setloader] = React.useState(false);
  const [account, setAddress] = React.useState(
    window?.tronWeb?.defaultAddress?.base58
  );

  const { connection } = useConnection();
  console.log("connection >>", connection);
  const wallet = useWallet();
  console.log("wallet  connection >> --------->>>>>  ", wallet);

  const isWalletConnected = async () => {
    try {
      const { solana } = window;
      if (solana) {
        if (solana.isPhantom) {
          console.log("phantom wallet found", solana);
          // When using this flag, Phantom will only connect and emit a connect event if the application is trusted. Therefore, this can be safely called on page load for new users, as they won't be bothered by a pop-up window even if they have never connected to Phantom before.
          // if user already connected, { onlyIfTrusted: true }
          // const response = await solana.request({ onlyIfTrusted: false });
          const response = await solana.connect({ onlyIfTrusted: false });
          console.log(" phantom wallet found ''' key", response);
          // console.log("public key", response.publicKey.toString());
          setAddress(response.publicKey.toString());
          props.setpubkey(response.publicKey);
        } else {
          // const response = await solana.connect({ onlyIfTrusted: false });
          alert("Please install phantom wallet");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   isWalletConnected();
  // }, []);
  return (
    <React.Fragment>
      <div
        className="selectSearchOption"
        style={{
          paddingTop: "15px",
        }}
      >
        {/* <button className={"blockies"} onClick={SolanaConnection}> */}

        {/* <WalletMultiButton className="wallet-btn-connect">
          <div>
            {account
              ? account.slice(0, 6) + "..." + account.slice(-4)
              : "Connect to Phantom Wallet"}
          </div>
        </WalletMultiButton> */}
        <button className={"blockies"} onClick={isWalletConnected}>
          <Blockies className={"blockies"} seed={"Jeremy"} size={8} scale={3} />
          <div>
            {account
              ? account.slice(0, 6) + "..." + account.slice(-4)
              : "Connect to Phantom Wallet"}
          </div>
        </button>
        {/* <div className="container has-background-grey-light">
          <div className="row">
            <WalletMultiButton className="wallet-btn-connect success">
              Connect wallet
            </WalletMultiButton>
            <>
              {wallet.connected && (
                <WalletDisconnectButton>
                  disconnect wallet
                </WalletDisconnectButton>
              )}
            </>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("DAIVIK", state);
  return {
    token: state.persist["c_user"],
    user: state.user["user"],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifygoogleAuth: (values) => dispatch(verifyGoogleAuthRequest(values)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PhantomWallet);
