import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { Form } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { withRouter } from "react-router";
import "./download.scss";
import { toast } from "../../components/Toast/Toast";
import axios from "axios";
import { downLoaded } from "../../Redux/actons/userList.action";

export class DownloadExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: "",
      end_date: "",
      datepicker_start_date: null,
      datepicker_end_date: null,
    };
  }
  componentDidMount = () => {};
  SetStartDate(data) {
    if (data.value !== null) {
      let date = new Date(data.value);
      let start_date = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
      this.setState({ start_date: start_date });
      this.setState({ datepicker_start_date: data.value });
    } else {
      this.setState({ start_date: "" });
      this.setState({ datepicker_start_date: data.value });
    }
  }
  SetEndDate(data) {
    //     var msDiff =  new Date(data.value).getTime() -  new Date(this.state.start_date).getTime()
    // var daysTill30June2035 = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    // console.log('date--------------', daysTill30June2035);

    if (data.value !== null) {
      let date = new Date(data.value);
      let end_date = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
      this.setState({ end_date: end_date });
      this.setState({ datepicker_end_date: data.value });
    } else {
      this.setState({ end_date: "" });
      this.setState({ datepicker_end_date: data.value });
    }
  }

  submit() {
    console.log("formdata", this.props.filterData);

    let params = {
      start_date: "",
      end_date: "",
      page: 1,
      perPage: 10,
      kycLevelType: this.props.filterData.kycLevelType,
      searchKey: this.props.filterData.searchKey,
      type: this.props.filterData.type,
      order: this.props.filterData.order,
    };
    let params1 = {
      start_date: "",
      end_date: "",
      page: 1,
      perPage: 10,
    };
    if (this.state.start_date !== "" && this.state.end_date !== "") {
      var d1 = new Date(this.state.datepicker_start_date);
      var d2 = new Date(this.state.datepicker_end_date);
      if (d1.getTime() >= d2.getTime()) {
        alert("End date must be greater than Start date");
        this.setState({ datepicker_end_date: null });
        return false;
      }
      params.start_date = this.state.start_date;
      params.end_date = this.state.end_date;
      params1.start_date = this.state.start_date;
      params1.end_date = this.state.end_date;
    }

    params = params ? "?" + new URLSearchParams(params).toString() : "";
    window.location.href = `${this.props.download_url}${params}`;
    // var rekuest = new XMLHttpRequest();
    // rekuest.open("GET", `${this.props.download_url}${params}`, true);
    // rekuest.send();
    // console.log("Limit exceed------", rekuest);
    // console.log("Limit exceed------ status", rekuest.status);
    // if (rekuest.status.toString() !== "200") {
    //   // toast.error("Limit exceed");
    // }
    /* ************************************************************************** */
    // this.props.downLoaded(`${this.props.download_url}${params}`, params1);
    /* ************************************************************************** */
    // try {
    //   const res = await axios({
    //     method: "get",
    //     url:
    //     // url: `${API_HOST}api/v1/admin/active_block_coin`,
    //     // headers: {
    //     //   Authorization: options.jwt,
    //     // },
    //     params: data,
    //   });
    // console.log("Limit exceed------ status", res);
    //   // getCoinValue(data1, options);
    //   // return res.data;
    //   // }
    // } catch (error) {
    //   console.log(error);
    // }

    // let filename = "UserLists12.xlsx";
    // return axios({
    //   headers: {
    //     "Content-type": "application/json",
    //   },
    //   accept: "application/x-www-form-urlencoded",
    //   url: `${this.props.download_url}${params}`,
    //   method: "get",
    //   // data: {
    //   //   key: "8575",
    //   //   type: "userdetails",
    //   //   data: filteredRows,
    //   // },
    // }).then((resp) => {
    //   var blob = resp.data;
    //   if (window.navigator.msSaveOrOpenBlob) {
    //     window.navigator.msSaveBlob(blob, filename);
    //   } else {
    //     var downloadLink = window.document.createElement("a");
    //     downloadLink.href = window.URL.createObjectURL(
    //       new Blob([blob], {
    //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //       })
    //     );
    //     downloadLink.download = filename;
    //     document.body.appendChild(downloadLink);
    //     console.log("Limit exceed------", downloadLink);
    //     downloadLink.click();
    //     document.body.removeChild(downloadLink);
    //   }
    // });
    /* ************************************************************************** */
    // const data = `${this.props.download_url}${params}`;

    // const url = window.URL.createObjectURL(downloadUrl1);
    // const link1 = document.createElement("a");
    // link1.href = downloadUrl1;

    // document.body.appendChild(link1);
    // link1.click();
    /* ************************************************************************** */
    // const data =  this.getUrlFromServer()

    // const blob = new Blob([data], {
    //   type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // });
    // const downloadURL = window.URL.createObjectURL(blob);
    // const link = document.createElement("a");
    // link.href = downloadURL;
    // link.download = "data.docx";
    // console.log("Limit exceed------ downloadUrl1", downloadURL);
    // console.log("Limit exceed------,link1", link);
    // link.click();
    /* ************************************************************************** */
    this.setState({
      start_date: "",
      end_date: "",
      datepicker_start_date: null,
      datepicker_end_date: null,
    });
    this.props.closeDownloadExcel();
  }
  render() {
    // console.log("this.props222 >> ", this.props);
    // console.log("this.state >> ", this.state);

    let { handleSubmit } = this.props;
    return (
      <div>
        {/* <Button color="danger">X</Button> */}

        <Modal
          isOpen={this.props.downloadExcel}
          toggle={this.props.closeDownloadExcel}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader
            toggle={this.props.closeDownloadExcel}
            className="records_download"
          >
            Download Record
          </ModalHeader>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Select start date</label>
                  <SemanticDatepicker
                    locale="en-US"
                    onChange={(event, data) => this.SetStartDate(data)}
                    type="basic"
                    format="YYYY-MM-DD"
                    value={this.state.datepicker_start_date}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Select end date</label>
                  <SemanticDatepicker
                    locale="en-US"
                    onChange={(event, data) => this.SetEndDate(data)}
                    type="basic"
                    format="YYYY-MM-DD"
                    value={this.state.datepicker_end_date}
                  />
                </Form.Field>
              </Form.Group>
              {/* { this.state.start_date !== "" && this.state.end_date!=="" && ( */}
              <Form.Field className="addBtn">
                <Button
                  type="submit"
                  color="success"
                  onClick={() => this.submit()}
                  // href={`${this.props.download_url}`}
                >
                  Submit
                </Button>
              </Form.Field>
              {/* )} */}
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    downLoaded: (data) => dispatch(downLoaded(data)),

    // downLoadeddownLoaded
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DownloadExcel)
);
