import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const getBannerList = (params, token) => {
  return fetch("get", `${API_HOST}api/v1/admin/banners/list`, params, token);
};

const getBannerType = (id, options) => {
  return fetch("get", `${API_HOST}api/v1/admin/banners/types`, {}, options);
};
const addBanner = (data, options) => {
  return fetch("post", `${API_HOST}api/v1/admin/banners/create`, data, options);
};

const deleteBanner = (data, options) => {
    return fetch("post", `${API_HOST}api/v1/admin/banners/delete`, data, options);
  };


export const BannerServices = {
  getBannerList,
  getBannerType,
  addBanner,
  deleteBanner
};
