import { InterestServices } from "Services/InterestServices";
import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../components/Toast/Toast";

export const actionTypes = {
  INTEREST_LIST: "INTEREST_LIST",
  SINGLE_INTEREST: "SINGLE_INTEREST",
  STAKE_INTEREST_LIST: "STAKE_INTEREST_LIST",
  Daily_INTEREST_LIST: "Daily_INTEREST_LIST",
};

export function saveInterestList(data) {
  return {
    type: actionTypes.INTEREST_LIST,
    data: data,
  };
}
export function saveStakeInterestList(data) {
  return {
    type: actionTypes.STAKE_INTEREST_LIST,
    data: data,
  };
}
export function saveDailyInterestList(data) {
  return {
    type: actionTypes.Daily_INTEREST_LIST,
    data: data,
  };
}

export function saveSingleInterest(data) {
  return {
    type: actionTypes.SINGLE_INTEREST,
    data: data,
  };
}

export function addInterest(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InterestServices.addInterest(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function userDailyInterestList(params, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InterestServices.userDailyInterestList(
      params,
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      },
      id
    )
      .then(async (data) => {
        dispatch(saveDailyInterestList(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function userUnstaked(params, data1) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InterestServices.userUnstaked(
      params,
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      },
      data1
    )
      .then(async (data) => {
        userStakInterestList(data1);
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
        }
        dispatch(stopLoading());
      });
  };
}
export function userStakInterestList(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InterestServices.userStakInterestList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveStakeInterestList(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          console.log("error", error);
          console.log("error.message", error.response);
          // toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getInterestList(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InterestServices.getInterestList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveInterestList(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function deleteInterestPair(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InterestServices.deleteInterestPair(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getInterestList());
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function updateAllInterest(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InterestServices.updateAllInterest(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getSingleInterest(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InterestServices.getSingleInterestInfo(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveSingleInterest(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
