import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { getFiatRevenueList } from "../../Redux/actons/revenue.action";
import { Pagination, Form, Dropdown } from "semantic-ui-react";
import Header from "components/Headers/Header";
import { withRouter, Link } from "react-router-dom";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
const listFilter = [
  { key: "o", text: "All", value: "all" },
  { key: "c", text: "Deposit", value: "deposit" },
  { key: "u", text: "Earning", value: "earning" },
];

export class FiatRevenueList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      sortBy: "",
      order: "asc",
      listType: "all",
    };
  }
  componentDidMount = () => {
    // alert('test');
    this.getFiatList();
  };

  getFiatList = () => {
    const param = {
      page: this.state.page,
      perPage: this.state.perPage,
      search: this.state.searchKey,
      order: this.state.order,
      listType: this.state.listType,
    };
    this.props.getFiatRevenueList(param);
  };

  onChangePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getFiatList();
    });
  };

  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ order: data.value }, () => {
      this.getFiatList();
    });
  };

  listBy = (e, data) => {
    if (data.value !== "all") {
      this.setState({ listType: data.value }, () => {
        this.getFiatList();
      });
    } else {
      this.setState({ listType: "all" }, () => {
        this.getFiatList();
      });
    }
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Fiat Revenue List" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="revenue-management">Revenue Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Fiat Revenue List</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.getFiatList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.getFiatList()}
                      >
                        Search
                      </Button>
                      {/* <Link to="trade-setting">
                        <Button color="success btnMamagePermission">
                          Trade Settings
                        </Button>
                      </Link> */}

                      <Dropdown
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.filterBy(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={listFilter}
                        placeholder="Filter"
                        selection
                        value={this.state.listType}
                        onChange={(e, data) => this.listBy(e, data)}
                      />

                      {/* <Link to="trade-history">
                        <Button color="success btnMamagePermission">
                          Trade History
                        </Button>
                      </Link> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">User EMail</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.fiatRevenueData?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.fiatRevenueData?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.email}</td>
                            <td>{data.amount}</td>
                            <td>{data.type}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.fiatRevenueRecords != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.onChangePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.fiatRevenueRecords / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    fiatRevenueData: state.revenue.fiatRevenueData,
    fiatRevenueRecords: state.revenue.fiatRevenueRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFiatRevenueList: (data) => dispatch(getFiatRevenueList(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FiatRevenueList)
);
