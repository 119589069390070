import React, { Component } from "react";
import {
  Form,
  Image,
  Modal,
  Grid,
  Button,
  List,
  GridColumn,
  Header,
  Input,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export class GoogleAuthStepTwo extends Component {
  constructor(props){
    super(props);
    this.state={
      copied:false
    }
  }

  render() {
    let { googleAuthKeys } = this.props;
    console.log("PP", this.props.googleAuthKeys);
    return (
      <Grid.Row columns={1} className="stepTwo">
        <Grid.Column>
          <Image centered src={googleAuthKeys.qr_code} width={150} alt="" />
        </Grid.Column>
        <GridColumn>
          <Header as="h5" textAlign="center" style={{ color: "#d6d6d6" }}>
            Scan the QR code in the Google Authentication App, if you are unable
            to scan the QR Code please enter this code manually into the app
          </Header>
        </GridColumn>
        <GridColumn className="inputOuterCopy">
          <Input
            // style={{ width: "370px" }}
            focus
            disabled
            fluid
            size="large"
            value={googleAuthKeys.google_secret_key}
          />
        <CopyToClipboard style={{ "width":"20%",padding: "0.625rem 1.25rem",   "border-radius":"7px", "color":"white","font-size": "100%", "font-weight": "400","background": "#0da5c0", "cursor":"pointer"}} text={googleAuthKeys.google_secret_key}
          onCopy={() => {
            this.setState({copied: true})
            setTimeout(() => {
              this.setState({copied: false})
            }, 1000);
          }}>
          <button >Copy</button>
        </CopyToClipboard>
        {this.state.copied ? <span className="copiedText" style={{color: 'green'}}>Copied.</span> : null}
        </GridColumn>
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    googleAuthKeys: state.account.googleAuthKeys,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // verifyAuthToken: values => dispatch(verifyAuthRequest(values)),
    // verifygoogleAuth: values => dispatch(verifyGoogleAuthRequest(values))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAuthStepTwo);
