import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AuthGuard = ({ component: Component, ...rest }) => {
  const { token } = rest;
  var isAuthenticated = true;
  if (token.token !== "" && token.token !== undefined) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/vnjdLbguybcAzLmvAlsdnfjDbiumaas/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.persist["c_user"],
  };
};

export default connect(mapStateToProps, null)(AuthGuard);
