import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../components/Toast/Toast";
import { RewardServices } from "../../Services/RewardServices";
import { reset } from "redux-form";

export const actionTypes = {
  REWARD_LIST: "REWARD_LIST",
  SCRATCH_LIST: "SCRATCH_LIST",
  INCENTIVE_LIST: "INCENTIVE_LIST",
  REWARD_HISTORY: "REWARD_HISTORY",
  SCRATCH_HISTORY: "SCRATCH_HISTORY",
};

export function saveRewardList(data) {
  return {
    type: actionTypes.REWARD_LIST,
    data: data,
  };
}
export function saveScratchList(data) {
  return {
    type: actionTypes.SCRATCH_LIST,
    data: data,
  };
}

export function saveIncentiveList(data) {
  return {
    type: actionTypes.INCENTIVE_LIST,
    data: data,
  };
}

export function saveRewardHistory(data) {
  return {
    type: actionTypes.REWARD_HISTORY,
    data: data,
  };
}
export function saveScratchHistory(data) {
  return {
    type: actionTypes.SCRATCH_HISTORY,
    data: data,
  };
}

export function addScratch(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.addScratch(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getScratchList());
        dispatch(reset("AddRewardForm"));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function sendScratchCard(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.sendScratchCard(
      { card_arr: data },
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      }
    )
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getScratchList());
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getScratchList(params) {
  return (dispatch, getState) => {
    let state = getState();
    return RewardServices.getScratchList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveScratchList(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function addReward(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.addReward(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getRewardList());
        dispatch(reset("AddRewardForm"));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getRewardList(params) {
  return (dispatch, getState) => {
    let state = getState();
    return RewardServices.getRewardList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveRewardList(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getIncentiveList(params) {
  return (dispatch, getState) => {
    let state = getState();
    return RewardServices.getIncentiveList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveIncentiveList(data.data));
      })
      .catch((error) => {
        if (error) {
          // toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function addIncentive(data, param) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.addIncentive(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getIncentiveList(param));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function changeIncentiveAmount(data, params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.changeIncentiveAmount(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getIncentiveList(params));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function changeIncentiveStatus(params, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.changeIncentiveStatus(id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getIncentiveList(params));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function enableDisableScratch(data, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.enableDisableScratch(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getScratchList());
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function enableDisableReward(data, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.enableDisableReward(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getRewardList());
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function editRewardScratch(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.editRewardScratch(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getScratchList());
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function changeRewardAmount(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.editRewardAmount(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getRewardList());
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getScratchedHistory(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.getScratchedHistory(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        console.log("SCRATCH_HISTORY--- data", data);
        dispatch(saveScratchHistory(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getRewardHistory(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return RewardServices.getRewardHistory(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveRewardHistory(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
