import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  getRewardList,
  changeRewardAmount,
} from "../../../Redux/actons/reward.action";

import {
  getSubjectList,
  deleteSubject,
  changeStatus,
} from "../../../Redux/actons/subject.action";

import { openCloseModal } from "../../../Redux/actons/SubAdmin.action";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Pagination, Dropdown, Form, Confirm } from "semantic-ui-react";
import swapIcon from "../../../images/swap.png";
import { required } from "redux-form-validators";
import { amountLimit } from "../../../validators/customValidator";
import { FormField } from "../../../components/FormField";
import AddSubject from "./AddSubject";
import RewardLimit from "./RewardLimit";

export class RewardManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      order: "asc",
      amountModalOpen: false,
      minAmount: "",
      planData: "",
      addRewardModal: false,
      openConfirm: false,
      deleteStatus: "",
      deleteId: "",
      withdrawReward: false,
    };
  }
  componentDidMount = () => {
    this.getList();
  };
  componentWillUnmount = () => {
    // this.props.openCloseModal();
  };

  getList = () => {
    // const data = {
    //   page: this.state.page,
    //   perPage: this.state.perPage,
    //   searchKey: this.state.searchKey,
    // };
    this.props.getRewardList();
    this.props.getSubjectList();
  };

  changeStatus = (status, title, id) => {
    const buttonState = { status: status, title: title, id: id };
    this.props.changeStatus(buttonState, id);
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getRewardList();
    });
  };

  //   onSearchList = (search) => {
  //     this.setState({ searchKey: search }, () => {
  //       this.getPairList();
  //     });
  //   };

  openAmountModal = (data) => {
    this.setState({
      openAmountModal: true,
      minAmount: data.min_amount,
      planData: data,
    });
  };

  toggle = () =>
    this.setState({ openAmountModal: !this.state.openAmountModal });

  editPlanAmount = async (data) => {
    const params = {
      id: data.id,
      amount: this.state.minAmount,
    };

    this.props.changeRewardAmount(params);
    this.toggle();
  };

  onIncentiveClick = (data) => {
    this.props.history.push({
      pathname: `viewIncentive`,
      state: data,
    });
  };

  addNewReward = () => {
    this.setState({ addRewardModal: !this.state.addRewardModal });
  };

  withdrawReward = () => {
    this.setState({ withdrawReward: !this.state.withdrawReward });
  };

  openConfrimationModal = (status, id) => {
    this.setState({ openConfirm: true, deleteStatus: status, deleteId: id });
  };

  closeRewardModal = () => {
    this.setState({ addRewardModal: !this.state.addRewardModal });
  };

  close = () => {
    this.setState({ openConfirm: false });
  };

  onConfirmation = () => {
    const buttonState = { status: this.state.deleteStatus };
    this.props.deleteSubject(buttonState, this.state.deleteId);
    this.setState({ openConfirm: false });
  };

  checkForEnable = (list, data) => {
    if (data?.status == 1) {
      return true;
    } else if (
      list?.find((el) => el.type == data?.type && el.status == 1) &&
      data?.status == "inactive"
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Reward Management" />
        <div>
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="contact-queries">Contact Query Management</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Subject</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div>
            <div>
              <Confirm
                content={`Are you sure to delete this plan ?`}
                style={{
                  height: "auto",
                  top: "5%",
                  left: "auto",
                  bottom: "auto",
                  right: "auto",
                }}
                size="small"
                className="confirm-model"
                open={this.state.openConfirm}
                onCancel={this.close}
                onConfirm={this.onConfirmation}
              />
            </div>
          </div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Button
                        color="default btnMamagePermission"
                        onClick={() => this.addNewReward()}
                      >
                        + Add Subject
                      </Button>
                    </div>
                  </CardHeader>
                  {console.log(this.props.subjectList, "jjjjjjjj===>")}
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Title</th>
                        {/* <th scope="col">Min amount</th> */}
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.subjectList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.subjectList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data?.title.toUpperCase()}</td>
                            {/* <td>{data.min_amount}</td> */}
                            <td>{data.status === 1 ? "active" : "inactive"}</td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color={
                                    data.status === 1 ? "danger" : "success"
                                  }
                                  onClick={() => {
                                    if (
                                      this.checkForEnable(
                                        this.props.rewardList.rows,
                                        data
                                      ) == true
                                    ) {
                                      this.changeStatus(
                                        data.status === 1 ? "0" : "1",
                                        data.title,
                                        data.id
                                      );
                                    } else {
                                      alert(
                                        `${data?.type.toUpperCase()} is alreay active`
                                      );
                                    }
                                  }}
                                >
                                  {data.status === 1 ? "Inactive" : "active"}
                                </Button>

                                <Button
                                  color={"danger"}
                                  onClick={() =>
                                    this.openConfrimationModal(
                                      "deleted",
                                      data.id
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <AddSubject
          closeRewardModal={this.closeRewardModal}
          addRewardModal={this.state.addRewardModal}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    rewardList: state.rewardList.rewardList,
    subjectList: state.subjectList.subjectList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRewardList: () => dispatch(getRewardList()),
    getSubjectList: () => dispatch(getSubjectList()),
    deleteSubject: (data, id) => dispatch(deleteSubject(data, id)),
    changeRewardAmount: (data) => dispatch(changeRewardAmount(data)),
    changeStatus: (data) => dispatch(changeStatus(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RewardManagement)
);
