import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCoinList } from "../../Redux/actons/user.action";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  getCurrencyList,
  updateCurrency,
  deleteCoinValue,
  updateRate,
  addCoinValue,
  updateCoinValue,
  getCoinValue,
} from "../../Redux/actons/user.action";
import { Pagination, Form, Dropdown } from "semantic-ui-react";
import Header from "components/Headers/Header";
import { withRouter, Link } from "react-router-dom";
import { listDateFormat } from "utils/utils";

const UseBaseCoin = [
  { key: "m", text: "ETH", value: "ETH" },
  { key: "k", text: "INR", value: "INR" },
  { key: "k", text: "USDT", value: "USDT" },
  { key: "k", text: "BTC", value: "BTC" },
];

const UserFilter = [
  { key: "o", text: "All", value: "" },
  { key: "c", text: "Inactive", value: "0" },
  { key: "u", text: "Active", value: "1" },
];

export class ViewOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      buyOrder: "",
      sellOrder: "",
      coinName: "",
      maxAdd: "",
      minAdd: "",
      plan_tenure_type: "",
      SecondaryCoin: "",
      BaseCoin: "",
      pair_name: "",
      // baseCoin: "",
      // SecondaryCoin: "",
      mark_up: "",
      mark_down: "",
      trading_fee: "",
      max: "",
      min: "",
      coinName1: "",
      max1: "",
      min1: "",
      buyRate: "",
      sellRate: "",
      sortBy: "",
      openModel: false,
      openModelFee: false,
      openModel1: false,
      openModel2: false,
      isUpdate: false,
      modalData: "",
      listType: "1",
      tokenId: "",
      _id: "",
      status1: "",
      setTotalData: {},
    };
  }

  toggleFee = () =>
    this.setState({
      openModelFee: !this.state.openModelFee,
    });
  openModelFee = (data) => {
    this.setState({
      openModelFee: true,
    });
  };
  componentDidMount = () => {
    this.getCoinValue();
    // this.getCoinList();
    this.props.getCurrencyList();
    this.props.getCoinList();
  };

  getCoinList = () => {
    const param = {
      searchKey: this.state.searchKey,
      page: 1,
      perPage: 100,
      // list_type: this.state.listType,
      status: "1",
    };
    this.props.getCoinList(param);
  };
  getCurrencyList = () => {
    const param = {
      searchKey: this.state.searchKey,
      page: 1,
      perPage: 100,
      // list_type: this.state.listType,
      status: "1",
    };
    this.props.getCurrencyList(param);
  };
  getCoinValue = () => {
    // status1
    const param = {
      searchKey: this.state.searchKey,
      page: this.state.page,
      perPage: this.state.perPage,
      status: this.state.status1.toString(),
    };
    // console.log("price_in_inr", param);
    this.props.getCoinValue(param);
  };
  onSearchList = () => {
    this.getCoinValue();
  };

  listByUser = (e, data) => {
    if (data.value !== "none") {
      this.setState({ status1: data.value }, () => {
        this.getCoinValue(); //
      });
    } else {
      this.setState({ status1: "" }, () => {
        this.getCoinValue(); //
      });
    }
  };
  listByBaseCoin = (e, data) => {
    if (data.value !== "none") {
      this.setState({ BaseCoin: data.value }, () => {
        this.getCoinValue(); //
      });
    } else {
      this.setState({ BaseCoin: "" }, () => {
        this.getCoinValue(); //
      });
    }
  };
  listBySecondaryCoin = (e, data) => {
    if (data.value !== "none") {
      this.setState({ SecondaryCoin: data.value }, () => {
        // this.getCoinValue(); //
      });
    } else {
      this.setState({ SecondaryCoin: "" }, () => {
        // this.getCoinValue(); //
      });
    }
  };

  toggle = () =>
    this.setState({
      openModel: !this.state.openModel,
    });
  toggle1 = () =>
    this.setState({
      openModel1: !this.openModel1,
    });
  toggle2 = () =>
    this.setState({
      openModel2: !this.openModel2,
    });

  openModel = (data) => {
    this.setState({
      openModel: true,
    });
  };
  openModel1 = (data) => {
    this.setState({
      openModel1: true,
      coinName1: data.symbol,
      _id: data.id,
      min1: data.min,
      max1: data.max,
      tokenId: data.id,
      isUpdate: true,
      isModal: false,
      isModal: false,
      setTotalData: data,
    });
  };
  openModel2 = (data) => {
    console.log(data, "coinsList ------->>> data1", this.props.coinsList);
    let filteredData = this.props.coinsList.find(
      (findValue) =>
        findValue.coin_symbol?.toLowerCase() == data.symbol?.toLowerCase()
    );
    console.log("coinsList ------->>> data1", filteredData);
    if (filteredData == undefined) {
      alert(`${data.symbol} is not listed on tarality`);
      return;
    }
    if (data.status == "0" || data.status == null) {
      this.setState({
        openModel2: true,
        coinName1: data.symbol,
        _id: data.id,
        min1: data.min,
        max1: data.max,
        tokenId: data.id,
        isUpdate: true,
        isModal: false,
        isModal: false,
        setTotalData: data,
      });
    } else {
      alert("To edit the coin, first disabled the coin!");
    }
  };

  // deleteCoinValue = (data1, id, currencyType) => {
  //   this.setState({isModal:true,
  //     min1: data.min,
  //     max1: data.max,
  //    })
  // }
  deleteCoinValue = (data1, id, currencyType) => {
    console.log("coinsList ------->>> data1", data1);
    let filteredData = this.props.coinsList.find(
      (findValue) =>
        findValue.coin_symbol.toLowerCase() == data1.symbol.toLowerCase()
    );

    if (filteredData == undefined && data1.status !== "1") {
      alert(`${data1.symbol} is not listed on tarality!`);
      return;
    }
    if (
      this.state.openModel1 &&
      (this.state.min1 == "" || this.state.max1 == "")
    ) {
      alert("All fields are required");
      return;
    }
    if (
      this.state.openModel1 &&
      (this.state.min1 == null || this.state.max1 == null)
    ) {
      alert("All fields are required");
      return;
    }
    if (
      this.state.openModel1 &&
      Number(this.state.min1) > Number(this.state.max1)
    ) {
      alert("Maximum amount must be grater then minimum");
      return;
    }
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
      status: this.state.status1,
    };

    const buttonState = { status: data1 };

    let params;
    if (this.state.openModel1) {
      params = {
        coin_id: this.state._id,
        min: this.state.min1,
        max: this.state.max1,
      };
    } else {
      params = {
        coin_id: data1.id,
        min: data1.min,
        max: data1.max,
      };
    }
    this.props.deleteCoinValue(params, data2);
    if (this.state.openModel1) {
      this.toggle1();
    }
  };
  editCoinPairs = (data1, id, currencyType) => {
    if (
      this.state.openModel2 &&
      (this.state.min1 == "" || this.state.max1 == "")
    ) {
      alert("All fields are required");
      return;
    }
    if (
      this.state.openModel2 &&
      (this.state.min1 == null || this.state.max1 == null)
    ) {
      alert("All fields are required");
      return;
    }
    if (
      this.state.openModel2 &&
      Number(this.state.min1) > Number(this.state.max1)
    ) {
      alert("Maximum amount must be grater then minimum");
      return;
    }
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    const buttonState = { status: data1 };

    let params;
    if (this.state.openModel2) {
      params = {
        coin_id: this.state._id,
        min: this.state.min1,
        max: this.state.max1,
      };
    } else {
      params = {
        coin_id: data1.id,
        min: data1.min,
        max: data1.max,
      };
    }
    console.log("params ------ results", params);
    this.props.deleteCoinValue(params, data2);
    if (this.state.openModel2) {
      this.toggle2();
    }
  };
  enableDisableCurrency = (data, id, currencyType) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    const buttonState = { status: data };

    const params = {
      currency_type: currencyType,
      currency_id: id,
      status: data,
    };
    this.props.updateCurrency(params, data2);
  };

  onChangePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getCoinValue();
    });
  };

  sortForBuy = (e, data) => {
    this.setState({ buyOrder: data.value }, () => {});
  };

  sortForSell = (e, data) => {
    this.setState({ sellOrder: data.value }, () => {});
  };

  updateCoinValueClick = (currency_type) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    if (
      this.state.coinName1 === "" ||
      this.state.min1 === "" ||
      this.state.max1 === ""
    ) {
      alert("all fields are required");
      return;
    }

    if (this.state.max1 < this.state.min1) {
      alert("Min value should be less than Max");
    } else {
      const data = {
        id: this.state.tokenId,
        coin_name: this.state.coinName1,
        min: this.state.min1,
        max: this.state.max1,
        // sell_rate_type: this.state.sellOrder,
        // currency_id: this.state.modalData.currency_id,
        // currency_type: this.state.currencyType,
      };
      this.props.updateCoinValue(data, this.state.modalData.id, data2);
      this.toggle1();
    }
  };
  addCoinValueClick = (currency_type) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    if (
      this.state.coinName === "" ||
      this.state.min === "" ||
      this.state.max === ""
    ) {
      alert("all fields are required");
      return;
    }

    if (this.state.max < this.state.min) {
      alert("Min value should be less than Max");
    } else {
      const data = {
        coin_name: this.state.coinName,
        min: this.state.min,
        max: this.state.max,
      };
      this.props.addCoinValue(data, this.state.modalData.id, data2);
      this.toggle();
    }
  };

  editFeeClick = (currency_type) => {
    if (
      // this.state.coinName === "" ||
      // this.state.sell_fee === "" ||
      // this.state.buy_fee === "" ||
      this.state.sell_fee === "" ||
      this.state.buy_fee === ""
    ) {
      alert("all fields are required");
      return;
    }

    const data = {
      buy_fee: this.state.buy_fee,
      sell_fee: this.state.sell_fee,
    };
    this.props.editFee(data);
    this.toggleFee();
    // }
  };
  render() {
    let { handleSubmit, coinsList } = this.props;
    let secondaryCoinList = [];
    coinsList.forEach((element) => {
      element.value = element.coin_symbol.toUpperCase();
      secondaryCoinList.push(element);
    });

    return (
      <div>
        <Header headerTitle="Order Book Management" />
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="order-book-engine">Order Book Engine</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>View Pair List</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>

                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>

                      <Dropdown
                        className="selectUserStyle btnMamagePermission"
                        options={UserFilter}
                        placeholder="Filter by order"
                        value={this.state.status1}
                        selection
                        onChange={(e, data) => this.listByUser(e, data)}
                      />

                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openModelFee()}
                      >
                        Add Pair
                      </Button>
                      {/* <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openModel()}
                      >
                        Add pairs
                      </Button> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Coin Id</th>
                        <th scope="col">Coin Name</th>
                        <th scope="col">Price In USDT</th>
                        <th scope="col">Price In INR</th>
                        <th scope="col">Min Price </th>
                        <th scope="col">Max Price </th>
                        {/* <th scope="col">Date</th> */}
                        <th scope=" col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.saveCoinValue?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.saveCoinValue?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.id}</td>
                            <td>{data.symbol}</td>
                            <td>{data.price}</td>
                            <td>{data.price_in_inr}</td>
                            <td>{data.min ? data.min : "None"}</td>
                            <td>{data.max ? data.max : "None"}</td>
                            <td>
                              {data.status == "0" ? "Inactive" : "Active"}
                            </td>
                            {/* <td>
                              {data.updated_at != ""
                                ? listDateFormat(data.updated_at)
                                : "None"}
                            </td> */}

                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color={
                                    data.status != "1" ? "success" : "danger"
                                  }
                                  onClick={() => {
                                    if (data.max > 0) {
                                      this.deleteCoinValue(data);
                                    } else {
                                      // this.EnableModal(data.id);
                                      this.openModel1(data);
                                    }
                                  }}
                                >
                                  {data.status == "1" ? "Disable" : "Enable"}
                                </Button>

                                <Button
                                  color="success"
                                  onClick={() => this.openModel2(data)}
                                >
                                  Edit Pair
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.onChangePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(this.props.total)}
                        />
                      </nav>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        <Modal isOpen={this.state.openModel1} toggle={this.toggle1}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Coin Name</label>

                  <input
                    name="coin_name1"
                    placeholder="Coin Name"
                    value={this.state.coinName1}
                    type="text"
                    disabled={true}
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ coinName1: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Min</label>
                  <input
                    name="Min"
                    placeholder="Min"
                    value={this.state.min1}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ min1: e.target.value })
                    }
                  ></input>
                  {/* <Dropdown
                    className="selectUserStyle"
                    placeholder="Min"
                    options={options}
                    selection
                    value={this.state.buyOrder}
                    onChange={(e, data) => this.sortForBuy(e, data)}
                  />{" "} */}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Max</label>

                  <input
                    name="max1"
                    placeholder="Max"
                    value={this.state.max1}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ max1: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel1: false })}
            >
              Cancel
            </Button>
            <Button
              color="success"
              // onClick={() => this.updateCoinValueClick()}
              onClick={() => this.deleteCoinValue(this.state.setTotalData)}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.openModel2} toggle={this.toggle2}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Coin Name</label>

                  <input
                    name="coin_name1"
                    placeholder="Coin Name"
                    value={this.state.coinName1}
                    type="text"
                    disabled={true}
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ coinName1: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Min</label>
                  <input
                    name="Min"
                    placeholder="Min"
                    value={this.state.min1}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ min1: e.target.value })
                    }
                  ></input>
                  {/* <Dropdown
                    className="selectUserStyle"
                    placeholder="Min"
                    options={options}
                    selection
                    value={this.state.buyOrder}
                    onChange={(e, data) => this.sortForBuy(e, data)}
                  />{" "} */}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Max</label>

                  <input
                    name="max1"
                    placeholder="Max"
                    value={this.state.max1}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ max1: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel2: false })}
            >
              Cancel
            </Button>
            <Button
              color="success"
              // onClick={() => this.updateCoinValueClick()}
              onClick={() => this.editCoinPairs()}
            >
              Edit Pairs
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.openModel} toggle={this.toggle}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Coin Name</label>

                  <input
                    name="coin_mname"
                    placeholder="Coin Name"
                    value={this.state.coinName}
                    type="text"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ coinName: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Min</label>
                  <input
                    name="Min"
                    placeholder="Min"
                    value={this.state.min}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ min: e.target.value })
                    }
                  ></input>
                  {/* <Dropdown
                    className="selectUserStyle"
                    placeholder="Min"
                    options={options}
                    selection
                    value={this.state.buyOrder}
                    onChange={(e, data) => this.sortForBuy(e, data)}
                  />{" "} */}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Max</label>

                  <input
                    name="max"
                    placeholder="Max"
                    value={this.state.max}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ max: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.addCoinValueClick()}>
              Add Pair
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.openModelFee} toggle={this.toggleFee}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Base Coin</label>

                  <Dropdown
                    className="selectUserStyle btnMamagePermission"
                    options={UseBaseCoin}
                    placeholder="select base coin"
                    value={this.state.BaseCoin}
                    selection
                    onChange={(e, data) => this.listByUser(e, data)}
                  />
                </Form.Field>
                {/* </Form.Group>
              <Form.Group> */}
                <Form.Field>
                  <label>Secondary Coin</label>

                  <Dropdown
                    className="selectUserStyle btnMamagePermission"
                    options={secondaryCoinList}
                    placeholder="select base coin"
                    value={this.state.SecondaryCoin}
                    selection
                    onChange={(e, data) => this.listByUser(e, data)}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Mark Up</label>
                  <input
                    name="sell_fee"
                    placeholder="Mark Up"
                    value={this.state.mark_up}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ mark_up: e.target.value })
                    }
                  ></input>
                </Form.Field>
                {/* </Form.Group>
              <Form.Group> */}
                <Form.Field>
                  <label>Mark Down</label>
                  <input
                    name="buy_fee"
                    placeholder="Mark Down"
                    value={this.state.mark_down}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ mark_down: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Min</label>
                  <input
                    name="buy_fee"
                    placeholder="Min"
                    value={this.state.min}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ min: e.target.value })
                    }
                  ></input>
                </Form.Field>
                {/* </Form.Group>
              <Form.Group> */}
                <Form.Field>
                  <label>Max</label>
                  <input
                    name="buy_fee"
                    placeholder="Max"
                    value={this.state.max}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ max: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Plan Tenure Type</label>
                  <input
                    name="buy_fee"
                    placeholder="plan_tenure_type"
                    value={this.state.plan_tenure_type}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ plan_tenure_type: e.target.value })
                    }
                  ></input>
                </Form.Field>
                {/* </Form.Group>
              <Form.Group> */}
                <Form.Field>
                  <label>Mark Type</label>
                  <input
                    name="buy_fee"
                    placeholder="Mark Type"
                    value={this.state.mark_type}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ mark_type: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Trading Fee</label>
                  <input
                    name="buy_fee"
                    placeholder="Trade Fee"
                    value={this.state.trading_fee}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ trading_fee: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModelFee: false })}
            >
              Cancel
            </Button>
            <Button
              color="success"
              // onClick={() => this.editFeeClick()}
            >
              Add Pair
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    getCoinList: state.user.getCoinList,
    coinsList: state.user.coinsList,
    totalCount: state.user.saveCoinValuesCount,
    saveCoinValue: state.user.saveCoinValues,
    total: state.user.saveCoinValuesTotalPages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrencyList: (data) => dispatch(getCurrencyList(data)),
    // editFee: (data, id, data2) => dispatch(editFee(data)),
    getCoinValue: (data) => dispatch(getCoinValue(data)),
    getCoinList: (data) => dispatch(getCoinList()),
    updateCurrency: (data, data2) => dispatch(updateCurrency(data, data2)),
    deleteCoinValue: (data, data2) => dispatch(deleteCoinValue(data, data2)),
    addCoinValue: (data, id, data2) => dispatch(addCoinValue(data, id, data2)),
    updateCoinValue: (data, id, data2) =>
      dispatch(updateCoinValue(data, id, data2)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewOrderList)
);
