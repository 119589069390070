import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Pagination, Confirm } from "semantic-ui-react";
import { Field, reduxForm, change } from "redux-form";
import CustomHeader from "../../components/Headers/CustomHeader";
import { API_HOST } from "../../constants/index";

import { withRouter } from "react-router";
import {
  getOtcOrderList,
  ordertradershistory,
  cancelOrder,
} from "../../Redux/actons/otc.action";
import { Link } from "react-router-dom";
import { convertExponentialToDecimal } from "../../utils/utils";

export class OtcOrderListManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      open: false,
      openConfirm: false,
    };
  }

  componentDidMount = () => {
    this.getOrderList();
    this.checkRouteValid();
  };
  cancelOrderOpen = (id) => {
    this.setState({ openConfirm: true, cancelId: id });
  };
  close = () => {
    this.setState({ openConfirm: false, cancelId: "" });
  };
  onConfirmation = async () => {
    let data = {
      searchKey: this.state.searchKey,

      perPage: this.state.perPage,
      page: this.state.page,
    };
    await this.props.cancelOrder(this.state.cancelId, data);
    this.close();
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      var isfound = false;
      // check user Permissions if route accessible
      await this.props.permissions.forEach((element) => {
        if (element.title == "OTC Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };
  openModel = (id) => {
    this.props.ordertradershistory(id);
    this.setState({ open: true });
  };
  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  getOrderList = () => {
    let data = {
      searchKey: this.state.searchKey,

      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.getOtcOrderList(data);
  };

  // //page change
  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getOrderList();
    });
  };

  //search
  onSearchList = (abc) => {
    console.log(abc);
    this.setState({ searchKey: abc, page: 1 }, () => {
      this.getOrderList();
    });
  };

  downLoadOrderList = () => {
    window.location.href = `${API_HOST}api/v1/admin/otc/download/orders`;
  };

  render() {
    return (
      <div>
        <CustomHeader />
        <div>
          <Modal isOpen={this.state.openConfirm} toggle={this.close}>
            <ModalHeader toggle={this.close}>
              Are you sure you want to cancel order ?
            </ModalHeader>
            <ModalBody></ModalBody>
            <ModalFooter>
              <Button color="error" onClick={() => this.close()}>
                Cancel
              </Button>
              <Button color="success" onClick={() => this.onConfirmation()}>
                Confirm
              </Button>
            </ModalFooter>
          </Modal>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0" style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-0">OTC Order List</h3>
                      <div className="selectSearchOption">
                        <Input
                          placeholder="Search"
                          style={{ width: "500px" }}
                          onChange={(e) =>
                            this.onSearchList(`${e.target.value}`)
                          }
                        ></Input>
                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.downLoadOrderList()}
                        >
                          Download Order List
                        </Button>
                      </div>
                    </div>

                    <Link to="otc-management">
                      <Button color="success">Back</Button>
                    </Link>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">ID (Email)</th>
                        <th scope="col">Buying</th>
                        <th scope="col">Selling</th>
                        <th scope="col">Min. Limit</th>
                        <th scope="col">Max. Limit</th>
                        <th scope="col">Price</th>
                        <th scope="col">Status</th>
                        <th scope="col">IP Address</th>
                        <th scope="col">Tx History</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.getOrderList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          <td>{usersList.order_user.email}</td>
                          <td>{usersList.buy_symbol.toUpperCase()}</td>
                          <td>{usersList.sell_symbol.toUpperCase()}</td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.minimum_limit)
                            )}
                          </td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.maximum_limit)
                            )}
                          </td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.price_per_unit)
                            )}
                          </td>
                          <td>
                            {usersList.status == 0
                              ? "Deactivate"
                              : usersList.status == 1
                              ? "Activate"
                              : usersList.status == 2
                              ? "Completed"
                              : "Cancelled"}
                          </td>
                          <td>
                            {" "}
                            {usersList.ip_address
                              ? usersList.ip_address
                              : "N/A"}{" "}
                          </td>

                          <td className="text-left">
                            <span className="mb-0 text-sm">
                              {" "}
                              <Button
                                color="success"
                                onClick={() => this.openModel(usersList.id)}
                              >
                                View
                              </Button>
                              {usersList.status !== 3 &&
                                usersList.status !== 2 && (
                                  <Button
                                    color="success"
                                    onClick={() =>
                                      this.cancelOrderOpen(usersList.id)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                )}
                            </span>
                          </td>
                        </tr>
                      ))}
                      {this.props.getOrderList.length == 0 && (
                        <td>No record</td>
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      {this.props.totalOrderRecords > 0 && (
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalOrderRecords / this.state.perPage
                          )}
                        />
                      )}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        {/* {this.props.traderHistory.length > 0 && ( */}
        <div className="otc_modal">
          <Modal isOpen={this.state.open} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}> Transaction History</ModalHeader>
            <ModalBody style={{ marginBottom: 40 }}>
              <Table className="align-items-center table-flush" responsive>
                {this.props.traderHistory.length > 0 && (
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Trader User</th>
                      <th scope="col">Trader Owner</th>
                      <th scope="col">buy Asset</th>
                      <th scope="col">Sell Asset</th>

                      <th scope="col">Amount </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {this.props.traderHistory.map((history, index) => (
                    <tr className="table-tr">
                      {history.trade_owner != null ? (
                        <td>{history.trade_owner.email}</td>
                      ) : (
                        <td>None</td>
                      )}
                      {history.trade_user != null ? (
                        <td>{history.trade_user.email}</td>
                      ) : (
                        <td>None</td>
                      )}
                      <td>{history.buy_symbol}</td>

                      <td>{history.sell_symbol}</td>

                      <td>{history.amount}</td>
                    </tr>
                  ))}
                  {this.props.traderHistory.length == 0 && (
                    <tr>No history found</tr>
                  )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
        </div>
        {/* )} */}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log("@@@@@@>>>>>>>ritu.", state);
  return {
    getOrderList: state.otc.getOrderList,
    totalOrderRecords: state.otc.totalOrderRecords,
    traderHistory: state.otc.traderHistory,
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOtcOrderList: (data) => dispatch(getOtcOrderList(data)),
    ordertradershistory: (data) => dispatch(ordertradershistory(data)),
    cancelOrder: (id, fdata) => dispatch(cancelOrder(id, fdata)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "OtcOrderListManagement" })(OtcOrderListManagement))
);
