import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import { changeKycStatus } from "../../../Redux/actons/userList.action";
import AddComment from "../AddComment";
import { IMAGE_PATH } from "constants/index";

export function includTextImage(text) {
  const fileterinc = text.includes(IMAGE_PATH);
  return fileterinc ? text : `${IMAGE_PATH}` + text;
}

export class Leve3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCommentOpen: false,
      id: 0,
      action: 1,
    };
  }

  verifyKyc = (action) => {
    let id = this.props.userDetail?.user_id;
    if (action == 2) {
      this.addComment(id, action);
    } else {
      let data = {
        action: action,
      };

      this.props.changeKycStatus(id, data, this.props.formData);
      this.props.toggle2();
    }
  };
  addComment = (id, action) => {
    this.setState({
      action: action,
      id: id,
      isCommentOpen: true,
    });
  };
  openCloseCommentModal = () => {
    this.setState({
      isCommentOpen: !this.state.isCommentOpen,
    });
  };
  acceptRejectKyc = async (values = {}) => {
    console.log(
      "comment >> >>",
      values,
      this.state.action,
      this.state.id,
      this.props.formData
    );
    let data = {
      action: this.state.action,
      comment: values.message,
    };
    this.props.changeKycStatus(this.state.id, data, this.props.formData);
    this.props.toggle2();
    this.openCloseCommentModal();
  };

  render() {
    let { userDetail } = this.props;
    console.log(
      userDetail?.pancard_image,
      "https://prod-taral.s3.amazonaws.com/ ----- slice ",
      userDetail?.pancard_image?.slice("https://prod-taral.s3.amazonaws.com/")
    );
    let name =
      userDetail?.pan_card != "" ? JSON.parse(userDetail?.pan_card) : "";
    return (
      <div>
        <Modal
          isOpen={this.props.modal2}
          toggle={this.props.toggle2}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.toggle2}>
            <h1>Document Info</h1>
          </ModalHeader>
          <ModalBody>
            <div className="userNameList">
              <div className="itemStyle">
                <label>Name:</label>
                <span>{userDetail?.name ? userDetail?.name : "None"}</span>
              </div>
              <div className="itemStyle">
                <label>Address:</label>
                <span>
                  {userDetail?.address ? userDetail?.address : "None"}
                </span>
              </div>
              <div className="itemStyle">
                <label>KYC Type:</label>
                <span style={{ textTransform: "" }}>
                  {userDetail?.kyc_type ? (
                    <span style={{ textTransform: "uppercase" }}>
                      {userDetail?.kyc_type}
                    </span>
                  ) : (
                    "None"
                  )}
                </span>
              </div>
              {userDetail?.document_number && (
                <div className="itemStyle">
                  <label>Aadhaar Number</label>
                  <span>
                    {userDetail?.document_number
                      ? userDetail?.document_number
                      : "None"}
                  </span>
                </div>
              )}
              {userDetail?.dl_number && (
                <div className="itemStyle">
                  <label>DL Number</label>
                  <span>
                    {userDetail?.dl_number ? userDetail?.dl_number : "None"}
                  </span>
                </div>
              )}
              {userDetail?.passport_number && (
                <div className="itemStyle">
                  <label>Passport Number</label>
                  <span>
                    {userDetail?.passport_number
                      ? userDetail?.passport_number
                      : "None"}
                  </span>
                </div>
              )}
              {userDetail?.pancard_number && (
                <div className="itemStyle">
                  <label>PAN Number</label>
                  <span>
                    {userDetail?.pancard_number
                      ? userDetail?.pancard_number
                      : "None"}
                  </span>
                </div>
              )}
              {name && name?.data?.full_name && (
                <div className="itemStyle">
                  <label>Name on PAN</label>
                  <label>{name ? name?.data?.full_name : "None"}</label>
                </div>
              )}
              {userDetail?.rejection_comment && (
                <div className="itemStyle">
                  <label>Rejection Comment</label>
                  <span style={{ textDecoration: "none" }}>
                    {userDetail?.rejection_comment
                      ? userDetail?.rejection_comment
                      : "None"}
                  </span>
                </div>
              )}
              <div style={{ width: "100%", height: "50%", padding: 10 }}>
                {userDetail?.doc_image1 && (
                  <span>
                    <img
                      onClick={() =>
                        window.open(`${includTextImage(userDetail?.doc_image1)}`, "_blank")
                      }
                      style={{
                        cursor: "pointer",
                        width: "50%",
                        height: 200,
                      }}
                      src={
                        userDetail?.doc_image1
                          ? `${includTextImage(userDetail?.doc_image1)}`
                          : "No Image"
                      }
                      // src={
                      //   userDetail?.doc_image1
                      //     ? userDetail?.doc_image1
                      //     : "No image"
                      // }
                    ></img>
                  </span>
                )}
                {userDetail?.doc_image2 && (
                  <span style={{ cursor: "pointer" }}>
                    <img
                      onClick={() =>
                        window.open(`${includTextImage(userDetail.doc_image2)}`, "_blank")
                      }
                      style={{ width: "50%", height: 200 }}
                      src={
                        userDetail?.doc_image2
                          ? `${includTextImage(userDetail?.doc_image2)}`
                          : "No image"
                      }
                    ></img>
                  </span>
                )}
                {userDetail?.doc_image3 && (
                  <span style={{ cursor: "pointer" }}>
                    <img
                      onClick={() =>
                        window.open(`${includTextImage(userDetail?.doc_image3)}`, "_blank")
                      }
                      style={{ width: "50%", height: 200 }}
                      src={
                        userDetail?.doc_image3
                          ? `${includTextImage(userDetail?.doc_image3)}`
                          : "No Image"
                      }
                      // src={
                      //   userDetail?.doc_image3
                      //     ? userDetail?.doc_image3
                      //     : "No image"
                      // }
                    ></img>
                  </span>
                )}
                {userDetail?.pancard_image && (
                  <span style={{ cursor: "pointer" }}>
                    <img
                      onClick={() =>
                        window.open(`${includTextImage(userDetail?.pancard_image)}`, "_blank")
                      }
                      style={{ width: "50%", height: 200 }}
                      src={
                        userDetail?.pancard_image
                          ? `${includTextImage(userDetail?.pancard_image)}`
                          : "No Image"
                      }
                      // src={
                      //   userDetail?.pancard_image
                      //     ? userDetail?.pancard_image
                      //     : "No image"
                      // }
                    ></img>
                  </span>
                )}

                {userDetail?.passport_image && (
                  <span style={{ cursor: "pointer" }}>
                    <img
                      onClick={() =>
                        window.open(`${includTextImage(userDetail?.passport_image)}`, "_blank")
                      }
                      style={{ width: "50%", height: 200 }}
                      src={
                        userDetail?.passport_image
                          ? `${includTextImage(userDetail?.passport_image)}`
                          : "No Image"
                      }
                      // src={
                      //   userDetail?.passport_image
                      //     ? userDetail?.passport_image
                      //     : "No image"
                      // }
                    ></img>
                  </span>
                )}

                {userDetail?.passport_image_back && (
                  <span style={{ cursor: "pointer" }}>
                    <img
                      onClick={() =>
                        window.open(
                          `${includTextImage(userDetail?.passport_image_back)}`,
                          "_blank"
                        )
                      }
                      style={{ width: "50%", height: 200 }}
                      src={
                        userDetail?.passport_image_back
                          ? `${includTextImage(userDetail?.passport_image_back)}`
                          : "No image"
                      }
                    ></img>
                  </span>
                )}
                {userDetail?.dl_image && (
                  <span style={{ cursor: "pointer" }}>
                    <img
                      onClick={() =>
                        window.open(`${includTextImage(userDetail?.dl_image)}`, "_blank")
                      }
                      style={{ width: "50%", height: 200 }}
                      src={
                        userDetail?.dl_image ? includTextImage(userDetail?.dl_image) : "No image"
                      }
                    ></img>
                  </span>
                )}
                {userDetail?.dl_image_back && (
                  <span style={{ cursor: "pointer" }}>
                    <img
                      onClick={() =>
                        window.open(`${includTextImage(userDetail?.dl_image_back)}`, "_blank")
                      }
                      style={{ width: "50%", height: 200 }}
                      src={
                        userDetail?.dl_image_back
                          ? includTextImage(userDetail?.dl_image_back)
                          : "No image"
                      }
                    ></img>
                  </span>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {/* 0=>docNotUploaded,1=>pending,2=>approved,3=>rejected 	 */}
            {userDetail?.kyc_status !== "2" && (
              <Button color="success" onClick={() => this.verifyKyc(1)}>
                Verify
              </Button>
            )}
            {userDetail?.kyc_status !== "3" && (
              <Button color="danger" onClick={() => this.verifyKyc(2)}>
                Reject
              </Button>
            )}
          </ModalFooter>
        </Modal>
        <AddComment
          isCommentOpen={this.state.isCommentOpen}
          openCloseCommentModal={this.openCloseCommentModal}
          acceptRejectKyc={this.acceptRejectKyc}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    changeKycStatus: (id, data, fdata) =>
      dispatch(changeKycStatus(id, data, fdata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Leve3);
