import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  getFiatBalance,
  updateFiatBalance,
} from "../../Redux/actons/bank.action";
import { reset } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Pagination, Form } from "semantic-ui-react";
import { Field } from "redux-form";
import { FormField } from "../../components/FormField";
import { date, required } from "redux-form-validators";
import { amountLimit } from "../../validators/customValidator";
import Input from "reactstrap/lib/Input";
import { Link } from "react-router-dom";

export class FiatManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      order: "asc",
      opneModal: false,
      userData: "",
      searchKey: "",
    };
  }
  componentDidMount = () => {
    this.getFiatList();
  };

  getFiatList = () => {
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      order: this.state.order,
      searchKey: this.state.searchKey,
    };
    this.props.getFiatBalanceList(params);
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getFiatList();
    });
  };

  openModal = (data) => {
    console.log("userData", data);
    this.setState({ userData: data }, () => {
      this.toggleModal();
    });
  };

  close = () => {
    this.setState({ opneModal: false });
  };

  toggleModal = () => {
    this.setState({ opneModal: !this.state.opneModal });
  };

  addFunds = (data) => {
    const params = {
      amount: data.balance,
      id: data.user_id,
    };
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
      order: this.state.order,
      searchKey: this.state.searchKey,
    };

    this.props.updateFiatBalance(params, data2);
    this.close();
  };

  onSearchFee = (search) => {
    // this.setState({ searchKey: search, page: 1 }, () => {
    this.getFiatList();
    // });
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Fiat Balance" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="fund-management">Fund Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Fiat Balance</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchFee();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchFee()}
                      >
                        Search
                      </Button>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Balance</th>
                        <th scope="col">Add Funds</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.total === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : this.props.fiatList ? (
                        this.props.fiatList.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.email}</td>
                            <td>{data.balance ? data.balance : "0"}</td>
                            <td>
                              {" "}
                              <span className="mb-0 text-sm">
                                <Button
                                  color="success"
                                  onClick={() => this.openModal(data)}
                                >
                                  Add funds
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        console.log("emp list")
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.opneModal}
          toggle={this.toggleModal}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.toggleModal}>
            <h1>
              <span>Add Funds</span>
            </h1>
          </ModalHeader>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label style={{ color: "#fff" }}>Amount</label>
                  <input
                    type="number"
                    min="0"
                    value={this?.state?.userData?.balance}
                    validate={[required(), amountLimit()]}
                    component={FormField}
                    placeholder="Amount"
                    onChange={(e) => {
                      let value = e?.target?.value;
                      this.setState((prevState) => ({
                        userData: {
                          ...prevState.userData,
                          balance: value,
                        },
                      }));
                    }}
                  ></input>
                </Form.Field>
              </Form.Group>
              <Form.Field className="addBtn">
                <Button
                  color="success"
                  onClick={() => this.addFunds(this?.state?.userData)}
                >
                  Add
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    fiatList: state.bankAccount.fiatDetails.history,
    total: state.bankAccount.fiatDetails.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFiatBalanceList: (data) => dispatch(getFiatBalance(data)),
    updateFiatBalance: (data, data2) =>
      dispatch(updateFiatBalance(data, data2)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FiatManagement)
);
