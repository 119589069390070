import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import Web3 from "web3";
import axios from "axios";
import { default_RPC_URL } from "./ContractAddress";

export const mulltiCallAddressBnb =
  "0x32F8647b2ff89B454170A25037D7643d219d6A19";

export function fromWeiDecimals(balanceOfWei, decimals) {
  // console.log(balanceOfWei, "========>>>>>>>", decimals);
  if (balanceOfWei) {
    const balanceOfFromWei12 = ethers.utils.formatUnits(
      balanceOfWei.toString(),
      decimals
    );
    return balanceOfFromWei12;
  } else {
    return balanceOfWei;
  }
}
export function toWeiDecimals(balanceOfWei, decimals) {
  if (balanceOfWei) {
    const balanceOfFromWei = ethers.utils.parseUnits(
      balanceOfWei.toString(),
      decimals
    );
    return balanceOfFromWei.toString();
  } else {
    return balanceOfWei;
  }
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getWeb3Obj = async (RPC_URL = default_RPC_URL) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = async (
  abi,
  contractAddress,
  RPC_URL = default_RPC_URL
) => {
  const web3 = await getWeb3Obj(RPC_URL);
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};

// export function copyTextById(id) {
//   var copyText = document.getElementById(id);
//   copyText.select();
//   copyText.setSelectionRange(0, 99999); /* For mobile devices */
//   navigator.clipboard.writeText(copyText.value);
//   toast.info(`Copied ${copyText.value}`);
// }

// export const swichNetworkHandler = async () => {
//   try {
//     await window.ethereum.request({
//       method: "wallet_switchEthereumChain",
//       params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
//     });
//   } catch (error) {
//     console.log("ERROR", error);
//     toast.warn(error.message);
//     if (error.code === 4902) {
//       addNetworkHandler();
//     }
//   }
// };

// export const addNetworkHandler = async () => {
//   try {
//     await window.ethereum.request({
//       method: "wallet_addEthereumChain",
//       params: NetworkDetails,
//     });
//   } catch (error) {
//     console.log("ERROR", error);
//     toast.warn(error.message);
//   }
// };
