import React, { Component } from "react";
import CustomHeader from "components/Headers/CustomHeader";
import Header from "components/Headers/Header";
import { Form, label } from "semantic-ui-react";
import { Button, Table } from "reactstrap";

import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../../components/FormField";
import { SelectField } from "../../../components/SelectField";
import { email, required } from "redux-form-validators";
import {
  getAdminSettings,
  saveFeeSettingDetails,
  updateAdminSetting,
} from "../../../Redux/actons/account.action";
import {
  amountLimit,
  amountValidator,
} from "../../../validators/customValidator";
// import "./OtcLevelSetting.scss";
import { getCoinList } from "../../../Redux/actons/user.action";
import {
  getCoinLimitSetting,
  setCoinLimitSetting,
} from "../../../Redux/actons/otc.action";
const options = [
  { key: "m", text: "KYC Level 0", value: "kyc_level_zero_transaction_limit" },
  { key: "f", text: "KYC Level 1", value: "kyc_level_one_transaction_limit" },
  { key: "k", text: "KYC Level 2", value: "kyc_level_two_transaction_limit" },
];
const actions = [
  { key: "m", text: "Activate", value: 1 },
  { key: "f", text: "Inactivate", value: 2 },
];

export class KycLevelSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isType: false,
      defaultType: "",
      defaultCoin: "",
      defaultAction: 1,
      selectedCoin: [],
    };
  }
  componentDidMount = () => {
    this.props.dispatch(change("KycSettingForm", "status", 1));
  };
  componentWillUnmount = () => {
    this.checkRouteValid();

    this.props.saveFeeSettingDetails([]);
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      var isfound = false;
      // check user Permissions if route accessible
      await this.props.permissions.forEach((element) => {
        if (element.title == "Kyc Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };

  onSelectCoin = (e, title, id) => {
    let coin = [...this.state.selectedCoin];
    if (e.target.checked) {
      coin.push(id);
      this.setState({ selectedCoin: coin });
    } else {
      for (var i = 0; i < coin.length; i++) {
        if (coin[i] == id) {
          coin.splice(i, 1);
        }
      }
      this.setState({ selectedCoin: coin });
    }
  };

  selectAllCoin = (e, data) => {
    let coin = [];
    let coinList = data;
    if (e.target.checked) {
      for (var i = 0; i < coinList?.length; i++) {
        coin.push(coinList[i].coin_id);
        // console.log(coin);
      }
      this.setState({ selectedCoin: coin });
    } else {
      for (var i = 0; i < coinList?.length; i++) {
        coin.splice(i, 1);
        // console.log(coin);
      }

      this.setState({ selectedCoin: coin });
    }
  };

  setFormValues = (data) => {
    // console.log("DATA", data);
    if (data.length > 0) {
      this.setState({
        defaultType: data[0].type,
        defaultAction: data[0].status,
        isType: true,
      });
      this.props.dispatch(change("KycSettingForm", "status", data[0].status));
      this.props.dispatch(change("KycSettingForm", "value", data[0].value));
    } else {
      this.props.dispatch(change("KycSettingForm", "status", 1));
      this.props.dispatch(change("KycSettingForm", "value", ""));
    }
  };
  //   componentWillReceiveProps = (nextProps) => {
  //     if (this.props.feeSettingDetails !== nextProps.feeSettingDetails) {
  //       this.setFormValues(nextProps.feeSettingDetails);
  //     }
  //   };
  componentWillReceiveProps = (nextProps) => {
    if (this.props.coinSetting !== nextProps.coinSetting) {
      this.setFormValues(nextProps.coinSetting);
    }
  };
  //   getDetailsType = (e, data) => {
  //     this.props.dispatch(change("KycSettingForm", "status", 1));
  //     this.setState({ isType: true, defaultAction: 1, defaultType: data });

  //     let param = {
  //       type: data,
  //     };
  //     this.props.getAdminSettings(param);
  //   };
  getDetailsType = (e, data) => {
    this.setState({ defaultAction: 1, defaultType: data });

    let param = {
      type: data,
      coin_id: this.state.defaultCoin,
    };
    // this.props.getAdminSettings(param);
    if (this.state.defaultCoin !== "") {
      this.props.dispatch(change("KycSettingForm", "status", ""));

      this.setState({ isType: true });

      //   this.props.getAdminSettings(param);
      this.props.getCoinLimitSetting(param);
    }
  };
  getDetailsCoin = (e, data) => {
    this.setState({ defaultCoin: data });
    let param = {
      type: this.state.defaultType,
      coin_id: data,
    };
    if (this.state.defaultType !== "") {
      this.props.dispatch(change("KycSettingForm", "value", ""));

      this.setState({ isType: true });
      //   this.props.getAdminSettings(param);
      this.props.getCoinLimitSetting(param);
    }
  };
  setStatus = (e, data) => {
    this.props.dispatch(change("KycSettingForm", "status", data));
    this.setState({ isType: true, defaultAction: data });
  };

  updateForm = (values) => {
    const param = {
      coin_id: this.state.selectedCoin,
      type: values.type,
      value: values.value,
    };

    // console.log("calue", param);
    this.props.setCoinLimitSetting(param);
  };
  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Kyc Level Transaction Settings" />
        <div style={{ padding: 30 }}>
          <label>Select Coin</label>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-dark">
              <tr>
                <th scope="col">
                  <input
                    name=""
                    component={FormField}
                    type="checkbox"
                    label=""
                    onChange={(e) =>
                      this.selectAllCoin(e, this.props.coinsList)
                    }
                  />
                </th>
                <th scope="col">Coin Name</th>
              </tr>
            </thead>
            <tbody>
              {this.props.coinsList?.length === 0 ? (
                <tr>
                  <td>No results found</td>
                </tr>
              ) : (
                this.props.coinsList?.map((data, index) => (
                  <tr className="table-tr" key={index}>
                    <td>
                      <input
                        name=""
                        component={FormField}
                        type="checkbox"
                        label=""
                        onChange={(e) =>
                          this.onSelectCoin(e, data.coin_name, data.coin_id)
                        }
                        checked={this.state.selectedCoin.includes(data.coin_id)}
                      />
                    </td>
                    <td>{data.text.toUpperCase()}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>

          {this.state.selectedCoin?.length !== 0 && (
            <Form onSubmit={handleSubmit(this.updateForm)}>
              <Form.Group>
                <Form.Field>
                  <label>Choose Type</label>
                  <Field
                    name="type"
                    search={false}
                    selectOnBlur={false}
                    validate={[required()]}
                    component={SelectField}
                    defaultValue={this.state.defaultType}
                    children={options}
                    placeholder="Select "
                    onChange={(e, data) => this.getDetailsType(e, data)}
                  ></Field>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Limit</label>
                  <Field
                    type="number"
                    name="value"
                    validate={[required(), amountLimit()]}
                    component={FormField}
                    placeholder="Limit"
                  ></Field>
                </Form.Field>
              </Form.Group>
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    feeSettingDetails: state.account.feeSettingDetails,
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
    coinsList: state.user.coinsList,
    coinSetting: state.otc.coinSetting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAdminSettings: (data) => dispatch(getAdminSettings(data)),
    updateAdminSetting: (data) => dispatch(updateAdminSetting(data)),
    saveFeeSettingDetails: (data) => dispatch(saveFeeSettingDetails(data)),
    getCoinList: () => dispatch(getCoinList()),
    setCoinLimitSetting: (data) => dispatch(setCoinLimitSetting(data)),
    getCoinLimitSetting: (data) => dispatch(getCoinLimitSetting(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "KycSettingForm" })(KycLevelSetting));
