import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
  Tooltip,
} from "reactstrap";
import {
  getLockFundHistory,
  unLockUserFunds,
  unLockUserFundsVesting,
} from "../../Redux/actons/fund.action";
import { openCloseModal } from "../../Redux/actons/SubAdmin.action";
import { Link, withRouter } from "react-router-dom";
import Header from "components/Headers/Header";
import { Pagination, Dropdown, Form } from "semantic-ui-react";
import swapIcon from "../../images/swap.png";
import { listDateFormat } from "../../utils/utils";
import { required } from "redux-form-validators";
import { amountLimit } from "../../validators/customValidator";
import { FormField } from "../../components/FormField";
import { reset, Field, reduxForm, getFormValues } from "redux-form";
import { SelectField } from "../../components/SelectField";
import moment from "moment";
const options = [
  { key: "k", text: "None", value: "" },
  { key: "m", text: "Admin", value: "admin" },
  { key: "l", text: "User", value: "user" },
];

const statusOptions = [
  { key: "k", text: "None", value: "" },
  { key: "m", text: "Locked", value: "locked" },
  { key: "l", text: "Unlocked", value: "unlocked" },
];

export class LockFundHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      userId: "",
      lockedBy: "admin",
      status: "",
      opneModal: false,
      getUserId: {},

      tooltipOpen12: false,
    };
    this.toggle12 = this.toggle12.bind(this);
    // this.state = {
    // };
  }
  //   };
  // }

  toggle12() {
    this.setState({
      tooltipOpen12: !this.state.tooltipOpen12,
    });
  }

  componentDidMount = async () => {
    if (this.props.location.search.split("?")[1]) {
      await this.getPairList(this.props.location.search.split("?")[1]);
    } else {
      this.props.history.goBack();
    }
  };
  componentWillUnmount = () => {
    // this.props.openCloseModal();
  };

  getPairList = (userId) => {
    this.setState({ userId: userId });
    const data = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      userId: userId,
      locked_by: this.state.lockedBy,
      status: this.state.status,
    };
    this.props.getLockFundHistory(data);
  };
  close = () => {
    this.setState({ opneModal: false });
  };

  unlockUserFundModals = (id) => {
    this.setState({ opneModal: !this.state.opneModal, getUserId: id });
    // };
    // unlockUserFundModals = (id) => {
    const buttonState = { id: id.id };
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      userId: this.state.userId,
      locked_by: this.state.lockedBy,
      status: this.state.status,
    };
  };
  unlockUserFund = (amount, id) => {
    const buttonState = { id: id, token: amount };
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      userId: this.state.userId,
      locked_by: this.state.lockedBy,
      status: this.state.status,
    };
    this.props.unLockUserFundsVesting(buttonState, params);
    this.close();
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getPairList(this.state.userId);
    });
  };

  onSearchList = (search) => {
    // this.setState({ searchKey: search }, () => {
    this.getPairList(this.state.userId);
    // });
  };

  filterBy = (e, data) => {
    this.setState({ lockedBy: data.value }, () => {
      this.getPairList(this.state.userId);
    });
  };

  filterByStatus = (e, data) => {
    this.setState({ status: data.value }, () => {
      this.getPairList(this.state.userId);
    });
  };

  render() {
    let { handleSubmit, tooltipOpen } = this.props;
    let VestingRuleData = this.state?.getUserId?.vesting;
    let VestingRuleHistory = this.state?.getUserId?.vesting_histrory;
    return (
      <div>
        <Header headerTitle="Lock Fund History" />

        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link
                // to="fund-management"
                onClick={() => this.props.history.goBack()}
              >
                Fund Management
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Lock Fund History</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Container className="contentBlock" fluid>
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="selectSearchOption">
                  <Input
                    placeholder="Search List"
                    style={{
                      color: "black",
                    }}
                    onKeyDown={(e) => {
                      // eslint-disable-next-line
                      if (e.keyCode == 13) {
                        this.onSearchList();
                      }
                    }}
                    onChange={(e) => {
                      this.setState(
                        { searchKey: e.target.value, page: 1 },
                        () => {
                          if (this.state.searchKey == "") {
                            this.onSearchList();
                          }
                        }
                      );
                    }}
                  ></Input>
                  <Button
                    color="success btnMamagePermission"
                    onClick={() => this.onSearchList()}
                  >
                    Search
                  </Button>

                  <Dropdown
                    options={options}
                    selection
                    value={this.state.lockedBy}
                    placeholder="Select"
                    onChange={(e, data) => this.filterBy(e, data)}
                  />

                  <Dropdown
                    options={statusOptions}
                    selection
                    placeholder="Select"
                    value={this.state.status}
                    onChange={(e, data) => this.filterByStatus(e, data)}
                  />
                </div>
              </CardHeader>
            </Card>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">User</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Release Amount</th>
                        <th scope="col">Coin</th>
                        <th scope="col">Time period</th>
                        <th scope="col">Locked at</th>
                        {/* <th scope="col">Unlock at</th> */}
                        <th scope="col">Locked by</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.lockFundsList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.lockFundsList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.email}</td>
                            <td>{data.amount}</td>
                            <td>{data.released_token}</td>
                            <td>{data.coin_name}</td>
                            <td>
                              {data?.vesting?.length > 0
                                ? listDateFormat(
                                    data?.vesting[data?.vesting?.length - 1]
                                      ?.date
                                  )
                                : data.time_period +
                                  " " +
                                  data.time_period_unit}
                            </td>
                            <td>{listDateFormat(data.created_at)}</td>
                            {/* <td>{listDateFormat(data.unlocked_at)}</td> */}
                            <td>{data.locked_by.toUpperCase()}</td>
                            <td>{data.status.toUpperCase()}</td>
                            <td>
                              {data.status == "locked" &&
                                data.locked_by == "admin" && (
                                  <span className="mb-0 text-sm">
                                    <Button
                                      color={"success"}
                                      onClick={() =>
                                        this.unlockUserFundModals(data)
                                      }
                                    >
                                      View Unlock fund
                                    </Button>
                                  </span>
                                )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>

          <Modal
            isOpen={this.state.opneModal}
            toggle={this.close}
            className={[this.props.className, "modalCustom"]}
          >
            <ModalHeader toggle={this.close}>
              <h1>
                <span>View Unlock Funds</span>
              </h1>
            </ModalHeader>
            <ModalBody>
              <div className="userNameList">
                {/* <div className="itemStyle">
                  <label style={{ color: "white" }}>Name:</label>
                  <span>
                    {this.state?.getUserId?.name
                      ? this.state?.getUserId?.name
                      : "None"}
                  </span>
                </div> */}
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Email:</label>
                  <span style={{ textDecoration: "none" }}>
                    {this.state?.getUserId?.email
                      ? this.state?.getUserId?.email
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Coin:</label>
                  <span style={{ textDecoration: "none" }}>
                    {this.state?.getUserId?.coin_name
                      ? this.state?.getUserId?.coin_name
                      : "None"}{" "}
                    (
                    {this.state?.getUserId?.coin_symbol
                      ? this.state?.getUserId?.coin_symbol.toUpperCase()
                      : "None"}
                    )
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Lock Amount:</label>
                  <span style={{ textDecoration: "none" }}>
                    {this.state?.getUserId?.amount
                      ? this.state?.getUserId?.amount
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Released Amount:</label>
                  <span style={{ textDecoration: "none" }}>
                    {this.state?.getUserId?.released_token
                      ? this.state?.getUserId?.released_token
                      : "0"}
                  </span>
                </div>
              </div>
              <div className="userNameList">
                {VestingRuleData &&
                  VestingRuleData.map((viewVesting, i) => {
                    return (
                      <div>
                        <div className="itemStyle">
                          <label style={{ color: "white" }}>
                            Vesting Conditions : ({i + 1}))
                          </label>
                          <Button
                            color={
                              i + 1 == VestingRuleHistory?.length &&
                              moment(VestingRuleData[i]?.date).unix() >
                                moment().unix()
                                ? "danger"
                                : VestingRuleHistory?.length == i &&
                                  moment(VestingRuleData[i]?.date).unix() <
                                    moment().unix()
                                ? "success"
                                : i + 1 > VestingRuleHistory?.length
                                ? "success"
                                : "danger"
                            }
                            // color={"success"}
                            // id="TooltipExample12"
                            // onClick={() => this.unlockUserFundModals(data)}
                            onClick={() =>
                              this.unlockUserFund(
                                viewVesting?.amount,
                                this.state?.getUserId?.id
                              )
                            }
                            disabled={
                              VestingRuleData?.length ==
                                VestingRuleHistory?.length ||
                              VestingRuleHistory?.length ===
                                VestingRuleData?.length ||
                              VestingRuleHistory?.length != i ||
                              moment(VestingRuleData[i]?.date).unix() >
                                moment().unix()
                            }
                          >
                            {i + 1 == VestingRuleHistory?.length &&
                            moment(VestingRuleData[i]?.date).unix() >
                              moment().unix()
                              ? "Released"
                              : VestingRuleHistory?.length == i &&
                                moment(VestingRuleData[i]?.date).unix() <
                                  moment().unix()
                              ? "Release"
                              : i + 1 > VestingRuleHistory?.length
                              ? "Pending"
                              : "Released"}
                          </Button>
                        </div>{" "}
                        <div className="itemStyle">
                          <label style={{ color: "white" }}>
                            Vesting Amount:
                          </label>
                          <span style={{ textDecoration: "none" }}>
                            {viewVesting?.amount ? viewVesting?.amount : "None"}
                          </span>
                        </div>
                        <div className="itemStyle">
                          <label style={{ color: "white" }}>
                            Released Date:
                          </label>
                          <span style={{ textDecoration: "none" }}>
                            {viewVesting?.date
                              ? moment(viewVesting?.date).format("ll")
                              : "None"}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* <Form>
                <Form.Group>
                  <Form.Field>
                    <label style={{ color: "#fff" }}>Amount</label>
                    <input
                      type="number"
                      min="0"
                      value={this?.state?.userData?.balance}
                      validate={[required(), amountLimit()]}
                      component={FormField}
                      placeholder="Amount"
                      onChange={(e) => {
                        let value = e?.target?.value;
                        this.setState((prevState) => ({
                          userData: {
                            ...prevState.userData,
                            balance: value,
                          },
                        }));
                      }}
                    ></input>
                  </Form.Field>
                </Form.Group>
                <Form.Field className="addBtn">
                  <Button
                    color="success"
                    onClick={() => this.addFunds(this?.state?.userData)}
                  >
                    Add
                  </Button>
                </Form.Field>
              </Form> */}
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    lockFundsList: state.funds.lockFundHistory.history,
    total: state.funds.lockFundHistory.total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // reset: (data) => dispatch(reset(data)),
    // openCloseModal: () => dispatch(openCloseModal(true)),
    getLockFundHistory: (data) => dispatch(getLockFundHistory(data)),
    unLockUserFunds: (data, fdata) => dispatch(unLockUserFunds(data, fdata)),
    unLockUserFundsVesting: (data, fdata) =>
      dispatch(unLockUserFundsVesting(data, fdata)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LockFundHistory)
);
