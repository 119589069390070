import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { openCloseModal } from "../../Redux/actons/SubAdmin.action";
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";
import { Pagination, Dropdown, Confirm } from "semantic-ui-react";
import { listDateFormat } from "../../utils/utils";
import { reset, Field, reduxForm } from "redux-form";
import {
  getCoinList,
  getUserTerminationRequests,
  terminateRequest,
} from "../../Redux/actons/user.action";
import {
  userInstalmentHistory,
  terminatePlan,
  viewTerminatePlan,
} from "../../Redux/actons/installment.action";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

const Filteroptions = [{ key: "e", text: "None", value: "none" }];
const status = [
  { key: "n", text: "None", value: "" },
  { key: "m", text: "Pending", value: "pending" },
  { key: "k", text: "Terminated", value: "terminated" },
  // { key: "l", text: "Processing", value: "processing" },
];

export class TerminationRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmTerminate: false,
      searchKey: "",
      perPage: "10",
      page: "1",
      sortBy: "",
      coin_family: "",
      order: "asc",
      selectedCoin: "",
      status: "",
      openAmountModal: false,
      userData: "",
      isTerminate: false,
      penalty: "",
      comment: "",
      plan_id: "",
      search: "",
    };
  }
  componentDidMount = async () => {
    this.termanationRequestList();
  };

  termanationRequestList = () => {
    let data = {
      search: this.state.searchKey,
      // ? this.state.searchKey
      // : this.state.status
      // ? this.state.status
      // : "",
      status: this.state.status,
      page: this.state.page,
      perPage: this.state.perPage,
    };
    this.props.getUserTerminationRequests(data);
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.termination_list != nextProps.termination_list) {
      console.log("will receive in termination : ", nextProps.termination_list);
    }
  }
  componentWillUnmount = () => {
    this.props.openCloseModal();
  };

  getPairList = (planId) => {
    const data = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      order: this.state.order,
      coin_id: this.state.selectedCoin,
      status: this.state.status,
      plan_id: planId,
    };
    this.props.userInstalmentHistory(data);
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      // this.getPairList(this.state.plan_id);
      this.termanationRequestList(this.state.plan_id);
    });
  };

  openAmountModal = (data, isTerminate) => {
    console.log("7609-------", data);

    this.props.viewTerminatePlan(
      // params, id,
      data.installment_id
    );

    setTimeout(() => {
      this.setState({
        openAmountModal: !this.state.openAmountModal,
        userData: data,
        isTerminate: isTerminate,
      });
    }, 100);
  };

  toggle = () =>
    this.setState({ openAmountModal: !this.state.openAmountModal });

  onSearchList = () => {
    this.setState({ status: "", searchKey: this.state.searchKey }, () => {
      // this.getPairList(this.state.plan_id);
      this.termanationRequestList();
    });
  };

  orderBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getPairList(this.state.plan_id);
    });
  };

  filterByCoin = (e, data) => {
    this.setState({ selectedCoin: data.value }, () => {
      this.getPairList(this.state.plan_id);
    });
  };

  filterByStatus = (e, data) => {
    this.setState({ status: data.value, searchKey: "" }, () => {
      this.termanationRequestList(this.state.plan_id);
    });
  };

  terminatePlan = (id) => {
    if (this.state.penalty == "") {
      alert("Penalty amount is required");
    } else if (this.state.comment == "") {
      alert("Comment is required");
    } else if (
      this.state.userData.paid_amount +
        (this.state.userData.processing_fee / 100) *
          this.state.userData.paid_amount -
        this.state.penalty <
      0
    ) {
      alert(
        "Amount cannot exceed" +
          " " +
          (this.state.userData.paid_amount +
            (this.state.userData.processing_fee / 100) *
              this.state.userData.paid_amount -
            this.state.penalty)
      );
    } else {
      const params = {
        message: this.state.comment,
        penalty: this.state.penalty,
      };

      const data = {
        page: this.state.page,
        perPage: this.state.perPage,
        searchKey: this.state.searchKey,
        order: this.state.order,
        coin_id: this.state.selectedCoin,
        status: this.state.status,
      };

      this.props.terminatePlan(params, id, data);
      this.toggle();
    }
  };

  terminateUser = (data) => {
    this.setState({ confirmTerminate: true, userData: data });
  };
  close = () => {
    this.setState({ confirmTerminate: false, userData: {} });
  };

  onConfirmation = () => {
    this.props.terminateRequest(this.state.userData?.id).then((res) => {
      console.log("sdfkljhkshfdkjshfk : ", res);
      if (res) {
        this.setState({ confirmTerminate: false, userData: {} });
        this.termanationRequestList();
      }
    });
  };
  render() {
    let { handleSubmit } = this.props;
    console.log(
      "saveviewTerminatePlan ----- ",
      this.props.saveviewTerminatePlan
    );
    return (
      <div>
        <Header headerTitle="User Taral Gold History" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="taral-gold-management">Taral Gold Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>User Taral Gold History</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        value={this.state.searchKey}
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      {/* <Dropdown
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.orderBy(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={Filteroptions}
                        placeholder="Filter by Coin"
                        selection
                        onChange={(e, data) => this.filterByCoin(e, data)}
                      />*/}
                      <Dropdown
                        className="selectUserStyle"
                        options={status}
                        value={this.state.status}
                        placeholder="Filter by status"
                        selection
                        onChange={(e, data) => this.filterByStatus(e, data)}
                      />
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">S.No.</th>
                        <th scope="col">User Name</th>
                        <th scope="col">User email</th>
                        <th scope="col">Coin</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.termination_list?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.termination_list?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{index + 1 + (this.state.page - 1) * 10}</td>
                            <td>{data.user_name}</td>
                            <td>{data.email}</td>
                            <td>{data.coin_name}</td>
                            <td>{data?.amount}</td>
                            <td>{data?.request_status}</td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.openAmountModal(data, false)
                                  }
                                  // disabled={
                                  //   data.request_status !== "terminated"
                                  //     ? true
                                  //     : false
                                  // }
                                >
                                  View
                                </Button>

                                {data.request_status !== "terminated" && (
                                  <Button
                                    disabled={
                                      data.request_status === "terminated"
                                        ? true
                                        : false
                                    }
                                    color="danger"
                                    onClick={() => this.terminateUser(data)}
                                  >
                                    Terminate
                                  </Button>
                                )}
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(this.props.total)}
                          // totalPages={Math.ceil(
                          //   this.props.total / this.state.perPage
                          // )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.openAmountModal}
          toggle={this.toggle}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.toggle}>
            <h1>Installment Details</h1>
          </ModalHeader>
          <ModalBody>
            <div className="userNameList">
              {/* <div className="itemStyle">
                <label>User name:</label>
                <label>{this.props.saveviewTerminatePlan.email}</label>
              </div> */}
              <div className="itemStyle">
                <label>User email:</label>
                <label>
                  {this.props.saveviewTerminatePlan.email
                    ? this.props.saveviewTerminatePlan.email
                    : "--"}
                </label>
              </div>
              <div className="itemStyle">
                <label>User currency:</label>
                <label>{this.props.saveviewTerminatePlan.coin_name}</label>
              </div>
              <div className="itemStyle">
                <label>Quantity:</label>
                <label>{this.props.saveviewTerminatePlan.qty}</label>
              </div>
              <div className="itemStyle">
                <label>Estimated amount:</label>
                <label>{this.props.saveviewTerminatePlan.monthly_emi}</label>
              </div>
              <div className="itemStyle">
                <label>Enroll Date:</label>
                <label>
                  {listDateFormat(this.props.saveviewTerminatePlan.created_at)}
                </label>
              </div>
              <div className="itemStyle">
                <label>Plan Tenure:</label>
                <label>
                  {" "}
                  {this.props.saveviewTerminatePlan.tenure +
                    " " +
                    this.props.saveviewTerminatePlan.tenure_type}{" "}
                </label>
              </div>
              <div className="itemStyle">
                <label>Maturity Date:</label>
                <label>
                  {listDateFormat(this.props.saveviewTerminatePlan.due_date)}
                </label>
              </div>
              <div className="itemStyle">
                <label>Total amount paid:</label>
                <label>{this.props.saveviewTerminatePlan.paid_amount}</label>
              </div>
              <div className="itemStyle">
                <label>Total payment due:</label>
                <label>
                  {this.props.saveviewTerminatePlan.due_amount > 0
                    ? this.props.saveviewTerminatePlan.due_amount
                    : "0"}
                </label>
              </div>
              {/* {this.state.isTerminate && (
                <div>
                  <div className="itemStyle">
                    <label>Penalty to be charged(₹):</label>
                    <input
                      type="number"
                      name="downpayment"
                      min="0"
                      required
                      onChange={(e, data) =>
                        this.setState({ penalty: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className="itemStyle">
                    <label>Add comment:</label>
                    <textarea
                      style={{ height: 200 }}
                      onChange={(e, data) =>
                        this.setState({ comment: e.target.value })
                      }
                    ></textarea>
                  </div>
                  <div className="itemStyle">
                    <label></label>
                    <Button
                      color={"danger"}
                      onClick={() =>
                        this.terminatePlan(this.state.userData.installment_id)
                      }
                    >
                      Terminate
                    </Button>
                  </div>
                </div>
              )} */}
            </div>
          </ModalBody>
        </Modal>
        <Confirm
          content={`Are you sure to terminate this request ?`}
          style={{
            height: "auto",
            top: "5%",
            left: "auto",
            bottom: "auto",
            right: "auto",
          }}
          size="small"
          className="confirm-model"
          open={this.state.confirmTerminate}
          onCancel={this.close}
          onConfirm={this.onConfirmation}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    instalmentList: state.installment.userInstalment.history,
    saveviewTerminatePlan: state.installment.saveviewTerminatePlan,
    termination_list: state.userTermination.user_termination_list.history,
    metaData: state.userTermination.user_termination_list?.meta,
    total: state.userTermination.user_termination_list.totalPages,
    pagess: state.userTermination.user_termination_list.page,
    coinsList: state.user.coinsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (data) => dispatch(reset(data)),
    openCloseModal: () => dispatch(openCloseModal(true)),
    userInstalmentHistory: (data) => dispatch(userInstalmentHistory(data)),
    getUserTerminationRequests: (data) =>
      dispatch(getUserTerminationRequests(data)),
    terminatePlan: (data, id, params) =>
      dispatch(terminatePlan(data, id, params)),
    viewTerminatePlan: (data, id, params) =>
      dispatch(viewTerminatePlan(data, id, params)),
    getCoinList: () => dispatch(getCoinList()),
    terminateRequest: (data) => dispatch(terminateRequest(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "TerminationRequestForm" })(TerminationRequests));
