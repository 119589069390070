import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { setSwapFee } from "../../Redux/actons/token.action";
import { Form } from "semantic-ui-react";
import { Field, reduxForm, change } from "redux-form";
import { FormField } from "../../components/FormField";
import { required } from "redux-form-validators";

import {
  getTokenList,
  deleteTokens,
  enableDisableToken,
  makeCoinPublic,
  swapOnOff,
  autoTransactionOnOff,
  updatePriceSource,
} from "../../Redux/actons/token.action";
// import { amountLimit } from "../../validators/customValidator";
import { Dropdown } from "semantic-ui-react";

let options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

export class UpdateSetting extends Component {
  constructor(props) {
    super(props);
  }
  //   componentWillReceiveProps = (nextProps) => {
  //       if(nextProps !== this.props){
  //         //   alert('tedd');
  //         this.setState({
  //             fee: nextProps.swap_fee
  //         });
  //       }
  //   };

  componentWillReceiveProps = async (nextProps) => {
    if (this.props.swap_fee !== nextProps.swap_fee) {
      await this.props.dispatch(
        change("SetSwapFee", "fee", nextProps?.swap_fee)
      );
    }
  };
  updateForm = (values) => {
    if (values.fee == undefined && values.fee == "") {
      alert("Please enter the required value");
      return false;
    }
    if (values.fee < 0) {
      alert("Swap fee must be greater than and equal to 0");
      return false;
    }

    let data = {
      coin_id: this.props.coin_id,
      auto_withdraw_min_amt: values.fee,
      allow_withdraw: this.props.coins_setting,
    };
    let data2 = {
      search: this.props.search,
      order: this.props.order,
      perPage: this.props.perPage,
      page: this.props.page,
      operateBy: this.props.operateBy == undefined ? "" : this.props.operateBy,
    };

    this.props.autoTransactionOnOff(data, data2);
    this.props.closeSwapFeeModal();
  };
  setFeeValue(value) {
    this.setState({ fee: value });
  }

  // onAutoTransactionOnOff = (id, type) => {
  //   // console.log('event >>>>>',type,id);
  //   let data = {
  //     // coin_id: id,
  //     auto_withdraw_min_amt: this.state.fee,
  //     auto_withdraw_type: "worth_of_fiat",
  //   };
  //   let data2 = {
  //     search: this.state.search,
  //     order: this.state.order,
  //     perPage: this.state.perPage,
  //     page: this.state.page,
  //     operateBy:this.state.operateBy,
  //   };
  //   this.props.autoTransactionOnOff(data, data2);
  // };

  render() {
    // console.log('this.props222 >> ',this.props);
    // console.log('this.state >> ',this.state);

    let { handleSubmit } = this.props;
    return (
      <div>
        {/* <Button color="danger">X</Button> */}

        <Modal
          isOpen={this.props?.updateSettingModal}
          toggle={this.props.closeSwapFeeModal}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.closeSwapFeeModal}>
            Set Auto-Withdraw Limit
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.updateForm)}>
              <Form.Group>
                <Form.Field>
                  <label>Auto-Withdraw Min Amount(Crypto)</label>
                  <Field
                    placeholder="Amount"
                    name="fee"
                    // value={this.state.fee}
                    component={FormField}
                    type="number"
                    validate={[required()]}
                  />
                </Form.Field>
                <Form.Field>
                  {/* <label>Fee</label> */}
                  {/* <Dropdown
                      className="mx-2"
                        options={options}
                        selection
                        // value={this.state.order}
                        onChange={(e, data) => this.filterBy(e, data)}
                      /> */}

                  {/* <input
                        type="number"
                        name="fee"
                        value={this.state.fee}
                        validate={[required()]}
                        component={FormField}
                        onChange={(e, data) =>
                            this.setFeeValue(e.target.value)
                        }
                        ></input> */}
                </Form.Field>
              </Form.Group>
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSwapFee: (data, data2) => dispatch(setSwapFee(data, data2)),
    autoTransactionOnOff: (data, data2) =>
      dispatch(autoTransactionOnOff(data, data2)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "UpdateSetting" })(UpdateSetting));
// export default connect(mapStateToProps, mapDispatchToProps)(SetWithdrawDailyLimit);
