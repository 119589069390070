import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, label } from "semantic-ui-react";
import { Button } from "reactstrap";
import {
  getUserFund,
  setUserFund,
  getFundUsers,
  saveWalletId,
  lockUserFunds,
  unLockUserFunds,
} from "../../Redux/actons/fund.action";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { FormField } from "../../components/FormField";
import { SelectField } from "../../components/SelectField";
import { email, required } from "redux-form-validators";
import { toast } from "../../components/Toast/Toast";
import { reset } from "redux-form";
import { getCoinList } from "../../Redux/actons/user.action";
import { addPlan } from "../../Redux/actons/installment.action";
import { Pagination, Dropdown } from "semantic-ui-react";

const options = [
  { key: "n", text: "Minutes", value: "minutes" },
  { key: "z", text: "Hours", value: "hours" },
  { key: "m", text: "Day", value: "day" },
  { key: "e", text: "Week", value: "week" },
  { key: "k", text: "Month", value: "month" },
  { key: "l", text: "Year", value: "year" },
];

const downpaymentType = [
  { key: "k", text: "Percentage", value: "percentage" },
  { key: "l", text: "Actual Value", value: "actual_value" },
];

export class AddPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet_rel_id: "",
      user_id: "",
      selectedCoin: "",
      downpayment: "",
      downpaymentType: "percentage",
      minQty: "",
      maxQty: "",
      planTenure: "day",
      interest_rate_coin_id: 0,
      interest_rate: 0,
      planTime: "",
      coveyanceFees: "",
      processingFees: "",
      acquisition: "",
      description: "",
      acquisitionType: "day",
      acquisitionTime: "",
      terminationTenureType: "day",
      terminationTenureTime: "",
      page: "1",
      perPage: "10",
      searchKey: "",
      order: "",
      coin_id: "",
      status: "",
    };
  }

  componentDidMount = () => {
    this.props.getCoinList();
  };

  toggle = () => {
    this.props.reset("addFundsForm");
    this.props.close();
  };

  addPlan = () => {
    let dayObject = { day: 1, week: 7, month: 30, year: 365 };
    if (
      !this.state.selectedCoin ||
      !this.state.minQty ||
      !this.state.maxQty ||
      !this.state.downpayment ||
      !this.state.downpaymentType ||
      !this.state.planTime ||
      !this.state.planTenure ||
      !this.state.coveyanceFees ||
      !this.state.processingFees ||
      !this.state.acquisition ||
      !this.state.description ||
      !this.state.acquisitionTime ||
      !this.state.acquisitionType ||
      !this.state.terminationTenureTime ||
      !this.state.terminationTenureType ||
      !this.state.interest_rate_coin_id ||
      !this.state.interest_rate
    ) {
      alert("All fields are required");
    } else if (parseFloat(this.state.minQty) >= parseFloat(this.state.maxQty)) {
      alert("Minimum quantity should be less than Maximum quantity");
    } else if (this.state.downpayment <= 0) {
      alert("Downpayment should be greater than 0");
    } else {
      // console.log('dayObject[this.state.planTenure]',dayObject[this.state.planTenure]);
      let actualPlanTime =
        this.state.planTime * dayObject[this.state.planTenure];
      let actualAcquisitionTime =
        this.state.acquisitionTime * dayObject[this.state.acquisitionType];
      // console.log('dayObject[this.state.acquisitionType]',dayObject[this.state.acquisitionType]);

      if (actualPlanTime < actualAcquisitionTime) {
        alert("Acquisition Time should be less than Plan Time");
      } else {
        const param = {
          currency_id: this.state.selectedCoin,
          min_qty: this.state.minQty,
          max_qty: this.state.maxQty,
          down_payment: this.state.downpayment,
          plan_tenure_time: this.state.planTime,
          plan_tenure_type: this.state.planTenure,
          conveyance_fee: this.state.coveyanceFees,
          processing_fee: this.state.processingFees,
          acquisition: this.state.acquisition,
          description: this.state.description,
          acquisition_tenure_time: this.state.acquisitionTime,
          acquisition_tenure_type: this.state.acquisitionType,
          termination_tenure_time: this.state.terminationTenureTime,
          termination_tenure_type: this.state.terminationTenureType,
          interest_rate_coin_id: this.state.interest_rate_coin_id,
          interest_rate: this.state.interest_rate,
          down_payment_type: this.state.downpaymentType
        };

        const data = {
          page: this.state.page,
          perPage: this.state.perPage,
          searchKey: this.state.searchKey,
          order: this.state.order,
          //coin_id: this.state.selectedCoin,
          status: this.state.status,
        };

        this.props.addPlan(param, data);
        this.toggle();
      }
    }
  };

  render() {
    // console.log('add plan',this.props);
    let { handleSubmit } = this.props;
    return (
      <Modal isOpen={this.props.toggle}>
        <ModalHeader toggle={this.toggle}>Add Plan</ModalHeader>
        <ModalBody>
          <Form>
            <Form.Group>
              <Form.Field>
                <label>Select Currency </label>
                <Dropdown
                  className="selectUserStyle"
                  options={this.props.coinsList}
                  selection
                  value={this.state.selectedCoin}
                  onChange={(e, data) =>
                    this.setState({ selectedCoin: data.value })
                  }
                  required
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Minimum Quantity</label>
                <input
                  type="number"
                  name="minimum_qunatity"
                  min="0"
                  max="100"
                  required
                  onChange={(e, data) =>
                    this.setState({ minQty: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Maximum Quantity</label>
                <input
                  type="number"
                  name="max_qty"
                  min="0"
                  max="100"
                  required
                  onChange={(e, data) =>
                    this.setState({ maxQty: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Downpayment</label>
                <input
                  type="number"
                  name="downpayment"
                  min="0"
                  max="100"
                  required
                  onChange={(e, data) =>
                    this.setState({ downpayment: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Downpayment Type</label>
                <Dropdown
                  className="selectUserStyle"
                  options={downpaymentType}
                  selection
                  value={this.state.downpaymentType}
                  onChange={(e, data) =>
                    this.setState({ downpaymentType: data.value })
                  }
                  required
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Plan Time</label>
                <input
                  type="number"
                  name="plan_time"
                  min="0"
                  required
                  onChange={(e, data) =>
                    this.setState({ planTime: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Plan Tenure </label>
                <Dropdown
                  className="selectUserStyle"
                  options={options}
                  selection
                  value={this.state.planTenure}
                  onChange={(e, data) =>
                    this.setState({ planTenure: data.value })
                  }
                  required
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Conveyance Fees (%)</label>
                <input
                  type="number"
                  name="coveyance_fees"
                  min="0"
                  max="100"
                  required
                  onChange={(e, data) =>
                    this.setState({ coveyanceFees: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Processing Fees (%)</label>
                <input
                  type="number"
                  name="processing_fees"
                  min="0"
                  max="100"
                  required
                  onChange={(e, data) =>
                    this.setState({ processingFees: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Acquisition (%)</label>
                <input
                  type="number"
                  name="acquisition"
                  min="0"
                  max="100"
                  required
                  onChange={(e, data) =>
                    this.setState({ acquisition: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Acquisition Time</label>
                <input
                  type="number"
                  name="acquisition_time"
                  min="0"
                  required
                  onChange={(e, data) =>
                    this.setState({ acquisitionTime: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Acquisition Type </label>
                <Dropdown
                  className="selectUserStyle"
                  options={options}
                  selection
                  value={this.state.acquisitionType}
                  onChange={(e, data) =>
                    this.setState({ acquisitionType: data.value })
                  }
                  required
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Termination Time</label>
                <input
                  type="number"
                  name="termination_tenure_time"
                  min="0"
                  required
                  onChange={(e, data) =>
                    this.setState({ terminationTenureTime: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Termination Type</label>
                <Dropdown
                  className="selectUserStyle"
                  options={options}
                  selection
                  value={this.state.terminationTenureType}
                  onChange={(e, data) =>
                    this.setState({ terminationTenureType: data.value })
                  }
                  required
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Interest Rate Coin </label>
                <Dropdown
                  className="selectUserStyle"
                  options={this.props.coinsList}
                  selection
                  value={this.state.interest_rate_coin_id}
                  onChange={(e, data) =>
                    this.setState({ interest_rate_coin_id: data.value })
                  }
                  required
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Interest rate(%)</label>
                <input
                  type="number"
                  name="interest_rate"
                  min="0"
                  required
                  onChange={(e, data) =>
                    this.setState({ interest_rate: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.TextArea
                label="Description"
                placeholder="Description"
                onChange={(e, data) =>
                  this.setState({ description: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="error" onClick={() => this.toggle()}>
            Cancel
          </Button>
          <Button color="success" onClick={() => this.addPlan()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coinsList: state.user.coinsList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCoinList: () => dispatch(getCoinList()),
    reset: (data) => dispatch(reset(data)),
    addPlan: (data, params) => dispatch(addPlan(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "addFundsForm" })(AddPlan));
