import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Header(props) {
  return (
    <>
      <div className="header bg-gradient-info pb-4 pt-2 pt-md-6">
        <Container fluid>
          <div
            className="header-body"
            style={{ paddingLeft: 25, paddingRight: 25 }}
          >
            {/* Card stats */}
            <CardTitle tag="h3" className="text-uppercase text-muted mb-0">
              {props.headerTitle}
            </CardTitle>
            {/* <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Link to="user-management">
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              User Stats
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.props.usersList.users}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-users" />
                            </div>
                          </Col>
                        </Row>
                      </Link>
                    </CardBody>
                  </Card>
                </Col> */}
            {/* <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Link to="submitted-kyc">
                        {" "}
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Pending KYC
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.props.usersList.pending}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fas fa-users" />
                            </div>
                          </Col>
                        </Row>
                      </Link>
                      
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Link to="approved-kyc">
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              KYC approved
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {" "}
                              {this.props.usersList.approved}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                              <i className="fas fa-users" />
                            </div>
                          </Col>
                        </Row>
                      </Link>
                      
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Active Users
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.props.usersList.active_users}{" "}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                     
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Revenue
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.props.getTotalCoinRevenue}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      {/* </Row>
                    </CardBody>
                  </Card>
                      </Col> */}
            {/* </Row> */}
          </div>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    usersList: state.userList.dashboardLists,
    getTotalCoinRevenue: state.revenue.getTotalCoinRevenue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
