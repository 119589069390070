import { actionTypes } from "../actons/token.action";

const initialState = {
  tokenList: [],
  totalRecords: "",
  ercToken: {},
  priceList: [],
  editToken: [],
  coin_list: [],
  editTokenDetails: {},
  save_price_data: [],
  updateButton: false,
};

const token = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_TOKEN_LIST:
      var coin = action.data.data;
      var Final = [];
      // console.log("coinLIst", coin);
      coin.forEach((element) => {
        element.value = element.coin_id;

        // 1=eth, 2=btc, 3=bnb, 4=trx

        if (element.is_token == 1) {
          if (element.coin_family == 1) {
            element.text = element.coin_symbol.toUpperCase() + "(ERC-20)";
          } else if (element.coin_family == 3) {
            element.text = element.coin_symbol.toUpperCase() + "(BEP-20)";
          } else if (element.coin_family == 4) {
            element.text = element.coin_symbol.toUpperCase() + "(TRC-20)";
          } else if (element.coin_family == 5) {
            element.text = element.coin_symbol.toUpperCase() + "(MATIC)";
          } else {
            element.text = element.coin_symbol.toUpperCase();
          }
        } else {
          element.text = element.coin_symbol.toUpperCase();
        }

        Final.push(element);
      });
      return {
        ...state,
        tokenList: Final,
        totalRecords: action.data.meta.total,
      };
    case actionTypes.SAVE_TOKEN:
      return {
        ...state,
        ercToken: action.data.data,
      };
    case actionTypes.SAVE_PRICE_LIST:
      return {
        ...state,
        priceList: action.data,
      };
    case actionTypes.SAVE_EDIT_TOKEN_DETAILS:
      return {
        ...state,
        editToken: action.data,
      };
    case actionTypes.SAVE_EDIT_TOKEN_DETAILS2:
      return {
        ...state,
        editTokenDetails: action.data,
      };
    case actionTypes.SAVE_PRICE_DATA:
      return {
        ...state,
        save_price_data: action.data,
      };
    case actionTypes.UPDATE_BUTTON:
      return {
        ...state,
        updateButton: action.data,
      };

    case actionTypes.SAVE_COIN_LIST:
      return {
        ...state,
        coin_list: action.data,
      };
    default:
      return state;
  }
};

export default token;
