import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
} from "reactstrap";

import { openCloseModal } from "../../Redux/actons/SubAdmin.action";
import Header from "components/Headers/Header";
import { Grid, GridColumn } from "semantic-ui-react";
import { Pagination, Dropdown } from "semantic-ui-react";
import { reset, Field, reduxForm } from "redux-form";
// import { getCoinList } from "../../Redux/actons/user.action";
import { getCoinList, getPlan } from "../../Redux/actons/user.action";
import {
  getInstalmentList,
  getInstalmentListCSV,
  enableDisabelLock,
  update_description,
} from "../../Redux/actons/installment.action";
import AddPlan from "./AddPlan";
import EditDescription from "./EditDescription";
import Input from "reactstrap/lib/Input";
import SetLimit from "./SetLimit";
import EditPlan from "./EditPlan";
import DashboardResult from "views/ContactQueryManagement/SubjectManagement/DashboardResult";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

const Filteroptions = [{ key: "e", text: "None", value: "none" }];
const status = [
  { key: "n", text: "All", value: "" },
  { key: "m", text: "Active", value: "active" },
  { key: "k", text: "Inactive", value: "inactive" },
];

export class TaralGoldManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      sortBy: "",
      coin_family: "",
      order: "desc",
      selectedCoin: "",
      status: "",
      openAmountModal: false,
      openAmountModalEdit: false,
      openSetLimitModal: false,
      updateDescriptionModal: false,
      updateData: {},
      setPlanID: "",
    };
  }
  componentDidMount = () => {
    this.getPairList();
    this.props.getCoinList().then(() => {
      this.props.coinsList.forEach((element) => {
        Filteroptions.push(element);
      });
    });
  };
  componentWillUnmount = () => {
    this.props.openCloseModal();
  };

  getPairList = () => {
    console.log("this.state.selectedCoin----", this.state.selectedCoin);
    let selectedCoin_id = this.state.selectedCoin?.options?.find(
      (data1) => data1.value == this.state.selectedCoin.value
    ).coin_id;
    const data = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      order: this.state.order,
      coin_id: selectedCoin_id,
      status: this.state.status,
    };
    this.props.getInstalmentList(data);
  };

  enableDisablePair = (data, id) => {
    const buttonState = { status: data };
    let selectedCoin_id = this.state.selectedCoin?.options?.find(
      (data1) => data1.value == this.state.selectedCoin.value
    ).coin_id;
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      order: this.state.order,
      coin_id: selectedCoin_id,
      status: this.state.status,
    };

    this.props.enableDisabelLock(buttonState, id, params);
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getPairList();
    });
  };

  openAmountModal = (data) => {
    setTimeout(() => {
      this.setState({ openAmountModal: !this.state.openAmountModal });
    }, 100);
  };
  openAmountModalEdit = (data) => {
    console.log("data -----planId", data);

    this.props.getPlan({ plan_id: data });

    setTimeout(() => {
      this.setState({
        openAmountModalEdit: !this.state.openAmountModalEdit,
        // setPlanID: data,
      });
    }, 500);
  };
  openSetLimitModal = (data) => {
    setTimeout(() => {
      this.setState({ openSetLimitModal: !this.state.openSetLimitModal });
    }, 100);
  };

  toggle = () => {
    this.setState({
      openAmountModal: !this.state.openAmountModal,
    });
  };
  toggleEdit = () => {
    this.setState({
      openAmountModalEdit: !this.state.openAmountModalEdit,
    });
  };
  toggle1 = () => {
    this.setState({
      openSetLimitModal: !this.state.openSetLimitModal,
    });
  };

  onSearchList = (search) => {
    this.setState({ page: 1 }, () => {
      this.getPairList();
    });
  };

  orderBy = (e, data) => {
    this.setState({ order: data.value, page: 1 }, () => {
      this.getPairList();
    });
  };

  filterByCoin = (e, data) => {
    this.setState({ selectedCoin: data, page: 1 }, () => {
      this.getPairList();
    });
  };

  filterByStatus = (e, data) => {
    this.setState({ status: data.value, page: 1 }, () => {
      this.getPairList();
    });
  };

  onUserHistory = () => {
    this.props.history.push({
      pathname: `user-taral-gold-history`,
    });
  };

  redirectPlanUserHistory = (id) => {
    this.props.history.push({
      pathname: `user-taral-gold-history`,
      state: id,
    });
  };

  openCloseDescriptionModal = (id, description) => {
    let data = { id: id, description: description };
    this.setState({
      updateData: data,
      updateDescriptionModal: !this.state.updateDescriptionModal,
    });
  };

  updateDescription = (values) => {
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      order: this.state.order,
      status: this.state.status,
    };
    let description = values.description;
    //console.log('description >>>2222455355555',description);
    this.props.update_description(
      { description: description },
      this.state.updateData.id,
      params
    );
    this.setState({
      updateDescriptionModal: !this.state.updateDescriptionModal,
    });
  };
  DownLoadCSV = () => {
    const data = {
      page: 1,
      perPage: this.props.total,
      // searchKey: this.state.searchKey,
      // order: this.state.order,
      // coin_id: this.state.selectedCoin,
      // status: this.state.status,
    };
    this.props.getInstalmentListCSV(data);
    // try {

    //   //api/v1/admin/instalment/plan-list
    // } catch (err) {
    //   console.log(err);
    // }
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Taral Gold Management" />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Card className="shadow">
                <Grid.Row
                  columns={2}
                  // style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Total"}
                      labResult={
                        this.props?.meta?.disableList
                          ? this.props?.meta?.disableList +
                            this.props?.meta?.enableList
                          : "0"
                      }
                    />
                  </GridColumn>
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Disabled"}
                      labResult={
                        this.props?.meta?.disableList
                          ? this.props?.meta?.disableList
                          : "0"
                      }
                    />
                  </GridColumn>
                  {/* </Grid.Row>
            <Grid.Row
              columns={2}
              // style={{ paddingTop: "10px", paddingBottom: "10px" }}
            > */}
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Resolved"}
                      labResult={
                        this.props?.meta?.enableList
                          ? this.props?.meta?.enableList
                          : "0"
                      }
                    />
                  </GridColumn>
                </Grid.Row>
              </Card>
            </div>
            <Row>
              {" "}
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.orderBy(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle mx-2"
                        options={Filteroptions}
                        placeholder="Filter by Coin"
                        selection
                        onChange={(e, data) => this.filterByCoin(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={status}
                        placeholder="Filter by status"
                        selection
                        onChange={(e, data) => this.filterByStatus(e, data)}
                      />
                    </div>
                    <div
                      className="selectSearchOption"
                      style={{ marginTop: 20 }}
                    >
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openAmountModal()}
                      >
                        Add plan
                      </Button>
                      {/* <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onUserHistory()}
                      >
                        User history
                      </Button> */}
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openSetLimitModal()}
                      >
                        Credit limit
                      </Button>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => {
                          this.props.history.push("user-taral-gold-requests");
                        }}
                      >
                        Termination requests
                      </Button>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => {
                          this.DownLoadCSV();
                          // this.props.history.push("user-taral-gold-requests");
                        }}
                      >
                        DownLoad CSV
                      </Button>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Plan Id</th>
                        <th scope="col">Currency type</th>
                        <th scope="col">Tenure</th>
                        <th scope="col">Down payment</th>
                        <th scope="col">Down payment type</th>
                        <th scope="col">Conveyance Fee</th>
                        <th scope="col">Processing Fee</th>
                        <th scope="col">Status</th>
                        <th scope="col"> Acquisition Time Period </th>
                        <th scope="col"> Termination Time Period </th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.instalmentList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.instalmentList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data?.plan_id}.</td>
                            <td>{data.coin_name}</td>
                            <td>
                              {data.plan_tenure_time +
                                " " +
                                data.plan_tenure_type}
                            </td>
                            <td>{data.down_payment}</td>
                            <td>{data.down_payment_type.toUpperCase()}</td>
                            <td> {data.conveyance_fee}</td>
                            <td> {data.processing_fee}</td>
                            <td>{data.status.toUpperCase()}</td>
                            <td>
                              {data.acquisition_tenure_time +
                                " " +
                                data.acquisition_tenure_type}
                            </td>
                            <td>
                              {/* {data.termination_tenure_time != null ?{data.termination_tenure_time + " " + data.termination_tenure_type } : "NULL"} */}
                              {data.termination_tenure_type != null
                                ? data.termination_tenure_time +
                                  " " +
                                  data.termination_tenure_type
                                : "None"}
                            </td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color={
                                    data.status === "active"
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={() =>
                                    this.enableDisablePair(
                                      data.status === "active"
                                        ? "inactive"
                                        : "active",
                                      data.plan_id
                                    )
                                  }
                                >
                                  {data.status === "active"
                                    ? "Disable"
                                    : "Enable"}
                                </Button>
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.redirectPlanUserHistory(data.plan_id)
                                  }
                                >
                                  View users
                                </Button>

                                <Button
                                  color="success btnMamagePermission"
                                  onClick={() =>
                                    this.openAmountModalEdit(data.plan_id)
                                  }
                                >
                                  Edit plan
                                </Button>
                                {/* <Button
                                  color="success"
                                  onClick={() =>
                                    this.openCloseDescriptionModal(
                                      data.plan_id,
                                      data.description
                                    )
                                  }
                                >
                                  Edit Description
                                </Button> */}
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <AddPlan
          toggle={this.state.openAmountModal}
          close={this.toggle}
          type={"Add"}
        />
        {this.state.openAmountModalEdit && (
          <EditPlan
            // setPlanID={this.state.setPlanID}
            toggle={this.state.openAmountModalEdit}
            close={this.toggleEdit}
            type={"Edit"}
          />
        )}
        <SetLimit toggle={this.state.openSetLimitModal} close={this.toggle1} />
        <EditDescription
          updateDescriptionModal={this.state.updateDescriptionModal}
          openCloseDescriptionModal={this.openCloseDescriptionModal}
          updateDescription={this.updateDescription}
          updateData={this.state.updateData}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    instalmentList: state.installment.instalmentList.history,
    total: state.installment.instalmentList.totalRecords,
    meta: state.installment.instalmentList,
    coinsList: state.user.coinsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (data) => dispatch(reset(data)),
    getPlan: (data) => dispatch(getPlan(data)),
    openCloseModal: () => dispatch(openCloseModal(true)),
    getInstalmentList: (data) => dispatch(getInstalmentList(data)),
    getInstalmentListCSV: (data) => dispatch(getInstalmentListCSV(data)),
    enableDisabelLock: (data, id, params) =>
      dispatch(enableDisabelLock(data, id, params)),
    getCoinList: () => dispatch(getCoinList()),
    update_description: (data, id, params) =>
      dispatch(update_description(data, id, params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "InstallmentsManagementForm" })(TaralGoldManagement));
