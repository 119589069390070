import React, { Component } from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
// import "./WithDrawDepositSetting.scss";

export class Error404 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCoin: false,
      isFieldSelected: false,
      defaultType: "",
      defaultAction: 1,
      selectedCoin: [],
      feeCoin: "",
      searchKey: "",
      listType: "1",
      type: "",
    };
  }
  //   componentDidMount = () => {
  //     this.getCoinList();
  //   };
  //   getCoinList = () => {
  //     let params = {
  //       search: this.state.searchKey,
  //       list_type: this.state.listType,
  //     };
  //     this.props.getCoinList(params);
  //   };
  //   onSearchList = () => {
  //     this.getCoinList();
  //   };
  //   listByUser = (e, data) => {
  //     if (data.value !== "none") {
  //       this.setState({ listType: data.value }, () => {
  //         this.getCoinList();
  //       });
  //     } else {
  //       this.setState({ listType: "1" }, () => {
  //         this.getCoinList();
  //       });
  //     }
  //   };

  //   getDetails = (e, data) => {
  //     console.log(data);
  //     this.setState({ isFieldSelected: true, defaultType: data });
  //   };

  //   onSelectCoin = (e, title, id) => {
  //     let coin = [...this.state.selectedCoin];
  //     if (e.target.checked) {
  //       coin.push(id);
  //       this.setState({ selectedCoin: coin });
  //     } else {
  //       for (var i = 0; i < coin.length; i++) {
  //         if (coin[i] == id) {
  //           coin.splice(i, 1);
  //         }
  //       }
  //       this.setState({ selectedCoin: coin });
  //     }
  //   };

  //   selectAllCoin = (e, data) => {
  //     let coin = [];
  //     let coinList = data;
  //     if (e.target.checked) {
  //       for (var i = 0; i < coinList?.length; i++) {
  //         coin.push(coinList[i].coin_id);
  //         console.log(coin);
  //       }
  //       this.setState({ selectedCoin: coin });
  //     } else {
  //       for (var i = 0; i < coinList?.length; i++) {
  //         coin.splice(i, 1);
  //         console.log(coin);
  //       }

  //       this.setState({ selectedCoin: coin });
  //     }
  //   };

  //   updateForm = (values) => {
  //     if (this.state.defaultType == "withdraw_limit") {
  //       console.log("values >>", values);
  //       if (
  //         +values.min_withdraw_limit !== 0 &&
  //         +values.max_withdraw_limit !== 0
  //       ) {
  //         if (+values.min_withdraw_limit >= +values.max_withdraw_limit) {
  //           alert(
  //             "Minimum withdraw limit must be less than Maximum withdraw limit"
  //           );
  //           return false;
  //         }
  //       }
  //       const param = {
  //         key: this.state.defaultType,
  //         // daily_withdraw_limit: Number(values.daily_withdraw_limit),
  //         min_withdraw_limit: Number(values.min_withdraw_limit),
  //         max_withdraw_limit: Number(values.max_withdraw_limit),
  //         coin_ids: this.state.selectedCoin,
  //         withdraw_limit_type: values?.type,
  //       };

  //       console.log(param);

  //       this.props.updateWithdrawLimit(param);
  //       this.setState({ isFieldSelected: false });
  //     } else if (this.state.defaultType == "deposit_limit") {
  //       console.log("values >>", values);
  //       if (
  //         values.min_deposit_limit == "NaN" ||
  //         values.min_deposit_limit == undefined ||
  //         values.min_deposit_limit == ""
  //       ) {
  //         alert("Please enter minimum deposit amount");
  //         return false;
  //       }
  //       const param = {
  //         key: this.state.defaultType,
  //         min_deposit_limit: Number(values.min_deposit_limit),
  //         coin_ids: this.state.selectedCoin,
  //         deposit_limit_type: values?.type,
  //       };

  //       console.log(param);
  //       this.props.updateWithdrawLimit(param);
  //       this.setState({ isFieldSelected: false });
  //     } else {
  //       //Withdraw Fee
  //       // console.log('values value',values);
  //       if (
  //         values.value == "NaN" ||
  //         values.value == undefined ||
  //         values.value == ""
  //       ) {
  //         alert("Please enter fee amount");
  //         return false;
  //       }
  //       const param = {
  //         key: this.state.defaultType,
  //         value: Number(values.value),
  //         coin_ids: this.state.selectedCoin,
  //         // fee_coin: this.state.feeCoin,
  //       };

  //       console.log(param);
  //       this.props.updateWithdrawFee(param);
  //       // this.setState({ isFieldSelected: false });
  //       this.setState({ isFieldSelected: false, defaultType: "" });
  //       values.value = "";
  //     }
  //   };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <div>
          <h4>Testing error--- Error404 </h4>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // feeSettingDetails: state.account.feeSettingDetails,
    // coinsList: state.user.coinsList,
  };
};
const mapDispatchToProps = (dispatch) => {
  // this.setState({ isFieldSelected: false, defaultType: "" })
  return {
    // getAdminSettings: (data) => dispatch(getAdminSettings(data)),
    // updateAdminSetting: (data) => dispatch(updateAdminSetting(data)),
    // getCoinList: (params) => dispatch(getCoinList(params)),
    // updateWithdrawLimit: (data) => dispatch(updateWithdrawLimit(data)),
    // updateWithdrawFee: (data) => dispatch(updateWithdrawFee(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "feeSettingForm" })(Error404));
