import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  getIncentiveList,
  changeIncentiveStatus,
  addIncentive,
  changeIncentiveAmount,
} from "../../Redux/actons/reward.action";
import { Link } from "react-router-dom";
import { reset, Field, reduxForm, getFormValues } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Pagination, Dropdown, Form, Confirm, Radio } from "semantic-ui-react";
import swapIcon from "../../images/swap.png";
import { required } from "redux-form-validators";
import { amountLimit } from "../../validators/customValidator";
import { FormField } from "../../components/FormField";
import { getCoinList, activeCoinList } from "../../Redux/actons/user.action";
import { SelectField } from "../../components/SelectField";

const rewardOptions = [
  { key: "m", text: "Worth of fiat", value: "worth_of_fiat" },
  { key: "k", text: "Actual Value", value: "actual_value" },
  // { key: "l", text: "Fiat", value: "fiat" },
];

export class ViewIncentive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      order: "asc",
      amountModalOpen: false,
      rewardAmount: "",
      reward_type: "worth_of_fiat",
      planData: "",
      id: "",
      selectedCoin: "",
      open: false,
      amount: "",
      incetiveData: "",
      worthFiat: "",
      actualValue: "",
      deleteConfirm: false,
      deteteData: {},
      transactionTypes: 1,
      userAmount: "",
    };
  }
  componentDidMount = () => {
    this.props.activeCoinList();
    if (this.props.location.state) {
      localStorage.setItem("id", this.props.location.state.id);
      console.log("this.props.location.state", this.props.location.state);
      if (this.state.id == "") {
        this.setState({ id: this.props.history.location.state.id }, () => {
          this.getList(this.props.history.location.state.id);
          this.props.getCoinList();
        });
      } else {
        this.props.history.goBack();
      }
    } else {
      this.setState({ id: localStorage.getItem("id") });
      this.getList(localStorage.getItem("id"));
    }
  };

  getList = (id) => {
    const data = {
      page: this.state.page,
      perPage: this.state.perPage,
      id: id,
    };
    this.props.getIncentiveList(data);
  };

  enableDisableReward = (data, id) => {
    const buttonState = { status: data, id: id };
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      id: this.state.id,
    };

    this.props.changeIncentiveStatus(params, buttonState);
  };

  deleteReward = (data, id, status) => {
    if (status == "active") {
      alert("Please inactive the incentive before deleting.");
      return false;
    }
    let deteteData = { status: data, id: id };
    this.setState({ deteteData: deteteData, deleteConfirm: true });
  };

  closeDeleteConfirm = () => {
    this.setState({ deteteData: {}, deleteConfirm: false });
  };

  onConfirmation = () => {
    const buttonState = {
      status: this.state.deteteData.status,
      id: this.state.deteteData.id,
    };
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      id: this.state.id,
    };

    this.props.changeIncentiveStatus(params, buttonState);
    this.setState({ deteteData: {}, deleteConfirm: false });
  };

  editPlanAmount = (data) => {
    const post_data = {
      reward_amount: this.state.amount,
      id: this.state.incetiveData.reward_incentive_id,
    };
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      id: this.state.id,
    };
    this.props.changeIncentiveAmount(post_data, params);
    this.toggleAmount();
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getList(this.state.id);
    });
  };

  //   onSearchList = (search) => {
  //     this.setState({ searchKey: search }, () => {
  //       this.getPairList();
  //     });
  //   };

  openAmountModal = (data) => {
    this.setState({ openAmountModal: true });
  };

  openIncentiveEditModal = (data) => {
    this.setState({
      open: true,
      amount: data.reward_amount,
      incetiveData: data,
    });
  };

  toggle = () =>
    this.setState({ openAmountModal: !this.state.openAmountModal });

  toggleAmount = () => this.setState({ open: !this.state.open });

  addIncentive = async (data) => {
    let rewardType = this.state.reward_type;
    let reward_coin;
    if (!this.state.rewardAmount) {
      return;
    }
    if (this.state.transactionTypes === 0) {
      // this.setState({ reward_type: "fiat" });
      rewardType = "actual_value";
      reward_coin = "690";
      // reward_coin = this.props.coinsList.find(
      //   (data) => data.coin_symbol === "inr"
      // ).coin_id;
    } else {
      reward_coin = this.state.selectedCoin;
    }

    let post_data = {
      reward_plan: this.state.id,
      reward_coin,
      reward_amount: this.state.rewardAmount,
      reward_type: rewardType,
      user_amount: this.state.userAmount,
    };
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      id: this.state.id,
    };
    console.log(params);
    this.props.addIncentive(post_data, params);
    this.toggle();
  };

  checkForEnable = (list, data) => {
    if (data?.incentiveStatus == "active") {
      return true;
    } else if (
      list?.find(
        (el) =>
          el.coin_symbol == data?.coin_symbol &&
          el.incentiveStatus == "active" &&
          el?.reward_type == data?.reward_type
      ) &&
      data?.incentiveStatus == "inactive"
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    let { handleSubmit } = this.props;

    return (
      <div>
        <Header headerTitle="Incentive Management" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="rewardManagement">Reward Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Incentive Management</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          {/** Are you sure delete incentive */}
          <Confirm
            content={`Are you sure to delete incentive`}
            style={{
              height: "auto",
              top: "5%",
              left: "auto",
              bottom: "auto",
              right: "auto",
            }}
            size="small"
            className="confirm-model"
            open={this.state.deleteConfirm}
            onCancel={this.closeDeleteConfirm}
            onConfirm={this.onConfirmation}
          />
        </div>
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Button
                        color="success"
                        onClick={() => this.openAmountModal()}
                      >
                        Add incentive
                      </Button>
                      {/* <Input
                        placeholder="Search"
                        onChange={(e) => this.onSearchList(`${e.target.value}`)}
                      ></Input>

                      {/* <Link to="exchange-coin">
                        <Button color="success btnMamagePermission">
                          Add coin pair
                        </Button>
                      </Link> */}
                      {/* <Link to="swap-history">
                        <Button color="success btnMamagePermission">
                          Swap History
                        </Button>
                      </Link> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col"> Coin Name</th>
                        <th scope="col">Coin Symbol</th>
                        <th scope="col">Reward Amount</th>
                        <th scope="col">Reward Type</th>
                        {/* <th scope="col">Reward coin</th> */}
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.rewardList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.rewardList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.coin_name.toUpperCase()}</td>
                            <td>{data.coin_symbol.toUpperCase()}</td>
                            <td>{data.reward_amount}</td>
                            <td>
                              {data.reward_type == "actual_value" &&
                                "Actual value"}
                              {data.reward_type == "worth_of_fiat" &&
                                "Worth of fiat"}
                              {data.reward_type == "fiat" && "Fiat"}
                              {/* {data.reward_type == "actual_value"
                                ? "Actual value"
                                : "Worth of fiat"} */}
                            </td>
                            {/* <td>{data.reward_coin}</td> */}
                            <td>{data.incentiveStatus.toUpperCase()}</td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color={
                                    data.incentiveStatus === "active"
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={() => {
                                    if (
                                      this.checkForEnable(
                                        this.props.rewardList,
                                        data
                                      ) == true
                                    ) {
                                      this.enableDisableReward(
                                        data.incentiveStatus === "active"
                                          ? "inactive"
                                          : "active",
                                        data.reward_incentive_id
                                      );
                                    } else {
                                      alert(
                                        `${data?.coin_name.toUpperCase()} with reward type ${
                                          data?.reward_type
                                        } is alreay active`
                                      );
                                    }
                                  }}
                                >
                                  {data.incentiveStatus === "active"
                                    ? "Inactive"
                                    : "Active"}
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() =>
                                    this.deleteReward(
                                      "is_deleted",
                                      data.reward_incentive_id,
                                      data.incentiveStatus
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                                {/* <Button
                                  color={"success"}
                                  onClick={() => this.openIncentiveEditModal(data)}
                                >
                                  Edit
                                </Button> */}
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <Modal isOpen={this.state.openAmountModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Edit Amount</ModalHeader>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  {/* <label>Rew </label> */}
                  <Radio
                    label="Coin"
                    name="coin"
                    value={1}
                    checked={this.state.transactionTypes === 1}
                    onChange={(e, { value }) =>
                      this.setState({ transactionTypes: value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Fiat"
                    name="fiat"
                    value={0}
                    checked={this.state.transactionTypes === 0}
                    onChange={(e, { value }) =>
                      this.setState({ transactionTypes: value })
                    }
                  />
                </Form.Field>
              </Form.Group>
              {this.state.transactionTypes === 1 && (
                <Form.Group>
                  <Form.Field>
                    <label>Select Coin </label>
                    <Field
                      name="coin_id"
                      search={false}
                      selectOnBlur={false}
                      validate={[required()]}
                      component={SelectField}
                      defaultValue={this.state.selectedCoin}
                      children={this.props.activeCoinListData}
                      placeholder="Select "
                      onChange={(e, data) =>
                        this.setState({ selectedCoin: data })
                      }
                    ></Field>
                  </Form.Field>
                </Form.Group>
              )}
              {(this?.props?.location?.state?.type === "first_trade" ||
                this?.props?.location?.state?.type === "deposit_fiat") && (
                <Form.Group>
                  <Form.Field>
                    <label>Amount</label>
                    <input
                      type="number"
                      name="user_amount"
                      min="0"
                      validate={[required(), amountLimit()]}
                      onChange={(e, data) =>
                        this.setState({ userAmount: e.target.value })
                      }
                    ></input>
                  </Form.Field>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Field>
                  <label>Reward amount</label>
                  <input
                    type="number"
                    name="reward_amount"
                    min="0"
                    validate={[required(), amountLimit()]}
                    onChange={(e, data) =>
                      this.setState({ rewardAmount: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
              {this.state.transactionTypes === 1 && (
                <Form.Group>
                  <Form.Field>
                    <label>Reward Type </label>
                    <Field
                      name="reward_type"
                      search={false}
                      selectOnBlur={false}
                      validate={[required()]}
                      component={SelectField}
                      defaultValue={this.state.reward_type}
                      children={rewardOptions}
                      placeholder="Select "
                      onChange={(e, data) =>
                        this.setState({ reward_type: data })
                      }
                    ></Field>
                  </Form.Field>
                </Form.Group>
              )}
              {/* <Form.Group>
                <Form.Field>
                  <label>Worth of Fiat</label>
                  <input
                    type="number"
                    name="worth_fiat"
                    min="0"
                    validate={[required(), amountLimit()]}
                    onChange={(e, data) =>
                      this.setState({ worthFiat: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Actual Value</label>
                  <input
                    type="number"
                    name="actual_value"
                    min="0"
                    validate={[required(), amountLimit()]}
                    onChange={(e, data) =>
                      this.setState({ actualValue: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group> */}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openAmountModal: false })}
            >
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() => this.addIncentive(this.state.planData)}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.open} toggle={this.toggleAmount}>
          <ModalHeader toggle={this.toggleAmount}>Edit Amount</ModalHeader>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Amount</label>
                  <input
                    type="number"
                    name="min_amount"
                    min="0"
                    maxLength={10}
                    value={this.state.amount}
                    validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ amount: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ open: false })}
            >
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() => this.editPlanAmount(this.state.planData)}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    rewardList: state.rewardList.incentiveList.list,
    coinsList: state.user.coinsList,
    total: state.rewardList.incentiveList.totalRecords,
    activeCoinListData: state.user.activeCointList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIncentiveList: (id) => dispatch(getIncentiveList(id)),
    changeIncentiveStatus: (params, id) =>
      dispatch(changeIncentiveStatus(params, id)),
    getCoinList: () => dispatch(getCoinList()),
    activeCoinList: () => dispatch(activeCoinList()),

    addIncentive: (data, param) => dispatch(addIncentive(data, param)),
    changeIncentiveAmount: (data, params) =>
      dispatch(changeIncentiveAmount(data, params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "exchangeCoinForm" })(ViewIncentive));
