import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  CardFooter,
} from "reactstrap";
import Header from "components/Headers/Header";
import ReactTooltip from "react-tooltip";
import { Grid, GridColumn } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
// import { Table, TableRow } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import {
  getContactQueryList,
  getSubjectList,
  updateQuery,
  getContactUsCSV,
} from "../../Redux/actons/subject.action";
import { listDateFormat, nl2br } from "../../utils/utils";
import {
  Pagination,
  Dropdown,
  Form,
  Confirm,
  Popup,
  Input,
} from "semantic-ui-react";
import { IMAGE_PATH } from "../../constants/index";
import DashboardResult from "./SubjectManagement/DashboardResult";
import moment from "moment";

const options = [
  { key: "j", text: "None", value: "" },
  { key: "m", text: "Pending", value: "pending" },
  { key: "k", text: "Resolved", value: "resolved" },
  { key: "l", text: "More info Required", value: "more info required" },
];
export class ContactQueryManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      subject: "",
      from_date: "",
      to_date: "",
      datepicker_start_date: "",
      datepicker_end_date: "",
      sortBy: "",
      getDetailsId: {},
      modal: false,
      openConfirm: false,
    };
  }
  toggle = () => this.setState({ open: !this.state.open });

  componentDidMount = () => {
    this.getQueryList();
    this.getSubjectList();
  };

  updateQueryMoreInfo = (id) => {
    this.props.history.push({
      pathname: `update-query`,
      search: id.id.toString(),
      state: id,
    });
  };

  getSubjectList = () => {
    let data = {
      // search: this.state.searchKey,
      status: 1,
      // subject: this.state.subject,
      // perPage: this.state.perPage,
      // page: this.state.page,
    };
    this.props.getSubjectList(data);
  };
  SetStartDate(data) {
    if (data.value !== null) {
      let date = new Date(data.value);
      let from_date = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
      this.setState({ from_date: from_date });
      this.setState({ datepicker_start_date: data.value });
    } else {
      this.setState({ from_date: "" });
      this.setState({ datepicker_start_date: data.value });
    }
  }
  SetEndDate(data) {
    //     var msDiff =  new Date(data.value).getTime() -  new Date(this.state.from_date).getTime()
    // var daysTill30June2035 = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    // console.log('date--------------', daysTill30June2035);

    if (data.value !== null) {
      let date = new Date(data.value);
      let to_date = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
      this.setState({ to_date: to_date });
      this.setState({ datepicker_end_date: data.value });
    } else {
      this.setState({ to_date: "" });
      this.setState({ datepicker_end_date: data.value });
    }
  }
  getQueryList = () => {
    let data = {
      search: this.state.searchKey,
      status: this.state.order,
      subject: this.state.subject,
      perPage: this.state.perPage,
      page: this.state.page,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      // to_date: this.state.to_date,
    };
    this.props.getContactQueryList(data);
  };
  openConfrimationModal = (status, id) => {
    this.setState({ openConfirm: true, resolvedStatus: status, id: id });
  };
  close = () => {
    this.setState({ openConfirm: false });
  };

  onConfirmation = () => {
    console.log(this.state.id, "this.state.id");
    const buttonState = {
      status: this.state.resolvedStatus,
      id: this.state.id,
    };
    this.props.getContactQueryList(buttonState);
    this.props.updateQuery(buttonState);
    this.setState({ openConfirm: false });
  };

  // deleteBanner = (id) => {
  //   let data = {
  //     searchKey: this.state.searchKey,
  //     order: this.state.order,
  //     perPage: this.state.perPage,
  //     page: this.state.page,
  //   };

  //     const param = {
  //       announcement_id : id
  //     }
  //     this.props.deleteAnnouncement(param, data);
  // }

  // //page change
  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getQueryList();
    });
  };

  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ order: data.value }, () => {
      this.getQueryList();
    });
  };
  filterBySubject = (e, data) => {
    console.log(data.value);
    this.setState({ subject: data.value }, () => {
      this.getQueryList();
    });
  };
  //search
  onSearchList = (abc) => {
    if (this.state.from_date !== "" && this.state.to_date !== "") {
      var d1 = new Date(this.state.datepicker_start_date);
      var d2 = new Date(this.state.datepicker_end_date);
      if (d1.getTime() >= d2.getTime()) {
        alert("End date must be greater than Start date");
        this.setState({ datepicker_end_date: null });
        return false;
      }
    }
    // this.setState({ searchKey: abc, page: 1 }, () => {
    this.getQueryList();
    // });
  };
  // openModel = (data) => {
  //   // console.log("detail", data);
  //   this.setState({ getDetailsId: data, open: true });
  // };

  DownLoadCSV = () => {
    const data = {
      page: 1,
      perPage: this.props?.queryListRecords?.totalRecords,
      status: this.state.order,
      subject: this.state.subject,
      // searchKey: this.state.searchKey,
      // order: this.state.order,
      // coin_id: this.state.selectedCoin,
      // status: this.state.status,
    };
    this.props.getContactUsCSV(data);
    // try {

    //   //api/v1/admin/instalment/plan-list
    // } catch (err) {
    //   console.log(err);
    // }
  };

  render() {
    let { handleSubmit } = this.props;
    let subjectedLists = [
      {
        key: "",
        text: "none",
        value: "",
      },
    ];
    let subjectedLists1 = this.props.subjectList ? this.props.subjectList : [];
    let subjectedLists12 = this.props.subjectList
      ? this.props.subjectList.filter((data) => data.status == "0")
      : [];
    let obj = {
      key: "",
      text: "",
      value: "",
    };
    if (subjectedLists1.length > 0) {
      for (let i = 0; i < subjectedLists1.length; i++) {
        obj = {
          key: i,
          text: subjectedLists1[i].title,
          value: subjectedLists1[i].title,
        };
        subjectedLists.push(obj);
      }
    }
    console.log(
      "this.props?.queryListRecords?.totalPagess ----------   ",
      this.props?.queryListRecords?.totalPages,
      this.props?.queryListRecords
    );
    return (
      <div>
        <Header headerTitle="Contact Queries" />
        <div>
          <div>
            <div>
              <Confirm
                content={`Are you sure to resolved this issue ?`}
                style={{
                  height: "auto",
                  top: "5%",
                  left: "auto",
                  bottom: "auto",
                  right: "auto",
                }}
                size="small"
                className="confirm-model"
                open={this.state.openConfirm}
                onCancel={this.close}
                onConfirm={this.onConfirmation}
              />
            </div>
          </div>
          <Container className="contentBlock" fluid>
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Card className="shadow">
                <Grid.Row
                  columns={2}
                  // style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Total"}
                      labResult={
                        this.props?.queryListRecords?.pendingList
                          ? this.props?.queryListRecords?.pendingList +
                            this.props?.queryListRecords?.resolveList +
                            this.props?.queryListRecords?.infoReqList
                          : "0"
                      }
                    />
                  </GridColumn>
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Pending"}
                      labResult={
                        this.props?.queryListRecords?.pendingList
                          ? this.props?.queryListRecords?.pendingList
                          : "0"
                      }
                    />
                  </GridColumn>
                  {/* </Grid.Row>
            <Grid.Row
              columns={2}
              // style={{ paddingTop: "10px", paddingBottom: "10px" }}
            > */}
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Resolved"}
                      labResult={
                        this.props?.queryListRecords?.resolveList
                          ? this.props?.queryListRecords?.resolveList
                          : "0"
                      }
                    />
                  </GridColumn>
                  {/* </Grid.Row>

            <Grid.Row
              columns={2}
              // style={{ paddingTop: "10px", paddingBottom: "10px" }}
            > */}
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"More Info"}
                      labResult={
                        this.props?.queryListRecords?.infoReqList
                          ? this.props?.queryListRecords?.infoReqList
                          : "0"
                      }
                    />
                  </GridColumn>
                </Grid.Row>
              </Card>
            </div>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      {/* <CardHeader className="border-0"> */}
                      {/* <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Announcement List</h3>
                    </div> */}
                      {/* <div className="selectSearchOption"> */}
                      <Input
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        options={options}
                        selection
                        value={this.state.order}
                        placeholder="Filter by status"
                        onChange={(e, data) => this.filterBy(e, data)}
                      />

                      <Dropdown
                        // selectOnBlur={false}
                        className="selectUserStyle mx-3 "
                        options={subjectedLists}
                        placeholder="Filter by Subject"
                        selection
                        value={this.state.subject}
                        // onChange={(e, data) => this.filterBy(e, data)}
                        onChange={(e, data) => this.filterBySubject(e, data)}
                      />
                      {/* <Button
                        color="default btnMamagePermission"
                        onClick={() => this.addNewContent()}
                      >
                        + Add Content
                      </Button> 
                      {/* </div> */}
                      {/* </CardHeader> */}
                    </div>
                    {/* <CardHeader className="border-0">*/}
                    <div
                      style={{ paddingTop: "15px" }}
                      className="selectSearchOption"
                    >
                      {/* <Form.Group>
                        <Form.Field>
                          <label style={{ color: "#000" }}>
                            Select start date
                          </label>
                          <SemanticDatepicker
                            style={{ color: "#000" }}
                            locale="en-US"
                            onChange={(event, data) => this.SetStartDate(data)}
                            type="basic"
                            format="YYYY-MM-DD"
                            value={this.state.datepicker_start_date}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field>
                           <label style={{ color: "#000" }}>
                            Select end date
                          </label>
                          <SemanticDatepicker
                            style={{ color: "#000" }}
                            locale="en-US"
                            onChange={(event, data) => this.SetEndDate(data)}
                            type="basic"
                            format="YYYY-MM-DD"
                            value={this.state.datepicker_end_date}
                          />
                        </Form.Field>
                      </Form.Group> */}
                      <Link to="add-subject">
                        <Button color="success btnMamagePermission">
                          Subject
                        </Button>
                      </Link>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => {
                          this.DownLoadCSV();
                          // this.props.history.push("user-taral-gold-requests");
                        }}
                      >
                        DownLoad CSV
                      </Button>
                      {/* <Link to="coin-wise-history">
                          <Button color="success btnMamagePermission">
                            Coin Wise History
                          </Button>
                        </Link>  */}
                      <Link to="taralgold-reply-broadcasting">
                        <Button color="success btnMamagePermission">
                          Taral Gold Broadcasting
                        </Button>
                      </Link>
                    </div>
                  </CardHeader>

                  {console.log(this.props.queryList, "=====getQueryList")}

                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Ticket ID</th>
                        <th scope="col">Email</th>
                        {/* <th scope="col">Content</th> */}
                        <th scope="col">Subject</th>
                        <th scope="col">Description</th>
                        <th scope="col">Status</th>
                        <th scope="col">Image</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.queryList?.map((contentData, index) => (
                        <tr className="table-tr" key={index}>
                          <td>{contentData?.id}</td>
                          <td>{contentData?.email}</td>
                          <td>{contentData?.subject}</td>
                          <td>
                            {" "}
                            <span>
                              {contentData?.message.length <= 43
                                ? `${contentData?.message.slice(13, 43)}`
                                : `${contentData?.message.slice(13, 43)}...`}
                            </span>
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {contentData?.status}
                          </td>
                          {/* <td><span className="text-left">{contentData.content != null ? nl2br(contentData.content.replace(/(?:\r\n|\r|\n)/g, '<br />')) : "None"}</span></td> */}
                          <td>
                            {contentData?.image ? (
                              <img
                                onClick={() =>
                                  window.open(
                                    `${IMAGE_PATH}${contentData?.image}`,
                                    "_blank"
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                  width: 50,
                                  height: 50,
                                }}
                                src={`${IMAGE_PATH}${contentData?.image}`}
                              ></img>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>
                            {contentData.updated_at != ""
                              ? listDateFormat(contentData.updated_at)
                              : "None"}
                          </td>
                          <td className="text-left">
                            <span className="mb-0 text-sm">
                              {/* <Button
                                color="primary"
                                onClick={() =>
                                  this.ResolvedQuery(contentData.id)
                                }
                              >
                                Resolved
                              </Button> */}

                              {contentData?.status !== "resolved" && (
                                <Button
                                  color={"danger"}
                                  onClick={() =>
                                    this.openConfrimationModal(
                                      "resolved",
                                      contentData.id
                                    )
                                  }
                                  // disabled={
                                  //   contentData?.status === "resolved" ||
                                  //   contentData?.status === "more info required"
                                  // }
                                >
                                  Resolved
                                </Button>
                              )}
                              <Button
                                color="primary"
                                onClick={() =>
                                  this.updateQueryMoreInfo(contentData)
                                }
                                disabled={contentData?.status === "resolved"}
                              >
                                More Info Required
                              </Button>
                            </span>
                          </td>
                        </tr>
                      ))}
                      {this.props.queryList?.length == 0 && (
                        <tr>
                          <td>No record</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      {this.props?.queryListRecords?.totalPages > 1 && (
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={2}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          prevItem={true}
                          nextItem={true}
                          siblingRange={1}
                          // boundaryRange={0}
                          // defaultActivePage={1}
                          // ellipsisItem={null}
                          // firstItem={null}
                          // lastItem={null}
                          // siblingRange={1}
                          // ellipsisItem={showEllipsis ? undefined : null}
                          // firstItem={showFirstAndLastNav ? undefined : null}
                          // lastItem={showFirstAndLastNav ? undefined : null}
                          // prevItem={showPreviousAndNextNav ? undefined : null}
                          // nextItem={showPreviousAndNextNav ? undefined : null}
                          //
                          totalPages={Math.ceil(
                            this.props?.queryListRecords?.totalPages
                          )}
                        />
                      )}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        {/* 
        <div>
          <Modal isOpen={this.state.open} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Banner</ModalHeader>
            <ModalBody>
              <div className="userNameList">
                <div className="itemStyle">
                  <label
                    style={{ color: "black", fontWeight: "bold", fontSize: 14 }}
                  >
                    {" "}
                    Subject:<p>{this.state.getDetailsId.subject}</p>
                  </label>
                </div>
                <div className="itemStyle">
                  <label
                    style={{ color: "black", fontWeight: "bold", fontSize: 14 }}
                  >
                    Message:<p>{this.state.getDetailsId.message} </p>
                  </label>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    queryList: state?.subjectList?.queryList?.history,
    queryListRecords: state?.subjectList?.queryList,
    subjectList: state.subjectList?.subjectList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContactQueryList: (data) => dispatch(getContactQueryList(data)),
    getSubjectList: (data) => dispatch(getSubjectList(data)),
    updateQuery: (data) => dispatch(updateQuery(data)),
    getContactUsCSV: (data) => dispatch(getContactUsCSV(data)),
    // deleteAnnouncement: (data, params) => dispatch(deleteAnnouncement(data, params))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactQueryManagement)
);
