import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { verifyGoogleAuthRequest } from "../../Redux/actons/security.action";
import { Redirect } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { InjectedConnector } from "@web3-react/injected-connector";
import { Pagination, Dropdown, Icon } from "semantic-ui-react";
import { IMAGE_PATH } from "constants/index";
// import { ethers } from "ethers";
import Blockies from "react-blockies";

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 9731],
});

const ChainNetworkId = [
  {
    key: "ETH",
    text: (
      <>
        {" "}
        <img
          src={`${IMAGE_PATH}images/1634290767066-628px-Ethereum_logo_2014.svg.png`}
          alt="logo"
          style={{ height: 32, width: 25 }}
        />
        Ethereum Mainnet (ETH)
      </>
    ),
    value: "1",
  },
  {
    key: "BNB",
    text: (
      <>
        {" "}
        <img
          src={`${IMAGE_PATH}images/1655508573511-bnb-bnb-logo.png`}
          alt="logo"
          style={{ height: 32, width: 25 }}
        />
        Binance Mainnet (BNB)
      </>
    ),
    value: "56",
  },
  {
    key: "MATIC",
    text: (
      <>
        {" "}
        <img
          src={`${IMAGE_PATH}images/1655508675070-polygon-matic-logo%20(1).png`}
          alt="logo"
          style={{ height: 32, width: 25 }}
        />
        Matic Mainnet (MATIC)
      </>
    ),
    value: "137",
  },
];
const ConnectWalletFun = (props) => {
  const { activate, account, deactivate, chainId } = useWeb3React();
  const web3 = (window.web3 = new Web3(window.ethereum));
  const [networkId, setNetworkId] = React.useState(chainId);

  //   signerAddress, contract_1155, contract_721, setContract_1155, setContract_721, setSignerAddress , setNetworkId, SetProvider
  const [addressAmount, setAddressAmount] = React.useState(chainId);
  // console.log(chainId, "====================================");
  // console.log(networkId);
  // console.log("====================================");
  React.useEffect(() => {
    if (chainId) {
      NetworkHandlet(chainId.toString(), { value: chainId.toString() });
    }
  }, [chainId]);
  const NetworkHandlet = (e, data) => {
    try {
      // console.log(e, "e--------->>>>>> data", data);
      setNetworkId(data.value);
    } catch (error) {
      console.log(error, "error");
    }
  };
  function connectWallet12(params) {
    try {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          console.log("ERROR", error);
          const errorMSG = error.message; //+ ' Please install Metamask';
          //   toast.warn(errorMSG);
          // alert(errorMSG);
          activate(injected);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  //   const dataDisplauhandler = async () => {
  //     try {
  //       const addressUser = web3.eth.getBalance(account);
  //       const convertedStringAmount = (await addressUser).toString();
  //       console.log("convertedStringAmount", convertedStringAmount);
  //       console.log("address*******", web3.utils.fromWei(convertedStringAmount));
  //       setAddressAmount(web3.utils.fromWei(convertedStringAmount));
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   React.useEffect(() => {
  //     dataDisplauhandler();
  //   }, [account, chainId]);

  //

  //   const { connectWallet, disconnectWallet, provider, error } = useWeb3Modal();

  //   useEffect(() => {
  //     const getAddress = async () => {
  //       const signer = provider.getSigner();
  //       const address = await signer.getAddress();
  //       setSignerAddress(address);

  //       const networkId = await provider.getNetwork();
  //       console.log(networkId);
  //       setNetworkId(networkId);

  //       // if(chainId !== 8001)

  //       // for erc721 mainnet and testnet
  //       setContract_721(
  //         new ethers.Contract("0x002606386e5D884e338A5ac351D3A79260bB65CD", abi)
  //       );

  //       // for erc1155 mainnet
  //       if (networkId == "137")
  //         setContract_1155(
  //           new ethers.Contract(
  //             "0x002606386e5D884e338A5ac351D3A79260bB65CD",
  //             abi_1155
  //           )
  //         );
  //       // for erc1155 testnet
  //       else
  //         setContract_1155(
  //           new ethers.Contract(
  //             "0x002606386e5D884e338A5ac351D3A79260bB65CD",
  //             abi_1155
  //           )
  //         );

  //       console.log(contract_721, contract_1155);
  //     };
  //     if (provider) getAddress();
  //     else setSignerAddress("");
  //   }, [provider]);

  //   const handleClickConnect = async () => {
  //     await connectWallet();
  //   };

  //   const handleClickAddress = () => {
  //     disconnectWallet();
  //   };
  //

  return (
    <React.Fragment>
      {/* {!account && (
        <div
          className="twoFactorauth"
          style={{
            paddingTop: "15px",
          }}
        >
          <Button
            color="success btnMamagePermission"
            onClick={() => connectWallet()}
          >
            {" "}
            Connect Wallet
          </Button>
        </div>
      )} */}
      {/* {account && ( */}
      <div
        className="selectSearchOption"
        style={{
          paddingTop: "15px",
        }}
      >
        {/* <div
            style={{
              border: "1px solid #2dce89",
              borderRadius: "15px",
              //   width: "200px",
              padding: "5px 15px",
              margin: "5px 15px",
            }}
          >
            {addressAmount ? parseFloat(addressAmount).toFixed(4) : "0"}{" "}
            {
              ChainNetworkId.find((data) => data.value == chainId.toString())
                ?.key
            }{" "}
            {`${account.slice(0, 6)}...${account.slice(account.length - 4)}`}
          </div> */}
        <button className={"blockies"} onClick={!account && connectWallet12}>
          <Blockies
            className={"blockies"}
            seed={"Jeremy"}
            // dtyle={{
            //   display: "flex",
            //   /* align-content: center; */
            //   alignItems: "center",
            // }}
            // seed={account && account.toLowerCase()}
            size={8}
            scale={3}
          />
          <div>
            {account
              ? account.slice(0, 6) + "..." + account.slice(-4)
              : "Connect Wallet"}
          </div>
        </button>
        {networkId && (
          <Dropdown
            className="selectUserStyle mx-2"
            options={ChainNetworkId}
            placeholder="Filter"
            selection
            defaultValue={networkId}
            // value={networkId || chainId}
            // onChange={(e, data) => this.listByUser(e, data)}
            onChange={(e, data) => NetworkHandlet(e.target.value, data)}
          />
        )}
      </div>
      {/* )} */}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("DAIVIK", state);
  return {
    token: state.persist["c_user"],
    user: state.user["user"],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifygoogleAuth: (values) => dispatch(verifyGoogleAuthRequest(values)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConnectWalletFun);
