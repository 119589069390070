import { FundService } from "../../Services/FundServices";
import { toast } from "../../components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";
import { reset } from "redux-form";
import { useWeb3React } from "@web3-react/core";
// import { InjectedConnector } from "@web3-react/injected-connector";

// export const injected = new InjectedConnector({
//   supportedChainIds: [1, 3, 4, 5, 42, 56, 97],
// });
export const actionTypes = {
  SAVE_FUND_DETAILS: "SAVE_FUND_DETAILS",
  SAVE_VIEW_FUND_DETAILS: "SAVE_VIEW_FUND_DETAILS",
  SAVE_FUND_FILE_DETAILS: "SAVE_FUND_FILE_DETAILS",
  SAVE_SAMPLE_FILE_LIST: "SAVE_SAMPLE_FILE_LIST",
  TOTAL_FUND_RECORDS: "TOTAL_FUND_RECORDS",
  GET_USER_FUND_COIN: "GET_USER_FUND_COIN",
  SAVE_USER_WALLET_ID: "SAVE_USER_WALLET_ID",
  SAVE_ALL_FUND__USER: "SAVE_ALL_FUND__USER",
  SAVE_HOT_WALLET_BALANCE: "SAVE_HOT_WALLET_BALANCE",
  SAVE_COLD_WALLET_HISTORY: "SAVE_COLD_WALLET_HISTORY",
  SAVE_FUND_HISTORY: "SAVE_FUND_HISTORY",
  SAVE_USER_BALANCE: "SAVE_USER_BALANCE",
  SAVE_UNIQUE_USER_BALANCE: "SAVE_UNIQUE_USER_BALANCE",
  SAVE_LOCK_FUND_LIST: "SAVE_LOCK_FUND_LIST",
  SAVE_LOCK_FUND_HISTORY: "SAVE_LOCK_FUND_HISTORY",
  OPEN_CLOSE_SECURITY: "OPEN_CLOSE_SECURITY",
  OPEN_CLOSE_COLD_MODAL: "OPEN_CLOSE_COLD_MODAL",
};

export function saveUserBalance(data) {
  return {
    type: actionTypes.SAVE_USER_BALANCE,
    data,
  };
}

export function saveUniqueUserBalance(data) {
  return {
    type: actionTypes.SAVE_UNIQUE_USER_BALANCE,
    data,
  };
}

export function saveColdWalletHistory(data, total) {
  return {
    type: actionTypes.SAVE_COLD_WALLET_HISTORY,
    data,
    total,
  };
}
export function saveHotWalletBalance(data) {
  return {
    type: actionTypes.SAVE_HOT_WALLET_BALANCE,
    data,
  };
}
export function saveAllFundUsers(data) {
  return {
    type: actionTypes.SAVE_ALL_FUND__USER,
    data,
  };
}

export function saveLockFundList(data) {
  return {
    type: actionTypes.SAVE_LOCK_FUND_LIST,
    data,
  };
}

export function saveWalletId(data) {
  return {
    type: actionTypes.SAVE_USER_WALLET_ID,
    data,
  };
}
export function saveFundDetails(data) {
  return {
    type: actionTypes.SAVE_FUND_DETAILS,
    data,
  };
}
export function saveViewFundDetails(data) {
  return {
    type: actionTypes.SAVE_VIEW_FUND_DETAILS,
    data,
  };
}
export function totalFundRecords(data) {
  return {
    type: actionTypes.TOTAL_FUND_RECORDS,
    data,
  };
}
export function saveFundHistory(data) {
  return {
    type: actionTypes.SAVE_FUND_HISTORY,
    data,
  };
}

export function saveLockFundHistory(data) {
  return {
    type: actionTypes.SAVE_LOCK_FUND_HISTORY,
    data,
  };
}

export function openCloseModal(payload) {
  return {
    type: actionTypes.OPEN_CLOSE_SECURITY,
    data: payload,
  };
}
export function openCloseSendClodModal(payload) {
  return {
    type: actionTypes.OPEN_CLOSE_COLD_MODAL,
    data: payload,
  };
}
export function saveFundsFileDetails(data) {
  return {
    type: actionTypes.SAVE_FUND_FILE_DETAILS,
    data,
  };
}
export function saveSampleFileList(data) {
  return {
    type: actionTypes.SAVE_SAMPLE_FILE_LIST,
    data,
  };
}

//get fund list
export function getUserFundListView(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();
    return FundService.getUserFundListView(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveViewFundDetails(data.data.data));
        // dispatch(totalFundRecords(data.data.meta.total));

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getUserFundList(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();
    return FundService.getFundList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveFundDetails(data.data.data));
        dispatch(totalFundRecords(data.data.meta.total));

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getUserFund(params) {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(saveWalletId([]));
    return FundService.getUserFunds(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveWalletId(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function setUserFund(newData, fdata) {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(startLoading());

    return FundService.setUserFunds(newData, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(getUserFundListView({ user_id: newData.user_id }));
        dispatch(stopLoading());

        dispatch(reset("addFundsForm"));
        dispatch(getUserFundList(fdata));

        dispatch(saveWalletId({}));

        toast.success(data.data.message);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function lockUserFunds(newData, fdata) {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(startLoading());

    return FundService.lockUserFunds(newData, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (response) => {
        toast.success(response.data.message);
        dispatch(getUserFundListView({ user_id: newData.userId }));
        dispatch(stopLoading());
        dispatch(getUserFundList(fdata));
      })
      .catch((error) => {
        if (error) {
          toast.error(error.data.message);
        }
        dispatch(stopLoading());
      });
  };
}

export function unLockUserFundsVesting(data, fdata) {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(startLoading());

    return FundService.unLockUserFundsVesting(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (response) => {
        toast.success(response.data.message);
        dispatch(stopLoading());
        // dispatch(getUserFundList(fdata));
        dispatch(getLockFundHistory(fdata));
      })
      .catch((error) => {
        if (error) {
          toast.error(error.data.message);
        }
        dispatch(stopLoading());
      });
  };
}

export function unLockUserFunds(data, fdata) {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(startLoading());

    return FundService.unLockUserFunds(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (response) => {
        toast.success(response.data.message);
        dispatch(stopLoading());
        // dispatch(getUserFundList(fdata));
        dispatch(getLockFundHistory(fdata));
      })
      .catch((error) => {
        if (error) {
          toast.error(error.data.message);
        }
        dispatch(stopLoading());
      });
  };
}

export function getFundUsers(params) {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(saveWalletId([]));
    return FundService.getFundUser(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveAllFundUsers(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getLockFundList(params) {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(saveWalletId([]));
    return FundService.getLockFundList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveLockFundList(data.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function enableDisabelLock(data, id, param) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return FundService.enableDisableLock(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getLockFundList(param));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function addNewPlan(data, param) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return FundService.addNewPlan(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getLockFundList(param));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function connectWallet(params) {
  // const { activate, account, deactivate } = useWeb3React();
  return (dispatch, getState) => {
    // dispatch(startLoading());

    let state = getState();
    // activate(injected, undefined, true).catch((error) => {
    //   if (error) {
    //     activate(injected);
    //   }
    //   console.log("errorIN ConnectWallet", error);
    // });
    // return FundService.getHotWalletBalance(params, {
    //   jwt: state["persist"]["c_temp_user"]["token"],
    // })
    //   .then(async (data) => {
    //     dispatch(saveHotWalletBalance(data.data));
    //     dispatch(stopLoading());
    //   })
    //   .catch((error) => {
    //     if (error) {
    //       toast.error(error["data"]["message"]);
    //     }
    //     dispatch(stopLoading());
    //   });
  };
}
export function getHotWalletBalance(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return FundService.getHotWalletBalance(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveHotWalletBalance(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getAdminHotWalletBalance(data, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return FundService.getAdminHotWalletBalance(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(getHotWalletBalance(data2));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getLockFundHistory(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return FundService.lockFundHistory(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveLockFundHistory(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getUserBalance(params) {
  return (dispatch, getState) => {
    let state = getState();
    return FundService.getUserBalance(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveUserBalance(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getUniqueUserBalance(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return FundService.getUniqueUserBalance(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveUniqueUserBalance(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function sendToColdWallets(coin, data) {
  return (dispatch, getState) => {
    let state = getState();
    return FundService.sendToColdWallets(coin, data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        toast.success(data.data.message);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getColdWalletHistory(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();
    return FundService.getColdWalletHistory(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveColdWalletHistory(data.data.data, data.data.meta.total));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
//get fund history

export function getFundHistory(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();
    return FundService.getFundHistory(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveFundHistory(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function verifyGoogleAuthRequestSecurity(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    FundService.verifyGoogleAuth(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then((success) => {
        dispatch(stopLoading());
        dispatch(reset("securityColdForm"));
        dispatch(openCloseModal(false));
        dispatch(openCloseSendClodModal(true));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
// add FundsFile
export function addFundsFile(data, params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();

    return FundService.addFundsFile(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(reset("FundManagement")); // requires form
        toast.success(res.data.message);

        dispatch(getFundsFileList(params));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// Get Funds File List
export function getFundsFileList(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();
    return FundService.getFundsFileList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveFundsFileDetails(data.data.data.rows));
        dispatch(totalFundRecords(data.data.data.count));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// Get Sample File List
export function getSampleFileList() {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return FundService.getSampleFileList({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveSampleFileList(data.data.data.rows));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// Process File
export function processFile(params, otherParams) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();
    return FundService.processFile(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data.data.message);
        dispatch(getFundsFileList(otherParams));
        // dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// Process File
export function deleteFile(params, otherParams) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();

    return FundService.deleteFile(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data.data.message);
        dispatch(getFundsFileList(otherParams));
        // dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// Download sample File
export function downloadSampleFileApi(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();

    return FundService.downloadSampleFile(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        // window.location.href = `${data.data.data}`;
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
