import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const getAnnouncementList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/announcement/list`,
    {},
    options,
    params
  );
};

const getAnnouncementDetail = (id, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/announcement/detail/${id.user_id.user_id}`,
    {},
    options
  );
};
const addAnnouncement = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/announcement/add`,
    data,
    options
  );
};
const deleteAnnouncement = (id, options) => {
  return fetch(
    "delete",
    `${API_HOST}api/v1/admin/announcement/delete/${id}`,
    {},
    options
  );
};
const updateAnnouncement = (data, id, options) => {
  console.log("put", data);
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/announcement/update/${id}`,
    data,
    options
  );
};

const sendAnnouncement = (data, id, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/announcement/sendNotification/${id}`,
    data,
    options
  );
};

export const AnnouncementService = {
  getAnnouncementList,
  getAnnouncementDetail,
  addAnnouncement,
  deleteAnnouncement,
  updateAnnouncement,
  sendAnnouncement
};
