import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

export class CustomHeader extends Component {
  //   static propTypes = {
  //     prop: PropTypes
  //   };

  render() {
    return (
      <div>
        <div className="header bg-gradient-info pb-4 pt-5 ">
          <Container fluid>
            <div className="header-body"></div>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomHeader);
