import { actionTypes } from "../actons/inrMangement.action";

const initialState = {
  fiatHistoryList: [],
  queryListRecords: {},
  fiatStatus: [],
  userFiatList: [],
  balance: 0,
};

const fiatHistory = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIAT_HISTORY_LIST:
      return {
        ...state,
        fiatHistoryList: action.data.data.history,
        total: action.data.data.total,
        queryListRecords: action.data.data,
      };
    case actionTypes.DEPOSIT_SETTINGS:
      return {
        ...state,
        depositSettings: action.data.data,
        // fiatHistoryList: action.data.data.history,
        // total: action.data.data.total,
        // queryListRecords: action.data.data,
      };
    case actionTypes.FIAT_STATUS:
      return {
        ...state,
        fiatStatus: action.data.data,
      };
    case actionTypes.USER_FIAT_LIST:
      return {
        ...state,
        userFiatList: action.data.data,
      };
    case actionTypes.VIRTUAL_BALANCE:
      return {
        ...state,
        balance: action.data.data.balance,
      };

    default:
      return state;
  }
};

export default fiatHistory;
