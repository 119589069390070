import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { getUserBalance } from "../../../Redux/actons/fund.action";
import { API_HOST } from "../../../constants/index";
import { Link } from "react-router-dom";
import SendToColdWallet from "./SendToColdWallet";
import { reset } from "redux-form";
import Security from "../../SubAdminManagement/AddNewSubAdmin/Security";
import {
  BTC_ADDRESS_BTC,
  ETH_ADDRESS_ETH,
  BTCV_ADDRESS_BTCV,
  BSC_ADDRESS_BSC,
  MATIC_ADDRESS_MATIC,
} from "../../../constants/index";
import Header from "components/Headers/Header";
import { Pagination } from "semantic-ui-react";

export class CoinWiseBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      coin_family: "",
      coinId: "",
      totalAmount: "",
    };
  }
  componentDidMount = async () => {
    if (this.props.location.state) {
      await this.getBalance(this.props.location.state.coin_id);
    } else {
      this.props.history.goBack();
    }
  };

  getBalance = (id) => {
    this.setState({ coinId: id });
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      coin_id: id,
    };
    this.props.getUserBalance(data);
  };

  //page change
  changeApprovedPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getBalance(this.state.coinId);
    });
  };
  //search
  onSearch = (abc) => {
    this.setState({ searchKey: abc, page: 1 }, () => {
      this.getBalance(this.state.coinId);
    });
  };
  showUserBalance = (data) => {
    let pushParams = {
      coinId: data.coin_id,
      userId: data.user_id,
    };
    this.props.history.push({
      pathname: `user-wise-balance`,
      state: pushParams,
    });
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="coin wise balance" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="hot-wallet-funds">Hot Wallet Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Coin Wise Balance</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search List"
                        style={{
                          color: "black",
                        }}
                        onChange={(e) => this.onSearch(`${e.target.value}`)}
                      ></Input>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Email id</th>
                        <th scope="col">Balance</th>
                        <th scope="col"> View User Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.userBalanceList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.userBalanceList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.email}</td>
                            <td>{data.balance}</td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color="success"
                                  onClick={() => this.showUserBalance(data)}
                                >
                                  View
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        onPageChange={(e, data) =>
                          this.changeApprovedPage(e, data)
                        }
                        boundaryRange={1}
                        defaultActivePage={1}
                        ellipsisItem={true}
                        firstItem={true}
                        lastItem={true}
                        siblingRange={1}
                        totalPages={Math.ceil(
                          this.props.totalRecords / this.state.perPage
                        )}
                      />
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    userBalanceList: state.funds.userBalance.history,
    totalRecords: state.funds.userBalance.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserBalance: (data) => dispatch(getUserBalance(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CoinWiseBalance)
);
