import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, Table, Container, Row, Button } from "reactstrap";
import CustomHeader from "../../../components/Headers/CustomHeader";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router";
import { getCoinList } from "../../../Redux/actons/user.action";

import { Link } from "react-router-dom";

export class TransactionCoin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.getCoinList();
  };

  render() {
    return (
      <div>
        <CustomHeader />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Transaction Coin List</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Link to="Transaction-fee">
                        <Button color="success btnMamagePermission">
                          Transaction Fee Setting
                        </Button>
                      </Link>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Coin Name</th>
                        <th scope="col">Transaction Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.coinsList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          <td>{usersList.coin_name}</td>
                          <td>{usersList.transaction_fee}</td>
                        </tr>
                      ))}
                      {this.props.coinsList.length == 0 && <td>No record</td>}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log("@@@@@@>>>>>>>ritu.", state);
  return {
    coinsList: state.user.coinsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCoinList: () => dispatch(getCoinList()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "TransactionCoin" })(TransactionCoin))
);
