import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  CardHeader,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { getRewardHistory } from "../../Redux/actons/reward.action";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Dropdown, Pagination } from "semantic-ui-react";
import { listDateFormat } from "../../utils/utils";
import { Link } from "react-router-dom";
const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

const reward_types = [
  { key: "a", text: "All", value: "" },
  { key: "m", text: "Deposit fiat", value: "deposit_fiat" },
  { key: "k", text: "Sign up", value: "sign_up" },
  { key: "l", text: "First Trade", value: "first_trade" },
];

export class RewardHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      order: "asc",
      reward_types: "",
    };
  }
  componentDidMount = () => {
    this.getHistoryList();
  };

  getHistoryList = () => {
    let data = {
      search: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      reward_types: this.state.reward_types,
    };
    this.props.getRewardHistory(data);
  };

  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getHistoryList();
    });
  };

  sortByType = (e, data) => {
    this.setState({ reward_types: data.value }, () => {
      this.getHistoryList();
    });
  };

  onSearchList = (search) => {
    //this.setState({ searchKey: search }, () => {
    this.getHistoryList();
    //});
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getHistoryList();
    });
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Reward History" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="rewardManagement">Reward Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Reward History</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search by email"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />

                      <Dropdown
                        className="selectUserStyle"
                        options={reward_types}
                        selection
                        value={this.state.reward_types}
                        onChange={(e, data) => this.sortByType(e, data)}
                      />
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Coin</th>
                        <th scope=" col">Plan Type</th>
                        <th scope="col">Rewarded User</th>
                        <th scope="col">Reward amount</th>
                        <th scope="col">Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.historyList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.historyList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.coin_symbol.toUpperCase()}</td>
                            <td>{data.type}</td>
                            <td>{data.email}</td>
                            <td>{data.reward_amount}</td>
                            <td>{listDateFormat(data.created_at)}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    historyList: state?.rewardList?.rewardHistoryList?.history,
    total: state?.rewardList?.rewardHistoryList?.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRewardHistory: (data) => dispatch(getRewardHistory(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RewardHistory)
);
