import React, { useMemo } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
import { Checkbox } from "semantic-ui-react";
import { Pagination, Dropdown } from "semantic-ui-react";
import axios from "axios";
import { toast } from "../../../components/Toast/Toast";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { API_HOST } from "../../../constants/index";
import TableBodyComponent from "./TableBodyComponent";
// import { AlgoTransfer } from "./AlgoTransfer/index";
import SolTransfer from "./SolTransfer/index";
import MetaMaskConnection from "components/WalletConnection/MetaMaskConnection";
import TronConnection from "components/WalletConnection/TronConnection";
import { bnbMultisender } from "assets/ABI/ContractAddress";
import multiSenderABI from "assets/ABI/multiSenderABI.json";
import IERC20ABI from "assets/ABI/IERC20ABI.json";
import { getContract, toWeiDecimals } from "assets/ABI/utils";
import { trxMultisender } from "assets/ABI/ContractAddress";
import { ethMultisender } from "assets/ABI/ContractAddress";
// import PhantomWallet from "../../../components/WalletConnection/PhantomWallet";
// import AlgorandWallet from "../../../components/WalletConnection/AlgorandWallet";
import { ethers } from "ethers";

const Coinfilter = [{ key: "p", text: "None", value: "none" }];
const MultisenderTables = ({ statusType }) => {
  const { activate, account, deactivate, chainId, library } = useWeb3React();
  const [withdrawList, setwithdrawList] = React.useState([]);
  const [pageListMeta, setpageListMeta] = React.useState({});
  const [coin_family, setcoin_family] = React.useState("");
  const web3 = (window.web3 = new Web3(window.ethereum));
  const [metamasks, setmetamasks] = React.useState(false);
  const [Loader, setLoader] = React.useState(false);
  const [balance, setbalance] = React.useState("");
  const [updateButtonName, setupdateButtonName] = React.useState("");
  const [ErrorAmount, setErrorAmount] = React.useState("");
  const [coin_type, setcoin_type] = React.useState("");
  const [walletIndex, setWalletIndex] = React.useState("");
  //   const [arrayWallet, setarrayWallet] = React.useState("");
  const [walleterror, setWalleterror] = React.useState("");
  const [TokenError, setTokenError] = React.useState("");
  const [arrayAmount, setarrayAmount] = React.useState([]);
  const [tron, settron] = React.useState(false);
  const [solana, setsolana] = React.useState(false);
  const [algo, setalgo] = React.useState(false);
  const [totalRecords, settotalRecords] = React.useState(0);
  const [page, setpage] = React.useState(100);
  let token = window.localStorage.getItem("tokens");
  const [lists, setlists] = React.useState([]);
  const filterCoinBy = (e, data) => {
    // console.log(e, "data ----- Coinfilter", data);
    setcoin_type(data.value);
  };
  React.useEffect(() => {
    CoinLists();
  }, [token]);
  const CoinLists = async () => {
    // const pageData = page != undefined ? page + 1 : "";
    // const keywordData = keyword != undefined ? keyword : "";
    // let state = getState();
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    // console.log("tokens", token);
    try {
      //   dispatch(fetchStart());
      await axios({
        method: "GET",
        url: `${API_HOST}api/v1/admin/wallets/coinList?operateBy=1&page=1&perPage=35`,
        headers: {
          Authorization: token,
        },
        params: {},
      }).then(({ data }) => {
        // console.log("data -----", data);
        if (data.data) {
          data.data.forEach((element) => {
            // console.log("data ----- Coinfilter element", element);

            if (element.is_token == 1) {
              if (element.coin_family == 1) {
                element.text = element.coin_symbol.toUpperCase() + "(ERC-20)";
              } else if (element.coin_family == 3) {
                element.text = element.coin_symbol.toUpperCase() + "(BEP-20)";
              } else if (element.coin_family == 4) {
                element.text = element.coin_symbol.toUpperCase() + "(TRC-20)";
              } else if (element.coin_family == 5) {
                element.text = element.coin_symbol.toUpperCase() + "(MATIC)";
              } else {
                element.text = element.coin_symbol.toUpperCase();
              }
            } else {
              element.text = element.coin_symbol.toUpperCase();
            }

            let obj = {
              ...element,
              // text: `${element.coin_symbol.toUpperCase()} ({})`,
              value: element.coin_id,
            };
            Coinfilter.push(obj);
          });
          // console.log("data ----- Coinfilter", Coinfilter);
          // setwithdrawList(data.data);
          // setpageListMeta(data.meta);
        } else {
        }
      });
    } catch (error) {}
    // };
  };
  const TaralICODetails = async ({ token, page, perPage, keyword, order }) => {
    const pageData = "100";
    // const pageData = page != undefined ? page + 1 : "";
    const keywordData = keyword != undefined ? keyword : "";
    // let state = getState();
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      //   dispatch(fetchStart());
      const res = await axios({
        method: "POST",
        url: `${API_HOST}api/v1/admin/withdraw/list?searchKey=&perPage=${pageData}&page=1&order=desc&coin_type=${coin_type}&coin_family=${
          coin_family ? coin_family : ""
        }`,
        headers: {
          Authorization: token,
        },
        // params: {
        //   //   keyword,
        //   //   perPage,
        //   // page: pageData,
        // },
        data: {
          status: "pending",
          coin_type: [coin_type == "none" ? "" : coin_type],
        },
      }).then(({ data }) => {
        // console.log("data -----", data);
        if (data.data) {
          setwithdrawList(data.data);
          setpageListMeta(data.meta);
        } else {
        }
      });
    } catch (error) {}
    // };
  };
  React.useEffect(() => {
    if (coin_family != "") {
      TaralICODetails({
        token: token,
        page: page,
        //   perPage: rowsPerPage,
        //   keyword: searchText,
        //   order,
      });
    }
  }, [page, coin_family, coin_type]);

  const MetamaskConnection = () => {
    setmetamasks(!metamasks);
    setcoin_family("");
    if (tron) {
      settron(false);
    }
    if (solana) {
      setsolana(false);
    }
    if (algo) {
      setalgo(false);
    }
  };
  const TronWalletConnection = () => {
    setcoin_family(4);
    if (metamasks) {
      setmetamasks(false);
    }
    if (solana) {
      setsolana(false);
    }
    if (algo) {
      setalgo(false);
    }
    settron(!tron);
  };
  const SolanaWalletConnection = () => {
    setcoin_family(4);
    if (metamasks) {
      setmetamasks(false);
    }
    if (tron) {
      settron(false);
    }
    if (algo) {
      setalgo(false);
    }
    setsolana(!solana);
  };
  const AlgoWalletConnection = () => {
    setcoin_family(4);
    if (metamasks) {
      setmetamasks(false);
    }
    if (tron) {
      settron(false);
    }
    if (solana) {
      setsolana(false);
    }
    setalgo(!algo);
  };

  /* *************************************** ETHER TRANSFER *************************************** */

  const EtherTransfer = async () => {
    // console.log(" token_address ------->>>> EtherTransfer", lists[0]);
    if (lists[0].token_address == "") {
      // console.log(" token_address ------->>>> EtherTransfer 1");
      HandleEtherTransfer(lists[0].coin_family);
    } else {
      console.log(" token_address ------->>>> EtherTransfer 2");
      HandleCoinTransfer(lists[0].token_address, lists[0].coin_family);
    }
  };
  const HandleEtherTransfer = async (coin_family) => {
    setTokenError(true);
    // let arrayAmount = []
    let arrayWallet = [];
    // const valueList = arrayAmount.map((people) =>
    // parseFloat()
    // );
    try {
      const balance = await web3.eth.getBalance(account);
      const balanceImETH = await web3.utils.fromWei(balance);
      setLoader(true);
      web3.eth.setProvider(Web3.givenProvider);
      setupdateButtonName("Sending BNB");
      const valueList = lists.map((people) =>
        parseFloat(web3.utils.toWei(people.amount.toString()))
      );
      const arrayAmount = lists.map((people) =>
        web3.utils.toWei(
          parseFloat(Number(people.amount)).toFixed(18).toString()
        )
      );
      arrayWallet = lists.map((people) => people.to_adrs.toString());
      let sum = 0;
      for (let i = 0; i < valueList.length; i++) {
        sum += valueList[i];
      }

      if (sum > parseFloat(web3.utils.toWei(balance.toString()))) {
        setErrorAmount(true);
        setLoader(false);
        toast.error(["Insufficient funds."]);
        setupdateButtonName("Send");
        return;
      }
      // console.log("location,");
      //  let token_address
      let contractAddress;
      if (coin_family === 3) {
        // BNB
        contractAddress = bnbMultisender;
      }
      if (coin_family === 1) {
        // ETH
        contractAddress = ethMultisender;
      }
      const contract = getContract(
        contractAddress,
        multiSenderABI,
        library,
        account
      );

      // console.log("location,");
      let poolMoreData = {};
      for (let i = 0; i < arrayWallet.length; i++) {
        poolMoreData[i] = await web3.utils.isAddress(arrayWallet[i]);
      }
      // console.log("location,");
      setWalletIndex(poolMoreData);
      for (let j = 0; j < arrayWallet.length; j++) {
        poolMoreData[j] = await web3.utils.isAddress(arrayWallet[j]);
        if (!poolMoreData[j]) {
          setWalleterror(true);
          setLoader(false);
          return;
        }
      }

      // *********************** using the promise
      if (lists.length == 1) {
        const PriceInBNB = web3.utils.toWei(
          Number(lists[0].amount).toFixed(18).toString()
        );
        console.log("PriceInBNB", PriceInBNB);

        const transfer = await web3.eth.sendTransaction(
          {
            from: account,
            to: lists[0].to_adrs,
            value: PriceInBNB,
          },
          function async(err, transactionHash) {
            if (err) {
              console.log("Payment failed Error", err);
              return;
            } else {
            }
          }
        );
        // console.log("transfer ----->>>", transfer);
        const mapsData = lists.map((people) => ({
          id: people.id,
          status: "Approved",
          coin_symbol: people.coin_symbol,
          tx_id: transfer?.transactionHash,
          gasLimit: transfer?.gasUsed?.toString(),
          gasPrice: transfer?.effectiveGasPrice?.toString(),
        }));
        ApiHandler({ mapsData, lists });
        setupdateButtonName("Send");
        toast.success("Fund transfer successfully");
        setlists([]);
        return;
      } else {
        // console.log(contract, sum, "location, -----poolMoreData", poolMoreData);
        setupdateButtonName("Distribute BNB");
        console.log(arrayAmount, "lists ----- ", sum);
        const distributeETH = await contract.distributeETH(
          arrayWallet,
          arrayAmount,
          { value: sum.toString() }
        );
        await distributeETH.wait();

        console.log("lists ----- ", distributeETH);
        // console.log("lists ----- wait", distributeETH.wait);
        // console.log("lists ----- wait()", distributeETH.wait());

        const mapsData = lists.map((people) => ({
          id: people.id,
          status: "Approved",
          coin_symbol: people.coin_symbol,
          tx_id: people.id.toString(),
          gasLimit: people.id.toString(),
          gasPrice: people.id.toString(),
        }));
        ApiHandler({ mapsData, lists });
        setupdateButtonName("Send");
        toast.success("Fund transfer successfully");
        setlists([]);
        setLoader(false);
      }
      setLoader(false);
    } catch (err) {
      setupdateButtonName("Send");
      toast.error([err.message]);
      setLoader(false);
      console.log(err);
      console.log(err.message);
    }
  };
  /* *************************************** ETHER TRANSFER *************************************** */

  const HandleCoinTransfer = async (token_address, coin_family) => {
    // console.log(" token_address ------->>>> HandleCoinTransfer", token_address);
    setTokenError(true);
    let arrayWallet = [];
    try {
      const balance = await web3.eth.getBalance(account);
      const balanceImETH = await web3.utils.fromWei(balance);

      setLoader(true);
      web3.eth.setProvider(Web3.givenProvider);
      setupdateButtonName("Sending BNB");

      let contractAddress;
      //  let token_address
      if (coin_family === 3) {
        // BNB
        contractAddress = bnbMultisender;
        token_address = "0x7B5eD21BC595e23c2cb6459a4E7CCCD45c8eE33F"; // BNB - For test net only
      }
      if (coin_family === 1) {
        // ETH
        contractAddress = ethMultisender;
        token_address = "0xE26115df366916cC8EA0965Ec33A042Ec7e875db"; // ETH - For test net only
      }

      console.log("location,---- contractAddress", contractAddress);
      const contract = getContract(
        contractAddress,
        multiSenderABI,
        library,
        account
      );

      console.log("location,---- contract", contract);
      const contractDeposit = getContract(
        token_address,
        IERC20ABI,
        library,
        account
      );
      let decimals = await contractDeposit.decimals();
      console.log("location,---- contractDeposit", decimals);

      const valueList = lists.map((people) =>
        parseFloat(ethers.utils.parseUnits(people.amount.toString(), decimals))
      );
      const arrayAmount = lists.map((people) =>
        ethers.utils.parseUnits(
          parseFloat(Number(people.amount)).toString(),
          decimals
        )
      );
      // const arrayAmount = lists.map((people) =>
      //   toWeiDecimals(parseFloat(Number(people.amount)).toString(), decimals)
      // );
      arrayWallet = lists.map((people) => people.to_adrs.toString());
      let sum = 0;
      for (let i = 0; i < valueList.length; i++) {
        sum += valueList[i];
      }
      if (sum > parseFloat(web3.utils.toWei(balance.toString()))) {
        setErrorAmount(true);
        setLoader(false);
        toast.error(["Insufficient funds."]);
        setupdateButtonName("Send");
        return;
      }

      const allowanceallowance = await contractDeposit.allowance(
        account,
        contractAddress
      );
      console.log("location,");
      let poolMoreData = {};
      for (let i = 0; i < arrayWallet.length; i++) {
        poolMoreData[i] = await web3.utils.isAddress(arrayWallet[i]);
      }
      console.log("location,");
      setWalletIndex(poolMoreData);
      for (let j = 0; j < arrayWallet.length; j++) {
        poolMoreData[j] = await web3.utils.isAddress(arrayWallet[j]);
        if (!poolMoreData[j]) {
          setWalleterror(true);
          setLoader(false);
          return;
        }
      }

      console.log("location, -----poolMoreData", lists.length);
      // onsole.log("BinencecoinUSD ------ BinencecoinUSD", BinencecoinUSD);
      // console.log(Object.values(poolMoreData));
      // *********************** using the promise
      // if (lists.length == 1) {
      //   const PriceInBNB = web3.utils.toWei(
      //     (Number(lists[0].amount) / 1000).toString()
      //   );
      // console.log("PriceInBNB", PriceInBNB);

      //   const transfer = await web3.eth.sendTransaction(
      //     {
      //       from: account,
      //       to: lists[0].to_adrs,
      //       value: PriceInBNB,
      //     },
      //     function async(err, transactionHash) {
      //       if (err) {
      // console.log("Payment failed Error", err);
      //         return;
      //       } else {
      //       }
      //     }
      //   );
      //   return;
      // }
      //
      console.log(contract, sum, "location, -----poolMoreData", poolMoreData);
      setupdateButtonName("Distribute BNB");

      if (parseFloat(allowanceallowance.toString()) === 0) {
        setupdateButtonName("Max Approving");
        console.log(account, "contractAddress", contractAddress);
        const setAllowanceallowance = await contractDeposit.approve(
          contractAddress,
          "115792089237316195423570985008687907853269984665640564039457584007913129639935"
        );
        await setAllowanceallowance.wait();

        setupdateButtonName("Distribute Token");
        const distributeETH1 = await contract.distributeToken(
          token_address,
          arrayWallet,
          arrayAmount
          // { value: sum.toString() }
        );
        await distributeETH1.wait();
        setupdateButtonName("Send");

        toast.success("Fund transfer successfully");
        const mapsData = lists.map((people) => ({
          id: people.id,
          status: "Approved",
          coin_symbol: people.coin_symbol,
          tx_id: people.id.toString(),
          gasLimit: people.id.toString(),
          gasPrice: people.id.toString(),
        }));
        ApiHandler({ mapsData, lists });
        setlists([]);
        setLoader(false);
      } else {
        // console.log(account, "contractAddress");
        setupdateButtonName("Distribute Token");
        // const distributeETH = await contract.distributeToken(
        //   tokenAddress,
        //   arrayWallet,
        //   arrayAmount
        // );
        // await distributeETH.wait();
        // console.log("lists ----- ", distributeETH);
        const distributeETH1 = await contract.distributeToken(
          token_address,
          arrayWallet,
          arrayAmount
          // { value: sum.toString() }
        );
        await distributeETH1.wait();
        const mapsData = lists.map((people) => ({
          id: people.id,
          status: "Approved",
          coin_symbol: people.coin_symbol,
          tx_id: people.id.toString(),
          gasLimit: people.id.toString(),
          gasPrice: people.id.toString(),
        }));
        ApiHandler({ mapsData, lists });
        setupdateButtonName("Send");
        toast.success("Fund transfer successfully");
        setlists([]);
        setLoader(false);
      }
      setupdateButtonName("Send");
      setLoader(false);
    } catch (err) {
      setupdateButtonName("Send");
      setLoader(false);
      console.log(err);
    }
  };
  /* *************************************** TOKEN TRANSFER *************************************** */
  /* *************************************** TOKEN TRANSFER *************************************** */
  const TRONTransfer = async () => {
    setTokenError(true);
    let arrayWalletTron = [];
    if (lists.length > 1) {
      try {
        setLoader(true);
        web3.eth.setProvider(Web3.givenProvider);
        setupdateButtonName("Sending BNB");
        const valueList = lists.map((people) =>
          parseFloat(
            ethers.utils.parseUnits(
              parseFloat(Number(people.amount)).toFixed(6).toString(),
              6
            )
          )
        );
        const arrayAmount = lists.map((people) =>
          ethers.utils.parseUnits(
            parseFloat(Number(people.amount)).toFixed(6).toString(),
            6
          )
        );
        arrayWalletTron = lists.map((people) => people.to_adrs.toString());
        let sum = 0;
        for (let i = 0; i < valueList.length; i++) {
          sum += valueList[i];
        }

        // console.log(
        //   arrayWalletTron,
        //   "arrayWalletTron ---------- sum",
        //   sum,
        //   "----",
        //   arrayAmount,
        //   "arrayAmount  ----------  valueList"
        // );

        const contract = await window.tronWeb.contract().at(trxMultisender);

        // console.log("location, -----poolMoreData", lists.length);

        // // console.log(contract, "location, -----poolMoreData");
        setupdateButtonName("Distribute Tron");
        const distributeETH = await contract
          .distributeETH(arrayWalletTron, arrayAmount)
          .send({
            feeLimit: 100,
            callValue: sum,
            shouldPollResponse: false,
          });
        // await distributeETH.wait();
        const txData = await window.tronWeb.trx.getTransaction(distributeETH);

        // console.log(txData, "location, -----poolMoreData");
        const trxHash = txData.ret[0].contractRet;
        // console.log(txData, "location, -----poolMoreData", trxHash);
        if (trxHash === "SUCCESS") {
          const mapsData = lists.map((people) => ({
            id: people.id,
            status: "Approved",
            coin_symbol: people.coin_symbol,
            tx_id: txData?.raw_data?.txID.toString(),
            gasLimit: txData?.fee_limit.toString(),
            gasPrice: "",
          }));
          setupdateButtonName("Send");
          toast.success(["Fund transfer successfully"]);
          // console.log("lists --- distributeETH -- mapsData", mapsData);
          ApiHandler({ mapsData, lists });
        } else {
          setupdateButtonName("Send");
          toast.error([trxHash]);
          // const mapsData = lists.map((people) => ({
          // id: people.id,
          // status: "Approved",
          // coin_symbol: people.coin_symbol,
          // tx_id: trxHash?.raw_data?.txID.toString(),
          // gasLimit: trxHa
          // }));
          // setupdateButtonName("Send");
          // toast.success(["Fund transfer successfully"]);
          // // console.log("lists --- distributeETH -- mapsData", mapsData);
          // ApiHandler({ mapsData, lists });
        }
        setLoader(false);
        // }
        setLoader(false);
      } catch (err) {
        toast.error([err.message]);
        setLoader(false);
        console.log(err);
      }
    } else {
      const tronWeb = window.tronWeb;
      // const contract = await tronWeb.contract().at("");
      // const contractToken = await tronWeb.contract().at("");
      try {
        // console.log(lists[0]);
        const price = parseFloat(lists[0].amount / 1000).toFixed(6);
        const ammount = price * Math.pow(10, 6);
        const account = await window.tronWeb.trx.getAccount(
          window.tronWeb.defaultAddress.base58
        );
        // let result1 = await contractToken
        //   .approve("TQqijFSzWbNtzkRyeEavqAb6AgcNiNr5Ed", ammount.toString())
        //   .send();
        // console.log(ammount, account.address);
        const txHas = await tronWeb.trx.sendTransaction(
          lists[0].to_adrs,
          // "TDqh6usD1b7HjWCD92FAXvJYjdjJV8XUhc",
          ammount.toString()
        );
        // const txHas = await tronWeb.transactionBuilder.sendTrx(
        //   // lists[0].to_adrs,
        //   "TDqh6usD1b7HjWCD92FAXvJYjdjJV8XUhc",
        //   ammount.toString(),
        //   account.address
        // );
        // await txHas.wait();
        // console.log(txHas);
        // const txHas = await contract
        //   .depositToken("", lists[1].to_adrs, ammount.toString())
        //   .send();
        // settrxdata(txHas);
        const txData = await tronWeb.trx.getTransaction(txHas.txid);
        // console.log(txData);
        const trxHash = txData.ret[0].contractRet;
        if (trxHash === "SUCCESS") {
          const mapsData = lists.map((people) => ({
            id: people.id,
            status: "Approved",
            coin_symbol: people.coin_symbol,
            tx_id: txHas.txid ? txHas.txid.toString() : "0",
            gasLimit: txData?.fee_limit ? txData?.fee_limit.toString() : "0",
            gasPrice: "0",
          }));
          setupdateButtonName("Send");
          toast.success(["Fund transfer successfully"]);

          ApiHandler({ mapsData, lists });
        } else {
          toast.error(["Transaction hash not found"]);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  /* *************************************** TOKEN TRANSFER *************************************** */
  /* *************************************** TOKEN TRANSFER *************************************** */
  /* *************************************** Api Handler *************************************** */

  const ApiHandler = async ({ mapsData, lists }) => {
    // let state = getState();
    // console.log("mapsData ---", mapsData);
    setlists([]);
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      //   dispatch(fetchStart());
      const res = await axios({
        method: "POST",
        url: `${API_HOST}api/v1/admin/withdraw/withdrawForMetaMask`,
        headers: {
          Authorization: token,
        },
        data: {
          withdrawData: mapsData,
        },
        // data: {
        //   status: "pending",
        //   coin_type: [coin_type == "none" ? "" : coin_type],
        // },
      }).then(({ data }) => {
        // console.log("data -----", data);
        TaralICODetails({
          token: token,
          page: page,
          //   perPage: rowsPerPage,
          //   keyword: searchText,
          //   order,
        });
        if (data.data) {
          // setwithdrawList(data.data);
          // setpageListMeta(data.meta);
        } else {
        }
      });
    } catch (error) {}
    // };
  };
  /* *************************************** Api Handler *************************************** */

  return (
    <div>
      <div>
        <Container className="contentBlock" fluid>
          {/* Table */}

          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  {/* <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Withdraw List</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchWithdraw();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchWithdraw()}
                      >
                        Search
                      </Button>

                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={filter}
                        placeholder="Filter by Status"
                        selection
                        onChange={(e, data) => this.filterBy(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={Coinfilter}
                        placeholder="Filter by Coin"
                        selection
                        onChange={(e, data) => this.filterCoinBy(e, data)}
                      />
                     
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.downLoadUserList()}
                      >
                        Download Withdraw list
                      </Button>
                    </div> */}
                  <div className=" custom-control-alternative custom-checkbox">
                    <div className="selectSearchOption">
                      <div>
                        {" "}
                        <Checkbox
                          label="Metamask Wallet"
                          id=" customCheckLogin"
                          checked={metamasks}
                          onChange={(e, data) => MetamaskConnection()}
                        />
                      </div>
                      <div
                        style={{ marginLeft: "15px" }}
                        className=" custom-control-alternative custom-checkbox"
                      >
                        <Checkbox
                          label="Tron Wallet"
                          id=" customCheckLogin1"
                          checked={tron}
                          onChange={(e, data) => TronWalletConnection()}
                        />
                      </div>
                      {/* <div
                        style={{ marginLeft: "15px" }}
                        className=" custom-control-alternative custom-checkbox"
                      >
                        <Checkbox
                          label="Solana Wallet"
                          id=" SolanaWalletConnection"
                          checked={solana}
                          onChange={(e, data) => SolanaWalletConnection()}
                        />
                      </div> */}
                      {/* <div
                        style={{ marginLeft: "15px" }}
                        className=" custom-control-alternative custom-checkbox"
                      >
                        <Checkbox
                          label="Algorand Wallet"
                          id=" AlgoWalletConnection"
                          checked={algo}
                          onChange={(e, data) => AlgoWalletConnection()}
                        />
                      </div> */}
                    </div>
                    <div className="selectSearchOption">
                      {metamasks && (
                        <MetaMaskConnection
                          coin_family={coin_family}
                          setcoin_family={setcoin_family}
                        />
                      )}
                      {tron && <TronConnection />}
                      {/* {solana && <PhantomWallet setpubkey={setpubkey} />} */}
                      {/* {algo && <AlgorandWallet />} */}
                      {/* <ConnectSolana /> */}
                      {/* {algo && <AlgoTransfer />} */}
                      {solana && <SolTransfer />}
                      <div style={{ paddingTop: "15px" }}>
                        {metamasks && (
                          <Button
                            color="success"
                            disabled={lists?.length == 0}
                            onClick={EtherTransfer}
                          >
                            Send
                          </Button>
                        )}
                        {tron && (
                          <Button
                            color="success"
                            disabled={lists?.length == 0}
                            onClick={TRONTransfer}
                          >
                            Send Tron
                          </Button>
                        )}
                      </div>
                    </div>{" "}
                    <div style={{ paddingTop: "15px" }}>
                      <Dropdown
                        className="selectUserStyle"
                        options={Coinfilter}
                        placeholder="Filter by Coin"
                        selection
                        onChange={(e, data) => filterCoinBy(e, data)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">
                        Select
                        {/* <Checkbox
                          label="All"
                          // id=" customCheckLogin"
                          //   checked={metamasks}
                          //   onChange={(e, data) => MetamaskConnection()}
                        /> */}
                      </th>
                      <th scope="col">ID(Email)</th>
                      <th scope="col">Asset</th>

                      <th scope="col">Amount</th>
                      <th scope="col">To</th>
                      <th scope="col">Status</th>
                      <th scope="col">TXID</th>
                      <th scope="col">Tx Time</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <TableBodyComponent
                    withdrawList={withdrawList}
                    setlists={setlists}
                    lists={lists}
                  />
                  {/* <tbody>
                    {withdrawList.map((usersList, index) => (
                      <tr className="table-tr" key={index}>
                        <td>
                          {" "}
                          <Checkbox
                          // label="Metamask Wallet"
                          // id=" customCheckLogin"
                          //   checked={metamasks}
                          //   onChange={(e, data) => MetamaskConnection()}
                          />
                        </td>
                        <td>
                          <Link
                          // onClick={() => this.detailModalToggle(usersList)}
                          >
                            {usersList.email ? usersList.email : "None"}
                          </Link>
                        </td>
                        <td>{usersList.coin_symbol.toUpperCase()}</td>

                        <td>{usersList.amount}</td>
                        <td
                          title={usersList.to_adrs}
                          onClick={() =>
                            this.redirectUrl(
                              usersList.coin_symbol,
                              usersList.to_adrs
                            )
                          }
                        >
                          {usersList.to_adrs
                            ? usersList.to_adrs.slice(0, 12) + "..."
                            : "NONE"}
                        </td>
                        <td>{usersList.status.toUpperCase()}</td>
                        <td
                          title={usersList.tx_id}
                          onClick={() =>
                            this.redirectToHashSite(
                              usersList.coin_symbol,
                              usersList.tx_id
                            )
                          }
                        >
                          {usersList.tx_id
                            ? usersList.tx_id.slice(0, 12) + "..."
                            : "NONE"}
                        </td>

                        <td>{listDateFormat(usersList.updated_at)}</td>

                        <td>
                          {statusType == "MultiSender" &&
                            usersList.status != "complete" &&
                            usersList.status != "rejected" && (
                              <span className="mb-0 text-sm">
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.openConfirmApproveModal(
                                      1,
                                      usersList.id,
                                      usersList.coin_symbol,
                                      usersList.coin_family,
                                      usersList.token_address != ""
                                        ? usersList.token_address
                                        : usersList.coin_symbol
                                    )
                                  }
                                >
                                  Approve
                                </Button>
                              </span>
                            )}
                          {statusType == "MultiSender" &&
                            usersList.status != "complete" &&
                            usersList.status != "rejected" && (
                              <span
                                className="mb-0 text-sm"
                                style={{ marginLeft: "10px" }}
                              >
                                <Button
                                  color="warning"
                                  onClick={() =>
                                    this.openConfirmApproveModal(
                                      2,
                                      usersList.id,
                                      usersList.coin_symbol,
                                      usersList.coin_family,
                                      usersList.token_address != ""
                                        ? usersList.token_address
                                        : usersList.coin_symbol
                                    )
                                  }
                                >
                                  Reject
                                </Button>
                              </span>
                            )}

                          <span
                            className="mb-0 text-sm"
                            style={{ marginLeft: "10px" }}
                          >
                            <Button
                              color="success"
                              onClick={() => this.showDetails(usersList)}
                            >
                              Details
                            </Button>
                          </span>
                          <span
                            className="mb-0 text-sm"
                            style={{ marginLeft: "10px" }}
                          >
                            <Button
                              color="success"
                              onClick={() => this.openCommentModal(usersList)}
                            >
                              Add Comment
                            </Button>
                          </span>

                          <span
                            className="mb-0 text-sm"
                            style={{ marginLeft: "10px" }}
                          >
                            <Button
                              color="success"
                              onClick={() =>
                                this.downloadTradeHistory(usersList)
                              }
                            >
                              Download Excel
                            </Button>
                          </span>
                          <span
                            className="mb-0 text-sm"
                            style={{ marginLeft: "10px" }}
                          >
                            <Button
                              color="success"
                              onClick={() =>
                                this.getTotalBalance(usersList.user_id)
                              }
                            >
                              View total balance
                            </Button>
                          </span>
                        </td>
                      </tr>
                    ))}
                    {withdrawList.length == 0 && (
                      <tr>
                        <td>No record found</td>
                      </tr>
                    )}
                  </tbody> */}
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    {totalRecords > 0 && (
                      <Pagination
                        onPageChange={(e, data) =>
                          this.changeApprovedPage(e, data)
                        }
                        boundaryRange={1}
                        defaultActivePage={1}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        totalPages={Math.ceil(totalRecords)}
                      />
                    )}
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      {/* )} */}
    </div>
  );
};
export default MultisenderTables;
