import { ReferralServices } from "../../Services/ReferralServices";
import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../components/Toast/Toast";
import { reset } from "redux-form";

export const actionTypes = {
  REFERRAL_LIST: "REFERRAL_LIST",
  REFERRAL_LIST_MLM: "REFERRAL_LIST_MLM",
  REFERRAL_HISTORY: "REFERRAL_HISTORY",
  COIN_WISE_HISTORY: "COIN_WISE_HISTORY",
  REFERRAL_INCENTIVE: "REFERRAL_INCENTIVE",
  INFLUENCER_REFERAL_LIST: "INFLUENCER_REFERAL_LIST",
  INFUENCER_INCENTIVE: "INFUENCER_INCENTIVE",
  INFUENCER_HISTORY: "INFUENCER_HISTORY",
};

export function saveReferralList(data) {
  return {
    type: actionTypes.REFERRAL_LIST,
    data: data,
  };
}
export function saveReferralListMLM(data) {
  return {
    type: actionTypes.REFERRAL_LIST_MLM,
    data: data,
  };
}

export function saveRefferalIncentive(data) {
  return {
    type: actionTypes.REFERRAL_INCENTIVE,
    data: data,
  };
}

export function saveReferralHistory(data) {
  return {
    type: actionTypes.REFERRAL_HISTORY,
    data: data,
  };
}

export function saveCoinWiseHistory(data) {
  return {
    type: actionTypes.COIN_WISE_HISTORY,
    data: data,
  };
}

export function saveInfluencerReferralList(data) {
  return {
    type: actionTypes.INFLUENCER_REFERAL_LIST,
    data: data,
  };
}

export function saveInfluencerlIncentive(data) {
  return {
    type: actionTypes.INFUENCER_INCENTIVE,
    data: data,
  };
}

export function saveInfluencerlHistory(data) {
  return {
    type: actionTypes.INFUENCER_HISTORY,
    data: data,
  };
}

/*
Api call for adding the referral
@method : post
*/
export function saveReferralPair(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.addReferral(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function enableDisableReferral(data, params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.enableDisableReferral(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getReferralList(params));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getReferralList(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.getReferalList(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveReferralList(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          // toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}
export function updateReferralMLM(data, data1) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.updateReferralMLM(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(getReferralListMLM(data1));
        // dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          // toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}
export function getReferralListMLM(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.getReferalListMLM(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveReferralListMLM(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          // toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}

export function getIncentiveList(data, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.getIncentiveHistory(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveRefferalIncentive(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}

export function changeIncentiveStatus(data, params, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.changeIncentiveStatus(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getIncentiveList(params, id));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function addReferralIncentive(data, params, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.addReferralIncentive(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getIncentiveList(params, id));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getReferralHistory(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.getReferalHistory(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveReferralHistory(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getCoinWiseHistory(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.getCoinWiseHistory(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveCoinWiseHistory(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getInfluencerReferralList(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.getInfluencerReferalList(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveInfluencerReferralList(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          // toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}

export function addCoupon(data, params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.addCoupon(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(reset("AddCouponForm"));
        dispatch(getInfluencerReferralList(params));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function changeInfluencerCouponStatus(data, params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.changeInfluencerCouponStatus(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getInfluencerReferralList(params));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getInfluencerIncentiveList(data, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.getInfluencerIncentiveHistory(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveInfluencerlIncentive(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}

export function addInfluencerIncentive(data, params, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.addInfluencerIncentive(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getInfluencerIncentiveList(params, id));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function changeIncentiveAmount(data, params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.changeIncentiveAmount(data, data?.id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getInfluencerIncentiveList(params, params?.id));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function changeInfluencerIncentiveStatus(data, params) {
  console.log("----------------------data ", data, params);
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.changeInfluencerIncentiveStatus(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getInfluencerIncentiveList(params, params?.id));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getInfluencerHistory(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return ReferralServices.getInfluencerHistory(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveInfluencerlHistory(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
