import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  Row,
  CardBody,
  CardTitle,
  Col,
  Container,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import "../AccountingManagement/Accounting.css";
import {
  getTotalCoinList,
  getWithdrawRevenueFee,
  getOtcRevenueFee,
} from "../../Redux/actons/revenue.action";
import CustomHeader from "../../components/Headers/CustomHeader";
import { withRouter } from "react-router";
import { API_HOST } from "../../constants/index";
import { convertExponentialToDecimal } from "../../utils/utils";
export class ReportsManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    this.props.getTotalCoinList();
    this.props.getWithdrawRevenueFee();
    this.props.getOtcRevenueFee();
  };
  goToTRaderReport = () => {
    this.props.history.push("trading-reports");
  };
  goToDepositReport = () => {
    this.props.history.push("deposit-token");
  };

  goToWithdraw = () => {
    this.props.history.push("all_withdrawal-revenue");
  };
  goToWithdrawReport = () => {
    this.props.history.push("transaction");
  };
  downLoadbalanceList = () => {
    // this.props.downloadBalanceList();
    window.location.href = `${API_HOST}api/v1/admin/report/download/balancelist`;
  };

  render() {
    return (
      <div>
        <CustomHeader />
        <br></br>
        <div className="selectSearchOption">
          {" "}
          <Button
            color="success btnMamagePermission"
            onClick={() => this.downLoadbalanceList()}
          >
            Download Balance list
          </Button>
          {/* <Button
            color="success btnMamagePermission"
            onClick={() => this.goToTRaderReport()}
          >
            OTC Trading Fee
          </Button>{" "} */}
          {/* <Button
            color="success btnMamagePermission"
            onClick={() => this.goToDepositReport()}
          >
            Token Deposit Fee
          </Button> */}
          <Button
            color="success btnMamagePermission"
            onClick={() => this.goToWithdraw()}
          >
            Withdraw Revenue
          </Button>
          <Button
            color="success btnMamagePermission"
            onClick={() => this.goToWithdrawReport()}
          >
            Withdraw Commision
          </Button>
        </div>
        <br></br>
        <div>
          <Container fluid>
            <div
              className="header-body"
              style={{ paddingLeft: 25, paddingRight: 25 }}
            >
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Link to="deposit-token">
                        <Row >
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Deposit Fee
                            </CardTitle>

                            <span className="h3 font-weight-bold mb-0">
                              Total Deposit :{" "}
                              {
                                this.props.getTotalCoinRevenue
                                  .total_deposit_cost
                              }{" "}
                              ETH
                              <br></br>
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fas fa-users" />
                            </div>
                          </Col>{" "}
                        </Row>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Link to="transaction">
                        <Row>
                          <div className="col" >
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 "
                              style={{margin:"20px"}}
                            >
                              Withdraw Commision
                            </CardTitle>
                            <span className="h3 font-weight-bold mb-0">
                              Eth Revenue :{" "}
                              {this.props.getWithdrawTotal.ethRevenue}
                              <br></br>
                              Btc Revenue :{" "}
                              {this.props.getWithdrawTotal.btcRevenue}
                              <br></br>
                              {/* Btcv Revenue :{" "}
                              {this.props.getWithdrawTotal.btcvRevenue}{" "} */}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fas fa-users" />
                            </div>
                          </Col>{" "}
                        </Row>{" "}
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Link to="trading-reports">
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Otc Fee Earning
                            </CardTitle>
                            <span className="h3 font-weight-bold mb-0">
                              Ida Revenue : <br></br>
                              {this.props.getOtcRevenue.idaRevenue}
                              <br></br>
                              Btc Revenue :{" "}
                              {convertExponentialToDecimal(
                                parseFloat(this.props.getOtcRevenue.btcRevenue)
                              )}{" "}
                              <br></br>
                              Btcv Revenue :{" "}
                              {convertExponentialToDecimal(
                                parseFloat(this.props.getOtcRevenue.btcvRevenue)
                              )}{" "}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fas fa-users" />
                            </div>
                          </Col>{" "}
                        </Row>{" "}
                      </Link>
                    </CardBody>
                  </Card>
                </Col> */}

                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Revenue
                          </CardTitle>
                          <span className="h3 font-weight-bold mb-0">
                            Fee Earned :<br></br>
                            ETH :{" "}
                            {this.props.getTotalCoinRevenue.ethRevenue -
                              this.props.getTotalCoinRevenue
                                .total_deposit_cost}{" "}
                            {"ETH"}
                            <br></br>
                            BTC:
                            {convertExponentialToDecimal(
                              parseFloat(this.props.getOtcRevenue.btcRevenue) +
                                parseFloat(
                                  this.props.getWithdrawTotal.btcRevenue
                                )
                            )}{" "}
                            {"BTC"}
                            {/* <br></br>
                            IDA :{
                              this.props.getTotalCoinRevenue.idaRevenue
                            }{" "}
                            {"IDA"}
                            <br></br>
                            BTCV :{
                              this.props.getTotalCoinRevenue.btcvRevenue
                            }{" "}
                            {"BTCV"} */}
                            <br></br>
                            Fee Spent :{" "}
                            {
                              this.props.getTotalCoinRevenue.total_deposit_cost
                            }{" "}
                          </span>
                          <br></br>
                        </div>
                      </Row>{" "}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    getTotalCoinRevenue: state.revenue.getTotalCoinRevenue,
    getWithdrawTotal: state.revenue.getWithdrawTotal,
    getOtcRevenue: state.revenue.getOtcRevenue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTotalCoinList: (data) => dispatch(getTotalCoinList(data)),
    getOtcRevenueFee: () => dispatch(getOtcRevenueFee()),
    getWithdrawRevenueFee: (data) => dispatch(getWithdrawRevenueFee(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportsManagement)
);
