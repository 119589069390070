import React, { Component } from "react";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Tooltip,
  Table,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { Dropdown } from "semantic-ui-react";
import CustomHeader from "components/Headers/CustomHeader";
import axios from "axios";
import { API_HOST } from "../../../constants/index";
import { Field, reduxForm, change, unregisterField } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../../components/FormField";
import { required } from "redux-form-validators";
import {
  startLoading,
  stopLoading,
} from "../../../Redux/actons/loading.action";
import {
  searchToken,
  saveToken,
  getPrice,
  addTokens,
} from "../../../Redux/actons/token.action";
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";

const options = [
  { key: "e", text: "Ethereum", value: "eth" },
  { key: "b", text: "Binance", value: "bnb" },
  { key: "t", text: "Tron", value: "trx" },
  { key: "m", text: "Matic", value: "matic" },
];
export class AddToken extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contract_address: "",
      image: "",
      coin_symbol: "eth",
      tooltipOpen: false,
      gickoAlias: "",
      symbol: "",
      coin_usd_price: "",
      contract_addressError: "",
    };
  }

  componentWillUnmount = () => {
    this.props.saveToken({});
  };
  componentWillReceiveProps = (nextProps) => {
    let { ercToken } = this.props;
    console.log("props-----------------", ercToken);
    if (Object.keys(nextProps.ercToken).length !== 0) {
      if (ercToken !== nextProps.ercToken) {
        this.updateForm(nextProps);
      }
    }
  };
  //update form
  updateForm = (nextProps) => {
    let { initialize } = this.props;

    initialize({
      name: nextProps.ercToken.name,
      symbol: nextProps.ercToken.symbol,
      decimals: nextProps.ercToken.decimals,
      gicko_alias: nextProps.ercToken.gicko_alias,
    });
    this.setState({
      symbol: nextProps.ercToken.symbol,
    });
  };

  addToken = (values) => {
    //  add
    // {
    //   "name": "string",
    //   "symbol": "string",
    //   "gicko_alias": "string",
    //   "decimals": 0,
    //   "contract_address": "string"
    // }

    if (this.state.gickoAlias === "") {
      delete values["gicko_alias"];
      delete values["price"];
      values["coinFiatPriceData"] = this.props.priceList;
    } else {
      delete values["price"];
      delete values["coinFiatPriceData"];
    }
    if (this.state.coin_symbol == "eth") {
      values["coin_family"] = 1;
    } else if (this.state.coin_symbol == "bnb") {
      values["coin_family"] = 3;
    } else if (this.state.coin_symbol == "trx") {
      values["coin_family"] = 4;
    } else if (this.state.coin_symbol == "matic") {
      values["coin_family"] = 5;
    }

    values["contract_address"] = this.state.contract_address;
    values["image"] = this.state.image;

    this.props.addTokens(values, this.props.history);
  };

  onContractAddressChange = (e) => {
    this.setState({
      contract_address: e.target.value,
      contract_addressError:
        e.target.value != "" ? "" : "Please Enter  Contract Address",
    });
  };
  handleValidation = () => {
    var formValid = true;
    if (!this.state.contract_address) {
      formValid = false;
      this.setState({
        contract_addressError: "Please Enter  Contract Address",
      });
    }
    return formValid;
  };
  onSearchAddress = () => {
    if (this.handleValidation()) {
      let coinFamily = 1;
      if (this.state.coin_symbol == "eth") {
        coinFamily = 1;
      } else if (this.state.coin_symbol == "bnb") {
        coinFamily = 3;
      } else if (this.state.coin_symbol == "trx") {
        coinFamily = 4;
      } else if (this.state.coin_symbol == "matic") {
        coinFamily = 5;
      }
      let data = {
        contract_address: this.state.contract_address,
        coin_family: coinFamily,
      };

      this.props.searchToken(data);
    }
  };
  onImageUpload = async (e) => {
    if (e.target.files.length == 1) {
      const formData = new FormData();
      await formData.append("image", e.target.files[0]);
      this.props.startLoading(true);
      var self = this;
      axios
        .post(`${API_HOST}api/v1/admin/wallets/uploadimage`, formData, {
          headers: {
            Authorization: this.props.JwtToken,
          },
        })
        .then(function (response) {
          self.setState({ image: response.data.data });
          self.props.stopLoading();
        })
        .catch(function (error) {
          this.props.stoptLoading();
        });
    } else {
      this.setState({
        image: "",
      });
    }
  };
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  onAddGicko = (e) => {
    this.setState({
      gickoAlias: e.target.value,
    });
  };
  onPriceChange = (e) => {
    this.setState({
      symbol: this.state.symbol,
      coin_usd_price: e.target.value,
    });
  };
  onSubmitUsdPrice = () => {
    let data = {
      symbol: this.state.symbol,
      coin_usd_price: this.state.coin_usd_price,
    };
    this.props.getPrice(data);
  };
  render() {
    let { handleSubmit, priceList } = this.props;
    let { tooltipOpen, gickoAlias } = this.state;
    const positionStyle =
      Object.keys(this.props.ercToken).length === 0 ? "fixed" : "";
    return (
      <div>
        <Header headerTitle="Add token" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="token-management">Token Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Add Token</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div style={{ padding: 100 }}>
          <Form onSubmit={handleSubmit(this.addToken)}>
            <Label for="subject">Select Coin :</Label>
            <br />
            <Dropdown
              options={options}
              selection
              value={this.state.coin_symbol}
              onChange={(e, data) =>
                this.setState({
                  coin_symbol: data.value,
                })
              }
            />
            <br />
            <Label for="subject">Contract Address</Label>

            <Input
              placeholder="Search"
              onChange={(e) => this.onContractAddressChange(e)}
            ></Input>
            {this.state.contract_addressError &&
              this.state.contract_addressError.length && (
                <p style={{ color: "red", position: positionStyle }}>
                  {this.state.contract_addressError}
                </p>
              )}
            <Button
              onClick={() => this.onSearchAddress()}
              className="my-4"
              color="primary"
              type="button"
            >
              Go
            </Button>
            {Object.keys(this.props.ercToken).length === 0 && (
              <Link to="token-management">
                <Button color="success">Back</Button>
              </Link>
            )}

            {Object.keys(this.props.ercToken).length > 0 && (
              <div>
                <FormGroup>
                  <Label for="name">Name</Label>

                  <Field
                    name="name"
                    component={FormField}
                    type="text"
                    validate={[required()]}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="symbol">Symbol</Label>

                  <Field
                    name="symbol"
                    component={FormField}
                    type="text"
                    validate={[required()]}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="decimals">Decimal</Label>

                  <Field
                    name="decimals"
                    component={FormField}
                    type="text"
                    validate={[required()]}
                    disabled
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="decimals">Alias</Label>
                  <div style={{ display: "flex" }}>
                    <Field
                      name="gicko_alias"
                      component={FormField}
                      type="text"
                      // validate={[required()]}
                      disabled={this.props.ercToken.gicko_alias ? true : false}
                      onChange={(e) => this.onAddGicko(e)}
                      style={{ width: "100%" }}
                    />
                    <div style={{ marginLeft: "5px" }}>
                      <p>
                        {" "}
                        <Button
                          type="button"
                          style={{
                            textDecoration: "underline",
                            color: "red",
                            border: "1px solid",
                          }}
                          id="TooltipExample"
                        >
                          i
                        </Button>
                      </p>
                      <Tooltip
                        placement="right"
                        isOpen={tooltipOpen}
                        target="TooltipExample"
                        toggle={this.toggle}
                      >
                        {gickoAlias === "" ? "xxx" : gickoAlias} is the
                        coingeckoalias in "https://www.coingecko.com/en/coins/
                        {gickoAlias === "" ? "xxx" : gickoAlias}"
                      </Tooltip>
                    </div>
                  </div>
                </FormGroup>
                {this.state.gickoAlias === "" && (
                  <div>
                    <FormGroup>
                      <Label for="decimals">Price</Label>
                      <>
                        <div style={{ display: "flex", flex: 1 }}>
                          <Field
                            name="price"
                            component={FormField}
                            type="text"
                            style={{ flex: 1 }}
                            validate={[required()]}
                            onChange={(e) => this.onPriceChange(e)}
                          />
                          <Button
                            type="button"
                            id="updatePrice"
                            color="primary"
                            style={{ marginLeft: "15px" }}
                            onClick={() => this.onSubmitUsdPrice()}
                            disabled={!this.state.coin_usd_price}
                          >
                            GetPrice
                          </Button>
                        </div>
                      </>
                    </FormGroup>
                    {this.state.coin_usd_price && priceList.length !== 0 && (
                      <div>
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Coin Type</th>
                              <th scope="col">Fiat Type</th>
                              <th scope="col">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {priceList.map((data, index) => (
                              <tr className="table-tr" key={index}>
                                <td>{data.coin_type}</td>
                                <td>{data.fiat_type}</td>
                                <td>{data.value}</td>
                              </tr>
                            ))}
                            {priceList.length == 0 && <td>No record</td>}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </div>
                )}

                <FormGroup style={{ display: "grid" }}>
                  <Label for="decimals">Upload Image</Label>

                  <input type="file" onChange={this.onImageUpload} />
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="submit"
                    disabled={this.state.image === ""}
                  >
                    Add
                  </Button>
                  <Link to="token-management">
                    <Button color="success">Back</Button>
                  </Link>
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ercToken: state.token.ercToken,
    JwtToken: state.persist.c_user.token,
    priceList: state.token.priceList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchToken: (payload) => dispatch(searchToken(payload)),
    saveToken: (data) => dispatch(saveToken(data)),
    addTokens: (data, history) => dispatch(addTokens(data, history)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    getPrice: (data) => dispatch(getPrice(data)),
  };
};

export default reduxForm({ form: "AddTokenForm" })(
  connect(mapStateToProps, mapDispatchToProps)(AddToken)
);
