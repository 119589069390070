import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, Table, Container, Row, Button } from "reactstrap";

import Header from "components/Headers/Header";

import { withRouter } from "react-router-dom";
import { getContentList } from "../../Redux/actons/content.action";
import { listDateFormat, nl2br } from "../../utils/utils";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
export class ContentManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
      sortBy: "",
      getDetailsId: {},
      modal: false,
    };
  }
  toggle = () => this.setState({ open: !this.state.open });

  componentDidMount = () => {
    this.getContentList();
  };

  updateContent = (id) => {
    this.props.history.push({
      pathname: `update-content`,
      state: id,
    });
  };

  getContentList = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.getContentList(data);
  };

  // deleteBanner = (id) => {
  //   let data = {
  //     searchKey: this.state.searchKey,
  //     order: this.state.order,
  //     perPage: this.state.perPage,
  //     page: this.state.page,
  //   };

  //     const param = {
  //       announcement_id : id
  //     }
  //     this.props.deleteAnnouncement(param, data);
  // }

  // //page change
  // changePage = (e, data) => {
  //   this.setState({ page: data.activePage }, () => {
  //     this.getContentList();
  //   });
  // };

  // filterBy = (e, data) => {
  //   console.log(data.value);
  //   this.setState({ order: data.value }, () => {
  //     this.getContentList();
  //   });
  // };
  //search
  // onSearchList = (abc) => {
  //   // this.setState({ searchKey: abc, page: 1 }, () => {
  //   this.getContentList();
  //   // });
  // };
  // openModel = (data) => {
  //   // console.log("detail", data);
  //   this.setState({ getDetailsId: data, open: true });
  // };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Content Management" />
        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  {/* <CardHeader className="border-0"> */}
                  {/* <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Announcement List</h3>
                    </div> */}
                  {/* <div className="selectSearchOption"> */}
                  {/* <Input
                        placeholder="Search"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input> */}
                  {/* <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button> */}
                  {/* <Dropdown
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.filterBy(e, data)}
                      /> */}
                  {/* <Button
                        color="default btnMamagePermission"
                        onClick={() => this.addNewContent()}
                      >
                        + Add Content
                      </Button> */}
                  {/* </div> */}
                  {/* </CardHeader> */}
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Title</th>
                        {/* <th scope="col">Content</th> */}
                        <th scope="col">Updated At</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.contentList.map((contentData, index) => (
                        <tr className="table-tr" key={index}>
                          <td>{contentData.page_title}</td>
                          {/* <td><span className="text-left">{contentData.content != null ? nl2br(contentData.content.replace(/(?:\r\n|\r|\n)/g, '<br />')) : "None"}</span></td> */}
                          <td>
                            {contentData.updated_at != ""
                              ? listDateFormat(contentData.updated_at)
                              : "None"}
                          </td>
                          <td className="text-left">
                            <span className="mb-0 text-sm">
                              <Button
                                color="primary"
                                onClick={() =>
                                  this.updateContent(contentData.id)
                                }
                              >
                                Edit
                              </Button>
                            </span>
                          </td>
                        </tr>
                      ))}
                      {this.props.contentList.length == 0 && (
                        <tr>
                          <td>No record</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {/* <CardFooter className="py-4">
                    <nav aria-label="...">
                      {this.props.announceRecords > 0 && (
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={0}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.announceRecords / this.state.perPage
                          )}
                        />
                      )}
                    </nav>
                  </CardFooter> */}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        {/* 
        <div>
          <Modal isOpen={this.state.open} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Banner</ModalHeader>
            <ModalBody>
              <div className="userNameList">
                <div className="itemStyle">
                  <label
                    style={{ color: "black", fontWeight: "bold", fontSize: 14 }}
                  >
                    {" "}
                    Subject:<p>{this.state.getDetailsId.subject}</p>
                  </label>
                </div>
                <div className="itemStyle">
                  <label
                    style={{ color: "black", fontWeight: "bold", fontSize: 14 }}
                  >
                    Message:<p>{this.state.getDetailsId.message} </p>
                  </label>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    contentList: state.content.contentList,
    // announceRecords: state.announcement.announceRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContentList: (data) => dispatch(getContentList(data)),
    // deleteAnnouncement: (data, params) => dispatch(deleteAnnouncement(data, params))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentManagement)
);
