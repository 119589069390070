import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { reset } from "redux-form";
import { listDateFormat } from "../../utils/utils";
import Header from "components/Headers/Header";
import { Pagination } from "semantic-ui-react";
import { getUserINR } from "../../Redux/actons/inrMangement.action";
import { Link } from "react-router-dom";

export class UserInrList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      coin_family: "",
      coinId: "",
      userId: "",
      totalAmount: "",
    };
  }
  componentDidMount = async () => {
    if (this.props.location.state) {
      await this.getBalance(this.props.location.state);
    } else {
      this.props.history.goBack();
    }
  };

  getBalance = (userId) => {
    this.setState({ userId: userId });
    let data = { user_id: userId };
    let params = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      // user_id: userId,
    };
    this.props.getUserINR(data, params);
  };

  //page change
  changeApprovedPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getBalance(this.state.userId);
    });
  };
  // //search
  // onSearch = (abc) => {
  //   this.setState({ searchKey: abc, page: 1 }, () => {
  //     this.getBalance(this.state.coinId, this.state.userId);
  //   });
  // };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="user balance" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="inrmanagement">INR Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>User Balance</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Balance</th>
                        <th scope="col">Tx Type</th>
                        <th scope="col">From</th>
                        <th scope="col">To</th>
                        <th scope="col">Buy Value</th>
                        <th scope="col">Sell Value</th>
                        <th scope="col">Trade type</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.userBalanceList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.userBalanceList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.amount != "null" ? data.amount : "-"}</td>
                            <td>{data.type.toUpperCase()}</td>
                            <td>
                              {data.coin_from != "null"
                                ? data.coin_from.toUpperCase()
                                : "-"}
                            </td>
                            <td>
                              {data.coin_to != "null"
                                ? data.coin_to.toUpperCase()
                                : "-"}
                            </td>
                            <td>
                              {data.buy_value != "null"
                                ? parseFloat(data.buy_value).toFixed(4)
                                : "-"}
                            </td>
                            <td>
                              {data.sell_value != "null"
                                ? parseFloat(data.sell_value).toFixed(4)
                                : "-"}
                            </td>
                            <td>
                              {data.order_type != "null"
                                ? data.order_type.toUpperCase()
                                : "-"}
                            </td>
                            <td>{data.status.toUpperCase()}</td>
                            <td>{listDateFormat(data.updated_at)}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        onPageChange={(e, data) =>
                          this.changeApprovedPage(e, data)
                        }
                        boundaryRange={1}
                        defaultActivePage={1}
                        ellipsisItem={true}
                        firstItem={true}
                        lastItem={true}
                        siblingRange={1}
                        totalPages={Math.ceil(
                          this.props.totalRecords / this.state.perPage
                        )}
                      />
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    userBalanceList: state.fiatHistory.userFiatList.history,
    totalRecords: state.fiatHistory.userFiatList.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserINR: (data, params) => dispatch(getUserINR(data, params)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserInrList)
);
