import { actionTypes } from "../actons/userGuide.action";

const initialState = {
  getUserGuideList: [],
  userGuideRecords: "",
  getDetailsId: {},
  editDetails: {},
};

const userGuide = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case actionTypes.GET_USER_GUIDE_DETAILS:
      return {
        ...state,
        getUserGuideList: action.data,
      };

    case actionTypes.TOTAL_USER_GUIDE_RECORDS:
      return {
        ...state,
        userGuideRecords: action.data,
      };

    // case actionTypes.GET_ANNOUNCE_DETAIL:
    //   return {
    //     ...state,
    //     getDetailsId: action.data
    //   };
    // case actionTypes.SAVE_EDIT_DETAIL:
    //   return {
    //     ...state,
    //     editDetails: action.data
    //   };
    default:
      return state;
  }
};

export default userGuide;
