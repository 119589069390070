import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const getRevenueList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/report/withdrawCoinRevenue`,
    {},
    options,
    params
  );
};

const getTotalRevenue = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/report/totalCoinRevenue`,
    {},
    options,
    params
  );
};
const getWithdrawRevenue = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/report/withdrawRevenue`,
    {},
    options,
    params
  );
};

const getSwapRevenue = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/report/swapRevenue`,
    {},
    options,
    params
  );
};

const getOtcRevenue = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/report/otcRevenue`,
    {},
    options,
    params
  );
};

//revenue_graph
const getRevenueGraph = (params, options) => {
  // console.log("************>>>>>", options);
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/report/totalrevenuegraph`,
    {},
    options,
    params
  );
};
const getCoinList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/revenue/coinList`,
    {},
    options,
    params
  );
};
const getFiatRevenueList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/revenue/fiatRevenueList`,
    {},
    options,
    params
  );
};

export const RevenueService = {
  getRevenueList,
  getTotalRevenue,
  getOtcRevenue,
  getWithdrawRevenue,
  getSwapRevenue,
  getRevenueGraph,
  getCoinList,
  getFiatRevenueList,
};
