import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { getCoinWiseHistory } from "../../../Redux/actons/referral.action";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { IMAGE_PATH } from "./../../../constants/index";
import { Link } from "react-router-dom";

// const options = [
//   { key: "m", text: "Ascending", value: "asc" },
//   { key: "k", text: "Descending", value: "desc" },
// ];

export class CoinWiseHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      order: "asc",
    };
  }
  componentDidMount = () => {
    this.getHistoryList();
  };

  getHistoryList = () => {
    // let data = {
    //   // searchKey: this.state.searchKey,
    //   order: this.state.order,
    //   // perPage: this.state.perPage,
    //   // page: this.state.page,
    //   // filterType: this.state.filterType,
    // };
    this.props.getCoinWiseHistory();
  };

  sortBy = (e, data) => {
    // this.setState({ order: data.value }, () => {
    //   this.getPairFilteredList();
    // });
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Coin Wise History" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="referral-management">Referral Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Coin Wise History</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  {/* <CardHeader className="border-0"> */}
                  {/* <div className="selectSearchOption">
                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />
                    </div> */}
                  {/* </CardHeader> */}
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Coin</th>
                        <th scope=" col">Coin Symbol</th>
                        <th scope="col">Referral amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.getCoinWiseHistoryList.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.getCoinWiseHistoryList.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.coin_name}</td>
                            <td>
                              <img
                                src={`${IMAGE_PATH}${data.coin_image}`}
                                alt="logo"
                                style={{ height: 40, width: 40 }}
                              />
                            </td>
                            <td>{data.referral_amount}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    getCoinWiseHistoryList: state.referralList.coinWiseHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCoinWiseHistory: () => dispatch(getCoinWiseHistory()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CoinWiseHistory)
);
