import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";

import { Pagination, Dropdown, Form } from "semantic-ui-react";
import CustomHeader from "../../components/Headers/CustomHeader";
import { IMAGE_PATH } from "../../constants/index";
import Header from "components/Headers/Header";
import axios from "axios";
import { API_HOST } from "../../constants/index";
import { FormField } from "../../components/FormField";
import { withRouter } from "react-router-dom";
import { getBannerType, addBanner } from "../../Redux/actons/banner.action";
import { Field, reduxForm, change } from "redux-form";
import { SelectField } from "../../components/SelectField";
import { getCoinList } from "../../Redux/actons/user.action";
import { startLoading, stopLoading } from "../../Redux/actons/loading.action";
import { required } from "redux-form-validators";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
export class AddBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
      sortBy: "",
      image: "",
      link: "",
      bannerType: "0",
      selectedCoin: "",
    };
  }

  componentDidMount = () => {
    this.getBannerList();
    this.props.getCoinList();
  };

  addBanner = () => {
    let data = {
      type_id: this.state.bannerType,
      coin_id: this.state.selectedCoin,
      image: `${IMAGE_PATH}${this.state.image}`,
      link: this.state.link,
    };
    this.props.addBanner(data);
    this.props.history.goBack();
  };

  getBannerList = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.getBannerType(data);
  };

  onImageUpload = async (e) => {
    if (e.target.files.length == 1) {
      const formData = new FormData();
      await formData.append("image", e.target.files[0]);
      this.props.startLoading(true);
      var self = this;
      axios
        .post(`${API_HOST}api/v1/admin/wallets/uploadimage`, formData, {
          headers: {
            Authorization: this.props.JwtToken,
          },
        })
        .then(function (response) {
          self.setState({ image: response.data.data });
          self.props.stopLoading();
        })
        .catch(function (error) {
          this.props.stoptLoading();
        });
    } else {
      this.setState({
        image: "",
      });
    }
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <div>
          <Header headerTitle="Add Banner" />
        </div>
        <div style={{ padding: 30 }}>
          <Form onSubmit={handleSubmit(this.addBanner)}>
            <Form.Group>
              <Form.Field>
                <label>Banner type</label>
                <Field
                  name="banner_type"
                  search={false}
                  selectOnBlur={false}
                  component={SelectField}
                  defaultValue={this.state.bannerType}
                  children={this.props.bannerType}
                  placeholder="Select"
                  onChange={(e, data) =>
                    this.setState({
                      bannerType: data,
                      selectedCoin: "",
                      link: "",
                    })
                  }
                ></Field>
              </Form.Field>
            </Form.Group>

            {this.state.bannerType == 3 || this.state.bannerType == 4 || this.state.bannerType == 1 || this.state.bannerType == 2 ? (
              <Form.Group>
                <Form.Field>
                  <label>Coin</label>
                  <Field
                    name="banner_type"
                    search={false}
                    selectOnBlur={false}
                    component={SelectField}
                    defaultValue={this.state.selectedCoin}
                    children={this.props.coinsList}
                    placeholder="Select"
                    onChange={(e, data) =>
                      this.setState({ selectedCoin: data })
                    }
                    required
                  ></Field>
                </Form.Field>
              </Form.Group>
            ) : (
              console.log("")
            )}

            {this.state.bannerType == 5 ? (
              <Form.Group>
                <Form.Field>
                  <label>Link (* https:// is required)</label>
                  <input
                    type="text"
                    name="link"
                    placeholder="https://"
                    component={FormField}
                    onChange={(e, data) =>
                      this.setState({ link: e.target.value })
                    }
                    required
                  ></input>
                </Form.Field>
              </Form.Group>
            ) : (
              console.log("")
            )}

            <Form.Group>
              <Form.Field>
                <Label for="image">Upload Image (328*180)</Label>
                <input accept="image/*" type="file" onChange={this.onImageUpload} required/>
              </Form.Field>
            </Form.Group>

            {/* {this.state.isCoin && ( */}
            <Form.Field className="addBtn">
              <Button type="submit" color="success">
                Add Banner
              </Button>
            </Form.Field>
            {/* // )} */}
          </Form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    getAnnounceList: state.banner.bannerList,
    bannerType: state.banner.bannerType,
    coinsList: state.user.coinsList,
    JwtToken: state.persist.c_user.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerType: (data) => dispatch(getBannerType(data)),
    getCoinList: () => dispatch(getCoinList()),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    addBanner: (data) => dispatch(addBanner(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "AddBannerForm" })(AddBanner));
