import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
} from "reactstrap";

import { Pagination, Confirm, Dropdown, Menu } from "semantic-ui-react";
import CustomHeader from "../../../components/Headers/CustomHeader";
import { withRouter } from "react-router";
import { listDateFormat } from "../../../utils/utils";
import {
  API_HOST,
  BTC_TXN_BTC,
  ETH_TXN_ETH,
  BTC_ADDRESS_BTC,
  ETH_ADDRESS_ETH,
  BSC_ADDRESS_BSC,
  MATIC_ADDRESS_MATIC
} from "../../../constants/index";
import { getTransactionWithdrawList } from "../../../Redux/actons/report.action";
import { getCoinListForFilter } from "../../../Redux/actons/userList.action";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

const Filteroptions = [{ key: "e", text: "None", value: "none" }];
export class WithdrawRevenue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      sort: "asc",
      filterType: "",
    };
  }

  componentDidMount = () => {
    this.props.getCoinList();
    this.getAllWithdrawList();
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.coinListCoinFilter !== this.props.coinListCoinFilter) {
      nextProps.coinListCoinFilter.forEach((element) => {
        Filteroptions.push(element);
      });
    }
  };
  goToTRaderReport = () => {
    this.props.history.push("reports");
  };

  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ sort: data.value }, () => {
      this.getAllWithdrawList();
    });
  };
  getAllWithdrawList = () => {
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      sort: this.state.sort,
      filterType: this.state.filterType,
    };
    this.props.getTransactionWithdrawList(data);
  };
  redirectToHashSite = (coin, hash) => {
    if (coin == "btc") {
      window.open(`${BTC_TXN_BTC}/${hash}`, "_blank");
    }
    if (coin == "eth" || coin == "ida" || coin == "orbs") {
      window.open(`${ETH_TXN_ETH}/${hash}`, "_blank");
    }
    if (coin == "bnb" || coin == "busd" || coin == "rena") {
      window.open(`${BSC_ADDRESS_BSC}/${hash}`, "_blank");
    }
    if (coin == "matic") {
      window.open(`${MATIC_ADDRESS_MATIC}/${hash}`, "_blank");
    }
  };
  //page change
  changeApprovedPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getAllWithdrawList();
    });
  };

  onSearchFee = (abc) => {
    console.log(abc);
    this.setState({ searchKey: abc, page: 1 }, () => {
      this.getAllWithdrawList();
    });
  };

  filterCoinBy = (e, data) => {
    console.log("swap", data.value);

    if (data.value !== "none") {
      this.setState({ filterType: data.value }, () => {
        this.getAllWithdrawList();
      });
    } else {
      this.setState({ filterType: "" }, () => {
        this.getAllWithdrawList();
      });
    }
  };

  render() {
    return (
      <div>
        <CustomHeader />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-0">Withdraw Fee Revenue</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onChange={(e) => this.onSearchFee(`${e.target.value}`)}
                      ></Input>
                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.sort}
                        onChange={(e, data) => this.filterBy(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={Filteroptions}
                        placeholder="Filter by Coin"
                        selection
                        onChange={(e, data) => this.filterCoinBy(e, data)}
                      />
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.goToTRaderReport()}
                      >
                        Back
                      </Button>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email Id</th>
                        <th scope="col">Coin Name</th>
                        <th scope="col">TXID</th>

                        <th scope="col">Amount</th>
                        <th scope="col">Commission Fee</th>
                        <th scope="col">Fee</th>
                        <th scope="col">Added On</th>
                        {/* <th scope="col">Tx Time</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.withdrawList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          <td>{usersList.user.name}</td>
                          <td>{usersList.user.email}</td>
                          <td>{usersList.coin.coin_symbol}</td>
                          {/* <td>{usersList.tx_id}</td> */}
                          <td
                            title={usersList.tx_id}
                            onClick={() =>
                              this.redirectToHashSite(
                                usersList.coin.coin_symbol,
                                usersList.tx_id
                              )
                            }
                          >
                            {usersList.tx_id
                              ? usersList.tx_id.slice(0, 12) + "..."
                              : "NONE"}
                          </td>

                          <td>{usersList.amount}</td>
                          <td>{usersList.trnx_fee_eth}</td>
                          <td>{usersList.fee_eth}</td>
                          <td>{listDateFormat(usersList.created_at)}</td>
                        </tr>
                      ))}
                      {this.props.withdrawList.length == 0 && (
                        <td>No record</td>
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      {this.props.withdrawTotalRecords > 0 && (
                        <Pagination
                          onPageChange={(e, data) =>
                            this.changeApprovedPage(e, data)
                          }
                          boundaryRange={0}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.withdrawTotalRecords / this.state.perPage
                          )}
                        />
                      )}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    withdrawList: state.reports.withdrawList,
    withdrawTotalRecords: state.reports.withdrawTotalRecords,
    coinListCoinFilter: state.userList.coinListCoinFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionWithdrawList: (data) =>
      dispatch(getTransactionWithdrawList(data)),
    getCoinList: () => dispatch(getCoinListForFilter()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WithdrawRevenue)
);
