import { actionTypes } from "../actons/revenue.action";

const initialState = {
  getWithdrawRevenue: [],
  revenueRecords: "",
  getTotalCoinRevenue: [],
  getWithdrawTotal: [],
  getOtcRevenue: [],
  coinData: [],
  totalRecords: 0,
  fiatRevenueRecords: 0,
  fiatRevenueData: [],
  revenueGraph: {
    labels: [],
    datasets: [
      {
        label: "Performance",
        data: [],
      },
    ],
  },
};

const revenue = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case actionTypes.SAVE_WITHDRAW_REVENUE:
      return {
        ...state,
        getWithdrawRevenue: action.data,
      };
    case actionTypes.TOTAL_WITHDRAW_REVENUE:
      return {
        ...state,
        revenueRecords: action.data,
      };
    case actionTypes.SAVE_TOTAL_REVENUE:
      return {
        ...state,
        getTotalCoinRevenue: action.data,
      };

    case actionTypes.SAVE_TOTAL_WITHDRAW_REVENUE:
      return {
        ...state,
        getWithdrawTotal: action.data,
      };

    case actionTypes.SAVE_OTC_REVENUE:
      return {
        ...state,
        getOtcRevenue: action.data,
      };
    case actionTypes.SAVE_REVENUE_COINS:
      return {
        ...state,
        coinData: action.data,
      };
    case actionTypes.TOTAL_REVENUE_COINS:
      return {
        ...state,
        totalRecords: action.data,
      };
    case actionTypes.SAVE_FIAT_REVENUE:
      return {
        ...state,
        fiatRevenueData: action.data,
      };
    case actionTypes.TOTAL_FIAT_REVENUE:
      return {
        ...state,
        fiatRevenueRecords: action.data,
      };
    case actionTypes.SAVE_REVENUE_GRAPH:
      return {
        ...state,
        revenueGraph: {
          labels: action.total_data.lable,
          datasets: [
            {
              label: "Performance",
              data: action.total_data.data,
            },
          ],
        },
      };
    default:
      return state;
  }
};

export default revenue;
