import React, { Component } from "react";
import { Form, Radio } from "semantic-ui-react";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../components/FormField";
import { required } from "redux-form-validators";
import {
  getBankDetails,
  updateBankDetails,
} from "../../Redux/actons/bank.action";
import Header from "components/Headers/Header";
import { reset } from "redux-form";
import { Link } from "react-router-dom";

const options = [
  { key: "m", text: "Deposit", value: "deposit" },
  { key: "k", text: "Kyc", value: "kyc" },
];

export class BankAccountManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountHolder: "",
      accountNumber: "",
      bankName: "",
      branch: "",
      ifscCode: "",
      upiId: "",
      fiat_deposit_fee: "",
      fiat_withdraw_fee: "",
      min_deposit_amount: "",
      max_deposit_amount: "",
      min_withdraw_amount: "",
      max_withdraw_amount: "",
      fiat_daily_withdraw_limit: "",

      fiat_withdraw_status: "",
    };
  }
  componentDidMount = async () => {
    this.props.getBankDetails().then(() => {
      this.setState({
        accountHolder: this.props.bankAccount.account_holder,
        accountNumber: this.props.bankAccount.account_number,
        bankName: this.props.bankAccount.bank_name,
        branch: this.props.bankAccount.branch,
        ifscCode: this.props.bankAccount.ifsc_code,
        upiId: this.props.bankAccount.upi_id,
        fiat_deposit_fee: this.props.bankAccount.fiat_deposit_fee,
        fiat_withdraw_fee: this.props.bankAccount.fiat_withdraw_fee,
        min_deposit_amount: this.props.bankAccount.min_deposit_amount,
        max_deposit_amount: this.props.bankAccount.max_deposit_amount,
        min_withdraw_amount: this.props.bankAccount.min_withdraw_amount,
        max_withdraw_amount: this.props.bankAccount.max_withdraw_amount,
        fiat_daily_withdraw_limit:
          this.props.bankAccount.fiat_daily_withdraw_limit,

        fiat_withdraw_status: Number(
          this.props.bankAccount.fiat_withdraw_status
        ),
      });
    });
  };

  updateForm = () => {
    if (this.state.fiat_deposit_fee < 0) {
      alert("Fiat deposit fee value greater and equal to zero");
      return false;
    }
    if (Number(this.state.fiat_withdraw_fee) < 0) {
      alert("Fiat withdraw fee value greater and equal to zero");
      return false;
    }

    if (this.state.min_deposit_amount < 0) {
      alert("Minimum deposit amount value greater and equal to zero");
      return false;
    }
    if (
      Number(this.state.max_deposit_amount) <
      Number(this.state.min_deposit_amount)
    ) {
      alert(
        "Maximum deposit amount should greater then minimum deposit amount"
      );
      return false;
    }

    if (this.state.min_withdraw_amount < 0) {
      alert("Minimum withdraw amount value greater and equal to zero");
      return false;
    }
    if (
      Number(this.state.max_withdraw_amount) <
      Number(this.state.min_withdraw_amount)
    ) {
      alert(
        "Maximum withdraw amount should greater then minimum withdraw amount"
      );
      return false;
    }
    if (this.state.fiat_daily_withdraw_limit < 0) {
      alert("Daily withdraw limit value greater and equal to zero");
      return false;
    }

    const formData = {
      account_holder: this.state.accountHolder,
      account_number: this.state.accountNumber,
      bank_name: this.state.bankName,
      branch: this.state.branch,
      ifsc_code: this.state.ifscCode,
      upi_id: this.state.upiId,
      fiat_deposit_fee: this.state.fiat_deposit_fee,
      fiat_withdraw_fee: this.state.fiat_withdraw_fee,

      min_deposit_amount: this.state.min_deposit_amount,
      max_deposit_amount: this.state.max_deposit_amount,
      min_withdraw_amount: this.state.min_withdraw_amount,
      max_withdraw_amount: this.state.max_withdraw_amount,
      fiat_daily_withdraw_limit: this.state.fiat_daily_withdraw_limit,

      fiat_withdraw_status: this.state.fiat_withdraw_status,
    };
    this.props.updateBankDetails(formData);
    this.props.reset("addReferralForm");
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <div>
          <Header headerTitle="Admin Bank Account Management" />
          {/* <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="inrmanagement">INR Management</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Fee/Limit Management</BreadcrumbItem>
            </Breadcrumb>
          </div> */}
        </div>
        <div style={{ padding: 30 }}>
          <Form onSubmit={handleSubmit(this.updateForm)}>
            <Form.Group>
              <Form.Field>
                <label>Account Holder</label>
                <input
                  type="text"
                  name="account_holder"
                  value={this.state.accountHolder}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ accountHolder: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Account Number</label>
                <input
                  value={this.state.accountNumber}
                  name="account_number"
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ accountNumber: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Bank Name</label>
                <input
                  name="bank_name"
                  value={this.state.bankName}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ bankName: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Branch Name</label>
                <input
                  name="branch_name"
                  value={this.state.branch}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ branch: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>IFSC Code</label>
                <input
                  name="ifsc_code"
                  value={this.state.ifscCode}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ ifscCode: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>UPI ID</label>
                <input
                  type="text"
                  name="upi_id"
                  value={this.state.upiId}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ upiId: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Bank account deposit fee</label>
                <input
                  type="number"
                  name="fiat_deposit_fee"
                  value={this.state.fiat_deposit_fee}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ fiat_deposit_fee: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Bank account withdraw fee</label>
                <input
                  type="number"
                  name="fiat_withdraw_fee"
                  value={this.state.fiat_withdraw_fee}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ fiat_withdraw_fee: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Minimum deposit amount</label>
                <input
                  type="number"
                  name="min_deposit_amount"
                  value={this.state.min_deposit_amount}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ min_deposit_amount: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Maximum deposit amount</label>
                <input
                  type="number"
                  name="max_deposit_amount"
                  value={this.state.max_deposit_amount}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ max_deposit_amount: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Minimum withdraw amount</label>
                <input
                  type="number"
                  name="min_withdraw_amount"
                  value={this.state.min_withdraw_amount}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ min_withdraw_amount: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Maximum withdraw amount</label>
                <input
                  type="number"
                  name="max_withdraw_amount"
                  value={this.state.max_withdraw_amount}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ max_withdraw_amount: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Daily withdraw limit</label>
                <input
                  type="number"
                  name="fiat_daily_withdraw_limit"
                  value={this.state.fiat_daily_withdraw_limit}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ fiat_daily_withdraw_limit: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>
                  Bank account withdraw status
                  {console.log(this.state.fiat_withdraw_status, "jjjjjjkk")}
                </label>

                {this.state.fiat_withdraw_status === 0 ? (
                  <Form.Field>
                    <Radio
                      toggle
                      value={1}
                      checked={0 ? true : false}
                      onChange={(e, { value }) =>
                        this.setState({ fiat_withdraw_status: value })
                      }
                    />
                  </Form.Field>
                ) : (
                  <Form.Field>
                    <Radio
                      toggle
                      value={0}
                      checked={1 ? true : false}
                      onChange={(e, { value }) =>
                        this.setState({ fiat_withdraw_status: value })
                      }
                    />
                  </Form.Field>
                )}
              </Form.Field>
            </Form.Group>

            <Form.Field className="addBtn">
              <Button type="submit" color="success">
                Submit
              </Button>
            </Form.Field>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bankAccount: state.bankAccount.bankAccount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBankDetails: () => dispatch(getBankDetails()),
    updateBankDetails: (data) => dispatch(updateBankDetails(data)),
    reset: (data) => dispatch(reset(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "addBankForm" })(BankAccountManagement));
