import { fetch } from "./Fetch";
import { API_HOST, API_LOCAL_HOST } from "../constants/index";

const updateBankDetails = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/fiat/updateAccount`,
    data,
    options
  );
};

const getBankDetails = (data, options) => {
  return fetch("get", `${API_HOST}api/v1/admin/fiat/account`, data, options);
};

const getFiatBalance = (data, options) => {
  return fetch("get", `${API_HOST}api/v1/admin/fiat/allUsersBalance?order=${data.order}&page=${data.page}&perPage=${data.perPage}&searchKey=${data.searchKey}`,data, options);
};

const updateFiatBalance = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/fiat/updateBalance/${data.id}`,
    data,
    options
  );
};

export const BankManagementServices = {
  getBankDetails,
  updateBankDetails,
  getFiatBalance,
  updateFiatBalance
};
