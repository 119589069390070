import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getAdminPermissions } from "../../Redux/actons/SubAdmin.action";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { withRouter } from "react-router";

import logoPublet from "../../images/Taral.png";

var ps;

class SidebarSubAdmin extends React.Component {
  state = {
    collapseOpen: false,
  };
  componentWillMount = () => {
    // this.props.getAdminPermissions();
  };
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      //   alert("Route change!");
    }
  }
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState(
      {
        collapseOpen: false,
      },
      () => {
        if (this.props.user.role == 2) {
          window.location.reload(false);
        }
      }
    );
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          {
            // prop.name == "Kyc Management" &&    //  Transaction Management    ///hot-wallet-funds
            prop.path !== "/influencer-management" &&
              // prop.path !== "/fiat-management" &&
              prop.name !== "/fiat-management" &&
              prop.name !== "/referral-management-mlm" &&
              prop.name !== "View Fund Management" &&
              prop.name !== "/view-fund-management" &&
              prop.path !== "/view-fund-management" &&
              prop.path !== "/update-query" &&
              prop.path !== "/bank-account-management" &&
              prop.path !== "/interest-user-list" &&
              prop.path !== "/user-taral-gold-history" &&
              prop.path !== "/view-user-interest-history" &&
              prop.path !== "/taralgold-reply-broadcasting" &&
              prop.path !== "/user-taral-gold-requests" &&
              prop.name !== "OTC Management" &&
              prop.name !== "Withdrawal Management" &&
              prop.name !== "Report Management" &&
              // prop.name !== "Announcement Management" &&
              // prop.name !== "Fund Management" &&
              prop.name !== "Login" &&
              prop.name !== "Register" &&
              prop.name !== "User Profile" &&
              prop.name !== "Google Auth" &&
              prop.name !== "Forgot Password" &&
              prop.name !== "Email Confirmation" &&
              prop.name !== "OTC Level Setting" &&
              prop.name !== "Transaction Fee" &&
              prop.name !== "OTC order" &&
              prop.name !== "OTC Trader" &&
              prop.name !== "Trading Report" &&
              prop.name !== "Transaction Withdraw Report" &&
              prop.name !== "Token Deposit Report" &&
              prop.name !== "OTC order" &&
              prop.name !== "OTC Trader" &&
              prop.name !== "Add / Update Announcement" &&
              prop.name !== "Users Approved Kyc" &&
              prop.name !== "Users Submitted Kyc" &&
              prop.name !== "Coin Setting" &&
              prop.name !== "Reports Management" &&
              prop.name !== "Transaction Fee Management" &&
              prop.name !== "OTC transaction fee" &&
              prop.name !== "Pending Withdrawal List" &&
              prop.name !== "Add New Sub Admin" &&
              prop.name !== "Delete User" &&
              prop.name !== "Fund History" &&
              prop.name !== "Cold wallet Transaction History" &&
              prop.name !== "Kyc Level Transaction Setting" &&
              prop.name !== "Reward History" &&
              prop.name !== "Add Banner" &&
              prop.name !== "Add Interest" &&
              prop.name !== "Edit Interest" &&
              prop.name !== "Trade History" &&
              prop.name !== "Trade Setting" &&
              prop.name !== "Coin Setting" &&
              prop.name !== "Add Token" &&
              prop.name !== "Add Coin Pair" &&
              prop.name !== "Edit Token" &&
              prop.name !== "Edit Pair Coin" &&
              prop.name !== "Lock fund History" &&
              prop.name !== "Lock fund Management" &&
              prop.name !== "Withdrawal Management" &&
              prop.name !== "User INR Management" &&
              prop.name !== "Coin Balance" &&
              prop.name !== "Fiat Revenue List" &&
              prop.name !== "View Incentive" &&
              prop.name !== "Update Content" &&
              prop.name !== "Add Referral" &&
              prop.name !== "Referral History" &&
              prop.name !== "Coin Wise History" &&
              prop.name !== "User Installment History" &&
              prop.name !== "Pair Coin Management" &&
              prop.name !== "Banner Management" &&
              prop.name !== "Add Funds File" &&
              prop.name !== "Fiat Management" &&
              prop.name !== "Add User Guide" &&
              prop.name !== "Referral Incentive" &&
              prop.name !== "Update Query" &&
              prop.name !== "Add Subject" && (
                // prop.name !== 'Contact Queries'
                // prop.name !== "Account Settings" &&
                <NavLink
                  to={prop.layout + prop.path}
                  tag={NavLinkRRD}
                  onClick={this.closeCollapse}
                  id={prop.path}
                  activeClassName="active"
                >
                  <i className={prop.icon} />
                  {prop.name}
                </NavLink>
              )
          }
        </NavItem>
      );
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light customColr"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>

          {/* Collapse */}
          <Collapse
            navbar
            isOpen={this.state.collapseOpen}
            className="customNav"
          >
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>

            <div className="logoBlock">
              <img src={logoPublet} className="insideLogo" />
              <p>Taral Admin</p>
            </div>
            <hr className="my-3" style={{ background: "darkgray" }} />
            {/* Navigation */}
            <h6 className="navbar-heading text-muted">Menu</h6>

            <Nav navbar className="customNavFont">
              {this.createLinks(routes)}
            </Nav>
            {/* Divider */}
            <hr className="my-3" style={{ background: "darkgray" }} />
            {/* Heading */}
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

SidebarSubAdmin.defaultProps = {
  routes: [{}],
};

SidebarSubAdmin.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
    permissions: state.subAdmin.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdminPermissions: () => dispatch(getAdminPermissions()),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarSubAdmin)
);
