import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import {
  getGSTLimit,
  setGSTLimit,
} from "../../Redux/actons/transaction.action";
import { Form } from "semantic-ui-react";
import { Field, reduxForm, change } from "redux-form";
import { FormField } from "../../components/FormField";
import { required } from "redux-form-validators";
// import { amountLimit } from "../../validators/customValidator";

export class SetGST extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gstValue: 0,
    };
  }
  componentDidMount = () => {
    this.props.getGSTLimit({ type: "gst_percent" }).then(async () => {
      // this.setState({gstValue: this.props.gstValue})
      await this.props.dispatch(
        change("SetGstForm", "limit", this.props?.gstValue)
      );
    });
  };
  updateGSTForm = (values) => {
    let data = {
      type: "gst_percent",
      value: values.limit,
    };
    // console.log("data >> ", data);
    this.props.setGSTLimit(data);
    this.props.openGstModal();
  };
  render() {
    // console.log("this.props222 >> ", this.props);
    let { handleSubmit } = this.props;
    return (
      <div>
        {/* <Button color="danger">X</Button> */}

        <Modal
          isOpen={this.props.gstModal}
          toggle={this.props.openGstModal}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.openGstModal}>Set GST</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.updateGSTForm)}>
              <Form.Group>
                <Form.Field>
                  <label>Value(%)</label>
                  {/* <input
                    style={{ width: "86px" }}
                    placeholder="Value"
                    name="limit"
                    min="0"
                    max="100"
                    // value={this.state.gstValue}
                    // value={this.props.gstValue}
                    // component={FormField}
                    type="number"
                    validate={[required()]} 
                    // onChange={(e, data) =>
                    //   {
                    //     console.log(e.target.value);
                    //     {
                    //       this.setState({ gstvalue: e.target.value });
                    //     }
                    //   }
                    // }
                */}
                  <Field
                    placeholder="Limit"
                    name="limit"
                    // value={this.state.fee}
                    component={FormField}
                    type="number"
                    validate={[required()]}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    gstValue: state.transaction.gstValue,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGSTLimit: (data) => dispatch(getGSTLimit(data)),
    setGSTLimit: (data) => dispatch(setGSTLimit(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "SetGstForm" })(SetGST));
// export default connect(mapStateToProps, mapDispatchToProps)(SetWithdrawDailyLimit);
