import { BankManagementServices } from "Services/BankManagementServices";
import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../components/Toast/Toast";

export const actionTypes = {
  BANK_ACCOUNT_DETAILS: "BANK_ACCOUNT_DETAILS",
  FIAT_BALANCE_DETAILS: "FIAT_BALANCE_DETAILS"
};

export function saveBankAccount(data) {
  return {
    type: actionTypes.BANK_ACCOUNT_DETAILS,
    data: data,
  };
}

export function fiatBalanceDetails(data) {
  return {
    type: actionTypes.FIAT_BALANCE_DETAILS,
    data: data,
  };
}

export function updateBankDetails(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return BankManagementServices.updateBankDetails(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(stopLoading());
        dispatch(getBankDetails());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getBankDetails(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return BankManagementServices.getBankDetails(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveBankAccount(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getFiatBalance(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return BankManagementServices.getFiatBalance(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(fiatBalanceDetails(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function updateFiatBalance(data, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return BankManagementServices.updateFiatBalance(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(stopLoading());
        dispatch(getFiatBalance(data2));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}