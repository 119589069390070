import Index from "./views/Dashboard/Index";
import Profile from "views/examples/Profile.js";
import TransactionFeeSetting from "./views/TransactionFeeManagement/TransactionFeeSetting/TransactionFeeSetting";
import AuthForm from "./views/TwoFactorauth/AuthForm";
import TwoFactorauth from "./views/TwoFactorauth/TwoFactorauth";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import EmailConfirmation from "./views/ForgotPassword/EmailConfirmation";
import SubmittedKyc from "./views/UserManagement/SubmittedKyc/SubmittedKyc";
import ApprovedKyc from "./views/UserManagement/ApprovedKyc/ApprovedKyc";
import LoginFormPage from "./views/Login/LoginFormPage";
import AccountSetting from "./views/AccountSetting/AccountSetting";
import GoogleAuth from "./views/GoogleAuth/GoogleAuth";
import WithDrawDepositSetting from "./views/WithDrawDepositSetting/WithDrawDepositSetting";
import OtcLevelSetting from "./views/OtcManagement/OtcLevelSetting/OtcLevelSetting";

import TradingReports from "./views/ReportsManagement/TradingReports/TradingReports";
import TokenDepositReports from "./views/ReportsManagement/DepositReports/TokenDepositReports";
import TransactionWithdrawReports from "./views/ReportsManagement/WithdrawReports/TransactionWithdrawReports";
import AddAnnouncement from "./views/Announcement/AddAnnouncement/AddAnnouncement";
import KycLevelSetting from "./views/KycManagement/KycLevelSettings/KycLevelSettings";
import OtcTransactionFee from "./views/OtcManagement/OtcTransactionFee/OtcTransactionFee";
import OtcTraderManagement from "./views/OtcManagement/OtcTraderManagement";
import OtcOrderListManagement from "./views/OtcManagement/OtcOrderListManagement";
import WithdrawRevenue from "./views/AccountingManagement/WithdrawRevenue/WithdrawRevenue";
import AddNewSubAdmin from "./views/SubAdminManagement/AddNewSubAdmin/AddNewSubAdmin";
import HotwalletFundsHistory from "./views/Withdraw/HotWalletFunds/HotWalletFundsHistory";

import PendingWithdrawList from "./views/Withdraw/WithdrawList/PendingWithdrawList";
import DeleteUser from "./views/UserManagement/User/DeleteUser";
import FundHistory from "./views/FundsManagement/FundHistory";

// import RewardManagement from "views/RewardManagement/RewardManagement";
import RewardHistory from "views/RewardManagement/RewardHistory";

import AddInterest from "./views/InterestManagement/AddInterest/AddInterest";
import TradeHistory from "views/TradeManagement/TradeHistory/TradeHistory";
import TradeSetting from "views/TradeManagement/TradeSetting/TradeSetting";
import LockFundMangement from "views/LockFundManagement/LockFundMangement";
import WithdrawContainer from "./views/Withdraw/WithdrawContainer/WithdrawContainer";
import UserInrList from "views/INRManagement/UserInrList";
import CoinWiseBalance from "views/Withdraw/HotWalletFunds/CoinWiseBalance";
import FiatRevenueList from "views/RevenueManagement/FiatRevenueList";
import ViewIncentive from "views/RewardManagement/ViewIncentive";
import AddBanner from "views/BannerManagement/AddBanner";
// import BannerManagement from "views/BannerManagement/BannerManagement";
import UpdateContent from "views/ContentManagement/UpdateContent";
import AddReferral from "views/ReferralManagement/AddReferral/AddReferral";
import BankAccountManagement from "views/BankAccountManagement/BankAccountManagement";
import ReferralHistory from "views/ReferralManagement/ReferralHistory/ReferralHistory";
import CoinWiseHistory from "views/ReferralManagement/CoinWiseHistory/CoinWiseHistory";
import UserInstalmentHistory from "views/InstallmentsManagement/UserInstalmentHistory";
import AddToken from "./views/TokenManagement/AddToken/AddToken";
import EditToken from "./views/TokenManagement/Edit Token/EditToken";
import AddFundsFile from "views/FundsManagement/AddFundsFile/AddFundsFile";
import ReferralIncentive from "./views/ReferralManagement/ReferralIncentive";
import SubjectManagement from "views/ContactQueryManagement/SubjectManagement/SubjectManagement";
import ContactQueryManagement from "views/ContactQueryManagement/ContactQueryManagement";
import UserGuideListManagement from "views/UserGuide/UserGuideList";
import AddUserGuide from "./views/UserGuide/AddUserGuide/AddUserGuide";
import InterestUserList1 from "./views/InterestManagement/InterestUserList/InterestUserList";
import ViewUserInterestHistory from "./views/InterestManagement/ViewUserInterestHistory/ViewUserInterestHistory";
// import UpdateQuery from "views/ContactQueryManagement/UpdateQuery";
// import PairCoinManagement from "views/PairCoinManagement/PairCoinManagement";
// import FundManagement from "views/FundsManagement/FundManagement";

var routesSubAdmin = [
  {
    path: "/delete-users",
    name: "Delete User",
    icon: "ni ni-circle-08",
    component: DeleteUser,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-announcement",
    name: "Add / Update Announcement",
    icon: "ni ni-badge",
    component: AddAnnouncement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  // {
  //   path: "/banner-management",
  //   name: "Banner Management",
  //   icon: "ni ni-image",
  //   component: BannerManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  // {
  //   path: "/hot-wallet-funds",
  //   name: "Hot Wallet Management",
  //   icon: "ni ni-badge",
  //   component: HotwalletFunds,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  // {
  //   name: "Pair Coin Management",
  //   path: "/pair-coin",
  //   icon: "ni ni-send",
  //   component: PairCoinManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02",
    component: Profile,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25",
    component: LoginFormPage,
    layout: "/vnjdLbguybcAzLmvAlsdnfjDbiumaas",
  },

  {
    path: "/submitted-kyc",
    name: "Users Submitted Kyc",
    icon: "ni ni-money-coins",
    component: SubmittedKyc,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/approved-kyc",
    name: "Users Approved Kyc",
    icon: "ni ni-single-02",
    component: ApprovedKyc,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/referral-incentive",
    name: "Referral Incentive",
    icon: "ni ni-diamond",
    component: ReferralIncentive,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/account-setting",
  //   name: "Account Settings",
  //   icon: "ni ni-badge",
  //   component: AccountSetting,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/coin-setting",
    name: "Coin Setting",
    icon: "ni ni-badge",
    component: WithDrawDepositSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/Otc-Level-setting",
    name: "OTC Level Setting",
    icon: "ni ni-badge",
    component: OtcLevelSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/otc-trader",
    name: "OTC Trader",
    component: OtcTraderManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/Kyc-Level-setting",
    name: "Kyc Level Transaction Setting",
    icon: "ni ni-badge",
    component: KycLevelSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/Transaction-fee",
    name: "Transaction Fee",
    icon: "ni ni-chart-bar-32 text-green",
    component: TransactionFeeSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/google-auth-form",
    name: "Google Auth",
    icon: "ni ni-badge",
    component: AuthForm,
    layout: "/vnjdLbguybcAzLmvAlsdnfjDbiumaas",
  },
  {
    path: "/reset-password",
    name: "Forgot Password",
    icon: "ni ni-circle-08",
    component: ForgotPassword,
    layout: "/vnjdLbguybcAzLmvAlsdnfjDbiumaas",
  },
  {
    path: "/forgot-password",
    name: "Email Confirmation",
    icon: "ni ni-circle-08",
    component: EmailConfirmation,
    layout: "/vnjdLbguybcAzLmvAlsdnfjDbiumaas",
  },
  {
    path: "/two-factor-auth",
    component: TwoFactorauth,
    layout: "/vnjdLbguybcAzLmvAlsdnfjDbiumaas",
  },
  {
    name: "Google Auth",
    path: "/google-setting",
    component: GoogleAuth,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/trading-reports",
    name: "Trading Report",
    icon: "ni ni-badge",
    component: TradingReports,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/transaction",
    name: "Transaction Withdraw Report",
    icon: "ni ni-badge",
    component: TransactionWithdrawReports,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/deposit-token",
    name: "Token Deposit Report",
    icon: "ni ni-badge",
    component: TokenDepositReports,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/otc-transaction-fee",
    name: "OTC transaction fee",
    component: OtcTransactionFee,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/all_withdrawal-revenue",
    name: "Reports Management",
    component: WithdrawRevenue,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/otc-order",
    name: "OTC order",
    component: OtcOrderListManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/history",
    name: "Cold wallet Transaction History",
    icon: "ni ni-badge",
    component: HotwalletFundsHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-sub-admin",
    name: "Add New Sub Admin",
    icon: "ni ni-badge",
    component: AddNewSubAdmin,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/pending-withdraw",
    name: "Pending Withdrawal List",
    icon: "ni ni-badge",
    component: PendingWithdrawList,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/fund-history",
    name: "Fund History",
    icon: "ni ni-badge",
    component: FundHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/edit-interest/:id",
    name: "Edit Interest",
    icon: "ni ni-badge",
    component: AddInterest,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-interest",
    name: "Add Interest",
    icon: "ni ni-badge",
    component: AddInterest,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/interest-user-list",
  //   // name: "Interest User List1",
  //   icon: "ni ni-badge",
  //   component: InterestUserList1,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },

  // {
  //   path: "/view-user-interest-history",
  //   name: "view user interest history",
  //   icon: "ni ni-badge",
  //   component: ViewUserInterestHistory,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/reward-history",
    name: "Reward History",
    icon: "ni ni-badge",
    component: RewardHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/trade-history",
    name: "Trade History",
    icon: "ni ni-collection",
    component: TradeHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/trade-setting",
    name: "Trade Setting",
    icon: "ni ni-badge",
    component: TradeSetting,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/lock-fund-management",
    name: "Lock fund Management",
    icon: "ni ni-lock-circle-open",
    component: LockFundMangement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/fund-management",
  //   name: "Fund Management",
  //   icon: "ni ni-badge",
  //   component: FundManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  // {
  //   path: "/lock-fund-management",
  //   name: "Lock fund Management",
  //   icon: "ni ni-lock-circle-open",
  //   component: LockFundMangement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },

  // {
  //   path: "/taralgold-reply-broadcasting",
  //   name: "Taralgold Broadcasting",
  //   // name: "Lock fund Management",
  //   icon: "ni ni-lock-circle-open",
  //   component: ReplyOnTaralGoldQuery,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },

  // {
  //   name: "Bank Account Management",
  //   path: "/bank-account-management",
  //   icon: "ni ni-badge",
  //   component: BankAccountManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/withdrawal",
    name: "Withdrawal Management",
    icon: "ni ni-badge",
    component: WithdrawContainer,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/user-inr",
    name: "User INR Management",
    icon: "ni ni-badge",
    component: UserInrList,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/coin-wise-balance",
    name: "Coin Balance",
    icon: "ni ni-badge",
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
    component: CoinWiseBalance,
  },
  {
    path: "/fiat-revenue-list",
    name: "Fiat Revenue List",
    icon: "ni ni-badge",
    component: FiatRevenueList,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/viewIncentive",
    name: "View Incentive",
    icon: "ni ni-badge",
    component: ViewIncentive,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-banner",
    name: "Add Banner",
    icon: "ni ni-image",
    component: AddBanner,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/update-content",
    name: "Update Content",
    icon: "ni ni-image",
    component: UpdateContent,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/update-query",
  //   name: "Update Query",
  //   icon: "ni ni-image",
  //   component: UpdateQuery,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/add-referral",
    name: "Add Referral",
    component: AddReferral,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  // {
  //   path: "/contact-queries",
  //   name: "Contact Queries",
  //   icon: "ni ni-single-copy-04",
  //   component: ContactQueryManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },

  {
    path: "/add-subject",
    name: "Add Subject",
    icon: "ni ni-badge",
    component: SubjectManagement,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  // {
  //   path: "/User-guide",
  //   name: "User-guide Management",
  //   icon: "ni ni-badge",
  //   component: UserGuideListManagement,
  //   layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  // },
  {
    path: "/referral-history",
    name: "Referral History",
    icon: "ni ni-badge",
    component: ReferralHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/coin-wise-history",
    name: "Coin Wise History",
    icon: "ni ni-badge",
    component: CoinWiseHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/user-installment-history",
    name: "User Installment History",
    icon: "",
    component: UserInstalmentHistory,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-token",
    name: "Add Token",
    icon: "ni ni-badge",
    component: AddToken,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/edit-token",
    name: "Edit Token",
    icon: "ni ni-badge",
    component: EditToken,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
  {
    path: "/add-funds-file",
    name: "Add Funds File",
    icon: "ni ni-badge",
    component: AddFundsFile,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },

  {
    path: "/add-user-guide",
    name: "Add User Guide",
    icon: "ni ni-badge",
    component: AddUserGuide,
    layout: "/vainkjranltifshdfhgdsfhdjjhdgf",
  },
];
export default routesSubAdmin;
