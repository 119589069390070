import { actionTypes } from "../actons/bank.action";

const initialState = {
  bankAccount: "",
  fiatDetails: [],
};

const bankAccount = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BANK_ACCOUNT_DETAILS:
      return {
        ...state,
        bankAccount: action.data.data,
      };
    case actionTypes.FIAT_BALANCE_DETAILS:
      return {
        ...state,
        fiatDetails: action.data.data,
      };
    default:
      return state;
  }
};

export default bankAccount;
