import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "components/Headers/Header";
import {
  Button,
  FormGroup,
  Form,
  Table,
  Container,
  Card,
  CardHeader,
  Tooltip,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import CustomHeader from "components/Headers/CustomHeader";
import { reduxForm, change, Field } from "redux-form";
import axios from "axios";
import { API_HOST } from "../../../constants/index";
import {
  getEditTokenDetails,
  saveEditTokenDetails,
  updateEditTokenDetails,
  saveEditTokenDetails2,
  getTokenPriceByAlias,
  updateButton,
  getPriceByUsd,
} from "../../../Redux/actons/token.action";
import { FormField } from "../../../components/FormField";
import { required } from "redux-form-validators";
import {
  startLoading,
  stopLoading,
} from "../../../Redux/actons/loading.action";
import { IMAGE_PATH } from "../../../constants/index";
import { Link } from "react-router-dom";
import { fourDigitsAfterDecimal } from "../../../validators/customValidator";
export class EditToken extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contract_address: "",
      image: "",
      tooltipOpen: false,
      gickoAlias: "",
      symbol: "",
      priceList: [],
      usdPrice: "",
      decimalError: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.location.state) {
      localStorage.setItem("id", this.props.location.state);
      await this.props.getEditTokenDetails(this.props.location.state);
    } else {
      await this.props.getEditTokenDetails(localStorage.getItem("id"));
    }
    // await this.props.getEditTokenDetails(this.props.match.params.id);
    console.log("asd", typeof this.props.details.coin_gicko_alias);
    await this.props.dispatch(
      change(
        "EditTokenForm",
        "coin_gicko_alias",
        this.props?.details?.coin_gicko_alias || null
      )
    );
    if (this.props.details.coin_gicko_alias !== null) {
      this.setState({
        gickoAlias: this.props.details.coin_gicko_alias,
      });
    }
    this.props.priceList.forEach((element, index) => {
      this.props.dispatch(change("EditTokenForm", index, element.value));
    });

    this.setState({ priceList: this.props.priceList });
  };

  // componentWillReceiveProps = async (nextProps) => {
  //  await this.props.dispatch(
  //     change(
  //       "EditTokenForm",
  //       "coin_gicko_alias",
  //       // nextProps.this.state.gickoAlias
  //     )
  //   );
  //   if (
  //     nextProps.save_price_data !== undefined &&
  //     nextProps.save_price_data.length > 0
  //   ) {
  //     nextProps.save_price_data.forEach((element, index) => {
  //       nextProps.dispatch(change("EditTokenForm", index, element.value));
  //     });
  //     this.setState({ priceList: nextProps.save_price_data });
  //   }
  // };

  updatePriceForm = () => {
    this.props.dispatch(
      change("EditTokenForm", "coin_gicko_alias", this.state.gickoAlias)
    );
    if (
      this.props.save_price_data !== undefined &&
      this.props.save_price_data.length > 0
    ) {
      this.props.save_price_data.forEach((element, index) => {
        this.props.dispatch(change("EditTokenForm", index, element.value));
      });
      this.setState({ priceList: this.props.save_price_data });
    }
  };

  componentWillUnmount = () => {
    this.props.saveEditTokenDetails([]);
    this.props.saveEditTokenDetails2({});
    this.props.updateButton(false);
  };

  addToken = (values) => {
    let data = [];
    this.props.priceList &&
      this.props.priceList.forEach((element, index) => {
        let rec = {
          coin_type: element.coin_type,
          fiat_type: element.fiat_type,
          value: values[index],
        };
        data.push(rec);
      });
    let finalData = {
      coinFiatPriceData: data,
      gicko_alias: values.coin_gicko_alias ? values.coin_gicko_alias : "",
      image: this.state.image,
    };
    if (this.state.image === "") {
      delete finalData["image"];
    }
    this.props.updateEditTokenDetails(
      localStorage.getItem("id"),
      finalData,
      this.props.history
    );
  };

  back = () => {
    window.history.back();
  };
  onImageUpload = async (e) => {
    if (e.target.files.length == 1) {
      const formData = new FormData();
      await formData.append("image", e.target.files[0]);
      this.props.startLoading(true);
      var self = this;
      axios
        .post(`${API_HOST}api/v1/admin/wallets/uploadimage`, formData, {
          headers: {
            Authorization: this.props.JwtToken,
          },
        })
        .then(function (response) {
          self.setState({ image: response.data.data });
          self.props.stopLoading();
        })
        .catch(function (error) {
          this.props.stoptLoading();
        });
    } else {
      this.setState({
        image: "",
      });
    }
  };
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  onAddGicko = async (e) => {
    await this.setState({
      gickoAlias: e.target.value,
    });
    if (this.state.gickoAlias !== "") {
      this.props.updateButton(true);
    } else {
      this.props.updateButton(false);
    }
  };

  onSubmitGickoAlias = async () => {
    if (this.state.gickoAlias !== "") {
      this.props.dispatch(change("EditTokenForm", "manual_price", ""));
      this.setState({
        usdPrice: "",
      });
      await this.props.getTokenPriceByAlias(
        this.state.gickoAlias,
        localStorage.getItem("id")
      );
      this.updatePriceForm();
    }
  };

  onUsdPriceChange = async (e) => {
    await this.setState({
      usdPrice: e.target.value,
      decimalError: false,
    });
    var regex = /^\d{0,1000}(\.\d{0,6})?$/;
    if (!e.target.value.match(regex)) {
      this.setState({
        decimalError: true,
      });
    }
  };

  onSubmitUsdPrice = async () => {
    let data = {
      symbol: this.props.details.coin_symbol,
      coin_usd_price: this.state.usdPrice,
    };
    await this.props.getPriceByUsd(data);
    this.props.dispatch(change("EditTokenForm", "coin_gicko_alias", ""));
    this.setState({
      gickoAlias: "",
    });
    this.updatePriceForm();
  };
  render() {
    let { handleSubmit, priceLists, updateSubmitButton } = this.props;
    let { tooltipOpen, gickoAlias, priceList } = this.state;
    return (
      <div>
        <Header headerTitle="Edit token" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="token-management">Token Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Edit Token</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <Container className="contentBlock" fluid>
          <div style={{ padding: 100 }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <div style={{ width: "100%" }}>
                  <h3 className="mb-2">Edit Token</h3>
                </div>
              </CardHeader>
              <Form onSubmit={handleSubmit(this.addToken)}>
                <div>
                  <FormGroup style={{ padding: "10px" }}>
                    <Label for="coin_gicko_alias">Gecko Alias</Label>
                    <div style={{ display: "flex" }}>
                      <Field
                        name="coin_gicko_alias"
                        component={FormField}
                        type="text"
                        onChange={(e) => this.onAddGicko(e)}
                        style={{ width: "50%" }}
                      />

                      <div style={{ marginLeft: "5px" }}>
                        <p>
                          {" "}
                          <Button
                            type="button"
                            id="TooltipExample"
                            color="primary"
                            onClick={this.onSubmitGickoAlias}
                            disabled={
                              this.state.gickoAlias === "" ? true : false
                            }
                          >
                            Update
                          </Button>
                        </p>
                      </div>
                      <div style={{ marginLeft: "5px" }}>
                        <p>
                          {" "}
                          <Button
                            type="button"
                            style={{
                              textDecoration: "underline",
                              color: "red",
                              border: "1px solid",
                            }}
                            id="TooltipExample"
                          >
                            i
                          </Button>
                        </p>
                        <Tooltip
                          placement="right"
                          isOpen={tooltipOpen}
                          target="TooltipExample"
                          toggle={this.toggle}
                        >
                          {gickoAlias === "" ? "xxx" : gickoAlias} is the
                          coingeckoalias in "https://www.coingecko.com/en/coins/
                          {gickoAlias === "" ? "xxx" : gickoAlias}"
                        </Tooltip>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup style={{ display: "grid", padding: "10px" }}>
                    <Label for="image">Upload Image</Label>
                    <input type="file" onChange={this.onImageUpload} />
                    <div style={{ marginTop: "10px" }}>
                      <img
                        src={`${IMAGE_PATH}${this.props.details.coin_image}`}
                        style={{ height: 100, width: 100 }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup style={{ padding: "10px" }}>
                    <Label for="coin_gicko_alias">Manual Price Update</Label>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <Field
                          name="manual_price"
                          component={FormField}
                          type="text"
                          placeholder="USD"
                          // style={{ width: "50%" }}
                          onChange={(e) => this.onUsdPriceChange(e)}
                        />
                        {this.state.decimalError && (
                          <div className="form__field-error">
                            <p>Only six digits allowed after decimal</p>
                          </div>
                        )}
                      </div>
                      <div style={{ marginLeft: "5px" }}>
                        <p>
                          {" "}
                          <Button
                            type="button"
                            id="updatePrice"
                            color="primary"
                            onClick={this.onSubmitUsdPrice}
                            disabled={
                              this.state.usdPrice === ""
                                ? true
                                : false || this.state.decimalError === true
                            }
                          >
                            Update
                          </Button>
                        </p>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup style={{ padding: "10px" }}>
                    <Label for="edit_price">Price</Label>

                    {priceList.length !== 0 && (
                      <div>
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Coin Type</th>
                              <th scope="col">Fiat Type</th>
                              <th scope="col">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {priceList.map((data, index) => (
                              <tr key={index}>
                                <td>{data.coin_type}</td>
                                <td>{data.fiat_type}</td>
                                <td>
                                  <Field
                                    name={index}
                                    component={FormField}
                                    type="number"
                                    validate={[required()]}
                                    disabled
                                  />
                                </td>
                              </tr>
                            ))}
                            {priceList.length == 0 && <td>No record</td>}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="submit"
                      disabled={updateSubmitButton}
                    >
                      Update
                    </Button>

                    <Button onClick={this.back} color="success">
                      Back
                    </Button>
                  </div>
                </div>
              </Form>
            </Card>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    priceList: state.token.editToken,
    details: state.token.editTokenDetails,
    save_price_data: state.token.save_price_data,
    JwtToken: state.persist.c_user.token,
    updateSubmitButton: state.token.updateButton,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEditTokenDetails: (id) => dispatch(getEditTokenDetails(id)),
    saveEditTokenDetails: (data) => dispatch(saveEditTokenDetails(data)),
    updateEditTokenDetails: (id, data, history) =>
      dispatch(updateEditTokenDetails(id, data, history)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    saveEditTokenDetails2: (data) => dispatch(saveEditTokenDetails2(data)),
    getTokenPriceByAlias: (alias, id) =>
      dispatch(getTokenPriceByAlias(alias, id)),
    updateButton: (data) => dispatch(updateButton(data)),
    getPriceByUsd: (data) => dispatch(getPriceByUsd(data)),
  };
};

export default reduxForm({ form: "EditTokenForm" })(
  connect(mapStateToProps, mapDispatchToProps)(EditToken)
);
