import React, { Component } from "react";
import { Form, Dropdown, Pagination } from "semantic-ui-react";
import {
  Button,
  Table,
  Container,
  Row,
  CardFooter,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../components/FormField";
import { SelectField } from "../../components/SelectField";
import { email, required } from "redux-form-validators";
import {
  amountLimit,
  invalidMinusValue,
} from "../../validators/customValidator";
import {
  getAdminSettings,
  updateAdminSetting,
  updateWithdrawLimit,
  updateWithdrawFee,
} from "../../Redux/actons/account.action";
import { getCoinListCoinSetting } from "../../Redux/actons/user.action";
import "./WithDrawDepositSetting.scss";
import Header from "components/Headers/Header";
import { Link } from "react-router-dom";
import { setType } from "../../utils/utils";

const options = [
  { key: "k", text: "Withdraw Fee", value: "withdraw_fee_amount" },
  { key: "l", text: "Withdraw Limit", value: "withdraw_limit" },
  { key: "l", text: "Deposit Limit", value: "deposit_limit" },
];

const UserFilter = [
  { key: "o", text: "All", value: "" },
  { key: "c", text: "By Admin", value: "1" },
  { key: "u", text: "By Users", value: "0" },
];

const Type = [
  { key: "m", text: "Worth of fiat", value: "worth_of_fiat" },
  { key: "k", text: "Actual Crypto", value: "actual_crypto" },
];

export class WithDrawDepositSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCoin: false,
      isFieldSelected: false,
      defaultType: "",
      defaultAction: 1,
      selectedCoin: [],
      feeCoin: "",
      searchKey: "",
      listType: "",
      type: "",
      page: 1,
      perPage: 10,
    };
  }
  componentDidMount = () => {
    this.getCoinListCoinSetting();
  };

  getCoinListCoinSetting = () => {
    let params = {
      search: this.state.searchKey,
      list_type: this.state.listType,
      page: this.state.page,
      perPage: this.state.perPage,
    };
    this.props.getCoinListCoinSetting(params);
  }; //page change
  changeTransPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getCoinListCoinSetting();
    });
  };
  onSearchList = () => {
    this.getCoinListCoinSetting();
  };
  listByUser = (e, data) => {
    console.log(" data ----->", data.value);
    if (data.value !== "none") {
      this.setState({ listType: data.value }, () => {
        this.getCoinListCoinSetting();
      });
    } else {
      this.setState({ listType: "1" }, () => {
        this.getCoinListCoinSetting();
      });
    }
  };

  getDetails = (e, data) => {
    console.log(data);
    this.setState({ isFieldSelected: true, defaultType: data });
  };

  onSelectCoin = (e, title, id) => {
    let coin = [...this.state.selectedCoin];
    if (e.target.checked) {
      coin.push(id);
      this.setState({ selectedCoin: coin });
    } else {
      for (var i = 0; i < coin.length; i++) {
        if (coin[i] == id) {
          coin.splice(i, 1);
        }
      }
      this.setState({ selectedCoin: coin });
    }
  };

  selectAllCoin = (e, data) => {
    let coin = [];
    let coinList = data;
    if (e.target.checked) {
      for (var i = 0; i < coinList?.length; i++) {
        coin.push(coinList[i].coin_id);
        console.log(coin);
      }
      this.setState({ selectedCoin: coin });
    } else {
      for (var i = 0; i < coinList?.length; i++) {
        coin.splice(i, 1);
        console.log(coin);
      }

      this.setState({ selectedCoin: coin });
    }
  };

  updateForm = (values) => {
    if (this.state.defaultType == "withdraw_limit") {
      console.log("values >>", values);
      if (
        +values.min_withdraw_limit !== 0 &&
        +values.max_withdraw_limit !== 0
      ) {
        if (+values.min_withdraw_limit >= +values.max_withdraw_limit) {
          alert(
            "Minimum withdraw limit must be less than Maximum withdraw limit"
          );
          return false;
        }
      }
      const param = {
        key: this.state.defaultType,
        // daily_withdraw_limit: Number(values.daily_withdraw_limit),
        min_withdraw_limit: Number(values.min_withdraw_limit),
        max_withdraw_limit: Number(values.max_withdraw_limit),
        coin_ids: this.state.selectedCoin,
        withdraw_limit_type: values?.type,
      };

      console.log(param);

      this.props.updateWithdrawLimit(param);
      this.setState({ isFieldSelected: false });
    } else if (this.state.defaultType == "deposit_limit") {
      console.log("values >>", values);
      if (
        values.min_deposit_limit == "NaN" ||
        values.min_deposit_limit == undefined ||
        values.min_deposit_limit == ""
      ) {
        alert("Please enter minimum deposit amount");
        return false;
      }
      const param = {
        key: this.state.defaultType,
        min_deposit_limit: Number(values.min_deposit_limit),
        coin_ids: this.state.selectedCoin,
        deposit_limit_type: values?.type,
      };

      console.log(param);
      this.props.updateWithdrawLimit(param);
      this.setState({ isFieldSelected: false });
    } else {
      //Withdraw Fee
      // console.log('values value',values);
      if (
        values.value == "NaN" ||
        values.value == undefined ||
        values.value == ""
      ) {
        alert("Please enter fee amount");
        return false;
      }
      const param = {
        key: this.state.defaultType,
        value: Number(values.value),
        coin_ids: this.state.selectedCoin,
        // fee_coin: this.state.feeCoin,
      };

      console.log(param);
      this.props.updateWithdrawFee(param);
      // this.setState({ isFieldSelected: false });
      this.setState({ isFieldSelected: false, defaultType: "" });
      values.value = "";
    }
  };

  render() {
    let { handleSubmit } = this.props;
    console.log(
      this.props.coinsListMeta,
      this.props.coinsListMeta.pages,
      "coinsList",
      this.props.coinsList
    );
    return (
      <div>
        <div>
          <Header headerTitle="Set Coin limit/fee" />
        </div>
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="transaction-fee-management">
                Transaction Management
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Set Coin Limit Fee</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div style={{ padding: 30 }}>
          <label>Select Coin</label>
          <div className="selectSearchOption" style={{ padding: 30 }}>
            <Input
              placeholder="Search"
              onKeyDown={(e) => {
                // eslint-disable-next-line
                if (e.keyCode == 13) {
                  this.onSearchList();
                }
              }}
              onChange={(e) => {
                this.setState({ searchKey: e.target.value, page: 1 }, () => {
                  if (this.state.searchKey == "") {
                    this.onSearchList();
                  }
                });
              }}
            ></Input>
            <Button
              color="success btnMamagePermission"
              onClick={() => this.onSearchList()}
            >
              Search
            </Button>
            <Dropdown
              className="selectUserStyle"
              options={UserFilter}
              placeholder="Filter"
              selection
              onChange={(e, data) => this.listByUser(e, data)}
            />
          </div>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-dark">
              <tr>
                <th scope="col">
                  <input
                    name=""
                    component={FormField}
                    type="checkbox"
                    label=""
                    onChange={(e) =>
                      this.selectAllCoin(e, this.props.coinsList)
                    }
                  />
                </th>
                <th scope="col">Coin Name</th>
                <th scope="col">Withdraw Fee(₹)</th>
                {/* <th scope="col">Fee coin</th> */}
                {/* <th scope="col">Daily withdraw limit</th> */}
                <th scope="col">Maximum withdraw limit</th>
                <th scope="col">Minimum withdraw limit</th>
                <th scope="col">Minimum deposit limit</th>
                <th scope="col">Withdraw Limit Type</th>
                <th scope="col">Deposit Limit Type</th>
              </tr>
            </thead>
            <tbody>
              {this.props.coinsList?.length === 0 ? (
                <tr>
                  <td>No results found</td>
                </tr>
              ) : (
                this.props.coinsList?.map((data, index) => (
                  <tr className="table-tr" key={index}>
                    <td>
                      <input
                        name=""
                        component={FormField}
                        type="checkbox"
                        label=""
                        onChange={(e) =>
                          this.onSelectCoin(e, data.coin_name, data.coin_id)
                        }
                        checked={this.state.selectedCoin.includes(data.coin_id)}
                      />
                      {/* 
                      <Field
                        name=""
                        component={FormField}
                        type="checkbox"
                        label=""
                        onChange={(e) =>
                          this.onSelectCoin(e, data.coin_name, data.coin_id)
                        }                        
                        checked={this.state.selectedCoin.includes(data.coin_id)}
                      /> */}
                    </td>
                    <td>{data.text.toUpperCase()}</td>
                    <td>
                      {data.coins_setting
                        ? data.coins_setting.withdraw_fee_amount
                        : "-"}
                    </td>

                    {/* <td>
                      {data.fee_coin != 0
                        ? data?.fee_coin_data?.coin_name
                        : "-"}
                    </td> */}
                    {/* <td>{data.withdraw_limit}</td> */}
                    {/* <td>{data.daily_withdraw_limit}</td> */}
                    <td>{data.max_withdraw_limit}</td>
                    <td>{data.min_withdraw_limit}</td>
                    <td>
                      {data.min_deposit_limit !== undefined
                        ? data.min_deposit_limit
                        : "N/A"}
                    </td>
                    <td>
                      {data?.withdraw_limit_type
                        ? setType(data?.withdraw_limit_type)
                        : "N/A"}
                    </td>
                    <td>
                      {data?.deposit_limit_type
                        ? setType(data?.deposit_limit_type)
                        : "N/A"}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          {this.props.coinsList?.length != 0 && (
            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  onPageChange={(e, data) => this.changeTransPage(e, data)}
                  boundaryRange={1}
                  defaultActivePage={1}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  totalPages={Math.ceil(this.props.coinsListMeta.pages)}
                />
                <Button
                  disabled={this.props.coinsListMeta.pages == 1}
                  onClick={() => {
                    this.setState(
                      { perPage: this.props.coinsListMeta.total },
                      () => {
                        this.getCoinListCoinSetting();
                      }
                    );
                  }}
                >
                  All
                </Button>
              </nav>
            </CardFooter>
          )}
          <Form onSubmit={handleSubmit(this.updateForm)}>
            <Form.Group>
              <Form.Field>
                <label>Choose Field to update</label>
                <Field
                  name="field"
                  search={false}
                  selectOnBlur={false}
                  validate={[required()]}
                  component={SelectField}
                  defaultValue={this.state.defaultType}
                  children={options}
                  placeholder="Select"
                  onChange={(e, data) => this.getDetails(e, data)}
                />
              </Form.Field>
            </Form.Group>

            {this.state.isFieldSelected === true &&
              this.state.defaultType !== "withdraw_fee_amount" && (
                <Form.Group>
                  <Form.Field>
                    <label>Type</label>
                    <Field
                      name="type"
                      search={false}
                      selectOnBlur={false}
                      validate={[required()]}
                      component={SelectField}
                      defaultValue={this.state.type}
                      children={Type}
                      placeholder="Select"
                      onChange={(e, data) => this.setState({ type: data })}
                    />
                  </Form.Field>
                </Form.Group>
              )}

            {/* {this.state.isFieldSelected == true &&
              this.state.defaultType == "withdraw_fee" && (
                <Form.Group>
                  <Form.Field>
                    <label>Fee Coin</label>
                    <Field
                      name="fee_Coin"
                      search={false}
                      selectOnBlur={false}
                      validate={[required()]}
                      component={SelectField}
                      defaultValue={this.state.feeCoin}
                      children={this.props.coinsList}
                      placeholder="Select"
                      onChange={(e, data) => this.setState({ feeCoin: data })}
                    ></Field>
                  </Form.Field>
                </Form.Group>
              )} */}

            {this.state.isFieldSelected == true &&
              this.state.defaultType == "withdraw_fee_amount" && (
                <Form.Group>
                  <Form.Field>
                    <label>Value(INR)</label>
                    <Field
                      type="number"
                      name="value"
                      validate={[
                        required(),
                        amountLimit(),
                        invalidMinusValue(),
                      ]}
                      component={FormField}
                      placeholder="Value"
                    ></Field>
                  </Form.Field>
                </Form.Group>
              )}
            {this.state.isFieldSelected == true &&
              this.state.defaultType == "withdraw_limit" && (
                <div>
                  {/* <Form.Group>
                    <Form.Field>
                      <label>Daily withdraw limit</label>
                      <Field
                        type="number"
                        name="daily_withdraw_limit"
                        validate={[
                          required(),
                          amountLimit(),
                          invalidMinusValue(),
                        ]}
                        component={FormField}
                        placeholder="Value"
                      ></Field>
                    </Form.Field>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Field>
                      <label>Minimum withdraw limit</label>
                      <Field
                        type="number"
                        name="min_withdraw_limit"
                        validate={[
                          required(),
                          amountLimit(),
                          invalidMinusValue(),
                        ]}
                        component={FormField}
                        placeholder="Value"
                      ></Field>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <label>Maximum withdraw limit</label>
                      <Field
                        type="number"
                        name="max_withdraw_limit"
                        validate={[
                          required(),
                          amountLimit(),
                          invalidMinusValue(),
                        ]}
                        component={FormField}
                        placeholder="Value"
                      ></Field>
                    </Form.Field>
                  </Form.Group>
                </div>
              )}
            {this.state.isFieldSelected == true &&
              this.state.defaultType == "deposit_limit" && (
                <div>
                  <Form.Group>
                    <Form.Field>
                      <label>Minimum deposit limit</label>
                      <Field
                        type="number"
                        name="min_deposit_limit"
                        validate={[
                          required(),
                          amountLimit(),
                          invalidMinusValue(),
                        ]}
                        component={FormField}
                        placeholder="Value"
                      ></Field>
                    </Form.Field>
                  </Form.Group>
                </div>
              )}

            {this.state.selectedCoin?.length !== 0 && (
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    feeSettingDetails: state.account.feeSettingDetails,
    coinsList: state.user.coinsListSetting,
    coinsListMeta: state.user.coinsListMeta,
  };
};
const mapDispatchToProps = (dispatch) => {
  // this.setState({ isFieldSelected: false, defaultType: "" })
  return {
    getAdminSettings: (data) => dispatch(getAdminSettings(data)),
    updateAdminSetting: (data) => dispatch(updateAdminSetting(data)),
    getCoinListCoinSetting: (params) =>
      dispatch(getCoinListCoinSetting(params)),
    updateWithdrawLimit: (data) => dispatch(updateWithdrawLimit(data)),
    updateWithdrawFee: (data) => dispatch(updateWithdrawFee(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "feeSettingForm" })(WithDrawDepositSetting));
