import { AnnouncementService } from "../../Services/AnnouncementServices";
import { toast } from "../../components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";
import { reset } from "redux-form";

export const actionTypes = {
  SAVE_ANNOUNCE_DETAILS: "SAVE_ANNOUNCE_DETAILS",
  TOTAL_ANNOUNCE_RECORDS: "TOTAL_ANNOUNCE_RECORDS",
  GET_ANNOUNCE_DETAIL: "GET_ANNOUNCE_DETAIL",
  SAVE_EDIT_DETAIL: "SAVE_EDIT_DETAIL"
};
export function saveAnnounceEditDetails(data) {
  return {
    type: actionTypes.SAVE_EDIT_DETAIL,
    data
  };
}

export function saveAnnounceDetails(data) {
  return {
    type: actionTypes.SAVE_ANNOUNCE_DETAILS,
    data
  };
}
export function totalAnnounceRecords(data) {
  return {
    type: actionTypes.TOTAL_ANNOUNCE_RECORDS,
    data
  };
}

export function getAnnounceDetailId(data) {
  return {
    type: actionTypes.GET_ANNOUNCE_DETAIL,
    data: data
  };
}

//get Announcement list
export function getAnnouncementList(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }

    let state = getState();
    return AnnouncementService.getAnnouncementList(params, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async data => {
        dispatch(saveAnnounceDetails(data.data.data));
        dispatch(totalAnnounceRecords(data.data.meta.total));

        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//get details
export function getAnnouncementDetails(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return AnnouncementService.getAnnouncementDetail(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async data => {
        dispatch(getAnnounceDetailId(data.data.data));

        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
// add anouncement
export function addAnnouncement(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return AnnouncementService.addAnnouncement(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        toast.success(res.data.message);
        dispatch(reset("AddAnnouncementForm")); // requires form name

        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
//delete announcement
export function deleteAnnouncement(data, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return AnnouncementService.deleteAnnouncement(id, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        toast.success(res.data.message);
        dispatch(getAnnouncementList(data));
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//update
export function updateAnnouncement(data, id, history) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return AnnouncementService.updateAnnouncement(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        toast.success(res.data.message);
        history.push("announcement-list");
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//send notification

export function sendAnnouncementNotification(data, id, history) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return AnnouncementService.sendAnnouncement(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        toast.success(res.data.message);
        // history.push("announcement-list");
        dispatch(stopLoading());
      })
      .catch(error => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
