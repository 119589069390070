import { SwapServices } from "../../Services/swapServices";
import { startLoading, stopLoading } from "./loading.action";

import { toast } from "../../components/Toast/Toast";
export const actionTypes = {
  GET_SWAP_LIST: "GET_SWAP_LIST",
  SAVE_SWAP_GRAPH: "SAVE_SWAP_GRAPH"
};

/*
 * Select/change Security option
 */
export function saveSwapList(payload) {
  return {
    type: actionTypes.GET_SWAP_LIST,
    data: payload
  };
}

export function saveSwapGraph(status) {
  return {
    type: actionTypes.SAVE_SWAP_GRAPH,
    swap_data: status
  };
}

export function getAllSwapList(data) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return SwapServices.getSwapList(data, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async res => {
        console.log("WithdrawList1", res.data);
        dispatch(saveSwapList(res.data));
        dispatch(stopLoading());
      })
      .catch(error => {
        console.log("ERROR", error);
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function SwapDetailsGraph(type) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return SwapServices.getSwapGraph(type, {
      jwt: state["persist"]["c_temp_user"]["token"]
    })
      .then(async data => {
        console.log("getGraph", data);
        dispatch(stopLoading());
        console.log("ZZZZZZZ", data.data);
        dispatch(saveSwapGraph(data.data));
      })
      .catch(error => {
        console.log("ERROR", error);
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
