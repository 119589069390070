import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { Pagination, Confirm, Form, Dropdown } from "semantic-ui-react";
import "./WithdrawList.scss";
import CustomHeader from "../../../components/Headers/CustomHeader";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router";
import {
  getAllWithdrawListCSV,
  getAllWithdrawList,
  approveDisapproveRequest,
  withdrawComment,
  getUserTotalBalance,
} from "../../../Redux/actons/withdraw.action";
import Security from "../../SubAdminManagement/AddNewSubAdmin/Security";
import { listDateFormat } from "../../../utils/utils";
import { Link } from "react-router-dom";
import { toast } from "../../../components/Toast/Toast";
import {
  API_HOST,
  BTC_TXN_BTC,
  ETH_TXN_ETH,
  BSC_TXN_BSC,
  BTC_ADDRESS_BTC,
  ETH_ADDRESS_ETH,
  BSC_ADDRESS_BSC,
  MATIC_ADDRESS_MATIC,
  MATIC_TXN_MATIC,
} from "../../../constants/index";
import {
  getCoinListForFilter,
  userDetail,
} from "../../../Redux/actons/userList.action";
import Header from "components/Headers/Header";
import UserDetail from "../../UserManagement/User/UserDetail";
import AddComment from "./AddComment";
import Download from "./CommonFiles/Download";
// import Download from "./views/CommonFiles/Download";
import MetaMaskConnection from "components/WalletConnection/MetaMaskConnection";
import TronConnection from "components/WalletConnection/TronConnection";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

// 'pending','complete','rejected'

const filter = [
  { key: "p", text: "None", value: "none" },
  { key: "s", text: "Pending", value: "pending" },
  { key: "k", text: "Complete", value: "complete" },
  { key: "n", text: "Rejected", value: "rejected" },
];

const Coinfilter = [{ key: "p", text: "None", value: "none" }];

export class WithdrawList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "desc",
      sortBy: "",
      openConfirm: false,
      action: "",
      transId: "",
      showDetails: false,
      tx_details: {},
      showComment: false,
      comment: "",
      user_id: "",
      coin: "",
      type: "",
      coin_family: "",
      coin_type: [],
      coin_symbol: "",
      showTotalBalance: false,
      rejectCommentModal: false,
      downloadExcel: false,
      download_url: "",
      filterData: {},
    };
  }
  toggleModal = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  toggleShowTotalBalance = () => {
    this.setState({ showTotalBalance: !this.state.showTotalBalance });
  };

  openCommentModal = (data) => {
    this.setState({
      showComment: !this.state.showComment,
      user_id: data.user_id,
    });
  };

  openbalanceModal = () => {
    this.setState({
      showTotalBalance: !this.state.showTotalBalance,
    });
  };

  toggleCommentModal = () => {
    this.setState({ showComment: !this.state.showComment });
  };
  componentDidMount = () => {
    this.props.getCoinList();
    this.getWithdrawList();
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.coinListCoinFilter !== this.props.coinListCoinFilter) {
      nextProps.coinListCoinFilter.forEach((element) => {
        Coinfilter.push(element);
      });
    }
  };

  redirectToHashSite = (coin, hash) => {
    if (coin == "btc") {
      // window.open(`https://etherscan.io/tx/${hash}`, "_blank");

      window.open(`${BTC_TXN_BTC}/${hash}`, "_blank");
    }
    if (coin == "eth" || coin == "ida" || coin == "orbs") {
      // window.open(
      //   `https://live.blockcypher.com/btc-testnet/tx/${hash}`,
      //   "_blank"
      // );
      window.open(`${ETH_TXN_ETH}/${hash}`, "_blank");
    }
    if (coin == "bnb" || coin == "busd" || coin == "rena") {
      // window.open(
      //   `https://live.blockcypher.com/btc-testnet/tx/${hash}`,
      //   "_blank"
      // );
      window.open(`${BSC_TXN_BSC}/${hash}`, "_blank");
    }
    if (coin == "bnb" || coin == "busd" || coin == "rena") {
      // window.open(
      //   `https://live.blockcypher.com/btc-testnet/tx/${hash}`,
      //   "_blank"
      // );
      window.open(`${BSC_TXN_BSC}/${hash}`, "_blank");
    }
    if (coin == "matic") {
      window.open(`${MATIC_TXN_MATIC}/${hash}`, "_blank");
    }
  };

  redirectUrl = (coin, address) => {
    if (coin == "btc") {
      window.open(`${BTC_ADDRESS_BTC}/${address}`, "_blank");
    }
    if (coin == "eth" || coin == "ida" || coin == "orbs") {
      window.open(`${ETH_ADDRESS_ETH}/${address}`, "_blank");
    }
    if (coin == "bnb" || coin == "busd" || coin == "rena") {
      // window.open(
      //   `https://live.blockcypher.com/btc-testnet/tx/${hash}`,
      //   "_blank"
      // );
      window.open(`${BSC_ADDRESS_BSC}/${address}`, "_blank");
    }
    if (coin == "matic") {
      window.open(`${MATIC_ADDRESS_MATIC}/${address}`, "_blank");
    }
  };

  showDetails = (tx) => {
    this.setState({ tx_details: tx }, () => {
      this.toggleModal();
    });
  };
  getWithdrawList = () => {
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      order: this.state.order,
      coin_type: this.state.coin_type,
      coin_family: "",
    };
    var fData = {
      coin_type: this.state.coin_type,
      from_date: "",
      to_date: "",
      status: this.state.type,
    };
    this.props.getAllWithdrawList(data, fData);
  };

  getTotalBalance = (data) => {
    const param = {
      id: data,
    };
    this.props.getUserTotalBalance(param).then(() => {
      this.openbalanceModal();
    });
  };

  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getWithdrawList();
    });
  };
  filterBy = (e, data) => {
    if (data.value !== "none") {
      this.setState({ type: data.value }, () => {
        this.getWithdrawList();
      });
    } else {
      this.setState({ type: "" }, () => {
        this.getWithdrawList();
      });
    }
  };

  filterCoinBy = (e, data) => {
    if (data.value !== "none") {
      let coin_type = [];
      coin_type.push(data.value);
      this.setState({ coin_type: coin_type }, () => {
        this.getWithdrawList();
      });
    } else {
      this.setState({ coin_type: [] }, () => {
        this.getWithdrawList();
      });
    }
  };
  //page change
  changeApprovedPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getWithdrawList();
    });
  };
  //search
  onSearchWithdraw = (abc) => {
    // this.setState({ searchKey: abc, page: 1 }, () => {
    this.getWithdrawList();
    // });
  };
  openConfirmApproveModal = (
    id,
    transId,
    coin_symbol,
    coin_family,
    token_address
  ) => {
    if (id == 1) {
      this.setState({
        openConfirm: true,
        action: id,
        transId: transId,
        coin: token_address,
        coin_symbol: coin_symbol,
        coin_family: coin_family,
      });
    } else {
      this.setState({
        rejectCommentModal: true,
        action: id,
        transId: transId,
        coin: token_address,
        coin_symbol: coin_symbol,
        coin_family: coin_family,
      });
    }
  };
  close = () => {
    this.setState({ openConfirm: false });
  };
  onConfirmation = (values = {}) => {
    let data = {
      id: this.state.transId,
      action: this.state.action,
      coin: this.state.coin,
      coin_family: this.state.coin_family,
      coin_symbol: this.state.coin_symbol,
    };
    if (values.message !== undefined && values.message !== "") {
      data.rejection_comment = values.message;
    }
    let dataS = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      order: this.state.order,

      coin_type: this.state.coin_type,
    };
    var fData = {
      coin_type: this.state.coin_type,
      from_date: "",
      to_date: "",
      status: this.state.type,
    };
    this.props.approveDisapproveRequest(data, dataS, fData);
    if (this.state.action == 1) {
      this.setState({ openConfirm: false });
    } else {
      this.setState({ rejectCommentModal: false });
    }
  };
  setMessage = (e) => {
    this.setState({ comment: e.target.value });
  };

  //Download Button Action
  downloadTradeHistory = (data) => {
    let filterData = {
      user_id: data.user_id,
    };

    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: `${API_HOST}api/v1/admin/users/tradeHistoryDownload`,
      filterData: filterData,
    });
  };

  sendMesage = () => {
    if (this.state.comment !== "") {
      this.setState({ showComment: false });
      let data = {
        comment: this.state.comment,
        user_id: this.state.user_id,
      };
      this.props.withdrawComment(data);
    } else {
      toast.error("Please enter comment");
    }
  };

  downLoadUserList = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      page: 1,
      perPage: this.props.totalRecords,
    };
    this.props.getAllWithdrawListCSV(data);
    // this.setState({
    //   downloadExcel: !this.state.downloadExcel,
    //   download_url: `${API_HOST}api/v1/admin/withdraw/download/list`,
    //   filterData: filterData,
    // });
    // window.location.href = `${API_HOST}api/v1/admin/withdraw/download/list`;
  };

  closeDownloadExcel = () => {
    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: "",
      filterData: {},
    });
  };

  detailModalToggle = (userData) => {
    this.props.userDetail({ user_id: userData });
    this.setState({ detailModal: !this.state.detailModal });
  };
  clostDetailModal = () => {
    this.setState({ detailModal: !this.state.detailModal });
  };
  closeRejectionModal = () => {
    this.setState({ rejectCommentModal: !this.state.rejectCommentModal });
  };

  render() {
    let { handleSubmit, statusType } = this.props;
    return (
      <div>
        {/* <Header headerTitle="withdrawal management" /> */}
        {/* <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="transaction-fee-management">
                Transaction Management
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Withdrawal Management</BreadcrumbItem>
          </Breadcrumb>
        </div> */}
        <Security />
        {/* approve disaprove confirm */}
        <div>
          <Confirm
            content={`Are you sure to ${
              this.state.action == 1 ? "Approve" : "Disapprove"
            }`}
            style={{
              height: "auto",
              top: "5%",
              left: "auto",
              bottom: "auto",
              right: "auto",
            }}
            size="small"
            className="confirm-model"
            open={this.state.openConfirm}
            onCancel={this.close}
            onConfirm={this.onConfirmation}
          />
        </div>

        {!this.props.openSecurityModal && (
          <div>
            <Container className="contentBlock" fluid>
              {/* Table */}

              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div style={{ width: "100%" }}>
                        <h3 className="mb-2">Withdraw List</h3>
                      </div>
                      <div className="selectSearchOption">
                        <Input
                          placeholder="Search"
                          onChange={(e) => {
                            this.setState(
                              { searchKey: e.target.value, page: 1 },
                              () => {
                                if (this.state.searchKey == "") {
                                  this.onSearchWithdraw();
                                }
                              }
                            );
                          }}
                        ></Input>
                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.onSearchWithdraw()}
                        >
                          Search
                        </Button>

                        <Dropdown
                          className="selectUserStyle"
                          options={options}
                          selection
                          value={this.state.order}
                          onChange={(e, data) => this.sortBy(e, data)}
                        />
                        <Dropdown
                          className="selectUserStyle"
                          options={filter}
                          placeholder="Filter by Status"
                          selection
                          onChange={(e, data) => this.filterBy(e, data)}
                        />
                        <Dropdown
                          className="selectUserStyle"
                          options={Coinfilter}
                          placeholder="Filter by Coin"
                          selection
                          onChange={(e, data) => this.filterCoinBy(e, data)}
                        />
                        {/* <Link to="hot-wallet-funds">
                        <Button color="success btnMamagePermission">
                          Hot wallet Funds
                        </Button>
                      </Link> */}
                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.downLoadUserList()}
                        >
                          Download Withdraw list
                        </Button>
                      </div>
                      {/* <div className="selectSearchOption">
                        <div>
                          <MetaMaskConnection />
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                          <TronConnection />
                        </div>
                      </div> */}
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">ID(Email)</th>
                          <th scope="col">Asset</th>

                          <th scope="col">Amount</th>
                          <th scope="col">To</th>
                          {/* <th scope="col">Commssion</th> */}

                          <th scope="col">Status</th>
                          <th scope="col">TXID</th>

                          <th scope="col">Tx Time</th>

                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.withdrawList.map((usersList, index) => (
                          <tr className="table-tr" key={index}>
                            {/* <td>{usersList.email}</td> */}
                            <td>
                              <Link
                                onClick={() =>
                                  this.detailModalToggle(usersList)
                                }
                              >
                                {usersList.email ? usersList.email : "None"}
                              </Link>
                            </td>
                            <td>{usersList.coin_symbol.toUpperCase()}</td>

                            <td>{usersList.amount}</td>
                            {/* <td title={usersList.to_adrs}>
                            {usersList.to_adrs.slice(0, 12)}..
                          </td> */}
                            <td
                              title={usersList.to_adrs}
                              onClick={() =>
                                this.redirectUrl(
                                  usersList.coin_symbol,
                                  usersList.to_adrs
                                )
                              }
                            >
                              {usersList.to_adrs
                                ? usersList.to_adrs.slice(0, 12) + "..."
                                : "NONE"}
                            </td>
                            {/* <td>{usersList.trnx_fee_eth}</td> */}

                            <td>{usersList.status.toUpperCase()}</td>

                            <td
                              title={usersList.tx_id}
                              onClick={() =>
                                this.redirectToHashSite(
                                  usersList.coin_symbol,
                                  usersList.tx_id
                                )
                              }
                            >
                              {usersList.tx_id
                                ? usersList.tx_id.slice(0, 12) + "..."
                                : "NONE"}
                            </td>

                            <td>{listDateFormat(usersList.updated_at)}</td>

                            <td>
                              {usersList.status != "complete" &&
                                usersList.status != "rejected" && (
                                  <span className="mb-0 text-sm">
                                    <Button
                                      color="success"
                                      onClick={() =>
                                        this.openConfirmApproveModal(
                                          1,
                                          usersList.id,
                                          usersList.coin_symbol,
                                          usersList.coin_family,
                                          usersList.token_address != ""
                                            ? usersList.token_address
                                            : usersList.coin_symbol
                                        )
                                      }
                                    >
                                      Approve
                                    </Button>
                                  </span>
                                )}
                              {usersList.status != "complete" &&
                                usersList.status != "rejected" && (
                                  <span
                                    className="mb-0 text-sm"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <Button
                                      color="warning"
                                      onClick={() =>
                                        this.openConfirmApproveModal(
                                          2,
                                          usersList.id,
                                          usersList.coin_symbol,
                                          usersList.coin_family,
                                          usersList.token_address != ""
                                            ? usersList.token_address
                                            : usersList.coin_symbol
                                        )
                                      }
                                    >
                                      Reject
                                    </Button>
                                  </span>
                                )}

                              <span
                                className="mb-0 text-sm"
                                style={{ marginLeft: "10px" }}
                              >
                                <Button
                                  color="success"
                                  onClick={() => this.showDetails(usersList)}
                                >
                                  Details
                                </Button>
                              </span>
                              <span
                                className="mb-0 text-sm"
                                style={{ marginLeft: "10px" }}
                              >
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.openCommentModal(usersList)
                                  }
                                >
                                  Add Comment
                                </Button>
                              </span>

                              <span
                                className="mb-0 text-sm"
                                style={{ marginLeft: "10px" }}
                              >
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.downloadTradeHistory(usersList)
                                  }
                                >
                                  Download Excel
                                </Button>
                              </span>
                              <span
                                className="mb-0 text-sm"
                                style={{ marginLeft: "10px" }}
                              >
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.getTotalBalance(usersList.user_id)
                                  }
                                >
                                  View total balance
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))}
                        {this.props.withdrawList.length == 0 && (
                          <tr>
                            <td>No record found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        {this.props.totalRecords > 0 && (
                          <Pagination
                            onPageChange={(e, data) =>
                              this.changeApprovedPage(e, data)
                            }
                            boundaryRange={1}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={1}
                            totalPages={Math.ceil(
                              this.props.totalRecords / this.state.perPage
                            )}
                          />
                        )}
                      </nav>
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            </Container>
          </div>
        )}
        {/* detail modal */}
        <Modal
          isOpen={this.state.showDetails}
          toggle={this.toggleModal}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.toggleModal}>
            <h1>
              <span>Transaction Informations</span>
            </h1>
          </ModalHeader>
          <ModalBody>
            <div className="userNameList">
              <div className="itemStyle">
                <label>Sent From:</label>
                <span>{this.state.tx_details.from_adrs}</span>
              </div>
              <div className="itemStyle">
                <label>Sent To:</label>
                <span>{this.state.tx_details.to_adrs}</span>
              </div>
              <div className="itemStyle">
                <label>Transaction fee:</label>
                <span>{this.state.tx_details.trnx_fee_eth}</span>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* add comment modal  */}
        <Modal isOpen={this.state.showComment} toggle={this.toggleCommentModal}>
          <ModalHeader toggle={this.toggleCommentModal}>
            Send Comment
          </ModalHeader>
          <ModalBody>
            <Form>
              <Form.TextArea
                label=" Comment"
                placeholder="Enter Comment..."
                onChange={(e) => this.setMessage(e)}
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ showComment: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.sendMesage()}>
              Send
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.showTotalBalance}
          toggle={this.toggleShowTotalBalance}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.toggleShowTotalBalance}>
            <h1>
              <span>Total Balance</span>
            </h1>
          </ModalHeader>
          <ModalBody>
            <div className="userNameList">
              <div className="itemStyle">
                <label>Current balance:</label>
                <span>
                  {parseFloat(
                    this.props.userTotalBalance?.totalCurrentBalance
                  ).toFixed(2)}
                </span>
              </div>
              <div className="itemStyle">
                <label>Deposit balance:</label>
                <span>
                  {parseFloat(
                    this.props.userTotalBalance?.totalDepositBalance
                  ).toFixed(2)}
                </span>
              </div>
              <div className="itemStyle">
                <label>Withdraw Balance:</label>
                <span>
                  {parseFloat(
                    this.props.userTotalBalance?.totalWithdrowBalance
                  ).toFixed(2)}
                </span>
              </div>
              <div className="itemStyle">
                <label>Crypto Balance Blocked:</label>
                <span>
                  {parseFloat(
                    this.props.userTotalBalance?.userCryptoBalanceBlocked
                  ).toFixed(2)}
                </span>
              </div>
              <div className="itemStyle">
                <label>Crypto Balance Stacked:</label>
                <span>
                  {parseFloat(
                    this.props.userTotalBalance?.userCryptoBalanceStaked
                  ).toFixed(2)}
                </span>
              </div>
              <div className="itemStyle">
                <label>Crypto Balance Locked:</label>
                <span>
                  {parseFloat(
                    this.props.userTotalBalance?.userCryptoBalanceLocked
                  ).toFixed(2)}
                </span>
              </div>
              <div className="itemStyle">
                <label>Note : The balance is shown in current INR value.</label>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <UserDetail
          clostDetailModal={this.clostDetailModal}
          detailModal={this.state.detailModal}
        />
        <AddComment
          openCloseCommentModal={this.closeRejectionModal}
          rejectCommentModal={this.state.rejectCommentModal}
          onConfirmation={this.onConfirmation}
        />
        <Download
          filterData={this.state.filterData}
          closeDownloadExcel={this.closeDownloadExcel}
          downloadExcel={this.state.downloadExcel}
          download_url={this.state.download_url}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    withdrawList: state.withdraw.withdrawList,
    totalRecords: state.withdraw.totalRecords,
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
    coinListCoinFilter: state.userList.coinListCoinFilter,
    openSecurityModal: state.subAdmin.openSecurityModal,
    userTotalBalance: state.withdraw.userTotalBalance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllWithdrawList: (data, fdata) =>
      dispatch(getAllWithdrawList(data, fdata)),
    getAllWithdrawListCSV: (data, fdata) =>
      dispatch(getAllWithdrawListCSV(data, fdata)),
    approveDisapproveRequest: (data, data1, data2) =>
      dispatch(approveDisapproveRequest(data, data1, data2)),
    withdrawComment: (data) => dispatch(withdrawComment(data)),
    getCoinList: () => dispatch(getCoinListForFilter()),
    getUserTotalBalance: (data) => dispatch(getUserTotalBalance(data)),
    userDetail: (data) => dispatch(userDetail(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "ApprovedKyc" })(WithdrawList))
);
