import React from "react";
import { Checkbox } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { listDateFormat } from "../../../utils/utils";
import { toast } from "../../../components/Toast/Toast";

const TableBodyComponent = ({ withdrawList, setlists, lists }) => {
  const [isCheck, setIsCheck] = React.useState(false);
  const selectData = (usersList, index, state) => {
    try {
      // let ArrInfo = {
      //   list:[]
      // }
      let values = [...lists];

      // console.log("iiicccc----- element start", usersList);
      for (let i = 0; i < values.length; i++) {
        // console.log(
        //   values[i],
        //   index,
        //   "index ------------ {19} -----",
        //   values[i].index !== index
        // );
        // console.log(
        //   "index ------------ {25}- contract address",
        //   values[i]?.token_address,
        //   "!= address----",
        //   usersList?.token_address
        // );
        // console.log(
        //   "index ------------",
        //   values[i]?.token_address != usersList?.token_address,
        //   "{31}----- element start"
        // );
        if (
          values[i].index != index &&
          values[i]?.token_address != usersList?.token_address
        ) {
          toast.error(["We can not add different type of coin symbols"]);
          return;
        }
        if (values[i].index == index) {
          values.forEach((element, i) => {
            if (index == element.index) {
              values.splice(i, 1);
            }
          });
          setlists(values);
          return;
        }
      }
      let obj = {
        amount: usersList?.amount,
        to_adrs: usersList?.to_adrs,
        index: index,
        coin_symbol: usersList?.coin_symbol,
        id: usersList?.id,
        coin_family: usersList?.coin_family,
        token_address: usersList?.token_address ? usersList?.token_address : "",
      };
      values.push(obj);
      setlists(values);
      // console.log("iiicccc----- element", values);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <tbody>
      {withdrawList.map((usersList, index) => (
        <tr className="table-tr" key={index}>
          <td>
            {" "}
            {/* {console.log(
              "lists.filter((data)=> data.id == usersList.id).length",
              lists.filter((data) => data.id == usersList.id).length
            )} */}
            <Checkbox
              // label="Metamask Wallet"
              // id=" customCheckLogin"
              checked={
                lists &&
                lists.filter((data) => data.id == usersList.id).length == 1
              }
              onChange={(e, data) => selectData(usersList, index)}
            />
          </td>
          <td>
            <Link
            // onClick={() => this.detailModalToggle(usersList)}
            >
              {usersList.email ? usersList.email : "None"}
            </Link>
          </td>
          <td>{usersList.coin_symbol.toUpperCase()}</td>

          <td>{usersList.amount}</td>
          <td
            title={usersList.to_adrs}
            onClick={() =>
              this.redirectUrl(usersList.coin_symbol, usersList.to_adrs)
            }
          >
            {usersList.to_adrs
              ? usersList.to_adrs.slice(0, 12) + "..."
              : "NONE"}
          </td>
          <td>{usersList.status.toUpperCase()}</td>
          <td
            title={usersList.tx_id}
            onClick={() =>
              this.redirectToHashSite(usersList.coin_symbol, usersList.tx_id)
            }
          >
            {usersList.tx_id ? usersList.tx_id.slice(0, 12) + "..." : "NONE"}
          </td>

          <td>{listDateFormat(usersList.updated_at)}</td>

          <td>
            {/*  {usersList.status != "complete" &&
              usersList.status != "rejected" && (
                <span className="mb-0 text-sm">
                 <Button
                    color="success"
                    onClick={() =>
                      this.openConfirmApproveModal(
                        1,
                        usersList.id,
                        usersList.coin_symbol,
                        usersList.coin_family,
                        usersList.token_address != ""
                          ? usersList.token_address
                          : usersList.coin_symbol
                      )
                    }
                  >
                    Approve
                  </Button>
                </span>
              )} 
            {usersList.status != "complete" &&
              usersList.status != "rejected" && (
                <span className="mb-0 text-sm" style={{ marginLeft: "10px" }}>
                  <Button
                    color="warning"
                    onClick={() =>
                      this.openConfirmApproveModal(
                        2,
                        usersList.id,
                        usersList.coin_symbol,
                        usersList.coin_family,
                        usersList.token_address != ""
                          ? usersList.token_address
                          : usersList.coin_symbol
                      )
                    }
                  >
                    Reject
                  </Button>
                </span>
              )}*/}

            <span className="mb-0 text-sm" style={{ marginLeft: "10px" }}>
              <Button
                color="success"
                onClick={() => this.showDetails(usersList)}
              >
                Details
              </Button>
            </span>
            <span className="mb-0 text-sm" style={{ marginLeft: "10px" }}>
              <Button
                color="success"
                onClick={() => this.openCommentModal(usersList)}
              >
                Add Comment
              </Button>
            </span>

            <span className="mb-0 text-sm" style={{ marginLeft: "10px" }}>
              <Button
                color="success"
                onClick={() => this.downloadTradeHistory(usersList)}
              >
                Download Excel
              </Button>
            </span>
            <span className="mb-0 text-sm" style={{ marginLeft: "10px" }}>
              <Button
                color="success"
                onClick={() => this.getTotalBalance(usersList.user_id)}
              >
                View total balance
              </Button>
            </span>
          </td>
        </tr>
      ))}
      {withdrawList.length == 0 && (
        <tr>
          <td>No record found</td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBodyComponent;
