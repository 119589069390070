import { UserService } from "../../Services/UserServices";
import { startLoading, stopLoading } from "./loading.action";
import { enableDisableGoogle } from "./user.action";
import {
  loginUserPersist,
  logoutUserPersist,
  loginUserTempPersist,
} from "./persist.action";
import { socket } from "../../index";

import { reset } from "redux-form";

import { toast } from "../../components/Toast/Toast";

export const actionTypes = {
  SECURITY_ACTIVE: "SECURITY_ACTIVE",
  GOOGLE_AUTH_PROCESS: "GOOGLE_AUTH_PROCESS",
  GET_SECRET_KEY: "GET_SECRET_KEY",
  ENABLE_GOOGLE_AUTH: "ENABLE_GOOGLE_AUTH",
  SEND_EMAIL_AUTH_TOKEN: "SEND_EMAIL_AUTH_TOKEN",
  SEND_AUTH_TOKEN: "SEND_AUTH_TOKEN",
  GET_SECRET_KEY: "GET_SECRET_KEY",
};

/*
 * Select/change Security option
 */

export function chooseSecurityOption(payload) {
  return {
    type: actionTypes.SECURITY_ACTIVE,
    data: payload,
  };
}

export function gotoGoogleAuthStep(payload) {
  return {
    type: actionTypes.GOOGLE_AUTH_PROCESS,
    data: payload,
  };
}

export function getScrectWithAuthImageSuccess(payload) {
  return {
    type: actionTypes.GET_SECRET_KEY,
    data: payload,
  };
}
export function enableAuthSuccess(payload) {
  return {
    type: actionTypes.ENABLE_DISABLE_GOOGLE_AUTH,
    data: payload,
  };
}

export function getScrectWithAuthImage(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getAuthSecret(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then((user) => {
        dispatch(stopLoading());
        // toast.success(user["data"]["message"]);
        return dispatch(getScrectWithAuthImageSuccess({ user: user["data"] }));
      })
      .catch((error) => {
        if (error) {
          //   toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function enableGoogleAuth(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.enableUserGoogleAuth(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then((user) => {
        toast.success(user["data"]["message"]);

        dispatch(stopLoading());
        dispatch(enableDisableGoogle("enable"));
        dispatch(chooseSecurityOption("password"));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function disableGoogleAuth(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.disableUserGoogleAuth(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then((user) => {
        toast.success(user["data"]["message"]);

        dispatch(stopLoading());
        dispatch(enableDisableGoogle("disable"));
        dispatch(gotoGoogleAuthStep(1));
        dispatch(chooseSecurityOption("password"));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

/** email authentication */

export function emailAuthToken(payload) {
  return {
    type: actionTypes.SEND_EMAIL_AUTH_TOKEN,
    data: payload,
  };
}

export function sendAuthToken(payload) {
  return {
    type: actionTypes.SEND_AUTH_TOKEN,
    data: payload,
  };
}

export function sendEmailAuthToken() {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.sendEmailAuthToken({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then((success) => {
        toast.success(success["data"]["message"]);
        return dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

/** verify auth token */
export function verifyAuthRequest(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    UserService.verifyemailtoken(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then((success) => {
        toast.success(success["data"]["message"]);
        dispatch(stopLoading());

        dispatch(
          loginUserPersist({ token: success.config.headers.Authorization })
        );
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function verifyGoogleAuthRequest(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    UserService.verifyGoogleAuth(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then((success) => {
        dispatch(stopLoading());

        socket.emit("login", { userId: state.user.user.id });

        dispatch(
          loginUserPersist({ token: success.config.headers.Authorization })
        );
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
