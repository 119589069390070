import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { getTradeHistory } from "../../../Redux/actons/user.action";
import { downloadTradeHistory } from "Redux/actons/user.action";
import Header from "components/Headers/Header";
import { withRouter, Link } from "react-router-dom";
import { Pagination, Dropdown } from "semantic-ui-react";
import { listDateFormat } from "../../../utils/utils";
import { API_HOST } from "../../../constants/index";
import Download from "views/CommonFiles/Download";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

const TradeTypeFilter = [
  { key: "o", text: "All", value: "" },
  { key: "c", text: "Buy", value: "buy" },
  { key: "u", text: "Sell", value: "sell" },
];

export class TradeHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
      sortBy: "",
      showDetails: false,
      details: "",
      tradeType: "",
      downloadExcel: false,
      download_url: "",
      filterData: {},
    };
  }
  componentDidMount = () => {
    this.getTradeHistoryList();
  };

  getTradeHistoryList = () => {
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      order: this.state.order,
      search: this.state.searchKey,
      trade_type: this.state.tradeType,
    };
    this.props.getTradeHistoryList(params);
  };

  listByTradeType = (e, data) => {
    if (data.value !== "none") {
      this.setState({ tradeType: data.value }, () => {
        this.getTradeHistoryList();
      });
    } else {
      this.setState({ tradeType: "1" }, () => {
        this.getTradeHistoryList();
      });
    }
  };

  showDetails = (data) => {
    this.setState({ details: data }, () => {
      this.toggleModal();
    });
  };

  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getTradeHistoryList();
    });
  };

  toggleModal = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getTradeHistoryList();
    });
  };

  //Download Button Action
  downloadTradeHistory = (data) => {
    let filterData = {
      user_id: data.user_id,
    };

    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: `${API_HOST}api/v1/admin/users/tradeHistoryDownload`,
      filterData: filterData,
    });
    // window.location.href = `${API_HOST}api/v1/admin/users/tradeHistoryDownload?user_id=${data.user_id}`;
  };

  closeDownloadExcel = () => {
    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: "",
      filterData: {},
    });
  };

  onSearch = (key) => {
    this.getTradeHistoryList();
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Trade History" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="trade-management">Trade Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Trade History</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search List"
                        style={{
                          color: "black",
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearch();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearch()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        className="selectUserStyle"
                        options={TradeTypeFilter}
                        placeholder="Filter"
                        selection
                        onChange={(e, data) => this.listByTradeType(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">EMAIL ID</th>
                        <th scope=" col">FROM</th>
                        <th scope="col">TO</th>
                        <th scope="col">Trade type</th>
                        <th scope="col">Trade amount</th>
                        <th scope="col">Converted Value</th>
                        <th scope="col">status</th>
                        <th scope="col">Date</th>
                        <th scope="col">Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.historyList ? (
                        this.props.historyList.length === 0 ? (
                          <tr>
                            <td>No results found</td>
                          </tr>
                        ) : this.props.historyList ? (
                          this.props.historyList?.map((data, index) => (
                            <tr className="table-tr" key={index}>
                              {/* <td>{usersList.pair_name.toUpperCase()}</td> */}
                              <td>{data?.email}</td>
                              <td>
                                {" "}
                                {data.fromType == "crypto" && data.fromCoin
                                  ? data.fromCoin
                                  : data.fromCurrency}
                              </td>
                              <td>
                                {" "}
                                {data.toType == "crypto" && data.toCoin
                                  ? data.toCoin
                                  : data.toCurrency}
                              </td>
                              <td>{data.tradeType.toUpperCase()}</td>
                              <td>{data.tradeAmount}</td>
                              <td>{data.convertValue}</td>
                              <td>{data.status}</td>
                              <td>{listDateFormat(data.created_at)}</td>
                              <td>
                                <span className="mb-0 text-sm">
                                  <Button
                                    color="success"
                                    onClick={() => this.showDetails(data)}
                                  >
                                    Details
                                  </Button>
                                </span>
                                <span
                                  className="mb-0 text-sm"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <Button
                                    color="success"
                                    onClick={() =>
                                      this.downloadTradeHistory(data)
                                    }
                                  >
                                    Download Excel
                                  </Button>
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          console.log("")
                        )
                      ) : (
                        console.log("")
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.showDetails}
          toggle={this.toggleModal}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.toggleModal}>
            <h1>
              <span>Trade history</span>
            </h1>
          </ModalHeader>
          <ModalBody>
            <div className="userNameList">
              <div className="itemStyle">
                <label>Fee:</label>
                <label>
                  {this.state.details.feeAmount
                    ? this.state.details.feeAmount
                    : "None"}
                </label>
              </div>
              <div className="itemStyle">
                <label>Fee Coin:</label>
                <label>
                  {this.state.details.feeCoin
                    ? this.state.details.feeCoin
                    : "None"}
                </label>
              </div>
              <div className="itemStyle">
                <label>Markup rate:</label>
                <label>
                  {this.state.details.markup_rate
                    ? this.state.details.markup_rate + "%"
                    : "None"}
                </label>
              </div>
              <div className="itemStyle">
                <label>Markup amount:</label>
                <label>
                  {this.state.details.markup_amount
                    ? this.state.details.markup_amount
                    : "None"}
                </label>
              </div>
              <div className="itemStyle">
                <label>Markup coin:</label>
                <label>
                  {" "}
                  {this.state.details.fromType == "fiat" ||
                  this.state.details.toType == "fiat"
                    ? this.state.details.conversionCurrency
                      ? this.state.details.conversionCurrency.toUpperCase()
                      : "-"
                    : this.state.details.toCoin
                    ? this.state.details.toCoin
                    : this.state.details.toCurrency}
                </label>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Download
          filterData={this.state.filterData}
          closeDownloadExcel={this.closeDownloadExcel}
          downloadExcel={this.state.downloadExcel}
          download_url={this.state.download_url}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    historyList: state?.user?.tradeHistoryList?.data?.history,
    total: state?.user?.tradeHistoryList?.data?.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTradeHistoryList: (data) => dispatch(getTradeHistory(data)),
    downloadTradeHistoryList: (data) => dispatch(downloadTradeHistory(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TradeHistory)
);
