import React, { Component } from "react";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import CustomHeader from "components/Headers/CustomHeader";
import {
  getAdminPermissions,
  assignAdminPermissions,
  addSubAdmin,
  openCloseModal,
} from "../../../Redux/actons/SubAdmin.action";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../../components/FormField";
import { required, email } from "redux-form-validators";
import { Link } from "react-router-dom";
import Security from "./Security";
export class AddNewSubAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissions: [],
    };
  }

  componentDidMount = () => {
    this.props.getAdminPermissions();
    this.checkRouteValid();

    // let data = {
    //   permissions: [2]
    // };
    // this.props.assignAdminPermissions(data, 10);
  };
  componentWillUnmount = () => {
    this.props.openCloseModal();
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      var isfound = false;
      // check user Permissions if route accessible
      await this.props.permissionss.forEach((element) => {
        if (element.title == "Subadmin Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };
  addSubAdmin = (values) => {
    values["permissions"] = this.state.permissions;
    this.props.addSubAdmin(values, this.props.history);
  };
  onSelectPermissions = (e, title, id) => {
    let permissions = [...this.state.permissions];
    if (e.target.checked) {
      permissions.push(id);
      this.setState({ permissions: permissions });
    } else {
      for (var i = 0; i < permissions.length; i++) {
        if (permissions[i] == id) {
          permissions.splice(i, 1);
        }
      }
      this.setState({ permissions: permissions });
    }
  };
  render() {
    let { handleSubmit } = this.props;
    console.log(this.state);
    return (
      <div>
        <CustomHeader />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="sub-admin-management">Subadmin Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Add Subadmin</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div style={{ marginTop: 40, marginRight: 40 }}>
          <Link to="sub-admin-management">
            <Button className="float-right" color="success">
              Back
            </Button>
          </Link>
        </div>
        <div style={{ padding: 100 }}>
          <Form onSubmit={handleSubmit(this.addSubAdmin)}>
            <FormGroup>
              <Label className="text-colorB" for="name">
                Name
              </Label>

              <Field
                name="name"
                component={FormField}
                type="text"
                validate={[required()]}
                placeholder="Name"
              />
            </FormGroup>
            <FormGroup>
              <Label className="text-colorB" for="email">
                Email
              </Label>

              <Field
                name="email"
                component={FormField}
                type="email"
                placeholder="Enter email"
                validate={[required(), email()]}
                autoFocus
              />
            </FormGroup>
            <FormGroup>
              <Label>Assign Permissions</Label>
              {this.props.permissions.map((data, index) => (
                <div>
                  <Field
                    className="text-colorB"
                    name={data.title}
                    component={FormField}
                    type="checkbox"
                    label={data.title.toUpperCase()}
                    onChange={(e) =>
                      this.onSelectPermissions(e, data.title, data.id)
                    }
                  />
                  <br />
                </div>
              ))}
            </FormGroup>

            <div className="text-center">
              <Button className="my-4" color="primary" type="submit">
                Add
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.subAdmin.permissions,
    permissionss: state.subAdmin.sideBarSubAdminPermissions,
    user: state.user.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAdminPermissions: () => dispatch(getAdminPermissions()),
    assignAdminPermissions: (data, id) =>
      dispatch(assignAdminPermissions(data, id)),
    addSubAdmin: (data, history) => dispatch(addSubAdmin(data, history)),
    openCloseModal: () => dispatch(openCloseModal(true)),
  };
};

export default reduxForm({ form: "addSubAdminForm" })(
  connect(mapStateToProps, mapDispatchToProps)(AddNewSubAdmin)
);
