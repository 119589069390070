import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const addReferral = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/referral/addReferral`,
    data,
    options
  );
};

const addReferralIncentive = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/referral/addNewIncentive`,
    data,
    options
  );
};

const getReferalList = (data, options) => {
  return fetch("get", `${API_HOST}api/v1/admin/referral/plans`, data, options);
};
const getReferalListMLM = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/referral/get_mlm_list`,
    data,
    options
  );
};
const updateReferralMLM = (data, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/referral/update_mlm`,
    data,
    options
  );
};

const getReferalHistory = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/referral/history`,
    {},
    options,
    data
  );
};

const getIncentiveHistory = (data, id, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/referral/incentives/${id}`,
    {},
    options,
    data
  );
};

const getCoinWiseHistory = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/referral/coinWisehistory`,
    data,
    options
  );
};

const enableDisableReferral = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/referral/changePlanStatus`,
    data,
    options
  );
};

const changeIncentiveStatus = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/referral/changeIncentiveStatus`,
    data,
    options
  );
};

const getInfluencerReferalList = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/influencer/coupon/list`,
    data,
    options
  );
};

const addCoupon = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/influencer/coupon/create`,
    data,
    options
  );
};

const changeInfluencerCouponStatus = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/influencer/coupon/changecouponstatus`,
    data,
    options
  );
};

const getInfluencerIncentiveHistory = (data, id, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/influencer/coupon/incentives/${id}`,
    {},
    options,
    data
  );
};

const addInfluencerIncentive = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/influencer/coupon/addNewIncentive`,
    data,
    options
  );
};

const changeIncentiveAmount = (data, id, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/influencer/coupon/changeIncentiveAmount/${id}`,
    data,
    options
  );
};

const changeInfluencerIncentiveStatus = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/influencer/coupon/changeIncentiveStatus/${data?.id}`,
    data,
    options
  );
};

const getInfluencerHistory = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/influencer/coupon/history`,
    {},
    options,
    data
  );
};

export const ReferralServices = {
  addReferral,
  getReferalList,
  getReferalListMLM,
  updateReferralMLM,
  getReferalHistory,
  getCoinWiseHistory,
  enableDisableReferral,
  getIncentiveHistory,
  changeIncentiveStatus,
  addReferralIncentive,
  getInfluencerReferalList,
  addCoupon,
  changeInfluencerCouponStatus,
  getInfluencerIncentiveHistory,
  addInfluencerIncentive,
  changeIncentiveAmount,
  changeInfluencerIncentiveStatus,
  getInfluencerHistory,
};
