import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
} from "reactstrap";
import { Pagination, Dropdown, Menu } from "semantic-ui-react";
import CustomHeader from "../../components/Headers/CustomHeader";
import { withRouter } from "react-router";
import { listDateFormat } from "../../utils/utils";

import { API_HOST } from "../../constants/index";
import { getAllSwapList } from "../../Redux/actons/swap.action";
import { Link } from "react-router-dom";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
const Swapoptions = [
  { key: "e", text: "None", value: "none" },
  { key: "i", text: "Ida to Idp", value: "1" },
  { key: "p", text: "Idp to Ida", value: "2" },
];
export class SwapManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
      filterType: "",
    };
  }

  componentDidMount = () => {
    this.getAllSwapList();
  };
  filterBy = (e, data) => {
    console.log(data.value);

    this.setState({ order: data.value }, () => {
      this.getAllSwapList();
    });
  };
  filterSwapBy = (e, data) => {
    if (data.value !== "none") {
      this.setState({ filterType: data.value, trnx_type: data.value }, () => {
        this.getAllSwapList();
      });
    } else {
      this.setState({ filterType: "", trnx_type: data.value }, () => {
        this.getAllSwapList();
      });
    }
  };
  getAllSwapList = () => {
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      order: this.state.order,
      filterType: this.state.filterType,
    };
    this.props.getAllSwapList(data);
  };

  //page change
  changeApprovedPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getAllSwapList();
    });
  };
  //search
  onSearchWithdraw = (abc) => {
    console.log(abc);
    this.setState({ searchKey: abc, page: 1 }, () => {
      this.getAllSwapList();
    });
  };

  downLoadUserList = () => {
    window.location.href = `${API_HOST}api/v1/admin/swap/download/list`;
  };

  render() {
    return (
      <div>
        <CustomHeader />
        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Swap Transaction List</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onChange={(e) =>
                          this.onSearchWithdraw(`${e.target.value}`)
                        }
                      ></Input>

                      <Dropdown
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.filterBy(e, data)}
                      />

                      <Dropdown
                        className="selectUserStyle"
                        options={Swapoptions}
                        placeholder="Filter by Swap"
                        selection
                        onChange={(e, data) => this.filterSwapBy(e, data)}
                      />

                      {/* <Link to="swap-ida-to-idp-rate">
                        <Button color="success btnMamagePermission">
                          Swap ida idp rate setting
                        </Button>
                      </Link> */}
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.downLoadUserList()}
                      >
                        Download Swap list
                      </Button>
                    </div>
                  </CardHeader>

                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">ID (Email)</th>
                        <th scope="col">Swap From</th>
                        <th scope="col">Swap To</th>
                        <th scope="col">Amount(From)</th>

                        <th scope="col">Amount(To)</th>

                        <th scope="col">Added On</th>
                        <th scope="col">IP Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.swapList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          {/* <td>{usersList.user.email}</td> */}
                          <Link to="user-management">
                            <td>
                              {usersList.user.email
                                ? usersList.user.email
                                : "None"}
                            </td>
                          </Link>
                          <td>{usersList.swap_from}</td>
                          <td>{usersList.swap_to}</td>
                          <td>{usersList.from_amount}</td>

                          <td>{usersList.amount}</td>

                          <td>{listDateFormat(usersList.created_at)}</td>
                          <td>
                            {usersList.ip_address
                              ? usersList.ip_address
                              : "N/A"}
                          </td>
                        </tr>
                      ))}
                      {this.props.swapList.length == 0 && (
                        <td>No records found</td>
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      {this.props.totalRecords > 0 && (
                        <Pagination
                          onPageChange={(e, data) =>
                            this.changeApprovedPage(e, data)
                          }
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalRecords / this.state.perPage
                          )}
                        />
                      )}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    totalRecords: state.swap.totalRecords,
    swapList: state.swap.swapList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllSwapList: (data) => dispatch(getAllSwapList(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SwapManagement)
);
