import React, { Component } from "react";
import CustomHeader from "components/Headers/CustomHeader";
import { Form, label } from "semantic-ui-react";
import { Button } from "reactstrap";

import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../../components/FormField";
import { SelectField } from "../../../components/SelectField";
import { email, required } from "redux-form-validators";
import {
  getAdminSettings,
  saveFeeSettingDetails,
  updateAdminSetting,
} from "../../../Redux/actons/account.action";
import {
  getCoinLimitSetting,
  setCoinLimitSetting,
} from "../../../Redux/actons/otc.action";
import {
  amountLimit,
  amountValidator,
} from "../../../validators/customValidator";
import { getCoinList } from "../../../Redux/actons/user.action";

import "./OtcLevelSetting.scss";
const options = [
  { key: "m", text: "KYC Level 0", value: "kyc_level_zero_otc_limit" },
  { key: "f", text: "KYC Level 1", value: "kyc_level_one_otc_limit" },
  { key: "k", text: "KYC Level 2", value: "kyc_level_two_otc_limit" },
];

export class OtcLevelSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isType: false,
      defaultType: "",
      defaultAction: 1,
      defaultCoin: "",
    };
  }
  componentDidMount = () => {
    this.checkRouteValid();
    this.props.getCoinList();

    this.props.dispatch(change("OtcSettingForm", "status", 1));
  };
  componentWillUnmount = () => {
    this.props.saveFeeSettingDetails([]);
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      var isfound = false;
      // check user Permissions if route accessible
      await this.props.permissions.forEach((element) => {
        if (element.title == "OTC Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };
  setFormValues = (data) => {
    console.log("DATA", data);
    if (data.length > 0) {
      this.setState({
        defaultType: data[0].type,
        // defaultAction: data[0].status,
        defaultCoin: data[0].coin_id,
        isType: true,
      });
      this.props.dispatch(change("OtcSettingForm", "status", data[0].status));
      this.props.dispatch(change("OtcSettingForm", "value", data[0].value));
    } else {
      this.props.dispatch(change("OtcSettingForm", "status", 1));
      this.props.dispatch(change("OtcSettingForm", "value", ""));
    }
  };
  componentWillReceiveProps = (nextProps) => {
    if (this.props.coinSetting !== nextProps.coinSetting) {
      this.setFormValues(nextProps.coinSetting);
    }
  };
  getDetailsType = (e, data) => {
    this.setState({ defaultAction: 1, defaultType: data });

    let param = {
      type: data,
      coin_id: this.state.defaultCoin,
    };
    // this.props.getAdminSettings(param);
    if (this.state.defaultCoin !== "") {
      this.props.dispatch(change("OtcSettingForm", "value", ""));

      this.setState({ isType: true });

      this.props.getCoinLimitSetting(param);
    }
  };
  getDetailsCoin = (e, data) => {
    this.setState({ defaultCoin: data });
    let param = {
      type: this.state.defaultType,
      coin_id: data,
    };
    // this.props.getAdminSettings(param);
    if (this.state.defaultType !== "") {
      this.props.dispatch(change("OtcSettingForm", "value", ""));

      this.setState({ isType: true });
      this.props.getCoinLimitSetting(param);
    }
  };
  setStatus = (e, data) => {
    this.props.dispatch(change("OtcSettingForm", "status", data));
    this.setState({ isType: true, defaultAction: data });
  };

  updateForm = (values) => {
    console.log("calue", values);
    values["status"] = 1;
    // this.props.updateAdminSetting(values);
    this.props.setCoinLimitSetting(values);
  };
  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <CustomHeader />
        <div style={{ padding: 30 }}>
          <Form onSubmit={handleSubmit(this.updateForm)}>
            <Form.Group>
              <Form.Field>
                <label>Choose Type</label>
                <Field
                  name="type"
                  search={false}
                  selectOnBlur={false}
                  validate={[required()]}
                  component={SelectField}
                  defaultValue={this.state.defaultType}
                  children={options}
                  placeholder="Select KYC Level "
                  onChange={(e, data) => this.getDetailsType(e, data)}
                ></Field>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Select Coin </label>
                <Field
                  name="coin_id"
                  search={false}
                  selectOnBlur={false}
                  validate={[required()]}
                  component={SelectField}
                  defaultValue={this.state.defaultCoin}
                  children={this.props.coinsList}
                  placeholder="Select "
                  onChange={(e, data) => this.getDetailsCoin(e, data)}
                ></Field>
              </Form.Field>
            </Form.Group>
            {this.state.isType && (
              <Form.Group>
                <Form.Field>
                  <label>Limit</label>
                  <Field
                    type="number"
                    name="value"
                    validate={[required(), amountLimit()]}
                    component={FormField}
                    placeholder="amount"
                  ></Field>
                </Form.Field>
              </Form.Group>
            )}
            {this.state.isType && (
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    feeSettingDetails: state.account.feeSettingDetails,
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
    coinsList: state.user.coinsList,
    coinSetting: state.otc.coinSetting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAdminSettings: (data) => dispatch(getAdminSettings(data)),
    updateAdminSetting: (data) => dispatch(updateAdminSetting(data)),
    saveFeeSettingDetails: (data) => dispatch(saveFeeSettingDetails(data)),
    getCoinList: () => dispatch(getCoinList()),

    getCoinLimitSetting: (data) => dispatch(getCoinLimitSetting(data)),
    setCoinLimitSetting: (data) => dispatch(setCoinLimitSetting(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "OtcSettingForm" })(OtcLevelSetting));
