import { fetch } from "./Fetch";
import { API_HOST, API_LOCAL_HOST } from "../constants/index";
// import { addScratch } from "../Redux/actons/reward.action";

const addScratch = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/scratch/addScratchCardReward`,
    data,
    options
  );
};

const getScratchList = (params, options) => {
  return fetch(
    "get",
    //   `${API_LOCAL_HOST}api/v1/admin/trade/pair-list?page=${data.page}&perPage=${data.perPage}&searchKey=${data.searchKey}`
    `${API_HOST}api/v1/admin/scratch/scratchCardsList`,
    {},
    options,
    params
  );
};

const enableDisableScratch = (data, id, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/scratch/changeScratchCardStatus/${id}`,
    data,
    options
  );
};

const editRewardScratch = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/scratch/changeScratchCardAmount/${data.id}`,
    data,
    options
  );
};
const addReward = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/reward/addReward`,
    data,
    options
  );
};

const getRewardList = (params, options) => {
  return fetch(
    "get",
    //   `${API_LOCAL_HOST}api/v1/admin/trade/pair-list?page=${data.page}&perPage=${data.perPage}&searchKey=${data.searchKey}`
    `${API_HOST}api/v1/admin/reward/plans`,
    {},
    options,
    params
  );
};

const enableDisableReward = (data, id, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/reward/changePlanStatus/${id}`,
    data,
    options
  );
};

const editRewardAmount = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/reward/changePlanAmount/${data.id}`,
    data,
    options
  );
};

const getIncentiveList = (params, options) => {
  let id = params.id;
  delete params.id;
  return fetch(
    "get",
    //   `${API_LOCAL_HOST}api/v1/admin/trade/pair-list?page=${data.page}&perPage=${data.perPage}&searchKey=${data.searchKey}`
    `${API_HOST}api/v1/admin/reward/incentives/${id}`,
    {},
    options,
    params
  );
};

const changeIncentiveStatus = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/reward/changeIncentiveStatus/${data.id}`,
    data,
    options
  );
};

const addIncentive = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/reward/addNewIncentive`,
    data,
    options
  );
};

const changeIncentiveAmount = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/reward/changeIncentiveAmount/${data.id}`,
    data,
    options
  );
};

const getScratchedHistory = (params, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/scratch/history`,
    {},
    options,
    params
  );
};
const getRewardHistory = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/reward/history`,
    {},
    options,
    params
  );
};
const sendScratchCard = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/scratch/sendScratchCard`,
    data,
    options
  );
};

export const RewardServices = {
  addScratch,
  getScratchList,
  enableDisableScratch,
  editRewardScratch,
  addReward,
  getRewardList,
  enableDisableReward,
  editRewardAmount,
  getIncentiveList,
  changeIncentiveStatus,
  addIncentive,
  changeIncentiveAmount,
  getScratchedHistory,
  getRewardHistory,
  sendScratchCard,
};
