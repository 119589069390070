import { InstallmentsServices } from "../../Services/InstallmentsServices";
import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../components/Toast/Toast";
import moment from "moment";

function mapElementToColumns(fieldNames) {
  fieldNames.forEach((el, i) => {
    if (el == "idp_withdraw_data") {
      fieldNames.splice(i, 1);
    }
  });
  // console.log(" --------- typeof ", fieldNames); // idp_withdraw_data
  return function (element) {
    let fields = fieldNames.map((n, i) => {
      let jsonData = [];
      let dataobj;
      if (n == "wallet_addresses") {
        // jsonData = JSON.stringify(element[n]);
        jsonData = element?.wallet_addresses;
        if (jsonData.length > 0) {
          dataobj = Object?.keys(jsonData[0]);
        }
      }
      let jonmsAddress = jsonData.map((nd, i) => {
        // console.log(" ------ nd", nd);
        return `${nd.coin_name} : ${nd.address}`;
      });
      // console.log(" ------ nd", jonmsAddress);
      return element[n]
        ? typeof element[n] == "object"
          ? n == "wallet_addresses"
            ? JSON.stringify(jonmsAddress)
            : "NA"
          : JSON.stringify(element[n])
        : '""';
    });
    return fields.join(",");
  };
}
export var DownLoadCSVFunction = async (arrayOfObjects, name) => {
  let jsontxt = JSON?.stringify(arrayOfObjects);
  console.log(
    arrayOfObjects?.length,
    "arrayOfObjects",
    Array.isArray(mythings)
  );
  let csvContent;
  let mythings = JSON.parse(jsontxt);
  if (!Array.isArray(mythings)) throw "Oooops, stranger things happen!";
  let fieldnames = Object.keys(mythings[0]);
  let csvtxt = await mythings.map(mapElementToColumns(fieldnames)).join("\n");

  csvContent = fieldnames.join(",") + "\n" + csvtxt;

  const blob = new Blob([csvContent], {
    type: "text/csv;charset=UTF-8",
  });
  var csvURL = window.URL.createObjectURL(blob);
  var tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute(
    "download",
    `${name}_` + moment().format("DD-MM-YYYY") + ".csv"
  );
  tempLink.click();
};

export const actionTypes = {
  INSTALMENT_LIST: "INSTALMENT_LIST",
  SAVE_VIEW_TERMINATE_PLAN: "SAVE_VIEW_TERMINATE_PLAN",
  USER_INSTALMENT_LIST: "USER_INSTALMENT_LIST",
  GetFee_List: "GetFee_List",
  GetWithdraw_Limit: "GetWithdraw_Limit",
};

export function saveInstalments(data) {
  return {
    type: actionTypes.INSTALMENT_LIST,
    data: data,
  };
}
export function saveviewTerminatePlan(data) {
  return {
    type: actionTypes.SAVE_VIEW_TERMINATE_PLAN,
    data: data,
  };
}

export function GetCreditFee(data) {
  return {
    type: actionTypes.GetFee_List,
    data: data,
  };
}
export function GetWithdrawLimit(data) {
  return {
    type: actionTypes.GetWithdraw_Limit,
    data: data,
  };
}

export function saveInstalmentHistory(data) {
  return {
    type: actionTypes.USER_INSTALMENT_LIST,
    data: data,
  };
}

export function addPlan(data, param) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.addPlan(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(getInstalmentList(param));
        toast.success(data.data.message);
      })
      .catch((error) => {
        if (error) {
          // toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function addPlanTaralGold(data, param) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.addPlanTaralGold(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(getInstalmentList(param));
        toast.success(data.data.message);
      })
      .catch((error) => {
        if (error) {
          // toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function editPlanTaralGold(data, param, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.editPlanTaralGold(
      data,
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      },
      id
    )
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(getInstalmentList(param));
        toast.success(data.data.message);
      })
      .catch((error) => {
        if (error) {
          // toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getInstalmentList(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.getInstalmentList(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveInstalments(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getInstalmentListCSV(data) {
  return (dispatch, getState) => {
    // dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.getInstalmentList(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        let arrayOfObjects = data.data.data.history;
        if (!arrayOfObjects.length) {
          return;
        }
        DownLoadCSVFunction(arrayOfObjects, "taral_gold_plan_list");
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function addCriditLimitFee(data, param) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.addFee(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(getFeeList(param));
        toast.success(data.data.message);
      })
      .catch((error) => {
        if (error) {
          // toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function addUpdateWithdraw(data, param) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.addWithdraw(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(getWithdrawFeeList(param));
        toast.success(data.data.message);
      })
      .catch((error) => {
        if (error) {
          // toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getFeeList(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.getFeeList()
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(GetCreditFee(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getWithdrawFeeList(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.getWithdrawFeeList()
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(GetWithdrawLimit(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function userInstalmentHistory(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.userInstalmentHistory(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        dispatch(saveInstalmentHistory(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function enableDisabelLock(data, id, param) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.enableDisableLock(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getInstalmentList(param));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function viewTerminatePlan(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.viewTerminatePlan(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        // dispatch(saveviewTerminatePlan(param));
        dispatch(saveviewTerminatePlan(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function terminatePlan(data, id, param) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.terminatePlan(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getInstalmentList(param));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function update_description(data, id, param) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return InstallmentsServices.update_description(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getInstalmentList(param));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
