import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  userUnstaked,
  userStakInterestList,
  deleteInterestPair,
} from "../../../Redux/actons/interest.action";
import Input from "reactstrap/lib/Input";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Pagination, Confirm, Dropdown } from "semantic-ui-react";
const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];

const Filteroptions = [{ key: "e", text: "None", value: "none" }];
export class InterestUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      openConfirm: false,
      itemId: "",
    };
  }
  componentDidMount = () => {
    this.userStakInterestList();
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.userStakInterestList();
    });
  };
  userStakInterestList = () => {
    const data = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      order: this.state.order,
      // coin_id: this.state.selectedCoin,
      // status: "unstaked",
    };
    this.props.userStakInterestList(data);
  };

  onSearchList = (search) => {
    this.setState({ page: 1 }, () => {
      this.userStakInterestList();
    });
  };

  orderBy = (e, data) => {
    this.setState({ order: data.value, page: 1 }, () => {
      this.userStakInterestList();
    });
  };

  filterByCoin = (e, data) => {
    this.setState({ selectedCoin: data.value, page: 1 }, () => {
      this.userStakInterestList();
    });
  };

  filterByStatus = (e, data) => {
    this.setState({ status: data.value, page: 1 }, () => {
      this.userStakInterestList();
    });
  };
  unstakePop = (data) => {};
  unstake = (data) => {
    const data1 = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
      order: this.state.order,
      coin_id: this.state.selectedCoin,
      // status: "unstaked",
    };
    this.props.userUnstaked(this.state.itemId, data1);
    this.close();
    // this.props.history.push({
    //   pathname: `edit-interest`,
    //   state: data,
    // });
  };
  onEditPair = (id, data) => {
    this.props.history.push({
      pathname: `edit-interest/${id}`,
      state: data,
    });
  };

  openDeleteModal = (id) => {
    this.setState({
      openConfirm: true,
      itemId: id,
    });
  };

  close = () => {
    this.setState({ openConfirm: false });
  };

  // onConfirmation = () => {
  //   this.props.deleteInterestPair(this.state.itemId);
  //   this.close();
  // };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="User Interest Management" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="interest-management">Interest Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>User Interest List</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Confirm
            content={`Are you sure to Unstake this user interest?`}
            style={{
              height: "auto",
              top: "5%",
              left: "auto",
              bottom: "auto",
              right: "auto",
            }}
            size="small"
            className="confirm-model"
            open={this.state.openConfirm}
            onCancel={this.close}
            onConfirm={this.unstake}
          />
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      {/* <Dropdown
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.orderBy(e, data)}
                      /> */}
                      {/* <Dropdown
                        className="selectUserStyle mx-2"
                        options={Filteroptions}
                        placeholder="Filter by Coin"
                        selection
                        onChange={(e, data) => this.filterByCoin(e, data)}
                      /> */}
                      {/* <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      /> */}

                      {/* <Link to="add-interest">
                        <Button color="success btnMamagePermission">
                          Add Interest
                        </Button>
                      </Link> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Currency</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Total Interest</th>
                        <th scope="col">Duration</th>
                        <th scope="col">View Report</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.InterestList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.InterestList &&
                        this.props.InterestList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data?.coin_name}</td>
                            <td>{data?.email}</td>
                            <td>{data?.status}</td>
                            <td>{data?.amount}</td>
                            <td>{data?.total_interest_amount}</td>
                            <td>
                              {data?.time_period} {data?.time_period_unit}
                            </td>
                            <td>
                              <Link
                                to={`view-user-interest-history?${data.id}`}
                              >
                                <Button
                                  color="primary"
                                  // onClick={() =>
                                  //   this.onEditPair(data.coin_id, data.coin_id)
                                  // }
                                >
                                  View Daily History
                                </Button>
                              </Link>
                            </td>
                            <td>
                              <Button
                                color="primary"
                                onClick={() => this.openDeleteModal(data)}
                              >
                                Unstake
                              </Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(this.props.total)}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    InterestList: state?.currencyInterest?.stakeInterestList,
    total: state?.currencyInterest?.stakeInterestList1?.totalPages,
    totalRecords: state?.currencyInterest?.stakeInterestList1?.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userStakInterestList: (data) => dispatch(userStakInterestList(data)),
    userUnstaked: (data) => dispatch(userUnstaked(data)),
    deleteInterestPair: (data) => dispatch(deleteInterestPair(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InterestUserList)
);
