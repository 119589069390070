import { fetch } from "./Fetch";
import { API_HOST, API_LOCAL_HOST } from "../constants/index";

const addPlan = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/instalment/add-new-plan`,
    data,
    options
  );
};
const addPlanTaralGold = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/instalment/add-new-planV2`,
    data,
    options
  );
};
const editPlanTaralGold = (data, options, id) => {
  console.log(" add savePlanData editPlanTaralGold ", id);
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/instalment/editPlanV2/${id}`,
    data,
    options
  );
};
const getInstalmentList = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/instalment/plan-list`,
    {},
    options,
    data
  );
};
const addFee = (data, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/instalment/add_fee`,
    data,
    options
  );
};
const addWithdraw = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/add_update_withdraw`,
    data,
    options
  );
};

const getFeeList = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/instalment/get_fee`,
    {},
    options,
    data
  );
};
const getWithdrawFeeList = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/get_withdraw_limit`,
    {},
    options,
    data
  );
};

const enableDisableLock = (data, id, token) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/instalment/change-plan-status/${id}`,
    data,
    token
  );
};

const userInstalmentHistory12 = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/instalment/history?page=${data.page}&perPage=${data.perPage}&order=${data.order}&coin_id=${data.coin_id}&status=${data.status}&search=${data.searchKey}&plan_id=${data.plan_id}`,
    data,
    options
  );
};

const userInstalmentHistory = (data, options) => {
  // userInstalmentHistory12(data, options);
  console.log("coin_name ----", data);
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/instalment/user_subscription_list?page=${data.page}&perPage=${data.perPage}&order=${data.order}&coin_id=${data.coin_name}&status=${data.status}&searchKey=${data.searchKey}&plan_id=${data.plan_id}`,
    data,
    options
  );
};
const terminatePlan = (data, id, token) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/instalment/terminateV2/${id}`,
    data,
    token
  );
};
const viewTerminatePlan = (data, token) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/instalment/view_subscription?id=${data}`,
    data,
    token
  );
};

const update_description = (data, id, token) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/instalment/update_description/${id}`,
    data,
    token
  );
};

export const InstallmentsServices = {
  addPlan,
  addPlanTaralGold,
  editPlanTaralGold,
  addFee,
  addWithdraw,
  getFeeList,
  getWithdrawFeeList,
  getInstalmentList,
  enableDisableLock,
  userInstalmentHistory,
  terminatePlan,
  viewTerminatePlan,
  update_description,
};
