import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../components/FormField";
import { email, required } from "redux-form-validators";
import logo from "../../images/Taral.png";
import HidePassword from '../../images/eye_close.png';
import showPassword from '../../images/eye_open.png'

import "./LoginForm.scss";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword:false
    };
  }
  componentDidMount = () => {
    let { initialize } = this.props;
    var user = JSON.parse(sessionStorage.getItem("savedUser"));
    if (user) {
      initialize({
        email: user.email,
        password: user.password,
        remember: user.remember,
      });
    }
  };

  // handleSubmit = () => {
  //   this.props.history.push("/auth/google-auth-form");
  // };
  // forgotPassword = () => {
  //   this.props.history.push("/auth/forgot-password");
  // };

  render() {
    let { handleSubmit } = this.props;

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0 loginBlock">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <img src={logo} />
                <h3>Taral Admin Login</h3>
              </div>
              <Form onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Field
                      name="email"
                      component={FormField}
                      type="email"
                      placeholder="Enter email"
                      validate={[required(), email()]}
                      autoFocus
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Field
                      name="password"
                      component={FormField}
                      type={this.state.showPassword?'text':'password'}
                      validate={[required()]}
                      placeholder="Password"
                    />
                  </InputGroup>
                </FormGroup>
                <a
                      style={{ "position": "absolute" ,"top": "58%","transform": "translateY(-50%)" ,"right": "50px",
                        "font-size": 0}}
                      onClick={() => this.setState({
                        showPassword:!this.state.showPassword
                      })}
                    >
                      {this.state.showPassword ? (
                        <img style={{ "width": "18px", "height": "18px"}} className="light_icon" src={showPassword} />
                      ) : (
                        <img style={{ "width": "18px", "height": "18px"}} className="blue_icon" src={HidePassword} />
                      )}
                    </a>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>

                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Login
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default reduxForm({ form: "LoginForm" })(LoginForm);
