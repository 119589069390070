import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import {
  getTxnWalletThresholdValue,
  updateTxnWalletThresholdValues,
} from "../../Redux/actons/transaction.action";
import { Form } from "semantic-ui-react";
import { Field, reduxForm, change } from "redux-form";
import { FormField } from "../../components/FormField";
import { required } from "redux-form-validators";
// import { amountLimit } from "../../validators/customValidator";

export class SetThresholdWalletValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thresholdvalues: [],
    };
    this.updateForm = this.updateForm.bind(this);
  }
  componentDidMount = async () => {
    await this.props.getTxnWalletThresholdValue();
    const dataList = this.props?.txnWalletThresholdValue;
    let array = [];
    for (var i = 0; i < dataList.length; i++) {
      array.push({
        symbol: dataList[i].symbol,
        price: dataList[i].price,
      });
    }
    this.setState({
      thresholdvalues: array,
    });
  };

  updateForm = async (values) => {
    try {
      console.log("ascasc", this.state.thresholdvalues);

      this.props.updateTxnWalletThresholdValues(this.state.thresholdvalues);

      this.props.openWalletThresholdModal();
    } catch (error) {
      console.log(error);
    }
  };
  changeThresHold = (e, data) => {
    const temp = [...this.state.thresholdvalues];
    const length = temp.length;
    for (let i = 0; i < length; i++) {
      if (temp[i].symbol === data.symbol) {
        temp[i] = { symbol: data.symbol, price: Number(e.target.value) };
      }
    }
    this.setState({
      thresholdvalues: temp,
    });
  };
  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        {/* <Button color="danger">X</Button> */}

        <Modal
          isOpen={this.props.walletTxnLimitModal}
          toggle={this.props.openWalletThresholdModal}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.openWalletThresholdModal}>
            Set Wallet Threshold Value
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.updateForm)}>
              {this.state?.thresholdvalues &&
                this.state?.thresholdvalues.map((data, i) => {
                  return (
                    <Form.Group>
                      <Form.Field>
                        <label>{`${data.symbol} Wallet`}</label>
                        <input
                          placeholder="Limit"
                          name={`${data.symbol}_threshold_value`}
                          value={data.price}
                          component={FormField}
                          type="number"
                          validate={[required()]}
                          onChange={(e) => this.changeThresHold(e, data)}
                        />
                      </Form.Field>{" "}
                    </Form.Group>
                  );
                })}

              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    txnWalletThresholdValue: state.transaction.txnWalletThresholdValue,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTxnWalletThresholdValue: (data) =>
      dispatch(getTxnWalletThresholdValue(data)),
    updateTxnWalletThresholdValues: (data) =>
      dispatch(updateTxnWalletThresholdValues(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "TxnWalletThresholdValue" })(SetThresholdWalletValue));
// export default connect(mapStateToProps, mapDispatchToProps)(SetWithdrawDailyLimit);
