import { actionTypes } from "../actons/content.action";

const initialState = {
  contentList: [],
  detail: {},
};

const content = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CONTENT_LIST:
      return {
        ...state,
        contentList: action.data,
      };
    case actionTypes.SAVE_CONTENT_DETAIL:
      return {
        ...state,
        detail: action.data,
      };
    default:
      return state;
  }
};

export default content;
