import React, { Component } from "react";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import CustomHeader from "components/Headers/CustomHeader";

import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import { API_HOST } from "../../../constants/index";
import { FormField } from "../../../components/FormField";
import { required } from "redux-form-validators";
import {
  addAnnouncement,
  updateAnnouncement,
} from "../../../Redux/actons/announcement.action";
import { addUserGuideImage } from "../../../Redux/actons/userGuide.action";
import { Link } from "react-router-dom";
import {
  startLoading,
  stopLoading,
} from "../../../Redux/actons/loading.action";

export class AddUserGuide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: "",
    };
  }
  componentDidMount = () => {
  };

 

  onImageUpload = async (e) => {
    if (e.target.files.length == 1) {
      const formData = new FormData();
      await formData.append("file", e.target.files[0]);
      this.props.startLoading(true);
      var self = this;
      axios
        .post(`${API_HOST}api/v1/admin/guide_screen/uploadimage`, formData, {
          headers: {
            Authorization: this.props.JwtToken,
          },
        })
        .then(function (response) {
          self.setState({ file: response.data.data });
          self.props.stopLoading();
        })
        .catch(function (error) {
          this.props.stoptLoading();
        });
    } else {
      this.setState({
        file: "",
      });
    }
  };

  addUserGuideImage = (values) => {
    const param = {
      image: this.state.file.name,
    };

    this.props.addUserGuideImage(param, this.props.history);
  };

  render() {
    console.log("ALLPROPS", this.props);
    let { handleSubmit } = this.props;
    console.log(this.state);
    return (
      <div>
        <CustomHeader />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="user-guide">User Guide</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Add User Guide</BreadcrumbItem>
            <BreadcrumbItem>{"Add Image"}</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div style={{ padding: 100 }}>
          <Form>
            <FormGroup>
              <Label for="image">Upload Image</Label>
              <input type="file" accept=".png, .jpg, .jpeg,.svg"  onChange={this.onImageUpload} />
            </FormGroup>

            <div className="text-center">
              <Button className="my-4" color="primary" type="submit" onClick={() => this.addUserGuideImage()}>
                {"Add"}
              </Button>
              <Link to="user-guide">
                <Button color="success">Back</Button>
              </Link>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
    JwtToken: state.persist.c_user.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addUserGuideImage: (data, history) =>
      dispatch(addUserGuideImage(data, history)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserGuide);

