import React, { Component } from "react";
// import { Modal, Grid, Button, List, GridColumn } from "semantic-ui-react";
import {
  Card,
  Button,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  CardImg,
  Container,
  Row,
  Col,
} from "reactstrap";

import iconGoogle from "../../../images/googleAuthentication_en.png";
import iconGoogleDisable from "../../../images/googleAuthentication_en.png";

import { connect } from "react-redux";
import GoogleAuthStepOne from "./GoogleAuthStepOne";
import GoogleAuthStepTwo from "./GoogleAuthStepTwo";
import GoogleAuthStepThree from "./GoogleAuthStepThree";
import {
  enableDisableColdWalletGoogleAuth,
  getColdWalletGoogleImageSecretKey,
  openColdWalletGoogleDisableModal,
  openColdWalletGoogleEnableModal,
} from "../../../Redux/actons/account.action";
import GoogleAuthDisable from "./GoogleAuthDisable";
import { MDBCloseIcon } from "mdbreact";
import { getColdWalletGoogleStatus } from "../../../Redux/actons/user.action";

export class GoogleAuthActive extends Component {
  componentDidMount = () => {
    this.props.getColdWalletGoogleStatus();
  };
  enableGoogleAuth = (values) => {
    values["action"] = 1;
    values["google_secret_key"] = this.props.googleAuthKeys.google_secret_key;
    this.props.enableDisableColdWalletGoogleAuth(values, "enable");
  };

  disableGoogleAuth = (values) => {
    values["action"] = 0;
    values["google_secret_key"] = this.props.user.cold_wallet_google2fa_secret;
    this.props.enableDisableColdWalletGoogleAuth(values, "disable");
  };

  openModal = (isEnable) => {
    if (isEnable) {
      this.props.openColdWalletGoogleEnableModal(true);
    } else {
      let data={
        issueQr:"taralitycoldwallet"
      }
      this.props.getColdWalletGoogleImageSecretKey(data);
    }
  };
  close = () => {
    this.props.openColdWalletGoogleDisableModal(false);
    this.props.openColdWalletGoogleEnableModal(false);
    this.props.coldWalletGoToPrevStep(2);
  };

  render() {
    let {
      user,
      step,
      openGoogleModalEnable,
      openGoogleModalDisable,
    } = this.props;
    console.log("USER", user);

    return (
      <div>
        <Card body>
          <CardImg
            src={user.cold_wallet_google2fa_status ? iconGoogle : iconGoogleDisable}
          />
          <CardTitle>Cold Wallet Google Authentication</CardTitle>
          <Button
            color="info"
            onClick={() => this.openModal(user.cold_wallet_google2fa_status)}
          >
            {user.cold_wallet_google2fa_status ? "Disable" : "Enable"}
          </Button>

          <Modal
            isOpen={openGoogleModalEnable}
            closeOnDimmerClick={false}
            onClosed={this.close}
            className={[this.props.className, "modalCustom"]}
          >
            <ModalHeader>
              <h1>Enable Cold Wallet Google Authentication</h1>
              <MDBCloseIcon
                onClick={this.close}
                floated="right"
                className="popUpClose"
              />
            </ModalHeader>
            <ModalBody className="authPopUpBody">
              {step === 1 && <GoogleAuthStepOne></GoogleAuthStepOne>}
              {step === 2 && (
                <GoogleAuthStepTwo
                coldWalletGoToPrevStep={this.props.coldWalletGoToPrevStep}
                ></GoogleAuthStepTwo>
              )}
              {step === 3 && (
                <GoogleAuthStepThree
                  onSubmit={this.enableGoogleAuth}
                  coldWalletGoToPrevStep={this.props.coldWalletGoToPrevStep}
                ></GoogleAuthStepThree>
              )}

              {step === 2 && (
                <Button
                  color="secondary"
                  floated="left"
                  onClick={() => this.props.coldWalletGoToPrevStep(step)}
                >
                  Previous
                </Button>
              )}

              {(step === 1 || step === 2) && (
                <Button
                  color="info"
                  type="button"
                  floated="right"
                  positive
                  onClick={() => this.props.coldWalletGoToStep(step)}
                >
                  Continue
                </Button>
              )}
            </ModalBody>
          </Modal>
          {/* disable google modal */}
          <Modal
            size="tiny"
            closeIcon
            isOpen={openGoogleModalDisable}
            closeOnDimmerClick={false}
            onClosed={this.close}
          >
            <ModalHeader>Disable Cold Wallet Google Authentication</ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col>
                    <GoogleAuthDisable
                      close={this.close}
                      onSubmit={this.disableGoogleAuth}
                    ></GoogleAuthDisable>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log("googleAuthKeys111111", state);
  return {
    user: state.user.user,
    isGoogleForm: state.account.isGoogleAuthForm,
    step: state.account.googleColdWalletStep,
    googleAuthKeys: state.account.coldWalletGoogleAuthKeys,
    openGoogleModalDisable: state.account.openColdWalletGoogleModalDisable,
    openGoogleModalEnable: state.account.openColdWalletGoogleModalEnable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    enableDisableColdWalletGoogleAuth: (data, type) =>
      dispatch(enableDisableColdWalletGoogleAuth(data, type)),
      getColdWalletGoogleImageSecretKey: (data) => dispatch(getColdWalletGoogleImageSecretKey(data)),
    openColdWalletGoogleDisableModal: (status) =>
      dispatch(openColdWalletGoogleDisableModal(status)),
    openColdWalletGoogleEnableModal: (status) => dispatch(openColdWalletGoogleEnableModal(status)),
    getColdWalletGoogleStatus: () => dispatch(getColdWalletGoogleStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAuthActive);
