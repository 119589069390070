import React, { Component, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Pagination,
  Dropdown,
  Form,
  Icon,
  GridColumn,
  Grid,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./UserManagement.scss";
import CustomHeader from "../../../components/Headers/CustomHeader";
import Header from "components/Headers/Header";
import { withRouter } from "react-router";
import { API_HOST } from "../../../constants/index";
import { required, email } from "redux-form-validators";
import { FormField } from "../../../components/FormField";
import SetLimit from "./SetLimit";
import {
  userList,
  getUserListCSV,
  openCloseUserDetailPopup,
  openCloseBankDetailPopup,
  openCloseBalancePopup,
  userDetail,
  bankDetails,
  userCoinBalance,
  userAddComment,
  enableDisableUser,
  enableDisableUserWithdraw,
  removeUser,
  changeUserEmail,
  DashboardList,
} from "../../../Redux/actons/userList.action";
import { Field, reduxForm, change } from "redux-form";
import Download from "../../CommonFiles/Download";
import CmtDropdownMenu from "components/CmtDropdownMenu";
import moment from "moment";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { DashboardResult } from "views/ContactQueryManagement/SubjectManagement/DashboardResult";
const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
const UserFilter = [
  { key: "o", text: "None", value: "none" },

  { key: "c", text: "Confirmed Email", value: "1" },
  { key: "d", text: "Not Confirmed", value: "0" },
  { key: "e", text: "Completed (Level 0)", value: "2" },
  { key: "f", text: "Pending (Level 1)", value: "3" },
  { key: "g", text: "Pending (Level 2)", value: "4" },
  { key: "h", text: "Indian", value: "5" },
  { key: "i", text: "Non-Indian", value: "6" },
  { key: "j", text: "Past Week", value: "7" },
  { key: "k", text: "Past Month", value: "8" },
  //{ key: "u", text: "Kyc Pending", value: "7" },
  // { key: "n", text: "Unverified", value: "3" },
];

const getUserActions = (user) => {
  const actions = [
    { action: "view", label: "View", icon: "V" },
    { action: "delete", label: "Delete", icon: "D" },
    { action: "profile", label: "Profile", icon: "pro" },
  ];
  return actions;
};

const renderLabel = (label) => ({
  color: "blue",
  content: `${label.text}`,
  icon: "check",
});

export class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentUserId: "",

      searchKey: "",
      perPage: 10,
      page: 1,
      order: "desc",
      sortBy: "",
      comment: "",
      deleteUserId: "",
      openConfirm: false,
      filterType: [],
      openEditEmail: false,
      onUserMenuClick: false,
      editIdpUserId: "",
      editUserEmail: "",
      downloadExcel: false,
      download_url: "",
      filterData: {},
      isBankDetails: false,
      openSetLimitModal: false,
    };
  }
  componentDidMount = () => {
    this.getUserList();
    this.props.DashboardList();
  };

  enableOrDisable = (detail) => {
    //   0 disable 1 enable
    let reqdata = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      filterType: this.state.filterType,
    };
    let data = {
      status_code: detail.isEnabled === "1" ? 0 : 1,
    };
    this.props.enableDisableUser(detail.user_id, data, reqdata);
  };
  enableOrDisableWithdraw = (detail) => {
    //   0 disable 1 enable
    let reqdata = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      filterType: this.state.filterType,
    };
    let data = {
      status_code: detail.is_withdraw_status === "1" ? 0 : 1,
    };
    this.props.enableDisableUserWithdraw(detail.user_id, data, reqdata);
  };

  deleteUser = (user) => {
    this.setState({ deleteUserId: user.user_id, openConfirm: true });
  };
  getUserList = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      filterType: this.state.filterType,
    };
    this.props.userList(data);
  };

  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getUserList();
    });
  };
  filterBy = (e, data) => {
    this.setState({ filterType: [] });
    if (data.value !== "none") {
      this.setState({ filterType: data.value }, () => {
        this.getUserList();
      });
    } else {
      this.setState({ filterType: [] }, () => {
        this.getUserList();
      });
    }
  };

  openSetLimitModal = (data) => {
    setTimeout(() => {
      this.setState({ openSetLimitModal: !this.state.openSetLimitModal });
    }, 100);
  };

  toggle1 = () => {
    this.setState({
      openSetLimitModal: !this.state.openSetLimitModal,
    });
  };
  downLoadUserList = (data) => {
    let params = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.props.totalRecords,
      // perPage: 100,
      page: 1,
      filterType: this.state.filterType,
      // start_date: data.start_date,
      // end_date: data.end_date,
    };
    this.props.getUserListCSV(params); //getUserList
    // params = params ? "?" + new URLSearchParams(params).toString() : "";
    // window.location.href = `${API_HOST}api/v1/admin/users/download/userlist${params}`;
  };
  openDownloadExcel = (type) => {
    let filterData = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      filterType: this.state.filterType,
    };

    let download_url = "";
    type == "users"
      ? (download_url = `${API_HOST}api/v1/admin/users/download/userlist`)
      : (download_url = `${API_HOST}api/v1/admin/report/download/balanceWithAddress`);
    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: download_url,
      filterData: filterData,
    });
  };
  closeDownloadExcel = () => {
    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: "",
      filterData: {},
    });
  };
  downLoadAddressList = () => {
    window.location.href = `${API_HOST}api/v1/admin/report/download/balanceWithAddress`;
  };
  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getUserList();
    });
  };

  //search user
  searchUser = (value) => {
    this.getUserList();
  };

  closeMe = () => {
    this.props.openCloseUserDetailPopup(false);
  };

  showDetails = (id) => {
    this.props.openCloseUserDetailPopup(true);
    var data = {
      user_id: id,
    };

    this.props.userDetail(data);

    this.setState({ commentUserId: data.user_id });
  };

  closeBankDetail = () => {
    this.props.openCloseBankDetailPopup(false);
  };

  //Bank Details
  showBankDetails = (data) => {
    var params = {
      user_id: data.user_id,
    };
    this.props.bankDetails(params);
    this.setState({ isBankDetails: true });
  };

  showCoinDetails = (id) => {
    this.props.openCloseBalancePopup(true);
    var data = {
      user_id: id,
    };
    this.props.userCoinBalance(data);
  };
  closed = () => {
    this.props.openCloseBalancePopup(false);
  };

  setMessage = (e) => {
    this.setState({ comment: e.target.value });
  };

  redirectUrlTo = (coin, address) => {
    switch (coin) {
      case 1:
        window.open(`https://etherscan.io/address/${address}`, "_blank");
        break;
      case 2:
        window.open(`https://www.blockchain.com/address/${address}`, "_blank");
        break;
      case 3:
        window.open(
          `https://live.blockcypher.com/btc/address/${address}`,
          "_blank"
        );
        break;
      case 4:
        window.open(`https://tronscan.org/#/address/${address}`, "_blank");
        break;
      case 5:
        window.open(
          `https://mumbai.polygonscan.com/address/${address}`,
          "_blank"
        );
        break;
    }
  };
  sendMesage = async () => {
    if (this.state.comment != "") {
      let data = {
        user_id: this.state.commentUserId.user_id,
        comment: this.state.comment,
      };
      await this.props.userAddComment(data);

      this.props.openCloseUserDetailPopup(false);
      this.setState({ comment: "" });
    }
  };
  onConfirmation = () => {
    let fdata = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      filterType: this.state.filterType,
    };

    let data = {
      status_code: "0",
    };
    this.props.removeUser(this.state.deleteUserId, data, fdata);
    this.setState({ openConfirm: false });
  };
  close = () => {
    this.setState({ openConfirm: false });
  };
  closeUpdateEmail = () => {
    this.setState({ openEditEmail: false });
  };
  updateIdpEmail = (values) => {
    let fdata = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      filterType: this.state.filterType,
    };

    let data = {
      email: values.email,
    };
    this.props.changeUserEmail(this.state.editIdpUserId, data, fdata);
    this.setState({ openEditEmail: false });
  };
  editUserEmail = async (data) => {
    await this.props.dispatch(
      change("UserManagement", "email", data.idp_withdraw_email)
    );
    this.setState({
      editIdpUserId: data.payee_id,
      editUserEmail: data.idp_withdraw_email,
      openEditEmail: true,
    });
  };
  render() {
    let { handleSubmit, dashboardLists } = this.props;
    const userActions = getUserActions();
    return (
      <div>
        <Header headerTitle="User management" />
        <Modal isOpen={this.state.openConfirm} toggle={this.close}>
          <ModalHeader toggle={this.close}>
            Are you sure you want to delete the user ?
          </ModalHeader>
          <ModalBody></ModalBody>
          <ModalFooter>
            <Button color="error" onClick={() => this.close()}>
              Cancel
            </Button>
            <Button color="success" onClick={() => this.onConfirmation()}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
        {/* <CustomHeader /> */}
        <div>
          <Container className="contentBlock" fluid>
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Card className="shadow">
                <Grid.Row
                  columns={2}
                  style={{
                    display: "flex",
                    width: "100%",
                    //   flexWrap: "wrap",
                    // justifyContent: "space-between",
                    marginRight: 0,
                    marginLeft: 0,
                  }}
                  // style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Total Users"}
                      labResult={
                        dashboardLists.userStats?.total_users
                          ? dashboardLists.userStats?.total_users
                          : "0"
                      }
                    />
                  </GridColumn>
                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"New users (24 Hrs)"}
                      labResult={
                        dashboardLists.userStats?.twenty_four_new_users
                          ? dashboardLists.userStats?.twenty_four_new_users
                          : "0"
                      }
                    />
                  </GridColumn>

                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"New Users (1 Week)"}
                      labResult={
                        dashboardLists.userStats?.week_new_users
                          ? dashboardLists.userStats?.week_new_users
                          : "0"
                      }
                    />
                  </GridColumn>

                  <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"New Users (1 Month)"}
                      labResult={
                        dashboardLists.userStats?.month_new_users
                          ? dashboardLists.userStats?.month_new_users
                          : "0"
                      }
                    />
                  </GridColumn>
                  {/* <GridColumn className="maxWidth">
                    <DashboardResult
                      heading={"Rejected Deposit"}
                      labResult={
                        this.props?.queryListRecords?.dp_rejected
                          ? this.props?.queryListRecords?.dp_rejected
                          : "0"
                      }
                    />
                  </GridColumn> */}
                </Grid.Row>
              </Card>
            </div>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">User Management</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search User"
                        style={{
                          color: "black",
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.searchUser();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.searchUser()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        className="selectUserStyle mx-2"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />
                      <Dropdown
                        style={{ minWidth: "22em" }}
                        className="selectUserStyle"
                        options={UserFilter}
                        placeholder="Filter"
                        selection
                        multiple
                        closeOnChange={true}
                        renderLabel={renderLabel}
                        onChange={(e, data) => this.filterBy(e, data)}
                      />
                      {/* <Form style={{ display: "flex" }}>
                        <Form.Group>
                          <Form.Field>
                            <label>Select start date</label>
                            <SemanticDatepicker
                              locale="en-US"
                              onChange={(event, data) =>
                                this.SetStartDate(data)
                              }
                              type="basic"
                              format="YYYY-MM-DD"
                              value={this.state.datepicker_start_date}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field>
                            <label>Select end date</label>
                            <SemanticDatepicker
                              locale="en-US"
                              onChange={(event, data) => this.SetEndDate(data)}
                              type="basic"
                              format="YYYY-MM-DD"
                              value={this.state.datepicker_end_date}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form> */}
                    </div>
                    <div
                      className="selectSearchOption"
                      style={{ marginTop: 20 }}
                    >
                      {" "}
                      <Link to="delete-users">
                        <Button color="success btnMamagePermission">
                          Deleted Users
                        </Button>
                      </Link>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.downLoadUserList()}
                        // onClick={() => this.openDownloadExcel("users")}
                        // disabled
                      >
                        Download User List
                      </Button>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openDownloadExcel("addresss")}
                      >
                        Address List
                      </Button>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openSetLimitModal()}
                      >
                        Withdraw Limit
                      </Button>
                    </div>
                  </CardHeader>
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    style={{ minHeight: "282px" }}
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Email-Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">KYC(yes/no)</th>
                        {/* <th scope="col">Email Confirmation</th> */}
                        <th scope="col">Mobile Number</th>
                        <th scope="col">Country</th>
                        <th scope="col">Last Login Attempts</th>
                        {/* <th scope="col">Enable/disable</th>
                        <th scope="col">Enable/Disable withdraw</th> */}
                        {/* <th scope="col">IDP(Email)</th> */}

                        {/* <th scope="col">User Details</th> */}
                        {/* <th scope="col">Coin Balance</th> */}
                        <th scope="col">Action</th>
                        <th scope="col">View</th>
                        {/* <th scope="col">Bank Details</th> */}
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "baseline" }}>
                      {this.props.usersList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          <td>
                            <Link onClick={() => this.showDetails(usersList)}>
                              {usersList.email ? usersList.email : "None"}
                            </Link>
                          </td>
                          <td>{usersList.name ? usersList.name : "None"}</td>

                          <td>
                            {usersList.email_status == 0 &&
                            usersList.mobile_status == 0
                              ? "Completed (Level 0)"
                              : (usersList.email_status == 0 &&
                                  usersList.mobile_status == 1) ||
                                (usersList.email_status == 1 &&
                                  usersList.mobile_status == 0)
                              ? "Pending (Level 1)"
                              : usersList.email_status == 1 &&
                                usersList.mobile_status == 1 &&
                                usersList.kyc_status == 0
                              ? "Completed (Level 1)"
                              : usersList.email_status == 1 &&
                                usersList.mobile_status == 1 &&
                                (usersList.kyc_status == 1 ||
                                  usersList.kyc_status == 3)
                              ? "Pending (Level 2)"
                              : usersList.email_status == 1 &&
                                usersList.mobile_status == 1 &&
                                usersList.kyc_status == 2
                              ? "Completed (Level 2)"
                              : "Pending"}{" "}
                            {/*"Pending"*/}
                          </td>
                          <td>
                            {usersList.mobile == null
                              ? "N/A"
                              : usersList.mobile}
                          </td>
                          <td>
                            {usersList.country == null
                              ? "N/A"
                              : usersList.country}
                          </td>
                          <td>
                            {usersList.last_login_attempts == null
                              ? "N/A"
                              : moment(usersList.last_login_attempts).format(
                                  "lll"
                                )}
                          </td>
                          {/* <td>
                            {usersList.email_status == 1
                              ? "Confirmed"
                              : "Not Confirmed"}
                          </td>

                          <td>
                            {usersList.mobile_status == 1
                              ? "Confirmed"
                              : "Not Confirmed"}
                          </td>

                           {Object.keys(usersList.idp_withdraw_data).length !=
                          0 ? (
                            <td>
                              {usersList.idp_withdraw_data.idp_withdraw_email}
                              {"  "}
                              <Icon
                                onClick={() =>
                                  this.editUserEmail(
                                    usersList.idp_withdraw_data
                                  )
                                }
                                name="pencil"
                              ></Icon>
                            </td>
                          ) : (
                            <td>None</td>
                          )} */}
                          {/* <td>
                            <Button
                              color="primary"
                              // style={{ width: "80px" }}
                              onClick={() => this.enableOrDisable(usersList)}
                            >
                              {usersList.isEnabled === "1"
                                ? "Disable"
                                : "Enable"}{" "}
                            </Button>
                          </td> */}
                          {/* <td>
                            <Button
                              color="primary"
                              // style={{ width: "80px" }}
                              onClick={() =>
                                this.enableOrDisableWithdraw(usersList)
                              }
                            >
                              {usersList.is_withdraw_status === "1"
                                ? "Disable"
                                : "Enable"}{" "}
                            </Button>
                          </td> */}
                          {/* <td>
                            <Button
                              color="primary"
                              style={{ width: "80px" }}
                              onClick={() => this.showDetails(usersList)}
                            >
                              View{" "}
                            </Button>
                          </td> */}

                          {/* <td>
                            <Button
                              color="primary"
                              // style={{ width: "110px" }}
                              onClick={() => this.showCoinDetails(usersList)}
                            >
                              Coin Balance{" "}
                            </Button>
                          </td> */}
                          {/* <td>
                            <Button
                              color="primary"
                              // style={{ width: "80px" }}
                              onClick={() => this.deleteUser(usersList)}
                            >
                              Delete{" "}
                            </Button>
                          </td> */}
                          {/* <td>
                            <Button
                              color="primary"
                              // style={{ width: "110px" }}
                              onClick={() => this.showBankDetails(usersList)}
                            >
                              Bank Details{" "}
                            </Button>
                           */}
                          <td>
                            {/* <Button
                              color="primary"
                              // style={{ width: "110px" }}
                              onClick={() => this.showBankDetails(usersList)}
                            >
                              Bank Details{" "}
                            </Button> */}
                            <Dropdown
                              style={{
                                backgroundColor: "#2dce89",
                                color: "#fff",
                              }}
                              text="View"
                              pointing="right"
                              className="link item"
                              // icon="view"
                              floating
                              // labeled
                              button
                              // className="icon"
                            >
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  // onClick={() => this.deleteUser(usersList)}
                                  onClick={() =>
                                    this.enableOrDisable(usersList)
                                  }
                                >
                                  {usersList.isEnabled === "1"
                                    ? "Disable"
                                    : "Enable"}{" "}
                                  User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  // onClick={() => this.deleteUser(usersList)}
                                  onClick={() =>
                                    this.enableOrDisableWithdraw(usersList)
                                  }
                                >
                                  {usersList.is_withdraw_status === "1"
                                    ? "Disable"
                                    : "Enable"}{" "}
                                  Withdraw
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => this.deleteUser(usersList)}
                                >
                                  {" "}
                                  Delete User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    this.showCoinDetails(usersList)
                                  }
                                >
                                  {" "}
                                  {/* <Button
                                    color="primary"
                                    // style={{ width: "110px" }}
                                    onClick={() =>
                                      this.showCoinDetails(usersList)
                                    } 
                                  >*/}
                                  Coin Balance {/* </Button/> */}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    this.showBankDetails(usersList)
                                  }
                                >
                                  {/* <Button
                                    color="primary"
                                    // style={{ width: "110px" }}
                                    onClick={() =>
                                      this.showBankDetails(usersList)
                                    }
                                  > */}
                                  Bank Details {/* </Button> */}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                          <td>
                            <Link
                              style={{ marginRight: "10px" }}
                              to={`view-fund-management?${usersList.user_id.toString()}`}
                              // onClick={() =>
                              //   this.detailModalToggle(usersList)
                              // }
                            >
                              <Button color="success btnMamagePermission">
                                View Funds
                              </Button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                      {this.props.usersList.length === 0 && (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {this.props.usersList.length != 0 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalRecords / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <div>
          <Modal isOpen={this.props.userDetailModal} className={"modalCustom"}>
            <ModalHeader toggle={this.closeMe}></ModalHeader>
            <ModalBody>
              <div className="userNameList">
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Name:</label>
                  <span>
                    {this.props.getUserId.name
                      ? this.props.getUserId.name
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Email:</label>
                  <span style={{ textDecoration: "none" }}>
                    {this.props.getUserId.email
                      ? this.props.getUserId.email
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Email Verified:</label>
                  <span style={{ textDecoration: "none" }}>
                    {this.props.getUserId.email_status &&
                    this.props.getUserId.email_status == 1
                      ? "Yes"
                      : "No"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Aadhaar no:</label>
                  <span>
                    {this.props.getUserId.document_number
                      ? this.props.getUserId.document_number
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Pan no:</label>
                  <span>
                    {this.props.getUserId.pancard_number
                      ? this.props.getUserId.pancard_number
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Mobile:</label>
                  <span>
                    {this.props.getUserId.mobile
                      ? this.props.getUserId.mobile
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Mobile Verified:</label>
                  <span>
                    {this.props.getUserId?.mobile_status &&
                    this.props.getUserId.mobile_status == 1
                      ? "Yes"
                      : "No"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Address:</label>
                  <span>
                    {this.props.getUserId.address
                      ? this.props.getUserId.address
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Country:</label>
                  <span>
                    {this.props.getUserId.country
                      ? this.props.getUserId.country
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>City:</label>
                  <span>
                    {this.props.getUserId.city
                      ? this.props.getUserId.city
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Zip Code:</label>
                  <span>
                    {this.props.getUserId.zip_code
                      ? this.props.getUserId.zip_code
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Device Type:</label>
                  <span>
                    {this.props.getUserId.device_type == 0 ? "IOS" : "Android"}
                  </span>
                </div>
                <div style={{ marginTop: "16px" }}>
                  <span>
                    <img
                      onClick={() =>
                        window.open(
                          `${this.props.getUserId.doc_image1}`,
                          "_blank"
                        )
                      }
                      style={{ height: "200px", width: "48%" }}
                      src={
                        this.props.getUserId.doc_image1
                          ? this.props.getUserId.doc_image1
                          : "No image"
                      }
                    ></img>
                  </span>
                  <span style={{ marginLeft: "10px" }}>
                    <img
                      onClick={() =>
                        window.open(
                          `${this.props.getUserId.doc_image2}`,
                          "_blank"
                        )
                      }
                      style={{ height: "200px", width: "48%" }}
                      src={
                        this.props.getUserId.doc_image2
                          ? this.props.getUserId.doc_image2
                          : "No image"
                      }
                    ></img>
                  </span>
                  <span style={{ marginTop: "10px" }}>
                    <img
                      onClick={() =>
                        window.open(
                          `${this.props.getUserId.doc_image3}`,
                          "_blank"
                        )
                      }
                      style={{ height: "200px", width: "48%" }}
                      src={
                        this.props.getUserId.doc_image3
                          ? this.props.getUserId.doc_image3
                          : "No image"
                      }
                    ></img>
                  </span>
                  <span style={{ marginLeft: "10px" }}>
                    <img
                      onClick={() =>
                        window.open(
                          `${this.props.getUserId.pancard_image}`,
                          "_blank"
                        )
                      }
                      style={{ height: "200px", width: "48%" }}
                      src={
                        this.props.getUserId.pancard_image
                          ? this.props.getUserId.pancard_image
                          : "No image"
                      }
                    ></img>
                  </span>
                  <Form style={{ marginTop: "20px" }}>
                    <Form.TextArea
                      label="Add Comments"
                      placeholder="Add Comments..."
                      onChange={(e) => this.setMessage(e)}
                    />
                  </Form>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => this.closeMe()}>
                Close
              </Button>
              <Button
                color="success"
                disabled={!this.state.comment}
                onClick={() => this.sendMesage()}
              >
                Send
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <div>
          <Modal isOpen={this.props.userBalanceModal} className={"modalCustom"}>
            <ModalHeader toggle={this.userToggle}></ModalHeader>
            <ModalBody>
              <div>
                <div>
                  <Table
                    className="align-items-center table-flush table_customStyle"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th>Coin</th>
                        <th>Coin Balance</th>
                        <th>Coin Address</th>
                      </tr>
                    </thead>

                    {this.props.getUserCoinId.length > 0 && (
                      <tbody>
                        {this.props.getUserCoinId.map((a, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{a.coin_symbol.toUpperCase()}</td>

                            <td>
                              {a.balance}
                              {""} {a.coin_symbol.toUpperCase()}
                            </td>
                            <td
                              title={a.address}
                              onClick={() =>
                                this.redirectUrlTo(a.coin_family, a.address)
                              }
                            >
                              {a.address.slice(0, 6) +
                                "..." +
                                a.address.slice(-4)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.closed}>
                Close
              </Button>{" "}
            </ModalFooter>
          </Modal>

          {/* Bank Details Model */}
          <SetLimit
            toggle={this.state.openSetLimitModal}
            close={this.toggle1}
          />
          <Modal
            size="lg"
            style={{ maxWidth: "700px", width: "100%" }}
            isOpen={this.state.isBankDetails}
            className={"modalCustom"}
          >
            <ModalHeader toggle={this.userToggle}></ModalHeader>
            <ModalBody>
              <div>
                <div>
                  <Table
                    className="align-items-center table-flush table_customStyle"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th>Account Holder</th>
                        <th>Bank Name</th>
                        <th>Account Number</th>
                        <th>IFSC Code</th>
                      </tr>
                    </thead>

                    {this.props.getBankDetails.length > 0 ? (
                      <tbody>
                        {this.props.getBankDetails.map((a, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{a.account_holder.toUpperCase()}</td>

                            <td>{a.bank_name}</td>
                            <td>{a.account_number}</td>
                            <td>{a.ifsc_code}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <div
                        style={{
                          color: "white",
                          textAlign: "center",
                          marginTop: "10px",
                          height: "32px",
                        }}
                      >
                        No record
                      </div>
                    )}
                  </Table>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({ isBankDetails: false });
                }}
              >
                Close
              </Button>{" "}
            </ModalFooter>
          </Modal>

          {/* changeIDp modal email */}
          <Modal
            isOpen={this.state.openEditEmail}
            // toggle={this.closeUpdateEmail}
          >
            <ModalHeader toggle={this.closeUpdateEmail}>
              Update email
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit(this.updateIdpEmail)}>
                <Form.Group>
                  <Form.Field>
                    <label>Email </label>
                    <Field
                      type="text"
                      name="email"
                      validate={[required(), email()]}
                      component={FormField}
                    ></Field>
                  </Form.Field>
                </Form.Group>
                <Button
                  color="error"
                  onClick={() => this.setState({ openEditEmail: false })}
                >
                  Cancel
                </Button>
                <Button type="submit" color="success">
                  Update
                </Button>
              </Form>
            </ModalBody>
          </Modal>
          <Download
            filterData={this.state.filterData}
            closeDownloadExcel={this.closeDownloadExcel}
            downloadExcel={this.state.downloadExcel}
            download_url={this.state.download_url}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dashboardLists: state.userList.dashboardLists,
    usersList: state.userList.usersLists,
    userDetailModal: state.userList.userDetailModal,
    totalRecords: state.userList.totalRecords,
    getUserId: state.userList.getUserId,
    getBankDetails: state.bankDetails.getBankDetails,
    getUserCoinId: state.userList.getUserCoinId,
    userBalanceModal: state.userList.userBalanceModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    DashboardList: (data) => dispatch(DashboardList(data)),
    userList: (filterType, data) => dispatch(userList(filterType, data)),
    getUserListCSV: (data) => dispatch(getUserListCSV(data)),
    openCloseBalancePopup: (data) => dispatch(openCloseBalancePopup(data)),
    openCloseBankDetailPopup: (data) =>
      dispatch(openCloseBankDetailPopup(data)),
    userCoinBalance: (id) => dispatch(userCoinBalance(id)),
    userDetail: (id) => dispatch(userDetail(id)),
    bankDetails: (params) => dispatch(bankDetails(params)),
    openCloseUserDetailPopup: (data) =>
      dispatch(openCloseUserDetailPopup(data)),

    userAddComment: (data, id) => dispatch(userAddComment(data, id)),
    enableDisableUser: (id, data, fData) =>
      dispatch(enableDisableUser(id, data, fData)),
    enableDisableUserWithdraw: (id, data, fData) =>
      dispatch(enableDisableUserWithdraw(id, data, fData)),
    removeUser: (id, data, fData) => dispatch(removeUser(id, data, fData)),
    changeUserEmail: (id, data, fdata) =>
      dispatch(changeUserEmail(id, data, fdata)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "UserManagement" })(UserManagement))
);
