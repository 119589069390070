import { UserService } from "../../Services/UserServices";
import { toast } from "../../components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";
import { saveSubAdminPermissions } from "./SubAdmin.action";
import { socket } from "../../index";
import {
  loginUserPersist,
  logoutUserPersist,
  loginUserTempPersist,
} from "./persist.action";
/** seting action types */
export const actionTypes = {
  LOGIN_USER_REQUEST: "LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_ERROR: "LOGIN_USER_ERROR",
  LOGIN_USER_RESET: "LOGIN_USER_RESET",
  USER_TERMINATION_REQUESTS: "USER_TERMINATION_REQUESTS",

  ENABLE_DISABLE_GOOGLE_AUTH: "ENABLE_DISABLE_GOOGLE_AUTH",
  SAVE_USER_COIN_LIST: "SAVE_USER_COIN_LIST",
  SAVE_USER_ACTIVE_COIN_LIST: "SAVE_USER_ACTIVE_COIN_LIST",
  SAVE_COIN_MARKET_CAP: "SAVE_COIN_MARKET_CAP",
  SAVE_COIN_GECKO: "SAVE_COIN_GECKO",
  SAVE_USER_COIN_LIST_SETTING: "SAVE_USER_COIN_LIST_SETTING",
  SAVE_PLAN_DATA: "SAVE_PLAN_DATA", //-------
  SAVE_CURRENCY_LIST: "SAVE_CURRENCY_LIST",
  SAVE_COIN_VALUE: "SAVE_COIN_VALUE",
  SAVE_COIN_LIMIT: "SAVE_COIN_LIMIT",
  SAVE_ORDER_BOOK_LIST: "SAVE_ORDER_BOOK_LIST",
  SAVE_ORDER_BOOK_PAIR_LIST: "SAVE_ORDER_BOOK_PAIR_LIST",
  SAVE_ORDER_BOOK_FEE: "SAVE_ORDER_BOOK_FEE",
  SAVE_VIEW_ORDER_BOOK: "SAVE_VIEW_ORDER_BOOK",
  SAVE_TRADE_SETTINGS: "SAVE_TRADE_SETTINGS",
  SAVE_USER_COIN_LIST_FILTER: "SAVE_USER_COIN_LIST_FILTER",
  SAVE_USER_GOOGLE_2FA_STATUS: "SAVE_USER_GOOGLE_2FA_STATUS",
  SAVE_TRADE_HISTORY: "SAVE_TRADE_HISTORY",
  SAVE_ORDER_SETTING: "SAVE_ORDER_SETTING",
  SAVE_VIEW_PAIR: "SAVE_VIEW_PAIR",
  SAVE_USER_COLD_WALLET_GOOGLE_2FA_STATUS:
    "SAVE_USER_COLD_WALLET_GOOGLE_2FA_STATUS",
  ENABLE_DISABLE_COLD_WALLET_GOOGLE_AUTH:
    "ENABLE_DISABLE_COLD_WALLET_GOOGLE_AUTH",
};

/*
 * Action creators for login
 */
export function saveGoogle_2fa_status(data) {
  return {
    type: actionTypes.SAVE_USER_GOOGLE_2FA_STATUS,
    data: data,
  };
}

export function userTerminationRequests(data) {
  return {
    type: actionTypes.USER_TERMINATION_REQUESTS,
    data: data,
  };
}

export function loginUserRequest() {
  return {
    type: actionTypes.LOGIN_USER_REQUEST,
  };
}

export function loginUserError(error) {
  return {
    type: actionTypes.LOGIN_USER_ERROR,
    error,
  };
}

export function loginUserSuccess(user) {
  return {
    type: actionTypes.LOGIN_USER_SUCCESS,
    user,
  };
}

export function enableDisableGoogle(data, key) {
  return {
    type: actionTypes.ENABLE_DISABLE_GOOGLE_AUTH,
    user: data,
    google_secret_key: key,
  };
}

export function saveCoinsList(data) {
  return {
    type: actionTypes.SAVE_USER_COIN_LIST,
    user: data,
  };
}
export function saveActiveCoinList(data) {
  return {
    type: actionTypes.SAVE_USER_ACTIVE_COIN_LIST,
    user: data,
  };
}
export function savecoinmarketcap(data) {
  return {
    type: actionTypes.SAVE_COIN_MARKET_CAP,
    user: data,
  };
}
export function savecoingecko(data) {
  return {
    type: actionTypes.SAVE_COIN_GECKO,
    user: data,
  };
}
export function saveCoinsListSetting(data) {
  return {
    type: actionTypes.SAVE_USER_COIN_LIST_SETTING,
    user: data,
  };
}
export function savePlanData(data) {
  return {
    type: actionTypes.SAVE_PLAN_DATA,
    user: data,
  };
}

export function saveCurrencyList(data) {
  return {
    type: actionTypes.SAVE_CURRENCY_LIST,
    data: data,
  };
}

export function saveCoinValue(data) {
  return {
    type: actionTypes.SAVE_COIN_VALUE,
    data: data,
  };
}
export function saveCoinLimit(data) {
  return {
    type: actionTypes.SAVE_COIN_LIMIT,
    data: data,
  };
}
export function saveorderbookList(data) {
  return {
    type: actionTypes.SAVE_ORDER_BOOK_LIST,
    data: data,
  };
}
export function saveorderbookPairList(data) {
  return {
    type: actionTypes.SAVE_ORDER_BOOK_PAIR_LIST,
    data: data,
  };
}
export function saveorderbookFee(data) {
  return {
    type: actionTypes.SAVE_ORDER_BOOK_FEE,
    data: data?.data,
  };
}
export function savevieworderbook(data) {
  return {
    type: actionTypes.SAVE_VIEW_ORDER_BOOK,
    data: data,
  };
}

export function saveTradeSettings(data) {
  return {
    type: actionTypes.SAVE_TRADE_SETTINGS,
    user: data,
  };
}

export function saveTradeHistory(data) {
  return {
    type: actionTypes.SAVE_TRADE_HISTORY,
    user: data,
  };
}
export function saveOrderSettings(data) {
  return {
    type: actionTypes.SAVE_ORDER_SETTING,
    user: data,
  };
}
export function saveviewPair(data) {
  return {
    type: actionTypes.SAVE_VIEW_PAIR,
    user: data,
  };
}

export function saveColdWalletGoogle_2fa_status(data) {
  return {
    type: actionTypes.SAVE_USER_COLD_WALLET_GOOGLE_2FA_STATUS,
    data: data,
  };
}

export function enableDisableColdWalletGoogle(data, key) {
  return {
    type: actionTypes.ENABLE_DISABLE_COLD_WALLET_GOOGLE_AUTH,
    user: data,
    google_secret_key: key,
  };
}

/** logout user */
export function logoutUser() {
  return (dispatch, getState) => {
    let state = getState();

    // localStorage.clear();
    socket.emit("logout", { userId: state.user.user.id });
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);

    return dispatch(logoutUserPersist());
  };
}
export function getGoogleStatus() {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getGoogleStatus({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveGoogle_2fa_status(res.data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function loginUser(data, history) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return UserService.login(data)
      .then(async (user) => {
        dispatch(
          loginUserTempPersist({ token: user["data"]["data"]["access_token"] })
        );
        if (data.remember) {
          sessionStorage.setItem("savedUser", JSON.stringify(data));
        } else {
          sessionStorage.removeItem("savedUser");
        }
        dispatch(stopLoading());

        if (user.data.data.role == 2) {
          // sessionStorage.setItem("roleData",JSON.stringify(user.data.roleData));
          dispatch(saveSubAdminPermissions(user.data.roleData));
        }

        dispatch(loginUserSuccess(user["data"]["data"]));
        let { google2fa_status } = user["data"]["data"];
        if (google2fa_status === 1) {
          history.push("/vnjdLbguybcAzLmvAlsdnfjDbiumaas/two-factor-auth");
        } else {
          toast.success(user["data"]["message"]);

          dispatch(
            loginUserPersist({ token: user["data"]["data"]["access_token"] })
          );
          socket.emit("login", { userId: user.data.data.id });
          if (user.data.data.role == 1) {
            history.push("/admin/index");
          } else {
            history.push("/admin/account-setting");
          }
        }
      })
      .catch((error) => {
        if (error) {
          console.log(" error", error);
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getCoinList(params) {
  return (dispatch, getState) => {
    // dispatch(startLoading());
    let state = getState();
    return UserService.getCoinList(
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      },
      params
    )
      .then(async (res) => {
        // dispatch(stopLoading());
        dispatch(saveCoinsList(res.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function activeCoinList(params) {
  return (dispatch, getState) => {
    // dispatch(startLoading());
    let state = getState();
    return UserService.getActiveCoinList(
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      },
      params
    )
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveActiveCoinList(res.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getcoinmarketcap(params) {
  return (dispatch, getState) => {
    // dispatch(startLoading());
    let state = getState();
    return UserService.getcoinmarketcap(
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      },
      params
    )
      .then(async (res) => {
        // dispatch(stopLoading());
        // console.log(" ----- >>>> saveviewPair---- marketCap", res.data.status);
        dispatch(
          savecoinmarketcap(res.data.status ? res.data.data : res.data.status)
        );
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getcoingecko(params) {
  return (dispatch, getState) => {
    // dispatch(startLoading());
    let state = getState();
    return UserService.getcoingecko(
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      },
      params
    )
      .then(async (res) => {
        // dispatch(stopLoading());
        // console.log(" ----- >>>> saveviewPair---- gecko", res.data.status);
        dispatch(
          savecoingecko(res.data.status ? res.data.data : res.data.status)
        );
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getCoinListCoinSetting(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getCoinListCoinSetting(
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      },
      params
    )
      .then(async (res) => {
        dispatch(stopLoading());
        console.log(" =====>> ", res.data);
        dispatch(saveCoinsListSetting(res.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
// export function getCoinList1(params) {
//   return (dispatch, getState) => {
//     // dispatch(startLoading());
//     let state = getState();
//     return UserService.getCoinList1(
//       {
//         jwt: state["persist"]["c_temp_user"]["token"],
//       },
//       params
//     )
//       .then(async (res) => {
//         dispatch(stopLoading());
//         dispatch(saveCoinsList(res.data.data));
//
//         if (res.data.data.length !== res.data.meta.total) {
//           let params1 = { perPage: res.data.meta.total, page: 1 };
//
//           getCoinList(params1);
//         }
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }
export function getPlan(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getPlan(
      {
        jwt: state["persist"]["c_temp_user"]["token"],
      },
      params
    )
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(savePlanData(res.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function orderbookViewCoins(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.viewOrderbookCoins(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(savevieworderbook(res.data));
      })
      .catch((error) => {
        if (error) {
          // toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}
export function orderbookFee(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.orderbookFee(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveorderbookFee(res.data));
      })
      .catch((error) => {
        if (error) {
          // toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}
export function orderbookListCoins(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.orderbookListCoins(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveorderbookList(res.data));
      })
      .catch((error) => {
        if (error) {
          // toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}
export function OrderPairList(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.OrderPairList(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveorderbookPairList(res.data));
      })
      .catch((error) => {
        if (error) {
          // toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}
export function listCoinsLimits(data) {
  return (dispatch, getState) => {
    // dispatch(startLoading());
    let state = getState();
    return UserService.listCoinsLimits(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        // console.log("data ----- updateCoinLimit", res);
        dispatch(saveCoinLimit(res.data));
      })
      .catch((error) => {
        if (error) {
          // toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}
export function getCoinValue(data) {
  return (dispatch, getState) => {
    // dispatch(startLoading());
    let state = getState();
    return UserService.getCoinValue(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveCoinValue(res.data));
      })
      .catch((error) => {
        if (error) {
          // toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}

export function getCurrencyList(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getCurrencyList(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveCurrencyList(res.data.data));
      })
      .catch((error) => {
        if (error) {
          // toast.error(error?.data?.message);
        }
        dispatch(stopLoading());
      });
  };
}

export function getTradeSettings() {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getTradeSettings({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveTradeSettings(res.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function viewPair(data) {
  console.log("data ----", data);
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.viewPair(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveviewPair(res.data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getOrderSettings() {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getOrderSettings({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        dispatch(saveOrderSettings(res.data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getTradeHistory(params) {
  return (dispatch, getState) => {
    // dispatch(startLoading());
    let state = getState();
    return UserService.getTradeHistory(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveTradeHistory(data.data));
        // dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function downloadTradeHistory(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.downloadTradeHistory(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        // dispatch(saveTradeHistory(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function addPairs(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.addPairs(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        toast.success(res["data"]["message"]);
        // dispatch(getOrderSettings());
        // this.history.goBack();
        // this.props.history.goBack();
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function EditPair(data, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.EditPair(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        toast.success(res["data"]["message"]);

        // dispatch(getOrderSettings());
        // this.history.goBack();
        // this.props.history.goBack();
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function addOrUpdateMarkup(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.addOrUpdateMarkup(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        toast.success(res["data"]["message"]);
        dispatch(getOrderSettings());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function updateTradeSettings(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.updateTradeSettings(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(stopLoading());
        toast.success(res["data"]["message"]);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function updateCurrency(params, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.updateCurrencyList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data.data.message);
        dispatch(stopLoading());
        dispatch(getCurrencyList(data2));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function updateCoinLimit(params, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.updateCoinLimit(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        console.log(" action --->>> ", data);
        dispatch(listCoinsLimits(data2));
        toast.success(data.message);
      })
      .catch((error) => {
        if (error) {
          toast.error(error["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function deleteCoinLimit(params, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.deleteCoinLimit(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data.message);
        dispatch(stopLoading());
        dispatch(listCoinsLimits(data2));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function deleteCoinValue(params, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.deleteCoinValue(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data.data.message);
        dispatch(stopLoading());
        dispatch(getCoinValue(data2));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function updateCoinValue(data, id, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.updateCoinValue(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data.data.message);
        dispatch(stopLoading());
        dispatch(getCoinValue(data2));
      })
      .catch((error) => {
        if (error) {
          toast.error(
            error.data.errors[0].message
              ? error.data.errors[0].message
              : error["message"]
          );
        }
        dispatch(stopLoading());
      });
  };
}
export function EnableDisablePair(params, data, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.EnableDisablePair(params, data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        if (data) {
          toast.success(data.data.message);
        }
        dispatch(stopLoading());
        dispatch(OrderPairList(data2));
      })
      .catch((error) => {
        dispatch(stopLoading());

        dispatch(stopLoading());
      });
    dispatch(stopLoading());
  };
}
export function CancleOredr(data, id, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.CancleOredr(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        if (data) {
          toast.success(data.data.message);
        }
        dispatch(stopLoading());
        dispatch(orderbookFee());
      })
      .catch((error) => {
        dispatch(stopLoading());
        // toast.success(data.data.message);
        // if (error) {
        //   toast.error(
        //     error.data.errors[0].message
        //       ? error.data.errors[0].message
        //       : error["message"]
        //   );
        // }
        dispatch(stopLoading());
      });
    dispatch(stopLoading());
  };
}
export function editFee(data, id, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.editFee(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data.data.message);
        dispatch(stopLoading());
        dispatch(orderbookFee());
      })
      .catch((error) => {
        if (error) {
          toast.error(
            error.data.errors[0].message
              ? error.data.errors[0].message
              : error["message"]
          );
        }
        dispatch(stopLoading());
      });
    dispatch(stopLoading());
  };
}
export function addCoinValue(data, id, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.addCoinValue(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data.data.message);
        dispatch(stopLoading());
        dispatch(getCoinValue(data2));
      })
      .catch((error) => {
        if (error) {
          toast.error(
            error.data.errors[0].message
              ? error.data.errors[0].message
              : error["message"]
          );
        }
        dispatch(stopLoading());
      });
    dispatch(stopLoading());
  };
}

export function updateRate(params, id, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.updateRate(params, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data.data.message);
        dispatch(stopLoading());
        dispatch(getCurrencyList(data2));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function updateCoinPriceToUSD(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.updateCoinPriceToUSD(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
//for mobile app
export function forgotPasswordForUser(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.forgotPasswordForUser(data)
      .then(async (res) => {
        toast.success(res["data"]["message"]);

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
//for mobile app
export function forgotLoginPin(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.forgotLoginPin(data)
      .then(async (res) => {
        toast.success(res["data"]["message"]);

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

//for mobile app
export function forgotTxnPin(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.forgotTxnPin(data)
      .then(async (res) => {
        toast.success(res["data"]["message"]);

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
// IDP Charge List //
export function getIDPChargeList(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return new Promise((resolve, reject) => {
      return UserService.getIDPChargeList(params, {
        jwt: state["persist"]["c_temp_user"]["token"],
      })
        .then(async (res) => {
          dispatch(stopLoading());
          resolve(res);
        })
        .catch((error) => {
          if (error) {
            toast.error(error["data"]["message"]);
          }
          dispatch(stopLoading());
          reject(error);
        });
    });
  };
}

// IDP Charge List //
export function approveRejectIDP(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());

    let state = getState();
    return new Promise((resolve, reject) => {
      return UserService.approveRejectIDP(data, {
        jwt: state["persist"]["c_temp_user"]["token"],
      })
        .then(async (res) => {
          toast.success(res["data"]["message"]);
          dispatch(stopLoading());
          resolve(res);
        })
        .catch((error) => {
          if (error) {
            toast.error(error["data"]["message"]);
          }
          dispatch(stopLoading());
          reject(error);
        });
    });
  };
}

// IDP Charge setting action //
export function submitIdpChargeSetting(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.submitIdpChargeSetting(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
// get cold wallet google status
export function getColdWalletGoogleStatus() {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getColdWalletGoogleStatus({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveColdWalletGoogle_2fa_status(res.data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getUserTerminationRequests(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.getUserTerminationRequests(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(userTerminationRequests(res.data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function terminateRequest(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return UserService.terminateRequest(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(stopLoading());
        return res;
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
