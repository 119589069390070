import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import { connect } from "react-redux";
import { FormField } from "../../components/FormField";
import { required } from "redux-form-validators";
import { Field, reduxForm, change, reset } from "redux-form";

export class AddComment extends Component {
  state = {
    action_type: "",
    transactionHash: "",
    to_address: "",
    from_address: "",
    block_hash: "",
    status: 1,
    type: 1,
  };
  render() {
    console.log("this.props", this.props);
    let { handleSubmit, t } = this.props;
    return (
      <div>
        <div>
          <Modal
            isOpen={this.props.isCommentOpen}
            toggle={this.props.openCloseCommentModal}
            className={[this.props.className, "modalCustom"]}
          >
            <Form>
              <ModalHeader
                toggle={this.props.openCloseCommentModal}
                style={{ backgroundColor: "	#343434", color: "white" }}
              >
                <span style={{ color: "white" }}>Are you sure?</span>
              </ModalHeader>
              <ModalBody
                style={{ backgroundColor: "#343434", textAlign: "center" }}
              >
                <div>
                  <div>
                    <Field
                      placeholder="Add comment"
                      name="message"
                      component={FormField}
                      type="textArea"
                      validate={[required()]}
                    />
                    {/* <Field
                      name="message"
                      placeholder={t("commonHeadings.comment_message")}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    /> */}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter style={{ backgroundColor: "#343434" }}>
                <Button
                  color="success"
                  onClick={handleSubmit((values) =>
                    this.props.acceptRejectClaim({
                      ...values,
                    })
                  )}
                >
                  Yes
                </Button>
                <Button
                  color="danger"
                  onClick={this.props.openCloseCommentModal}
                >
                  Cancel
                </Button>
                {/* <Button
                  color="sucess"
                  onClick={handleSubmit((values) =>
                    this.props.acceptRejectClaim({
                      ...values,
                    })
                  )}
                >
                  {t("commonHeadings.add")}
                </Button> */}
              </ModalFooter>
            </Form>
          </Modal>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({});

// const mapDispatchToProps = (dispatch) => {};
export default connect()(reduxForm({ form: "addCommentForm" })(AddComment));
// export default connect(
//   // mapStateToProps,
//   // mapDispatchToProps
// )(reduxForm({ form: "addCommentForm" })(withTranslation()(AddComment)));
