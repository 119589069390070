import React, { Component } from "react";
import Header from "components/Headers/Header";
import CustomHeader from "components/Headers/CustomHeader";
import { Form } from "semantic-ui-react";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../../components/FormField";
import { SelectField } from "../../../components/SelectField";
import { email, required } from "redux-form-validators";
import {
  getAdminSettings,
  updateAdminSetting,
  updateTransactionFeeLimit,
} from "../../../Redux/actons/account.action";
import { getCoinList } from "../../../Redux/actons/user.action";
import { amountLimit } from "../../../validators/customValidator";
import { Link } from "react-router-dom";

// import { amountValidator } from "../../../validators/customValidator";

// const options = [
//   { key: "m", text: "Transaction Fee", value: "transaction_fee" }
// ];
const actions = [
  { key: "m", text: "Activate", value: 1 },
  { key: "f", text: "Inactivate", value: 2 },
];
export class TransactionFeeSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCoin: false,
      defaultType: "",
      defaultAction: 1,
    };
  }

  componentDidMount = () => {
    this.props.getCoinList();
  };

  getDetails = (e, data) => {
    this.props.coinsList.forEach((element) => {
      if (element.value == data) {
        this.props.dispatch(
          change("TransactionFeeForm", "limit", element.withdraw_limit)
        );
      }
    });
    this.setState({ isCoin: true, defaultType: data });
  };

  updateForm = (values) => {
    console.log("calue", values);
    this.props.updateTransactionFeeLimit(values);
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Set Coin Limit/Fee" />
        {/* <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="transaction-fee-management">
                Transaction Fee Management
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Set Coin Limit/Fee</BreadcrumbItem>
          </Breadcrumb>
        </div> */}

        <div style={{ padding: 30 }}>
          <Form onSubmit={handleSubmit(this.updateForm)}>
            <Form.Group>
              <Form.Field>
                <label>Choose Coin</label>
                <Field
                  name="coin_id"
                  search={false}
                  selectOnBlur={false}
                  validate={[required()]}
                  component={SelectField}
                  defaultValue={this.state.defaultType}
                  children={this.props.coinsList}
                  placeholder="Select"
                  onChange={(e, data) => this.getDetails(e, data)}
                ></Field>
              </Form.Field>
            </Form.Group>

            {this.state.isCoin && (
              <Form.Group>
                <Form.Field>
                  <label>Limit</label>
                  <Field
                    type="number"
                    name="limit"
                    validate={[required(), amountLimit()]}
                    component={FormField}
                    placeholder="amount"
                  ></Field>
                </Form.Field>
              </Form.Group>
            )}
            {this.state.isCoin && (
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    feeSettingDetails: state.account.feeSettingDetails,
    coinsList: state.user.coinsList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAdminSettings: (data) => dispatch(getAdminSettings(data)),
    updateAdminSetting: (data) => dispatch(updateAdminSetting(data)),
    getCoinList: () => dispatch(getCoinList()),
    updateTransactionFeeLimit: (data) =>
      dispatch(updateTransactionFeeLimit(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "TransactionFeeForm" })(TransactionFeeSetting));
