import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

//add coin
const pairCoinRequest = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/buySell/add-pair`,
    data,
    options
  );
};

const enableDisablePair = (data, id, token) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/trade/update-pair`,
    data,
    token
  );
};

const minMax = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/trade/update_min_max_value`,
    data,
    token
  );
};

//get pair list
const getPairList = (params, options) => {
  return fetch("get", `${API_HOST}api/v1/admin/trade/pair-list`, {}, options,params);
};

const getPairCoinFromId = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/buySell/pair/${data}`,
    data,
    options
  );
};

const updatePairCoin = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/buySell/updatePrice/${data.id}`,
    data,
    options
  );
};

const getSwapHistoryList = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/buySell/order-list?order=${data.order}&page=${data.page}&perPage=${data.perPage}`,
    data,
    options
  );
};

const getAllActiveTradeCurrencies = (options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/trade/all_trade_currencies`,
    {},
    options
  );
};
export const PairCoinServices = {
  pairCoinRequest,
  getPairList,
  enableDisablePair,
  getPairCoinFromId,
  updatePairCoin,
  getSwapHistoryList,
  minMax,
  getAllActiveTradeCurrencies
};
