import { actionTypes } from "../actons/otc.action";

const initialState = {
  getOtcList: [],
  totalOtcRecords: "",
  getOrderList: [],
  totalOrderRecords: "",
  getTraderList: [],
  totalTraderRecords: "",
  traderHistory: [],
  coinSetting: [],
  otc_limit_coin: [],
  otc_limit_All_coin: [],
};

const otc = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_OTC_DETAILS:
      return {
        ...state,
        getOtcList: action.data,
      };
    case actionTypes.SAVE_OTC_LIMIT_ALL_COIN:
      return {
        ...state,
        otc_limit_All_coin: action.data,
      };
    case actionTypes.TOTAL_OTC_RECORDS:
      return {
        ...state,
        totalOtcRecords: action.data,
      };

    case actionTypes.SAVE_ORDER_DETAILS:
      return {
        ...state,
        getOrderList: action.data,
      };

    case actionTypes.TOTAL_ORDER_RECORDS:
      return {
        ...state,
        totalOrderRecords: action.data,
      };

    case actionTypes.SAVE_TRADER_DETAILS:
      return {
        ...state,
        getTraderList: action.data,
      };

    case actionTypes.TOTAL_TRADER_RECORDS:
      return {
        ...state,
        totalTraderRecords: action.data,
      };

    case actionTypes.SAVE_TRADER_HISTORY:
      return {
        ...state,
        traderHistory: action.data,
      };

    case actionTypes.SAVE_COIN_LIMIT_SETTING:
      return {
        ...state,
        coinSetting: action.data,
      };
    case actionTypes.SAVE_OTC_LIMIT_COIN:
      return {
        ...state,
        otc_limit_coin: action.data,
      };
    default:
      return state;
  }
};

export default otc;
