import React from "react";
import { Field, reduxForm } from "redux-form";
import { FormField } from "../../components/FormField";
import { required, email } from "redux-form-validators";
import { connect } from "react-redux";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class EmailConfirmation extends React.Component {
  handleSubmit = () => {};

  render() {
    let { handleSubmit } = this.props;

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody>
              <h2 style={{ textAlign: "center" }}>ENTER YOUR EMAIL</h2>
              <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Field
                      name="email"
                      component={FormField}
                      type="email"
                      placeholder="Enter email"
                      validate={[required(), email()]}
                      autoFocus
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    SUBMIT
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col className="text-right" xs="12"></Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "EmailConfirmation" })(EmailConfirmation));
