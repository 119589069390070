// import { ReferralServices } from "../../Services/ReferralServices";
import { SubjectServices } from "../../Services/SubjectServices";

import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../components/Toast/Toast";
import { reset } from "redux-form";
import moment from "moment";
import { DownLoadCSVFunction } from "./installment.action";
// var objectToCSVRow = function (dataObject) {
//   console.log("arrayOfObjects.length -------   ", dataObject);
//   var dataArray = new Array();
//   for (var o in dataObject) {
//     var innerValue = dataObject[o] === null ? "" : dataObject[o].toString();
//     var result = innerValue.replace(/"/g, '""');
//     result = '"' + result + '"';
//     dataArray.push(result);
//   }
//   console.log("arrayOfObjects.length -------   ", dataArray);
//   return dataArray.join(" ") + "\r\n";
// };
// var DownLoadCSVFunction = function (arrayOfObjects, name) {
//   console.log("arrayOfObjects.length -------   ", arrayOfObjects);
//   var csvContent = "data:text/csv;charset=utf-8,";
//   let dataString;
//   arrayOfObjects.forEach(function (infoArray, index) {
//     csvContent +=
//       index == 0
//         ? objectToCSVRow(Object.keys(arrayOfObjects[index]))
//         : objectToCSVRow(Object.values(arrayOfObjects[index]));
//     // dataString = infoArray.join(";");
//     // csvContent +=
//     //   index < arrayOfObjects.length ? dataString + "\n" : dataString;
//   });
//   var encodedUri = encodeURI(csvContent);
//   var link = document.createElement("a");
//   link.setAttribute("href", encodedUri);
//   link.setAttribute(
//     "download",
//     `${name}_` + moment().format("DD-MM-YYYY") + ".csv"
//   );
//   link.click();
// };
export const actionTypes = {
  SUBJECT_LIST: "SUBJECT_LIST",
  QUERY_LIST: "QUERY_LIST",
  QUERY_VIEW: "QUERY_VIEW",
  // REFERRAL_HISTORY: "REFERRAL_HISTORY",
  // COIN_WISE_HISTORY: "COIN_WISE_HISTORY",
  // REFERRAL_INCENTIVE: "REFERRAL_INCENTIVE",
  // INFLUENCER_REFERAL_LIST: "INFLUENCER_REFERAL_LIST",
  // INFUENCER_INCENTIVE: 'INFUENCER_INCENTIVE',
  // INFUENCER_HISTORY: 'INFUENCER_HISTORY'
};
export function saveSubjectList(data) {
  return {
    type: actionTypes.SUBJECT_LIST,
    data: data,
  };
}

export function saveContactQueryList(data) {
  return {
    type: actionTypes.QUERY_LIST,
    data: data,
  };
}
export function saveContactQueryList1(data) {
  return {
    type: actionTypes.QUERY_VIEW,
    data: data,
  };
}

// export function saveRefferalIncentive(data) {
//   return {
//     type: actionTypes.REFERRAL_INCENTIVE,
//     data: data,
//   };
// }

// export function saveReferralHistory(data) {
//   return {
//     type: actionTypes.REFERRAL_HISTORY,
//     data: data,
//   };
// }

// export function saveCoinWiseHistory(data) {
//   return {
//     type: actionTypes.COIN_WISE_HISTORY,
//     data: data,
//   };
// }

// export function saveInfluencerReferralList(data) {
//   return {
//     type: actionTypes.INFLUENCER_REFERAL_LIST,
//     data: data,
//   };
// }

// export function saveInfluencerlIncentive(data) {
//   return {
//     type: actionTypes.INFUENCER_INCENTIVE,
//     data: data,
//   };
// }

// export function saveInfluencerlHistory(data) {
//   return {
//     type: actionTypes.INFUENCER_HISTORY,
//     data: data,
//   };
// }

/*
Api call for adding the referral
@method : post
*/

export function getSubjectList(params) {
  return (dispatch, getState) => {
    let state = getState();
    return SubjectServices.getSubjectList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveSubjectList(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getContactQueryList1(params) {
  console.log("getContactQueryList----", params);
  return (dispatch, getState) => {
    let state = getState();
    return SubjectServices.getContactQueryList1(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveContactQueryList1(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getContactQueryList(params) {
  console.log("getContactQueryList----", params);
  return (dispatch, getState) => {
    let state = getState();
    return SubjectServices.getContactQueryList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveContactQueryList(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getContactUsCSV(params) {
  console.log("getContactQueryList----", params);
  return (dispatch, getState) => {
    let state = getState();
    return SubjectServices.getContactQueryList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        console.log(" getInstalmentListCSV ---- ", data.data.data);
        let arrayOfObjects = data.data.data.history;
        /* ********************************* B ********************************* */
        console.log("arrayOfObjects.length ------- ", arrayOfObjects.length);
        if (!arrayOfObjects.length) {
          return;
        }
        DownLoadCSVFunction(arrayOfObjects, "CONTACT QUERIES");
        // dispatch(saveContactQueryList(data.data));
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function saveSubjectName(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return SubjectServices.AddSubject(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getSubjectList());
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function deleteSubject(data, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return SubjectServices.deleteSubject(data, id, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getSubjectList());
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function changeStatus(data, params, id) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return SubjectServices.changeStatus(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(getSubjectList(params, id));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function updateQueryTaralGoldReply(data) {
  console.log("Testing123455", data.id);
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return SubjectServices.updateQueryTaralGoldReply(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res.data.message);
        dispatch(getContactQueryList({ ticket_id: data.id }));
        dispatch(getContactQueryList1({ ticket_id: data.id }));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function updateQuery(data) {
  console.log("Testing123455", data.id);
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return SubjectServices.updateQuery(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res.data.message);
        dispatch(getContactQueryList({ ticket_id: data.id }));
        dispatch(getContactQueryList1({ ticket_id: data.id }));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

// export function enableDisableReferral(data, params) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.enableDisableReferral(data, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         toast.success(data["data"]["message"]);
//         dispatch(getReferralList(params));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function getReferralList(data) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.getReferalList(data, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         dispatch(saveReferralList(data.data));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           // toast.error(error?.data?.message);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function getIncentiveList(data, id) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.getIncentiveHistory(data, id, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         dispatch(saveRefferalIncentive(data.data));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error?.data?.message);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function addReferralIncentive(data, params, id) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.addReferralIncentive(data, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         toast.success(data["data"]["message"]);
//         dispatch(getIncentiveList(params, id));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function getReferralHistory(data) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.getReferalHistory(data, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         dispatch(saveReferralHistory(data.data));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function getCoinWiseHistory(data) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.getCoinWiseHistory(data, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         dispatch(saveCoinWiseHistory(data.data));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function getInfluencerReferralList(data) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.getInfluencerReferalList(data, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         dispatch(saveInfluencerReferralList(data.data));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           // toast.error(error?.data?.message);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function addCoupon(data, params) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.addCoupon(data, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         toast.success(data["data"]["message"]);
//         dispatch(reset("AddCouponForm"));
//         dispatch(getInfluencerReferralList(params))
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function changeInfluencerCouponStatus(data, params) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.changeInfluencerCouponStatus(data, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         toast.success(data["data"]["message"]);
//         dispatch(getInfluencerReferralList(params));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function getInfluencerIncentiveList(data, id) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.getInfluencerIncentiveHistory(data, id, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         dispatch(saveInfluencerlIncentive(data.data));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error?.data?.message);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function addInfluencerIncentive(data, params, id) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.addInfluencerIncentive(data, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         toast.success(data["data"]["message"]);
//         dispatch(getInfluencerIncentiveList(params, id));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function changeIncentiveAmount(data, params) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.changeIncentiveAmount(data,data?.id ,{
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         toast.success(data["data"]["message"]);
//         dispatch(getInfluencerIncentiveList(params, params?.id));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function changeInfluencerIncentiveStatus(data, params) {
//   console.log('----------------------data ', data, params)
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.changeInfluencerIncentiveStatus(data, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         toast.success(data["data"]["message"]);
//         dispatch(getInfluencerIncentiveList(params, params?.id));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }

// export function getInfluencerHistory(data) {
//   return (dispatch, getState) => {
//     dispatch(startLoading());
//     let state = getState();
//     return ReferralServices.getInfluencerHistory(data, {
//       jwt: state["persist"]["c_temp_user"]["token"],
//     })
//       .then(async (data) => {
//         dispatch(saveInfluencerlHistory(data.data));
//         dispatch(stopLoading());
//       })
//       .catch((error) => {
//         if (error) {
//           toast.error(error["data"]["message"]);
//         }
//         dispatch(stopLoading());
//       });
//   };
// }
