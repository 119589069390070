import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, label, Checkbox } from "semantic-ui-react";
import { Button } from "reactstrap";
import {
  getUserFund,
  setUserFund,
  getFundUsers,
  saveWalletId,
  lockUserFunds,
  unLockUserFunds,
} from "../../Redux/actons/fund.action";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { FormField } from "../../components/FormField";
import { SelectField } from "../../components/SelectField";
import { email, required } from "redux-form-validators";
import { toast } from "../../components/Toast/Toast";
import { reset } from "redux-form";
import { getCoinList, getPlan } from "../../Redux/actons/user.action";
import {
  addPlan,
  editPlanTaralGold,
} from "../../Redux/actons/installment.action";
import { Pagination, Dropdown } from "semantic-ui-react";

const options = [
  // { key: "n", text: "Minutes", value: "minutes" },
  // { key: "z", text: "Hours", value: "hours" },
  { key: "m", text: "Day", value: "day" },
  { key: "e", text: "Week", value: "week" },
  { key: "k", text: "Month", value: "month" },
  { key: "l", text: "Year", value: "year" },
];

const downpaymentType = [
  { key: "k", text: "Percentage", value: "percentage" },
  { key: "l", text: "Actual Value", value: "actual_value" },
];
const planExtensionDuration = [
  { key: "k", text: "3 Month", value: "3" },
  { key: "l", text: "6 Month", value: "6" },
];

export class EditPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet_rel_id: "",
      user_id: "",
      selectedCoin: this.props.getPlanData?.coin_id,
      downpayment: this.props.getPlanData?.down_payment,
      downpaymentType: this.props.getPlanData?.down_payment_type,
      minQty: this.props.getPlanData?.min_qty,
      maxQty: this.props.getPlanData?.max_qty,
      planTenure: this.props.getPlanData?.plan_tenure_type,
      interest_rate_coin_id: this.props.getPlanData?.interest_rate_coin_id,
      reward_coin_id: this.props.coinsList?.find(
        (data) => data.coin_symbol == this.props.getPlanData?.reward_type
      )?.coin_id,
      reward: this.props.getPlanData?.reward,
      interest_rate: this.props.getPlanData?.interest_rate,
      planTime: this.props.getPlanData?.plan_tenure_time,
      coveyanceFees: this.props.getPlanData?.conveyance_fee,
      processingFees: this.props.getPlanData?.processing_fee,
      acquisition: this.props.getPlanData?.acquisition,
      description: this.props.getPlanData?.description,
      acquisitionType: this.props.getPlanData?.acquisition_tenure_type,
      acquisitionTime: this.props.getPlanData?.acquisition_tenure_time,
      terminationTenureType: this.props.getPlanData?.termination_tenure_type,
      terminationTenureTime: this.props.getPlanData?.termination_tenure_time,
      terminationTenurefee: this.props.getPlanData?.termination_fee,
      page: "1",
      perPage: "10",
      searchKey: "",
      order: "desc",
      coin_id: "",
      status: "",
      pay_emi_min: this.props.getPlanData?.min_emi,
      pay_emi_max: this.props.getPlanData?.max_emi,
      pay_in_taral_value: this.props.getPlanData?.pay_in_taral_value,
      pay_in_inr_min: "",
      pay_in_inr_max: "",
      extension_limit: this.props.getPlanData?.extension_limit,
      plan_Extension_Duration: this.props.getPlanData?.plan_extension_duration,
      plan_Extension_Fee: this.props.getPlanData?.plan_extension,
      emi_value_taral: this.props.getPlanData?.emi_value_taral,
      acquisition_check: this.props.getPlanData?.acquisition_tenure_time != 0,
      interest_check: this.props.getPlanData?.interest_rate != 0,
      reward_check: this.props.getPlanData?.reward != 0,
    };
  }

  componentDidMount = () => {
    this.props.getCoinList();
  };

  toggle = () => {
    this.props.reset("addFundsForm");
    this.props.close();
    this.setState({
      wallet_rel_id: "",
      user_id: "",
      selectedCoin: "",
      downpayment: "",
      downpaymentType: "",
      minQty: "",
      maxQty: "",
      planTenure: "",
      interest_rate_coin_id: "",
      reward_coin_id: "",
      reward: "",
      interest_rate: "",
      planTime: "",
      coveyanceFees: "",
      processingFees: "",
      acquisition: "",
      description: "",
      acquisitionType: "",
      acquisitionTime: "",
      terminationTenureType: "",
      terminationTenureTime: "",
      terminationTenurefee: "",
      page: "1",
      perPage: "10",
      searchKey: "",
      order: "desc",
      coin_id: "",
      status: "",
      pay_emi_min: "",
      pay_emi_max: "",
      pay_in_taral_value: "",
      pay_in_inr_min: "",
      pay_in_inr_max: "",
      extension_limit: "",
      plan_Extension_Duration: "",
      plan_Extension_Fee: "",
      emi_value_taral: "",
      acquisition_check: "",
      interest_check: "",
      reward_check: "",
    });
  };

  acquisitionCheck = () => {
    this.setState({
      acquisition_check: !this.state.acquisition_check,
    });
  };
  interestCheck = () => {
    this.setState({
      interest_check: !this.state.interest_check,
    });
  };
  rewardCheck = () => {
    this.setState({
      reward_check: !this.state.reward_check,
    });
  };
  addPlan = () => {
    let dayObject = { day: 1, week: 7, month: 30, year: 365 };
    // console.log("  this.state ----", this.state.selectedCoin);
    let acquisition;
    let acquisitionTime;
    let acquisitionType;
    if (!this.state.acquisition_check) {
      acquisition = 0;
      acquisitionTime = 0;
      acquisitionType = "month";
    } else {
      acquisition = this.state.acquisition;
      acquisitionTime = this.state.acquisitionTime;
      acquisitionType = this.state.acquisitionType;
    }

    let interest_rate;
    let interest_rate_coin_id;
    if (!this.state.interest_check) {
      interest_rate = 0;
      interest_rate_coin_id = 32;
    } else {
      interest_rate = this.state.interest_rate;
      interest_rate_coin_id = this.state.interest_rate_coin_id;
    }
    let reward;
    // let reward_coin_id;
    let rewardCoinName;
    if (!this.state.reward_check) {
      reward = 0;
      rewardCoinName = "taral";
    } else {
      reward = this.state.reward;
      // reward_coin_id = this.state.reward_coin_id;

      rewardCoinName = this.props.coinsList.find(
        (data) => data.coin_id == this.state.reward_coin_id
      ).coin_symbol;
    }

    console.log(
      "------ editPlans --->>",
      this.state.selectedCoin,
      "------ minQty --->>",
      this.state.minQty,

      "------ interest_rate --->>",
      this.state.acquisition_check && !this.state.acquisition,
      this.state.interest_check && !this.state.interest_rate,
      this.state.acquisition_check && !this.state.acquisitionType,
      this.state.acquisition_check && !this.state.acquisitionTime,
      this.state.interest_check && !this.state.interest_rate_coin_id,
      this.state.reward_check && !this.state.reward_coin_id,
      this.state.reward_check && !this.state.reward
    );
    if (
      !this.state.selectedCoin ||
      !this.state.minQty ||
      !this.state.maxQty ||
      !this.state.downpayment ||
      !this.state.downpaymentType ||
      !this.state.planTime ||
      !this.state.planTenure ||
      !this.state.coveyanceFees ||
      !this.state.processingFees ||
      (this.state.acquisition_check && !this.state.acquisition) ||
      !this.state.description ||
      (this.state.acquisition_check && !this.state.acquisitionTime) ||
      (this.state.acquisition_check && !this.state.acquisitionType) ||
      !this.state.terminationTenureTime ||
      !this.state.terminationTenurefee ||
      !this.state.terminationTenureType ||
      (this.state.interest_check && !this.state.interest_rate_coin_id) ||
      (this.state.reward_check && !this.state.reward_coin_id) ||
      (this.state.reward_check && !this.state.reward) ||
      !this.state.extension_limit ||
      !this.state.plan_Extension_Duration ||
      !this.state.plan_Extension_Fee ||
      !this.state.emi_value_taral ||
      !this.state.pay_emi_min ||
      !this.state.pay_emi_max ||
      this.state.pay_in_taral_value == "" ||
      (this.state.interest_check && !this.state.interest_rate)
    ) {
      alert("All fields are required!");
    } else if (
      this.state.downpaymentType == "percentage" &&
      this.state.downpayment > 100
    ) {
      alert("Down payment not be greater than 100");
    } else if (parseFloat(this.state.minQty) >= parseFloat(this.state.maxQty)) {
      alert("Minimum quantity should be less than Maximum quantity");
    } else if (
      parseFloat(this.state.pay_emi_min) >= parseFloat(this.state.pay_emi_max)
    ) {
      alert("Minimum pay EMI should be less than Maximum pay EMI");
    } else if (this.state.downpayment <= 0) {
      alert("Down payment should be greater than 0");
    } else if (this.state.pay_in_taral_value > 100) {
      alert("Pay In Taral should not be greater than 100");
    } else if (this.state.acquisition > 100) {
      alert("Acquisition should not be greater than 100");
    } else if (this.state.interest_rate > 100) {
      alert("Interest rate should not be greater than 100");
    } else {
      // console.log('dayObject[this.state.planTenure]',dayObject[this.state.planTenure]);
      let actualPlanTime =
        this.state.planTime * dayObject[this.state.planTenure];
      let actualAcquisitionTime =
        this.state.acquisitionTime * dayObject[this.state.acquisitionType];
      // console.log('dayObject[this.state.acquisitionType]',dayObject[this.state.acquisitionType]);

      if (actualPlanTime < actualAcquisitionTime) {
        alert("Acquisition Time should be less than Plan Time");
      } else {
        // let rewardCoinName = this.props.coinsList.find(
        //   (data) => data.coin_id == this.state.reward_coin_id
        // ).coin_symbol;
        // console.log(
        //   this.props.coinsList,
        //   "  this.state ---- selectedCoin",
        //   rewardCoinName
        // );
        const param = {
          currency_id: this.state.selectedCoin,
          min_qty: this.state.minQty,
          max_qty: this.state.maxQty,
          down_payment: this.state.downpayment,
          plan_tenure_time: this.state.planTime,
          plan_tenure_type: this.state.planTenure,
          conveyance_fee: this.state.coveyanceFees,
          processing_fee: this.state.processingFees,
          acquisition: acquisition,
          description: this.state.description,
          acquisition_tenure_time: acquisitionTime,
          acquisition_tenure_type: acquisitionType,
          termination_tenure_time: this.state.terminationTenureTime,
          termination_fee: this.state.terminationTenurefee,
          termination_tenure_type: this.state.terminationTenureType,
          interest_rate_coin_id: interest_rate_coin_id,
          reward_type: rewardCoinName,
          reward: reward,
          interest_rate: interest_rate,
          down_payment_type: this.state.downpaymentType,
          min_emi: this.state.pay_emi_min,
          max_emi: this.state.pay_emi_max,
          pay_in_taral_value: this.state.pay_in_taral_value,
          // pay_in_inr_min: this.state.pay_in_inr_min,
          // pay_in_inr_max: this.state.pay_in_inr_max,
          extension_limit: this.state.extension_limit,
          plan_extension_duration: this.state.plan_Extension_Duration,
          plan_extension: this.state.plan_Extension_Fee,
          emi_value_taral: this.state.emi_value_taral,
        };

        const data = {
          page: this.state.page,
          perPage: this.state.perPage,
          searchKey: this.state.searchKey,
          order: this.state.order,
          //coin_id: this.state.selectedCoin,
          status: this.state.status,
        };

        this.props.editPlanTaralGold(param, data, this.props.getPlanData?.id);
        this.toggle();
      }
    }
  };

  render() {
    let { handleSubmit } = this.props;

    let selectedCoinName = this.props.coinsList.find(
      (data) => data.coin_id == this.state.selectedCoin
    ).value;
    console.log(
      this.props.coinsList,
      "this.props--------->>>> add savePlanData ",
      this.state.selectedCoin,
      " ---- selectedCoinName",
      selectedCoinName
    );

    return (
      <Modal isOpen={this.props.toggle}>
        <ModalHeader toggle={this.toggle}>Add Plan</ModalHeader>
        <ModalBody>
          <Form>
            <Form.Group>
              <Form.Field>
                <label>Select Currency </label>
                <Dropdown
                  className="selectUserStyle"
                  options={this.props.coinsList}
                  selection
                  // value={this.state.selectedCoin}
                  value={selectedCoinName.toUpperCase()}
                  // defaultValue={selectedCoinName.toUpperCase()}
                  onChange={(e, data) =>
                    this.setState({ selectedCoin: data.value })
                  }
                  required
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Minimum Quantity</label>
                <input
                  type="number"
                  name="minimum_qunatity"
                  min="0"
                  max="100"
                  value={this.state.minQty}
                  required
                  onChange={(e, data) =>
                    this.setState({ minQty: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Maximum Quantity</label>
                <input
                  type="number"
                  name="max_qty"
                  min="0"
                  max="100"
                  required
                  value={this.state.minQty}
                  onChange={(e, data) =>
                    this.setState({ maxQty: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Downpayment</label>
                <input
                  type="number"
                  name="downpayment"
                  min="0"
                  max="100"
                  required
                  value={this.state.downpayment}
                  onChange={(e, data) =>
                    this.setState({ downpayment: e.target.value })
                  }
                  error={
                    this.state.downpayment > 100 &&
                    this.state.downpaymentType == "percentage"
                  }
                />
                {this.state.downpayment > 100 &&
                  this.state.downpaymentType == "percentage" && (
                    <p style={{ color: "#9f3a38", fontSize: "11px" }}>
                      Down payment not be greater than 100, if downpayment type
                      is percentage
                    </p>
                  )}
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Downpayment Type</label>
                <Dropdown
                  className="selectUserStyle"
                  options={downpaymentType}
                  selection
                  value={this.state.downpaymentType}
                  onChange={(e, data) =>
                    this.setState({ downpaymentType: data.value })
                  }
                  required
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Pay In Taral (%)</label>
                <input
                  type="number"
                  name="pay_in_taral_value"
                  min="0"
                  required
                  value={this.state.pay_in_taral_value}
                  onChange={(e, data) =>
                    this.setState({ pay_in_taral_value: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Plan Time</label>
                <input
                  type="number"
                  name="plan_time"
                  min="0"
                  required
                  value={this.state.planTime}
                  onChange={(e, data) =>
                    this.setState({ planTime: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Plan Tenure </label>
                <Dropdown
                  className="selectUserStyle"
                  options={options}
                  selection
                  value={this.state.planTenure}
                  onChange={(e, data) =>
                    this.setState({
                      planTenure: data.value,
                      terminationTenureType: data.value,
                      acquisitionType: data.value,
                    })
                  }
                  required
                  error={
                    (this.state.planTime > 3 &&
                      this.state.planTenure == "week") ||
                    // (this.state.planTime > 11 &&
                    // this.state.planTenure == "month") ||
                    (this.state.planTime > 6 && this.state.planTenure == "day")
                  }
                />
                {this.state.planTime > 6 && this.state.planTenure == "day" && (
                  <p style={{ color: "#9f3a38", fontSize: "11px" }}>
                    Please Change Plan tenure to week
                  </p>
                )}
                {this.state.planTime > 3 && this.state.planTenure == "week" && (
                  <p style={{ color: "#9f3a38", fontSize: "11px" }}>
                    Please Change Plan tenure to month
                  </p>
                )}
                {/* {this.state.planTime > 11 &&
                  this.state.planTenure == "month" && (
                    <p style={{ color: "#9f3a38", fontSize: "11px" }}>
                      Please Change Plan tenure to year
                    </p>
                  )} */}
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Conveyance Fees </label>
                <input
                  type="number"
                  name="coveyance_fees"
                  min="0"
                  max="100"
                  required
                  value={this.state.coveyanceFees}
                  onChange={(e, data) =>
                    this.setState({ coveyanceFees: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Processing Fees </label>
                <input
                  type="number"
                  name="processing_fees"
                  min="0"
                  max="100"
                  required
                  value={this.state.processingFees}
                  onChange={(e, data) =>
                    this.setState({ processingFees: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Termination Time</label>
                <input
                  type="number"
                  name="termination_tenure_time"
                  min="0"
                  required
                  value={this.state.terminationTenureTime}
                  onChange={(e, data) =>
                    this.setState({ terminationTenureTime: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Termination Type</label>
                <Dropdown
                  className="selectUserStyle"
                  options={options}
                  selection
                  value={this.state.terminationTenureType}
                  onChange={(e, data) =>
                    this.setState({ terminationTenureType: data.value })
                  }
                  required
                  // error={
                  //   this.state.planTenure !== this.state.terminationTenureType
                  // }
                  error={
                    (this.state.terminationTenureTime > 3 &&
                      this.state.terminationTenureType == "week") ||
                    // (this.state.planTime > 11 &&
                    //   this.state.planTenure == "month") ||
                    (this.state.terminationTenureTime > 6 &&
                      this.state.terminationTenureType == "day")
                  }
                  // error={
                  //   this.state.planTenure !== this.state.terminationTenureType
                  // }
                />
                {this.state.terminationTenureTime > 6 &&
                  this.state.terminationTenureType == "day" && (
                    <p style={{ color: "#9f3a38", fontSize: "11px" }}>
                      Please Change termination tenure to week
                    </p>
                  )}
                {this.state.terminationTenureTime > 3 &&
                  this.state.terminationTenureType == "week" && (
                    <p style={{ color: "#9f3a38", fontSize: "11px" }}>
                      Please Change termination tenure to month
                    </p>
                  )}

                {/* {this.state.planTenure !== this.state.terminationTenureType && (
                  <p style={{ color: "#9f3a38", fontSize: "11px" }}>
                    Your plan type is in "{this.state.planTenure}", show
                    Termination type must be in "{this.state.planTenure}"
                  </p>
                )} */}
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Termination Fee</label>
                <input
                  type="number"
                  name="termination_tenure_fee"
                  min="0"
                  required
                  value={this.state.terminationTenurefee}
                  onChange={(e, data) =>
                    this.setState({ terminationTenurefee: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>

            {/* PLAN EXTENSION */}
            <Form.Group>
              <Form.Field>
                <label>Plan Extension </label>
                <input
                  type="text"
                  name="extension_limit"
                  // min="0"
                  required
                  value={this.state.extension_limit}
                  onChange={(e, data) =>
                    this.setState({ extension_limit: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Plan Extension Fee</label>
                <input
                  type="number"
                  name="plan_Extension_Fee"
                  min="0"
                  required
                  value={this.state.plan_Extension_Fee}
                  onChange={(e, data) =>
                    this.setState({ plan_Extension_Fee: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Plan Extension Duration </label>
                <Dropdown
                  className="selectUserStyle"
                  options={planExtensionDuration}
                  selection
                  value={this.state.plan_Extension_Duration}
                  onChange={(e, data) =>
                    this.setState({ plan_Extension_Duration: data.value })
                  }
                  required
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Minimum Pay EMI</label>
                <input
                  type="number"
                  name="pay_emi_min"
                  min="0"
                  required
                  value={this.state.pay_emi_min}
                  onChange={(e, data) =>
                    this.setState({ pay_emi_min: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Maximum Pay EMI</label>
                <input
                  type="number"
                  name="pay_emi_max"
                  min="0"
                  required
                  value={this.state.pay_emi_max}
                  onChange={(e, data) =>
                    this.setState({ pay_emi_max: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>EMI Value Taral (%)</label>
                <input
                  type="number"
                  name="emi_value_taral"
                  min="0"
                  required
                  value={this.state.emi_value_taral}
                  onChange={(e, data) =>
                    this.setState({ emi_value_taral: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>

            <div className=" custom-control-alternative custom-checkbox">
              <Checkbox
                label="Acquisition"
                id=" customCheckLogin1"
                checked={this.state.acquisition_check}
                onChange={(e, data) => this.acquisitionCheck()}
              />
            </div>
            {this.state.acquisition_check && (
              <>
                <Form.Group>
                  <Form.Field>
                    <label>Acquisition (%)</label>
                    <input
                      type="number"
                      name="acquisition"
                      min="0"
                      max="100"
                      required
                      value={this.state.acquisition}
                      onChange={(e, data) =>
                        this.setState({ acquisition: e.target.value })
                      }
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <label>Acquisition Time</label>
                    <input
                      type="number"
                      name="acquisition_time"
                      min="0"
                      required
                      value={this.state.acquisitionTime}
                      onChange={(e, data) =>
                        this.setState({ acquisitionTime: e.target.value })
                      }
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <label>Acquisition Type </label>
                    <Dropdown
                      className="selectUserStyle"
                      options={options}
                      selection
                      value={this.state.acquisitionType}
                      onChange={(e, data) =>
                        this.setState({ acquisitionType: data.value })
                      }
                      required
                      // error={
                      //   this.state.planTenure !== this.state.acquisitionType
                      // }

                      error={
                        (this.state.acquisitionTime > 3 &&
                          this.state.acquisitionType == "week") ||
                        // (this.state.planTime > 11 &&
                        //   this.state.planTenure == "month") ||
                        (this.state.planTacquisitionTimeime > 6 &&
                          this.state.acquisitionType == "day")
                      }
                    />
                    {this.state.acquisitionTime > 6 &&
                      this.state.acquisitionType == "day" && (
                        <p style={{ color: "#9f3a38", fontSize: "11px" }}>
                          Please Change acquisition to week
                        </p>
                      )}
                    {this.state.acquisitionTime > 3 &&
                      this.state.acquisitionType == "week" && (
                        <p style={{ color: "#9f3a38", fontSize: "11px" }}>
                          Please Change acquisition to month
                        </p>
                      )}

                    {/* {this.state.planTenure !== this.state.acquisitionType && (
                      <p style={{ color: "#9f3a38", fontSize: "11px" }}>
                        Your plan type is in "{this.state.planTenure}", show
                        Acquisition type must be in "{this.state.planTenure}"
                      </p>
                    )} */}
                  </Form.Field>
                </Form.Group>
              </>
            )}

            <div className=" custom-control-alternative custom-checkbox">
              <Checkbox
                label="Interest"
                id=" customCheckLogin2"
                checked={this.state.interest_check}
                onChange={(e, data) => this.interestCheck()}
              />
            </div>
            {this.state.interest_check && (
              <>
                <Form.Group>
                  <Form.Field>
                    <label>Interest Rate Coin </label>
                    <Dropdown
                      className="selectUserStyle"
                      options={this.props.coinsList}
                      selection
                      value={this.state.interest_rate_coin_id}
                      onChange={(e, data) =>
                        this.setState({ interest_rate_coin_id: data.value })
                      }
                      required
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <label>Interest Rate (%)</label>
                    <input
                      type="number"
                      name="interest_rate"
                      min="0"
                      required
                      value={this.state.interest_rate}
                      onChange={(e, data) =>
                        this.setState({ interest_rate: e.target.value })
                      }
                    />
                  </Form.Field>
                </Form.Group>
              </>
            )}

            <div className=" custom-control-alternative custom-checkbox">
              <Checkbox
                label="Reward"
                id=" customCheckLogin"
                checked={this.state.reward_check}
                onChange={(e, data) => this.rewardCheck()}
              />
            </div>
            {this.state.reward_check && (
              <>
                <Form.Group>
                  <Form.Field>
                    <label>Reward Coin </label>
                    <Dropdown
                      className="selectUserStyle"
                      options={this.props.coinsList}
                      selection
                      value={this.state.reward_coin_id}
                      onChange={(e, data) => {
                        this.setState({ reward_coin_id: data.value });
                      }}
                      required
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <label>Reward </label>
                    <input
                      type="number"
                      name="reward"
                      min="0"
                      required
                      value={this.state.reward}
                      onChange={(e, data) =>
                        this.setState({ reward: e.target.value })
                      }
                    />
                  </Form.Field>
                </Form.Group>
              </>
            )}
          </Form>
        </ModalBody>
        {this.state.planTime > 6 && this.state.planTenure == "day" && (
          <p
            style={{
              color: "#9f3a38",
              fontSize: "11px",
              textAlign: "end",
              paddingRight: "23px",
            }}
          >
            Please Change Plan tenure to week
          </p>
        )}
        {this.state.planTime > 3 && this.state.planTenure == "week" && (
          <p
            style={{
              color: "#9f3a38",
              fontSize: "11px",
              textAlign: "end",
              paddingRight: "23px",
            }}
          >
            Please Change Plan tenure to month
          </p>
        )}
        {this.state.planTime > 11 && this.state.planTenure == "month" && (
          <p
            style={{
              color: "#9f3a38",
              fontSize: "11px",
              textAlign: "end",
              paddingRight: "23px",
            }}
          >
            Please Change Plan tenure to year
          </p>
        )}
        <ModalFooter>
          <Button color="error" onClick={() => this.toggle()}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={() => this.addPlan()}
            disabled={
              (this.state.planTime > 6 && this.state.planTenure == "day") ||
              (this.state.planTime > 3 && this.state.planTenure == "week") ||
              (this.state.acquisitionTime > 6 &&
                this.state.acquisitionType == "day") ||
              (this.state.acquisitionTime > 3 &&
                this.state.acquisitionType == "week") ||
              (this.state.terminationTenureTime > 6 &&
                this.state.terminationTenureType == "day") ||
              (this.state.terminationTenureTime > 3 &&
                this.state.terminationTenureType == "week")
            }
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coinsList: state.user.coinsList,
    getPlanData: state.user.savePlanData?.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCoinList: () => dispatch(getCoinList()),
    getPlan: (data) => dispatch(getPlan(data)),
    reset: (data) => dispatch(reset(data)),
    editPlanTaralGold: (data, params, id) =>
      dispatch(editPlanTaralGold(data, params, id)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "addFundsForm" })(EditPlan));
