import { UserService } from "../../Services/UserServices";
import { toast } from "../../components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";

export const actionTypes = {
  SAVE_ALL_NOTIFICATION: "SAVE_ALL_NOTIFICATION",
};

export function saveNotifications(data) {
  return {
    type: actionTypes.SAVE_ALL_NOTIFICATION,
    data: data,
  };
}

//get fund list
export function getAdminNotification(params) {
  return (dispatch, getState) => {
    let state = getState();
    return UserService.getAdminNotification(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveNotifications(data.data.data));

        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
