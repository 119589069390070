import { actionTypes } from "../actons/subject.action";

const initialState = {
  subjectList: [],
  queryList: [],
  queryView: {},
};

const subjectList = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBJECT_LIST:
      return {
        ...state,
        subjectList: action.data.data,
      };
    case actionTypes.QUERY_LIST:
      return {
        ...state,
        queryList: action.data.data,
      };
    case actionTypes.QUERY_VIEW:
      return {
        ...state,
        queryView: action.data.data,
      };
    // case actionTypes.REWARD_HISTORY:
    //   return {
    //     ...state,
    //     rewardHistoryList: action.data.data,
    //   };

    default:
      return state;
  }
};

export default subjectList;
