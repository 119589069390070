import React, { Component } from "react";
import { Button } from "reactstrap";
import AuthForm from "./AuthForm.js";
import { connect } from "react-redux";
import { verifyGoogleAuthRequest } from "../../Redux/actons/security.action";
import { Redirect } from "react-router-dom";

const AuthContainer = (props) => {
  function passAuthValue(value) {
    props.changeAuthType(value);
  }
  return (
    <React.Fragment>
      {/* .LoginBox .LoginBoxForm */}

      <div className="twoFactorauth">
        <Button onClick={() => passAuthValue("googleAuth")}>
          {" "}
          Google Auth{" "}
        </Button>
      </div>
    </React.Fragment>
  );
};

class TwoFactorauth extends Component {
  constructor(props) {
    super(props);
    this.state = { type: "allAuth" };
  }

  componentDidMount = () => {
    let { google2fa_status } = this.props.user;

    if (google2fa_status === 1) {
      this.setState({ type: "googleAuth" });
    }
  };
  changeAuthState = (type) => {
    this.setState({ type });
  };

  handleSubmit = (values) => {
    let { type } = this.state;
    let { verifyAuthToken, verifygoogleAuth } = this.props;
    if (type === "googleAuth") {
      values["isEnabled"] = true;
      //  verifyAuthToken(values);
      verifygoogleAuth(values);
    }

    // else {
    //   verifygoogleAuth(values);
    // }
  };

  render() {
    let { type } = this.state;
    let { token, user } = this.props;
    if (token["token"] && user.role == "1") {
      return <Redirect to={"/admin/index"} />;
    }
    if (token["token"] && user.role == "2") {
      return <Redirect to={"/admin/account-setting"} />;
    }
    return (
      <div>
        <div className="LoginBox">
          {/* <div className="LoginBoxtext">
            <p>
              <span>Publet Wallet admin</span>
            </p>
          </div> */}
          <div className="LoginBoxForm">
            {type === "allAuth" && (
              <AuthContainer changeAuthType={this.changeAuthState} />
            )}
            {type === "googleAuth" && (
              <AuthForm type={type} onSubmit={this.handleSubmit} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log("DAIVIK", state);
  return {
    token: state.persist["c_user"],
    user: state.user["user"],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifygoogleAuth: (values) => dispatch(verifyGoogleAuthRequest(values)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorauth);
