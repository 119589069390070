import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";

export class Level2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { userDetail } = this.props;
    console.log("UserDetails -----", userDetail);
    return (
      <div>
        {/* <Button color="danger">X</Button> */}

        <Modal
          isOpen={this.props.modal1}
          toggle={this.props.toggle1}
          className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.toggle1}>
            <h1>
              Status <span>(Verified/Unverified Info)</span>
            </h1>
          </ModalHeader>
          <ModalBody>
            <div className="userNameList">
              <div className="itemStyle">
                <label>Email-Id:</label>
                <span>
                  {userDetail?.email_status == 1
                    ? userDetail?.email
                    : "Pending"}
                </span>
              </div>
              <div className="itemStyle">
                <label>Mobile:</label>
                <span>
                  {userDetail?.mobile_status == 1
                    ? userDetail?.mobile
                    : "Pending"}
                </span>
              </div>
            </div>
          </ModalBody>
          {/* <ModalFooter style={{ backgroundColor: "#1e1e1e" }}>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter> */}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Level2);
