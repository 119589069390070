import React, { Component } from "react";
import { Card, Container, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Tab } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";
import WithdrawList from "../WithdrawList/WithdrawList";
import MultisenderTables from "../WithdrawList/MultisenderTables";
const panes = [
  {
    menuItem: "Withdrawal List",
    render: () => (
      <Tab.Pane>
        {" "}
        <WithdrawList statusType={"withdraw"} />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "MultiSender",
    render: () => (
      <Tab.Pane>
        <MultisenderTables statusType={"MultiSender"} />
      </Tab.Pane>
    ),
  },
];

export class WithdrawContainer extends Component {
  render() {
    return (
      <div>
        <Header headerTitle="Withdrawal management" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="transaction-fee-management">
                Transaction Management
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Withdrawal Management</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Container className="contentBlock" fluid>
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Card className="shadow">
                <Tab panes={panes} />
              </Card>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default WithdrawContainer;
