import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { API_HOST } from "../../constants/index";
import { Pagination, Form, Dropdown, Menu } from "semantic-ui-react";
import { required } from "redux-form-validators";
import { Field, reduxForm, change } from "redux-form";
import CustomHeader from "../../components/Headers/CustomHeader";
import { FormField } from "../../components/FormField";
import { SelectField } from "../../components/SelectField";

import { withRouter } from "react-router";
import {
  getOtcUserList,
  updateOtcLimit,
  getOtcLimitByCoin,
  saveOtcLimitByCoin,
  getOtcLimitForAllCoin,
  saveOtcLimitForAllCoin,
} from "../../Redux/actons/otc.action";
import { getCoinList } from "../../Redux/actons/user.action";

import { Link } from "react-router-dom";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
const listFilter = [
  { key: "o", text: "None", value: "none" },

  { key: "v", text: "Verified", value: "1" },
  { key: "p", text: "Pending", value: "0" },
];

export class OtcListManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
      sortBy: "",
      open: false,
      userInfo: {},
      isAmount: false,
      viewAllLimit: false,
      filterType: "",
    };
  }
  componentWillReceiveProps = (nextProps) => {
    if (this.props.otc_limit_coin !== nextProps.otc_limit_coin) {
      if (nextProps.otc_limit_coin !== "") {
        this.setFormValues(nextProps.otc_limit_coin);
      }
    }
  };
  userToggle = () => {
    this.setState({ viewAllLimit: !this.state.viewAllLimit });
  };
  setFormValues = (data) => {
    console.log("DATA", data);
    if (data.length != 0) {
      this.setState({ isAmount: true });
      this.props.dispatch(
        change("OtcListManagement", "otc_limit", data[0].user_otc_limit)
      );
    }
  };
  openModel = (data) => {
    console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
    this.props.saveOtcLimitByCoin([]);

    this.setState({ userInfo: data, open: true, isAmount: false });
  };
  getDetailsCoin = (e, data) => {
    this.setState({ defaultCoin: data });
    let param = {
      user_id: this.state.userInfo.user_id,
      coin_id: data,
    };
    this.props.getOtcLimitByCoin(param);
  };

  getOtcLimitForAllCoin = (usersList) => {
    this.props.saveOtcLimitForAllCoin([]);
    let param = {
      user_id: usersList.user_id,
      coin_id: "",
    };
    this.props.getOtcLimitForAllCoin(param);
    this.setState({
      viewAllLimit: true,
    });
  };
  componentDidMount = () => {
    this.getUserList();
    this.props.getCoinList();
  };

  getUserList = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      filterType: this.state.filterType,
    };
    this.props.getOtcUserList(data);
  };

  filterOtcBy = (e, data) => {
    console.log("KKKK", data.value);

    if (data.value !== "none") {
      this.setState({ filterType: data.value }, () => {
        this.getUserList();
      });
    } else {
      this.setState({ filterType: "" }, () => {
        this.getUserList();
      });
    }
  };
  // //page change
  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getUserList();
    });
  };
  close = () => {
    this.setState({ open: false });
    this.props.saveOtcLimitByCoin([]);
  };
  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ order: data.value }, () => {
      this.getUserList();
    });
  };
  //search
  onSearchList = (abc) => {
    console.log(abc);
    this.setState({ searchKey: abc, page: 1 }, () => {
      this.getUserList();
    });
  };
  updateOtcLimit = (values) => {
    console.log("values", values);
    let data = {
      user_id: this.state.userInfo.user_id,
      otc_limit: values.otc_limit,
      coin_id: this.state.defaultCoin,
    };

    let fdata = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.updateOtcLimit(data, fdata);
    this.setState({ open: false });
  };

  downLoadUserList = () => {
    window.location.href = `${API_HOST}api/v1/admin/otc/download/otclist`;
  };

  render() {
    let { handleSubmit } = this.props;
    console.log("123", this.props.otc_limit_All_coin);
    return (
      <div>
        <CustomHeader />
        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">OTC User List</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onChange={(e) => this.onSearchList(`${e.target.value}`)}
                      ></Input>
                      <Dropdown
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.filterBy(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={listFilter}
                        placeholder="Filter by status"
                        selection
                        // value={this.state.filter}
                        onChange={(e, data) => this.filterOtcBy(e, data)}
                      />
                    </div>
                    <div
                      className="selectSearchOption"
                      style={{ marginTop: 20 }}
                    >
                      <Link to="Otc-Level-setting">
                        <Button color="success btnMamagePermission">
                          Manage Permission of level
                        </Button>
                      </Link>

                      <Link to="otc-transaction-fee">
                        <Button color="success btnMamagePermission">
                          Set OTC Transaction Fee
                        </Button>
                      </Link>
                      <Link to="otc-trader">
                        <Button color="success btnMamagePermission">
                          Trader List
                        </Button>
                      </Link>
                      <Link to="otc-order">
                        <Button color="success btnMamagePermission">
                          Order List
                        </Button>
                      </Link>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.downLoadUserList()}
                      >
                        Download Otc list
                      </Button>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        {/* <th scope="col">Name</th> */}
                        <th scope="col">ID (Email)</th>
                        <th scope="col"> Name</th>

                        <th scope="col">KYC Level 1</th>
                        <th scope="col">KYC Level 2</th>
                        <th scope="col">OTC Limit</th>

                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.getOtcList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          {/* <td>{usersList.email}</td> */}
                          <Link to="user-management">
                            <td>
                              {usersList.email ? usersList.email : "None"}
                            </td>
                          </Link>
                          <td>{usersList.name}</td>
                          {/* <td>{usersList.otc_limit}</td>
                           */}

                          <td>
                            {usersList.mobile_status == 1 &&
                            usersList.email_status == 1
                              ? "Verified"
                              : usersList.email_status == 0 &&
                                usersList.email_status == 0
                              ? "Pending"
                              : "Unverified"}
                          </td>

                          <td>
                            {usersList.kyc_status == 1
                              ? "Pending"
                              : usersList.kyc_status == 2
                              ? "Verified"
                              : usersList.kyc_status == 3
                              ? "Unverified"
                              : "Docs not uploaded"}
                          </td>
                          <td>
                            <span className="mb-0 text-sm">
                              <Button
                                onClick={() =>
                                  this.getOtcLimitForAllCoin(usersList)
                                }
                                color="success"
                              >
                                View OTC Limits
                              </Button>
                            </span>
                          </td>
                          <td>
                            <span className="mb-0 text-sm">
                              <Button
                                color="success"
                                onClick={() => this.openModel(usersList)}
                              >
                                Change OTC Limit
                              </Button>
                            </span>
                          </td>
                        </tr>
                      ))}
                      {this.props.getOtcList.length == 0 && (
                        <td>No records found</td>
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      {this.props.getOtcList.length != 0 && (
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalOtcRecords / this.state.perPage
                          )}
                        />
                      )}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        {/* //comment modal */}
        <Modal
          isOpen={this.state.open}
          // onClose={() => this.setState({ open: false })}
          toggle={this.close}
        >
          <ModalHeader toggle={this.close}>Update OTC Limit</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.updateOtcLimit)}>
              <Form.Group>
                <Form.Field>
                  <label>Select Coin </label>
                  <Field
                    name="coin_id"
                    search={false}
                    selectOnBlur={false}
                    validate={[required()]}
                    component={SelectField}
                    // defaultValue={this.state.defaultCoin}
                    children={this.props.coinsList.filter(
                      (c) =>
                        c.coin_symbol == "ida" ||
                        c.coin_symbol == "btc" ||
                        c.coin_symbol == "btcv"
                    )}
                    placeholder="Select "
                    onChange={(e, data) => this.getDetailsCoin(e, data)}
                  ></Field>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                {this.state.isAmount && (
                  <Form.Field>
                    <label>Limit </label>
                    <Field
                      type="text"
                      name="otc_limit"
                      validate={[required()]}
                      component={FormField}
                    ></Field>
                  </Form.Field>
                )}
              </Form.Group>
              <Button
                color="error"
                onClick={() => this.setState({ open: false })}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="success"
                disabled={!this.state.isAmount}
              >
                Update
              </Button>
            </Form>
          </ModalBody>
        </Modal>

        {/* all limit  */}
        <div>
          <Modal
            isOpen={this.state.viewAllLimit}
            className={[this.props.className, "modalCustom"]}
          >
            <ModalHeader toggle={this.userToggle}>
              <h4 style={{ color: "#fff" }}>OTC Limit</h4>
            </ModalHeader>
            <ModalBody>
              <div>
                <div>
                  <Table
                    className="align-items-center table-flush table_customStyle"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th>Coin</th>
                        <th>Limit</th>
                      </tr>
                    </thead>

                    {this.props.otc_limit_All_coin.length > 0 && (
                      <tbody>
                        {this.props.otc_limit_All_coin
                          .filter(
                            (c) =>
                              c.coin_symbol == "ida" ||
                              c.coin_symbol == "btc" ||
                              c.coin_symbol == "btcv"
                          )
                          .map((a, index) => (
                            <tr className="table-tr" key={index}>
                              <td>{a.coin_symbol.toUpperCase()}</td>

                              <td>{a.user_otc_limit}</td>
                            </tr>
                          ))}

                        {this.props.otc_limit_All_coin.filter(
                          (c) =>
                            c.coin_symbol == "ida" ||
                            c.coin_symbol == "btc" ||
                            c.coin_symbol == "btcv"
                        ).length == 0 && (
                          <tr style={{ color: "#fff" }}>No records found</tr>
                        )}
                      </tbody>
                    )}
                  </Table>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log("@@@@@@>>>>>>>ritu.", state);
  return {
    getOtcList: state.otc.getOtcList,
    totalOtcRecords: state.otc.totalOtcRecords,
    coinsList: state.user.coinsList,
    otc_limit_coin: state.otc.otc_limit_coin,
    otc_limit_All_coin: state.otc.otc_limit_All_coin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOtcUserList: (data) => dispatch(getOtcUserList(data)),
    updateOtcLimit: (data, fdata) => dispatch(updateOtcLimit(data, fdata)),
    getCoinList: () => dispatch(getCoinList()),

    getOtcLimitByCoin: (data) => dispatch(getOtcLimitByCoin(data)),
    saveOtcLimitByCoin: (data) => dispatch(saveOtcLimitByCoin(data)),
    getOtcLimitForAllCoin: (data) => dispatch(getOtcLimitForAllCoin(data)),
    saveOtcLimitForAllCoin: (data) => dispatch(saveOtcLimitForAllCoin(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "OtcListManagement" })(OtcListManagement))
);
