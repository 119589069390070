import { actionTypes } from "../actons/fund.action";

const initialState = {
  getFundsList: [],
  getViewFundsList: [],
  fundsCsvFileList: [],
  sampleFilesList: [],
  fundRecords: "",
  walletId: {},
  allFundUsers: [],
  hotWalletBalance: [],
  coldHistory: [],
  fundHistory: [],
  fundHistoryTotal: 0,
  idpBalance: 0,
  userBalance: [],
  singleUserBalance: [],
  lockFundList: [],
  lockFundHistory: [],
  openSecurityModal: false,
  openSendColdModal: false,
};

const funds = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_FUND_DETAILS:
      let final = [...action.data];
      final.forEach((element) => {
        element.text = element.email;
        element.value = element.user_id;
      });
      return {
        ...state,
        getFundsList: final,
      };
    case actionTypes.TOTAL_FUND_RECORDS:
      return {
        ...state,
        fundRecords: action.data,
      };

    case actionTypes.SAVE_FUND_FILE_DETAILS:
      let finall = [...action.data];
      finall.forEach((element) => {
        element.text = element.email;
        element.value = element.user_id;
      });
      return {
        ...state,
        fundsCsvFileList: finall,
      };

    case actionTypes.SAVE_SAMPLE_FILE_LIST:
      let files = [...action.data];

      files.forEach((element) => {
        element.key = element.id;
        element.value = element.file_name;
        element.text = element.type.toUpperCase();
      });
      return {
        ...state,
        sampleFilesList: files,
      };

    case actionTypes.SAVE_USER_WALLET_ID:
      return {
        ...state,
        walletId: action.data,
      };
    case actionTypes.SAVE_ALL_FUND__USER:
      let finals = [...action.data];
      finals.forEach((element) => {
        element.text = element.email;
        element.value = element.user_id;
      });
      return {
        ...state,
        allFundUsers: finals,
      };

    case actionTypes.SAVE_HOT_WALLET_BALANCE:
      return {
        ...state,
        hotWalletBalance: action.data.data,
        idpBalance: action.data.idpBal,
        totalPages: action.data.meta.total,
      };

    case actionTypes.SAVE_COLD_WALLET_HISTORY:
      return {
        ...state,
        coldHistory: action.data,
        total: action.total,
      };

    // case actionTypes.SAVE_VIEW_FUND_DETAILS:
    //   let final1 = [...action.data];
    //   final1.forEach((element) => {
    //     element.text = element.email;
    //     element.value = element.user_id;
    //   });
    //   return {
    //     ...state,
    //     getViewFundsList: final1,
    //   };
    case actionTypes.SAVE_VIEW_FUND_DETAILS:
      return {
        ...state,
        getViewFundsList: action.data,
        // fundHistoryTotal: action.data.meta.total,
      };
    case actionTypes.SAVE_FUND_HISTORY:
      return {
        ...state,
        fundHistory: action.data.data,
        fundHistoryTotal: action.data.meta.total,
      };
    case actionTypes.SAVE_USER_BALANCE:
      return {
        ...state,
        userBalance: action.data.data,
      };
    case actionTypes.SAVE_LOCK_FUND_LIST:
      return {
        ...state,
        lockFundList: action.data,
      };
    case actionTypes.SAVE_UNIQUE_USER_BALANCE:
      return {
        ...state,
        singleUserBalance: action.data.data,
      };
    case actionTypes.SAVE_LOCK_FUND_HISTORY:
      return {
        ...state,
        lockFundHistory: action.data.data,
      };
    case actionTypes.OPEN_CLOSE_SECURITY:
      return {
        ...state,
        openSecurityModal: action.data,
      };
    case actionTypes.OPEN_CLOSE_COLD_MODAL:
      return {
        ...state,
        openSendColdModal: action.data,
      };
    default:
      return state;
  }
};

export default funds;
