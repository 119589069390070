import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import {
  setDailyWithdrawLimit,
  getDailyWithdrawLimit,
} from "../../Redux/actons/transaction.action";
import { Form } from "semantic-ui-react";
import { Field, reduxForm, change } from "redux-form";
import { FormField } from "../../components/FormField";
import { required } from "redux-form-validators";
// import { amountLimit } from "../../validators/customValidator";

export class SetWithdrawDailyLimit extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    this.props
      .getDailyWithdrawLimit({ type: "daily_withdraw_limit" })
      .then(async () => {
        await this.props.dispatch(
          change(
            "SetDailyWithdrawLimitForm",
            "limit",
            this.props?.dailyWithdawLimit
          )
        );
      });
  };
  updateForm = (values) => {
    let data = {
      type: "daily_withdraw_limit",
      value: values.limit,
    };
    console.log("data >> ", data);
    this.props.setDailyWithdrawLimit(data);
    this.props.openDailyWithDrawLimitModal();
  };
  render() {
    // console.log('this.props222 >> ',this.props);

    let { handleSubmit } = this.props;
    return (
      <div>
        {/* <Button color="danger">X</Button> */}

        <Modal
          isOpen={this.props.dailyWithdrawLimitModal}
          toggle={this.props.openDailyWithDrawLimitModal}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.openDailyWithDrawLimitModal}>
            Set Daily Withdraw Limit
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.updateForm)}>
              <Form.Group>
                <Form.Field>
                  <label>Limit(INR)</label>
                  <Field
                    placeholder="Limit"
                    name="limit"
                    // value={this.state.fee}
                    component={FormField}
                    type="number"
                    validate={[required()]}
                  />

                  {/* <input
                        type="number"
                        name="limit"
                        value={this.state.limit}
                        validate={[required()]}
                        component={FormField}
                        onChange={(e, data) =>
                            this.setState({ limit: e.target.value })
                          }
                        ></input> */}
                </Form.Field>
              </Form.Group>
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dailyWithdawLimit: state.transaction.dailyWithdawLimit,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDailyWithdrawLimit: (data) => dispatch(getDailyWithdrawLimit(data)),
    setDailyWithdrawLimit: (data) => dispatch(setDailyWithdrawLimit(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "SetDailyWithdrawLimitForm" })(SetWithdrawDailyLimit));
// export default connect(mapStateToProps, mapDispatchToProps)(SetWithdrawDailyLimit);
