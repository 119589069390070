import React, { Component, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
  Label,
  FormGroup,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { API_HOST } from "../../../constants/index";
import { Pagination, Confirm, Dropdown, Menu, Header } from "semantic-ui-react";
import "../../UserManagement/User/UserManagement.scss";
import CustomHeader from "../../../components/Headers/CustomHeader";
import { withRouter } from "react-router";
import { getLogReport } from "../../../Redux/actons/SubAdmin.action";
import { listDateFormat } from "../../../utils/utils";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { FormField } from "../../../components/FormField";
import Security from "../AddNewSubAdmin/Security";
import { options } from "i18next";

const renderLabel = (label) => ({
  color: "blue",
  content: `${label.text}`,
  icon: "check",
});

export class LogReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      searchKey: "",
      module: "",
      perPage: 50,
      page: 1,
      sort: "asc",
      openConfirm: false,
      removeId: "",
      changePermissionId: "",
      open: false,
      options: [],
    };
  }

  componentDidMount = () => {
    console.log(this.props.nav);
    if (this.props.location.state) {
      localStorage.setItem("subAdminId", this.props.location.state);
      this.getLogReport(this.props.location.state);
    } else {
      this.getLogReport(localStorage.getItem("subAdminId"));
    }
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  changePermission = (id, permissions) => {
    console.log("pppp", permissions);
    this.setState({
      changePermissionId: id,
      //   permissions: permissions != null ? permissions : [],
      open: true,
    });
    let data = [];
    if (permissions !== null) {
      this.props.permissions.forEach((element, index) => {});
    }
    console.log("hey", data);
    this.setState({ permissions: data });
  };

  downLoadUserList = () => {
    window.location.href = `${API_HOST}api/v1/subadmin/download/list`;
  };

  getLogReport = (subId) => {
    let subAdminId = subId;
    let data = {
      user_id: subAdminId,
      module: this.state.module,
      page: this.state.page,
      prepage: this.state.perPage,
    };
    this.props.getLogReport(data);
  };

  //page change
  changeApprovedPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getLogReport(localStorage.getItem("subAdminId"));
    });
  };
  //search
  onSearchWithdraw = (abc) => {
    this.setState({ searchKey: abc, page: 1 }, () => {
      this.getLogReport(localStorage.getItem("subAdminId"));
    });
  };
  openConfirmApproveModal = (id) => {
    this.setState({ openConfirm: true, removeId: id });
  };
  close = () => {
    this.setState({ openConfirm: false });
  };

  onSelectPermissions = (e, id, index) => {
    let permissions = [...this.state.permissions];
    if (e.target.checked) {
      permissions[index] = id;
      this.setState({ permissions: permissions });
    } else {
      permissions[index] = -1;
      this.setState({ permissions: permissions });
    }
  };

  sortBy = (e, data) => {
    console.log(this.state.module);
    this.setState({ module: data.value }, () => {
      this.getLogReport(localStorage.getItem("subAdminId"));
    });
  };

  render() {
    let { handleSubmit } = this.props;
    console.log("ALL12", this.state);
    console.log("ALLProp", this.props);

    return (
      <div>
        {/* approve disaprove confirm */}
        <div>
          <Security />

          <Modal isOpen={this.state.openConfirm} toggle={this.close}>
            <ModalHeader toggle={this.close}>
              Are you sure you want to delete the record ?
            </ModalHeader>
            <ModalBody></ModalBody>
            <ModalFooter>
              <Button color="error" onClick={() => this.close()}>
                Cancel
              </Button>
              {/* <Button color="success" onClick={() => this.onConfirmation()}> */}
              {/* Confirm */}
              {/* </Button> */}
            </ModalFooter>
          </Modal>
        </div>
        <CustomHeader />

        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="sub-admin-management">Subadmin Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Log Report</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Action type</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Dropdown
                        className="selectUserStyle"
                        options={this.props.logModules}
                        placeholder="Filter"
                        selection
                        value={this.state.module}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />
                    </div>
                  </CardHeader>
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        {!this.props.openSecurityModal && (
          <div>
            <Container className="contentBlock" fluid>
              {/* Table */}
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">IP</th>
                          <th scope="col">Date & Time</th>
                          <th scope="col">Module</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.logDetailList.map((logDetail, index) => (
                          <tr className="table-tr" key={index}>
                            <td>
                              {logDetail.login_ip ? logDetail.login_ip : "None"}
                            </td>

                            <td>{listDateFormat(logDetail.created_at)}</td>
                            <td>{logDetail.module.toUpperCase()}</td>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: logDetail?.message,
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                        {this.props.logDetailList.length == 0 && (
                          <td>No record</td>
                        )}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        {this.props.totalRecords > 0 && (
                          <Pagination
                            onPageChange={(e, data) =>
                              this.changeApprovedPage(e, data)
                            }
                            boundaryRange={1}
                            defaultActivePage={1}
                            ellipsisItem={true}
                            firstItem={true}
                            lastItem={true}
                            siblingRange={1}
                            totalPages={Math.ceil(
                              this.props.totalRecords / this.state.perPage
                            )}
                          />
                        )}
                      </nav>
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            </Container>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    logDetailList: state.subAdmin.logDetailList,
    logModules: state.subAdmin.logModules,
    totalRecords: state.subAdmin.totalRecords,
    permissions: state.subAdmin.permissions,
    openSecurityModal: state.subAdmin.openSecurityModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLogReport: (data) => dispatch(getLogReport(data)),
  };
};

export default reduxForm({ form: "addSubAdminForm" })(
  connect(mapStateToProps, mapDispatchToProps)(LogReports)
);
