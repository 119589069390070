import { actionTypes } from "../actons/userList.action";

const initialState = {
  getBankDetails: [],
};

const bankDetails = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BANK_DETAIL:
      return {
        ...state,
        getBankDetails: action.data,
      };
    default:
      return state;
  }
};

export default bankDetails;