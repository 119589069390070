import { actionTypes } from "../actons/swap.action";

const initialState = {
  swapList: [],
  totalRecords: "",
  swapGraph: {
    labels: [],
    datasets: [
      {
        label: "Performance",
        data: []
      }
    ]
  }
};

const swap = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SWAP_LIST:
      return {
        ...state,
        swapList: action.data.data,
        totalRecords: action.data.meta.total
      };

    case actionTypes.SAVE_SWAP_GRAPH:
      return {
        ...state,
        swapGraph: {
          labels: action.swap_data.lable,
          datasets: [
            {
              label: "Performance",
              data: action.swap_data.data
            }
          ]
        }
      };

    default:
      return state;
  }
};

export default swap;
