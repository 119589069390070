import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Input,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  getHotWalletBalance,
  connectWallet,
  openCloseSendClodModal,
  getAdminHotWalletBalance,
  openCloseModal,
} from "../../../Redux/actons/fund.action";
import { getColdWalletGoogleStatus } from "../../../Redux/actons/user.action";
// import { openCloseModal } from "../../../Redux/actons/SubAdmin.action";
import { API_HOST } from "../../../constants/index";
import { Link } from "react-router-dom";
import SendToColdWallet from "./SendToColdWallet";
import { reset } from "redux-form";
import Security from "./Security";
import {
  BTC_ADDRESS_BTC,
  ETH_ADDRESS_ETH,
  BTCV_ADDRESS_BTCV,
  BSC_ADDRESS_BSC,
  MATIC_ADDRESS_MATIC,
} from "../../../constants/index";
import Header from "components/Headers/Header";
import Download from "views/CommonFiles/Download";

import { Pagination, Dropdown, Icon } from "semantic-ui-react";
import ConnectWalletFun from "Redux/actons/ConnectWalletFun";
import MetaMaskConnection from "components/WalletConnection/MetaMaskConnection";
import TronConnection from "components/WalletConnection/TronConnection";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
const UserFilter = [
  { key: "o", text: "All", value: "1" },
  { key: "c", text: "By Admin", value: "2" },
  { key: "u", text: "By Users", value: "3" },
];

export class HotwalletFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "desc",
      sortBy: "",
      coin_family: "",
      isSendColdWallet: false,
      address: "",
      coinId: "",
      totalAmount: "",
      isToken: "",
      listType: "2",
      downloadExcel: false,
      download_url: "",
      filterData: {},
    };
  }
  componentDidMount = () => {
    this.getHotWalletBalance();
    this.checkRouteValid();
    this.props.getColdWalletGoogleStatus();
  };
  componentWillUnmount = () => {
    // this.props.openCloseModal();
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      // console.log('permissions >>',this.props.permissions)
      var isfound = false;
      // check user Permissions if route accessible
      await this.props.permissions.forEach((element) => {
        // if (element.title == "Withdrawal Management") {
        if (element.title == "Transaction Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };
  getHotWalletBalance = () => {
    let data = {
      keyword: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      order: this.state.order,
      list_type: this.state.listType,
    };
    this.props.getHotWalletBalance(data);
  };
  listByUser = (e, data) => {
    console.log("listByUser", data);
    if (data.value !== "none") {
      this.setState({ listType: data.value }, () => {
        this.getHotWalletBalance();
      });
    } else {
      // this.setState({ listType: "1" }, () => {
      this.getHotWalletBalance();
      // });
    }
  };
  toggleModal = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };
  openModal = (data) => {
    if (this.props.user.cold_wallet_google2fa_status) {
      // alert('testedd');
      this.props.openCloseModal(true);
      this.setState({
        // isSendColdWallet: !this.state.isSendColdWallet,
        coinId: data.coin_symbol,
        coin_family: data.coin_family,
        totalAmount:
          data.coin_symbol != "btc" ? data.amount : data.amount.result,
      });
    } else {
      // alert('ted33')
      this.props.openCloseSendClodModal(true);
      this.setState({
        isSendColdWallet: !this.state.isSendColdWallet,
        coinId: data.coin_symbol,
        coin_family: data.coin_family,
        totalAmount:
          data.coin_symbol != "btc" ? data.amount : data.amount.result,
      });
    }
  };
  openSendColdModal = (data) => {
    this.setState({
      isSendColdWallet: !this.state.isSendColdWallet,
      // coinId: "",
      coinId: data.coin_symbol,
      coin_family: data.coin_family,
      address: data.token_address,
      isToken: data.is_token,
      totalAmount: data.coin_symbol != "btc" ? data.amount : data.amount.result,
    });
    this.props.reset("sendToColdWalletForm");
  };
  toggleColdModal = () => {
    this.props.openCloseSendClodModal(false);
    this.setState({
      //isSendColdWallet: !this.state.isSendColdWallet,
      coinId: "",
    });
    this.props.reset("sendToColdWalletForm");
  };

  getFundList = () => {
    let data = {
      keyword: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    // this.props.getAllWithdrawList(data);
  };

  //page change
  changeApprovedPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getHotWalletBalance();
    });
  };
  //search
  onSearchWithdraw = (abc) => {
    console.log(abc);
    this.setState({ searchKey: abc, page: 1 }, () => {
      //   this.getWithdrawList();
    });
  };

  downLoadList = (id) => {
    let filterData = {
      coin_id: id,
    };
    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: `${API_HOST}api/v1/admin/users/hotWalletBalanceListDownload`,
      filterData: filterData,
    });
    // window.location.href = `${API_HOST}api/v1/admin/users/hotWalletBalanceListDownload?coin_id=${id}`;
  };
  closeDownloadExcel = () => {
    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: "",
      filterData: {},
    });
  };

  close = () => {
    this.setState({ openConfirm: false });
  };
  redirectUrlTo = (coin, address) => {
    console.log(coin, "===", address);
    switch (coin) {
      case 1:
        window.open(`https://etherscan.io/address/${address}`, "_blank");
        break;
      case 2:
        window.open(`${BTC_ADDRESS_BTC}${address}`, "_blank");
        break;
      case 3:
        window.open(`${BSC_ADDRESS_BSC}${address}`, "_blank");
        break;
      case 4:
        window.open(`https://tronscan.org/#/address/${address}`, "_blank");
        break;
      case 5:
        window.open(`https://polygonscan.com/address/${address}`, "_blank");
        break;
    }
    // if (coin === "btc") {
    //   window.open(`${BTC_ADDRESS_BTC}/${address}`, "_blank");
    // }
    // if (coin === "btcv") {
    //   window.open(`${BTCV_ADDRESS_BTCV}/${address}`, "_blank");
    // }
    // if (coin === "eth" || coin === "ida" || coin === "orbs") {
    //   window.open(`${ETH_ADDRESS_ETH}/${address}`, "_blank");
    // }
    // if (coin == "bnb" || coin == "busd" || coin == "rena") {
    //   window.open(`${BSC_ADDRESS_BSC}/${address}`, "_blank");
    // }
    // if (coin == "matic") {
    //   window.open(`${MATIC_ADDRESS_MATIC}/${address}`, "_blank");
    // }
  };

  showBalance = (id, data) => {
    this.props.history.push({
      pathname: `coin-wise-balance`,
      state: data,
    });
  };

  onSearchList = (search) => {
    // this.setState({ searchKey: search }, () => {
    this.getHotWalletBalance();
    // });
  };
  connectWallet = (search) => {
    // this.setState({ searchKey: search }, () => {
    this.props.connectWallet();
    // });
  };
  sortBy = (e, data) => {
    console.log(data.value);
    this.setState({ order: data.value }, () => {
      this.getHotWalletBalance();
    });
  };

  refreshBalance = (coin_id) => {
    let data = {
      keyword: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      order: this.state.order,
      list_type: this.state.listType,
    };
    this.props.getAdminHotWalletBalance({ coin_id: coin_id }, data);
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="hot wallet management" />
        <div>
          {/*!this.props.openSecurityModal && */}
          {
            <Container className="contentBlock" fluid>
              {/* Table */}
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div className="selectSearchOption">
                        <Input
                          placeholder="Search List"
                          style={{
                            color: "black",
                          }}
                          onChange={(e) => {
                            this.setState(
                              { searchKey: e.target.value, page: 1 },
                              () => {
                                if (this.state.searchKey == "") {
                                  this.onSearchList();
                                }
                              }
                            );
                          }}
                        ></Input>
                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.onSearchList()}
                        >
                          Search
                        </Button>
                        <Dropdown
                          className="selectUserStyle mx-2"
                          options={UserFilter}
                          placeholder="Filter"
                          selection
                          value={this.state.listType}
                          onChange={(e, data) => this.listByUser(e, data)}
                        />
                        <Dropdown
                          className="selectUserStyle"
                          options={options}
                          selection
                          value={this.state.order}
                          onChange={(e, data) => this.sortBy(e, data)}
                        />
                        <Link to="history">
                          <Button color="success btnMamagePermission">
                            Cold Wallet Transaction History
                          </Button>
                        </Link>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "150px",
                          }}
                        >
                          {/* <span>
                            Total IDP Balance: {this.props.idpBalance} IDP
                          </span> */}
                        </div>
                      </div>
                      {/* <div className="selectSearchOption">
                        <div>
                          <MetaMaskConnection />
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                          <TronConnection />
                        </div>
                      </div> */}
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Wallet</th>
                          <th scope="col">Admin balance</th>
                          <th scope="col">User balance</th>
                          <th scope="col">Staked</th>
                          <th scope="col">Locked</th>
                          <th scope="col">Address</th>
                          <th scope="col">Download Balance List</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.hotWalletBalance.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>
                              {data.is_token == 1
                                ? data.coin_family == 1
                                  ? data.coin_symbol.toUpperCase() + "(ERC-20)"
                                  : data.coin_family == 3
                                  ? data.coin_symbol.toUpperCase() + "(BEP-20)"
                                  : data.coin_family == 4
                                  ? data.coin_symbol.toUpperCase() + "(TRC-20)"
                                  : data.coin_family == 3
                                  ? data.coin_symbol.toUpperCase() + "(BEP-20)"
                                  : data.coin_family == 5
                                  ? data.coin_symbol.toUpperCase() + "(MATIC)"
                                  : data.coin_symbol.toUpperCase()
                                : data.coin_symbol.toUpperCase()}
                            </td>
                            <td>
                              {" "}
                              {data.amount
                                ? Number(data.amount).toFixed(4)
                                : "NONE"}{" "}
                              <Icon
                                enabled
                                name="sync"
                                size="small"
                                title="refresh balance"
                                onClick={() =>
                                  this.refreshBalance(data.coin_id)
                                }
                              />
                            </td>
                            <td>
                              {data.usersBalance
                                ? Number(data.usersBalance).toFixed(4)
                                : "0"}
                            </td>
                            <td>
                              {data.staked
                                ? Number(data.staked).toFixed(4)
                                : "0"}
                            </td>
                            <td>
                              {data.locked
                                ? Number(data.locked).toFixed(4)
                                : "0"}
                            </td>
                            <td
                              title={data.address}
                              onClick={() =>
                                this.redirectUrlTo(
                                  data.coin_family,
                                  data.address
                                )
                              }
                            >
                              {data.address
                                ? data.address.slice(0, 6) +
                                  "..." +
                                  data.address.slice(-4)
                                : "0"}
                            </td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.downLoadList(data.coin_id)
                                  }
                                >
                                  Download List
                                </Button>
                              </span>
                            </td>

                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color="success"
                                  onClick={() => this.openModal(data)}
                                >
                                  Send to Cold Wallets{" "}
                                </Button>
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.showBalance(data.coin_id, data)
                                  }
                                >
                                  Show Balance
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) =>
                            this.changeApprovedPage(e, data)
                          }
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={
                            this.props.totalRecords !== undefined &&
                            this.props.totalRecords !== ""
                              ? Math.ceil(
                                  this.props.totalRecords / this.state.perPage
                                )
                              : "0"
                          }
                        />
                      </nav>
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            </Container>
          }
        </div>
        <Security
          openSendColdModal={this.openSendColdModal}
          coinId={this.state.coinId}
          coinFamily={this.state.coin_family}
          totalAmount={this.state.totalAmount}
        ></Security>
        {/* send cold wallet modal  */}
        <SendToColdWallet
          isSendColdWallet={this.state.isSendColdWallet}
          toggleColdModal={this.toggleColdModal}
          coinId={this.state.coinId}
          coinFamily={this.state.coin_family}
          totalAmount={this.state.totalAmount}
          address={this.state.address}
          isToken={this.state.isToken}
        ></SendToColdWallet>
        <Download
          filterData={this.state.filterData}
          closeDownloadExcel={this.closeDownloadExcel}
          downloadExcel={this.state.downloadExcel}
          download_url={this.state.download_url}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    hotWalletBalance: state.funds.hotWalletBalance,
    idpBalance: state.funds.idpBalance,
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
    // openSecurityModal: state.subAdmin.openSecurityModal,
    totalRecords: state.funds.totalPages,
    openSecurityModal: state.funds.openSecurityModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotWalletBalance: (data) => dispatch(getHotWalletBalance(data)),
    connectWallet: (data) => dispatch(connectWallet(data)),
    reset: (data) => dispatch(reset(data)),
    openCloseModal: (status) => dispatch(openCloseModal(status)),
    openCloseSendClodModal: (status) =>
      dispatch(openCloseSendClodModal(status)),
    getColdWalletGoogleStatus: () => dispatch(getColdWalletGoogleStatus()),
    getAdminHotWalletBalance: (data, data2) =>
      dispatch(getAdminHotWalletBalance(data, data2)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HotwalletFunds)
);
