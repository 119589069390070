import { INRManagementServices } from "../../Services/INRManagementServices";
import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../components/Toast/Toast";
import { DownLoadCSVFunction } from "./installment.action";

export const actionTypes = {
  FIAT_HISTORY_LIST: "FIAT_HISTORY_LIST",
  FIAT_STATUS: "FIAT_STATUS",
  USER_FIAT_LIST: "USER_FIAT_LIST",
  VIRTUAL_BALANCE: "VIRTUAL_BALANCE",
  DEPOSIT_SETTINGS: "DEPOSIT_SETTINGS",
};

export function saveFiatHistoryList(data) {
  return {
    type: actionTypes.FIAT_HISTORY_LIST,
    data: data,
  };
}

export function saveVirtualBalance(data) {
  return {
    type: actionTypes.VIRTUAL_BALANCE,
    data: data,
  };
}
export function saveDepositSettings(data) {
  return {
    type: actionTypes.DEPOSIT_SETTINGS,
    data: data,
  };
}

export function saveUserInr(data) {
  return {
    type: actionTypes.USER_FIAT_LIST,
    data: data,
  };
}

export function saveFiatStatus(data) {
  return {
    type: actionTypes.FIAT_STATUS,
    data: data,
  };
}

export function changeTransactionStatus(data, data2) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return INRManagementServices.changeTransactionStatus(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        toast.success(data["data"]["message"]);
        dispatch(stopLoading());
        dispatch(
          getFiatHistoryList({
            perPage: data2.perPage,
            page: data2.page,
            searchKey: data2.searchKey,
            order: data2.order,
            status: data2.status,
            type: data2.type,
          }),
          getBalance()
        );
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getFiatHistoryList(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return INRManagementServices.getFiatHistoryList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveFiatHistoryList(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getAllDepositListCSV(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return INRManagementServices.getFiatHistoryList(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        let arrayOfObjects = data?.data?.data?.history;
        if (!arrayOfObjects?.length) {
          dispatch(stopLoading());
          return;
        }
        DownLoadCSVFunction(arrayOfObjects, "CSV_download_deposit_list");
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getBankDepositSettings(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return INRManagementServices.getDepositSettings({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveDepositSettings(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function changeDepositSettings(params) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return INRManagementServices.changeDepositStatus(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        // dispatch(saveDepositSettings(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getBalance() {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return INRManagementServices.getBalance({
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveVirtualBalance(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getUserINR(data, params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return INRManagementServices.getUserInr(data, params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveUserInr(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getFiatStatus(params) {
  return (dispatch, getState) => {
    if (params.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return INRManagementServices.getFiatStatus(params, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(saveFiatStatus(data.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
