import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";

import { Pagination, Dropdown, Form } from "semantic-ui-react";
import CustomHeader from "../../components/Headers/CustomHeader";
import { IMAGE_PATH } from "../../constants/index";
import Header from "components/Headers/Header";
import axios from "axios";
import { API_HOST } from "../../constants/index";
import { FormField } from "../../components/FormField";
import { Link, withRouter } from "react-router-dom";
import {
  getContentDetail,
  updateContent,
} from "../../Redux/actons/content.action";
import { Field, reduxForm, change } from "redux-form";
import { SelectField } from "../../components/SelectField";
import { getCoinList } from "../../Redux/actons/user.action";
import { startLoading, stopLoading } from "../../Redux/actons/loading.action";
import { required } from "redux-form-validators";
import CKEditor from "react-ckeditor-component-4";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
export class UpdateContent extends Component {
  constructor(props) {
    super(props);
    // this.updateContent = this.updateContent.bind(this);
    this.state = {
      content: "",
    };
  }
  // updateContent(newContent) {
  //   this.setState({
  //       content: newContent
  //   })
  // }
  componentDidMount = async () => {
    if (this.props.location.state) {
      localStorage.setItem("id", this.props.location.state);
      await this.props.getContentDetail({ id: this.props.location.state });
    } else {
      await this.props.getContentDetail({ id: localStorage.getItem("id") });
    }
    // let val = this.props.match.params.id
    // console.log("Id ====",val)
    // await this.props.getContentDetail({id:this.props.match.params.id});
    this.setState({ content: this.props.detail.content });
    // if(this.props.detail.content !== ""){
    //   this.setState({content:this.props.detail.content});
    // }
  };

  // componentWillReceiveProps=(nextProps) => {
  //   if(this.props.detail.content !== nextProps.detail.content){
  //     //alert('test')
  //     this.setState({content:nextProps.detail.content});
  //   }
  // }

  onChange = (evt) => {
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
  };

  updateData = () => {
    this.props.updateContent({
      page_id: localStorage.getItem("id"),
      content: this.state.content,
    });
  };

  // onBlur=(evt)=>{
  //   console.log("onBlur event called with event info: ", evt);
  // }

  // afterPaste=(evt)=>{
  //   console.log("afterPaste event called with event info: ", evt);
  // }

  render() {
    console.log(">>>>>>", this.state);

    // console.log('this.location >>>>',this.props.location);
    let { handleSubmit } = this.props;
    return (
      <div>
        <div>
          <Header
            headerTitle={`Update ${this.props.detail.page_title} Content`}
          />
        </div>
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="content-management">Content Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem
              active
            >{`Update ${this.props.detail.page_title} Content`}</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div style={{ padding: 30 }}>
          {/* <Form onSubmit={handleSubmit(this.addBanner)}> */}
          <Form>
            {/* <Form.Group>
              <Form.TextArea
                rows={20}
                cols={500}
                label="Description"
                placeholder="Description"
                value={this.state.content}
                onChange={(e, data) =>
                  this.setState({ content: e.target.value })
                }
              />
            </Form.Group> */}
            <Form.Field>
              <CKEditor
                activeClass="p10"
                content={this.state.content}
                events={{
                  blur: this.onBlur,
                  afterPaste: this.afterPaste,
                  change: this.onChange,
                }}
              />
            </Form.Field>
            {/* {this.state.isCoin && ( */}
            <Form.Field className="addBtn">
              <Button
                type="submit"
                color="success"
                onClick={() => this.updateData()}
              >
                Update
              </Button>
              {/* <Button color="primary" onClick={()=> this.props.history.goBack()}>Back</Button> */}
            </Form.Field>
            {/* // )} */}
          </Form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    detail: state.content.detail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContentDetail: (data) => dispatch(getContentDetail(data)),
    updateContent: (data) => dispatch(updateContent(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "UpdateContentForm" })(UpdateContent));
