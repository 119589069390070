import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
  Label,
} from "reactstrap";
import { Pagination, Dropdown } from "semantic-ui-react";
import { reduxForm } from "redux-form";
import CustomHeader from "../../components/Headers/CustomHeader";
import { Link, withRouter } from "react-router-dom";

import { API_HOST } from "../../constants/index";
import { getUserFundList } from "../../Redux/actons/fund.action";
import {
  userCoinBalance,
  getCoinListForFilter,
  userDetail,
} from "../../Redux/actons/userList.action";
import AddFunds from "./AddFunds";
import Security from "../SubAdminManagement/AddNewSubAdmin/Security";
import Header from "components/Headers/Header";
import UserDetail from "../UserManagement/User/UserDetail";
import Download from "views/CommonFiles/Download";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
const UserFilter = [
  { key: "o", text: "All", value: "1" },
  { key: "c", text: "By Admin", value: "2" },
  { key: "u", text: "By Users", value: "3" },
];
const Coinfilter = [{ key: "p", text: "None", value: "none" }];

export class FundManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailModal: false,
      open: false,
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "desc",
      sortBy: "",
      listType: "1",
      // type: "fund",
      userFund: {},
      isAddFunds: false,
      userId: "",
      coin_type: [],
      isLockFunds: false,
      downloadExcel: false,
      download_url: "",
      filterData: {},
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.coinListCoinFilter !== this.props.coinListCoinFilter) {
      nextProps.coinListCoinFilter.forEach((element) => {
        Coinfilter.push(element);
      });
    }
  };

  componentDidMount = () => {
    this.getUserList();
    this.props.getCoinList();
  };

  openAddFunds = (user, islock) => {
    this.setState({ userId: user.user_id, isLockFunds: islock });
    setTimeout(() => {
      this.setState({ isAddFunds: !this.state.isAddFunds });
    }, 100);
  };
  openCloseAddFunds = () => {
    this.setState({ userId: "" });
    this.setState({ isAddFunds: !this.state.isAddFunds });
  };

  addFundsFile = () => {
    this.props.history.push("add-funds-file");
  };

  getUserList = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
      coin_type: this.state.coin_type,
      list_type: this.state.listType,
    };
    this.props.getUserFundList(data);
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };
  // //page change
  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getUserList();
    });
  };

  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ order: data.value }, () => {
      this.getUserList();
    });
  };
  listByUser = (e, data) => {
    if (data.value !== "none") {
      this.setState({ listType: data.value }, () => {
        this.getUserList();
      });
    } else {
      this.setState({ listType: "1" }, () => {
        this.getUserList();
      });
    }
  };

  filterCoinBy = (e, data) => {
    if (data.value !== "none") {
      let coin_type = [];
      coin_type.push(data.value);
      this.setState({ coin_type: coin_type }, () => {
        this.getUserList();
      });
    } else {
      this.setState({ coin_type: [] }, () => {
        this.getUserList();
      });
    }
  };

  //search
  onSearchList = (abc) => {
    // this.setState({ page: 1 }, () => {
    this.getUserList();
    // });
  };

  uploadCsvFile = () => {
    console.log("CALLLLLLLLLLLLLLLLLLLL");
    //     <input
    //   type="file"
    //   name="file"
    //   onChange={(e) => {
    //     console.log("FilePath---->", e.target.value);
    //   }}
    //   accept={".csv"}
    // />
  };

  downLoadUserList = () => {
    let filterData = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      coin_type: this.state.coin_type,
      list_type: this.state.listType,
      // page: 1,
      // perPage: this.props.fundRecords
    };
    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: `${API_HOST}api/v1/admin/users/download/userfundlist`,
      filterData: filterData,
    });
    // params = params ? "?" + new URLSearchParams(params).toString() : "";
    // window.location.href = `${API_HOST}api/v1/admin/users/download/userfundlist${params}`;
  };

  closeDownloadExcel = () => {
    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: "",
      filterData: {},
    });
  };

  redirectLockFund = (id) => {
    this.props.history.push({
      pathname: `lock-fund-history`,
      state: id,
      search: id.toString(),
    });
  };

  toPlainString = (num) => {
    return ("" + +num).replace(
      /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + "0." + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      }
    );
  };

  detailModalToggle = (userData) => {
    this.props.userDetail({ user_id: userData });
    this.setState({ detailModal: !this.state.detailModal });
  };
  clostDetailModal = () => {
    this.setState({ detailModal: !this.state.detailModal });
  };

  render() {
    var fdata = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    return (
      <div>
        <Header headerTitle="Fund management" />
        <div>
          <Security />
          {!this.props.openSecurityModal && (
            <Container className="contentBlock" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div style={{ width: "100%" }}>
                        <h3 className="mb-2">User Fund List</h3>
                      </div>
                      <div className="selectSearchOption">
                        <Input
                          placeholder="Search"
                          onKeyDown={(e) => {
                            // eslint-disable-next-line
                            if (e.keyCode == 13) {
                              this.onSearchList();
                            }
                          }}
                          onChange={(e) => {
                            this.setState(
                              { searchKey: e.target.value, page: 1 },
                              () => {
                                if (this.state.searchKey == "") {
                                  this.onSearchList();
                                }
                              }
                            );
                          }}
                        ></Input>
                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.onSearchList()}
                        >
                          Search
                        </Button>
                        <Dropdown
                          className="ml-2"
                          options={options}
                          selection
                          value={this.state.order}
                          onChange={(e, data) => this.filterBy(e, data)}
                        />
                        <Dropdown
                          className="selectUserStyle ml-2"
                          options={UserFilter}
                          placeholder="Filter"
                          selection
                          onChange={(e, data) => this.listByUser(e, data)}
                        />
                        {/* 
                        <Dropdown
                          className="selectUserStyle"
                          options={Coinfilter}
                          placeholder="Filter by Coin"
                          selection
                          onChange={(e, data) => this.filterCoinBy(e, data)}
                        /> */}

                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.downLoadUserList()}
                        >
                          Download Fund list
                        </Button>
                      </div>
                      <div
                        className="selectSearchOption"
                        style={{ marginTop: 20 }}
                      >
                        <Link to="fund-history">
                          <Button color="success btnMamagePermission">
                            Fund History
                          </Button>
                        </Link>

                        <Link to="lock-fund-management">
                          <Button color="success btnMamagePermission">
                            Lock Fund Management
                          </Button>
                        </Link>

                        <Link to="fiat-management">
                          <Button color="success btnMamagePermission">
                            Fiat Management
                          </Button>
                        </Link>
                        {/* Upload CSV File */}
                        {/* Design Pending */}
                        <Link to="add-funds-file">
                          <Button
                            color="default btnMamagePermission"
                            // onClick={() => this.addFundsFile()}
                          >
                            + Air Drop
                          </Button>
                        </Link>

                        {/* <div >
                          <Label style={{ textAlign: "left", marginLeft: "10px" }}>Add funds with csv file</Label>
                          <form
                            style={{ textAlign: "center", marginLeft: "10px" }}
                          >
                            <input
                              type="file"
                              name="file"
                              onChange={(e) => {
                                console.log("FilePath---->", e.target.value);
                              }}
                              accept={".csv"}
                            />
                          </form>
                        </div> */}
                      </div>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">ID (Email)</th>
                          {/* {this.props.getFundsList.length > 0 &&
                            this.props.getFundsList[0].coinAllBalances.map(
                              (coin, index) => (
                                <th scope="col">{coin.coin_symbol}</th>
                              )
                            )} */}
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.getFundsList &&
                          this.props.getFundsList?.map((usersList, index) => (
                            <tr className="table-tr" key={index}>
                              <td>
                                {/* <td>{usersList.email}</td> */}
                                <Link
                                  onClick={() =>
                                    this.detailModalToggle(usersList)
                                  }
                                >
                                  {usersList?.email ? usersList?.email : "None"}
                                </Link>
                              </td>
                              {/* {usersList.coinAllBalances.map((coin, index) => (
                              <td>{this.toPlainString(coin.balance)}</td>
                            ))} */}
                              <td>
                                {" "}
                                <Link
                                  style={{ marginRight: "10px" }}
                                  to={`view-fund-management?${usersList.user_id.toString()}`}
                                  // onClick={() =>
                                  //   this.detailModalToggle(usersList)
                                  // }
                                >
                                  <Button color="success btnMamagePermission">
                                    View Funds
                                  </Button>
                                </Link>
                                {/* <Button
                                color="success btnMamagePermission"
                                onClick={() =>
                                  this.openAddFunds(usersList, false)
                                }
                              >
                                Add Funds
                              </Button>
                              <Button
                                color="success btnMamagePermission"
                                onClick={() =>
                                  this.openAddFunds(usersList, true)
                                }
                              >
                                Manage Funds
                              </Button> */}
                                {/* <Button
                                color="success btnMamagePermission"
                                onClick={() => this.openAddFunds(usersList)}
                              >
                                Un-lock Funds
                              </Button> */}
                                <Link
                                  to={`lock-fund-history?${usersList.user_id.toString()}`}
                                >
                                  <Button
                                    color="success btnMamagePermission"
                                    // onClick={() =>
                                    //   this.redirectLockFund(usersList.user_id)
                                    // }
                                  >
                                    Lock fund history
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        {this.props.getFundsList?.length == 0 && (
                          <div>
                            <td>No record</td>
                          </div>
                        )}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        {this.props.getFundsList?.length > 0 && (
                          <Pagination
                            onPageChange={(e, data) => this.changePage(e, data)}
                            boundaryRange={1}
                            defaultActivePage={1}
                            ellipsisItem={true}
                            firstItem={true}
                            lastItem={true}
                            siblingRange={1}
                            totalPages={Math.ceil(
                              this.props.fundRecords / this.state.perPage
                            )}
                          />
                        )}
                      </nav>
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            </Container>
          )}
        </div>
        {/* add funds modal  */}
        {this.state.isAddFunds && (
          <AddFunds
            isAddFunds={this.state.isAddFunds}
            toggle={this.openCloseAddFunds}
            getUserList={this.getUserList}
            userId={this.state.userId}
            fdata={fdata}
            islockFunds={this.state.isLockFunds}
          />
        )}
        {this.state.detailModal && (
          <UserDetail
            clostDetailModal={this.clostDetailModal}
            detailModal={this.state.detailModal}
          />
        )}
        <Download
          filterData={this.state.filterData}
          closeDownloadExcel={this.closeDownloadExcel}
          downloadExcel={this.state.downloadExcel}
          download_url={this.state.download_url}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    getFundsList: state.funds.getFundsList,
    fundRecords: state.funds.fundRecords,
    getUserCoinId: state.userList.getUserCoinId,
    openSecurityModal: state.subAdmin.openSecurityModal,
    coinListCoinFilter: state.userList.coinListCoinFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserFundList: (data) => dispatch(getUserFundList(data)),
    userCoinBalance: (id) => dispatch(userCoinBalance(id)),
    getCoinList: () => dispatch(getCoinListForFilter()),
    userDetail: (data) => dispatch(userDetail(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "FundManagement" })(FundManagement))
);
