import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { setWithdawMaxMinLimit } from "../../Redux/actons/token.action";
import { Form } from "semantic-ui-react";
import { Field, reduxForm, change } from "redux-form";
import { FormField } from "../../components/FormField";
import { required } from "redux-form-validators";
import { invalidMinusValue } from "../../validators/customValidator";

export class SetWithdrawLimit extends Component {
  constructor(props) {
    super(props);
  }
  //   componentWillReceiveProps = (nextProps) => {
  //       if(nextProps !== this.props){
  //         //   alert('tedd');
  //         this.setState({
  //             fee: nextProps.swap_fee
  //         });
  //       }
  //   };
  componentWillReceiveProps = async (nextProps) => {
    if (
      this.props.min_withdraw_limit !== nextProps.min_withdraw_limit ||
      this.props.max_withdraw_limit !== nextProps.max_withdraw_limit
    ) {
      // console.log('min_withdraw_limit >>>>>>',nextProps.min_withdraw_limit);
      // console.log('max_withdraw_limit >>>>>>',nextProps.max_withdraw_limit);
      await this.props.dispatch(
        change(
          "SetWithdrawLimit",
          "min_withdraw_limit",
          nextProps?.min_withdraw_limit
        )
      );
      await this.props.dispatch(
        change(
          "SetWithdrawLimit",
          "max_withdraw_limit",
          nextProps?.max_withdraw_limit
        )
      );
    }
  };
  updateForm = (values) => {
    if (values.min_withdraw_limit < 0) {
      alert("Minimum withdraw limit must be greater than and equal to 0");
      return false;
    }
    if (values.max_withdraw_limit < 0) {
      alert("Maximun withdraw limit must be greater than and equal to 0");
      return false;
    }
    // if(values.max_withdraw_limit > 100){
    //   alert("Maximun withdraw limit must be less than and equal to 100");
    //   return false;
    // }
    if (+values.min_withdraw_limit >= +values.max_withdraw_limit) {
      alert("Minimum withdraw limit must be less than Maximum withdraw limit.");
      return false;
    }
    let data = {
      id: this.props.withdraw_coin_id,
      min_withdraw_limit: values.min_withdraw_limit,
      max_withdraw_limit: values.max_withdraw_limit,
    };
    let data2 = {
      search: this.props.search,
      order: this.props.order,
      perPage: this.props.perPage,
      page: this.props.page,
    };
    this.props.setWithdawMaxMinLimit(data, data2);
    this.props.closeWithdrawLimitModal();
  };
  setFeeValue(value) {
    this.setState({ fee: value });
  }
  render() {
    // console.log('this.props222 >> ',this.props);
    // console.log('this.state >> ',this.state);

    let { handleSubmit } = this.props;
    return (
      <div>
        {/* <Button color="danger">X</Button> */}

        <Modal
          isOpen={this.props?.withdrawLimitModal}
          toggle={this.props.closeWithdrawLimitModal}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.closeWithdrawLimitModal}>
            Set {this.props?.coin_name} Withdraw Limit
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.updateForm)}>
              <Form.Group>
                <Form.Field>
                  <label>Min Withdraw Limit</label>
                  <Field
                    placeholder="Min Limit"
                    name="min_withdraw_limit"
                    // value={this.state.fee}
                    component={FormField}
                    type="number"
                    validate={[required(), invalidMinusValue()]}
                    min={0}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Max Withdraw Limit</label>
                  <Field
                    placeholder="Max Limit"
                    name="max_withdraw_limit"
                    // value={this.state.fee}
                    component={FormField}
                    type="number"
                    validate={[required(), invalidMinusValue()]}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    setWithdawMaxMinLimit: (data, data2) =>
      dispatch(setWithdawMaxMinLimit(data, data2)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "SetWithdrawLimit" })(SetWithdrawLimit));
// export default connect(mapStateToProps, mapDispatchToProps)(SetWithdrawDailyLimit);
