import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../../components/FormField";
import { SelectField } from "../../../components/SelectField";
import { required } from "redux-form-validators";
import { amountLimit } from "../../../validators/customValidator";
import {
  addInterest,
  updateAllInterest,
  getSingleInterest,
} from "../../../Redux/actons/interest.action";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { getCoinList } from "../../../Redux/actons/user.action";
import Header from "components/Headers/Header";
import { reset } from "redux-form";

export class AddInterest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //   isCoin: false,
      id: "",
      defaultType: "",
      defaultAction: 1,
      interest: "",
      stake_amount: "",
      selectedCoinId: "",
      isEdit: false,
      allInterest: false,
    };
  }
  componentDidMount = async () => {
    this.props.getCoinList();
    if (this.props.location.pathname.includes("edit-interest")) {
      this.setState({
        id: this.props.location.state,
        isEdit: true,
      });
      await this.props.getSingleInterest(this.props.location.state);
    }

    const { coin_id, interest_rate } = this.props.currencyInterest;
    this.setState({
      selectedCoinId: coin_id,
      interest: interest_rate,
    });
  };

  getDetails = (e, data) => {
    this.setState({ defaultType: data });
  };

  updateForm = (values) => {
    const formData = {
      coin_id: this.state.selectedCoinId,
      interest_rate: this.state.interest,
      stake_amount: Number(this.state.stake_amount),
    };

    this.props.addInterest(formData);

    if (this.state.allInterest == true) {
      const data = {
        interest_rate: this.state.interest,
      };
      this.props.updateAllInterest(data);
      this.setState({ allInterest: false });
    }

    this.props.history.goBack();
  };

  updateAllInterests = (values) => {
    const formData = {
      interest_rate: this.state.allInterest,
    };
    this.props.updateAllInterest(formData);
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <div>
          <Header
            headerTitle={this.state.isEdit ? "Edit Interest" : "Add Interest"}
          />
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="interest-management">Interest Management</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.state.isEdit ? "Edit Interest" : "Add Interest"}
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <div style={{ padding: 30 }}>
          <Form onSubmit={handleSubmit(this.updateForm)}>
            <Form.Group>
              <Form.Field>
                <label>Choose Coin</label>
                <Field
                  props={{ disabled: this.state.isEdit ? true : false }}
                  name="coin_1"
                  search={false}
                  selectOnBlur={false}
                  validate={this.state.isEdit ? [] : [required()]}
                  component={SelectField}
                  defaultValue={this.state.selectedCoinId}
                  children={this.props.coinsList}
                  placeholder="Select"
                  onChange={(e, data) =>
                    this.setState({ selectedCoinId: data })
                  }
                ></Field>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Interest(%)</label>
                <input
                  type="number"
                  name="interest"
                  min="0"
                  value={this.state.interest}
                  validate={[required(), amountLimit()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ interest: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Stake Amount</label>
                <input
                  type="number"
                  name="stake_amount"
                  min="0"
                  value={this.state.stake_amount}
                  validate={[required(), amountLimit()]}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ stake_amount: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Update all interests</label>
                <input
                  type="checkbox"
                  name="interest"
                  value={this.state.allInterest}
                  component={FormField}
                  onChange={(e, data) =>
                    this.setState({ allInterest: !this.state.allInterest })
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            {/* {this.state.isCoin && ( */}
            <Form.Field className="addBtn">
              <Button type="submit" color="success">
                Submit
              </Button>
            </Form.Field>
            {/* // )} */}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coinsList: state.user.coinsList,
    currencyInterest: state.currencyInterest.singleInterest,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCoinList: () => dispatch(getCoinList()),
    addInterest: (data) => dispatch(addInterest(data)),
    updateAllInterest: (data) => dispatch(updateAllInterest(data)),
    getSingleInterest: (data) => dispatch(getSingleInterest(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "addInterestForm" })(AddInterest));
