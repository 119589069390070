import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  getReferralListMLM,
  updateReferralMLM,
  enableDisableReferral,
} from "../../Redux/actons/referral.action";
import { openCloseModal } from "../../Redux/actons/SubAdmin.action";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { IMAGE_PATH } from "../../constants/index";
import { Pagination, Confirm, GridColumn, Grid } from "semantic-ui-react";
import AddReferral from "./AddReferral/AddReferral";
import { DashboardResult } from "views/ContactQueryManagement/SubjectManagement/DashboardResult";
import { listDateFormat } from "utils/utils";

export class MLMReferralManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "30",
      page: "1",
      order: "",
      sortBy: "",
      coin_family: "",
      order: "asc",
      coinId: "",
      totalAmount: "",
      deleteConfirm: false,
      editMlm: false,
      editData: {},
      updateAmount: "",
      deleteData: {},
    };
  }
  componentDidMount = () => {
    this.getPairList();
  };
  componentWillUnmount = () => {
    this.props.openCloseModal();
  };

  getPairList = () => {
    const param = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    this.props.getReferralListMLM(param);
  };

  enableDisablePair = (data, id) => {
    const param = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    const buttonState = { plan_id: id, status: data };
    this.props.enableDisableReferral(buttonState, param);
    // this.props.enableDisableReferral(buttonState, param);
  };

  onEditPair = (data) => {
    this.setState({ editMlm: true, editData: data, updateAmount: data.amount });
  };
  UpdateMLM = () => {
    // // //
    const data = {
      id: this.state.editData.id,
      amount: this.state.updateAmount,
    };
    const param = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    this.props.updateReferralMLM(data, param);

    this.setState({ editMlm: false, editData: {}, updateAmount: "" });
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getPairList();
    });
  };

  onIncentiveClick = (data) => {
    this.props.history.push({
      pathname: `referral-incentive`,
      state: data,
    });
  };

  deleteReward = (data, id, status) => {
    if (status == "active") {
      alert("Please inactive the plan before deleting.");
      return false;
    }
    let deleteData = { status: data, plan_id: id };
    this.setState({ deleteData: deleteData, deleteConfirm: true });
  };

  closeDeleteConfirm = () => {
    this.setState({ deleteData: {}, deleteConfirm: false });
  };

  render() {
    let { referralListMLM } = this.props;

    return (
      <div>
        <Header headerTitle="MLM Referral Setting" />

        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="referral-management">Referral Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>MLM Referral Setting</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  {/* <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      
                    </div>
                  </CardHeader> */}
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Level</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Updated At</th>
                        {/* <th scope="col">Status</th> */}
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {referralListMLM?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        referralListMLM &&
                        referralListMLM?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data?.level}</td>
                            <td>{data?.amount}</td>
                            <td>{listDateFormat(data?.updated_at)}</td>
                            {/* <td>{data.status?.toUpperCase()}</td> */}
                            <td>
                              <Button
                                color="primary"
                                onClick={() => this.onEditPair(data)}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </Row>

            <Modal
              isOpen={this.state.editMlm}
              closeOnDimmerClick={false}
              onClosed={() => this.setState({ editMlm: false })}
            >
              <ModalHeader>
                <h2>MLM Admin Settings</h2>
              </ModalHeader>
              <ModalBody>
                <Container>
                  {/* <h3>WITHDRAW LIMIT:{this.props.adminSettings.withdrawLimit}</h3> */}
                  <h3>Level {this.state.editData.level}:</h3>
                  <Input
                    className="form-control-alternative"
                    defaultValue={this.state.updateAmount}
                    id="input-last-name"
                    placeholder="Deposit Limit"
                    onChange={(e) => {
                      this.setState({ updateAmount: e.target.value });
                    }}
                    type="text"
                  />
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => this.setState({ editMlm: false })}
                >
                  Cancel
                </Button>
                <Button color="primary" onClick={() => this.UpdateMLM()}>
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    referralListMLM: state.referralList?.referralListMLM,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (data) => dispatch(reset(data)),
    openCloseModal: () => dispatch(openCloseModal(true)),
    getReferralListMLM: (param) => dispatch(getReferralListMLM(param)),
    updateReferralMLM: (param, data) =>
      dispatch(updateReferralMLM(param, data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MLMReferralManagement)
);
