import { actionTypes } from "../actons/user.action";

const initialState = {
  user_termination_list: [],
};

const userTermination = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_TERMINATION_REQUESTS:
      return {
        ...state,
        user_termination_list: action.data,
      };

    default:
      return state;
  }
};

export default userTermination;
