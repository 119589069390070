import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { reduxForm } from "redux-form";

import { withRouter } from "react-router";
import { getTotalCoinList } from "../../../Redux/actons/revenue.action";

export class TotalRevenue extends Component {
  componentDidMount = () => {
    this.props.getTotalCoinList();
  };

  render() {
    // alert(this.props.getTotalCoinRevenue);
    return (
     
              <Col lg="12">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Coin Revenue
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {this.props.getTotalCoinRevenue}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
      
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log("@@@@@@>>>>>>>ritu.", state);
  return {
    getTotalCoinRevenue: state.revenue.getTotalCoinRevenue
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTotalCoinList: () => dispatch(getTotalCoinList())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "TotalRevenue" })(TotalRevenue))
);
