import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  HashRouter,
} from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import LandingLayout from "./layouts/Landing";
import AuthGuard from "./Guards/AuthGuard";
import TempGuard from "./Guards/TempGuard";
import { Provider } from "react-redux";
import configureStore from "./Redux/store/configureStore";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./Redux/store/history";
import { PersistGate } from "redux-persist/lib/integration/react";
import configureValidators from "./validators/validator";
import LoaderComponent from "./components/LoaderComponent/LoaderComponent";
import "semantic-ui-css/semantic.min.css";
import NoGuard from "./Guards/NoGuard";
import io from "socket.io-client";
import "./index.css";
import { SOCKET_URL } from "./constants/index";
import { Web3Provider } from "@ethersproject/providers";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import ConnectSolana from "components/WalletConnection/ConnectSolana.js";
// import { NetworkContextName } from "src/constants";

// /* ********************************************************************** */
// import { clusterApiUrl } from "@solana/web3.js";
// import { WalletAdapterNetwork, WalletError } from "@solana/wallet-adapter-base";
// import {
//   ConnectionProvider,
//   WalletProvider,
// } from "@solana/wallet-adapter-react";
// import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
// // import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets'
// import {
//   CoinbaseWalletAdapter,
//   PhantomWalletAdapter,
//   SlopeWalletAdapter,
//   SolflareWalletAdapter,
//   TorusWalletAdapter,
// } from "@solana/wallet-adapter-wallets";
// /* ********************************************************************** */
const Web3ProviderNetwork = createWeb3ReactRoot("Networks");

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 15000;
  return library;
}

export let socket = io.connect(SOCKET_URL, {
  reconnect: true,
  transports: ["websocket"],
});

configureValidators();
let { store, persistor } = configureStore();

/* ********************************************************************** */
// The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
// const network = WalletAdapterNetwork.Devnet;
// // You can also provide a custom RPC endpoint.
// const endpoint = useMemo(() => clusterApiUrl(network), [network]);
// console.log(endpoint);
// //wallet connection Error handling
// const SolanaConnection = (error = WalletError) => {
//   console.log("Wallet Connection Error:", error);
// };
// const wallet = useMemo(
//   () => [
//     new PhantomWalletAdapter(),
//     new CoinbaseWalletAdapter(),
//     new SlopeWalletAdapter(),
//     new TorusWalletAdapter(),
//     new SolflareWalletAdapter({ network }),
//   ],
//   [network]
// );
/* ********************************************************************** */
ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ProviderNetwork getLibrary={getLibrary}>
      {/* <WalletProvider store={store}> */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <LoaderComponent></LoaderComponent>
            <ConnectSolana>
              <HashRouter>
                <Switch>
                  <AuthGuard
                    path="/vainkjranltifshdfhgdsfhdjjhdgf"
                    component={AdminLayout}
                  />

                  <NoGuard path="/" component={AuthLayout} />
                  {/* Landing layout to render landing on ablank page */}
                  <TempGuard path="/" component={LandingLayout} />
                  <Redirect from="/" to="/" />

                  {/* <Redirect from="/" to="/vnjdLbguybcAzLmvAlsdnfjDbiumaas/login" /> */}
                </Switch>
              </HashRouter>
            </ConnectSolana>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
      {/* </WalletProvider> */}
    </Web3ProviderNetwork>
  </Web3ReactProvider>,
  document.getElementById("root")
);
