import { actionTypes } from "../actons/withdraw.action";

const initialState = {
  withdrawList: [],
  totalRecords: "",
  userTotalBalance: ""
};

const withdraw = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WITHDRAW_LIST:
      return {
        ...state,
        withdrawList: action.data.data,
        totalRecords: action.data.meta.total
      };
      case actionTypes.GET_TOTAL_BALANCE:
      return {
        ...state,
        userTotalBalance: action.data.data,
      };
    default:
      return state;
  }
};

export default withdraw;
