import React, { Component } from "react";
import { connect } from "react-redux";
import { getFundHistory } from "../../Redux/actons/fund.action";
import { withRouter } from "react-router";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Input,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { listDateFormat } from "../../utils/utils";
import Header from "components/Headers/Header";
import { Pagination, Form, Dropdown } from "semantic-ui-react";
import { toast } from "../../components/Toast/Toast";
import CustomHeader from "../../components/Headers/CustomHeader";
import { Link } from "react-router-dom";
class FundHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
    };
  }
  componentDidMount = () => {
    this.checkRouteValid();

    this.getFundHistory();
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      var isfound = false;
      await this.props.permissions.forEach((element) => {
        if (element.title == "Fund Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };
  getFundHistory = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.getFundHistory(data);
  };
  onSearch = (text) => {
    this.setState({ searchKey: text, page: 1 }, () => {
      this.getFundHistory();
    });
  };
  //page change
  changeTransPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getFundHistory();
    });
  };
  render() {
    return (
      <div>
        <Header headerTitle="Fund history" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="fund-management">Fund Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Fund History</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Transaction List</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onChange={(e) => this.onSearch(`${e.target.value}`)}
                        value={this.state.searchKey}
                      ></Input>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Coin</th>
                        <th scope="col">Fund type</th>
                        <th scope="col">Previous amount</th>
                        <th scope="col">Set amount</th>
                        <th scope="col">Comment</th>
                        <th scope="col">Added on</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.fundHistory.map((data, index) => (
                        <tr className="table-tr" key={index}>
                          <td>{data.fund_user_data.email}</td>
                          <td>
                            {data.fund_coin_data.coin_symbol.toUpperCase()}
                          </td>
                          <td>{data.fund_type}</td>
                          <td>{data.previous_amount}</td>
                          <td>{data.set_amount}</td>
                          <td>{data.comment ? data.comment : "None"}</td>
                          <td>{listDateFormat(data.created_at)}</td>
                        </tr>
                      ))}
                      {this.props.fundHistory.length == 0 && (
                        <td>No record found</td>
                      )}
                    </tbody>
                  </Table>
                  {this.props.fundHistoryTotal > this.state.perPage && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) =>
                            this.changeTransPage(e, data)
                          }
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.fundHistoryTotal / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log("FUNDS", state);
  return {
    fundHistory: state.funds.fundHistory,
    fundHistoryTotal: state.funds.fundHistoryTotal,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFundHistory: (data) => dispatch(getFundHistory(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FundHistory)
);
