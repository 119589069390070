import React, { Component } from "react";
import LoginForm from "./LoginForm";
import { loginUser } from "../../Redux/actons/user.action";
import { connect } from "react-redux";

class LoginFormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = (values) => {
    this.props.onLoginUser(values, this.props.history);
  };

  render() {
    return (
      <LoginForm onSubmit={(values) => this.handleSubmit(values)}></LoginForm>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formVal: state.form,
    user: state.persist["c_user"],
    usersDetails: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoginUser: (values, history) => dispatch(loginUser(values, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormPage);
