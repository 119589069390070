import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Form } from "semantic-ui-react";
import "./UserManagement.scss";
import { withRouter } from "react-router";

import { userDetail } from "../../../Redux/actons/userList.action";
import { Field, reduxForm, change } from "redux-form";

export class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {};

  render() {
    let { handleSubmit } = this.props;
    // console.log('this.propsthis.props>>',this.props);
    return (
      <div>
        <div>
          <Modal isOpen={this.props.detailModal} className={"modalCustom"}>
            <ModalHeader toggle={this.props.clostDetailModal}></ModalHeader>
            <ModalBody>
              <div className="userNameList">
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Name:</label>
                  <span>
                    {this.props.userDetail.name
                      ? this.props.userDetail.name
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Email:</label>
                  <span style={{ textDecoration: "none" }}>
                    {this.props.userDetail.email
                      ? this.props.userDetail.email
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Email Verified:</label>
                  <span style={{ textDecoration: "none" }}>
                    {this.props.userDetail.email_status &&
                    this.props.userDetail.email_status == 1
                      ? "Yes"
                      : "No"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Aadhaar no:</label>
                  <span>
                    {this.props.userDetail.document_number
                      ? this.props.userDetail.document_number
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Pan no:</label>
                  <span>
                    {this.props.userDetail.pancard_number
                      ? this.props.userDetail.pancard_number
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Mobile:</label>
                  <span>
                    {this.props.userDetail.mobile
                      ? this.props.userDetail.mobile
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Mobile Verified:</label>
                  <span>
                    {this.props.userDetail?.mobile_status &&
                    this.props.userDetail.mobile_status == 1
                      ? "Yes"
                      : "No"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Address:</label>
                  <span>
                    {this.props.userDetail.address
                      ? this.props.userDetail.address
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Country:</label>
                  <span>
                    {this.props.userDetail.country
                      ? this.props.userDetail.country
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>City:</label>
                  <span>
                    {this.props.userDetail.city
                      ? this.props.userDetail.city
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Zip Code:</label>
                  <span>
                    {this.props.userDetail.zip_code
                      ? this.props.userDetail.zip_code
                      : "None"}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "white" }}>Device Type:</label>
                  <span>
                    {this.props.userDetail.device_type == 0 ? "IOS" : "Android"}
                  </span>
                </div>
                <div style={{ marginTop: "16px" }}>
                  {this.props.userDetail.doc_image1 !== null &&
                    this.props.userDetail.doc_image1 !== "" && (
                      <span>
                        <img
                          onClick={() =>
                            window.open(
                              `${this.props.userDetail.doc_image1}`,
                              "_blank"
                            )
                          }
                          style={{ height: "200px", width: "48%" }}
                          src={
                            this.props.userDetail.doc_image1
                              ? this.props.userDetail.doc_image1
                              : "No image"
                          }
                        ></img>
                      </span>
                    )}
                  {this.props.userDetail.doc_image2 !== null &&
                    this.props.userDetail.doc_image2 !== "" && (
                      <span style={{ marginLeft: "10px" }}>
                        <img
                          onClick={() =>
                            window.open(
                              `${this.props.userDetail.doc_image2}`,
                              "_blank"
                            )
                          }
                          style={{ height: "200px", width: "48%" }}
                          src={
                            this.props.userDetail.doc_image2
                              ? this.props.userDetail.doc_image2
                              : "No image"
                          }
                        ></img>
                      </span>
                    )}
                  {this.props.userDetail.doc_image3 !== null &&
                    this.props.userDetail.doc_image3 !== "" && (
                      <span style={{ marginTop: "10px" }}>
                        <img
                          onClick={() =>
                            window.open(
                              `${this.props.userDetail.doc_image3}`,
                              "_blank"
                            )
                          }
                          style={{ height: "200px", width: "48%" }}
                          src={
                            this.props.userDetail.doc_image3
                              ? this.props.userDetail.doc_image3
                              : "No image"
                          }
                        ></img>
                      </span>
                    )}
                  {this.props.userDetail.pancard_image !== null &&
                    this.props.userDetail.pancard_image !== "" && (
                      <span style={{ marginLeft: "10px" }}>
                        <img
                          onClick={() =>
                            window.open(
                              `${this.props.userDetail.pancard_image}`,
                              "_blank"
                            )
                          }
                          style={{ height: "200px", width: "48%" }}
                          src={
                            this.props.userDetail.pancard_image
                              ? this.props.userDetail.pancard_image
                              : "No image"
                          }
                        ></img>
                      </span>
                    )}
                  {/* <Form style={{ marginTop: "20px" }}>
                    <Form.TextArea
                      label="Add Comments"
                      placeholder="Add Comments..."
                      onChange={(e) => this.setMessage(e)}
                    />
                  </Form> */}
                </div>
              </div>
            </ModalBody>
            {/* <ModalFooter>
              <Button color="secondary" onClick={() => this.closeMe()}>
                Close
              </Button>
              <Button
                color="success"
                disabled={!this.state.comment}
                onClick={() => this.sendMesage()}
              >
                Send
              </Button>
            </ModalFooter> */}
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userDetail: state.userList.getUserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "UserDetail" })(UserDetail))
);
