import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const TempGuard = ({ component: Component, ...rest }) => {
  const { token, user } = rest;
  var isAuthenticated = false;
  if (token.token !== "" && token.token !== undefined) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname:
                user.role == 1
                  ? "/vainkjranltifshdfhgdsfhdjjhdgf/index"
                  : "/vainkjranltifshdfhgdsfhdjjhdgf/account-setting",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.persist["c_user"],
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(TempGuard);
