import React, { Component } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
} from "reactstrap";

import { connect } from "react-redux";
import CustomHeader from "../../components/Headers/CustomHeader";
import {
  getAppSettings,
  triggerOnOff,
} from "../../Redux/actons/userList.action";
class AppSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    let data = {
      type: "server_maintenance_mode",
    };
    this.props.getAppSettings(data);
  };
  enableDisableApp = () => {
    let data = {
      value: this.props.appSetting[0].value == 0 ? 1 : 0,
      type: "server_maintenance_mode",
    };

    this.props.triggerOnOff(data);
  };
  render() {
    return (
      <div>
        <div>
          <CustomHeader />
        </div>
        {this.props.appSetting.length > 0 && (
          <div className="contentBlock container-fluidk authList">
            <Card body>
              <CardTitle style={{ fontSize: "16px" }}>
                ON/OFF (Signup/SignIn)
              </CardTitle>
              <Button color="info" onClick={() => this.enableDisableApp()}>
                {this.props.appSetting[0].value == 1 ? "Enable" : "Disable"}
              </Button>
            </Card>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
    appSetting: state.userList.appSetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAppSettings: (data) => dispatch(getAppSettings(data)),
    triggerOnOff: (data) => dispatch(triggerOnOff(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppSetting);
