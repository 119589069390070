import React, { Component } from "react";
import { Form, Dropdown } from "semantic-ui-react";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../../components/FormField";
import { SelectField } from "../../../components/SelectField";
import { required } from "redux-form-validators";
import {
  getTradeSettings,
  getOrderSettings,
  updateTradeSettings,
  addOrUpdateMarkup,
  getCoinList,
} from "../../../Redux/actons/user.action";
import Header from "components/Headers/Header";
import { Link } from "react-router-dom";

const trade_fee_type = [
  {
    key: "fixed",
    text: "fixed",
    value: "fixed",
  },
  {
    key: "percentage",
    text: "percentage",
    value: "percentage",
  },
];
export class OrderSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      markupRate: "",
      selectedCoin: "",
      fee: "",
      trade_buy_fee: "",
      trade_sell_fee: "",
      tradeType: "flat",
    };
    this.updateTradeSetting = this.updateTradeSetting.bind(this);
  }
  componentDidMount = async () => {
    await this.props.getOrderSettings();
    await this.props.getCoinList();
    this.setupTradeList();
  };

  setupTradeList() {
    const tradeList = this.props.tradeSettingList;
    console.log("-----------------trade", tradeList);
    this.setState({
      trade_buy_fee: tradeList?.find((el) => el.type == "trade_buy_fee")?.value,
      trade_sell_fee: tradeList?.find((el) => el.type == "trade_sell_fee")
        ?.value,
      tradeType: tradeList?.find((el) => el.type == "trade_fee_type")?.value,
    });
  }

  updateTradeSetting() {
    const formData = {
      mark_up: this.state.trade_buy_fee,
      mark_down: this.state.trade_sell_fee,
      type: this.state.tradeType,
    };
    this.props.addOrUpdateMarkup(formData);
  }

  onFeeChange(value, type) {
    if (type == "trade_buy_fee") {
      // if (value > 100) {
      //   alert("Trade buy fee must be less than and equal to 100");
      //   return false;
      // }
      this.setState({ trade_buy_fee: value });
    } else {
      // if (value > 100) {
      //   alert("Trade sell fee must be less than and equal to 100");
      //   return false;
      // }
      this.setState({ trade_sell_fee: value });
    }
  }

  render() {
    let { handleSubmit } = this.props;
    // console.log("  saveOrderDetails  ----", this.props.saveOrderDetails);
    return (
      <div>
        <div>
          <Header headerTitle="Order Settings" />
        </div>
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="order-management">Order Book Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Order Settings</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div style={{ padding: 30 }}>
          <Form onSubmit={handleSubmit(this.updateTradeSetting)}>
            {/* <Form.Group>
              <Form.Field>
                <label>Choose Fee Coin</label>
                <Dropdown
                  placeholder="Select Coin"
                  name="coin"
                  onChange={(e, data) =>
                    this.setState({ selectedCoin: data.value })
                  }
                  selection
                  options={this.props.coinList}
                  value={+this.state.selectedCoin}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Fee Amount</label>
                <input
                  type="number"
                  name="fee"
                  min="0.00"
                  step="0.001"
                  presicion={2}
                  value={this.state.fee}
                  validate={[required()]}
                  component={FormField}
                  onChange={(e, data) => this.setState({ fee: e.target.value })}
                ></input>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            </Form.Group> */}
            {/* <Form.Group>
              <Form.Field>
                <label>Mark up/down rate(%)</label>
                <input
                  type="number"
                  name="markup_rate"
                  validate={[required()]}
                  component={FormField}
                  min="0.00"
                  step="0.001"
                  presicion={2}
                  value={this.state.markupRate}
                  onChange={(e, data) =>
                    this.setState({ markupRate: e.target.value })
                  }
                ></input>
              </Form.Field>
            </Form.Group> */}
            <Form.Group>
              <Form.Field>
                <label>Markup (%)</label>
                <input
                  type="number"
                  name="trade_buy_fee"
                  validate={[required()]}
                  component={FormField}
                  min="0.00"
                  step="0.001"
                  presicion={2}
                  placeholder={this.props?.saveOrderDetails?.mark_up}
                  value={
                    this.state.trade_buy_fee
                    // ||  this.props.saveOrderDetails.mark_up
                  }
                  onChange={(e, data) =>
                    this.onFeeChange(e.target.value, "trade_buy_fee")
                  }
                ></input>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Markdown (%)</label>
                <input
                  type="number"
                  name="trade_sell_fee"
                  validate={[required()]}
                  component={FormField}
                  min="0.00"
                  step="0.001"
                  presicion={2}
                  placeholder={this.props?.saveOrderDetails?.mark_down}
                  value={
                    this.state.trade_sell_fee
                    // || this.props.saveOrderDetails.mark_down
                  }
                  onChange={(e, data) =>
                    this.onFeeChange(e.target.value, "trade_sell_fee")
                  }
                ></input>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Mark Type</label>
                <Dropdown
                  style={{ textTransform: "uppercase", width: 150 }}
                  className="selectUserStyle myupper"
                  selection
                  placeholder="Select Trade type"
                  value={
                    this.state.tradeType || this.props?.saveOrderDetails?.type
                  }
                  fluid
                  onChange={(e, d) => {
                    this.setState({ tradeType: d.value });
                  }}
                  options={trade_fee_type}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field className="addBtn">
              <Button type="submit" color="success">
                Submit
              </Button>
            </Form.Field>
          </Form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    coinList: state.user.coinsList,
    tradeSettingList: state.user.tradeSettingList,
    saveOrderDetails: state.user.saveOrderDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderSettings: () => dispatch(getOrderSettings()),
    getCoinList: () => dispatch(getCoinList()),
    addOrUpdateMarkup: (data) => dispatch(addOrUpdateMarkup(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "tradeSettings" })(OrderSetting));
