import React, { Component } from "react";
// import {
//   Form,
//   Image,
//   Modal,
//   Grid,
//   Button,
//   List,
//   GridColumn,
//   Header,
//   Input
// } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { required, email } from "redux-form-validators";
import { FormField } from "../../../components/FormField"
import { connect } from "react-redux";
import { Card, Button, CardTitle,CardImg, Container, Row, Col ,Form} from 'reactstrap';



export class GoogleAuthStepThree extends Component {
  render() {
    let { handleSubmit, step } = this.props;
    return (
      <Row columns={1} className="stepThree">
        <Col>
          <Form onSubmit={handleSubmit}>
            <label>Login password</label>

            <Field
              name="password"
              size="small"
              component={FormField}
              type="password"
              placeholder="Please enter password"
              validate={[required()]}
              autoFocus
            />
            <br />
            <label>Google authentication code</label>

            <Field
              name="token"
              size="small"
              component={FormField}
              type="password"
              placeholder="Enter authentication code"
              validate={[required()]}
            />

            <Row columns={2} style={{ marginTop: "20px" }}>
              <Col className="mr0">
                <Button
                color="secondary"
                  type="button"
                  floated="left"
                  onClick={() => this.props.goToPrevStep(step)}
                >
                  Previous
                </Button>
              </Col>
              <Col className="mr0">
                <Button type="submit" color="info" floated="right" positive>
                  Enable
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    step: state.account.googleStep
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // verifyAuthToken: values => dispatch(verifyAuthRequest(values)),
    // verifygoogleAuth: values => dispatch(verifyGoogleAuthRequest(values))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "GoogleEnableForm" })(GoogleAuthStepThree));
