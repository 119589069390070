import React from "react";
import { Dropdown } from "semantic-ui-react";
import "./FormField.scss";

const SelectField = props => {
  const { meta = {} } = props;
  const selectProps = {
    name: props.input.name,
    id: props.input.name,
    onChange: props.input.onChange,
    placeholder: props.placeholder,
    options: props.children,
    defaultValue: props.defaultValue,
    selectOnBlur: props.selectOnBlur
  };

  const onPress = (e, data, onChange, onChangeText) => {
    onChange(data.value);
    if (onChangeText) {
      onChangeText(data.value);
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        value={props.defaultValue}
        {...selectProps}
        search={props.search}
        selection
        onChange={(e, data) =>
          onPress(e, data, props.input.onChange, props.onChangeText)
        }
        disabled={props.disabled}
      />
      {meta.touched && meta.error ? (
        <div className="form__field-error">{meta.error}</div>
      ) : null}
    </React.Fragment>
  );
};

export { SelectField };
