import React from "react";
import { Field, reduxForm } from "redux-form";
import { FormField } from "../../components/FormField";
import { required, email } from "redux-form-validators";
import { connect } from "react-redux";
import {
  passwordValidator,
  resetValidator,
  confirmNewValidator,
  matchPasswords,
  // newPassValidator
} from "../../validators/customValidator";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

class ForgotPassword extends React.Component {
  handleSubmit = () => {};

  render() {
    let { handleSubmit } = this.props;
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody>
              <h2 style={{ textAlign: "center" }}>Reset Password</h2>
              <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Field
                      name="password"
                      component={FormField}
                      type="password"
                      validate={[required(), passwordValidator()]}
                      placeholder="Old Password"
                      autoFocus
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Field
                      name="new_password"
                      component={FormField}
                      type="password"
                      validate={[required(), resetValidator()]}
                      placeholder="New Password"
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Field
                      name="confirm_new_password"
                      component={FormField}
                      type="password"
                      validate={[
                        required(),
                        confirmNewValidator(),
                        matchPasswords(),
                      ]}
                      placeholder="Confirm New Password"
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    SUBMIT
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col className="text-right" xs="12"></Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "ForgotPaswword" })(ForgotPassword));
