import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  getLockFundList,
  enableDisabelLock,
  addNewPlan,
} from "../../Redux/actons/fund.action";
import { openCloseModal } from "../../Redux/actons/SubAdmin.action";
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Pagination, Dropdown, Form } from "semantic-ui-react";
import swapIcon from "../../images/swap.png";
import { listDateFormat } from "../../utils/utils";
import { required } from "redux-form-validators";
import { amountLimit } from "../../validators/customValidator";
import { FormField } from "../../components/FormField";
import { reset, Field, reduxForm, getFormValues } from "redux-form";
import { SelectField } from "../../components/SelectField";

const options = [
  { key: "m", text: "Day", value: "day" },
  { key: "k", text: "Month", value: "month" },
  { key: "l", text: "Year", value: "year" },
];

export class LockFundMangement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      coin_family: "",
      isSendColdWallet: false,
      order: "asc",
      coinId: "",
      totalAmount: "",
      openAmountModal: false,
      planTime: "",
      period: "day",
    };
  }
  componentDidMount = () => {
    this.getPairList();
  };
  componentWillUnmount = () => {
    this.props.openCloseModal();
  };

  getPairList = () => {
    const data = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
    };
    this.props.getLockFundList(data);
  };

  enableDisablePair = (data, id) => {
    const buttonState = { status: data };
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
    };

    this.props.enableDisabelLock(buttonState, id, params);
  };

  addPlan = () => {
    const params1 = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: this.state.searchKey,
    };

    const parameters = {
      plan_time_unit: this.state.period,
      plan_time: this.state.planTime,
    };

    this.props.addNewPlan(parameters, params1);
    this.toggle();
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getPairList();
    });
  };

  openAmountModal = (data) => {
    this.setState({ openAmountModal: true });
  };

  toggle = () =>
    this.setState({ openAmountModal: !this.state.openAmountModal });

  onSearchList = (search) => {
    // this.setState({ searchKey: search }, () => {
    this.getPairList();
    // });
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Lock Fund Management" />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="fund-management">Fund Management</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Lock Fund Management</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  {/* <CardHeader className="border-0">
                    <div className="selectSearchOption"> */}
                  {/* <Input
                        placeholder="Search"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button> */}

                  {/* <Link to="exchange-coin">
                        <Button color="success btnMamagePermission">
                          Add coin pair
                        </Button>
                      </Link> */}

                  {/* <Link to="swap-history">
                        <Button color="success btnMamagePermission">
                          Swap History
                        </Button>
                      </Link> */}
                  {/* </div>
                  </CardHeader> */}
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openAmountModal()}
                      >
                        Add Lock fund
                      </Button>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Plan Time</th>
                        <th scope="col">Plan time unit</th>
                        <th scope="col">Created at</th>
                        <th scope="col">status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.lockFundsList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.lockFundsList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            {/* <td>{usersList.pair_name.toUpperCase()}</td> */}
                            <td>{data?.plan_time}</td>
                            <td>
                              {data.plan_time_unit !== undefined &&
                              data.plan_time_unit !== null
                                ? data?.plan_time_unit.toUpperCase()
                                : ""}
                            </td>
                            <td>{listDateFormat(data?.created_at)}</td>
                            <td>{data?.status.toUpperCase()}</td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color={
                                    data.status === "active"
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={() =>
                                    this.enableDisablePair(
                                      data.status === "active"
                                        ? "inactive"
                                        : "active",
                                      data.id
                                    )
                                  }
                                >
                                  {data.status === "active"
                                    ? "Disable"
                                    : "Enable"}
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {/* {this.props.total != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.total / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}{" "} */}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <Modal isOpen={this.state.openAmountModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Lock Fund</ModalHeader>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Select Period </label>
                  <Dropdown
                    className="selectUserStyle"
                    options={options}
                    selection
                    value={this.state.period}
                    onChange={(e, data) =>
                      this.setState({ period: data.value })
                    }
                  />
                  {/* <Field
                    name="coin_id"
                    search={false}
                    selectOnBlur={false}
                    validate={[required()]}
                    component={SelectField}
                    defaultValue={this.state.selectedCoin}
                    children={this.props.coinsList}
                    placeholder="Select "
                    onChange={(e, data) =>
                      this.setState({ selectedCoin: data })
                    } */}
                  {/* ></Field> */}
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Plan time</label>
                  <input
                    type="number"
                    name="reward_amount"
                    min="0"
                    validate={[required(), amountLimit()]}
                    onChange={(e, data) =>
                      this.setState({ planTime: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openAmountModal: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.addPlan()}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log("state", state);
  return {
    lockFundsList: state.funds.lockFundList,
    // total: state.pairCoinList.pairCoinList.total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (data) => dispatch(reset(data)),
    openCloseModal: () => dispatch(openCloseModal(true)),
    getLockFundList: (data) => dispatch(getLockFundList(data)),
    enableDisabelLock: (data, id, params) =>
      dispatch(enableDisabelLock(data, id, params)),

    addNewPlan: (data, params) => dispatch(addNewPlan(data, params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "lockFundMangement" })(LockFundMangement));
