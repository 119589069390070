import { actionTypes } from "../actons/transaction.action";

const initialState = {
  getTransFeeList: [],
  totalTransRecords: "",
  queryListRecords: "",
  txnDetails: {},
  dailyWithdawLimit: 0,
  gstValue: 0,
  tdsValue: 0,
};

const transaction = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case actionTypes.SAVE_TRANSACTION_LIST:
      return {
        ...state,
        getTransFeeList: action.data.data,
        queryListRecords: action.data,
      };

    case actionTypes.TOTAL_TRANSACTION_RECORDS:
      return {
        ...state,
        totalTransRecords: action.data,
        // queryListRecords: action,
      };
    case actionTypes.SAVE_TRANSACTION_DETAIL:
      return {
        ...state,
        txnDetails: action.data,
      };
    case actionTypes.SAVE_DAILY_WITHDRAW_LIMIT:
      return {
        ...state,
        dailyWithdawLimit: action.data,
      };
    case actionTypes.SAVE_TXN_WALLET_THRESHOLD_VALUE:
      return {
        ...state,
        txnWalletThresholdValue: action.data,
      };
    case actionTypes.SAVE_GST_LIMIT:
      return {
        ...state,
        gstValue: action.data,
      };
    case actionTypes.SAVE_TDS_LIMIT:
      return {
        ...state,
        tdsValue: action.data,
      };

    default:
      return state;
  }
};

export default transaction;
