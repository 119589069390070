import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  getInfluencerReferralList,
  changeInfluencerCouponStatus,
} from "../../../Redux/actons/referral.action";
import { openCloseModal } from "../../../Redux/actons/SubAdmin.action";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { IMAGE_PATH } from "../../../constants/index";
import { Pagination, Confirm, Form } from "semantic-ui-react";
import AddCoupon from "./AddCoupon";
import { setType } from "utils/utils";
import { FormField } from "../../../components/FormField";
import { required } from "redux-form-validators";
import { amountLimit } from "../../../validators/customValidator";

export class InfluencerManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "20",
      page: "1",
      order: "",
      sortBy: "",
      coin_family: "",
      order: "asc",
      coinId: "",
      totalAmount: "",
      deleteConfirm: false,
      deleteData: {},
      addRewardModal: false,
    };
  }
  componentDidMount = () => {
    this.getPairList();
  };
  componentWillUnmount = () => {
    this.props.openCloseModal();
  };

  getPairList = () => {
    const param = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: "",
    };
    this.props.getInfluencerReferralList(param);
  };

  enableDisablePair = (data, id) => {
    const param = {
      page: this.state.page,
      perPage: this.state.perPage,
      searchKey: "",
    };

    const buttonState = { coupon_id: id, action: data };
    this.props.changeInfluencerCouponStatus(buttonState, param);
  };

  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getPairList();
    });
  };

  deleteReward = (data, id, status) => {
    if (status == "active") {
      alert("Please inactive the plan before deleting.");
      return false;
    }
    let deleteData = { status: data, plan_id: id };
    this.setState({ deleteData: deleteData, deleteConfirm: true });
  };

  closeDeleteConfirm = () => {
    this.setState({ deleteData: {}, deleteConfirm: false });
  };

  checkForEnable = (list, data) => {
    if (data?.status == 1) {
      return true;
    } else if (
      list?.find(
        (el) =>
          el.coupon.toUpperCase() == data?.coupon?.toUpperCase() &&
          el.status == 1
      ) &&
      data?.status == 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  addNewReward = () => {
    this.setState({ addRewardModal: !this.state.addRewardModal });
  };

  onConfirmation = () => {
    const buttonState = {
      status: this.state.deleteData.status,
      plan_id: this.state.deleteData.plan_id,
    };
    const params = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    this.props.enableDisableReferral(buttonState, params);
    this.setState({ deteteData: {}, deleteConfirm: false });
  };

  onIncentiveClick = (data) => {
    this.props.history.push({
      pathname: `influencer-incentive`,
      state: data,
    });
  };

  onHistoryClick = (data) => {
    this.props.history.push({
      pathname: `influencer-history`,
      state: data,
    });
  };

  render() {
    let { handleSubmit } = this.props;
    console.log("props", this.props.referralList);
    return (
      <div>
        <Header headerTitle="Influencer Management" />
        <div>
          <div>
            <Confirm
              content={`Are you sure to delete this plan?`}
              style={{
                height: "auto",
                top: "5%",
                left: "auto",
                bottom: "auto",
                right: "auto",
              }}
              size="small"
              className="confirm-model"
              open={this.state.deleteConfirm}
              onCancel={this.closeDeleteConfirm}
              onConfirm={this.onConfirmation}
            />
          </div>

          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      <Button
                        color="default btnMamagePermission"
                        onClick={() => this.addNewReward()}
                      >
                        + Add Coupon
                      </Button>
                      {/* <Link to="influencer-history">
                        <Button color="success btnMamagePermission">
                          View History
                        </Button>
                      </Link> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Coupon</th>
                        <th scope="col">User Limit</th>
                        <th scope="col">Available</th>
                        <th scope="col">Type</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props?.influencerReferralList?.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props?.influencerReferralList?.map(
                          (data, index) => (
                            <tr className="table-tr" key={index}>
                              <td>{index + 1}.</td>
                              <td>{data?.name?.toUpperCase()}</td>
                              <td>{data?.coupon}</td>
                              <td>{data?.limit != null ? data?.limit : "-"}</td>
                              <td>
                                {data?.available != null
                                  ? data?.limit - data?.available
                                  : "-"}
                              </td>
                              <td>
                                {data.type === "sign_up" ? "Sign Up" : ""}
                                {data.type === "redeem_coupon"
                                  ? "Redeem Coupon"
                                  : ""}
                              </td>
                              <td>
                                {data.status === 1 ? "ACTIVE" : "INACTIVE"}
                              </td>
                              <td>
                                {/* <Button
                                color="primary"
                                onClick={() => this.onEditPair(data)}
                              >
                                Edit Referral
                              </Button> */}
                                <Button
                                  color={
                                    data.status === 1 ? "danger" : "success"
                                  }
                                  onClick={() => {
                                    if (
                                      this.checkForEnable(
                                        this.props?.referralList,
                                        data
                                      ) == true
                                    ) {
                                      this.enableDisablePair(
                                        data.status === 1 ? 0 : 1,
                                        data.id
                                      );
                                    } else {
                                      alert(
                                        `${data?.name?.toUpperCase()} is alreay active`
                                      );
                                    }
                                  }}
                                >
                                  {data.status === 1 ? "Disable" : "Enable"}
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => this.onIncentiveClick(data)}
                                >
                                  View Incentive
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => this.onHistoryClick(data)}
                                >
                                  View History
                                </Button>
                              </td>
                            </tr>
                          )
                        )
                      )}
                    </tbody>
                  </Table>
                  {/* <CardFooter className="py-4"></CardFooter> */}
                </Card>
              </div>
            </Row>
            {this.props.influencerReferralList?.length != 0 && (
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    // onPageChange={(e, data) => this.changeKycPage(e, data)}
                    boundaryRange={1}
                    defaultActivePage={1}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={Math.ceil(
                      this.props.totalPages / this.state.perPage
                    )}
                  />
                </nav>
              </CardFooter>
            )}
          </Container>
          <AddCoupon
            closeRewardModal={this.addNewReward}
            addRewardModal={this.state.addRewardModal}
            reloadParams={{
              page: this.state.page,
              perPage: this.state.perPage,
              searchKey: "",
            }}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    influencerReferralList: state.referralList.influencerReferralList.data,
    totalPages: state.referralList.influencerReferralList.meta?.total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (data) => dispatch(reset(data)),
    openCloseModal: () => dispatch(openCloseModal(true)),
    getInfluencerReferralList: (param) =>
      dispatch(getInfluencerReferralList(param)),
    changeInfluencerCouponStatus: (data, params) =>
      dispatch(changeInfluencerCouponStatus(data, params)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InfluencerManagement)
);
