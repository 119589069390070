import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Pagination, Dropdown } from "semantic-ui-react";
import { reduxForm } from "redux-form";
import CustomHeader from "../../components/Headers/CustomHeader";
import { IMAGE_PATH } from "../../constants/index";

import { withRouter, Link } from "react-router-dom";

import {
  getGuideScreenList,
  changeGuideStatus,
} from "../../Redux/actons/userGuide.action";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
export class UserGuideListManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
      sortBy: "",
      getDetailsId: {},
      modal: false,
    };
  }
  toggle = () => this.setState({ open: !this.state.open });

  componentDidMount = () => {
    this.getUserGuideList();
  };
  // addNewAnnouncement = () => {
  //   this.props.saveAnnounceEditDetails({});
  //   this.props.history.push("add-announcement");
  // };
  addUserGuideList = (data) => {
    console.log("data >>>", this.props.history);
    // this.props.saveAnnounceEditDetails(data);
    console.log("fdjghfdkghfjk : ", this.props.history);
    this.props.history.push("add-user-guide");
  };
  updateUserGuideList = (id, status) => {
    let data = {
      id: id,
      status: status,
    };
    this.props.changeGuideStatus(data);
  };

  sendAnnouncement = (id) => {
    console.log("ID", id);
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.sendAnnouncementNotification(data, id);
  };

  getUserList = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.getAnnouncementList(data);
  };

  getUserGuideList = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.getGuideScreenList(data);
  };

  // //page change
  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getUserList();
    });
  };

  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ order: data.value }, () => {
      this.getUserList();
    });
  };
  //search
  onSearchList = (abc) => {
    // this.setState({ searchKey: abc, page: 1 }, () => {
    this.getUserList();
    // });
  };
  openModel = (data) => {
    // console.log("detail", data);
    this.setState({ getDetailsId: data, open: true });
  };

  render() {
    console.log("this.props >>>", this.props);
    return (
      <div>
        <CustomHeader />
        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">User Guide List</h3>
                    </div>
                    <div className="selectSearchOption">
                      {/* <Input
                        placeholder="Search"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input> */}
                      {/* <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                      className="ml-2"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.filterBy(e, data)}
                      /> */}
                      {/* <Button
                        color="default btnMamagePermission"
                        onClick={() => this.addNewAnnouncement()}
                      >
                        + Add Announcement
                      </Button>
                      <Link to="banner-management">
                          <Button color="success btnMamagePermission">
                            Banner Management
                          </Button>
                      </Link> */}
                      <Button
                        color="default btnMamagePermission"
                        onClick={() => this.addUserGuideList()}
                      >
                        + Add More UserGuide
                      </Button>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Image</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props?.UserGuideList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          <td>
                            <img
                              src={`${IMAGE_PATH}${usersList?.image}`}
                              alt=""
                              style={{ height: 40, width: 40 }}
                            />
                          </td>
                          <td>{usersList?.status}</td>

                          <td className="text-left">
                            <span className="mb-0 text-sm">
                              {" "}
                              {/* <Button
                                color="success"
                                onClick={() => this.openModel(usersList)}
                              >
                                View
                              </Button> */}
                              {/* <Button
                                color="danger"
                                onClick={() =>
                                  this.deleteAnnouncement(usersList.id)
                                }
                              >
                                Delete
                              </Button> */}
                            </span>
                            <span className="mb-0 text-sm">
                              {" "}
                              {usersList?.status === "active" ? (
                                <Button
                                  className="mx-2"
                                  color="success"
                                  onClick={() =>
                                    this.updateUserGuideList(
                                      usersList.id,
                                      "in-active"
                                    )
                                  }
                                >
                                  Disable
                                </Button>
                              ) : (
                                <Button
                                  className="mx-2"
                                  color="success"
                                  onClick={() =>
                                    this.updateUserGuideList(
                                      usersList.id,
                                      "active"
                                    )
                                  }
                                >
                                  Enable
                                </Button>
                              )}
                              <Button
                                className="mx-2"
                                color="danger"
                                onClick={() =>
                                  this.updateUserGuideList(
                                    usersList.id,
                                    "deleted"
                                  )
                                }
                              >
                                delete
                              </Button>
                            </span>
                            <span className="mb-0 text-sm">
                              {" "}
                              {/* <Button
                                color="success"
                                onClick={() =>
                                  this.sendAnnouncement(usersList.id)
                                }
                              >
                                Send
                              </Button> */}
                            </span>
                          </td>
                        </tr>
                      ))}
                      {this.props?.UserGuideList.length == 0 && (
                        <td>No record</td>
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      {this.props.UserGuideRecords > 0 && (
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={0}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.UserGuideRecords / this.state.perPage
                          )}
                        />
                      )}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        {console.log(this.props?.UserGuideList, "UserGuideList")}
        <div>
          <Modal isOpen={this.state.open} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>More..</ModalHeader>
            <ModalBody>
              <div className="userNameList">
                <div className="itemStyle">
                  <label
                    style={{ color: "black", fontWeight: "bold", fontSize: 14 }}
                  >
                    {" "}
                    {/* Subject:<p>{this.state.getDetailsId.subject}</p> */}
                  </label>
                </div>
                <div className="itemStyle">
                  <label
                    style={{ color: "black", fontWeight: "bold", fontSize: 14 }}
                  >
                    {/* Message:<p>{this.state.getDetailsId.message} </p> */}
                  </label>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log("@@@@@@>>>>>>>ritu.", state);
  return {
    // getAnnounceList: state.announcement.getAnnounceList,
    // announceRecords: state.announcement.announceRecords,
    UserGuideList: state?.userGuide?.getUserGuideList,
    UserGuideRecords: state?.userGuide?.userGuideRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getAnnouncementList: (data) => dispatch(getAnnouncementList(data)),
    getGuideScreenList: (data) => dispatch(getGuideScreenList(data)),

    // getAnnouncementDetails: (id) => dispatch(getAnnouncementDetails(id)),
    changeGuideStatus: (data) => dispatch(changeGuideStatus(data)),
    // saveAnnounceEditDetails: (data) => dispatch(saveAnnounceEditDetails(data)),
    // sendAnnouncementNotification: (data, id) =>
    //   dispatch(sendAnnouncementNotification(data, id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserGuideListManagement);
