import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { verifyGoogleAuthRequest } from "../../Redux/actons/security.action";
import { Redirect } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { InjectedConnector } from "@web3-react/injected-connector";
import { Pagination, Dropdown, Icon } from "semantic-ui-react";
import { IMAGE_PATH } from "constants/index";
// import { ethers } from "ethers";
import Blockies from "react-blockies";
import { toast } from "../../components/Toast/Toast";
import { trxMultisender } from "assets/ABI/ContractAddress";

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 9731],
});

const ChainNetworkId = [
  {
    key: "ETH",
    text: (
      <>
        {" "}
        <img
          src={`${IMAGE_PATH}images/1634290767066-628px-Ethereum_logo_2014.svg.png`}
          alt="logo"
          style={{ height: 32, width: 25 }}
        />
        Ethereum Mainnet (ETH)
      </>
    ),
    value: "1",
  },
  {
    key: "BNB",
    text: (
      <>
        {" "}
        <img
          src={`${IMAGE_PATH}images/1655508573511-bnb-bnb-logo.png`}
          alt="logo"
          style={{ height: 32, width: 25 }}
        />
        Binance Mainnet (BNB)
      </>
    ),
    value: "56",
  },
  {
    key: "MATIC",
    text: (
      <>
        {" "}
        <img
          src={`${IMAGE_PATH}images/1655508675070-polygon-matic-logo%20(1).png`}
          alt="logo"
          style={{ height: 32, width: 25 }}
        />
        Matic Mainnet (MATIC)
      </>
    ),
    value: "137",
  },
];
const TronConnection = (props) => {
  //   const { activate, account, deactivate, chainId } = useWeb3React();
  const web3 = (window.web3 = new Web3(window.ethereum));
  const [networkId, setNetworkId] = React.useState();
  const [loader, setloader] = React.useState(false);
  const [account, setAddress] = React.useState(
    window?.tronWeb?.defaultAddress?.base58
  );

  const connectTron = async (params) => {
    setloader(true);
    let istronWeb;
    try {
      const tronWeb = await window.tronWeb;
      console.log("tronWeb ------>>>> ", tronWeb);

      if (tronWeb == undefined) {
        toast.error(["trown wallet not found "]);
        return;
      }
      let address = window?.tronWeb?.defaultAddress?.base58;
      setAddress(address);

      let instance = await window.tronWeb.contract().at(trxMultisender);
      // setContract(instance);

      const account = await window.tronWeb.trx.getAccount(
        window.tronWeb.defaultAddress.base58
      );

      //   var currentaddress = await tronWeb.address.fromHex(
      //     (await tronWeb.trx.getAccount()).address.toString()
      //   );
      //   istronWeb = true;
      //   var balance = await tronWeb.trx.getBalance(currentaddress);
      //   balance = balance / 10 ** 6;
    } catch (err) {
      console.log("Tronweb not defined 133", err);
      istronWeb = false;
    }
  };

  return (
    <React.Fragment>
      <div
        className="selectSearchOption"
        style={{
          paddingTop: "15px",
        }}
      >
        <button className={"blockies"} onClick={connectTron}>
          <Blockies className={"blockies"} seed={"Jeremy"} size={8} scale={3} />
          <div>
            {account
              ? account.slice(0, 6) + "..." + account.slice(-4)
              : "Connect Tron Wallet"}
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("DAIVIK", state);
  return {
    token: state.persist["c_user"],
    user: state.user["user"],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifygoogleAuth: (values) => dispatch(verifyGoogleAuthRequest(values)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TronConnection);
