import { actionTypes } from "../actons/pairCoin.action";

const initialState = {
  pairCoinList: [],
  pairCoin: null,
  swapHistory: [],
  activeTradeCurrencies: [],
};

const pairCoin = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAIR_COIN_LIST:
      return {
        ...state,
        pairCoinList: action.data.data,
      };
    case actionTypes.PAIR_COIN:
      return {
        ...state,
        pairCoin: action.data.data,
      };
    case actionTypes.SWAP_HISTORY:
      return {
        ...state,
        swapHistory: action.data.data.history,
        totalRecords: action.data.data.totalRecords,
      };
    case actionTypes.SAVE_ACTIVE_TRADE_CURRENCIES:
      return {
        ...state,
        activeTradeCurrencies: action.data,
      };

    default:
      return state;
  }
};

export default pairCoin;
