import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Pagination,
  Form,
  Dropdown,
  Icon,
  Grid,
  GridColumn,
  Tab,
} from "semantic-ui-react";
import { toast } from "../../components/Toast/Toast";
import CustomHeader from "../../components/Headers/CustomHeader";
import Header from "components/Headers/Header";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router";
import { listDateFormat } from "../../utils/utils";
import "../AccountSetting/AccountSetting.scss";
import {
  API_HOST,
  BSC_TXN_BSC,
  BTC_TXN_BTC,
  ETH_TXN_ETH,
  MATIC_TXN_MATIC,
} from "../../constants/index";
import {
  getTransactionList,
  getTransactionListCSV,
  sendfailureMessage,
  getTransactionDetails,
} from "../../Redux/actons/transaction.action";
import { Link } from "react-router-dom";
import { userDetail } from "Redux/actons/userList.action";
import UserDetail from "../UserManagement/User/UserDetail";
import SetWithdrawDailyLimit from "./SetWithdrawDailyLimit";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Download from "../../views/CommonFiles/Download";
import SetGST from "./SetGST";
import TDSModal from "./TDSModal";
import DashboardResult from "views/ContactQueryManagement/SubjectManagement/DashboardResult";
import MetaMaskConnection from "components/WalletConnection/MetaMaskConnection";
import TronConnection from "components/WalletConnection/TronConnection";
import SetThresholdWalletValue from "./SetThresholdWalletValue";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
const panes = [
  { menuItem: "Tab 1", pane: "Tab 1 Content" },
  { menuItem: "Tab 2", pane: "Tab 2 Content" },
];
const feeFilter = [
  { key: "e", text: "None", value: "none" },
  { key: "i", text: "Internal", value: "internal" },
  { key: "o", text: "Outgoing", value: "outgoing" },
  // { key: "w", text: "Withdraw", value: "withdraw" },
  // { key: "d", text: "Deposit", value: "deposit" },
  // { key: "l", text: "Rejected", value: "rejected" },
];
const renderLabel = (label) => ({
  color: "blue",
  content: `${label.text}`,
  icon: "check",
});
const statusFilter = [
  { key: "k", text: "None", value: "none" },
  { key: "p", text: "Rejected", value: "rejected" },
  { key: "c", text: "Complete", value: "complete" },
  { key: "n", text: "Pending", value: "pending" },
  // { key: "s", text: "Signed", value: "signed" },
  { key: "f", text: "Failed", value: "failed" },
  { key: "i", text: "In-progress", value: "in-progress" },
  // { key: "a", text: "Approved", value: "approved" },
  // { key: "d", text: "Cancelled", value: "cancelled" },
];

const typeFilter = [
  { key: "w", text: "Withdraw", value: "withdraw" },
  { key: "d", text: "Deposit", value: "deposit" },
];

export class TransactionFeeManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentUserId: "",
      open: false,
      openModel: false,
      trnx_type: [this.props.statusType],
      status: [],
      coin_type: "",
      searchKey: "",
      perPage: "10",
      page: "1",
      from_date: "",
      to_date: "",
      message: "",
      detailModal: false,
      order: "desc",
      sortBy: "",
      coin_family: "",
      tabValue: "withdraw",
      filterType: [],
      dailyWithdrawLimitModal: false,
      gstModal: false,
      tdsModal: false,
      to_address_copied: false,
      from_address_copied: false,
      tx_id_copied: false,
      downloadExcel: false,
      filterData: {},
      walletTxnLimitModal: false,
    };
  }
  toggle = () => this.setState({ open: !this.state.open });
  toggleDetails = () => this.setState({ openDetails: !this.state.openDetails });

  viewDetails = (transaction) => {
    let data = {
      withdraw_id: transaction.id,
      trnx_type: transaction.type,
    };

    this.props.getTransactionDetails(data);
    this.setState({ openDetails: true, coin_family: transaction.coin_family });
  };
  openModel = (data) => {
    this.setState({ commentUserId: data, open: true });
  };
  setMessage = (e) => {
    this.setState({ message: e.target.value });
  };
  sendMesage = async () => {
    if (this.state.message != "") {
      let data = {
        user_id: this.state.commentUserId.user_id,
        message: this.state.message,
        transactionId: this.state.commentUserId.id,
        type: this.state.commentUserId.type,
      };
      await this.props.sendfailureMessage(data);

      this.setState({ open: false, message: "" });
    } else {
      toast.error("Please enter message");
    }
  };

  componentDidMount = () => {
    this.getTransList();
  };

  getTransList = () => {
    let data = {
      trnx_type: this.state.trnx_type,
      status: this.state.status,
      coin_type: this.state.coin_type,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      searchKey: this.state.searchKey,

      perPage: this.state.perPage,
      page: this.state.page,

      order: this.state.order,
      filterType: this.state.filterType,
    };

    this.props.getTransactionList(data);
  };

  //page change
  changeTransPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getTransList();
    });
  };

  sortBy = (e, data) => {
    this.setState({ order: data.value }, () => {
      this.getTransList();
    });
  };
  filterBy = (e, data) => {
    let filterTypeArr = this.state.filterType;
    let trnxTypeArr = this.state.trnx_type;
    if (data.value.length > 0) {
      if (
        data.value.indexOf("internal") > -1 &&
        filterTypeArr.indexOf("internal") == -1
      ) {
        filterTypeArr.push("internal");
      }
      if (data.value.indexOf("internal") == -1) {
        filterTypeArr = filterTypeArr.filter(function (item) {
          return item !== "internal";
        });
      }
      if (
        data.value.indexOf("outgoing") > -1 &&
        filterTypeArr.indexOf("outgoing") == -1
      ) {
        filterTypeArr.push("outgoing");
      }
      if (data.value.indexOf("outgoing") == -1) {
        filterTypeArr = filterTypeArr.filter(function (item) {
          return item !== "outgoing";
        });
      }

      if (
        data.value.indexOf("withdraw") > -1 &&
        trnxTypeArr.indexOf("withdraw") == -1
      ) {
        // trnxTypeArr.push("withdraw");
      }
      if (data.value.indexOf("withdraw") == -1) {
        // trnxTypeArr = trnxTypeArr.filter(function (item) {
        //   return item !== "withdraw";
        // });
      }

      if (
        data.value.indexOf("deposit") > -1 &&
        trnxTypeArr.indexOf("deposit") == -1
      ) {
        // trnxTypeArr.push("deposit");
      }
      if (data.value.indexOf("deposit") == -1) {
        // trnxTypeArr = trnxTypeArr.filter(function (item) {
        //   return item !== "deposit";
        // });
      }
      this.setState(
        { filterType: filterTypeArr, trnx_type: trnxTypeArr },
        () => {
          this.getTransList();
        }
      );
    } else {
      this.setState({ filterType: [] }, () => {
        this.getTransList();
      });
    }
  };

  filterByStatus = (e, data) => {
    this.setState({ status: [] });
    if (data.value !== "none") {
      // let status = [];

      //status.push(data.value);
      this.setState({ status: data.value }, () => {
        this.getTransList();
      });
    } else {
      this.setState({ status: [] }, () => {
        this.getTransList();
      });
    }
  };

  downLoadUserList = () => {
    let data = {
      trnx_type: this.state.trnx_type,
      status: this.state.status,
      coin_type: this.state.coin_type,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      searchKey: this.state.searchKey,

      perPage: this.props.totalTransRecords,
      page: this.state.page,

      order: this.state.order,
      filterType: this.state.filterType,
    };
    // this.setState({
    //   downloadExcel: !this.state.downloadExcel,
    //   download_url: `${API_HOST}api/v1/admin/transaction/download/transactionlist`,
    //   filterData: filterData,
    // });
    this.props.getTransactionListCSV(data);
    // params = params ? "?" + new URLSearchParams(params).toString() : "";
    // if (this.state.filterType === "withdraw") {
    //   window.location.href = `${API_HOST}api/v1/admin/transaction/download/transactionlist${params}`;
    // } else if (this.state.filterType === "deposit") {
    //   window.location.href = `${API_HOST}api/v1/admin/transaction/download/transactionlist${params}`;
    // } else {
    //   window.location.href = `${API_HOST}api/v1/admin/transaction/download/transactionlist${params}`;
    // }
  };
  closeDownloadExcel = () => {
    this.setState({
      downloadExcel: !this.state.downloadExcel,
      download_url: "",
      filterData: {},
    });
  };
  // filterTypeBy = (e, data) => {

  //   if (data.value !== "none") {
  //     this.setState({ trnx_type: data.value }, () => {
  //       this.getTransList();
  //     });
  //   } else {
  //     this.setState({ trnx_type: "" }, () => {
  //       this.getTransList();
  //     });
  //   }
  // };
  redirectToHashSite = (coin_family, hash) => {
    if (hash != "" || hash != undefined) {
      if (coin_family == 1) {
        window.open(`${ETH_TXN_ETH}/${hash}`, "_blank");
      } else if (coin_family == 2) {
        window.open(`${BTC_TXN_BTC}/${hash}`, "_blank");
      } else if (coin_family == 3) {
        window.open(`${BSC_TXN_BSC}/${hash}`, "_blank");
      } else if (coin_family == 5) {
        window.open(`${MATIC_TXN_MATIC}/${hash}`, "_blank");
      }
    }
  };
  //search
  onSearchFee = () => {
    // this.setState({  page: 1 }, () => {
    this.getTransList();
    // });
  };
  onTabeFilters = (data) => {
    // this.setState({  page: 1 }, () => {
    let trnxTypeArr = this.state.trnx_type;
    if (data == "withdraw") {
      if (trnxTypeArr.length > 0) {
        trnxTypeArr.pop();
      }
      trnxTypeArr.push("withdraw");
    }
    if (data == "deposit") {
      if (trnxTypeArr.length > 0) {
        trnxTypeArr.pop();
      }
      trnxTypeArr.push("deposit");
    }
    this.setState({ tabValue: data, page: 1 });
    this.getTransList();
    // });
  };

  detailModalToggle = (userData) => {
    this.props.userDetail({ user_id: userData });
    this.setState({ detailModal: !this.state.detailModal });
  };
  clostDetailModal = () => {
    this.setState({ detailModal: !this.state.detailModal });
  };

  openDailyWithDrawLimitModal = () => {
    this.setState({
      dailyWithdrawLimitModal: !this.state.dailyWithdrawLimitModal,
    });
  };
  openWalletThresholdModal = () => {
    this.setState({
      walletTxnLimitModal: !this.state.walletTxnLimitModal,
    });
  };

  openGstModal = () => {
    this.setState({ gstModal: !this.state.gstModal });
  };

  openTdsModal = () => {
    this.setState({ tdsModal: !this.state.tdsModal });
  };

  render() {
    let { handleSubmit, txnDetails, statusType } = this.props;
    return (
      <div>
        {/* <Header headerTitle="transaction management" /> */}
        <div>
          <Container>
            {/* <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Button
                outline
                color={
                  this.state.tabValue == "withdraw" ? "success" : "primary"
                }
                onClick={(e) => {
                  this.onTabeFilters("withdraw");
                  // this.setState({ tabValue: "withdraw", page: 1 });
                }}

                // style={{ border: "1px solid black" }}
              >
                Withdraw Transaction{" "}
                {this.state.tabValue == "withdraw" ? (
                  <Icon.Group size="large">
                    <Icon name="checkmark" />
                  </Icon.Group>
                ) : (
                  " "
                )}
              </Button>
              <Button
                outline
                color={this.state.tabValue == "deposit" ? "success" : "primary"}
                onClick={(e) => {
                  this.onTabeFilters("deposit");
                  // this.setState({ tabValue: "deposit", page: 1 });
                }}
              >
                Deposit Transaction{" "}
                {this.state.tabValue == "deposit" ? (
                  <Icon.Group size="large">
                    <Icon name="checkmark" />
                  </Icon.Group>
                ) : (
                  " "
                )}
              </Button>
            </div> */}
            {/* Table */}
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Card className="shadow">
                <Grid.Row
                  columns={2}
                  style={{
                    display: "flex",
                    width: "100%",
                    //   flexWrap: "wrap",
                    // justifyContent: "space-between",
                    marginRight: 0,
                    marginLeft: 0,
                  }}
                  // style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  {statusType == "deposit" ? (
                    <>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Pending Deposit"}
                          labResult={
                            this.props?.queryListRecords?.dp_pendingList
                              ? this.props?.queryListRecords?.dp_pendingList
                              : "0"
                          }
                        />
                      </GridColumn>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Inprogress Deposit"}
                          labResult={
                            this.props?.queryListRecords?.dp_inprogress
                              ? this.props?.queryListRecords?.dp_inprogress
                              : "0"
                          }
                        />
                      </GridColumn>

                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Failed Deposit"}
                          labResult={
                            this.props?.queryListRecords?.dp_failedList
                              ? this.props?.queryListRecords?.dp_failedList
                              : "0"
                          }
                        />
                      </GridColumn>

                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Confirm Deposit"}
                          labResult={
                            this.props?.queryListRecords?.dp_confirmList
                              ? this.props?.queryListRecords?.dp_confirmList
                              : "0"
                          }
                        />
                      </GridColumn>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Rejected Deposit"}
                          labResult={
                            this.props?.queryListRecords?.dp_rejected
                              ? this.props?.queryListRecords?.dp_rejected
                              : "0"
                          }
                        />
                      </GridColumn>
                    </>
                  ) : (
                    <>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Pending Withdraw"}
                          labResult={
                            this.props?.queryListRecords?.wd_pendingList
                              ? this.props?.queryListRecords?.wd_pendingList
                              : "0"
                          }
                        />
                      </GridColumn>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Inprogress Withdraw"}
                          labResult={
                            this.props?.queryListRecords?.wd_inprogress
                              ? this.props?.queryListRecords?.wd_inprogress
                              : "0"
                          }
                        />
                      </GridColumn>

                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Failed Withdraw"}
                          labResult={
                            this.props?.queryListRecords?.wd_failedList
                              ? this.props?.queryListRecords?.wd_failedList
                              : "0"
                          }
                        />
                      </GridColumn>

                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Confirm Withdraw"}
                          labResult={
                            this.props?.queryListRecords?.wd_confirmedList
                              ? this.props?.queryListRecords?.wd_confirmedList
                              : "0"
                          }
                        />
                      </GridColumn>
                      <GridColumn className="maxWidth">
                        <DashboardResult
                          heading={"Rejected Withdraw"}
                          labResult={
                            this.props?.queryListRecords?.wd_rejectedList
                              ? this.props?.queryListRecords?.wd_rejectedList
                              : "0"
                          }
                        />
                      </GridColumn>
                    </>
                  )}
                </Grid.Row>
              </Card>
            </div>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">
                        {statusType == "deposit" ? "Deposit" : "Withdraw "}
                        Transaction List
                      </h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchFee();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchFee()}
                      >
                        Search
                      </Button>

                      <Dropdown
                        className="selectUserStyle ml-2"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle mx-2"
                        options={feeFilter}
                        placeholder="Transaction and Type"
                        selection
                        multiple
                        closeOnChange={true}
                        renderLabel={renderLabel}
                        // value={this.state.filter}
                        onChange={(e, data) => this.filterBy(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle"
                        options={statusFilter}
                        placeholder="Filter by Status"
                        selection
                        multiple
                        closeOnChange={true}
                        renderLabel={renderLabel}
                        // value={this.state.filter}
                        onChange={(e, data) => this.filterByStatus(e, data)}
                      />

                      {/* <Dropdown
                        className="selectUserStyle"
                        options={typeFilter}
                        placeholder="Filter by Type"
                        selection
                        // value={this.state.filter}
                        onChange={(e, data) => this.filterTypeBy(e, data)}
                      /> */}

                      <Link to="coin-setting">
                        <Button color="success btnMamagePermission">
                          Set Coin Limit/Fee
                        </Button>
                      </Link>
                    </div>
                    <div
                      className="selectSearchOption"
                      style={{ marginTop: 20 }}
                    >
                      <Link to="withdrawal">
                        <Button color="success btnMamagePermission">
                          Withdrawal Requests
                        </Button>
                      </Link>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.downLoadUserList()}
                      >
                        Download TransactionFee list
                      </Button>
                      {statusType !== "deposit" && (
                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.openDailyWithDrawLimitModal()}
                        >
                          Set Daily Withdraw Limit
                        </Button>
                      )}
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openWalletThresholdModal()}
                      >
                        Set Wallet Threshold Value
                      </Button>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openGstModal()}
                      >
                        Set GST
                      </Button>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openTdsModal()}
                      >
                        Set TDS
                      </Button>
                    </div>

                    {/* <div className="selectSearchOption">
                    
                      <div>
                        <MetaMaskConnection />
                      </div>
                      <div style={{ marginLeft: "15px" }}>
                        <TronConnection />
                      </div>
                    </div> */}
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">ID (Email)</th>
                        {/* <th scope="col">Blockchain Transaction on</th> */}

                        <th scope="col">type</th>
                        <th scope="col">amount</th>
                        <th scope="col">Asset</th>
                        <th scope="col">status</th>

                        <th scope="col">blockchain_status</th>
                        <th scope="col">TX Time</th>
                        {/* <th scope="col">IP Address</th> */}
                        <th scope="col">Actions</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.getTransFeeList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          {/* <td>{usersList.email}</td> */}
                          <td>
                            {/* <Link onClick={()=> this.detailModalToggle(usersList)}>
                              {usersList.email ? usersList.email : "None"}
                            </Link> */}
                            {usersList.email ? usersList.email : "None"}
                          </td>
                          {/* <td>
                            {usersList.req_type == "APP"
                              ? "Internal"
                              : "Completed"}
                          </td> */}

                          <td>{usersList.type.toUpperCase()}</td>
                          <td>{usersList.amount}</td>
                          <td>{usersList.coin_symbol.toUpperCase()}</td>
                          <td>{usersList.status.toUpperCase()}</td>

                          <td>
                            {usersList.type == "deposit"
                              ? usersList.blockchain_status
                              : usersList.req_type == "APP"
                              ? "N/A"
                              : usersList.req_type == "EXNG" &&
                                usersList.tx_id == null
                              ? "PENDING"
                              : usersList.blockchain_status}
                          </td>
                          <td>{listDateFormat(usersList.created_at)}</td>
                          {/* <td>{(usersList.ip_address) ? usersList.ip_address: "N/A"}</td> */}
                          {usersList.status !== "failed" && (
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color="success"
                                  onClick={() => this.openModel(usersList)}
                                >
                                  Add Comment
                                </Button>
                              </span>
                            </td>
                          )}
                          <td>
                            <span className="mb-0 text-sm">
                              <Button
                                color="success"
                                onClick={() => this.viewDetails(usersList)}
                              >
                                View Details
                              </Button>
                            </span>
                          </td>
                        </tr>
                      ))}
                      {this.props.getTransFeeList.length == 0 && (
                        <td>No record found</td>
                      )}
                    </tbody>
                  </Table>
                  {this.props.getTransFeeList.length != 0 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) =>
                            this.changeTransPage(e, data)
                          }
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalTransRecords / this.state.perPage
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        {/* //comment modal */}

        <Modal isOpen={this.state.open} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Send Message</ModalHeader>
          <ModalBody>
            <Form>
              <Form.TextArea
                label="Message"
                placeholder="Message..."
                onChange={(e) => this.setMessage(e)}
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ open: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.sendMesage()}>
              Send
            </Button>
          </ModalFooter>
        </Modal>

        {/* detail modal */}

        {txnDetails && (
          <Modal
            isOpen={this.state.openDetails}
            toggle={this.toggleDetails}
            style={{ maxWith: "600" }}
          >
            <ModalHeader toggle={this.toggleDetails}>
              Transaction Details
            </ModalHeader>
            <ModalBody>
              <div className="userNameList">
                <div className="itemStyle">
                  <label style={{ color: "black" }}> From Address:</label>
                  <span style={{ color: "black" }}>
                    {txnDetails.from_adrs
                      ? txnDetails.from_adrs.slice(0, 6) +
                        "..." +
                        txnDetails.from_adrs.slice(-4)
                      : "None"}
                  </span>
                  {txnDetails.from_adrs ? (
                    this.state.from_address_copied ? (
                      <div>
                        <Icon
                          enabled
                          name="check circle outline"
                          size="large"
                        />{" "}
                        <span className="copiedText" style={{ color: "green" }}>
                          Copied.
                        </span>
                      </div>
                    ) : (
                      <CopyToClipboard
                        text={txnDetails.from_adrs}
                        onCopy={() => {
                          this.setState({ from_address_copied: true });
                          setTimeout(() => {
                            this.setState({ from_address_copied: false });
                          }, 1000);
                        }}
                      >
                        <Icon
                          enabled
                          name="copy outline"
                          size="large"
                          title="Copy From Address to clipboard"
                        />
                      </CopyToClipboard>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="itemStyle">
                  <label style={{ color: "black" }}>To Address:</label>
                  <span style={{ color: "black" }}>
                    {txnDetails.to_adrs ? txnDetails.to_adrs : "None"}{" "}
                  </span>
                  {txnDetails.to_adrs ? (
                    this.state.to_address_copied ? (
                      <div>
                        <Icon
                          enabled
                          name="check circle outline"
                          size="large"
                        />{" "}
                        <span className="copiedText" style={{ color: "green" }}>
                          Copied.
                        </span>
                      </div>
                    ) : (
                      <CopyToClipboard
                        text={txnDetails.to_adrs}
                        onCopy={() => {
                          this.setState({ to_address_copied: true });
                          setTimeout(() => {
                            this.setState({ to_address_copied: false });
                          }, 1000);
                        }}
                      >
                        <Icon
                          enabled
                          name="copy outline"
                          size="large"
                          title="Copy To Address to clipboard"
                        />
                      </CopyToClipboard>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="itemStyle">
                  <label style={{ color: "black" }}>Amount:</label>
                  <span style={{ color: "black" }}>
                    {txnDetails.amount ? txnDetails.amount : "None"}{" "}
                  </span>
                </div>
                {/* <div className="itemStyle">
                  <label style={{ color: "black" }}>Gas Limit:</label>
                  <span style={{ color: "black" }}>
                    {txnDetails.gas_limit}{" "}
                  </span>
                </div>
                <div className="itemStyle">
                  <label style={{ color: "black" }}>Gas Price:</label>
                  <span style={{ color: "black" }}>
                    {txnDetails.gas_price}{" "}
                  </span>
                </div> */}
                <div className="itemStyle">
                  <label style={{ color: "black" }}>Tx Hash:</label>
                  <span
                    style={{ color: "black", cursor: "pointer" }}
                    onClick={() =>
                      this.redirectToHashSite(
                        this.state.coin_family,
                        txnDetails.tx_id
                      )
                    }
                  >
                    {txnDetails.tx_id ? txnDetails.tx_id : "None"}{" "}
                  </span>
                  {txnDetails.tx_id ? (
                    this.state.tx_id_copied ? (
                      <div>
                        <Icon
                          enabled
                          name="check circle outline"
                          size="large"
                        />{" "}
                        <span className="copiedText" style={{ color: "green" }}>
                          Copied.
                        </span>
                      </div>
                    ) : (
                      <CopyToClipboard
                        text={txnDetails.tx_id}
                        onCopy={() => {
                          this.setState({ tx_id_copied: true });
                          setTimeout(() => {
                            this.setState({ tx_id_copied: false });
                          }, 1000);
                        }}
                      >
                        <Icon
                          enabled
                          name="copy outline"
                          size="large"
                          title="Copy Transaction Hash to clipboard"
                        />
                      </CopyToClipboard>
                    )
                  ) : (
                    ""
                  )}
                </div>
                {txnDetails.admin_comment !== "" && (
                  <div className="itemStyle">
                    <label style={{ color: "black" }}>Admin Comment:</label>
                    <span style={{ color: "black" }}>
                      {txnDetails.admin_comment
                        ? txnDetails.admin_comment
                        : "None"}{" "}
                    </span>
                  </div>
                )}
              </div>
            </ModalBody>
          </Modal>
        )}

        <UserDetail
          clostDetailModal={this.clostDetailModal}
          detailModal={this.state.detailModal}
        />

        {this.state.dailyWithdrawLimitModal && (
          <SetWithdrawDailyLimit
            openDailyWithDrawLimitModal={this.openDailyWithDrawLimitModal}
            dailyWithdrawLimitModal={this.state.dailyWithdrawLimitModal}
          />
        )}
        {this.state.walletTxnLimitModal && (
          <SetThresholdWalletValue
            openWalletThresholdModal={this.openWalletThresholdModal}
            walletTxnLimitModal={this.state.walletTxnLimitModal}
          />
        )}
        {this.state.gstModal && (
          <SetGST
            openGstModal={this.openGstModal}
            gstModal={this.state.gstModal}
          />
        )}
        {this.state.tdsModal && (
          <TDSModal
            openTdsModal={this.openTdsModal}
            tdsModal={this.state.tdsModal}
          />
        )}
        <Download
          filterData={this.state.filterData}
          closeDownloadExcel={this.closeDownloadExcel}
          downloadExcel={this.state.downloadExcel}
          download_url={this.state.download_url}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    totalTransRecords: state.transaction.totalTransRecords,
    queryListRecords: state.transaction.queryListRecords,
    getTransFeeList: state.transaction.getTransFeeList,
    txnDetails: state.transaction.txnDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionList: (filterType, data) =>
      dispatch(getTransactionList(filterType, data)),
    getTransactionListCSV: (data) => dispatch(getTransactionListCSV(data)),
    sendfailureMessage: (data) => dispatch(sendfailureMessage(data)),
    getTransactionDetails: (data) => dispatch(getTransactionDetails(data)),
    userDetail: (id) => dispatch(userDetail(id)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "ApprovedKyc" })(TransactionFeeManagement))
);
