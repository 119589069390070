import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const getGuideScreenList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/guide_screen/list`,
    {},
    options,
    // params
  );
};

// const getAnnouncementDetail = (id, options) => {
//   return fetch(
//     "get",
//     `${API_HOST}api/v1/admin/announcement/detail/${id.user_id.user_id}`,
//     {},
//     options
//   );
// };
// const addNewScreen= (data, options) => {
//   return fetch(
//     "post",
//     `${API_HOST}api/v1/admin/guide_screen/add_new`,
//     data,
//     options
//   );
// };

const enableDisableUserGuide = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/guide_screen/update_status`,
    data,
    options
  );
};




// const deleteAnnouncement = (id, options) => {
//   return fetch(
//     "delete",
//     `${API_HOST}api/v1/admin/announcement/delete/${id}`,
//     {},
//     options
//   );
// };
const uploadimageUserGuide = (data, id, options) => {
  console.log("put", data);
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/guide_screen/add_new`,
    data,
    options
  );
};

const addUserGuideImage = (data, options) => {
  
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/guide_screen/add_new`,
    data,
    options
  );
};




const changeGuideStatus = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/guide_screen/update_status`,
    data,
    options
  );
};

// const sendAnnouncement = (data, id, options) => {
//   return fetch(
//     "put",
//     `${API_HOST}api/v1/admin/announcement/sendNotification/${id}`,
//     data,
//     options
//   );
// };

export const UserGuideServices = {
  getGuideScreenList,
  enableDisableUserGuide,
  uploadimageUserGuide,
  addUserGuideImage,
  changeGuideStatus,
  // deleteAnnouncement,
  // updateAnnouncement,
  // sendAnnouncement
};
