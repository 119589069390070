export const getDate = (theDate, days) => {
  let newDate = new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  let month = newDate.getMonth() + 1;
  let date =
    (newDate.getDate() < 10 ? "0" : "") +
    newDate.getDate() +
    "-" +
    (month < 10 ? "0" : "") +
    month +
    "-" +
    newDate.getFullYear();
  return date;
};
export function copyTextToClipboard(text) {
  var txtArea = document.createElement("textarea");
  txtArea.id = "txt";
  txtArea.style.position = "fixed";
  txtArea.style.top = "0";
  txtArea.style.left = "0";
  txtArea.style.opacity = "0";
  txtArea.value = text;
  document.body.appendChild(txtArea);
  txtArea.select();
  try {
    var successful = document.execCommand("copy");
    if (successful) {
      return true;
    }
  } catch (err) {
  } finally {
    document.body.removeChild(txtArea);
  }
  return false;
}

export function setType(string) {
  return string != null ? string.replace("_", "  ").replace("_", "  ").toUpperCase() : "";
}

export const listDateFormat = (date) => {
  // MM/DD/YYYY 00:00 AM/PM
  let newDate = new Date(date);
  let parsedDate =
    (newDate.getMonth() > 8
      ? newDate.getMonth() + 1
      : "0" + (newDate.getMonth() + 1)) +
    "/" +
    (newDate.getDate() > 9 ? newDate.getDate() : "0" + newDate.getDate()) +
    "/" +
    newDate.getFullYear();

  var hours = newDate.getHours();
  var minutes = newDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return parsedDate + " " + strTime;
};

export function trimPair(pair, type) {
  if (type === 1) return pair.substring(0, pair.indexOf("-"));
  else return pair.substring(pair.indexOf("-") + 1);
}

export const listDateFormat_csv = (date) => {
  let newDAte = new Date(date);
  let parsedDate = newDAte.toLocaleDateString("default", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return parsedDate;
};

function parseDateFormat(date) {
  return (
    date.substr(date.lastIndexOf("-") + 1) +
    "-" +
    date[3] +
    date[4] +
    "-" +
    date[0] +
    date[1]
  );
}

export const extractDate = (date, type) => {
  if (type === 1) {
    let parsedDate = date.substring(0, date.indexOf(" - "));
    parsedDate = parseDateFormat(parsedDate);
    return parsedDate;
  } else {
    let parsedDate = date.substring(date.indexOf(" - ") + 3);
    if (parsedDate.length !== 0) {
      parsedDate = parseDateFormat(parsedDate);
      return parsedDate;
    } else return false;
  }
};
export function convertExponentialToDecimal(exponentialNumber) {
  const str = exponentialNumber.toString();
  if (str.indexOf("e") !== -1) {
    // const exponent = parseInt(str.split("-")[1], 18);
    const result = exponentialNumber.toFixed(15);
    return result;
  } else {
    return exponentialNumber;
  }
}
export function nl2br(str) {
  console.log("str >>> ", str);
  return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
  //return  str.replace(/(\r\n|\n\r|\r|\n)/g, '<br>');
  //return str.replace(/(?:.\r\n|\r|\n)/g, '<br/>');
}

export const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
