import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const AddSubject = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/contactUs/subject/add`,
    data,
    options
  );
};

const getSubjectList = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/contactUs/subject/list?`,
    data,
    options
  );
};

const getContactQueryList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/contactUs/list`,
    {},
    options,
    params
  );
};
const getContactQueryList1 = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/contactUs/view`,
    {},
    options,
    params
  );
};

const deleteSubject = (data, id, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/contactUs/subject/delete`,
    { id: `${id}` },
    options
  );
};

const changeStatus = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/contactUs/subject/update`,
    data,
    options
  );
};

const updateQueryTaralGoldReply = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/contactUs/reply_all`, //SET API HANDLER NEW HANDLER FOR  BROADCASTING ON TARALGOLD SUBSCRIPTION
    data,
    options
  );
};
const updateQuery = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/contactUs/updateStatus`,
    data,
    options
  );
};

export const SubjectServices = {
  getSubjectList,
  AddSubject,
  deleteSubject,
  changeStatus,
  getContactQueryList,
  getContactQueryList1,
  updateQuery,
  updateQueryTaralGoldReply,
};
