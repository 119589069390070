import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, label } from "semantic-ui-react";
import { Button } from "reactstrap";
import {
  getUserFund,
  setUserFund,
  getFundUsers,
  saveWalletId,
  lockUserFunds,
  unLockUserFunds,
} from "../../Redux/actons/fund.action";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { FormField } from "../../components/FormField";
import { SelectField } from "../../components/SelectField";
import { email, required } from "redux-form-validators";
import { toast } from "../../components/Toast/Toast";
import { reset } from "redux-form";
import { getCoinList } from "../../Redux/actons/user.action";
import { Pagination, Dropdown } from "semantic-ui-react";
const time_periods = process.env.REACT_APP_TENURE_PRIOD;

const options = [{ key: "m", text: "Lock", value: "lock" }];

export class AddFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet_rel_id: "",
      isUpdateTF: false,
      user_id: "",
      amountsToLock: "",
      comment: "",
      coin_id: this.props.coin_id.coin_id,
      lockUnlock: "lock",
      rows: [{ amount: 0, date: 0 }],
      totalPercentage: 0,
    };
  }
  componentDidMount = () => {
    console.log("Vik", this.props.coin_id);
    this.props.getFundUsers();
    this.props.getCoinList();
    // this.props.getDetailsCoin("e", this.props.coin_id);

    let queryParams = {
      user_id: this.props.userId,
      coin_id: this.props.coin_id.coin_id,
    };
    console.log(queryParams);
    this.props.getUserFund(queryParams);
  };
  componentWillReceiveProps = (nextProps) => {
    // console.log("nextProps----------nextProps", nextProps);
    if (this.props.walletId !== nextProps.walletId) {
      this.props.dispatch(
        change("addFundsForm", "amount", nextProps.walletId.balance)
      );
      // this.props.dispatch(
      this.setState({ amountsToLock: nextProps.walletId.balance });
      // );
      this.props.dispatch(
        change(
          "addFundsForm",
          "locked_amount",
          nextProps.walletId.balance_locked
        )
      );
    }
  };
  updateForm = (values) => {
    values["user_id"] = this.props.userId;
    // console.log(
    //   this.state.amountsToLock,
    //   "isEmptyTenure -------->> 71",
    //   this.state.rows
    // );
    if (Object.keys(this.props.walletId).length != 0) {
      values["wallet_rel_id"] = this.props.walletId.wallet_rel_id;
      if (this.props.islockFunds == true) {
        const params = {
          coin_id: this.state.coin_id,
          userId: this.props.userId,
          amount: values.amount,
        };

        if (this.state.lockUnlock == "lock") {
          params.comment = this.state.comment;
          params.vesting = this.state.rows;
          /* ************************************ ************************************ */

          // if (this.props.islockFunds === false) {
          //   // handleSubmit(this.updateForm);
          // } else {
          if (
            this.state.totalPercentage > values.amount ||
            this.state.totalPercentage < values.amount
          ) {
            alert(`Total vesting amount should be equal to ${values.amount}`);
            return;
          }
          let newData = this.state.rows.filter((el) => el.amount > 0);
          let isEmptyTenure = false;
          for (let index = 0; index < newData.length; index++) {
            const element = newData[index];
            if (element.date == 0) {
              isEmptyTenure = true;
              break;
            }
          }
          console.log("isEmptyTenure -------->> 71", params);
          if (!isEmptyTenure) {
            this.props.lockUserFunds(params, this.props.fdata);
            this.toggle();
            // this.setState({
            //   openFileProcessModal: true,
            // });
          } else {
            alert("Time should not be zero");
            return;
          }
          console.log(
            this.props.fdata,
            "isEmptyTenure -------->> 100",
            isEmptyTenure
          );
          /* ************************************ ************************************ */

          // this.props.lockUserFunds(params, this.props.fdata);
          this.toggle();
        } else {
          this.props.unLockUserFunds(params, this.props.fdata);
          this.toggle();
        }
      } else {
        this.props.setUserFund(values, this.props.fdata);
        this.toggle();
      }
    } else {
      toast.error("The coin fund of this user doesn't exist.");
    }
  };

  lockUnlockSelection = (e, data) => {
    this.setState({ lockUnlock: data.value });
  };

  getDetailsEmail = (e, data) => {
    this.setState({ user_id: data });
    let queryParams = {
      user_id: data,
      coin_id: this.state.coin_id,
    };

    if (this.state.coin_id !== "") {
      this.props.getUserFund(queryParams);
    }
  };
  getDetailsCoin = (e, data) => {
    this.setState({ coin_id: data });

    let queryParams = {
      user_id: this.props.userId,
      coin_id: data,
    };
    console.log(queryParams);
    this.props.getUserFund(queryParams);
  };
  toggle = () => {
    this.setState({ user_id: "", coin_id: "" });
    this.props.reset("addFundsForm");

    this.props.toggle();
  };

  addMore = (index) => {
    let data = [...this.state.rows];
    let newRow = {
      amount: 0,
      date: 0,
      // time_period_unit: time_periods,
    };
    data.push(newRow);

    const amount = data.reduce(
      (a, v) => parseFloat(a) + parseFloat(v.amount),
      0
    );
    this.setState({
      rows: data,
      totalPercentage: isNaN(parseFloat(amount)) ? 0 : amount,
    });
  };

  deleteRow = (index) => {
    let data = [...this.state.rows];
    let iPersent = this.state.rows[index].amount;
    let remaining = this.state.totalPercentage - iPersent;
    this.setState({ totalPercentage: remaining });
    data.splice(index, 1);
    const amount = data.reduce(
      (a, v) => parseFloat(a) + parseFloat(v.amount),
      0
    );
    this.setState({
      rows: data,
      totalPercentage: isNaN(parseFloat(amount)) ? 0 : amount,
    });
  };

  render() {
    let { handleSubmit } = this.props;

    if (this.state.coin_id && !this.state.isUpdateTF) {
      this.getDetailsCoin(this.state.coin_id, this.state.coin_id);
      this.setState({
        isUpdateTF: true,
      });
    }
    let selectedCoinName = this.props.coinsList.find(
      (data) => data.coin_id == this.state.coin_id
    )?.value;
    // console.log(
    //   this.props.coin_id,
    //   this.props.coinsList,
    //   "this.props.coin_id",
    //   selectedCoinName
    // );
    return (
      <Modal
        isOpen={this.props.isAddFunds}
        toggle={this.props.toggle}
        className={[this.props.className, "modalCustom"]}
      >
        <ModalHeader toggle={this.toggle}>
          {this.props.islockFunds == false ? (
            <h1>Add Funds</h1>
          ) : (
            <h1>Lock Funds</h1>
          )}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={handleSubmit(this.updateForm)}
            // onSubmit={() => {
            //   if (this.props.islockFunds) {
            //     if (
            //       this.state.totalPercentage > 100 ||
            //       this.state.totalPercentage < 100
            //     ) {
            //       alert("Amount should be equal to 100");
            //       return;
            //     }
            //     let newData = this.state.rows.filter((el) => el.amount > 0);
            //     let isEmptyTenure = false;
            //     for (let index = 0; index < newData.length; index++) {
            //       const element = newData[index];
            //       if (element.date == 0) {
            //         isEmptyTenure = true;
            //         break;
            //       }
            //     }
            //     if (!isEmptyTenure) {
            //       console.log("isEmptyTenure -------->> 190", isEmptyTenure);
            //       // this.setState({
            //       //   openFileProcessModal: true,
            //       // });
            //     } else {
            //       alert("Time should not be zero");
            //     }
            //   } else {
            //     handleSubmit(this.updateForm);
            //   }
            //   // }}
            // }}
          >
            <Form.Group>
              <Form.Field>
                <label style={{ color: "#fff" }}>Select Coin </label>
                <Field
                  name="coin_id"
                  search={false}
                  selectOnBlur={false}
                  // validate={[required()]}
                  component={SelectField}
                  defaultValue={selectedCoinName}
                  children={this.props.coinsList}
                  placeholder="Select "
                  value={selectedCoinName}
                  // value={Number(this.state.coin_id)}
                  onChange={(e, data) => this.getDetailsCoin(e, data)}
                  disabled
                ></Field>
              </Form.Field>
            </Form.Group>
            {Object.keys(this.props.walletId).length != 0 && (
              <div>
                <Form.Group>
                  <Form.Field>
                    <label style={{ color: "#fff" }}>Amount</label>
                    <Field
                      type="number"
                      name="amount"
                      validate={[required()]}
                      component={FormField}
                      placeholder="Amount"
                      onChange={(e) =>
                        // this.setState({  amountsToLock , e.targt
                        this.setState({ amountsToLock: e.target.value })
                      }
                    ></Field>
                  </Form.Field>
                </Form.Group>
                {this.props.islockFunds == false ? (
                  <div>
                    <Form.Group>
                      <Form.Field>
                        <label style={{ color: "#fff" }}>Comment</label>
                        <Field
                          type="textArea"
                          name="comment"
                          // validate={[required()]}
                          component={FormField}
                          placeholder="Comment"
                          // onChange={(event) => {
                          //   this.setState({ comment: event.target.value });
                          // }}
                        ></Field>
                      </Form.Field>
                    </Form.Group>
                  </div>
                ) : (
                  <div>
                    <Form.Group>
                      <Form.Field>
                        <label style={{ color: "#fff" }}>Amount Locked</label>
                        <Field
                          type="number"
                          name="locked_amount"
                          validate={[]}
                          component={FormField}
                          disabled={true}
                          placeholder="Amount"
                        ></Field>
                      </Form.Field>
                    </Form.Group>
                    {/* <Form.Group>
                      <Form.Field>
                        <label style={{ color: "#fff" }}>
                          Lock
                        </label>
                        <Dropdown
                          name="dropdown"
                          options={options}
                          selection
                          value={this.state.lockUnlock}
                          onChange={(e, data) =>
                            this.lockUnlockSelection(e, data)
                          }
                        />
                      </Form.Field>
                    </Form.Group> */}

                    {/* ************************************************************** */}
                    <div>
                      <Form.Group>
                        <Form.Field>
                          <label style={{ color: "#fff" }}>Comment</label>
                          <Field
                            type="textArea"
                            name="comment"
                            // validate={[required()]}
                            onChange={(event) => {
                              this.setState({ comment: event.target.value });
                            }}
                            component={FormField}
                            placeholder="Comment"
                          ></Field>
                        </Form.Field>
                      </Form.Group>
                    </div>
                    <div style={{ marginBottom: "15px" }}>
                      <ModalHeader>
                        <h1>Add Vesting Rules</h1>
                      </ModalHeader>{" "}
                    </div>
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <span style={{ color: "#fff" }}>
                                Amount(Actual Value)
                              </span>
                            </th>
                            <th>
                              <span style={{ color: "#fff" }}>Time</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.rows.map((r, i) => (
                            <tr key={i}>
                              <td style={{ padding: "10px" }}>
                                <input
                                  name="amount"
                                  value={r?.amount}
                                  // onBlur={() => {
                                  //   let data = this.state.rows;
                                  //   let newData = data.filter(
                                  //     (el) => el.amount > 0
                                  //   );
                                  //   if (newData.length == 0) {
                                  //     let newRow = {
                                  //       amount: 0,
                                  //       date: 0,
                                  //       time_period_unit: time_periods,
                                  //     };
                                  //     newData.push(newRow);
                                  //   }
                                  //   this.setState({
                                  //     rows: newData,
                                  //   });
                                  // }}
                                  onChange={async (event) => {
                                    event.preventDefault();
                                    let val = +event.target.value;
                                    let data = this.state.rows;
                                    data[i].amount = val;

                                    let amount = 0;

                                    for await (const element of data) {
                                      amount =
                                        amount + parseInt(element.amount);
                                    }

                                    if (amount <= this.state.amountsToLock) {
                                      this.setState({
                                        rows: data,
                                        totalPercentage: amount,
                                      });
                                    } else {
                                      data[i].amount = isNaN(
                                        parseFloat(
                                          data[i].amount.toString().slice(0, -1)
                                        )
                                      )
                                        ? null
                                        : parseFloat(
                                            data[i].amount
                                              .toString()
                                              .slice(0, -1)
                                          );

                                      this.setState({
                                        rows: data,
                                      });
                                    }
                                  }}
                                ></input>
                              </td>
                              <td style={{ padding: "10px" }}>
                                <input
                                  name="tenure"
                                  type="date"
                                  disablePast
                                  value={r?.date}
                                  onChange={(event) => {
                                    let data = [...this.state.rows];
                                    data[i].date = event.target.value;
                                    this.setState({ rows: data });
                                  }}
                                ></input>
                              </td>
                              <td style={{ padding: "10px" }}>
                                {i === this.state.rows.length - 1 ? (
                                  <Button
                                    disabled={
                                      this.state.totalPercentage >=
                                      this.state.amountsToLock
                                        ? true
                                        : false
                                    }
                                    color={"success btnMamagePermission"}
                                    onClick={() => {
                                      this.state.rows[i].amount == 0
                                        ? alert("Please add amount")
                                        : this.addMore(i - 1);
                                    }}
                                  >
                                    Add
                                  </Button>
                                ) : (
                                  <Button
                                    color={"danger"}
                                    onClick={() => this.deleteRow(i)}
                                  >
                                    Delete
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>
                              <td>
                                <p style={{ color: "#fff" }}>
                                  Total vesting amount:{" "}
                                  {+this.state.totalPercentage}
                                </p>
                              </td>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    {/* ************************************************************** */}
                  </div>
                )}
              </div>
            )}

            <Form.Field className="addBtn">
              <Button type="submit" color="success">
                Submit
              </Button>
            </Form.Field>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coinsList: state.user.coinsList,
    getFundsList: state.funds.getFundsList,
    allFundUsers: state.funds.allFundUsers,
    walletId: state.funds.walletId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserFund: (data) => dispatch(getUserFund(data)),
    setUserFund: (data, fdata) => dispatch(setUserFund(data, fdata)),
    getFundUsers: (data) => dispatch(getFundUsers(data)),
    getCoinList: () => dispatch(getCoinList()),
    reset: (data) => dispatch(reset(data)),
    lockUserFunds: (data, fdata) => dispatch(lockUserFunds(data, fdata)),
    unLockUserFunds: (data, fdata) => dispatch(unLockUserFunds(data, fdata)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "addFundsForm" })(AddFunds));
