import { fetch } from "./Fetch";
import { API_HOST, API_LOCAL_HOST } from "../constants/index";
import axios from "axios";
import { startLoading, stopLoading } from "../Redux/actons/loading.action";
// import { startLoading, stopLoading } from "./loading.action";

const addInterest = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/coinInterest/add-coin-interest`,
    data,
    options
  );
};

const getInterestList = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/coinInterest/list`,
    data,
    options
  );
};
const userDailyInterestList = async (data, options, id) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API_HOST}api/v1/admin/stak/listInterest?id=${id}`,
      // url: `${API_HOST}api/v1/admin/listCoins`,
      headers: {
        Authorization: options.jwt,
      },
      // params: data,
    });
    return res;
    // }
  } catch (error) {
    return error.response.data;
    // stopLoading();
    // return (dispatch, getState) => {
    //   dispatch();
    //   dispatch(stopLoading());
    // };
  }
};
const userUnstaked = async (data, options, data1) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${API_HOST}api/v1/admin/stak/unStakeToken`,
      // url: `${API_HOST}api/v1/admin/listCoins`,
      headers: {
        Authorization: options.jwt,
      },
      data: {
        coin_id: data.coin_id,
        user_id: data.user_id,
      },
    });
    userStakInterestList(data1, options);
    return res;
    // }
  } catch (error) {
    // userStakInterestList();
    console.log(error);
    return error.response.data;
  }
};
const userStakInterestList = async (data, options) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API_HOST}api/v1/admin/stak/user_stak_interest_list`,
      // url: `${API_HOST}api/v1/admin/listCoins`,
      headers: {
        Authorization: options.jwt,
      },
      params: data,
    });
    return res;
    // }
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const deleteInterestPair = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/coinInterest/remove/${data}`,
    data,
    options
  );
};

const updateAllInterest = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/coinInterest/update-all`,
    data,
    options
  );
};

const getSingleInterestInfo = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/coinInterest/single/${data}`,
    data,
    options
  );
};

export const InterestServices = {
  getInterestList,
  userUnstaked,
  userStakInterestList,
  userDailyInterestList,
  addInterest,
  deleteInterestPair,
  updateAllInterest,
  getSingleInterestInfo,
};
