import React, { Component } from "react";
import { Form, Grid, Button, GridColumn } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { required, email } from "redux-form-validators";
import { FormField } from "../../../components/FormField"
import { connect } from "react-redux";


export class GoogleAuthDisable extends Component {
  render() {
    let { handleSubmit } = this.props;
    console.log("ashubndr",this.props)
    return (
      <Form onSubmit={handleSubmit}>
        <div className="fieldsInSignleRow fullWidth">
            <Form.Group widths="equal">
              <Field
                label="Login password"
                name="password"
                size="small"
                component={FormField}
                type="password"
                placeholder="Please enter password"
                validate={[required()]}
                autoFocus
              />
          </Form.Group>
        </div>
        <div className="fieldsInSignleRow fullWidth">
            <Form.Group widths="equal">
              <Field
                label="Google authentication code"
                name="token"
                size="small"
                component={FormField}
                type="password"
                placeholder="Enter token code"
                validate={[required()]}
              />
            </Form.Group>
        </div>

        <Grid.Row columns={2} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <GridColumn className="mr0">
            <Button
            color="danger"
              type="button"
              positive
              onClick={this.props.close}
            >
              Cancel
            </Button>
            <Button color="info" type="submit" positive>
              Disable
            </Button>
          </GridColumn>
        </Grid.Row>
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    step: state.account.googleStep
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // verifyAuthToken: values => dispatch(verifyAuthRequest(values)),
    // verifygoogleAuth: values => dispatch(verifyGoogleAuthRequest(values))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "GoogleDisableForm" })(GoogleAuthDisable));
