import React, { Component } from "react";
import CustomHeader from "components/Headers/CustomHeader";
import { Form } from "semantic-ui-react";
import { Button } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { FormField } from "../../../components/FormField";
import { SelectField } from "../../../components/SelectField";
import { email, required } from "redux-form-validators";
import {
  getAdminSettings,
  saveFeeSettingDetails,
  updateAdminSetting
} from "../../../Redux/actons/account.action";
import {
  amountLimit,
  amountValidator
} from "../../../validators/customValidator";
const options = [
  { key: "m", text: "Swap ida idp rate", value: "swap_ida_idp_rate" }
];
const actions = [
  { key: "m", text: "Activate", value: 1 },
  { key: "f", text: "Inactivate", value: 2 }
];

export class SwapTransactionFee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isType: false,
      defaultType: "",
      defaultAction: 1
    };
  }
  componentDidMount = () => {
    this.props.dispatch(change("SwapTransFee", "status", 1));
    this.props.dispatch(change("SwapTransFee", "type", "swap_ida_idp_rate"));

    this.getDetails("", "swap_ida_idp_rate");
  };
  componentWillUnmount = () => {
    this.props.saveFeeSettingDetails([]);
  };
  setFormValues = data => {
    console.log("DATA", data);
    if (data.length > 0) {
      this.setState({
        defaultType: data[0].type,
        defaultAction: data[0].status,
        isType: true
      });
      this.props.dispatch(change("SwapTransFee", "status", data[0].status));
      this.props.dispatch(change("SwapTransFee", "value", data[0].value));
    } else {
      this.props.dispatch(change("SwapTransFee", "status", 1));
      this.props.dispatch(change("SwapTransFee", "value", ""));
    }
  };
  componentWillReceiveProps = nextProps => {
    if (this.props.feeSettingDetails !== nextProps.feeSettingDetails) {
      this.setFormValues(nextProps.feeSettingDetails);
    }
  };
  getDetails = (e, data) => {
    this.props.dispatch(change("SwapTransFee", "status", 1));
    this.setState({ isType: true, defaultAction: 1, defaultType: data });

    let param = {
      type: data
    };
    this.props.getAdminSettings(param);
  };
  setStatus = (e, data) => {
    this.props.dispatch(change("SwapTransFee", "status", data));
    this.setState({ isType: true, defaultAction: data });
  };

  updateForm = values => {
    console.log("calue", values);
    values["status"] = 1;
    this.props.updateAdminSetting(values);
  };
  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <CustomHeader />
        <div style={{ padding: 30 }}>
          <Form onSubmit={handleSubmit(this.updateForm)}>
            <Form.Group>
              <Form.Field>
                <label>Choose Type</label>
                <Field
                  name="type"
                  search={false}
                  selectOnBlur={false}
                  validate={[required()]}
                  component={SelectField}
                  defaultValue={this.state.defaultType}
                  children={options}
                  placeholder="Select "
                  onChange={(e, data) => this.getDetails(e, data)}
                ></Field>
              </Form.Field>
            </Form.Group>
            {this.state.isType && (
              <Form.Group>
                <Form.Field>
                  <label>Ida to Idp rate</label>
                  <Field
                    type="number"
                    name="value"
                    validate={[required(), amountLimit()]}
                    component={FormField}
                    placeholder="Fee"
                  ></Field>
                </Form.Field>
                {/* <Form.Field>
                  <label>Status</label>
                  <Field
                    name="status"
                    search={false}
                    selectOnBlur={false}
                    validate={[required()]}
                    component={SelectField}
                    children={actions}
                    placeholder="Select"
                    defaultValue={this.state.defaultAction}
                    onChange={(e, data) => this.setStatus(e, data)}
                  ></Field>
                </Form.Field> */}
              </Form.Group>
            )}
            {this.state.isType && (
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    feeSettingDetails: state.account.feeSettingDetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAdminSettings: data => dispatch(getAdminSettings(data)),
    updateAdminSetting: data => dispatch(updateAdminSetting(data)),
    saveFeeSettingDetails: data => dispatch(saveFeeSettingDetails(data))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "SwapTransFee" })(SwapTransactionFee));
