import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Container } from "reactstrap";
import { Tab } from "semantic-ui-react";
import Header from "components/Headers/Header";
import { withRouter } from "react-router";
import "../AccountSetting/AccountSetting.scss";
import { reduxForm } from "redux-form";
import { getDailyWithdrawLimit } from "../../Redux/actons/transaction.action";
import RewardManagement from "./RewardManagement";
import RewardScratch from "./RewardScratch";
// import TransactionFeeManagementWithdraw from "views/TransactionFeeManagement/TransactionFeeManagement";
const panes = [
  {
    menuItem: "Reward Type",
    render: () => (
      <Tab.Pane>
        {" "}
        <RewardManagement statusType={"withdraw"} />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Reward Scrach",
    render: () => (
      <Tab.Pane>
        <RewardScratch statusType={"deposit"} />
      </Tab.Pane>
    ),
  },

  // { menuItem: "Tab 2", pane: "Tab 2 Content" },
];

export class TabDevideHandle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentUserId: "",
      open: false,
      openModel: false,
      trnx_type: [],
      status: [],
      coin_type: "",
      searchKey: "",
      perPage: "10",
      page: "1",
      from_date: "",
      to_date: "",
      message: "",
      detailModal: false,
      order: "desc",
      sortBy: "",
      coin_family: "",
      tabValue: "",
      filterType: [],
      dailyWithdrawLimitModal: false,
      gstModal: false,
      tdsModal: false,
      to_address_copied: false,
      from_address_copied: false,
      tx_id_copied: false,
      downloadExcel: false,
      filterData: {},
    };
  }
  componentDidMount = () => {
    // this.props.getDailyWithdrawLimit();
    // this.props.getTDSLimit({ type: "tds_percent" }).then(async () => {
    //   // this.setState({gstValue: this.props.gstValue})
    //   await this.props.dispatch(
    //     change("SetTdsForm", "limit", this.props?.tdsValue)
    //   );
    // });
    // this.props.getGSTLimit({ type: "gst_percent" }).then(async () => {
    //   // this.setState({gstValue: this.props.gstValue})
    //   await this.props.dispatch(
    //     change("SetGstForm", "limit", this.props?.gstValue)
    //   );
    // });
    // this.props
    //   .getDailyWithdrawLimit({ type: "daily_withdraw_limit" })
    //   .then(async () => {
    //     await this.props.dispatch(
    //       change(
    //         "SetDailyWithdrawLimitForm",
    //         "limit",
    //         this.props?.dailyWithdawLimit
    //       )
    //     );
    //   });
  };

  getTDSLimitHandler = () => {
    this.props.getDailyWithdrawLimit();
  };
  render() {
    let { handleSubmit, txnDetails } = this.props;
    // console.log("tabValue ------>>>", this.state.tabValue);
    return (
      <div>
        <Header headerTitle="Reward Management" />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Card className="shadow">
                <Tab
                  panes={panes}
                  // onClick={(e) => {
                  //   this.setState({ tabValue: e.target });
                  // }}
                  // renderActiveOnly={false}
                />
              </Card>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    totalTransRecords: state.transaction.totalTransRecords,
    queryListRecords: state.transaction.queryListRecords,
    getTransFeeList: state.transaction.getTransFeeList,
    txnDetails: state.transaction.txnDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDailyWithdrawLimit: () => dispatch(getDailyWithdrawLimit()),
    // userDetail: (id) => dispatch(userDetail(id)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "getDailyWithdrawLimit" })(TabDevideHandle))
);
