// MetaMaskConnection
import React, { useEffect } from "react";
import { Button, Toast } from "reactstrap";
import { connect } from "react-redux";
import { verifyGoogleAuthRequest } from "../../Redux/actons/security.action";
import { Redirect } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { InjectedConnector } from "@web3-react/injected-connector";
import { Pagination, Dropdown, Icon } from "semantic-ui-react";
import { IMAGE_PATH } from "constants/index";
// import { ethers } from "ethers";
import Blockies from "react-blockies";
import { toast } from "../../components/Toast/Toast";

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 9731, 10001, 80001, 137],
});

const ChainNetworkId = [
  {
    key: "ETH",
    coin_family: 1,
    text: (
      <>
        {" "}
        <img
          src={`${IMAGE_PATH}images/1634290767066-628px-Ethereum_logo_2014.svg.png`}
          alt="logo"
          style={{ height: 32, width: 25, marginRight: "6px" }}
        />
        Ethereum Mainnet (ETH)
      </>
    ),
    value: "5",
  },
  {
    key: "BNB",
    coin_family: 3,
    text: (
      <>
        {" "}
        <img
          src={`${IMAGE_PATH}images/1655508573511-bnb-bnb-logo.png`}
          alt="logo"
          style={{ height: 32, width: 25, marginRight: "6px" }}
        />
        Binance Mainnet (BNB)
      </>
    ),
    value: "97",
  },
  {
    key: "MATIC",
    coin_family: 4,
    text: (
      <>
        {" "}
        <img
          src={`/matic.png`}
          alt="logo"
          style={{ height: 32, width: 25, marginRight: "6px" }}
        />
        Matic Mainnet (MATIC)
      </>
    ),
    value: "80001",
  },
];
const MetaMaskConnection = ({ coin_family, setcoin_family }) => {
  const { activate, account, deactivate, chainId } = useWeb3React();
  const web3 = (window.web3 = new Web3(window.ethereum));
  const [networkId, setNetworkId] = React.useState(chainId?.toString(), {
    value: chainId?.toString(),
  });

  React.useEffect(() => {
    if (chainId?.toString() != networkId?.toString()) {
      NetworkHandlet(chainId?.toString(), { value: chainId?.toString() });
    }
    // if (
    //   (chainId == data.value && data.value == 1) ||
    //   (chainId == data.value && data.value == 5)
    // ) {
    //   setcoin_family(1);
    //   // setcoin_family("");
    // }
    // if (
    //   (chainId == data.value && data.value == 56) ||
    //   (chainId == data.value && data.value == 97)
    // ) {
    //   setcoin_family(3);
    // }
  }, [chainId]);
  const NetworkHandlet = (e, data) => {
    try {
      if (data.value == 1 || data.value == 5) {
        setcoin_family(1);
        // setcoin_family("");
      }
      if (data.value == 56 || data.value == 97) {
        setcoin_family(3);
      }
      setNetworkId(data.value);
      if (chainId != data.value) {
        swichNetworkHandler(Number(data.value));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const swichNetworkHandler = async (ACTIVE_NETWORK) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
      if (error.code === 4902) {
        // addNetworkHandler(ACTIVE_NETWORK);
      }
    }
  };

  function connectWallet12(params) {
    try {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          console.log("ERROR 123", error);
          toast.error(error["message"]);
          activate(injected);
        }
      });
    } catch (error) {
      console.log("ERROR 123", error);
      toast.warn("Errors");
    }
  }

  return (
    <React.Fragment>
      <div
        className="selectSearchOption"
        style={{
          paddingTop: "15px",
        }}
      >
        <button
          className={"blockies"}
          onClick={!account && connectWallet12}
          style={{ marginRight: "10px" }}
        >
          <Blockies
            className={"blockies"}
            // seed={"Jeremy"}
            style={{
              height: "38px",
            }}
            size={8}
            scale={3}
          />
          <div style={{ marginLeft: "6px" }}>
            {account
              ? account.slice(0, 6) + "..." + account.slice(-4)
              : "Connect MetaMask Wallet"}
          </div>
        </button>
        {networkId && (
          <Dropdown
            className="selectUserStyle mx-2"
            options={ChainNetworkId}
            placeholder="Filter"
            selection
            defaultValue={networkId}
            onChange={(e, data) => NetworkHandlet(e.target.value, data)}
          />
        )}
      </div>
      {/* )} */}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.persist["c_user"],
    user: state.user["user"],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifygoogleAuth: (values) => dispatch(verifyGoogleAuthRequest(values)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MetaMaskConnection);
