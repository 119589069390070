import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import { Form } from "semantic-ui-react";
import { FormField } from "../../components/FormField";
import {
    getGSTLimit,
    setGSTLimit,
  } from "../../Redux/actons/transaction.action";
import { Field, reduxForm, change, reset } from "redux-form";
import { SelectField } from "../../components/SelectField";
import { getCoinList } from "../../Redux/actons/user.action";
import { required } from "redux-form-validators";
import { invalidMinusValue } from "validators/customValidator";

export class RewardLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
      sortBy: "",
      image: "",
      link: "",
      rewardType: "0",
    };
  }

  componentDidMount = () => {
    this.props.getGSTLimit({ type: "min_redeem_reward" }).then(async () => {
      await this.props.dispatch(
        change("SetGstForm", "amount", this.props?.rewardValue)
      );
    });
  };
  setRewardLimit = (values) => {
    let data = {
      type: "min_redeem_reward",
      value: values.amount,
    };
    console.log("data >> ", data);
    this.props.setGSTLimit(data);
    this.props.closeRewardModal();
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.showRewardModal}
          toggle={this.props.closeRewardModal}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.closeRewardModal}>
            Set Minimum Reward Withdraw
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.setRewardLimit)}>
              <Form.Group>
                <Form.Field>
                  <label>Minimum Withdraw</label>
                  <Field
                    placeholder="Amount"
                    name="amount"
                    component={FormField}
                    type="number"
                    validate={[required(), invalidMinusValue()]}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Add Reward
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
    return {
      rewardValue: state.transaction.gstValue,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      getGSTLimit: (data) => dispatch(getGSTLimit(data)),
      setGSTLimit: (data) => dispatch(setGSTLimit(data)),
    };
  };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "RewardLimitForm" })(RewardLimit));
