import { fetch } from "./Fetch";
import { API_HOST } from "../constants/index";

const getWithdrawList = (params, data, options) => {
  window.localStorage.setItem("tokens", options.jwt);
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/withdraw/list`,
    data,
    options,
    params
  );
};

const approveDisapproveRequest = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/withdraw/${data.coin}/acceptdeclinewithdrawrequest/${data.id}`,
    data,
    options
  );
};
const withdrawComment = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/withdraw/sendComment`,
    data,
    options
  );
};

const getTotalBalance = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/wallets/get_total_balance_value/${data.id}`,
    data,
    options
  );
};

export const WithdrawServices = {
  getWithdrawList,
  approveDisapproveRequest,
  withdrawComment,
  getTotalBalance,
};
