import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import {
  getCurrencyList,
  updateCurrency,
  deleteCoinValue,
  updateRate,
  addCoinValue,
  editFee,
  CancleOredr,
  updateCoinValue,
  getCoinValue,
  orderbookListCoins,
  orderbookFee,
  orderbookViewCoins,
} from "../../Redux/actons/user.action";
import { Pagination, Form, Dropdown } from "semantic-ui-react";
import Header from "components/Headers/Header";
import { withRouter, Link } from "react-router-dom";
import { listDateFormat } from "utils/utils";
import SemanticDatepicker from "react-semantic-ui-datepickers";

const options = [
  { key: "m", text: "Upgrade", value: "upgrade" },
  { key: "k", text: "Downgrade", value: "downgrade" },
];

const UserFilter = [
  { key: "o", text: "All", value: "none" },
  { key: "c", text: "Buy Sell", value: "BUY" },
  { key: "u", text: "Sell Order", value: "SELL" },
];
const statusFilter = [
  { key: "o", text: "All", value: "none" },
  { key: "c", text: "Pending", value: "pending" },
  { key: "u", text: "Cancelled", value: "cancelled" },
  { key: "u", text: "Filled", value: "filled" },
  { key: "u", text: "Partially Filled", value: "partially_filled" },
];

export class OrderManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      buyOrder: "",
      sellOrder: "",
      coinName: "",
      max: "",
      min: "",
      coinName1: "",
      max1: "",
      min1: "",
      buyRate: "",
      sellRate: "",
      sortBy: "",
      openModel: false,
      openModel1: false,
      openModelCancleOrder: false,
      openModelFee: false,
      isUpdate: false,
      modalData: "",
      listType: "1",
      tokenId: "",
      order_type: "",
      order_status: "",
      start_date: "",
      end_date: "",
      orderId: "",
      pairUSDT: "",
      sell_fee: this.props.saveorderbookFee?.data?.sell_fee,
      buy_fee: this.props.saveorderbookFee?.data?.buy_fee,
      datepicker_start_date: null,
      datepicker_end_date: null,
    };
  }
  componentDidMount = () => {
    this.orderbookListCoins();
    this.props.orderbookFee();
  };

  getCurrencyList = () => {
    const param = {
      search: this.state.searchKey,
      page: this.state.page,
      perPage: this.state.perPage,
      list_type: this.state.listType,
      status: "1",
    };
    this.props.getCurrencyList(param);
  };
  orderbookListCoins = () => {
    const data = {
      search: this.state.searchKey,
      page: this.state.page,
      perPage: this.state.perPage,
      order_type: this.state.order_type,
      filterBy: this.state.order_status,

      status: "1",
    };
    this.props.orderbookListCoins(data);
  };
  orderbookViewCoins = (id) => {
    console.log("id------- orderbookViewCoins", id);
    const params = {
      id: id.id,
      order_id: id.order_id,
      coin_name: id.coin_name,
    };
    this.props.orderbookViewCoins(params);
    this.openModel1();
  };
  // CancleOrderbookCoins = (id) => {
  //   // console.log("id------- orderbookViewCoins", id);
  //   // const params = {
  //   //   id: id.id,
  //   //   order_id: id.order_id,
  //   //   coin_name: id.coin_name,
  //   // };
  //   // // this.props.orderbookViewCoins(params);
  //   // this.openModelCancleOrder();
  // };
  onSearchList = () => {
    // let params = this.props.filterData;
    // if (this.state.start_date !== "" && this.state.end_date !== "") {
    //   var d1 = new Date(this.state.datepicker_start_date);
    //   var d2 = new Date(this.state.datepicker_end_date);
    //   if (d1.getTime() >= d2.getTime()) {
    //     alert("End date must be greater than Start date");
    //     this.setState({ datepicker_end_date: null });
    //     return false;
    //   }
    //   params.start_date = this.state.start_date;
    //   params.end_date = this.state.end_date;
    // }
    console.log("params --- this.props.filterData", this.props);
    // this.orderbookListCoins();
  };

  listByUser = (e, data) => {
    console.log("orderbookListCoins --- daat", data);
    if (data.value !== "none") {
      this.setState({ order_type: data.value }, () => {
        this.orderbookListCoins();
      });
    } else {
      this.setState(
        {
          order_type: "",
          start_date: "",
          end_date: "",
          datepicker_start_date: null,
          datepicker_end_date: null,
        },
        () => {
          this.orderbookListCoins();
        }
      );
    }
  };
  listByStatus = (e, data) => {
    console.log("orderbookListCoins --- daat", data);
    if (data.value !== "none") {
      this.setState({ order_status: data.value }, () => {
        this.orderbookListCoins();
      });
    } else {
      this.setState(
        {
          order_status: "",
          start_date: "",
          end_date: "",
          datepicker_start_date: null,
          datepicker_end_date: null,
        },
        () => {
          this.orderbookListCoins();
        }
      );
    }
  };
  SetStartDate(data) {
    if (data.value !== null) {
      let date = new Date(data.value);
      let start_date = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
      this.setState({ start_date: start_date });
      this.setState({ datepicker_start_date: data.value });
    } else {
      this.setState({ start_date: "" });
      this.setState({ datepicker_start_date: data.value });
    }
  }
  SetEndDate(data) {
    if (data.value !== null) {
      let date = new Date(data.value);
      let end_date = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
      this.setState({ end_date: end_date });
      this.setState({ datepicker_end_date: data.value });
    } else {
      this.setState({ end_date: "" });
      this.setState({ datepicker_end_date: data.value });
    }
  }

  toggleCancleOrder = () =>
    this.setState({
      openModelCancleOrder: !this.state.openModelCancleOrder,
    });
  toggleFee = () =>
    this.setState({
      openModelFee: !this.state.openModelFee,
    });
  toggle = () =>
    this.setState({
      openModel: !this.state.openModel,
    });
  toggle1 = () =>
    this.setState({
      openModel1: !this.openModel1,
    });

  openModelFee = (data) => {
    this.setState({
      openModelFee: true,
    });
  };
  CancleOrderbookCoins = (data) => {
    console.log("data------>>>>>", data);
    this.setState({
      orderId: data.order_id,
      pairUSDT: data.coin_name,
      openModelCancleOrder: true,
    });
  };
  openModel = (data) => {
    this.setState({
      openModel: true,
    });
  };
  openModel1 = () => {
    this.setState({
      openModel1: true,
      // coinName1: data.coin_name,
      // min1: data.min,
      // max1: data.max,
      // tokenId: data.id,
      // isUpdate: true,
    });
  };

  deleteCoinValue = (data1, id, currencyType) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    const buttonState = { status: data1 };

    const params = {
      id: data1,
    };
    this.props.deleteCoinValue(params, data2);

    this.orderbookListCoins();
  };
  enableDisableCurrency = (data, id, currencyType) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };

    const buttonState = { status: data };

    const params = {
      currency_type: currencyType,
      currency_id: id,
      status: data,
    };
    this.props.updateCurrency(params, data2);
  };

  onChangePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      console.log("this.props.orderbookMeta.page", data);
      this.orderbookListCoins();
    });
  };

  sortForBuy = (e, data) => {
    this.setState({ buyOrder: data.value }, () => {});
  };

  sortForSell = (e, data) => {
    this.setState({ sellOrder: data.value }, () => {});
  };

  updateCoinValueClick = (currency_type) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    if (
      this.state.coinName1 === "" ||
      this.state.min1 === "" ||
      this.state.max1 === ""
    ) {
      alert("all fields are required");
      return;
    }

    if (this.state.max1 < this.state.min1) {
      alert("Min value should be less than Max");
    } else {
      const data = {
        id: this.state.tokenId,
        coin_name: this.state.coinName1,
        min: this.state.min1,
        max: this.state.max1,
        // sell_rate_type: this.state.sellOrder,
        // currency_id: this.state.modalData.currency_id,
        // currency_type: this.state.currencyType,
      };
      this.props.updateCoinValue(data, this.state.modalData.id, data2);
      this.toggle1();
    }
  };
  CancleOredr = () => {
    const params = {
      // coin_name: this.state.coinName,
      orderId: this.state.orderId,
      pairInUSDT: this.state.pairUSDT,
    };
    this.props.CancleOredr(params);
    this.toggleCancleOrder();
    // }
  };
  editFeeClick = (currency_type) => {
    // const data2 = {
    //   page: this.state.page,
    //   perPage: this.state.perPage,
    // };
    if (
      // this.state.coinName === "" ||
      // this.state.sell_fee === "" ||
      // this.state.buy_fee === "" ||
      this.state.sell_fee === "" ||
      this.state.buy_fee === ""
    ) {
      alert("all fields are required");
      return;
    }

    // if (this.state.max < this.state.min) {
    //   alert("Min value should be less than Max");
    // } else {
    const data = {
      // coin_name: this.state.coinName,
      buy_fee: this.state.buy_fee,
      sell_fee: this.state.sell_fee,
      // sell_rate_type: this.state.sellOrder,
      // currency_id: this.state.modalData.currency_id,
      // currency_type: this.state.currencyType,
    };
    this.props.editFee(data);
    this.toggleFee();
    // }
  };
  addCoinValueClick = (currency_type) => {
    const data2 = {
      page: this.state.page,
      perPage: this.state.perPage,
    };
    if (
      this.state.coinName === "" ||
      this.state.min === "" ||
      this.state.max === ""
    ) {
      alert("all fields are required");
      return;
    }

    if (this.state.max < this.state.min) {
      alert("Min value should be less than Max");
    } else {
      const data = {
        coin_name: this.state.coinName,
        min: this.state.min,
        max: this.state.max,
        // sell_rate_type: this.state.sellOrder,
        // currency_id: this.state.modalData.currency_id,
        // currency_type: this.state.currencyType,
      };
      this.props.addCoinValue(data, this.state.modalData.id, data2);
      this.toggle();
    }
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Order Book Management" />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      {/* <Input
                        placeholder="Search"
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input> */}
                      {/* <Form
                        className="selectSearchOption"
                        style={{
                          height: "40px",
                          paddingRight: "10px",
                        }}
                      >
                        <Form.Group>
                          <Form.Field>
                            <SemanticDatepicker
                              locale="en-US"
                              onChange={(event, data) =>
                                this.SetStartDate(data)
                              }
                              type="basic"
                              format="YYYY-MM-DD"
                              value={this.state.datepicker_start_date}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field>
                            <SemanticDatepicker
                              locale="en-US"
                              className="btnMamagePermission"
                              onChange={(event, data) => this.SetEndDate(data)}
                              type="basic"
                              format="YYYY-MM-DD"
                              value={this.state.datepicker_end_date}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button> */}
                      <Dropdown
                        className="selectUserStyle btnMamagePermission"
                        options={UserFilter}
                        placeholder="Filter by order"
                        selection
                        onChange={(e, data) => this.listByUser(e, data)}
                      />
                      <Dropdown
                        className="selectUserStyle btnMamagePermission"
                        options={statusFilter}
                        placeholder="Filter by status"
                        selection
                        onChange={(e, data) => this.listByStatus(e, data)}
                      />
                      {/* <Link to="order-setting">
                        <Button color="success btnMamagePermission">
                          Settings
                        </Button>
                      </Link>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openModelFee()}
                      >
                        Edit Fee
                      </Button> */}
                      {/* <Link to="order-setting">
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.openModel()}
                      >
                        Add pairs
                      </Button>
                      </Link>  */}
                      {/* <Link to="view-order-list">
                        <Button color="success btnMamagePermission">
                          View pairs
                        </Button>
                      </Link> */}
                      {/* <Link to="trade-history">
                        <Button color="success btnMamagePermission">
                          Trade History
                        </Button>
                      </Link>
                      <Link to="pair-coin">
                        <Button color="success btnMamagePermission">
                          Pair Coin Management
                        </Button>
                      </Link> */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Order Id</th>
                        <th scope="col">Pair Id</th>
                        <th scope="col">Coin Id</th>
                        <th scope="col">Coin Name</th>
                        <th scope="col">User Email</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Price </th>
                        {/* <th scope="col">Price In INR</th> */}
                        {/* <th scope="col">Flat Fee</th>
                        <th scope="col">TDS Fee</th>
                        <th scope="col">TDS PER </th> */}
                        <th scope="col">ORDER TYPE </th>
                        <th scope="col">Date & Time</th>
                        <th scope=" col">Order Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.saveorderbookList?.length === 0 ||
                        (this.props.saveorderbookList === undefined && (
                          <tr>
                            <td>No results found</td>
                          </tr>
                        ))}{" "}
                      {this.props.saveorderbookList?.length !== 0 &&
                        this.props.saveorderbookList?.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data?.order_id}</td>
                            <td>{data?.pair_id}</td>
                            <td>{data?.coin_id}</td>
                            <td>{data?.pair_name?.toUpperCase()}</td>
                            <td>{data?.user?.email}</td>
                            <td>{data?.orig_qty}</td>
                            <td>{data?.price}</td>

                            <td
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {data?.order_type.toLowerCase()} Order{" "}
                            </td>

                            {/* <td>{data?.currency_type.toUpperCase()}</td> */}
                            <td>
                              {data?.created_at != ""
                                ? listDateFormat(data?.created_at)
                                : "None"}
                            </td>
                            <td
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {data?.order_status}{" "}
                            </td>

                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color="success"
                                  onClick={() => {
                                    // this.openModel1(data);
                                    this.orderbookViewCoins(data);
                                  }}
                                >
                                  View
                                </Button>
                                {/* <Button
                                  color="danger"
                                  onClick={() => {
                                    // this.openModel1(data);
                                    this.CancleOrderbookCoins(data);
                                  }}
                                >
                                  Cancel Order
                                </Button> */}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {this.props.orderbookMeta?.pages != 1 && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          onPageChange={(e, data) => this.onChangePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.orderbookMeta?.pages
                          )}
                        />
                      </nav>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        <Modal
          isOpen={this.state.openModel1}
          toggle={this.toggle1}
          className={[this.props.className, "modalCustom"]}
        >
          {/* {this.props.saveVieworderBook?.coin_name} */}

          {/* <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={[this.props.className, "modalCustom"]}
        > */}
          <ModalHeader toggle={this.props.toggle}>
            <h1>View Order INFO</h1>
          </ModalHeader>
          <ModalBody>
            <div className="userNameList">
              <div className="itemStyle">
                <label>Coin Name:</label>
                <label>
                  {this.props.saveVieworderBook?.pair_name
                    ? this.props.saveVieworderBook?.pair_name
                    : "None"}
                </label>
              </div>
              <div className="itemStyle">
                <label>Trade Type:</label>
                <span
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {this.props.saveVieworderBook?.order_type
                    ? `${this.props.saveVieworderBook?.type} Order`
                    : "None"}{" "}
                </span>
              </div>
              <div className="itemStyle">
                <label>Price :</label>
                <label>
                  {this.props.saveVieworderBook?.price
                    ? this.props.saveVieworderBook?.price
                    : "None"}{" "}
                </label>
              </div>
              <div className="itemStyle">
                <label>Quantity:</label>
                <label>
                  {this.props.saveVieworderBook?.origQty
                    ? this.props.saveVieworderBook?.origQty
                    : "None"}{" "}
                </label>
              </div>
              <div className="itemStyle">
                <label>Type:</label>
                <label>
                  {this.props.saveVieworderBook?.type
                    ? this.props.saveVieworderBook?.type
                    : "None"}{" "}
                </label>
              </div>
              {/* <div className="itemStyle">
                <label>Update Time:</label>
                <label>
                  {this.props.saveVieworderBook?.updateTime
                    ? listDateFormat(this.props.saveVieworderBook?.updateTime)
                    : "None"}{" "}
                </label>
              </div> */}
              <div className="itemStyle">
                <label>Time:</label>
                <label>
                  {this.props.saveVieworderBook?.updated_at
                    ? listDateFormat(this.props.saveVieworderBook?.updated_at)
                    : "None"}{" "}
                </label>
              </div>
              <div className="itemStyle">
                <label> Status:</label>
                <span>
                  {this.props.saveVieworderBook?.order_status
                    ? this.props.saveVieworderBook?.order_status.toUpperCase()
                    : "None"}{" "}
                </span>
              </div>
              {/* <div className="itemStyle">
                <label> Order Status:</label>
                <span>
                  {this.props.saveVieworderBook?.order_status
                    ? this.props.saveVieworderBook?.order_status.toUpperCase()
                    : "None"}{" "}
                </span>
              </div> */}
            </div>
          </ModalBody>
          {/* </Modal> */}
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel1: false })}
            >
              Cancel
            </Button>
            {/* <Button color="success" onClick={() => this.updateCoinValueClick()}>
              Update
            </Button> */}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.openModelCancleOrder}
          toggle={this.toggleCancleOrder}
        >
          <ModalBody>
            <label>Cancle Order</label>
            <span>Are you sure to cancel this order!</span>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModelCancleOrder: false })}
            >
              Back
            </Button>
            <Button color="success" onClick={() => this.CancleOredr()}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.openModelFee} toggle={this.toggleFee}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Fee Type</label>

                  <input
                    name="coin_mname"
                    placeholder="Coin Name"
                    value={this.props.saveorderbookFee?.data?.fee_type}
                    type="text"
                    disabled
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ coinName: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Sell Fee</label>
                  <input
                    name="sell_fee"
                    placeholder="Sell Fee"
                    value={this.state.sell_fee}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ sell_fee: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Buy Fee</label>
                  <input
                    name="buy_fee"
                    placeholder="Buy Fee"
                    value={this.state.buy_fee}
                    type="number"
                    onChange={(e, data) =>
                      this.setState({ buy_fee: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              {/* <Form.Group>
                <Form.Field>
                  <label>Buy Fee</label>

                  <input
                    name="buy_fee"
                    placeholder="Buy Fee"
                    value={this.state.buy_fee}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ but_fee: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group> */}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModelFee: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.editFeeClick()}>
              Edit Fee
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.openModel} toggle={this.toggle}>
          <ModalBody>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Coin Name</label>

                  <input
                    name="coin_mname"
                    placeholder="Coin Name"
                    value={this.state.coinName}
                    type="text"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ coinName: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Min</label>
                  <input
                    name="Min"
                    placeholder="Min"
                    value={this.state.min}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ min: e.target.value })
                    }
                  ></input>
                  {/* <Dropdown
                    className="selectUserStyle"
                    placeholder="Min"
                    options={options}
                    selection
                    value={this.state.buyOrder}
                    onChange={(e, data) => this.sortForBuy(e, data)}
                  />{" "} */}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <label>Max</label>

                  <input
                    name="max"
                    placeholder="Max"
                    value={this.state.max}
                    type="number"
                    // validate={[required(), amountLimit()]}
                    // component={FormField}
                    onChange={(e, data) =>
                      this.setState({ max: e.target.value })
                    }
                  ></input>
                </Form.Field>
              </Form.Group>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="error"
              onClick={() => this.setState({ openModel: false })}
            >
              Cancel
            </Button>
            <Button color="success" onClick={() => this.addCoinValueClick()}>
              Add Pair
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    getCoinList: state.user.CurrencyList,
    saveorderbookList: state.user.saveorderbookList?.data?.data,
    // saveorderbookListNo: state.user.saveorderbookListNo,
    orderbookMeta: state.user.saveorderbookList?.data?.meta,
    saveCoinValue: state.user.saveCoinValues?.rows,
    saveVieworderBook: state.user.savevieworderbook,
    total: state.user.totalRecords,
    saveorderbookFee: state.user.saveorderbookFee,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrencyList: (data) => dispatch(getCurrencyList(data)),
    orderbookListCoins: (data) => dispatch(orderbookListCoins(data)),
    orderbookFee: (data) => dispatch(orderbookFee(data)),
    orderbookViewCoins: (data) => dispatch(orderbookViewCoins(data)),
    updateCurrency: (data, data2) => dispatch(updateCurrency(data, data2)),
    deleteCoinValue: (data, data2) => dispatch(deleteCoinValue(data, data2)),
    addCoinValue: (data, id, data2) => dispatch(addCoinValue(data, id, data2)),
    editFee: (data, id, data2) => dispatch(editFee(data)),
    CancleOredr: (params, id, data2) => dispatch(CancleOredr(params)),
    updateCoinValue: (data, id, data2) =>
      dispatch(updateCoinValue(data, id, data2)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderManagement)
);
