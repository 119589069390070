import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, reset } from "redux-form";
import { saveSubjectName } from "../../../Redux/actons/subject.action";
import { Table, TableRow } from "semantic-ui-react";
import { Card, CardHeader } from "reactstrap";

export class DashboardResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SubjectName: " ",
    };
  }

  render() {
    let { handleSubmit } = this.props;
    // console.log("====================================");
    // console.log(this.props);
    // console.log("====================================");
    return (
      <div>
        {/* <Modal
          isOpen={this.props?.addRewardModal}
          toggle={this.props.closeRewardModal}
          // className={[this.props.className, "modalCustom"]}
        >
          
        </Modal> */}
        {/* <Card className="shadow"> */}
        <CardHeader className="border-0">
          <div className="selectSearchOption">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <h3>{this.props.heading}</h3>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <h5>{this.props.labResult}</h5>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </CardHeader>
        {/* </Card> */}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveSubjectName: (data) => dispatch(saveSubjectName(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "AddRewardForm" })(DashboardResult));
