import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
  Label,
  FormGroup,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Pagination } from "semantic-ui-react";
import { reduxForm } from "redux-form";
import CustomHeader from "../../components/Headers/CustomHeader";
import { API_HOST } from "../../constants/index";

import { withRouter } from "react-router";
import { getOtcTraderList } from "../../Redux/actons/otc.action";
import { Link } from "react-router-dom";
import { convertExponentialToDecimal } from "../../utils/utils";

export class OtcTraderManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      TradeUserId: {},
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      viewHistoryModel: false,
    };
  }
  openModel = (data) => {
    console.log(data);
    console.log("TradeUserId", this.state.TradeUserId);
    this.setState({ TradeUserId: data, open: true });
  };
  componentDidMount = () => {
    this.getTraderList();
    this.checkRouteValid();
  };
  checkRouteValid = async () => {
    if (this.props.user.role == 2) {
      var isfound = false;
      // check user Permissions if route accessible
      await this.props.permissions.forEach((element) => {
        if (element.title == "OTC Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        this.props.history.push("/");
      }
    }
  };

  getTraderList = () => {
    let data = {
      searchKey: this.state.searchKey,

      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.getOtcTraderList(data);
  };

  // //page change
  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getTraderList();
    });
  };
  //search
  onSearchList = (abc) => {
    console.log(abc);
    this.setState({ searchKey: abc, page: 1 }, () => {
      this.getTraderList();
    });
  };
  viewHistory = (data) => {
    this.setState({ viewHistoryModel: true });
  };
  closeModal2 = () => {
    this.setState({ viewHistoryModel: false });
  };
  toggle = () => {
    this.setState({ open: !this.state.open });
  };
  downLoadTradeList = () => {
    window.location.href = `${API_HOST}api/v1/admin/otc/download/trades`;
  };
  render() {
    return (
      <div>
        <CustomHeader />
        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0" style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-0">OTC Trader List</h3>
                      <div className="selectSearchOption">
                        <Input
                          placeholder="Search"
                          style={{ width: "500px" }}
                          onChange={(e) =>
                            this.onSearchList(`${e.target.value}`)
                          }
                        ></Input>
                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.downLoadTradeList()}
                        >
                          Download Trader List
                        </Button>
                      </div>
                    </div>

                    <Link to="otc-management">
                      <Button color="success">Back</Button>
                    </Link>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Seller(EMAIL)</th>
                        <th scope="col">Buyer(EMAIL)</th>

                        <th scope="col">Buying</th>
                        <th scope="col">Selling</th>
                        <th scope="col">Min. Limit</th>
                        <th scope="col">Max Limit</th>
                        <th scope="col">Price</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Buyer Fees</th>
                        <th scope="col">Buyer Recieved</th>

                        <th scope="col">Seller Fees</th>
                        <th scope="col">Seller Recieved</th>
                        <th scope="col">IP Address</th>

                        {/* <th scope="col">Tx History</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.getTraderList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          <td>{usersList.trade_owner.email}</td>
                          <td>{usersList.trade_user.email}</td>
                          <td>{usersList.buy_symbol.toUpperCase()}</td>
                          <td>{usersList.sell_symbol.toUpperCase()}</td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.minimum_limit)
                            )}
                          </td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.maximum_limit)
                            )}
                          </td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.price_per_unit)
                            )}
                          </td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.amount)
                            )}
                          </td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.user_otc_fee)
                            )}{" "}
                            {usersList.offer_type == "sell"
                              ? usersList.buy_symbol.toUpperCase()
                              : usersList.sell_symbol.toUpperCase()}{" "}
                          </td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.user_received)
                            )}{" "}
                            {usersList.offer_type == "sell"
                              ? usersList.buy_symbol.toUpperCase()
                              : usersList.sell_symbol.toUpperCase()}{" "}
                          </td>
                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.owner_otc_fee)
                            )}{" "}
                            {usersList.offer_type == "sell"
                              ? usersList.sell_symbol.toUpperCase()
                              : usersList.buy_symbol.toUpperCase()}
                          </td>

                          <td>
                            {convertExponentialToDecimal(
                              parseFloat(usersList.owner_received)
                            )}{" "}
                            {usersList.offer_type == "sell"
                              ? usersList.sell_symbol.toUpperCase()
                              : usersList.buy_symbol.toUpperCase()}
                          </td>
                          <td>
                            {" "}
                            {usersList.ip_address
                              ? usersList.ip_address
                              : "N/A"}{" "}
                          </td>
                        </tr>
                      ))}
                      {this.props.getTraderList.length == 0 && (
                        <td>No record</td>
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      {this.props.totalTraderRecords > 0 && (
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={1}
                          defaultActivePage={1}
                          ellipsisItem={true}
                          firstItem={true}
                          lastItem={true}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.totalTraderRecords / this.state.perPage
                          )}
                        />
                      )}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        <Modal isOpen={this.state.open} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}> Transaction History</ModalHeader>
          <ModalBody style={{ marginBottom: 40 }}>
            <p> Maximum Limit: {this.state.TradeUserId.maximum_limit}</p>
            <p> Minimum Limit: {this.state.TradeUserId.minimum_limit}</p>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log("@@@@@@>>>>>>>ritu.", state);
  return {
    getTraderList: state.otc.getTraderList,
    totalTraderRecords: state.otc.totalTraderRecords,
    user: state.user.user,
    permissions: state.subAdmin.sideBarSubAdminPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOtcTraderList: (data) => dispatch(getOtcTraderList(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "OtcTraderManagement" })(OtcTraderManagement))
);
