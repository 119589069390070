import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import { Form } from "semantic-ui-react";
import { FormField } from "../../../components/FormField";
import { addReward } from "../../../Redux/actons/reward.action";
import { Field, reduxForm, change, reset } from "redux-form";
import { SelectField } from "../../../components/SelectField";
import { getCoinList } from "../../../Redux/actons/user.action";
import { required } from "redux-form-validators";
import { invalidMinusValue } from "validators/customValidator";
import { saveSubjectName } from "../../../Redux/actons/subject.action";

export class AddSubject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SubjectName: " ",
    };
  }

  // componentDidMount = async () => {
  //   // this.props.getCoinList();
  //   console.log("pushed", this.props.location.state);
  //   if (this.props.location.state === undefined) {
  //   } else {
  //     this.setState({
  //       SubjectName: this.props.location.state.title,

  //     });
  //   }
  // };
  updateForm = (values) => {
    const formData = {
      // referral_amount: this.state.referralAmount,
      // referral_coin: this.state.referralCoin,
      // plan_type: this.state.planType,
      title: this.state.SubjectName,
      // this.state.planType != "deposit_fiat" ? "0" : this.state.SubjectName,
      // require_min_amount:
      //   this.state.planType != "deposit_fiat" ? "false" : "true",
    };
    this.props.saveSubjectName(formData);
    this.props.closeRewardModal();
    // }
    this.props.reset("addSubjectForm");
    // this.props.history.goBack();
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props?.addRewardModal}
          toggle={this.props.closeRewardModal}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.closeRewardModal}>
            Add Subject
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.updateForm)}>
              {/* <Form.Group>
              <Form.Field>
                <label>Plan Type</label>
                <Dropdown
                  className="selectUserStyle"
                  options={options}
                  selection
                  value={this.state.planType}
                  onChange={(e, data) =>
                    this.setState({ planType: data.value })
                  }
                />
              </Form.Field>
            </Form.Group> */}

              {
                <Form.Group>
                  <Form.Field>
                    <label>Name</label>
                    <input
                      type="text"
                      name="min_deposit"
                      min="0"
                      // value={
                      //   this.state.SubjectName !== null
                      //     ? this.state.SubjectName
                      //     : 0
                      // }
                      validate={[required()]}
                      component={FormField}
                      onChange={(e, data) =>
                        this.setState({ SubjectName: e.target.value })
                      }
                    ></input>
                  </Form.Field>
                </Form.Group>
              }

              {/* {this.state.isCoin && ( */}
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
              {/* // )} */}
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveSubjectName: (data) => dispatch(saveSubjectName(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "AddRewardForm" })(AddSubject));
