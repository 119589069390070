import React, { Component } from "react";
// import { Button, Form, Grid } from "semantic-ui-react";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import { required } from "redux-form-validators";
import { FormField } from "../../components/FormField";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
// import { sendemailverifytoken } from "../../../_actions/account.action";

class AuthForm extends Component {

  render() {
    let { type, handleSubmit } = this.props;
    type = "googleAuth";
    let heading = type ===  "Google";
    return (
      <React.Fragment>
        <br></br>
        <h1 className="heading-type">{heading} Authentication Verification</h1>
        <Form onSubmit={handleSubmit}>
          <div className="input-field">
            <Field
              name="token"
              component={FormField}
              type="text"
              label="Token:"
              placeholder="Verify Token"
              validate={[required()]}
              autoFocus
            />
<br></br>
            <Button type="submit"  color="info">Verify</Button>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    // sendemailverifytoken: () => dispatch(sendemailverifytoken())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "EmailAuth" })(AuthForm));
