import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import { Form } from "semantic-ui-react";
import { FormField } from "../../components/FormField";
import { addReward } from "../../Redux/actons/reward.action";
import { Field, reduxForm, change, reset } from "redux-form";
import { SelectField } from "../../components/SelectField";
import { getCoinList } from "../../Redux/actons/user.action";
import { required } from "redux-form-validators";
import { invalidMinusValue } from "validators/customValidator";

const reward_types = [
  { key: "n", text: "Deposit Crypto", value: "deposit_crypto" },
  { key: "m", text: "Deposit fiat", value: "deposit_fiat" },
  { key: "k", text: "Sign up", value: "sign_up" },
  { key: "l", text: "First Trade", value: "first_trade" },
];
export class AddReward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "asc",
      sortBy: "",
      image: "",
      link: "",
      rewardType: "0",
    };
  }

  componentDidMount = () => {
    // this.getBannerList();
    // this.props.getCoinList();
  };

  addReward = (values) => {
    console.log("values >>>", values);
    let data = {
      type: values.reward_type,
      amount: values.reward_type == "deposit_fiat" ? values.amount : "0",
    };
    this.props.addReward(data);
    this.props.closeRewardModal();
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props?.addRewardModal}
          toggle={this.props.closeRewardModal}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.closeRewardModal}>
            Add Reward
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.addReward)}>
              <Form.Group>
                <Form.Field>
                  <label>Reward Type</label>
                  <Field
                    name="reward_type"
                    search={false}
                    selectOnBlur={false}
                    component={SelectField}
                    children={reward_types}
                    placeholder="Select"
                    onChange={(type) => this.setState({ rewardType: type })}
                    validate={[required()]}
                  ></Field>
                </Form.Field>
              </Form.Group>

              {this.state.rewardType == "deposit_fiat" ? (
                <Form.Group>
                  <Form.Field>
                    <label>Amount</label>
                    <Field
                      placeholder="Amount"
                      name="amount"
                      // value={this.state.fee}
                      component={FormField}
                      type="number"
                      validate={[required(), invalidMinusValue()]}
                    />
                  </Form.Field>
                </Form.Group>
              ) : (
                console.log("")
              )}

              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Add Reward
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addReward: (data) => dispatch(addReward(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "AddRewardForm" })(AddReward));
