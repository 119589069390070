import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Pagination, Dropdown } from "semantic-ui-react";
import { reduxForm } from "redux-form";
import CustomHeader from "../../components/Headers/CustomHeader";
import { IMAGE_PATH } from "../../constants/index";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import {
  getAnnouncementList,
  getAnnouncementDetails,
  deleteAnnouncement,
  saveAnnounceEditDetails,
  sendAnnouncementNotification,
} from "../../Redux/actons/announcement.action";

const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
export class AnnouncementListManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "desc",
      sortBy: "",
      getDetailsId: {},
      modal: false,
    };
  }
  toggle = () => this.setState({ open: !this.state.open });

  componentDidMount = () => {
    this.getUserList();
  };
  addNewAnnouncement = () => {
    this.props.saveAnnounceEditDetails({});
    this.props.history.push("add-announcement");
  };
  updateAnnouncement = (data) => {
    this.props.saveAnnounceEditDetails(data);
    this.props.history.push("add-announcement");
  };
  deleteAnnouncement = (id) => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.deleteAnnouncement(data, id);
  };

  sendAnnouncement = (id) => {
    console.log("ID", id);
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.sendAnnouncementNotification(data, id);
  };

  getUserList = () => {
    let data = {
      searchKey: this.state.searchKey,
      order: this.state.order,
      perPage: this.state.perPage,
      page: this.state.page,
    };
    this.props.getAnnouncementList(data);
  };

  // //page change
  changePage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getUserList();
    });
  };

  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ order: data.value }, () => {
      this.getUserList();
    });
  };
  //search
  onSearchList = (abc) => {
    // this.setState({ searchKey: abc, page: 1 }, () => {
    this.getUserList();
    // });
  };
  openModel = (data) => {
    // console.log("detail", data);
    this.setState({ getDetailsId: data, open: true });
  };

  render() {
    let { handleSubmit } = this.props;

    if (this.props.user.role == 2) {
      // console.log('permissions >>',this.props.permissions)
      var isfound = false;
      // check user Permissions if route accessible
      this.props.permissions.forEach((element) => {
        // if (element.title == "Withdrawal Management") {
        if (element.title == "Banner Management") {
          isfound = true;
        }
      });
      if (!isfound) {
        // this.props.history.push("/");
      }
    }
    return (
      <div>
        <CustomHeader />
        <div>
          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div style={{ width: "100%" }}>
                      <h3 className="mb-2">Announcement List</h3>
                    </div>
                    <div className="selectSearchOption">
                      <Input
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13) {
                            this.onSearchList();
                          }
                        }}
                        onChange={(e) => {
                          this.setState(
                            { searchKey: e.target.value, page: 1 },
                            () => {
                              if (this.state.searchKey == "") {
                                this.onSearchList();
                              }
                            }
                          );
                        }}
                      ></Input>
                      <Button
                        color="success btnMamagePermission"
                        onClick={() => this.onSearchList()}
                      >
                        Search
                      </Button>
                      <Dropdown
                        className="ml-2"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.filterBy(e, data)}
                      />
                      <Button
                        color="default btnMamagePermission"
                        onClick={() => this.addNewAnnouncement()}
                      >
                        + Add Announcement
                      </Button>
                      {this.props.user.role !== 2 && (
                        <Link to="banner-management">
                          <Button color="success btnMamagePermission">
                            Banner Management
                          </Button>
                        </Link>
                      )}
                      {this.props.user.role === 2 && isfound && (
                        <Link to="banner-management">
                          <Button color="success btnMamagePermission">
                            Banner Management
                          </Button>
                        </Link>
                      )}
                      {/* {this.props.user.role === 2 &&
                        this.props.permissions.forEach((element) => {
                          if (element.title == "Transaction Management") {
                            return (
                              <Link to="banner-management">
                                <Button color="success btnMamagePermission">
                                  Banner Management
                                </Button>
                              </Link>
                            );
                          }
                        })} */}
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Subject</th>
                        <th scope="col">Message</th>
                        <th scope="col">Image</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.getAnnounceList.map((usersList, index) => (
                        <tr className="table-tr" key={index}>
                          <td>{usersList.subject.slice(0, 20)}</td>
                          <td>{usersList.message.slice(0, 20)}...</td>
                          <td>
                            {usersList?.image ? (
                              <img
                                src={`${IMAGE_PATH}${usersList?.image}`}
                                alt=""
                                style={{ height: 40, width: 40 }}
                              />
                            ) : (
                              "None"
                            )}
                          </td>
                          <td className="text-left">
                            <span className="mb-0 text-sm">
                              {" "}
                              <Button
                                color="success"
                                onClick={() => this.openModel(usersList)}
                              >
                                View
                              </Button>
                              <Button
                                color="danger"
                                onClick={() =>
                                  this.deleteAnnouncement(usersList.id)
                                }
                              >
                                Delete
                              </Button>
                            </span>
                            <span className="mb-0 text-sm">
                              {" "}
                              <Button
                                className="mx-2"
                                color="success"
                                onClick={() =>
                                  this.updateAnnouncement(usersList)
                                }
                              >
                                Update
                              </Button>
                            </span>
                            <span className="mb-0 text-sm">
                              {" "}
                              <Button
                                color="success"
                                onClick={() =>
                                  this.sendAnnouncement(usersList.id)
                                }
                              >
                                Send
                              </Button>
                            </span>
                          </td>
                        </tr>
                      ))}
                      {this.props.getAnnounceList.length == 0 && (
                        <td>No record</td>
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      {this.props.announceRecords > 0 && (
                        <Pagination
                          onPageChange={(e, data) => this.changePage(e, data)}
                          boundaryRange={0}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            this.props.announceRecords / this.state.perPage
                          )}
                        />
                      )}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        <div>
          <Modal isOpen={this.state.open} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>More..</ModalHeader>
            <ModalBody>
              <div className="userNameList">
                <div className="itemStyle">
                  <label
                    style={{ color: "black", fontWeight: "bold", fontSize: 14 }}
                  >
                    {" "}
                    Subject:<p>{this.state.getDetailsId.subject}</p>
                  </label>
                </div>
                <div className="itemStyle">
                  <label
                    style={{ color: "black", fontWeight: "bold", fontSize: 14 }}
                  >
                    Message:<p>{this.state.getDetailsId.message} </p>
                  </label>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log("@@@@@@>>>>>>>ritu.", state);
  return {
    getAnnounceList: state.announcement?.getAnnounceList,
    announceRecords: state.announcement?.announceRecords,
    user: state.user?.user,
    permissions: state.subAdmin?.sideBarSubAdminPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAnnouncementList: (data) => dispatch(getAnnouncementList(data)),
    getAnnouncementDetails: (id) => dispatch(getAnnouncementDetails(id)),
    deleteAnnouncement: (data, id) => dispatch(deleteAnnouncement(data, id)),
    saveAnnounceEditDetails: (data) => dispatch(saveAnnounceEditDetails(data)),
    sendAnnouncementNotification: (data, id) =>
      dispatch(sendAnnouncementNotification(data, id)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({ form: "AnnouncementListManagement" })(
      AnnouncementListManagement
    )
  )
);
