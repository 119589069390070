import { actionTypes } from "../actons/announcement.action";

const initialState = {
  getAnnounceList: [],
  announceRecords: "",
  getDetailsId: {},
  editDetails: {}
};

const announcement = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_ANNOUNCE_DETAILS:
      return {
        ...state,
        getAnnounceList: action.data
      };

    case actionTypes.TOTAL_ANNOUNCE_RECORDS:
      return {
        ...state,
        announceRecords: action.data
      };

    case actionTypes.GET_ANNOUNCE_DETAIL:
      return {
        ...state,
        getDetailsId: action.data
      };
    case actionTypes.SAVE_EDIT_DETAIL:
      return {
        ...state,
        editDetails: action.data
      };
    default:
      return state;
  }
};

export default announcement;
