import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardHeader,
} from "reactstrap";
import {
  getInterestList,
  deleteInterestPair,
} from "../../Redux/actons/interest.action";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import Header from "components/Headers/Header";
import { withRouter } from "react-router-dom";
import { Confirm } from "semantic-ui-react";

export class InterestManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      perPage: "10",
      page: "1",
      order: "",
      sortBy: "",
      openConfirm: false,
      itemId: "",
    };
  }
  componentDidMount = () => {
    this.getInterestList();
  };

  getInterestList = () => {
    this.props.getInterestList();
  };

  onEditPair = (id, data) => {
    console.log(this.props.history);
    this.props.history.push({
      pathname: `edit-interest/${id}`,
      state: data,
    });
  };

  openDeleteModal = (id) => {
    this.setState({
      openConfirm: true,
      itemId: id,
    });
  };

  close = () => {
    this.setState({ openConfirm: false });
  };

  onConfirmation = () => {
    this.props.deleteInterestPair(this.state.itemId);
    this.close();
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <div>
        <Header headerTitle="Interest Management" />
        <div>
          <Confirm
            content={`Are you sure to delete this ?`}
            style={{
              height: "auto",
              top: "5%",
              left: "auto",
              bottom: "auto",
              right: "auto",
            }}
            size="small"
            className="confirm-model"
            open={this.state.openConfirm}
            onCancel={this.close}
            onConfirm={this.onConfirmation}
          />
        </div>
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="selectSearchOption">
                      {/* <Dropdown
                        className="selectUserStyle"
                        options={options}
                        selection
                        value={this.state.order}
                        onChange={(e, data) => this.sortBy(e, data)}
                      /> */}

                      <Link to="add-interest">
                        <Button color="success btnMamagePermission">
                          Add Interest
                        </Button>
                      </Link>
                      <Link to="interest-user-list">
                        <Button color="success btnMamagePermission">
                          History
                        </Button>
                      </Link>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Currency</th>
                        <th scope="col">Interest(%)</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.currencyList.length === 0 ? (
                        <tr>
                          <td>No results found</td>
                        </tr>
                      ) : (
                        this.props.currencyList.map((data, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{data.coin_symbol.toUpperCase()}</td>
                            <td>{data.interest_rate}</td>
                            <td>
                              <Button
                                color="primary"
                                onClick={() =>
                                  this.onEditPair(data.coin_id, data.coin_id)
                                }
                              >
                                Edit Interest
                              </Button>
                            </td>
                            <td>
                              <Button
                                color="danger"
                                onClick={() =>
                                  this.openDeleteModal(data.coin_id)
                                }
                              >
                                Remove
                              </Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    currencyList: state.currencyInterest.currencyList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInterestList: () => dispatch(getInterestList()),
    deleteInterestPair: (data) => dispatch(deleteInterestPair(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InterestManagement)
);
