import { fetch } from "./Fetch";
import { API_HOST, API_LOCAL_HOST } from "../constants/index";

const getFundList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/userfundlist`,
    {},
    options,
    params
  );
};
const getUserFundListView = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/userFundView`,
    {},
    options,
    params
  );
};
const getUserFunds = (query, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/getUserFund/${query.coin_id}/${query.user_id}`,
    {},
    options
  );
};

const getUniqueUserBalance = (query, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/userCoinHistory/${query.coin_id}/${query.user_id}?page=${query.page}&perPage=${query.perPage}`,
    {},
    options
  );
};

const setUserFunds = (data, options) => {
  return fetch(
    "put",
    `${API_HOST}api/v1/admin/users/setUserFund`,
    data,
    options
  );
};

const lockUserFunds = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/lock-fund/lockFund/${data.userId}`,
    data,
    options
  );
};

const unLockUserFunds = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/lock-fund/unLockFund/${data.id}`,
    data,
    options
  );
};
const unLockUserFundsVesting = (data, options) => {
  console.log("data", data);
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/lock-fund/release_token`,
    data,
    options
  );
};

const getFundUser = (data, options) => {
  return fetch("get", `${API_HOST}api/v1/admin/users/fundUsers`, {}, options);
};
const getHotWalletBalance = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/hotWalletBalance`,
    {},
    options,
    data
  );
};
const getAdminHotWalletBalance = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/adminHotWalletBalanceByCoinId/${data.coin_id}`,
    {},
    options
  );
};

const getUserBalance = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/userbalance/${data.coin_id}?perPage=${data.perPage}&page=${data.page}&searchKey=${data.searchKey}`,
    data,
    options
  );
};

const sendToColdWallets = (coin, data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/users/sendToColdWallet/${coin}`,
    data,
    options
  );
};

const getColdWalletHistory = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/coldWalletTranasctionList`,
    {},
    options,
    params
  );
};
const getFundHistory = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/users/setFund/History`,
    {},
    options,
    params
  );
};

const getLockFundList = (params, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/lock-fund/time-plan-list`,
    {},
    options,
    params
  );
};

const enableDisableLock = (data, id, token) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/lock-fund/updateStatus/${id}`,
    data,
    token
  );
};

const addNewPlan = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/lock-fund/add-new-plan`,
    data,
    token
  );
};
const addFee = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/api/v1/admin/instalment/add_fee`,
    data,
    token
  );
};

const lockFundHistory = (data, token) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/lock-fund/fund-list?page=${data.page}&perPage=${data.perPage}&searchKey=${data.searchKey}&user_id=${data.userId}&locked_by=${data.locked_by}&status=${data.status}`,
    data,
    token
  );
};
const verifyGoogleAuth = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/coldWalletGoogle2faVerify`,
    data,
    options
  );
};
const addFundsFile = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/airdrop/upload_csv`,
    data,
    options
  );
};

const getFundsFileList = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/airdrop/csv_list`,
    data,
    options
  );
};

const getSampleFileList = (options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/airdrop/sample_files`,
    {},
    options
  );
};

const processFile = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/airdrop/read_file`,
    data,
    options
  );
};

const deleteFile = (data, options) => {
  return fetch(
    "post",
    `${API_HOST}api/v1/admin/airdrop/delete_file`,
    data,
    options
  );
};

const downloadSampleFile = (data, options) => {
  return fetch(
    "get",
    `${API_HOST}api/v1/admin/airdrop/download_log_file/${data}`,
    {},
    options
  );
};

export const FundService = {
  getFundList,
  getUserFundListView,
  getUserFunds,
  setUserFunds,
  getFundUser,
  getHotWalletBalance,
  getAdminHotWalletBalance,
  sendToColdWallets,
  getColdWalletHistory,
  getFundHistory,
  getUserBalance,
  getUniqueUserBalance,
  lockUserFunds,
  unLockUserFunds,
  unLockUserFundsVesting,
  getLockFundList,
  enableDisableLock,
  addNewPlan,
  addFee,
  lockFundHistory,
  verifyGoogleAuth,
  addFundsFile,
  getFundsFileList,
  getSampleFileList,
  processFile,
  deleteFile,
  downloadSampleFile,
};
