import { addValidator } from "redux-form-validators";

export var matchPasswords = addValidator({
  validator: function (options, value, allValues) {
    if (allValues.new_password != value) {
      return {
        defaultMessage: "Password not matched",
      };
    }
  },
});
export var matchPin = addValidator({
  validator: function (options, value, allValues) {
    if (allValues.new_pin != value) {
      return {
        defaultMessage: "Pin not matched",
      };
    }
  },
});
export var matchPassword = addValidator({
  validator: function (options, value, allValues) {
    if (value && allValues.new_password) {
      if (allValues.new_password != value) {
        return {
          defaultMessage: "Password not matched",
        };
      }
    }
  },
});

// export var newPassValidator = addValidator({
//   validator: function(options, value, allValues) {
//     if (allValues.new_password === value) {
//       return {
//         defaultMessage: "cannot enter old password"
//       };
//     }
//   }
// });

export var phoneNumber = addValidator({
  validator: function (options, value, allValues) {
    if (!allValues.mobileno.match(/^(0|[1-9][0-9]{9})$/)) {
      return {
        defaultMessage: "Invalid phone number, must be 10 digits",
      };
    }
  },
});
export var passwordValidator = addValidator({
  validator: function (options, value, allValues) {
    if (
      !value.match(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$"
      )
    ) {
      return {
        defaultMessage:
          "Password should have one uppercase, one lowercase, one number, one special character, minimum 8 characters",
      };
    }
  },
});

export var confirmNewValidator = addValidator({
  validator: function (options, value, allValues) {
    if (
      !allValues.confirm_new_password.match(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$"
      )
    ) {
      return {
        defaultMessage:
          "Password should have one uppercase, one lowercase, one number, one special character, minimum 8 characters",
      };
    }
  },
});

export var resetValidator = addValidator({
  validator: function (options, value, allValues) {
    if (
      !allValues.new_password.match(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$"
      )
    ) {
      return {
        defaultMessage:
          "Password should have one uppercase, one lowercase, one number, one special character, minimum 8 characters",
      };
    }
  },
});
export var zipcpde = addValidator({
  validator: function (options, value, allValues) {
    if (!allValues.zip.match(/^(0|[1-9][0-9]{6})$/)) {
      return {
        defaultMessage: "Invalid zip code, must be 7 digits",
      };
    }
  },
});
export var pinTypeCheck = addValidator({
  validator: function (options, value, allValues) {
    if (!allValues.new_pin.match(/^(0|[0-9][0-9]{3})$/)) {
      return {
        defaultMessage: "Invalid Pin, must be 4 digits",
      };
    }
  },
});
export var amountLimit = addValidator({
  validator: function (options, value, allValues) {
    if (value && isNaN(Number(value))) {
      return value;
    }
  },
});
export var invalidMinusValue = addValidator({
  validator: function (options, value, allValues) {
    if (value < 0) {
      return {
        defaultMessage: "Invalid value",
      };
    }
  },
});
export var amountValidator = addValidator({
  validator: function (options, value, allValues) {
    var regex = /^[0-9]+\.?[0-9]*$/;

    //
    if (!value.match(regex)) {
      return {
        defaultMessage: "Invalid amount",
      };
    }
  },
});

export var urlValidator = addValidator({
  validator: function (options, value, allValues) {
    // var regex = /^[\w\-\s]+$/;
    var regex = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

    if (!value.match(regex)) {
      return {
        defaultMessage: "Invalid Url",
      };
    }
  },
});

export var checkPlaceholder = addValidator({
  validator: function (options, value, allValues) {
    if (allValues.role == -1) {
      return {
        defaultMessage: "This field is required",
      };
    }
  },
});
export var fourDigitsAfterDecimal = addValidator({
  validator: function (options, value, allValues) {
    var regex = /^\d{0,20}(\.\d{0,4})?$/;
    if (!value.match(regex) && value !== "") {
      return {
        defaultMessage: "Invalid ! Only six digits allowed after decimal",
      };
    }
  },
});

export var maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};