import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { addMinMaxAmount } from "../../Redux/actons/pairCoin.action";
import { Form } from "semantic-ui-react";
import { Field, reduxForm, change } from "redux-form";
import { FormField } from "../../components/FormField";
import { required } from "redux-form-validators";
import { invalidMinusValue } from "../../validators/customValidator";
import { SelectField } from "../../components/SelectField";

const Type = [
  { key: "m", text: "Worth of fiat", value: "worth_of_fiat" },
  { key: "k", text: "Actual Crypto", value: "actual_crypto" },
];

export class SetPairAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit_type: "actual_crypto",
    };
  }
  componentWillReceiveProps = async (nextProps) => {
    // console.log('Pairs >>>>>>',this.props);
    // console.log('Pairs >>>>>>',nextProps);
    if (
      this.props.min_sell_value !== nextProps.min_sell_value ||
      this.props.max_sell_value !== nextProps.max_sell_value
    ) {
      await nextProps.dispatch(
        change("SetPairAmount", "min_sell_value", nextProps?.min_sell_value)
      );
      await nextProps.dispatch(
        change("SetPairAmount", "max_sell_value", nextProps?.max_sell_value)
      );
    }

    if (this.props.type != null || this.props.type != "") {
      await nextProps.dispatch(
        change("SetPairAmount", "type", nextProps?.type)
      );
      this.setState({
        limit_type: nextProps?.type != "" ? nextProps?.type : "actual_crypto",
      });
    }
  };
  // componentDidUpdate = async () => {
  //   console.log('Pairs >>>>>>',this.props);
  //   await this.props.dispatch(change("SetPairAmount","min_sell_value",this.props?.min_sell_value));
  //   await this.props.dispatch(change("SetPairAmount","max_sell_value",this.props?.max_sell_value));
  // };
  updateForm = (values) => {
    // console.log('Pairs 333 >>>',values);
    if (
      +values.min_sell_value >= +values.max_sell_value &&
      (+values.min_sell_value !== 0 || +values.max_sell_value !== 0)
    ) {
      alert("Minimum value must be less than maximum value");
      return false;
    }
    let data = {
      pair_id: this.props.selectPairId,
      min_sell_value: parseFloat(values.min_sell_value),
      max_sell_value: parseFloat(values.max_sell_value),
      limit_type: this.state.limit_type,
      // min_buy_value:values.min_buy_value,
      // max_buy_value:values.max_buy_value,
    };
    let data2 = {
      searchKey: this.props.searchKey,
      order: "desc",
      perPage: this.props.perPage,
      page: this.props.page,
    };

    this.props.addMinMaxAmount(data, data2);
    this.setState({ limit_type: "" });
    this.props.closeSetPairAmountModal();
  };

  render() {
    // console.log("this.props222 >> ", this.props);
    // console.log("this.state >> ", this.state);

    let { handleSubmit } = this.props;
    return (
      <div>
        {/* <Button color="danger">X</Button> */}

        <Modal
          isOpen={this.props?.setPairAmountModal}
          toggle={this.props.closeSetPairAmountModal}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.closeSetPairAmountModal}>
            <h1>
              <span>Set Minimum and Maximum for pairs</span>
            </h1>
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.updateForm)}>
              <Form.Group>
                <Form.Field>
                  <label>Min Sell Value</label>
                  <Field
                    placeholder={`${this.props.coin_1_symbol.toUpperCase()}`}
                    name="min_sell_value"
                    min="0"
                    // value={this.state.fee}
                    normalize={(value) => {
                      if (value > 0) {
                        return value;
                      } else {
                        return 0;
                      }
                    }}
                    component={FormField}
                    type="number"
                    validate={[required(), invalidMinusValue()]}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Max Sell Value</label>
                  <Field
                    placeholder={`${this.props.coin_1_symbol.toUpperCase()}`}
                    name="max_sell_value"
                    min="0"
                    // value={this.state.fee}
                    normalize={(value) => {
                      if (value > 0) {
                        return value;
                      } else {
                        return 0;
                      }
                    }}
                    component={FormField}
                    type="number"
                    validate={[required(), invalidMinusValue()]}
                  />
                </Form.Field>
                <Form.Group>
                  <Form.Field>
                    <label>Type</label>
                    <Field
                      name="type"
                      search={false}
                      selectOnBlur={false}
                      // validate={[required()]}
                      component={SelectField}
                      defaultValue={this.state.limit_type}
                      children={Type}
                      placeholder="Select"
                      onChange={(e, data) =>
                        this.setState({ limit_type: data })
                      }
                    />
                  </Form.Field>
                </Form.Group>
                {/* <Form.Field>
                        <label>Min Buy Value</label>
                        <Field
                        placeholder="Min Buy Value"
                        name="min_buy_value"
                        min="0"
                        // value={this.state.fee}
                        component={FormField}
                        type="number"
                        validate={[required()]}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Max Buy Value</label>
                        <Field
                        placeholder="Max Buy Value"
                        name="max_buy_value"
                        min="0"
                        // value={this.state.fee}
                        component={FormField}
                        type="number"
                        validate={[required()]}
                        />
                    </Form.Field> */}
              </Form.Group>
              <Form.Field className="addBtn">
                <Button type="submit" color="success">
                  Submit
                </Button>
              </Form.Field>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    addMinMaxAmount: (data, data2) => dispatch(addMinMaxAmount(data, data2)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "SetPairAmount" })(SetPairAmount));
