import { actionTypes } from "../actons/reward.action";

const initialState = {
  rewardList: [],
  scratchList: [],
  incentiveList: [],
  rewardHistoryList: [],
  scratchHistoryList: [],
};

const rewardList = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REWARD_LIST:
      return {
        ...state,
        rewardList: action.data.data,
      };
    case actionTypes.SCRATCH_LIST:
      return {
        ...state,
        scratchList: action.data.data,
      };
    case actionTypes.INCENTIVE_LIST:
      return {
        ...state,
        incentiveList: action.data.data,
      };
    case actionTypes.REWARD_HISTORY:
      return {
        ...state,
        rewardHistoryList: action.data.data,
      };
    case actionTypes.SCRATCH_HISTORY:
      return {
        ...state,
        scratchHistoryList: action.data.data,
      };

    default:
      return state;
  }
};

export default rewardList;
