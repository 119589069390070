import React, { Component } from "react";
import CustomHeader from "components/Headers/CustomHeader";
import { Card, Table, Container, Row, Button, Input } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";

import { getCoinList } from "../../Redux/actons/user.action";
import { uploadImage } from "../../Redux/actons/account.action";
import { toast } from "../../components/Toast/Toast";
import { IMAGE_PATH } from "../../constants/index";

const actions = [
  { key: "m", text: "Activate", value: 1 },
  { key: "f", text: "Inactivate", value: 2 },
];
export class LogoManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCoin: false,
      defaultType: "",
      defaultAction: 1,
    };
  }

  componentDidMount = () => {
    this.props.getCoinList();
  };

  fileChange = (e, coin) => {
    console.log("COIN", coin);
    let fileSize = e.target.files[0].size / 1024 / 1024; //result in mb

    console.log("FILE", fileSize);
    if (fileSize <= 1) {
      if (e.target.value.length > 0) {
        if (
          e.target.files[0].type === "image/png" ||
          e.target.files[0].type === "image/jpg" ||
          e.target.files[0].type === "image/jpeg"
        ) {
          const formData = new FormData();
          formData.append("image", e.target.files[0]);
          this.props.uploadImage(formData, coin);
        } else {
          toast.error("Only pdf,png and jpeg file required");
        }
      }
    } else {
      toast.error("File size should be less than equals to 1MB.");
    }
  };

  getDetails = (e, data) => {};

  updateForm = (values) => {
    console.log("PRICE", values);
  };

  render() {
    let { handleSubmit } = this.props;
    // console.log("coinLIst",this.props.coinsList)
    return (
      <div>
        <CustomHeader />
        <div style={{ padding: 30 }}>
          {/* <Form onSubmit={handleSubmit(this.updateForm)}>
            <Form.Group>
              <Form.Field>
                <label>Choose Coin</label>
                <Field
                  name="coin_id"
                  search={false}
                  selectOnBlur={false}
                  validate={[required()]}
                  component={SelectField}
                  defaultValue={this.state.defaultType}
                  children={this.props.coinsList}
                  placeholder="Select"
                  onChange={(e, data) => this.getDetails(e,9 data)}
                ></Field>
              </Form.Field>
              <Input type="file" onChange={this.fileChange} />
            </Form.Group>

            {this.state.isCoin && (
              <Form.Field className="addBtn">
                <Button type="submit">Submit</Button>
              </Form.Field>
            )}
          </Form> */}

          <Container className="contentBlock" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Coin Name</th>
                        <th scope="col">Coin Symbol</th>
                        <th scope="col">Coin Logo</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.coinsList.map((coin, index) => (
                        <tr className="table-tr">
                          <td>{coin.coin_name}</td>
                          <td>{coin.coin_symbol.toUpperCase()}</td>
                          <td>
                            {coin.coin_image ? (
                              <img
                                alt="logo"
                                onClick={() =>
                                  window.open(
                                    `${IMAGE_PATH}${coin.coin_image}`,
                                    "_blank"
                                  )
                                }
                                style={{ height: 80, width: 80 }}
                                src={`${IMAGE_PATH}${coin.coin_image}`}
                              ></img>
                            ) : (
                              "None"
                            )}
                          </td>
                          <td>
                            {" "}
                            <span className="mb-0 text-sm">
                              <Button color="success">
                                {" "}
                                <Input
                                  type="file"
                                  onChange={(e) => this.fileChange(e, coin)}
                                />
                              </Button>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    feeSettingDetails: state.account.feeSettingDetails,
    coinsList: state.user.LogoCoins,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCoinList: () => dispatch(getCoinList()),
    uploadImage: (data, coin) => dispatch(uploadImage(data, coin)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "LogoManagementForm" })(LogoManagement));
