import { WithdrawServices } from "../../Services/WithdrawServices";
import { startLoading, stopLoading } from "./loading.action";

import { toast } from "../../components/Toast/Toast";
import { DownLoadCSVFunction } from "./installment.action";
export const actionTypes = {
  GET_WITHDRAW_LIST: "GET_WITHDRAW_LIST",
  GET_TOTAL_BALANCE: "GET_TOTAL_BALANCE",
};

/*
 * Select/change Security option
 */
export function saveWithdrawList(payload) {
  return {
    type: actionTypes.GET_WITHDRAW_LIST,
    data: payload,
  };
}

export function saveTotalBalance(payload) {
  return {
    type: actionTypes.GET_TOTAL_BALANCE,
    data: payload,
  };
}

export function getAllWithdrawListCSV(data, fdata) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return WithdrawServices.getWithdrawList(data, fdata, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (data) => {
        dispatch(stopLoading());
        let arrayOfObjects = data?.data?.data;
        if (!arrayOfObjects?.length) {
          return;
        }
        DownLoadCSVFunction(arrayOfObjects, "CSV_download_withdraw_list");
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
export function getAllWithdrawList(data, fdata) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return WithdrawServices.getWithdrawList(data, fdata, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveWithdrawList(res.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function getUserTotalBalance(data) {
  return (dispatch, getState) => {
    dispatch(startLoading());
    let state = getState();
    return WithdrawServices.getTotalBalance(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        dispatch(saveTotalBalance(res.data));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function approveDisapproveRequest(data, data1, data2) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return WithdrawServices.approveDisapproveRequest(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(getAllWithdrawList(data1, data2));
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}

export function withdrawComment(data) {
  return (dispatch, getState) => {
    if (data.searchKey == "") {
      dispatch(startLoading());
    }
    let state = getState();
    return WithdrawServices.withdrawComment(data, {
      jwt: state["persist"]["c_temp_user"]["token"],
    })
      .then(async (res) => {
        toast.success(res["data"]["message"]);
        dispatch(stopLoading());
      })
      .catch((error) => {
        if (error) {
          toast.error(error["data"]["message"]);
        }
        dispatch(stopLoading());
      });
  };
}
