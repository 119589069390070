import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import {
    setSwapFee
} from "../../Redux/actons/token.action";
import { Form } from "semantic-ui-react";
import { Field, reduxForm, change } from "redux-form";
import { FormField } from "../../components/FormField";
import { required } from "redux-form-validators";
// import { amountLimit } from "../../validators/customValidator";

export class SetSwapFee extends Component {
  constructor(props) {
    super(props);
  }
//   componentWillReceiveProps = (nextProps) => {
//       if(nextProps !== this.props){
//         //   alert('tedd');
//         this.setState({
//             fee: nextProps.swap_fee 
//         });
//       }
//   };
  componentWillReceiveProps = async (nextProps) => {
    if (this.props.swap_fee !== nextProps.swap_fee){
      await this.props.dispatch(change("SetSwapFee","fee",nextProps?.swap_fee));
    }
  };
  updateForm=(values)=>{
    if(values.fee==undefined && values.fee==""){
      alert("Please enter the required value");
      return false;
    }
    if(values.fee > 100 || values.fee < 0){
      alert("Swap fee must be greater than and equal to 0 and less than 100");
      return false;
    }
    let data = {
        id : this.props.swap_coin_id,
        fee: values.fee
    };
    let data2 = {
        search: this.props.search,
        order: this.props.order,
        perPage: this.props.perPage,
        page: this.props.page,
        operateBy:this.props.setOperateBy
      
    };
    this.props.setSwapFee(data, data2);
    this.props.closeSwapFeeModal();
  }
  setFeeValue(value){
    this.setState({ fee: value })
  }
  render() {
    // console.log('this.props222 >> ',this.props);
    // console.log('this.state >> ',this.state);

    let { handleSubmit } = this.props;
    return (
      <div>
        {/* <Button color="danger">X</Button> */}

        <Modal
          isOpen={this.props?.swapFeeModal}
          toggle={this.props.closeSwapFeeModal}
          // className={[this.props.className, "modalCustom"]}
        >
          <ModalHeader toggle={this.props.closeSwapFeeModal}>Set {this.props?.coin_name} Swap Fee</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.updateForm)}>
                <Form.Group>
                    <Form.Field>
                    <label>Fee(%)</label>
                    <Field
                      placeholder="Fee"
                      name="fee"
                     // value={this.state.fee}
                      component={FormField}
                      type="number"
                      validate={[required()]}
                    />
                    </Form.Field>
                    {/* <Form.Field>
                    <label>Fee</label>
                    <input
                        type="number"
                        name="fee"
                        value={this.state.fee}
                        validate={[required()]}
                        component={FormField}
                        onChange={(e, data) =>
                            this.setFeeValue(e.target.value)
                        }
                        ></input>
                    </Form.Field> */}
                </Form.Group>
                <Form.Field className="addBtn">
                    <Button type="submit" color="success">
                    Submit
                    </Button>
                </Form.Field>
            </Form>
          </ModalBody>
           
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
  };
const mapDispatchToProps  = (dispatch) => {
    return {
        setSwapFee: (data,data2) =>
          dispatch(setSwapFee(data,data2)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "SetSwapFee" })(SetSwapFee));
// export default connect(mapStateToProps, mapDispatchToProps)(SetWithdrawDailyLimit);
