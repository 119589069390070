import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Container } from "reactstrap";
import { Tab } from "semantic-ui-react";
import Header from "components/Headers/Header";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router";
import "../AccountSetting/AccountSetting.scss";
import {
  getTransactionList,
  sendfailureMessage,
  getTransactionDetails,
} from "../../Redux/actons/transaction.action";
import INRManagement from "views/INRManagement/INRManagement";
// import TransactionFeeManagement from "views/TransactionFeeManagement/TransactionFeeManagement";
// import TransactionFeeManagementWithdraw from "views/TransactionFeeManagement/TransactionFeeManagement";
const panes = [
  {
    menuItem: "Withdraw",
    render: () => (
      <Tab.Pane>
        {" "}
        <INRManagement statusType={"withdraw"} />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Deposit",
    render: () => (
      <Tab.Pane>
        <INRManagement statusType={"deposit"} />
      </Tab.Pane>
    ),
  },
];

export class TabDevideHandle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentUserId: "",
      open: false,
      openModel: false,
      trnx_type: [],
      status: [],
      coin_type: "",
      searchKey: "",
      perPage: "10",
      page: "1",
      from_date: "",
      to_date: "",
      message: "",
      detailModal: false,
      order: "desc",
      sortBy: "",
      coin_family: "",
      tabValue: "",
      filterType: [],
      dailyWithdrawLimitModal: false,
      gstModal: false,
      tdsModal: false,
      to_address_copied: false,
      from_address_copied: false,
      tx_id_copied: false,
      downloadExcel: false,
      filterData: {},
    };
  }

  render() {
    let { handleSubmit, txnDetails } = this.props;
    // console.log("tabValue ------>>>", this.state.tabValue);
    return (
      <div>
        <Header headerTitle="transaction management" />
        <div>
          <Container className="contentBlock" fluid>
            {/* Table */}
            <div
              className="col"
              style={{
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "5px",
              }}
            >
              <Card className="shadow">
                <Tab
                  panes={panes}
                  // onClick={(e) => {
                  //   this.setState({ tabValue: e.target });
                  // }}
                  // renderActiveOnly={false}
                />
              </Card>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    totalTransRecords: state.transaction.totalTransRecords,
    queryListRecords: state.transaction.queryListRecords,
    getTransFeeList: state.transaction.getTransFeeList,
    txnDetails: state.transaction.txnDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionList: (filterType, data) =>
      dispatch(getTransactionList(filterType, data)),
    sendfailureMessage: (data) => dispatch(sendfailureMessage(data)),
    getTransactionDetails: (data) => dispatch(getTransactionDetails(data)),
    // userDetail: (id) => dispatch(userDetail(id)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "ApprovedKyc" })(TabDevideHandle))
);
