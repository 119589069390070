import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  Button,
  Label,
  FormGroup,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { API_HOST } from "../../constants/index";
import {
  Pagination,
  Confirm,
  Dropdown,
  Menu,
  Header,
  GridColumn,
  Grid,
} from "semantic-ui-react";
import "../UserManagement/User/UserManagement.scss";
import CustomHeader from "../../components/Headers/CustomHeader";
import { withRouter } from "react-router";
import {
  getAllSubAdmin,
  removeSubAdmin,
  enableDisableSubAdmin,
  getAdminPermissions,
  assignAdminPermissions,
} from "../../Redux/actons/SubAdmin.action";
import { listDateFormat } from "../../utils/utils";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { FormField } from "../../components/FormField";
import Security from "./AddNewSubAdmin/Security";
import { DashboardResult } from "views/ContactQueryManagement/SubjectManagement/DashboardResult";
const options = [
  { key: "m", text: "Ascending", value: "asc" },
  { key: "k", text: "Descending", value: "desc" },
];
export class SubAdminManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      searchKey: "",
      perPage: "10",
      page: "1",
      sort: "asc",
      openConfirm: false,
      removeId: "",
      changePermissionId: "",
      open: false,
    };
  }

  componentDidMount = () => {
    this.props.getAdminPermissions();
    this.getAllSubAdmin();
  };
  toggle = () => {
    this.setState({ open: !this.state.open });
  };
  changePermission = (id, permissions) => {
    console.log("permissions ---->> pppp", permissions);
    this.setState({
      changePermissionId: id,
      //   permissions: permissions != null ? permissions : [],
      open: true,
    });
    let data = [];
    if (permissions !== null) {
      this.props.permissions.forEach((element, index) => {
        if (permissions.includes(element.id)) {
          data.push(element.id);
        } else {
          data.push(0);
        }
      });
    }
    console.log("hey", data);
    this.setState({ permissions: data });
  };
  filterBy = (e, data) => {
    console.log(data.value);
    this.setState({ sort: data.value }, () => {
      this.getAllSubAdmin();
    });
  };

  downLoadUserList = () => {
    window.location.href = `${API_HOST}api/v1/subadmin/download/list`;
  };

  getAllSubAdmin = () => {
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      sort: this.state.sort,
    };
    this.props.getAllSubAdmin(data);
  };

  //page change
  changeApprovedPage = (e, data) => {
    this.setState({ page: data.activePage }, () => {
      this.getAllSubAdmin();
    });
  };
  //search
  onSearchWithdraw = (abc) => {
    this.setState({ searchKey: abc, page: 1 }, () => {
      this.getAllSubAdmin();
    });
  };
  openConfirmApproveModal = (id) => {
    this.setState({ openConfirm: true, removeId: id });
  };
  close = () => {
    this.setState({ openConfirm: false });
  };
  onConfirmation = () => {
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      sort: this.state.sort,
    };
    this.props.removeSubAdmin(data, this.state.removeId);
    this.setState({ openConfirm: false });
  };
  disableSubAdmin = (id, status) => {
    let data = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      sort: this.state.sort,
    };
    let aPidata = {
      action: status == 1 ? 2 : 1,
    };

    this.props.enableDisableSubAdmin(aPidata, data, id);
  };
  onSelectPermissions = (e, id, index) => {
    let permissions = [...this.state.permissions];
    // console.log("permissions ---->>", permissions);
    if (e.target.checked) {
      permissions[index] = id;
      this.setState({ permissions: permissions });
    } else {
      permissions[index] = -1;
      this.setState({ permissions: permissions });
    }
    console.log("permissions ---->>", permissions);
  };
  addSubAdmin = (values) => {
    let data = {
      permissions: this.state.permissions.filter((data) => data !== -1),
    };
    let reqData = {
      searchKey: this.state.searchKey,
      perPage: this.state.perPage,
      page: this.state.page,
      sort: this.state.sort,
    };
    this.props.assignAdminPermissions(
      data,
      this.state.changePermissionId,
      reqData
    );

    this.setState({ open: false });
  };

  render() {
    let { handleSubmit, dashboard } = this.props;
    console.log("props", dashboard);

    return (
      <div>
        {/* approve disaprove confirm */}
        <div>
          <Security />

          <Modal isOpen={this.state.openConfirm} toggle={this.close}>
            <ModalHeader toggle={this.close}>
              Are you sure you want to delete the record ?
            </ModalHeader>
            <ModalBody></ModalBody>
            <ModalFooter>
              <Button color="error" onClick={() => this.close()}>
                Cancel
              </Button>
              <Button color="success" onClick={() => this.onConfirmation()}>
                Confirm
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <CustomHeader />
        {!this.props.openSecurityModal && (
          <div>
            <Container className="contentBlock" fluid>
              {/* Table */}
              <div
                className="col"
                style={{
                  width: "100%",
                  paddingRight: 0,
                  paddingLeft: 0,
                  paddingBottom: "5px",
                }}
              >
                <Card className="shadow">
                  <Grid.Row
                    columns={2}
                    // style={{ paddingTop: "10px", paddingBottom: "10px" }}
                  >
                    <GridColumn className="maxWidth">
                      <DashboardResult
                        heading={"Total List"}
                        labResult={
                          dashboard?.totalList ? dashboard?.totalList - 1 : "0"
                        }
                      />
                    </GridColumn>
                    <GridColumn className="maxWidth">
                      <DashboardResult
                        heading={"Total Active"}
                        labResult={
                          dashboard?.activeList
                            ? dashboard?.activeList - 1
                            : "0"
                        }
                      />
                    </GridColumn>
                    <GridColumn className="maxWidth">
                      <DashboardResult
                        heading={"Total Inactive"}
                        labResult={
                          dashboard?.totalList
                            ? dashboard?.totalList - dashboard?.activeList
                            : "0"
                        }
                      />
                    </GridColumn>
                  </Grid.Row>
                </Card>
              </div>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div className="selectSearchOption">
                        {/* <Input
                          placeholder="Search"
                          onChange={e =>
                            this.onSearchWithdraw(`${e.target.value}`)
                          }
                        ></Input>
                        <Dropdown
                          options={options}
                          selection
                          value={this.state.sort}
                          onChange={(e, data) => this.filterBy(e, data)}
                        />
                        <Button
                          color="success btnMamagePermission"
                          onClick={() => this.downLoadUserList()}
                        >
                          Download SubAdmin list
                        </Button> */}
                        <Link to="add-sub-admin">
                          <Button color="default btnMamagePermission">
                            + Add New SubAdmin
                          </Button>
                        </Link>
                      </div>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">Added On</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.subAdminList.map((usersList, index) => (
                          <tr className="table-tr" key={index}>
                            <td>{usersList.username}</td>
                            <td>{usersList.email}</td>
                            <td>
                              {usersList.mobile_no
                                ? usersList.mobile_no
                                : "None"}
                            </td>

                            <td>{listDateFormat(usersList.created_at)}</td>
                            <td>
                              <span className="mb-0 text-sm">
                                <Button
                                  color="danger"
                                  onClick={() =>
                                    this.openConfirmApproveModal(usersList.id)
                                  }
                                >
                                  Delete
                                </Button>
                              </span>
                              <span
                                className="mb-0 text-sm"
                                style={{ marginLeft: "10px" }}
                              >
                                <Button
                                  // color="success"

                                  color={
                                    usersList.status === 1
                                      ? "danger"
                                      : "success"
                                  }
                                  onClick={() =>
                                    this.disableSubAdmin(
                                      usersList.id,
                                      usersList.status
                                    )
                                  }
                                >
                                  {usersList.status === 1
                                    ? "Disable"
                                    : "Enable"}
                                </Button>
                              </span>
                              <span
                                className="mb-0 text-sm"
                                style={{ marginLeft: "10px" }}
                              >
                                <Button
                                  color="success"
                                  onClick={() =>
                                    this.changePermission(
                                      usersList.id,
                                      JSON.parse(usersList.permissions)
                                    )
                                  }
                                >
                                  Change permissions
                                </Button>
                              </span>
                              <span
                                className="mb-0 text-sm"
                                style={{ marginLeft: "10px" }}
                              >
                                {/* <Link to="log-reports" params = {{userId: usersList.id}}> */}

                                <Link
                                  to={{
                                    pathname: "log-reports",
                                    state: usersList.id,
                                  }}
                                >
                                  <Button
                                    color="success"
                                    // onClick={() =>
                                    //   this.changePermission(
                                    //     usersList.id,
                                    //     JSON.parse(usersList.permissions)
                                    //   )
                                    // }
                                  >
                                    Show log reports
                                  </Button>
                                </Link>
                              </span>
                            </td>
                          </tr>
                        ))}
                        {this.props.subAdminList.length == 0 && (
                          <td>No record</td>
                        )}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        {this.props.totalRecords > 0 && (
                          <Pagination
                            onPageChange={(e, data) =>
                              this.changeApprovedPage(e, data)
                            }
                            boundaryRange={1}
                            defaultActivePage={1}
                            ellipsisItem={true}
                            firstItem={true}
                            lastItem={true}
                            siblingRange={1}
                            totalPages={Math.ceil(
                              this.props.totalRecords / this.state.perPage
                            )}
                          />
                        )}
                      </nav>
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            </Container>
          </div>
        )}
        {/* change permisiion modal */}
        <Modal isOpen={this.state.open} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>ASSIGN PERMISSIONS</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.addSubAdmin)}>
              {this.props.permissions.length > 0 && (
                <FormGroup>
                  {this.props.permissions.map((data, index) => (
                    <div>
                      <Field
                        name={data.title}
                        component={FormField}
                        type="checkbox"
                        label={data.title.toUpperCase()}
                        checked={
                          this.state.permissions[index] !== undefined &&
                          this.state.permissions[index] != -1 &&
                          this.state.permissions[index] == data.id
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          this.onSelectPermissions(e, data.id, index)
                        }
                      />
                      <br />
                    </div>
                  ))}
                </FormGroup>
              )}
              <div className="text-center">
                <Button className="my-4" color="success" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    subAdminList: state.subAdmin.subAdminList,
    dashboard: state.subAdmin.totalRecords,
    totalRecords: state.subAdmin.totalRecords?.total,
    permissions: state.subAdmin.permissions,
    openSecurityModal: state.subAdmin.openSecurityModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllSubAdmin: (data) => dispatch(getAllSubAdmin(data)),
    removeSubAdmin: (data, id) => dispatch(removeSubAdmin(data, id)),
    enableDisableSubAdmin: (Apidata, data, id) =>
      dispatch(enableDisableSubAdmin(Apidata, data, id)),
    getAdminPermissions: () => dispatch(getAdminPermissions()),

    assignAdminPermissions: (data, id, req) =>
      dispatch(assignAdminPermissions(data, id, req)),
  };
};

export default reduxForm({ form: "addSubAdminForm" })(
  connect(mapStateToProps, mapDispatchToProps)(SubAdminManagement)
);
