import { combineReducers } from "redux";
import user from "./user.reducers";
import persist from "./persist.reducer";
import account from "./account.reducer";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import loading from "./loading.reducer";
import userList from "./userList.reducer";
import transaction from "./transaction.reducer";
import withdraw from "./withdraw.reducer";
import otc from "./otc.reducer";
import swap from "./swap.reducer";
import subAdmin from "./SubAdmin.reducer";
import reports from "./report.reducer";
import announcement from "./announcement.reducer";
import funds from "./fund.reducer";
import revenue from "./revenue.reducer";
import notification from "./notification.reducer";
import token from "./token.reducer";
import pairCoinList from "./pairCoin.reducer";
import referralList from "./referral.reducer";
import fiatHistory from "./inrmanagement.reducer";
import bankAccount from "./bank.reducer";
import currencyInterest from "./interest.reducer";
import rewardList from "./reward.reducer";
import banner from "./banner.reducer";
import installment from "./installments.reducer";
import content from "./content.reducer";
import bankDetails from "./bankDetails.reducer";
import subjectList from "./subject.reducer";
import userGuide from "./userGuide.reducer";
import userTermination from "./userTermination.reducer";

export const history = createBrowserHistory();

const appReducer = combineReducers({
  user,
  persist,
  account,
  form: formReducer,
  router: connectRouter(history),
  loading,
  userList,
  transaction,
  withdraw,
  swap,
  subAdmin,
  reports,
  otc,
  announcement,
  funds,
  revenue,
  notification,
  token,
  pairCoinList,
  referralList,
  subjectList,
  fiatHistory,
  bankAccount,
  currencyInterest,
  rewardList,
  banner,
  installment,
  content,
  bankDetails,
  userGuide,
  userTermination,
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USERS_PERSIST") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
